import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import CropImage from "./image-crop";

const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 14px;
  font-weight: 600;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 0.5rem;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: -30px;
  }
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;

const InputField = styled.input`
  height: 46px;
  width: 100%;
  padding: 0.5rem;
  background: #eaeaea;
  border: 1px solid #eaeaea;
  margin-bottom: 1rem;
  border-radius: 4px;
  font-size: 14px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const ColForHeading = styled(Col)`
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 10px;
  }
`;

const SocialMediaSettings = ({ setSelectedFile, setIsSmallImage }: any) => {
  const formik = useFormikContext();
  const { values } = useFormikContext<any>();

  return (
    <>
      <Row>
        <ColForHeading sm={10}>
          <MenuHeading>Social Media Settings</MenuHeading>
        </ColForHeading>
        <Col
          sm={2}
          style={{
            justifyContent: "flex-end",
            alignSelf: "flex-start",
          }}
        >
          <CheckBoxWrapper>
            <>
              <CheckBox
                id="checkbox"
                checked={values.seoMetaSetting.seoMetaSettings}
                type="checkbox"
                name="seoMetaSetting.seoMetaSettings"
                onChange={(e: any) => {
                  formik.setFieldValue(
                    "seoMetaSetting.seoMetaSettings",
                    e.target.checked
                  );
                }}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </>
          </CheckBoxWrapper>
        </Col>
      </Row>
      <Row>
        <SubHeading>
          Modify the following settings to personalize the appearance of the
          URL's description, featured image, and title when shared. These
          customizations are part of what is referred to as the open graph
          settings.
        </SubHeading>
      </Row>
      {values.seoMetaSetting.seoMetaSettings && (
        <Col sm={12} style={{ flexDirection: "column" }}>
          <MenuHeading>Social Media Custom Image</MenuHeading>
          <SubHeading>
            It is suggested to use an image width of 1200px with a 2:1 aspect
            ratio.
          </SubHeading>
          <CropImage
            setIsSmallImage={setIsSmallImage}
            showSeoMetaOption={values.seoMetaSetting.seoMetaSettings}
            imageToShow={
              values.seoMetaSetting &&
              values.seoMetaSetting.socialMediaCustomImage
            }
            setSelectedFile={setSelectedFile}
          />
          <MenuHeading>Social Media Title</MenuHeading>
          <SubHeading>Page title should be around 50 characters.</SubHeading>
          <InputField
            type="text"
            name="seoMetaSetting.socialMediaTitle"
            disabled={!values.seoMetaSetting.seoMetaSettings}
            value={
              values.seoMetaSetting && values.seoMetaSetting.socialMediaTitle
            }
            onChange={(e: any) =>
              formik.setFieldValue(
                "seoMetaSetting.socialMediaTitle",
                e.target.value
              )
            }
          />
          <MenuHeading>Social Media Description</MenuHeading>
          <SubHeading>
            A description should ideally be between 150 to 160 characters.
          </SubHeading>
          <textarea
            disabled={!values.seoMetaSetting.seoMetaSettings}
            style={{
              height: "100px",
              width: "100%",
              padding: "0.5rem",
              background: "#eaeaea",
              border: "1px solid #eaeaea",
              borderRadius: "4px",
              fontSize: "14px",
            }}
            name="seoMetaSetting.socialMediaDescription"
            value={
              values.seoMetaSetting &&
              values.seoMetaSetting.socialMediaDescription
            }
            onChange={(e: any) =>
              formik.setFieldValue(
                "seoMetaSetting.socialMediaDescription",
                e.target.value
              )
            }
          ></textarea>
        </Col>
      )}
    </>
  );
};

export default SocialMediaSettings;
