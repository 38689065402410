import { Field, Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Col, FormLabel, Row } from "react-bootstrap";
import styled from "styled-components";
import { MOBILE } from "../../constants/mobile";
import { LoaderContext } from "../../context/loader-context";
import { NotificationManager } from "react-notifications";
import myAxios from "../../myaxios";
import { UserContext } from "../../context/UserContext";

const MainFormRow = styled(Row)`
  padding: 0.5rem 2rem;
  margin-top: 1rem;
`;

const ParentDiv = styled.div`
  overflow: hidden;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  align-items: center;
  justify-content: center;
`;
const EmptyDiv = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;
const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;
const FormLabelNew = styled(FormLabel)`
  font-weight: 600;
  font-size: 15px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const GetTokenText = styled.span`
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  color: #1585b5;
`;

const AncherTag = styled.a`
  text-decoration: none;
`;

const InputField = styled(Field)`
  width: 100%;
  padding: 15px 10px;
  background: #fff;
  border: 1px solid #eaeaea;
  display: inline;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    border-radius: 4px;
  }
  border-radius: 4px;
  height: 40px;
  opacity: 0px;
  background: #f8f7fa;
  &:focus {
    outline: none;
  }
`;
const Container = styled(Col)`
  position: fixed;
  display: block;
  width: 30vw;
  margin: auto;
  overflow: auto;
  max-height: 75vh;
  inset: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 999999;
`;
const DltHeader = styled(Row)`
  height: 45px;
  background: rgba(47, 51, 73, 1);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: #2f4c95;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: white;
  letter-spacing: 0.5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    color: white;
  }
`;

const ButtonRow = styled(Row)`
  padding: 0.5rem 2.7rem;
  display: flex;
  justify-content: space-between;
`;

const UpdateButton = styled.button`
  background: #1585b5;
  border-radius: 2px;
  color: white;
  border: 1px solid #1585b5;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: auto;
  padding: 0.4rem 1.4rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CancelButton = styled.button`
  background: none;
  padding: 0.4rem 3rem;
  color: #2f3349;
  border: 1px solid #2f3349;
  width: auto;
  display: flex;
  margin-top: auto;
  justify-content: center;
  font-weight: bold;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const PrefillSpan = styled.span`
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  right: 38px;
`;
const PrefillDiv = styled.div`
  background: #1585b5;
  color: white;
  border-radius: 0 4px 4px 0;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 6px;
`;
// const SpanError = styled.span`
//   font-style: normal;
//   /* margin-top: 5px; */
//   font-weight: 500;
//   font-size: 11px;
//   color: #df5c04;
//   padding: 3px;
//   display: flex;
//   @media (max-width: ${MOBILE.maxWidth}px) {
//     width: 100%;
//     margin-top: 0px;
//     font-size: 9px;
//   }
// `;

const ShopifyTokenForm = ({ setShowShopifyConnectForm }: any) => {
  const { setShowLoader } = useContext(LoaderContext);
  const [detailsVerified, setDetailsVerified] = useState<boolean>(false);
  const [shopifyDetails, setShopifyDetails] = useState<any>();
  const { setIntegrationAppsList, integrationAppList } =
    useContext(UserContext);

  const hidePopup = () => {
    setShowShopifyConnectForm(false);
    setDetailsVerified(false);
    setShopifyDetails(undefined);
  };

  const handleSubmit = (values: any) => {
    console.log("values in habdle submit, ", values);
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/verify-token", {
        shopName: values.shopName,
        apiKey: values.apiKey,
        password: values.password,
      })
      .then((response: any) => {
        if (response && response.data && response.data.success) {
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Credentials are verified, please proceed to save!"
          );
          setShowLoader(false);
          setDetailsVerified(true);
          setShopifyDetails({
            shopName: values.shopName,
            apiKey: values.apiKey,
            password: values.password,
          });
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Invalid credentials"
          );

          setShowLoader(false);
        }
      })
      .catch((error: any) => {
        setShowLoader(false);
      });
  };

  const saveShopifyDetails = () => {
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/save-shopify-settings", {
        shopName: shopifyDetails.shopName,
        apiKey: shopifyDetails.apiKey,
        password: shopifyDetails.password,
      })
      .then((response: any) => {
        if (response && response.data && response.data.success) {
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Details are saved successfully."
          );
          setShowLoader(false);
          setShowShopifyConnectForm(false);
          setIntegrationAppsList({ ...integrationAppList, shopify: true });
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Invalid credentials"
          );
          setShowLoader(false);
        }
      })
      .catch((error: any) => {
        setShowLoader(false);
      });
  };

  return (
    <>
      <ParentDiv>
        <EmptyDiv onClick={hidePopup}></EmptyDiv>
        <Container>
          <Formik
            initialValues={{
              shopName: "",
              APIKey: "",
              token: "",
            }}
            enableReinitialize
            onSubmit={(values: any) => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, handleChange, handleSubmit, values }) => {
              return (
                <>
                  <Form onSubmit={handleSubmit}>
                    <DltHeader>
                      <SpanDltPopup>Connect Shopify</SpanDltPopup>
                    </DltHeader>
                    <MainFormRow>
                      <FieldSection>
                        <FormLabelNew>Shop name</FormLabelNew>
                        <div style={{ flex: 1, flexDirection: "row-reverse" }}>
                          <PrefillDiv>
                            <PrefillSpan>.myshopify.com</PrefillSpan>
                          </PrefillDiv>
                          <InputField
                            style={{
                              marginBottom: "0.5rem",
                              borderRight: "none",
                            }}
                            type="text"
                            name="shopName"
                            placeholder=""
                            onChange={handleChange}
                            disabled={detailsVerified}
                          />
                        </div>
                        <GetTokenText>
                          You can find it by logging into your Shopify admin.
                          Your store name will be in the format
                          [your-shop-name].myshopify.com.
                        </GetTokenText>
                      </FieldSection>
                      <FieldSection>
                        <FormLabelNew>API Key</FormLabelNew>
                        <InputField
                          style={{ marginBottom: "0.5rem" }}
                          type="text"
                          name="apiKey"
                          defaultValue={values.APIKey}
                          placeholder=""
                          onChange={handleChange}
                          disabled={detailsVerified}
                        />
                        {values.shopName && (
                          <AncherTag
                            href={`https://admin.shopify.com/store/${values.shopName}/settings/apps/development`}
                            target="_blank"
                          >
                            <GetTokenText>Find Key</GetTokenText>
                          </AncherTag>
                        )}
                      </FieldSection>
                      <FieldSection>
                        <FormLabelNew>Admin API access token</FormLabelNew>
                        <InputField
                          style={{ marginBottom: "0.5rem" }}
                          type="text"
                          name="password"
                          defaultValue={values.token}
                          placeholder=""
                          onChange={handleChange}
                          disabled={detailsVerified}
                        />
                        {values.shopName && (
                          <AncherTag
                            href={`https://admin.shopify.com/store/${values.shopName}/settings/apps/development`}
                            target="_blank"
                          >
                            <GetTokenText>Find Token</GetTokenText>
                          </AncherTag>
                        )}
                      </FieldSection>
                    </MainFormRow>
                    <ButtonRow>
                      {!detailsVerified ? (
                        <UpdateButton type="submit">
                          Check Credentials
                        </UpdateButton>
                      ) : (
                        <UpdateButton
                          type="button"
                          onClick={() => saveShopifyDetails()}
                        >
                          Submit
                        </UpdateButton>
                      )}
                      <CancelButton onClick={hidePopup}>Cancel</CancelButton>
                    </ButtonRow>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Container>
      </ParentDiv>
    </>
  );
};

export default ShopifyTokenForm;
