import { useContext, useRef } from "react";
import { Col, FormLabel, Row } from "react-bootstrap";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import { BookContext } from "../../../context/BookContext";
import { Formik } from "formik";
import { LoaderContext } from "../../../context/loader-context";
import myAxios from "../../../myaxios";
import { NotificationManager } from "react-notifications";

const ParentDiv = styled.div`
  overflow: hidden;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  align-items: center;
  justify-content: center;
`;
const EmptyDiv = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;
const FormLabelNew = styled(FormLabel)`
  font-weight: 600;
  font-size: 14px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;
const TextAREANew = styled.textarea`
  border-radius: 4px;
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 10px;
  font-size: 14px;
  min-height: 15vh;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
  }
`;
const InputField = styled.input`
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #eaeaea !important;
  display: inline;
  border-radius: 4px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    border-radius: 4px;
  }
`;
const Container = styled(Col)`
  position: fixed;
  display: block;
  width: 40vw;
  margin: auto;
  overflow: auto;
  max-height: 85vh;
  inset: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 999999;
  border-radius: 10px 10px 0px 0px;
`;
const DltHeader = styled(Row)`
  height: 45px;
  background: rgba(47, 51, 73, 1);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #2f4c95;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: white;
  @media (max-width: ${MOBILE.maxWidth}px) {
    color: white;
  }
`;
const MainFormRow = styled(Row)`
  padding: 0.5rem 2rem;
`;

const ButtonRow = styled(Row)`
  padding: 0rem 3rem;
  display: flex;
  justify-content: space-between;
  //   align-items: center;
`;

const UpdateButton = styled.button`
  background: #1585b5;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: auto;
  //   padding: 5px 20px;
  padding: 0.4rem 3rem;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CancelButton = styled.button`
  background: none;
  padding: 0.4rem 3rem;
  color: #2f3349;
  border: 1px solid #2f3349;
  width: auto;
  display: flex;
  margin-top: auto;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const EditCharacterChatbot = ({ editPopUp, toBeEdit }: any) => {
  const { selectedBook, setSelectedBook, updateBookTitle } =
    useContext(BookContext);
  const { setShowLoader } = useContext(LoaderContext);

  const textAreaRef1 = useRef<HTMLTextAreaElement>(null);
  const textAreaRef2 = useRef<HTMLTextAreaElement>(null);

  const hidePopup = () => {
    editPopUp(false);
  };

  return (
    <ParentDiv>
      <EmptyDiv onClick={hidePopup}></EmptyDiv>
      <Container>
        <Formik
          initialValues={{
            characterTitle: selectedBook?.title || "",
            characterGreeting: selectedBook?.charGreeting || "",
            characterOtherInfo: selectedBook?.charOtherInfo || "",
          }}
          // validate={}
          enableReinitialize
          onSubmit={(values: any) => {
            const postData = {
              bookId: selectedBook?._id,
              ...values,
            };
            setShowLoader(true);

            myAxios
              .post(
                process.env.REACT_APP_SERVER_URL +
                  "/books/update-character-chatbot",
                postData
              )
              .then((response) => {
                if (response.data && response.data.success) {
                  console.log("response", response);
                  myAxios
                    .get(
                      process.env.REACT_APP_SERVER_URL +
                        "/books/book/" +
                        selectedBook?._id
                    )
                    .then((response) => {
                      if (response && response.data) {
                        setSelectedBook(response.data.data);
                        updateBookTitle(
                          selectedBook?._id || "",
                          values.characterTitle
                        );
                      }
                    })
                    .catch((error) => {
                      // console.log("Getting error ", error);
                    });
                  setShowLoader(false);
                  NotificationManager.success(response.data.message);
                }
              })
              .catch((error) => {
                // handle error
                setShowLoader(false);
                NotificationManager.error("");
              });
            hidePopup();
            toBeEdit = null;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <DltHeader>
                  <SpanDltPopup>Edit Character Bot</SpanDltPopup>
                </DltHeader>
                <MainFormRow>
                  <FieldSection>
                    <FormLabelNew>Name</FormLabelNew>
                    <InputField
                      style={{ marginBottom: "0.5rem" }}
                      type="text"
                      name="characterTitle"
                      defaultValue={values.characterTitle}
                      placeholder="Character Name"
                      onChange={handleChange}
                    />
                  </FieldSection>
                  <FieldSection>
                    <FormLabelNew>Greeting</FormLabelNew>
                    <TextAREANew
                      ref={textAreaRef1}
                      name="characterGreeting"
                      defaultValue={values.characterGreeting}
                      value={values.characterGreeting}
                      onChange={handleChange}
                    ></TextAREANew>
                  </FieldSection>

                  <FieldSection>
                    <FormLabelNew>
                      Other information about character
                    </FormLabelNew>
                    <TextAREANew
                      ref={textAreaRef2}
                      name="characterOtherInfo"
                      defaultValue={values.characterOtherInfo}
                      value={values.characterOtherInfo}
                      onChange={handleChange}
                    ></TextAREANew>
                  </FieldSection>
                </MainFormRow>
                <ButtonRow>
                  <CancelButton onClick={hidePopup}>Cancel</CancelButton>
                  <UpdateButton type="submit">Update</UpdateButton>
                </ButtonRow>
              </form>
            </>
          )}
        </Formik>
      </Container>
    </ParentDiv>
  );
};

export default EditCharacterChatbot;
