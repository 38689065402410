import { Col } from "react-bootstrap";
import styled from "styled-components";
import BackButton from "../images/left-pagination-arrow.svg";
import MagicLinkLoginPopup from "./magic-link-login-popup";
import { MEDIUM } from "../constants/desktop";
import { TAB } from "../constants/tab";
import { MOBILE } from "../constants/mobile";

const LoginMainSection = styled.div`
  justify-content: center;
  align-content: center;
  height: 100vh;
  margin: 15px;
`;
const LoginColumn = styled(Col)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: flex-start;
    padding: 2rem 0;
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: center;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    justify-content: center;
  }
`;

const BackButtonSection = styled.a`
  color: #1585b5;
  font-weight: bold;
  margin-top: 1rem;
  text-decoration: none;
  cursor: pointer;
  & img {
    margin-right: 1rem;
  }
  &:hover {
    color: #1585b5;
  }
`;

const MagicLinkLoginView = ({ loadingState }: any) => {
  return (
    <LoginMainSection>
      <LoginColumn lg={4}>
        <MagicLinkLoginPopup loadingState={loadingState} />
        <BackButtonSection href={process.env.REACT_APP_FRONTEND_URL}>
          <img alt="img" src={BackButton} />
          <span>Back to DropChat</span>
        </BackButtonSection>
      </LoginColumn>
    </LoginMainSection>
  );
};

export default MagicLinkLoginView;
