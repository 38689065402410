import { Col } from "react-bootstrap";
import styled from "styled-components";
import { MEDIUM, MOBILE, TAB } from "../../../../constants/breakpoints";

const FilterSection = styled.div`
  margin-bottom: 1rem;
  padding: 1rem 0.5rem;
  width: 100%;
`;
const Label = styled.label`
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
  }
`;
const Select = styled.select`
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    height: 40px;
  }
`;
const SingleFilter = styled(Col)`
  flex-direction: column;
  font-weight: bold;
  padding: 0.2rem;
  & select {
    margin-top: 0.5rem;
    border: 1px solid #eaeaea;
    padding: 0.5rem 1rem;
    background: #eaeaea;
    @media (max-width: ${MOBILE.maxWidth}px) {
      width: 100%;
    }
    @media (min-width: ${TAB.minWidth}px) {
      width: 100%;
    }
    @media (min-width: ${MEDIUM.minWidth}px) {
      width: 200px;
    }
  }
`;

const DataSourceFilterSection = ({
  setFileTypeFilter,
  setStatusFilter,
}: any) => {
  return (
    <FilterSection>
      <SingleFilter xs={6} lg={2}>
        <Label>File Type</Label>
        <Select onChange={(e: any) => setFileTypeFilter(e.target.value)}>
          <option value={"all"}>All Files</option>
          <option value={"aws"}>PDFs</option>
          <option value={"youtube"}>Youtube</option>
          <option value={"url"}>Website/Sitemap</option>
          <option value={"text"}>Text</option>
          <option value={"feedback"}>Feedback</option>
          <option value={"feedbackUp"}>Feedback Good</option>
          <option value={"feedbackDown"}>Feedback Bad</option>
          <option value={"shopify"}>Shopify</option>
        </Select>
      </SingleFilter>
      <SingleFilter
        xs={6}
        lg={2}
        onChange={(e: any) => setStatusFilter(e.target.value)}
      >
        <Label>Status</Label>
        <Select>
          <option value={0}>All</option>
          <option value={2}>Ready</option>
          <option value={1}>In progress</option>
          <option value={3}>Failed</option>
        </Select>
      </SingleFilter>
    </FilterSection>
  );
};

export default DataSourceFilterSection;
