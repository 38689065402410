import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  EventCategory,
  ShareChatbotEvents,
  Source,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { trackEvent } from "../../event-utils/track-event";
import { MOBILE } from "../../constants/mobile";

const InnerDiv = styled.div`
  height: auto;
  /* width: 100%; */
  width: 36vw;
  background: linear-gradient(0deg, #eaeaea, #eaeaea),
    linear-gradient(0deg, #f8f7fa, #f8f7fa);
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(234, 234, 234, 1);
  border-radius: 4px;
  display: block;
  padding: 10px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 70vw;
  }
`;
const InputField = styled.input`
  width: 100%;
  border: 1px solid rgba(51, 48, 60, 0.2);
  height: 35px;
  border-radius: 4px;
`;
const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;
const SkipFormSpan = styled.span`
  font-family: "Poppins", sans-serif;
  color: rgba(21, 133, 181, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
`;
const SubmitButton = styled.button`
  background: rgba(21, 133, 181, 1);
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  width: 40%;
  border-radius: 4px;
`;
const Labels = styled.label`
  color: rgba(51, 48, 60, 0.8);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;
const FormHeading = styled.p`
  font-weight: 600;
  color: #33303c;
  font-size: 14px;
  margin-bottom: 0;
`;

const FormConditionalHeading = styled.p`
  font-weight: 600;
  color: #1585b5;
  font-size: 1rem;
`;
const InlineMethodLoginForm = ({
  emailValue,
  nameValue,
  phoneValue,
  bookId,
  chatbotUserEmail,
  currentChatSession,
  validatedLoginSettings,
  createUpdateChatSession,
  setNameValue,
  setEmailValue,
  setPhoneValue,
  setShowLeadForm,
  leadSettings,
  setCurrentSessionHistory,
  currentSessionHistory,
}: any) => {
  // console.log("leadSettings inline component", leadSettings);
  return (
    <>
      <Container style={{ padding: "0" }}>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <InnerDiv>
              {leadSettings.loginForm === "conditional" && (
                <FormConditionalHeading>
                  {leadSettings.conditionalMessage}
                </FormConditionalHeading>
              )}

              {leadSettings &&
                leadSettings.loginForm &&
                leadSettings.loginForm !== "none" && (
                  <FormHeading>
                    {leadSettings && leadSettings.customText
                      ? leadSettings.customText
                      : "Fill out form to start chatting"}
                  </FormHeading>
                )}

              {leadSettings.isUserName && (
                <>
                  {" "}
                  <Labels>Full Name</Labels>
                  <InputField
                    type="text"
                    onChange={(e: any) => setNameValue(e.target.value)}
                  />
                </>
              )}
              {leadSettings.isEmail && (
                <>
                  <Labels style={{ marginTop: "10px" }}>Email Address</Labels>
                  <InputField
                    type="text"
                    onChange={(e: any) => setEmailValue(e.target.value)}
                  />
                </>
              )}
              {leadSettings.isMobileNumber && (
                <>
                  <Labels style={{ marginTop: "10px" }}>Phone</Labels>
                  <InputField
                    type="text"
                    maxLength={13}
                    onChange={(e: any) => setPhoneValue(e.target.value)}
                  />
                </>
              )}
              <ButtonDiv>
                {leadSettings.loginForm === "optional" && (
                  <SkipFormSpan
                    onClick={() => {
                      trackEvent({
                        email: emailValue || "PUBLIC",
                        eventCategory: EventCategory.CHATBOT,
                        event:
                          ShareChatbotEvents.USER_SKIP_LOGIN_FORM_ON_SHARE_CHAT,
                        subCategory: SubCategory.EVENT,
                        source: Source.SHARE,
                        bookId: bookId,
                        chatbotUserEmail: chatbotUserEmail,
                        leadChatSession:
                          currentChatSession && currentChatSession.id,
                      });
                      setShowLeadForm("none");
                      // console.log("click");
                    }}
                  >
                    Skip Form
                  </SkipFormSpan>
                )}
                <SubmitButton
                  onClick={() => {
                    trackEvent({
                      email: emailValue || "PUBLIC",
                      eventCategory: EventCategory.CHATBOT,
                      event:
                        ShareChatbotEvents.USER_FILL_LOGIN_FORM_ON_SHARE_CHAT,
                      subCategory: SubCategory.EVENT,
                      source: Source.SHARE,
                      rawData: {
                        name: nameValue,
                        email: emailValue,
                        phone: phoneValue,
                      },
                      bookId: bookId,
                      chatbotUserEmail: chatbotUserEmail,
                      leadChatSession:
                        currentChatSession && currentChatSession.id,
                    });

                    let nameString = nameValue && "Name: " + nameValue;
                    let emailString = emailValue && "Email: " + emailValue;
                    let phoneString = phoneValue && "Phone: " + phoneValue;

                    let question = ` ${nameString},  ${emailString},  ${phoneString}`;

                    if (validatedLoginSettings()) {
                      createUpdateChatSession(true);
                      setCurrentSessionHistory([
                        ...currentSessionHistory,
                        {
                          id: Math.random(),
                          question: question,
                          answer: "Thank you for filling out your information!",
                          isChatEnd: true,
                        },
                      ]);
                    }
                  }}
                >
                  Send
                </SubmitButton>
              </ButtonDiv>
            </InnerDiv>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default InlineMethodLoginForm;
