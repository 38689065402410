import { Field, Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Button, Col, Container, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import { LoaderContext } from "../../context/loader-context";
import myAxios from "../../myaxios";
import { parseErrorResponse } from "../../utils/utils";
import { SubscriptionPlan } from "../../types";
import CopyIcon from "../../images/copy-icon.svg";

const BookFetchForm = styled(Form)`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
  color: #33303cde;
  border: 1px solid #eaeaea;
  background: #f8f7fa;
`;

const FormError = styled.span`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const SaveButton = styled(Button)`
  width: 100%;
  background: #1585b5;
  padding: 0.5rem;
  color: #fff;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
`;

const ResetButton = styled.p`
  color: #33303c;
  border-bottom: 1px solid #33303c;
  font-size: 15px;
  margin: 0;
  cursor: pointer;
`;

const MainSection = styled(Container)`
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 2.5rem;
  padding-top: 2rem;
`;

const MainDiv = styled.div``;

const DivForDltPopUp = styled.div`
  position: fixed;
  // width: 367px;
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  z-index: 111;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;

const FieldConditionalSectionRadio = styled(Row)`
  & label {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  margin-bottom: 1rem;
`;

const InputFieldRadio = styled(Field)`
  width: auto;
  margin-top: 0.2rem;
  margin-right: 0.4rem;
  cursor: pointer;
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
`;

const CopyTextSection = styled.div`
  display: inline-grid;
  background: rgba(51, 48, 60, 0.09);
  padding: 16px;
  position: relative;
  margin: 1rem 0;
  & p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 95%;
    margin: 0;
  }
  & img {
    position: absolute;
    right: 16px;
    top: 25%;
    cursor: pointer;
  }
`;

const GeneratePaymentLink = ({
  selectedCustomerID,
  specialPlansList,
  setShowGeneratePaymentLinkBox,
}: any) => {
  const { setShowLoader } = useContext(LoaderContext);
  const [generatedPaymentLink, setGeneratedPaymentLink] = useState<string>("");

  const validateCustomPurchaseSubmission = (formData: any) => {
    console.log("formData is, ", formData);
    const errors: any = {};
    if (!formData.customerID) {
      errors.customerID = "Customer ID is invalid!";
    }
    if (!formData.priceId) {
      errors.priceId = "Please select special plan from the list!";
    }
    return errors;
  };

  return (
    <MainSection fluid>
      <MainDiv>
        <DivForDltPopUp>
          <Row style={{ margin: "0.5rem" }}>
            <p>
              <b>Generate Checkout Link</b>
            </p>
            <Col sm={12}>
              <Formik
                initialValues={{
                  customerID: selectedCustomerID,
                  priceId: "",
                }}
                validate={validateCustomPurchaseSubmission}
                onSubmit={(values) => {
                  setShowLoader(true);
                  let dataToGenerateLink = {
                    customerID: values.customerID,
                    priceId: values.priceId,
                  };
                  myAxios
                    .post(
                      process.env.REACT_APP_SERVER_URL +
                        "/create-checkout-session",
                      {
                        ...dataToGenerateLink,
                      }
                    )
                    .then((response) => {
                      if (response.data && response.data.success) {
                        setShowLoader(false);
                        NotificationManager.success(response.data.message);
                        setGeneratedPaymentLink(response.data.data.url);
                      } else {
                        setShowLoader(false);
                        NotificationManager.error(
                          response.data.message
                            ? response.data.message
                            : "Unable to process your request, please try later"
                        );
                      }
                    })
                    .catch((error) => {
                      setShowLoader(false);
                      NotificationManager.error(parseErrorResponse(error));
                    });
                }}
              >
                {({ values, errors, touched, resetForm }: any) => (
                  <BookFetchForm>
                    <FormInput name="customerID" type="text" hidden />
                    <>
                      <FieldConditionalSectionRadio>
                        <label>
                          Select Special Plan
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        {errors.customerID && touched.customerID && (
                          <FormError className="formError">
                            {errors.customerID}
                          </FormError>
                        )}
                        {errors.priceId && touched.priceId && (
                          <FormError className="formError">
                            {errors.priceId}
                          </FormError>
                        )}
                        {specialPlansList && specialPlansList.length > 0 ? (
                          specialPlansList.map(
                            (specialPlan: SubscriptionPlan) => {
                              return (
                                <Col md={4} className="align-items-center">
                                  <InputFieldRadio
                                    type="radio"
                                    name="priceId"
                                    value={specialPlan.id}
                                  />{" "}
                                  {specialPlan.name}
                                </Col>
                              );
                            }
                          )
                        ) : (
                          <Col md={12}>
                            <span>
                              <b>No special plan avaialble to show</b>
                            </span>
                          </Col>
                        )}
                      </FieldConditionalSectionRadio>
                      {generatedPaymentLink && (
                        <CopyTextSection>
                          <p>{generatedPaymentLink}</p>
                          <CopyToClipboard
                            text={generatedPaymentLink}
                            onCopy={(e) => {
                              NotificationManager.success("URL copied.");
                            }}
                          >
                            <img
                              alt="img5"
                              src={CopyIcon}
                              width="24"
                              height="24"
                            />
                          </CopyToClipboard>
                        </CopyTextSection>
                      )}
                      <Row>
                        <Col>
                          <SaveButton type="submit" disabled={!values.priceId}>
                            Generate Link
                          </SaveButton>
                        </Col>
                        <Col
                          style={{
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          <ResetButton
                            onClick={() =>
                              setShowGeneratePaymentLinkBox(resetForm)
                            }
                          >
                            Cancel
                          </ResetButton>
                        </Col>
                      </Row>
                    </>
                  </BookFetchForm>
                )}
              </Formik>
            </Col>
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </MainSection>
  );
};

export default GeneratePaymentLink;
