import { Formik } from "formik";
import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
import { UserContext } from "../../context/UserContext";
import { LoaderContext } from "../../context/loader-context";
import ZendeskCross from "../../images/cross-zendesk.svg";
import HidePassEye from "../../images/hidepasseye.svg";
import ShowPassEye from "../../images/showpasseye.svg";
import myAxios from "../../myaxios";
const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 59%);
  z-index: 9999;
`;

const DivForDltPopUp = styled.div`
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 8%);
  z-index: 1111;
  width: 30%;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    top: 50%;
    height: 100%;
    width: 100%;
    position: relative;
  }
  @media (min-width: ${TAB.minWidth}px) {
    position: relative;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    position: fixed;
    width: 30%;
  }
`;

const DltHeader = styled.div`
  width: 100% !important;
  background: #2f3349;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: center;
    height: auto;
    padding: 10px;
    flex-direction: row-reverse;
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: center;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    justify-content: center;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
`;
const EmptyDiv = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;
const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;
const LabelHeading = styled.label`
  /* padding: 2rem 3rem; */
  width: 100%;
  color: #000;
  font-size: 16px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
  }
`;

const InputField = styled.input`
  border: 1px solid #eaeaea;
  width: 100%;
  height: 40px;
  background: #eaeaea;
  border-radius: 4px;
  padding: 10px 28px 10px 10px;
  position: relative;
  &::placeholder {
    padding-left: 10px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    padding: 5px;
    height: 30px;
    &::placeholder {
      padding-left: 5px;
    }
  }
`;
// const TextArea = styled.textarea`
//   border: 1px solid #eaeaea;
//   /* padding: 2rem 3rem; */
//   width: 100%;
//   height: 80px;
//   background: #eaeaea;
//   border-radius: 4px;
//   padding: 10px 10px 10px 10px;
//   &::placeholder {
//     padding-left: 10px;
//   }
//   @media (max-width: ${MOBILE.maxWidth}px) {
//     font-size: 12px;
//     padding: 5px;
//     height: 60px;
//     &::placeholder {
//       padding-left: 5px;
//     }
//   }
// `;
const FormMainDiv = styled.div`
  margin-bottom: 10px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-bottom: 0px;
  }
`;
const SubmitBtn = styled.button`
  background: #1585b5;
  padding: 0.3rem 0.1rem;
  color: #fff;
  border: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    padding: 0.3rem 0.1rem;
    background: #0b5f82;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    padding: 0.1rem 0.1rem;
    &:hover {
      padding: 0.1rem 0.1rem;
      background: #0b5f82;
    }
  }
`;
const CancelBtn = styled.div`
  width: 100%;
  align-items: center;
  padding: 0.3rem 0.1rem;
  justify-content: center;
  color: #1585b5;
  border: 1px solid #1585b5;
  border-radius: 4px;
  cursor: pointer;
`;
const ROW = styled(Row)`
  justify-content: space-between;
  margin-top: 15px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 16px;
  }
`;
const OrTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OrText = styled.p`
  margin: 0 10px; /* Add margin for spacing */
`;

const Ruler = styled.div`
  flex: 1;
  height: 1px;
  background-color: #ccc; /* Color of the ruler */
`;
const SpanError = styled.span`
  font-style: normal;
  /* margin-top: 5px; */
  font-weight: 500;
  font-size: 11px;
  color: #df5c04;
  padding: 3px;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    margin-top: 0px;
    font-size: 9px;
  }
`;
const Paragragh = styled.p`
  font-size: 16px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    margin-bottom: 5px;
  }
`;
const EscEmailForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    padding: 10px;
  }
`;

const ClearText = styled.span`
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  cursor: pointer;
  color: #1585b5;
`;
const GetTokenText = styled.span`
  font-size: 13px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  color: #1585b5;
`;

const AncherTag = styled.a`
  text-decoration: none;
`;
const PrefillSpan = styled.span`
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  right: 38px;
`;
const PrefillDiv = styled.div`
  background: #1585b5;
  color: white;
  position: absolute;
  border-radius: 0 4px 4px 0;
  z-index: 1;
  right: 34px;
  margin-top: 2px;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 2px;
`;
const CrossImg = styled.img`
  position: absolute;
  margin-top: 15px;
  right: 40px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 9px;
    right: 31px;
  }
`;
const ShowPasswordImgTag = styled.img`
  position: absolute;
  right: 14%;
  z-index: 1;
  margin-top: 14px;
  cursor: pointer;
`;

const ZendeskConnectForm = ({
  showZendeskConnectForm,
  setShowZendeskConnectForm,
}: any) => {
  const { setShowLoader } = useContext(LoaderContext);
  const [toShowPasswordLogin, setToShowPasswordLogin] =
    useState<boolean>(false);

  const hideComponent = () => {
    setShowZendeskConnectForm(false);
  };
  const { setIntegrationAppsList, integrationAppList } =
    useContext(UserContext);
  const getStyles = (error: any) => ({
    border: error ? "1px solid rgba(242, 86, 86, 1)" : "1px solid #eaeaea",
  });
  const handleSubmit = (values: any) => {
    setShowLoader(true);
    let useUsernamePassword;
    // eslint-disable-next-line
    values.password != ""
      ? (useUsernamePassword = true)
      : (useUsernamePassword = false);

    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL + "/zendesk-integration-settings",
        {
          username: values.username,
          password: values.password,
          subDomain: values.subDomain,
          token: values.token,
          useUsernamePassword: useUsernamePassword,
          isZendeskConnect: true,
        }
      )
      .then((response) => {
        if (response && response.data && response.data.success) {
          setIntegrationAppsList({ ...integrationAppList, zendesk: true });
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Zendesk settings saved successfully!"
          );
          setShowLoader(false);
        } else {
          setIntegrationAppsList({ ...integrationAppList, zendesk: false });
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Invalid credentials"
          );

          setShowLoader(false);
        }
      })
      .catch((error) => {
        setIntegrationAppsList({ ...integrationAppList, zendesk: false });
        setShowLoader(false);
      });
  };

  const toShowPasswordInPlainText = () => {
    var x: any = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return showZendeskConnectForm ? (
    <>
      <MainDiv>
        <EmptyDiv onClick={hideComponent}></EmptyDiv>
        <DivForDltPopUp>
          <DltHeader>
            <SpanDltPopup>Connect Zendesk</SpanDltPopup>
          </DltHeader>

          <Row style={{ margin: "0" }}>
            <Col xs={12} lg={12} md={12}>
              <EscEmailForm>
                <Paragragh>
                  Enter your Zendesk subdomain, user name and token or password.
                </Paragragh>

                <Formik
                  initialValues={{
                    username: "",
                    password: "",
                    token: "",
                    subDomain: "",
                  }}
                  validate={(values: any) => {
                    const errors: {
                      username?: string;
                      password?: string;
                      token?: string;
                      subDomain?: string;
                    } = {};

                    if (!values.username) {
                      errors.username = "User name is required";
                    }
                    if (!values.subDomain) {
                      errors.subDomain = "Subdomain is required";
                    }
                    if (!values.token && values.password.length <= 0) {
                      // errors.token =
                      //   "Token is required if you  haven't  filled password";
                      errors.token = "Token or password is required";
                    }
                    if (!values.password && values.token.length <= 0) {
                      // errors.password =
                      //   "Password is required if you  haven't  filled token";
                      errors.password = "Token or password is required";
                    }

                    return errors;
                  }}
                  onSubmit={(values) => {
                    hideComponent();
                    handleSubmit(values);
                  }}
                >
                  {({
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col style={{ flexDirection: "column" }}>
                          <FormMainDiv className=" d-block">
                            <LabelHeading>Subdomain</LabelHeading>
                            <PrefillDiv>
                              <PrefillSpan>.zendesk.com</PrefillSpan>
                            </PrefillDiv>
                            <InputField
                              type="text"
                              // placeholder="Sub-Domain"
                              className="zendesk-subdomain-input-field"
                              name="subDomain"
                              style={getStyles(
                                errors.subDomain && touched.subDomain
                              )}
                              onChange={handleChange}
                            />

                            {errors.subDomain && touched.subDomain && (
                              <SpanError>{errors.subDomain}</SpanError>
                            )}
                          </FormMainDiv>
                          <FormMainDiv className=" d-block">
                            <LabelHeading>User name</LabelHeading>
                            <InputField
                              type="text"
                              // placeholder="Username"
                              name="username"
                              style={getStyles(
                                errors.username && touched.username
                              )}
                              onChange={handleChange}
                            />
                            {errors.username && touched.username && (
                              <SpanError>{errors.username}</SpanError>
                            )}
                          </FormMainDiv>
                        </Col>
                      </Row>
                      <FormMainDiv className="d-block">
                        <LabelHeading
                          style={{
                            color: values.password.length > 0 ? "#999" : "#000",
                          }}
                        >
                          Token
                        </LabelHeading>
                        <InputField
                          // placeholder="Token"
                          name="token"
                          value={values.token}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={values.password.length <= 0 ? false : true}
                        />
                        <CrossImg
                          style={{
                            cursor:
                              values.token.length >= 1
                                ? "pointer"
                                : "not-allowed",
                          }}
                          src={ZendeskCross}
                          alt="cross"
                          onClick={() => {
                            setFieldValue("token", "");
                          }}
                        />

                        <AncherTag
                          href="https://www.zendesk.com/in/login/"
                          target="_blank"
                        >
                          <GetTokenText>Get Token</GetTokenText>
                        </AncherTag>
                        {errors.token && touched.token && (
                          <SpanError>{errors.token}</SpanError>
                        )}
                      </FormMainDiv>

                      <OrTextContainer>
                        <Ruler />
                        <OrText>OR</OrText>
                        <Ruler />
                      </OrTextContainer>

                      <FormMainDiv className="d-block">
                        <LabelHeading
                          style={{
                            color: values.token.length > 0 ? "#999" : "#000",
                          }}
                        >
                          Password
                        </LabelHeading>
                        <div>
                          <ShowPasswordImgTag
                            src={
                              toShowPasswordLogin ? ShowPassEye : HidePassEye
                            }
                            onClick={() => {
                              toShowPasswordInPlainText();
                              setToShowPasswordLogin(!toShowPasswordLogin);
                            }}
                          />

                          <InputField
                            type="password"
                            id="myInput"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            disabled={values.token.length <= 0 ? false : true}
                          />

                          <CrossImg
                            style={{
                              cursor:
                                values.password.length >= 1
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                            src={ZendeskCross}
                            alt="cross"
                            onClick={() => {
                              setFieldValue("password", "");
                            }}
                          />
                          {errors.password && touched.password && (
                            <SpanError>{errors.password}</SpanError>
                          )}
                        </div>
                        <ClearText></ClearText>
                      </FormMainDiv>

                      <ROW>
                        <Col lg={5} xs={6}>
                          <SubmitBtn type="submit">Submit</SubmitBtn>
                        </Col>
                        <Col lg={5} xs={6}>
                          <CancelBtn onClick={hideComponent}>Cancel</CancelBtn>
                        </Col>
                      </ROW>
                    </form>
                  )}
                </Formik>
              </EscEmailForm>
            </Col>
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </>
  ) : (
    <></>
  );
};

export default ZendeskConnectForm;
