import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { useContext, useState } from "react";
import { Button, Col, Container, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { UserContext } from "../../context/UserContext";
import { LoaderContext } from "../../context/loader-context";
import {
  EventCategory,
  PlanEvents,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import myAxios from "../../myaxios";
import { isValidEmail, parseErrorResponse } from "../../utils/utils";
import { ADMIN_UPDATE_CUSTOM_PLAN } from "../../utils/class-names";
const BookFetchForm = styled(Form)`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
`;

const FormLabelText = styled(FormLabel)`
  color: #33303c;
  font-weight: 600;
  font-size: 15px;
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;

const FieldColumnSection = styled.div`
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-evenly;
  & div {
    flex-direction: column;
  }
  & div:nth-child(1) {
    width: 32%;
  }
  & div:nth-child(2) {
    width: 32%;
  }
  & div:nth-child(3) {
    width: 32%;
  }
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
  color: #33303cde;
  border: 1px solid #eaeaea;
  background: #f8f7fa;
`;

const FormError = styled.span`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const FieldToggleSection = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  & div {
    flex-direction: row;
  }
  & div:nth-child(1) {
  }
  & div:nth-child(2) {
  }
  & div:nth-child(3) {
  }
`;

const FormToggleLabelText = styled(FormLabel)`
  color: #33303c;
  font-weight: 600;
  font-size: 15px;
  padding-left: 0.5rem;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;

const FieldDateColumnSection = styled.div`
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  & div {
    flex-direction: column;
  }
`;

const SaveButton = styled(Button)`
  width: 100%;
  background: #1585b5;
  padding: 0.5rem;
  color: #fff;
`;

const ResetButton = styled.p`
  color: #33303c;
  border-bottom: 1px solid #33303c;
  font-size: 15px;
  margin: 0;
  cursor: pointer;
`;

const MainSection = styled(Container)`
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 2.5rem;
  padding-top: 2rem;
`;

const MainDiv = styled.div``;

const DivForDltPopUp = styled.div`
  position: fixed;
  // width: 367px;
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  z-index: 111;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;

const UpdateCustomPlanBox = ({
  currentPlan,
  setShowUpdateBox,
  email,
  getUserWithSubscriptions,
}: any) => {
  const { setShowLoader } = useContext(LoaderContext);
  const [deleteChatbotToggle, setDeleteChatbotToggle] = useState<boolean>(
    currentPlan.meta.deleteChat ? currentPlan.meta.deleteChat : false
  );
  const [flashcardsToggle, setFlashcardsToggle] = useState<boolean>(
    currentPlan.meta.flashCard ? currentPlan.meta.flashCard : false
  );
  const [removeDropchatBrandingToggle, setRemoveDropchatBrandingToggleToggle] =
    useState<boolean>(
      currentPlan.meta.removeDropChatBranding
        ? currentPlan.meta.removeDropChatBranding
        : false
    );

  const [allowPDFIndex, setAllowPDFIndex] = useState<boolean>(
    currentPlan.meta.allowPDFIndex ? currentPlan.meta.allowPDFIndex : false
  );
  const [allowWebsiteIndex, setAllowWebsiteIndex] = useState<boolean>(
    currentPlan.meta.allowWebsiteIndex
      ? currentPlan.meta.allowWebsiteIndex
      : false
  );
  const [allowYTIndex, setAllowYTIndex] = useState<boolean>(
    currentPlan.meta.allowYTIndex ? currentPlan.meta.allowYTIndex : false
  );

  const [gpt35Turbo16k, setGpt35Turbo16k] = useState<boolean>(
    currentPlan.meta.gpt35Turbo16k ? currentPlan.meta.gpt35Turbo16k : false
  );
  const [gpt4TurboPreview, setGpt4TurboPreview] = useState<boolean>(
    currentPlan.meta.gpt4TurboPreview
      ? currentPlan.meta.gpt4TurboPreview
      : false
  );
  const [leadLoginSettings, setLeadLoginSettings] = useState<boolean>(
    currentPlan.meta.leadLoginSettings
      ? currentPlan.meta.leadLoginSettings
      : false
  );

  const [deleteAccount, setDeleteAccount] = useState<boolean>(
    currentPlan.meta.deleteAccount ? currentPlan.meta.deleteAccount : false
  );
  const [customOpenAPIKey, setCustomOpenAPIKey] = useState<boolean>(
    currentPlan.meta.customOpenAPIKey
      ? currentPlan.meta.customOpenAPIKey
      : false
  );
  const [integrationApps, setIntegrationApps] = useState<boolean>(
    currentPlan.meta.integrationApps ? currentPlan.meta.integrationApps : false
  );
  const [APIConfiguration, setAPIConfiguration] = useState<boolean>(
    currentPlan.meta.APIConfiguration
      ? currentPlan.meta.APIConfiguration
      : false
  );

  const [discordIntegration, setDiscordIntegration] = useState<boolean>(
    currentPlan.meta.discordIntegration
      ? currentPlan.meta.discordIntegration
      : false
  );

  const [dateRange, setDateRange] = useState(null);
  const [endDateString, setEndDateString] = useState<null | string>(
    currentPlan && currentPlan.endDate
  );
  const [endDate, setEndDate] = useState(
    currentPlan && currentPlan.endDate
      ? moment(currentPlan.endDate, "DD/MM/YYYY").toDate()
      : null
  );
  const { user, setTotalBooksCount } = useContext(UserContext);
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);

  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");

  // eslint-disable-next-line
  const [confirmationAction, setConfirmationAction] = useState<string>("");

  const [localDataOfCustomOrder, setLocalDataOfCustomOrder] = useState<any>();

  const [resetFormCustom, setResetForm] = useState<any>();

  let today = new Date();

  const validateCustomPurchaseSubmission = (formData: any) => {
    const errors: any = {};
    if (!isValidEmail(formData.email)) {
      errors.email = "Enter valid email address!";
    }
    return errors;
  };

  const resetCustomPurchaseForm = (resetForm: any) => {
    resetForm();
    setDateRange(null);
    setEndDate(null);
    setDeleteChatbotToggle(false);
    setFlashcardsToggle(false);
    setRemoveDropchatBrandingToggleToggle(false);
    setAllowPDFIndex(false);
    setAllowWebsiteIndex(false);
    setAllowYTIndex(false);
    setGpt35Turbo16k(false);
    setGpt4TurboPreview(false);
    setLeadLoginSettings(false);
    setDeleteAccount(false);
    setCustomOpenAPIKey(false);
    setShowUpdateBox(false);
    setIntegrationApps(false);
    setAPIConfiguration(false);
  };

  return (
    <MainSection fluid>
      <MainDiv>
        <DivForDltPopUp>
          <Row style={{ margin: "3rem" }}>
            <Col sm={12}>
              <Formik
                initialValues={{
                  email: email,
                  name: "",
                  noOfBooks: currentPlan.meta.noOfBooks
                    ? currentPlan.meta.noOfBooks
                    : 5,
                  messageCount: currentPlan.meta.messageCount
                    ? currentPlan.meta.messageCount
                    : 10000,
                  pdfPageSupport: currentPlan.meta.pdfPageSupport
                    ? currentPlan.meta.pdfPageSupport
                    : 100,
                  indexCharacterSupport: currentPlan.meta.indexCharacterSupport
                    ? currentPlan.meta.indexCharacterSupport
                    : 1000,
                  deleteChat: currentPlan.meta.deleteChat
                    ? currentPlan.meta.deleteChat
                    : false,
                  flashCard: currentPlan.meta.flashCard
                    ? currentPlan.meta.flashCard
                    : false,
                  removeDropChatBranding: currentPlan.meta
                    .removeDropChatBranding
                    ? currentPlan.meta.removeDropChatBranding
                    : false,
                  startDateString: "",
                  endDateString: "",
                  isOverrideActivePlan: currentPlan.meta.isOverrideActivePlan
                    ? currentPlan.meta.isOverrideActivePlan
                    : false,
                  allowPDFIndex: currentPlan.meta.allowPDFIndex
                    ? currentPlan.meta.allowPDFIndex
                    : false,
                  allowWebsiteIndex: currentPlan.meta.allowWebsiteIndex
                    ? currentPlan.meta.allowWebsiteIndex
                    : false,
                  allowYTIndex: currentPlan.meta.allowYTIndex
                    ? currentPlan.meta.allowYTIndex
                    : false,
                  gpt35Turbo16k: currentPlan.meta.gpt35Turbo16k
                    ? currentPlan.meta.gpt35Turbo16k
                    : false,
                  gpt4TurboPreview: currentPlan.meta.gpt4TurboPreview
                    ? currentPlan.meta.gpt4TurboPreview
                    : false,
                  deleteAccount: currentPlan.meta.deleteAccount
                    ? currentPlan.meta.deleteAccount
                    : false,
                  customOpenAPIKey: currentPlan.meta.customOpenAPIKey
                    ? currentPlan.meta.customOpenAPIKey
                    : false,
                  integrationApps: currentPlan.meta.integrationApps
                    ? currentPlan.meta.integrationApps
                    : false,
                  APIConfiguration: currentPlan.meta.APIConfiguration
                    ? currentPlan.meta.APIConfiguration
                    : false,
                  shopifyProductCount: currentPlan.meta.shopifyProductCount
                    ? currentPlan.meta.shopifyProductCount
                    : 0,
                  discordIntegration: currentPlan.meta.discordIntegration
                    ? currentPlan.meta.discordIntegration
                    : false,
                }}
                validate={validateCustomPurchaseSubmission}
                onSubmit={(values, { resetForm }) => {
                  setShowLoader(true);
                  //let endDateString = JSON.stringify(endD);
                  if (!endDateString || endDateString === "null") {
                    NotificationManager.error(
                      "Please select correct date range!"
                    );
                    setShowLoader(false);
                    return;
                  }
                  // created the correct data to save after all validation success with latest values
                  let dataToSave = {
                    email: values.email,
                    name: values.name,
                    meta: {
                      noOfBooks: values.noOfBooks,
                      messageCount: values.messageCount,
                      pdfPageSupport: values.pdfPageSupport,
                      indexCharacterSupport: values.indexCharacterSupport,
                      deleteChat: deleteChatbotToggle,
                      flashCard: flashcardsToggle,
                      removeDropChatBranding: removeDropchatBrandingToggle,
                      allowPDFIndex: allowPDFIndex,
                      allowWebsiteIndex: allowWebsiteIndex,
                      allowYTIndex: allowYTIndex,
                      gpt35Turbo16k: gpt35Turbo16k,
                      gpt4TurboPreview: gpt4TurboPreview,
                      leadLoginSettings: leadLoginSettings,
                      deleteAccount: deleteAccount,
                      customOpenAPIKey: customOpenAPIKey,
                      integrationApps: integrationApps,
                      APIConfiguration: APIConfiguration,
                      shopifyProductCount: values.shopifyProductCount,
                      discordIntegration: discordIntegration,
                    },
                    endDateString: endDateString,
                    planId: currentPlan._id,
                    customPlanId: currentPlan.customPlan,
                  };

                  setLocalDataOfCustomOrder({ ...dataToSave });
                  setResetForm(resetForm);

                  myAxios
                    .post(
                      process.env.REACT_APP_SERVER_URL +
                        "/custom-plans/edit-plan",
                      {
                        ...dataToSave,
                      }
                    )
                    .then((response) => {
                      if (response.data && response.data.success) {
                        setShowLoader(false);
                        trackEvent({
                          email: getUsersEmail(user),
                          eventCategory: EventCategory.PLAN,
                          event: PlanEvents.CUSTOM_PLAN_UPDATED_BY_ADMIN,
                          subCategory: SubCategory.EVENT,
                          rawData: dataToSave,
                        });
                        trackEvent({
                          email: values.email,
                          eventCategory: EventCategory.PLAN,
                          event: PlanEvents.CUSTOM_PLAN_UPDATED,
                          subCategory: SubCategory.EVENT,
                          rawData: dataToSave,
                        });
                        /*  if (response.data.data.isOverrideActivePlan) {
                          setShowUtilityConfirmationBox(true);
                          setConfirmationBoxMessage(response.data.message);
                        } else { */
                        NotificationManager.success(response.data.message);
                        getUserWithSubscriptions();
                        resetCustomPurchaseForm(resetForm);

                        //  }
                      } else {
                        setShowLoader(false);
                        NotificationManager.error(
                          response.data.message
                            ? response.data.message
                            : "Unable to process your request, please try later"
                        );
                      }
                    })

                    .catch((error) => {
                      // handle error
                      setShowLoader(false);
                      // resetForm();
                      NotificationManager.error(parseErrorResponse(error));
                    });
                }}
              >
                {({ values, errors, touched, resetForm }: any) => (
                  <BookFetchForm className={ADMIN_UPDATE_CUSTOM_PLAN}>
                    <>
                      <FormLabelText>Email</FormLabelText>
                      <FieldSection>
                        <FormInput
                          name="email"
                          placeholder="Enter email"
                          disabled
                        />
                        {errors.email && touched.email && (
                          <FormError className="formError">
                            {errors.email}
                          </FormError>
                        )}
                      </FieldSection>
                      {/* <FormLabelText>Plan Name</FormLabelText>
                  <FieldSection>
                    <FormInput name="name" placeholder="Enter plan name" />
                    {errors.name && touched.name && (
                      <FormError className="formError">{errors.name}</FormError>
                    )}
                  </FieldSection> */}
                      <FormLabelText>Plan Meta</FormLabelText>
                      <FieldColumnSection>
                        <Col sm={4}>
                          <FormLabelText>Chatbot Count</FormLabelText>
                          <FormInput
                            name="noOfBooks"
                            type="number"
                            placeholder="Enter chatbot count"
                          />
                          {errors.noOfBooks && touched.noOfBooks && (
                            <FormError className="formError">
                              {errors.noOfBooks}
                            </FormError>
                          )}
                        </Col>
                        <Col sm={4}>
                          <FormLabelText>PDF Page Limit</FormLabelText>
                          <FormInput
                            name="pdfPageSupport"
                            type="number"
                            placeholder="PDF page limit"
                          />
                          {errors.pdfPageSupport && touched.pdfPageSupport && (
                            <FormError className="formError">
                              {errors.pdfPageSupport}
                            </FormError>
                          )}
                        </Col>
                        <Col sm={4}>
                          <FormLabelText>Shopify Products Count</FormLabelText>
                          <FormInput
                            name="shopifyProductCount"
                            type="number"
                            placeholder="Shopify Products Count"
                          />
                          {errors.shopifyProductCount &&
                            touched.shopifyProductCount && (
                              <FormError className="formError">
                                {errors.shopifyProductCount}
                              </FormError>
                            )}
                        </Col>
                      </FieldColumnSection>
                      <FieldColumnSection>
                        <Col sm={6}>
                          <FormLabelText>Message Limit</FormLabelText>
                          <FormInput
                            name="messageCount"
                            type="number"
                            placeholder="Enter message limit"
                          />
                          {errors.messageCount && touched.messageCount && (
                            <FormError className="formError">
                              {errors.messageCount}
                            </FormError>
                          )}
                        </Col>

                        <Col sm={6}>
                          <FormLabelText>Index Character Limit</FormLabelText>
                          <FormInput
                            name="indexCharacterSupport"
                            type="number"
                            placeholder="Index Character Limit"
                          />
                          {errors.pdfPageSupport && touched.pdfPageSupport && (
                            <FormError className="formError">
                              {errors.pdfPageSupport}
                            </FormError>
                          )}
                        </Col>
                      </FieldColumnSection>
                      <FieldToggleSection>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                name="deleteChat"
                                checked={deleteChatbotToggle}
                                onClick={(e: any) => {
                                  setDeleteChatbotToggle(!deleteChatbotToggle);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>
                                Delete Chatbot
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                name="flashCard"
                                checked={flashcardsToggle}
                                onClick={(e: any) => {
                                  setFlashcardsToggle(!flashcardsToggle);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>
                                Flashcards
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                checked={removeDropchatBrandingToggle}
                                type="checkbox"
                                name="removeDropChatBranding"
                                onClick={(e: any) => {
                                  setRemoveDropchatBrandingToggleToggle(
                                    !removeDropchatBrandingToggle
                                  );
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                Remove Dropchat Branding
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                      </FieldToggleSection>

                      <FieldToggleSection>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                name="allowPDFIndex"
                                checked={allowPDFIndex}
                                onClick={(e: any) => {
                                  setAllowPDFIndex(!allowPDFIndex);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>
                                PDF Chatbot
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                name="allowWebsiteIndex"
                                checked={allowWebsiteIndex}
                                onClick={(e: any) => {
                                  setAllowWebsiteIndex(!allowWebsiteIndex);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>
                                Website Chatbot
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                checked={allowYTIndex}
                                type="checkbox"
                                name="allowYTIndex"
                                onClick={(e: any) => {
                                  setAllowYTIndex(!allowYTIndex);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>
                                YouTube Chatbot
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                      </FieldToggleSection>

                      <FieldToggleSection>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                checked={leadLoginSettings}
                                type="checkbox"
                                name="leadLoginSettings"
                                onClick={(e: any) => {
                                  setLeadLoginSettings(!leadLoginSettings);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>
                                Lead Login Settings
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                name="gpt4TurboPreview"
                                checked={gpt4TurboPreview}
                                onClick={(e: any) => {
                                  setGpt4TurboPreview(!gpt4TurboPreview);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>gpt-4o</FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                name="gpt35Turbo16k"
                                checked={gpt35Turbo16k}
                                onClick={(e: any) => {
                                  setGpt35Turbo16k(!gpt35Turbo16k);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>
                                gpt-3.5-Turbo-16k
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                      </FieldToggleSection>

                      <FieldToggleSection>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                name="deleteAccount"
                                checked={deleteAccount}
                                onClick={(e: any) => {
                                  setDeleteAccount(!deleteAccount);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>
                                Delete Account
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                name="integrationApps"
                                checked={integrationApps}
                                onClick={(e: any) => {
                                  setIntegrationApps(!integrationApps);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>
                                Integration Apps
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                name="APIConfiguration"
                                checked={APIConfiguration}
                                onClick={(e: any) => {
                                  setAPIConfiguration(!APIConfiguration);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>
                                API Configuration
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                      </FieldToggleSection>
                      <FieldToggleSection>
                        <Col sm={5}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                name="customOpenAPIKey"
                                checked={customOpenAPIKey}
                                onClick={(e: any) => {
                                  setCustomOpenAPIKey(!customOpenAPIKey);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>
                                Use Own OpenAPI Key
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                      </FieldToggleSection>
                      {/* <FieldToggleSection>
                        <Col sm={4}>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                name="discordIntegration"
                                checked={discordIntegration}
                                onClick={(e: any) => {
                                  setDiscordIntegration(!discordIntegration);
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                              <FormToggleLabelText>
                                Discord Integration
                              </FormToggleLabelText>
                            </>
                          </CheckBoxWrapper>
                        </Col>
                      </FieldToggleSection> */}

                      <FieldDateColumnSection>
                        <Col sm={12}>
                          <FormLabelText>End Date</FormLabelText>
                          <Calendar
                            value={endDate}
                            minDate={today}
                            dateFormat="dd/mm/yy"
                            onChange={(e: any) => {
                              setEndDate(e.value);
                              //startDate conversion
                              if (e.value === null) {
                                setEndDateString(null);
                              } else {
                                let momentStartDateConvert = moment(
                                  e.value,
                                  "DD/MM/YYYY"
                                ).toDate();
                                let momentStartDate = moment(
                                  momentStartDateConvert
                                ).format("DD/MM/YYYY");
                                setEndDateString(momentStartDate);
                              }
                            }}
                            showIcon
                          />
                        </Col>
                      </FieldDateColumnSection>
                      <Row>
                        <Col>
                          <SaveButton type="submit">Update Plan</SaveButton>
                        </Col>
                        <Col
                          style={{
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          <ResetButton
                            onClick={() => resetCustomPurchaseForm(resetForm)}
                          >
                            Cancel
                          </ResetButton>
                        </Col>
                      </Row>
                    </>
                  </BookFetchForm>
                )}
              </Formik>
            </Col>
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </MainSection>
  );
};

export default UpdateCustomPlanBox;
