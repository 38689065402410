// import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
// import DltrossSvg from "../../images/cross.svg";
// import { BookContext } from "../../context/BookContext";
// import { formattedAnswer } from "../../utils/utils";

const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 59%);
  z-index: 9999;
`;

const DivForDltPopUp = styled.div`
  position: fixed;
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 8%);
  z-index: 1111;
  width: 50% !important;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: 1199px) and (min-width: 250px) {
    width: 90% !important;
  }
`;

const LeftDiv = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  height: auto;
  overflow: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 2rem 0rem;
  & label {
    font-weight: bold;
    margin-right: 10px;
    align-self: center;
  }
  & input {
    padding: 5px;
  }
`;

const SingleSourceParent = styled.div`
  height: max-content;
  padding: 10px 0px;
  width: 100%;
  text-align: center;
  flex-direction: column;
  & img {
    display: none;
  }
  @media (max-width: 1199px) and (min-width: 250px) {
    font-size: 12px;
  }
`;

const SaveButton = styled.button`
  background: #1585b5;
  padding: 0.4rem 5rem;
  color: #fff;
  border: 0;
  width: 100%;
  @media (max-width: 1199px) and (min-width: 250px) {
    margin-bottom: 0.1rem !important;
  }
`;

const CancelButton = styled.button`
  background: #f0ad4e;
  padding: 0.4rem 5rem;
  color: #fff;
  border: 0;
  width: 100%;
`;

const FieldSection = styled.div`
  position: relative;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0.5rem;
  @media (max-width: 1199px) and (min-width: 250px) {
    padding: 0 !important;
  }
`;

const FieldColumn = styled(Col)`
  position: relative;
  @media (max-width: 1199px) and (min-width: 250px) {
    flex-direction: column !important;
  }
`;

const AgreeComponent = ({
  showAgreePopup,
  submitTerms,
  waiverSettings,
}: any) => {
  return showAgreePopup ? (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <Row>
            <LeftDiv>
              <SingleSourceParent>
                {waiverSettings.waiverText}
              </SingleSourceParent>
              <FieldColumn sm={12} style={{ justifyContent: "center" }}>
                <FieldSection>
                  <SaveButton
                    name="agree"
                    type="button"
                    onClick={() => {
                      submitTerms("agree");
                    }}
                  >
                    AGREE
                  </SaveButton>
                </FieldSection>
                <FieldSection>
                  <CancelButton
                    type="button"
                    onClick={() => submitTerms("cancel")}
                  >
                    CANCEL
                  </CancelButton>
                </FieldSection>
              </FieldColumn>
            </LeftDiv>
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </>
  ) : (
    <></>
  );
};

export default AgreeComponent;
