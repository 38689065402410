import queryString from "query-string";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { JETLIST_MAGIC_CRED } from "./consts";
import { UserContext } from "./context/UserContext";
import { magic } from "./magic";
import { setAuthHeader } from "./myaxios";
import { isTestUser, mixPanel } from "./utils/utils";
import { getDeviceInfoForTrackEvent } from "./event-utils/dropchat-event-track";
// import Loader from "../components/Loader";
// import momentTZ from "moment-timezone";

const CallbackSocial = ({ loadingState }: any) => {
  // console.log("callback page");
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  async function validatedAndSaveToken(didToken: string | null) {
    loadingState(true);
    const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: didToken,
        deviceInfo: await getDeviceInfoForTrackEvent(),
        // timeZone: momentTZ.tz.guess(),
      }),
    });

    if (res.status === 200) {
      const data = await res.json();
      // Set the UserContext to the now logged in user
      let userMetadata = await magic.user.getMetadata();

      setUser({ ...data });
      if (!isTestUser(data.email))
        mixPanel(data.email, "Logged In Magic Link", "Magic Link Form");
      if (userMetadata) {
        navigate("/my-chatbots/create-pdf-chatbot");
      }
      loadingState(false);

      if (data.jwt) setAuthHeader(data.jwt);
    } else {
      loadingState(false);
    }
  }

  // useEffect(() => {
  //   if (!user) {
  //     navigate("/login");
  //   }
  // }, [navigate]);

  useEffect(() => {
    loadingState(true);
    const localUser = localStorage.getItem("user");

    // console.log("localUser --- ", localUser);

    const user = localUser ? JSON.parse(localUser) : undefined;

    if (user && user.jwt) {
      setUser({ ...user });
      if (user.jwt) setAuthHeader(user.jwt);

      // console.log("Jsut before return at home from call back");

      navigate("/my-chatbots/create-pdf-chatbot");
    } else {
      const queryParams: any = queryString.parse(window.location.search);

      // console.log("queryParams is, ", queryParams);

      if (queryParams && queryParams.magic_credential) {
        localStorage.setItem(JETLIST_MAGIC_CRED, queryParams.magic_credential);
      }

      magic.oauth
        .getRedirectResult()
        .then((response) => {
          // console.log(response);
          validatedAndSaveToken(response.magic.idToken);
        })
        .catch((error) => {
          // console.log("error occurred", error);
        });
    }
    // eslint-disable-next-line
  }, []);

  return <div>{/* <Loader text={"Verifying credentials..."} /> */}</div>;
};

export default CallbackSocial;
