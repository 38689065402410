import { Field, useFormikContext } from "formik";
import { Tooltip } from "primereact/tooltip";
import { useContext, useEffect, useRef } from "react";
import { Col, FormLabel, Row } from "react-bootstrap";
import Slider from "react-rangeslider";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import { UserContext } from "../../../context/UserContext";
import { PlanContext } from "../../../context/plan-context";
import GuideIcon from "../../../images/guide-question.svg";
import {
  CUSTOM_ERROR_RESP,
  QA_PROMPT,
} from "../../../utils/place-holder-consts";

const SidebarHeading = styled.h3`
  color: #33303c;
  font-size: 16px;
  margin-bottom: 1rem;
  font-weight: 600;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const FormLabelNew = styled(FormLabel)`
  font-weight: 600;
  font-size: 14px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const UpgradeSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 5px 25px;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    padding: 5px 20px;
  }
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;

const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;

const TEMPRATURE_HELP = `
Temperature is a hyperparameter that controls the randomness of the generated text. It adjusts the probability distribution of the next word in the generated text. A higher temperature value (e.g., 0.8) makes the output more random and creative, while a lower temperature value (e.g., 0.2) makes the output more deterministic and focused.

For example, with a high temperature, the model is more likely to choose less common words and phrases, leading to more varied and creative responses. With a low temperature, the model is more likely to choose the most probable next word, resulting in more conservative and repetitive responses.
`;

const TOPP_HELP = `
Top-p (Nucleus Sampling):

Top-p, also known as nucleus sampling, is another way to control the randomness of generated text. Instead of specifying a fixed number of options, it considers the top percentage of the most likely next words.

With top-p sampling, you specify a percentage (e.g., 0.7) and the model dynamically chooses from the most likely words until the cumulative probability exceeds that percentage. This means the number of words considered can vary depending on the probabilities of the words in the distribution.

Top-p sampling can produce more focused and coherent responses by considering a narrower set of likely words, which can be particularly useful when you want to avoid generating overly creative or irrelevant text
`;

const TextValueField = styled(Field)`
  /* width: 380px; */
  width: 56px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  margin: 0px 0px 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;
const FieldSectionRadio = styled.div`
  flex-direction: column;
  & label {
    width: 100%;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  & input {
    width: auto;
    margin-top: 0.6rem;
    margin-right: 0.4rem;
    cursor: pointer;
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    & label {
      width: 30%;
    }
  }
`;

const InputField = styled(Field)`
  width: 100%;
  padding: 10px;
  background: #eaeaea;
  border: 1px solid #eaeaea !important;
  display: inline;
  /* margin-bottom: 0.5rem; */
  border-radius: 4px;
  /* margin-right: 5px; */
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    border-radius: 4px;
    /* margin-bottom: 0.5rem; */
  }
`;

const ColForRadioButton = styled(Col)`
  justify-content: space-between;
  margin-bottom: 5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const ColForSliderText = styled(Col)`
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: flex;
    flex-direction: column;
  }
`;

const ColForChatMemoryToggle = styled(Col)`
  justify-content: flex-end;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: end;
  }
`;

const ColForRadioInputCom = styled(Col)`
  align-items: center;
`;

const TextAREANew = styled.textarea`
  border-radius: 4px;
  border: 1px solid #eaeaea;
  background: linear-gradient(0deg, #eaeaea, #eaeaea),
    linear-gradient(0deg, #f8f7fa, #f8f7fa);
  padding: 10px;
  font-size: 14px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
  }
`;

const ChatbotSetting = ({
  bookSettings,
  selectedBook,
  tempretureValue,
  setTempretureValue,
  topPValue,
  setTopPValue,
}: any) => {
  const isMobile = window.innerWidth <= MOBILE.maxWidth;
  const navigate = useNavigate();
  const formik = useFormikContext();
  const { values, errors, touched } = useFormikContext<any>();
  const { user } = useContext(UserContext);
  const { setPlanHighliter } = useContext(PlanContext);

  const useAutosizeTextArea = (
    textAreaRef: HTMLTextAreaElement | null,
    value: string,
    extraHeight: number = 0
  ) => {
    useEffect(() => {
      if (textAreaRef) {
        textAreaRef.style.height = "0px";
        const scrollHeight = textAreaRef.scrollHeight + extraHeight;
        textAreaRef.style.height = scrollHeight + "px";
      }
    }, [textAreaRef, value, extraHeight]);
  };

  const textAreaRef1 = useRef<HTMLTextAreaElement>(null);
  const textAreaRef2 = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(
    textAreaRef1.current,
    bookSettings && bookSettings.qaTemplate,
    50
  );
  useAutosizeTextArea(
    textAreaRef2.current,
    bookSettings && bookSettings.customAnswer,
    50
  );

  return (
    <>
      <SidebarHeading>Chatbot Settings</SidebarHeading>

      <FieldSection>
        <FormLabelNew>My Chatbots Title</FormLabelNew>
        <InputField
          style={{ marginBottom: "0.5rem" }}
          type="text"
          name="bookSetting.title"
          value={values.bookSetting && values.bookSetting.title}
          onChange={(e: any) =>
            formik.setFieldValue("bookSetting.title", e.target.value)
          }
        />
      </FieldSection>
      <FormLabelNew>Chat Model</FormLabelNew>
      <FieldSectionRadio>
        {/* <ColForRadioButton>
          <ColForRadioInputCom>
            <InputField
              type="radio"
              name="bookSetting.model"
              value="gpt-3.5-turbo"
            />{" "}
            gpt-3.5-turbo
          </ColForRadioInputCom>
        </ColForRadioButton> */}
        <ColForRadioButton>
          <ColForRadioInputCom>
            <InputField
              type="radio"
              name="bookSetting.model"
              value="gpt-4o-mini"
            />{" "}
            gpt-4o-mini
          </ColForRadioInputCom>
        </ColForRadioButton>

        {user?.currentSubscription ? (
          user?.currentSubscription.meta?.gpt4TurboPreview ? (
            <ColForRadioButton>
              <ColForRadioInputCom>
                <InputField
                  type="radio"
                  name="bookSetting.model"
                  value="gpt-4o"
                />{" "}
                gpt-4o
              </ColForRadioInputCom>
            </ColForRadioButton>
          ) : (
            <ColForRadioButton>
              <ColForRadioInputCom>
                <InputField
                  type="radio"
                  name="bookSetting.model"
                  value="gpt-4o"
                  disabled
                />{" "}
                gpt-4o
              </ColForRadioInputCom>
              <div>
                <UpgradeSpan
                  onClick={(e) => {
                    setPlanHighliter({
                      isBookHighliter: false,
                      bookLimit: 0,
                      isDeleteBookHighliter: false,
                      isFlashcardHighliter: false,
                      isCharacterLimit: false,
                      pdfLimit: 0,
                      isDeleteAccount: false,
                      isLeadLoginSettings: false,
                      isGpt35Turbo16k: false,
                      isGpt4TurboPreview: true,
                    });
                    navigate("/subscription");
                  }}
                >
                  Upgrade Plan
                </UpgradeSpan>
                {/* <PlanSpan>Your plan</PlanSpan> */}
              </div>
            </ColForRadioButton>
          )
        ) : (
          <ColForRadioButton>
            <ColForRadioInputCom>
              <InputField
                type="radio"
                name="bookSetting.model"
                value="gpt-4o"
                disabled
              />{" "}
              gpt-4o
            </ColForRadioInputCom>
            <div>
              <UpgradeSpan
                onClick={(e) => {
                  setPlanHighliter({
                    isBookHighliter: false,
                    bookLimit: 0,
                    isDeleteBookHighliter: false,
                    isFlashcardHighliter: false,
                    isCharacterLimit: false,
                    pdfLimit: 0,
                    isDeleteAccount: false,
                    isLeadLoginSettings: false,
                    isGpt35Turbo16k: false,
                    isGpt4TurboPreview: true,
                  });
                  navigate("/subscription");
                }}
              >
                Purchase Plan
              </UpgradeSpan>
            </div>
          </ColForRadioButton>
        )}
        <br />
      </FieldSectionRadio>
      {selectedBook && selectedBook.type !== "book" && (
        <>
          <FieldSection>
            <Tooltip style={{ width: "300px" }} target=".custom-target-icon" />
            <FormLabelNew>
              Temperature{" "}
              <i
                className="custom-target-icon pi  p-text-secondary p-overlay-badge"
                data-pr-tooltip={TEMPRATURE_HELP}
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
                style={{ fontSize: "1rem", cursor: "pointer" }}
              >
                <img alt="Guide Icon" src={GuideIcon} />
              </i>
            </FormLabelNew>
            <ColForSliderText>
              <div className={isMobile ? "" : "slider"}>
                <Slider
                  max={1}
                  min={0}
                  step={0.05}
                  tooltip={true}
                  value={tempretureValue}
                  onChange={(value) => {
                    setTempretureValue(
                      Number(parseFloat(`${value}`).toFixed(1))
                    );

                    formik.setFieldValue(
                      `bookSetting.temperature`,
                      parseFloat(`${value}`).toFixed(1)
                    );
                  }}
                />
              </div>

              <TextValueField
                type="number"
                min={0}
                max={1}
                step="0.05"
                presicion={1} //very important
                novalidate
                name="bookSetting.temperature"
                onChange={(e: any) => {
                  let { value, min, max } = e.target;

                  const maxLimit = Number(1);

                  if (value < 0 || value > maxLimit)
                    value = Math.max(
                      Number(min),
                      Math.min(Number(max), Number(value))
                    );

                  setTempretureValue(value);

                  //  setTempretureValue(value);
                  formik.setFieldValue(`bookSetting.temperature`, value);
                }}
              />
            </ColForSliderText>
            {errors.temperature && touched.temperature && (
              <FormError className="formError">
                {/* {errors.temperature} */}
              </FormError>
            )}
          </FieldSection>

          <FieldSection>
            <Tooltip
              style={{ width: "400px" }}
              target=".custom-target-icon-1"
            />
            <FormLabelNew>
              Top P{" "}
              <i
                className="custom-target-icon-1 pi  p-text-secondary p-overlay-badge"
                data-pr-tooltip={TOPP_HELP}
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
                style={{ fontSize: "1rem", cursor: "pointer" }}
              >
                <img alt="Guide Icon2" src={GuideIcon} />
              </i>
            </FormLabelNew>
            <ColForSliderText>
              <div className={isMobile ? "" : "slider"}>
                <Slider
                  max={1}
                  min={0}
                  step={0.05}
                  tooltip={true}
                  value={topPValue}
                  onChange={(value) => {
                    // console.log("value -- ", value);

                    setTopPValue(Number(parseFloat(`${value}`).toFixed(2)));

                    formik.setFieldValue(
                      `bookSetting.topP`,
                      parseFloat(`${value}`).toFixed(2)
                    );
                  }}
                />
              </div>

              <TextValueField
                type="number"
                min={0}
                max={1}
                step="0.05"
                presicion={2} //very important
                novalidate
                name="bookSetting.topP"
                onChange={(e: any) => {
                  let { value, min, max } = e.target;

                  const maxLimit = Number(1);

                  if (value < 0 || value > maxLimit)
                    value = Math.max(
                      Number(min),
                      Math.min(Number(max), Number(value))
                    );

                  setTopPValue(value);

                  formik.setFieldValue(`bookSetting.topP`, value);
                }}
              />
            </ColForSliderText>
            {errors.topP && touched.topP && (
              <FormError className="formError">{/* {errors.topP} */}</FormError>
            )}
          </FieldSection>
          <Row>
            <Col sm={7} style={{ flexDirection: "column" }}>
              <FormLabelNew>Allow chatbot memory</FormLabelNew>
            </Col>
            <ColForChatMemoryToggle sm={5}>
              <CheckBoxWrapper style={{ marginTop: isMobile ? "-25px" : "" }}>
                <>
                  <CheckBox
                    id="checkbox"
                    checked={values.bookSetting.allowMemory}
                    type="checkbox"
                    name="bookSetting.allowMemory"
                    onChange={(e: any) => {
                      formik.setFieldValue(
                        `bookSetting.allowMemory`,
                        e.target.checked
                      );
                    }}
                  />
                  <CheckBoxLabel htmlFor="checkbox" />
                </>
              </CheckBoxWrapper>
            </ColForChatMemoryToggle>
          </Row>

          <FieldSection>
            <FormLabelNew>Custom Prompt</FormLabelNew>
            <TextAREANew
              id="review-text"
              ref={textAreaRef1}
              // style={{ height: "100px" }}
              // style={{ height: scrollHeight }}
              name="bookSetting.qaTemplate"
              defaultValue={
                bookSettings &&
                bookSettings.qaTemplate &&
                bookSettings.qaTemplate.length > 0
                  ? bookSettings.qaTemplate
                  : QA_PROMPT
              }
              onChange={(e: any) => {
                formik.setFieldValue("bookSetting.qaTemplate", e.target.value);
              }}
            ></TextAREANew>
          </FieldSection>
        </>
      )}
      <FieldSection>
        <FormLabelNew>Custom Error Response</FormLabelNew>
        <TextAREANew
          // style={{ height: "100px" }}
          ref={textAreaRef2}
          name="bookSetting.customAnswer"
          defaultValue={
            bookSettings && bookSettings.customAnswer
              ? bookSettings.customAnswer
              : CUSTOM_ERROR_RESP
          }
          onChange={(e: any) => {
            formik.setFieldValue("bookSetting.customAnswer", e.target.value);
          }}
        ></TextAREANew>
      </FieldSection>
    </>
  );
};
export default ChatbotSetting;
