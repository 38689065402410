import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { LoaderContext } from "../../context/loader-context";
import EditIcon from "../../images/edit-icon.png";
import UrlIcon from "../../images/link-icon.svg";
import DeleteIcon from "../../images/trash.svg";
import myAxios from "../../myaxios";
import useDebounce from "../../use-debounce";
import ConfirmationBox from "../utility-component/confirmation-box";
import UpdateCustomPlanBox from "./update-custom-plan-box";
import GeneratePaymentLink from "./generate-payment-link-box";
import { PlanContext } from "../../context/plan-context";
import { SubscriptionPlan } from "../../types";

const DataTableColumn = styled(Col)`
  flex-direction: column;
  & td {
    padding: 0.4rem 1rem !important;
  }
`;

const UserSubscriptionsHistory = () => {
  const { plansList } = useContext(PlanContext);
  const [userSubscriptionHistoryData, setUserSubscriptionHistoryData] =
    useState<any>([]);
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  const [showUpdateBox, setShowUpdateBox] = useState<boolean>(false);
  const [showGeneratePaymentLinkBox, setShowGeneratePaymentLinkBox] =
    useState<boolean>(false);
  const [confirmationAction, setConfirmationAction] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<any>();

  const { setShowLoader } = useContext(LoaderContext);
  const [emailValue, setEmailValue] = useState("");

  const [customMessage, setCustomMessage] = useState(
    "Do you really want to delete custom plan?"
  );

  useDebounce(
    () => {
      getUserWithSubscriptions();
      // eslint-disable-next-line
    },
    [emailValue],
    750
  );

  const getUserWithSubscriptions = () => {
    setShowLoader(true);
    myAxios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/admin-settings/subscription/get-subcription-details`,
        { email: emailValue }
      )
      .then((response) => {
        if (response.data && response.data.success) {
          setUserSubscriptionHistoryData(response.data.data);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        // setLoading(false);
        // console.error(
        //   "Gettings error at the time of updating status of book ",
        //   error
        // );
        setShowLoader(false);
      });
  };

  const confirmClick = (action: string) => {
    if (action === "delete") {
      deleteCustomPlan();
    }
  };

  const deleteCustomPlan = () => {
    setShowUtilityConfirmationBox(false);

    setShowLoader(true);
    myAxios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/delete-custom-plans/${selectedUser.currentPlan._id}/${selectedUser.currentPlan.customPlan}`
      )
      .then((response) => {
        NotificationManager.success(
          response && response.data && response.data.message
            ? response.data.message
            : "Subscription deleted successfully."
        );

        setSelectedUser(undefined);
        getUserWithSubscriptions();
      })
      .catch((error) => {
        // setLoading(false);
        // console.error(
        //   "Gettings error at the time of updating status of book ",
        //   error
        // );
        setShowLoader(false);
      });
  };

  const submitSearch = () => {
    setShowLoader(true);
    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "/admin-settings/subscription/get-subcription-details",
        {
          email: emailValue,
        }
      )
      .then((response) => {
        // console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          setUserSubscriptionHistoryData(response.data.data);
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      })
      .catch((error) => {
        // handle error
        setShowLoader(false);
      });
  };

  const specialPlan = (data: any) => {
    return (
      <>
        <img
          style={{ cursor: "pointer" }}
          src={UrlIcon}
          alt={"Edit"}
          className="w-4rem"
          onClick={() => {
            setSelectedUser(data);
            setShowGeneratePaymentLinkBox(true);
          }}
        />
      </>
    );
  };

  const editBody = (data: any) => {
    const isCustomPlan =
      data && data.currentPlan && data.currentPlan.isCustomPlan;

    return isCustomPlan ? (
      <>
        <img
          style={{ cursor: "pointer" }}
          src={EditIcon}
          alt={"Edit"}
          className="w-4rem"
          onClick={() => {
            setSelectedUser(data);
            setShowUpdateBox(true);
          }}
        />
      </>
    ) : (
      <></>
    );
  };

  const deleteBody = (data: any) => {
    const isCustomPlan =
      data && data.currentPlan && data.currentPlan.isCustomPlan;

    return isCustomPlan ? (
      <>
        <img
          style={{ cursor: "pointer" }}
          src={DeleteIcon}
          alt={"Delete"}
          className="w-4rem"
          onClick={() => {
            setCustomMessage(
              `Do you really want to delete custom plan for ${data.email} ?`
            );
            setSelectedUser(data);
            setConfirmationAction("delete");
            setShowUtilityConfirmationBox(true);
          }}
        />
      </>
    ) : (
      <></>
    );
  };

  const currentPlanBody = (data: any) => {
    const planName =
      data && data.currentPlan && data.currentPlan.name
        ? data.currentPlan.name
        : "No Active Plan";
    return planName;
  };

  return (
    <>
      <Row style={{ marginTop: "1rem" }}>
        <DataTableColumn sm={12}>
          <div
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <h5>User Subscriptions History</h5>
            <div style={{ width: "60%", justifyContent: "flex-end" }}>
              <input
                type="text"
                onChange={(e) => setEmailValue(e.target.value)}
                placeholder="Type here to search by email"
                style={{ width: "40%" }}
              />
              {/*  <img
                src={searchbar}
                alt="search"
                style={{ cursor: "pointer" }}
                onClick={() => submitSearch()}
              /> */}
            </div>
          </div>
          <div className="userSubscriptionsHistoryTable">
            <DataTable
              showGridlines
              value={userSubscriptionHistoryData}
              paginator
              rows={25}
              rowsPerPageOptions={[25, 50, 100, 200]}
            >
              <Column field="email" header="User Email"></Column>
              <Column header="Current Plan" body={currentPlanBody}></Column>
              <Column
                field="currentPlan.meta.noOfBooks"
                header="Allowed Total Books"
              ></Column>
              <Column
                field="currentPlan.planStats.bookCount"
                header="Book Used"
              ></Column>
              <Column
                field="currentPlan.meta.messageCount"
                header="Allowed Total Message"
              ></Column>
              <Column
                field="currentPlan.planStats.usedMessage"
                header="Message Used"
              ></Column>
              <Column
                header="Special Plan"
                body={specialPlan}
                // style={{ width: "16rem", padding: "0.2rem" }}
              ></Column>
              <Column header="Edit" body={editBody}></Column>
              <Column header="Delete" body={deleteBody}></Column>
            </DataTable>
          </div>
        </DataTableColumn>
        {showUtilityConfirmationBox && (
          <ConfirmationBox
            setShow={setShowUtilityConfirmationBox}
            name="Confirmation"
            message={customMessage}
            okButtonText="Yes"
            action={confirmationAction}
            okButtonClick={confirmClick}
          />
        )}

        {showUpdateBox && (
          <UpdateCustomPlanBox
            setShowUpdateBox={setShowUpdateBox}
            currentPlan={selectedUser && selectedUser.currentPlan}
            email={selectedUser && selectedUser.email}
            getUserWithSubscriptions={getUserWithSubscriptions}
          />
        )}
        {showGeneratePaymentLinkBox && (
          <GeneratePaymentLink
            setShowGeneratePaymentLinkBox={setShowGeneratePaymentLinkBox}
            specialPlansList={
              plansList &&
              plansList.length > 0 &&
              plansList.filter((plan: SubscriptionPlan) => plan.meta.isSpecial)
            }
            selectedCustomerID={selectedUser && selectedUser.stripeCustomerId}
          />
        )}
      </Row>
    </>
  );
};

export default UserSubscriptionsHistory;
