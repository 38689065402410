import { Row, Modal, Button, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useRef, useState } from "react";
// import { DropArea } from "scorer-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import styled from "styled-components";
// import UploadHover from "../assets/upload_hover.svg";
// import Upload from "../assets/upload.svg";

const InputField = styled.input`
  height: 46px;
  width: 100%;
  padding: 0.5rem;
  background: #eaeaea;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin-bottom: 1rem;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const ImageOverlayPara = styled.p`
  position: absolute;
  margin: auto;
  top: 36%;
  background: rgba(0, 0, 0, 0.32);
  padding: 10px 50px;
  width: 100%;
  text-align: center;
  color: rgb(255, 255, 255);
`;

function ImageCrop({
  showSeoMetaOption,
  setIsSmallImage,
  imageToShow,
  setSelectedFile,
}) {
  const [srcImg, setSrcImg] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 2 / 1,
    x: 37,
    y: 37,
  });
  const [result, setResult] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const inputPic = useRef(null);

  const handleImage = async (event) => {
    // setSrcImg(null);
    const file = event.target.files[0];
    // console.log(file, "file");
    const files = URL.createObjectURL(file);
    // console.log(files, "files");
    setSrcImg(files);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  const getCroppedImg = async () => {
    try {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/png", 1);
      var newFile = dataURLtoFile(base64Image, "image/png");
      const img = new Image();
      img.src = base64Image;
      img.onload = function () {
        // console.log(this.width);
        if (this.width && this.height < 200) {
          setIsSmallImage(true);
        } else {
          setIsSmallImage(false);
        }
      };
      setResult(base64Image);

      setSelectedFile(newFile);
      setShowModal(false);
    } catch (e) {}
  };

  const handleUpload = () => {
    inputPic.current.value = "";
    inputPic.current.click();
  };
  // const fileTypes = ["jpg", "png", "jpeg"];
  return (
    <Col md={12} style={{ padding: 0 }}>
      {/* <FileUploader name="file" handleChange={handleChange} types={fileTypes} /> */}

      <div style={{ flexDirection: "column", flex: 1 }}>
        {!result && !imageToShow ? (
          <InputField
            type="file"
            ref={inputPic}
            name="file"
            id="file"
            accept=".png, .jpg, .jpeg"
            style={{ marginBottom: "0.5rem" }}
            onChange={handleImage}
            disabled={!showSeoMetaOption}
          />
        ) : (
          <div style={{ position: "relative", cursor: "pointer" }}>
            <InputField
              type="file"
              ref={inputPic}
              name="file"
              id="file"
              accept=".png, .jpg, .jpeg"
              style={{ display: "none" }}
              onChange={handleImage}
            />
            <img
              src={result || imageToShow}
              alt="upload"
              width="100%"
              onClick={handleUpload}
              style={{ alignSelf: "center", marginBottom: "0.5rem" }}
            />
            <ImageOverlayPara onClick={handleUpload}>
              Click here to change
            </ImageOverlayPara>
          </div>
        )}
      </div>
      <div>
        {srcImg && (
          <div>
            <Modal
              backdrop="static"
              size="md"
              show={showModal}
              onHide={hideModal}
              className="main-modal-logout crop-modal"
              style={{ marginTop: "0%" }}
            >
              <div className="flex">
                <div className="arrow-up"></div>
              </div>
              <Modal.Body className="modalContainer">
                <Row>
                  <ReactCrop
                    style={{ marginBottom: "20px" }}
                    src={srcImg}
                    onImageLoaded={setImage}
                    crop={crop}
                    onChange={setCrop}
                  />
                  <Button className="publish-btn" onClick={getCroppedImg}>
                    Crop
                  </Button>
                </Row>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </div>
    </Col>
  );
}

export default ImageCrop;
