import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, FormLabel } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { Document } from "react-pdf";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MEDIUM, MOBILE, TAB } from "../../../constants/breakpoints";
import { BookContext } from "../../../context/BookContext";
import { UserContext } from "../../../context/UserContext";
import { LoaderContext } from "../../../context/loader-context";
import { PlanContext } from "../../../context/plan-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../../event-utils/dropchat-event-track";
import { trackEvent } from "../../../event-utils/track-event";
import PDFIcon from "../../../images/pdf-icon.svg";
import DeleteIcon from "../../../images/trash.svg";
import myAxios from "../../../myaxios";
import { ADD_PDF_SOURCE } from "../../../utils/class-names";
import { parseErrorResponse } from "../../../utils/utils";
import IndexCharacterLimitPopUp from "../../index-character-limit-popup";
import UploadComponent from "../../utility-component/drag-drop-component";

const ParentDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const EmptyDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;
const SubscriptionExpireOverlay = styled.div`
  position: absolute;
  height: 50vh;
  width: 50vw;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;

  @media (max-width: ${MOBILE.maxWidth}px) {
    position: fixed;
    bottom: 25vh;
    width: 80vw;
    right: 10vw;
  }
`;

const ParentSection = styled.div`
  width: 100%;
  height: 78vh;
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: 75vh;
  }
`;

const InnerSection = styled.div`
  background: #f8f7fa;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
`;

const ChatbotSettingsSection = styled(Form)`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  & label {
    color: #33303c;
    font-weight: 600;
  }
`;
interface SaveButtonProps {
  displayProp?: boolean; // Declare the custom prop and its type
}
const SaveButton = styled.button<SaveButtonProps>`
  background: #1585b5;
  padding: 0.4rem 3rem;
  color: #fff;
  border: 1px solid transparent;
  width: auto;
  display: flex;
  margin-top: auto;
  justify-content: center;
  display: ${(props) => (props.displayProp ? " flex" : " none")};
  &:disabled {
    display: ${(props) => (props.displayProp ? " flex" : " none")};
    opacity: ${(props) => (props.displayProp ? " 0.5" : " 0")};
    cursor: not-allowed;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 96vw;
    margin-top: 0.2rem;
  }
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-bottom: 0;
    padding: 0.5rem;
  }
`;

const FooterSection = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px #0000001a;
  padding: 0.5rem;
  margin-top: auto;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    justify-content: space-between;
    flex-direction: column-reverse;
  }
`;
const CancelButton = styled.button`
  background: none;
  padding: 0.4rem 3rem;
  color: #2f3349;
  border: 1px solid #2f3349;
  width: auto;
  display: flex;
  margin-top: auto;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const SelectedSourceDetails = styled.div`
  flex-direction: row;
  padding: 1rem;
  border-bottom: 1px solid #eaeaea;
  & span {
    margin: 0rem 5rem 0rem 1rem;
    font-weight: normal;
    color: #33303c;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem 0rem;
  }
`;

const ImageTag = styled.img`
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 21px;
  }
`;
const FooterInnerDiv = styled.div`
  align-items: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: flex;
    flex-direction: column;
    // align-items: end;
  }
  @media (min-width: ${TAB.minWidth}px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    flex-direction: row;
  }
`;
const UpgradeSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 5px 25px;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
`;

const PlanSpan = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #585858;
  margin-left: 5px;
  margin-top: 1px;
  display: flex;
  align-items: center;
`;

const InnerDiv = styled.div`
  height: 100%;
  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column;
  }

  @media (min-width: ${TAB.minWidth}px) {
    flex-direction: column;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    flex-direction: row;
  }
`;
const FormLabelText = styled(FormLabel)`
  font-size: 14px;
  /* @media (max-width: ${MOBILE.maxWidth}px) {
  }
  @media (min-width: ${TAB.minWidth}px) {
  } */
`;
const Column = styled(Col)`
  padding: 1rem;
  border-left: 1px solid #2f334933;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0rem 0.5rem;
  }
  @media (min-width: ${TAB.minWidth}px) {
    border-left: none;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    border-left: 1px solid #2f334933;
  }
`;
const TextSpan = styled.span`
  font-size: 14px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const DeleleDiv = styled.div`
  font-size: 14px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
`;
const SelectedCount = styled.span`
  color: #000;
  font-size: 13px;
  font-weight: 500;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: space-around !important;
    display: flex;
    font-size: 13px;
    padding-right: "0rem";
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: space-around !important;
    display: flex;
    font-size: 11px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    color: #000;
    font-size: 13px;
    font-weight: bold;
    padding-left: 1rem;
    align-items: center;
    display: flex;
  }
`;
const CustomButton = styled.button`
  background: #f2ae01;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 15px;
  margin-left: 1rem;
  &:disabled {
    display: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: 0;
    padding: 0.5rem;
    font-size: 13px;
    width: 96vw;
    margin-top: 0.2rem;
  }
`;

interface fileWithCharacter {
  file: File;
  characterCount: number;
  characterCountDone: boolean;
}

const AddPdfSourceNew = ({ setShowAddNewSourcePage }: any) => {
  const navigate = useNavigate();
  const { setPlanHighliter } = useContext(PlanContext);
  const { user, updateUserSubscriptionIndexCount } = useContext(UserContext);
  const { setShowLoader } = useContext(LoaderContext);
  const { selectedBook, updateBookIndex } = useContext(BookContext);
  const [fileObject, setFileObject] = useState<fileWithCharacter[]>([]);
  const [totalPdfCharacterCount, setTotalPdfCharacterCount] = useState(0);
  const [disablePdfIndexLimit, setDisablePdfIndexLimitState] =
    useState<boolean>(false);
  const [showLimitPopUp, setShowLimitPopUp] = useState<boolean>(false);

  const onLoadSuccess = async (pdf: any, file: fileWithCharacter) => {
    if (file.characterCountDone) {
      return;
    }
    setShowLoader(true);
    const numPages = pdf.numPages;
    let extractedText = "";
    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map((item: any) => item.str).join(" ");
      extractedText += pageText;
    }

    setTotalPdfCharacterCount(
      (totalPdfCharacterCount) => totalPdfCharacterCount + extractedText.length
    );

    setFileObject((prevFileObjects: fileWithCharacter[]) => {
      const updatedArray = prevFileObjects.map((fileObject) => {
        if (fileObject.file === file.file) {
          return {
            ...fileObject,
            characterCount: extractedText.length,
            characterCountDone: true,
          };
        }
        return fileObject;
      });

      return updatedArray;
    });
    setShowLoader(false);
  };

  useEffect(() => {
    const indexingCharacterLimitCount = Math.max(
      (user?.currentSubscription?.meta?.indexCharacterSupport
        ? user?.currentSubscription?.meta?.indexCharacterSupport
        : 0) -
        (user?.currentSubscription?.indexCharacterUsedTotal
          ? user?.currentSubscription?.indexCharacterUsedTotal
          : 0),
      0
    );
    if (totalPdfCharacterCount > indexingCharacterLimitCount) {
      setDisablePdfIndexLimitState(true);
    } else {
      setDisablePdfIndexLimitState(false);
    }
  }, [totalPdfCharacterCount, setTotalPdfCharacterCount]);
  return (
    <ParentSection>
      {fileObject &&
        fileObject.length > 0 &&
        fileObject.map((object: fileWithCharacter, index: number) => (
          <div style={{ display: "none" }}>
            <Document
              file={object && object.file}
              onLoadSuccess={(pdf) => onLoadSuccess(pdf, object)}
            ></Document>
          </div>
        ))}

      <Col sm={12} xs={12}>
        <InnerSection>
          {/* <SidebarHeading>Chatbot Settings</SidebarHeading> */}
          <Formik
            initialValues={{}}
            onSubmit={(values) => {
              const data = new FormData();
              fileObject.forEach((file: fileWithCharacter, index: number) => {
                data.append(`files.file${index + 1}`, file.file);
              });
              data.append("data", JSON.stringify([]));

              setShowLoader(true);
              myAxios
                .post(
                  process.env.REACT_APP_SERVER_URL +
                    `/books/add-source/${selectedBook?._id}`,
                  data
                )
                .then((response) => {
                  // console.log("response is , ", response.data);
                  if (response.data && response.data.success) {
                    trackEvent({
                      email: getUsersEmail(user),
                      eventCategory: EventCategory.CHATBOT,
                      event:
                        ChatBotEvents.USER_ADDED_NEW_DATASOURCE_IN_EXISTING_CHATBOT,
                      subCategory: SubCategory.EVENT,
                      chatbotType: response.data.data.fileType,
                      bookId: response.data.data._id,
                      rawData: {
                        addedSourceType: "PDF",
                      },
                    });

                    NotificationManager.success(
                      response.data.message
                        ? response.data.message
                        : "Source saved successfully."
                    );
                    updateBookIndex(response.data.data);
                    setShowAddNewSourcePage(false);
                    setShowLoader(false);
                    updateUserSubscriptionIndexCount(
                      (user?.currentSubscription?.indexCharacterUsedTotal
                        ? user?.currentSubscription?.indexCharacterUsedTotal
                        : 0) + totalPdfCharacterCount
                    );
                  } else {
                    setShowLoader(false);
                    NotificationManager.error(
                      response.data.message
                        ? response.data.message
                        : "Unable to process your request, please try later"
                    );
                  }
                })
                .catch((error) => {
                  // handle error
                  setShowLoader(false);
                  NotificationManager.error(parseErrorResponse(error));
                });
            }}
          >
            {({ errors, touched, isValidating, setFieldValue }: any) => (
              <ChatbotSettingsSection className={ADD_PDF_SOURCE}>
                <InnerDiv>
                  <Col md={12} xs={12} sm={12} lg={4}>
                    <FieldSection>
                      <UploadComponent
                        setFieldValue={setFieldValue}
                        fileObject={fileObject}
                        setFileObject={setFileObject}
                      />
                    </FieldSection>
                  </Col>
                  <Column sm={12} xs={12} md={12} lg={8}>
                    <Col sm={12}>
                      <FormLabelText>Included Files</FormLabelText>
                    </Col>
                    <Col sm={12} style={{ flexDirection: "column" }}>
                      {fileObject && fileObject.length > 0 ? (
                        fileObject.map(
                          (object: fileWithCharacter, index: number) => {
                            return (
                              <SelectedSourceDetails>
                                <ImageTag src={PDFIcon} alt="pdf" />
                                <Col style={{ flexDirection: "column" }}>
                                  <TextSpan>{object.file.name}</TextSpan>
                                  <TextSpan
                                    style={{
                                      color: "grey",
                                      fontSize: "10px",
                                      paddingLeft: ".5rem",
                                      paddingTop: ".1rem",
                                    }}
                                  >
                                    ( {object.characterCount})
                                  </TextSpan>
                                </Col>
                                <DeleleDiv>
                                  <ImageTag
                                    src={DeleteIcon}
                                    onClick={() => {
                                      setShowLoader(true);

                                      let newFiles = fileObject.filter(
                                        (
                                          object: fileWithCharacter,
                                          idx: number
                                        ) => idx !== index
                                      );
                                      setFileObject(newFiles);
                                      let deleteFile = fileObject.filter(
                                        (
                                          object: fileWithCharacter,
                                          idx: number
                                        ) => idx === index
                                      );
                                      setTotalPdfCharacterCount(
                                        (totalPdfCharacterCount) =>
                                          totalPdfCharacterCount -
                                          deleteFile[0].characterCount
                                      );
                                      setShowLoader(false);
                                    }}
                                    alt="delete"
                                  />
                                </DeleleDiv>
                              </SelectedSourceDetails>
                            );
                          }
                        )
                      ) : (
                        <SelectedSourceDetails>
                          <TextSpan>No file selected</TextSpan>
                        </SelectedSourceDetails>
                      )}
                    </Col>
                  </Column>
                </InnerDiv>
                <FooterSection>
                  <CancelButton
                    type="button"
                    onClick={() => setShowAddNewSourcePage(false)}
                  >
                    Cancel
                  </CancelButton>
                  {user?.currentSubscription ? (
                    user?.currentSubscription.meta?.allowPDFIndex ? (
                      <FooterInnerDiv>
                        <SelectedCount
                          style={{
                            color: disablePdfIndexLimit ? "red" : "grey",
                          }}
                        >
                          {totalPdfCharacterCount} {" / "}
                          {Math.max(
                            (user?.currentSubscription?.meta
                              ?.indexCharacterSupport
                              ? user?.currentSubscription?.meta
                                  ?.indexCharacterSupport
                              : 0) -
                              (user?.currentSubscription
                                ?.indexCharacterUsedTotal
                                ? user?.currentSubscription
                                    ?.indexCharacterUsedTotal
                                : 0),
                            0
                          )}{" "}
                          {disablePdfIndexLimit
                            ? "Characters limit exceeds"
                            : "Characters"}
                        </SelectedCount>
                        <SaveButton
                          disabled={
                            fileObject.length === 0 ||
                            fileObject.some(
                              (fileObject) => !fileObject.characterCountDone
                            )
                          }
                          displayProp={disablePdfIndexLimit ? false : true}
                          type="submit"
                        >
                          Add Data Source
                        </SaveButton>
                        <CustomButton
                          type="button"
                          disabled={!disablePdfIndexLimit}
                          onClick={() => {
                            setShowLimitPopUp(true);
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: false,
                              isFlashcardHighliter: false,
                              isCharacterLimit: false,
                              isIndexCharacterLimit: true,
                              pdfLimit: 0,
                              isDeleteAccount: false,
                              isLeadLoginSettings: false,
                              isAllowYTIndex: false,
                            });
                          }}
                        >
                          Upgrade Plan
                        </CustomButton>
                      </FooterInnerDiv>
                    ) : (
                      <div>
                        <SaveButton
                          type="button"
                          style={{ opacity: 0.5, cursor: "not-allowed" }}
                        >
                          Add Data Source
                        </SaveButton>
                        <div style={{ paddingLeft: "2rem" }}>
                          <UpgradeSpan
                            onClick={(e) => {
                              setPlanHighliter({
                                isBookHighliter: false,
                                bookLimit: 0,
                                isDeleteBookHighliter: false,
                                isFlashcardHighliter: false,
                                isCharacterLimit: false,
                                isIndexCharacterLimit: true,
                                pdfLimit: 0,
                                isDeleteAccount: false,
                                isLeadLoginSettings: false,
                                isAllowYTIndex: false,
                              });
                              navigate("/subscription");
                            }}
                          >
                            Upgrade Plan
                          </UpgradeSpan>
                          {/* <PlanSpan>Your plan</PlanSpan> */}
                        </div>
                      </div>
                    )
                  ) : (
                    <Col>
                      <SaveButton
                        type="button"
                        style={{ opacity: 0.5, cursor: "not-allowed" }}
                      >
                        Add Data Source
                      </SaveButton>
                      <div style={{ paddingLeft: "2rem" }}>
                        <UpgradeSpan
                          onClick={(e) => {
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: false,
                              isFlashcardHighliter: false,
                              isCharacterLimit: true,
                              pdfLimit: 0,
                              isDeleteAccount: false,
                              isLeadLoginSettings: false,
                              isAllowYTIndex: false,
                            });
                            navigate("/subscription");
                          }}
                        >
                          Purchase Plan
                        </UpgradeSpan>
                      </div>
                    </Col>
                  )}
                </FooterSection>
              </ChatbotSettingsSection>
            )}
          </Formik>
        </InnerSection>
      </Col>
      {showLimitPopUp ? (
        <ParentDiv>
          <EmptyDiv
            onClick={() => {
              setShowLimitPopUp(false);
            }}
          ></EmptyDiv>
          <SubscriptionExpireOverlay>
            <IndexCharacterLimitPopUp
              titleContent={"PDF character limit exceeded"}
              subHeadingContent={"PDF character limit exceeded"}
              mainContent={`Please note that you have exceeded the PDF character limit of
                 your current subscription plan. To resolve this, you can either remove
                 some of the existing links or consider upgrading your plan for a
                 higher character limit.`}
              setShowLimitPopUp={setShowLimitPopUp}
            ></IndexCharacterLimitPopUp>
          </SubscriptionExpireOverlay>
        </ParentDiv>
      ) : null}
    </ParentSection>
  );
};

export default AddPdfSourceNew;
