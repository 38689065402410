import mixpanel from "mixpanel-browser";
import { isTestUser } from "./utils/utils";
//mixpanel.init("YOUR_MIXPANEL_TOKEN");
// 806b80af43d2ac19baeb90d1c147ae19

mixpanel.init(process.env.REACT_APP_MIXPANEL!);
let env_check = process.env.REACT_APP_ENV === "production";
//mixpanel.people.delete_user
let actions = {
  identify: (id: any) => {
    
    if (env_check && !isTestUser(id)) mixpanel.identify(id);
  },
  alias: (id: any) => {
    if (env_check && !isTestUser(id)) mixpanel.alias(id);
  },
  track: (name: any, props: any) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      if (env_check) mixpanel.people.set(props);
    },
    remove: (props: any) => {
      if (env_check) mixpanel.people.delete_user();
    },
  },
};

export let Mixpanel = actions;
