import { useState } from "react";
import MessageVector from "../../images/chat-msg-icon.svg";
import * as BookChatStyles from "./styled";

const QuestionsSideBar = ({
  questionAnswerHistory,
  setCurrentSessionHistory,
  fromChatbotBuilder
}: any) => {
  const [selectedQuestion, setSelectedQuestion] = useState<string>("");

  return (
    <>
      <BookChatStyles.Sidebar fromChatbotBuilder={fromChatbotBuilder} sm={2}>
        {/* <BookChatStyles.SideBarHeader>
          <img src={WhiteDropChatLogo} alt="DropChatLogo" />
        </BookChatStyles.SideBarHeader> */}
        <BookChatStyles.SideBarContent>
          <BookChatStyles.ChatHistoryHeading>
            Chat History
          </BookChatStyles.ChatHistoryHeading>
          <>
            {questionAnswerHistory &&
              questionAnswerHistory.length > 0 &&
              questionAnswerHistory.map((data: any) => (
                <>
                  <BookChatStyles.HistoryQuestion
                    onClick={() => {
                      setCurrentSessionHistory([data]);
                      setSelectedQuestion(data._id);
                    }}
                    selected={data._id === selectedQuestion ? true : false}
                  >
                    <img width="18" src={MessageVector} alt="message" />
                    <p>{data.question}</p>
                  </BookChatStyles.HistoryQuestion>
                </>
              ))}
          </>
        </BookChatStyles.SideBarContent>
      </BookChatStyles.Sidebar>
    </>
  );
};

export default QuestionsSideBar;
