// import { io } from "socket.io-client";
import { createContext, ReactElement, useState } from "react";
import socket from "../dropchat-socket";
import {
  integrationAppsSetting,
  User,
  UserDashboardDetails,
  UserProfileSetting,
  UserProps,
  UserSubscription,
  UserValues,
} from "../types";
/* import { io } from "socket.io-client";

const socket = io(
  process.env.REACT_APP_SOCKET_URL ? process.env.REACT_APP_SOCKET_URL : ""
); */
export const UserContext = createContext({} as UserValues);

const UserContextProvider = (props: UserProps): ReactElement<UserValues> => {
  const [user, setUser] = useState<User | undefined>();
  const [handleRefresh, setHandleRefresh] = useState(false);
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
  const [totalBooksCount, setTotalBooksCount] = useState(0);
  const [guestUserInfo, setGuestUserInfo] = useState<any>("");
  const [userProfileSettings, setUserProfileSettings] =
    useState<UserProfileSetting>({});

  const [userDashboardDetails, setUserDashboardDetails] =
    useState<UserDashboardDetails>({
      booksInLibrary: 0,
      lifetimeTokenUsed: 0,
      thisMonthTokenUsed: 0,
    });

  const [integrationAppList, setIntegrationAppsList] =
    useState<integrationAppsSetting>({
      hubspot: false,
      zendesk: false,
      shopify: false,
      discord: false,
    });

  const updateUserSubscription = (subscription: UserSubscription) => {
    if (user) {
      setUser({ ...user, currentSubscription: subscription });
    }
  };

  const updateUserSubscriptionIndexCount = (
    indexCharacterUsedTotal: number
  ) => {
    if (user && user.currentSubscription) {
      // Create a new user object with the updated indexCharacterUsedTotal
      const updatedUser = {
        ...user,
        currentSubscription: {
          ...user.currentSubscription,
          indexCharacterUsedTotal: indexCharacterUsedTotal,
        },
      };

      setUser(updatedUser);
    }
  };

  const updateIntegratedApps = (updateAppData: {
    integratedData: { [key: string]: boolean };
  }) => {
    setIntegrationAppsList({
      ...integrationAppList,
      ...updateAppData.integratedData,
    });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        handleRefresh,
        setHandleRefresh,
        userProfileSettings,
        setUserProfileSettings,
        userDashboardDetails,
        setUserDashboardDetails,
        showSubscriptionPopup,
        setShowSubscriptionPopup,
        totalBooksCount,
        setTotalBooksCount,
        socket,
        updateUserSubscription,
        updateUserSubscriptionIndexCount,
        integrationAppList,
        setIntegrationAppsList,
        updateIntegratedApps,
        guestUserInfo,
        setGuestUserInfo,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
