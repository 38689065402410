import { useState } from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
// import DltrossSvg from "../../images/cross.svg";
import { MOBILE } from "../../constants/mobile";
import { MEDIUM } from "../../constants/desktop";
import { TAB } from "../../constants/tab";

const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(51, 48, 60, 0.7);
`;

const DivForDltPopUp = styled.div`
  position: fixed;
  /* width: 450px; */
  width: 47%;
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  z-index: 1111;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 90%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 58%;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 47%;
  }
`;

const DltHeader = styled.div`
  /* width: 450px; */
  width: 100%;
  height: 35px;
  /* background: #2f4c95; */
  background: rgba(47, 51, 73, 1);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
  }
`;
// const Arrow = styled.img`
//   cursor: pointer;
//   left: 97%;
//   top: -14px;
//   z-index: 1111;
//   position: absolute;
//   background-color: white;
//   border-radius: 50%;
//   @media (max-width: 1199px) and (min-width: 320px) {
//     left: 95%;
//   }
// `;

const SpanDltPopup = styled.span`
  font-family: "Gill Sans MT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #2f4c95;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: white;
  @media (max-width: ${MOBILE.maxWidth}px) {
    color: white;
  }
`;
const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const SpanTextDlt = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(51, 48, 60, 1);
  padding: 1rem;
  padding-bottom: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    padding-bottom: 0;
  }
`;
const OkButton = styled.button`
  background: #ea5455;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 32%;
  padding: 5px 30px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 69%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 47%;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 32%;
  }
`;
const SpanCancel = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #2f4c95;
  margin: 4px 13px;
  cursor: pointer;
`;

const ConfirmationAlertText = styled.span`
  margin-top: 1rem;
  color: #ea5455;
  font-weight: bold;
  padding: 0rem 1rem;
  text-align: center;
`;

const ConfirmationFieldLabel = styled.span`
  color: #33303c;
  font-size: 14px;
  font-weight: 600;
`;

const ConfirmationField = styled.input`
  color: #33303c;
  width: 200px;
  padding: 0.5rem;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    border: 1px solid rgba(234, 234, 234, 1);
    width: 69%;
  }
`;

const DeleteAccountConfirmationBox = ({
  setShow,
  okButtonClick,
  name,
  message,
  okButtonText,
  cancelButtonText,
  action,
  from,
  email,
}: any) => {
  const [deleteTextMatched, setDeleteTextMatched] = useState<string>("");
  return (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <SpanDltPopup>{name}</SpanDltPopup>
            {/* <Arrow
              src={DltrossSvg}
              alt="dlt"
              onClick={() => {
                setShow(false);
              }}
            /> */}
          </DltHeader>
          <Row>
            <CenterDiv>
              <ConfirmationAlertText>
                {`Are you sure you want to Delete ${
                  from === "admin" ? email : "your"
                } account?`}
              </ConfirmationAlertText>
              <SpanTextDlt>{message}</SpanTextDlt>
              <ConfirmationFieldLabel>Type DELETE</ConfirmationFieldLabel>
              <ConfirmationField
                placeholder="DELETE"
                onChange={(e: any) => setDeleteTextMatched(e.target.value)}
              />
            </CenterDiv>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <OkButton
                type="button"
                onClick={() => {
                  okButtonClick(action);
                }}
                disabled={deleteTextMatched !== "DELETE"}
              >
                {okButtonText ? okButtonText : "Ok"}
              </OkButton>
              <SpanCancel
                onClick={() => {
                  setShow(false);
                }}
              >
                {cancelButtonText ? cancelButtonText : "Cancel"}
              </SpanCancel>
            </div>
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </>
  );
};

export default DeleteAccountConfirmationBox;
