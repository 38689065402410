import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

export const setAuthHeader = (authToken: string) => {
  instance.defaults.headers.common["Authorization"] = "Bearer " + authToken;
};

export default instance;
