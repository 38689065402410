import { useContext, useState } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import styled from "styled-components";
import { LoaderContext } from "../../context/loader-context";
import myAxios from "../../myaxios";
import { Field, Form, Formik } from "formik";
import { isValidEmail, parseErrorResponse } from "../../utils/utils";
import { NotificationManager } from "react-notifications";
import DeleteAccountConfirmationBox from "../utility-component/delete-account-confirmation-box";

const BookFetchForm = styled(Form)`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
`;

const FormLabelText = styled(FormLabel)`
  color: #33303c;
  font-weight: 600;
  font-size: 15px;
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
  color: #33303cde;
  border: 1px solid #eaeaea;
  background: #f8f7fa;
`;

const FormError = styled.span`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const SaveButton = styled(Button)`
  width: 100%;
  background: #ea5455;
  padding: 0.5rem;
  color: #fff;
  border: none;
  & hover {
    background: #ea5455;
  }
  & active {
    background: #ea5455;
  }
`;

const DeleteAccount = () => {
  const { setShowLoader } = useContext(LoaderContext);
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  const [emailForDelete, setEmailForDelete] = useState<any>("");
  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");
  const [confirmationAction, setConfirmationAction] = useState<string>("");
  const validateDeleteAccountSubmission = (formData: any) => {
    const errors: any = {};
    if (!isValidEmail(formData.email)) {
      errors.email = "Enter valid email address!";
    }
    return errors;
  };

  const deleteUserAccount = () => {
    setShowLoader(true);
    myAxios
      .post(`/user/user-profile-delete`, {
        email: emailForDelete,
      })
      .then(async (response) => {
        if (response.data.success) {
          NotificationManager.success("User successfully deleted.", "", 1500);
          setShowLoader(false);
          setShowUtilityConfirmationBox(false);
          setEmailForDelete("");
        } else {
          setShowLoader(false);
          NotificationManager.error(response.data.message, "", 2000);
        }
        // setLoading(false);
      })
      .catch((error) => {
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
        // setLoading(false);
      });
  };

  const deleteConfirmationBox = () => {
    setConfirmationBoxMessage(
      "Deleting user account will remove all of user information from app.dropchat.co. This can not be undone. If you need to use the service again, you will need to create a new account."
    );
    setConfirmationAction("delete");
    setShowUtilityConfirmationBox(true);
  };

  const confirmClick = (action: string) => {
    if (action === "delete") {
      deleteUserAccount();
    }
  };

  return (
    <>
      <Row style={{ marginTop: "1rem" }}>
        <Col sm={6}>
          <Formik
            initialValues={{
              email: "",
            }}
            validate={validateDeleteAccountSubmission}
            onSubmit={(values, { resetForm }) => {
              myAxios
                .post(`/user/check-user-exist`, {
                  email: values.email,
                })
                .then(async (response) => {
                  if (response.data.success) {
                    deleteConfirmationBox();
                    setEmailForDelete(values.email);
                    resetForm();
                  } else {
                    setShowLoader(false);
                    NotificationManager.error(response.data.message, "", 2000);
                  }
                  // setLoading(false);
                })
                .catch((error) => {
                  setShowLoader(false);
                  NotificationManager.error(parseErrorResponse(error));
                  // setLoading(false);
                });
            }}
          >
            {({ values, errors, touched, resetForm }: any) => (
              <BookFetchForm>
                <>
                  <FormLabelText>Email</FormLabelText>
                  <FieldSection>
                    <FormInput name="email" placeholder="Enter email" />
                    {errors.email && touched.email && (
                      <FormError className="formError">
                        {errors.email}
                      </FormError>
                    )}
                  </FieldSection>
                  <Row>
                    <Col>
                      <SaveButton type="submit">Search & Delete</SaveButton>
                    </Col>
                  </Row>
                </>
              </BookFetchForm>
            )}
          </Formik>
        </Col>
      </Row>
      {showUtilityConfirmationBox && (
        <DeleteAccountConfirmationBox
          setShow={setShowUtilityConfirmationBox}
          name="Confirmation"
          message={confirmationBoxMessage}
          okButtonText="Delete"
          action={confirmationAction}
          okButtonClick={confirmClick}
          from={"admin"}
          email={emailForDelete}
        />
      )}
    </>
  );
};

export default DeleteAccount;
