import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Container, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  DEVICE_MEDIUM,
  MEDIUM,
  MOBILE,
  TAB,
} from "../../../constants/breakpoints";
import { BookContext } from "../../../context/BookContext";
import { PublicContext } from "../../../context/PublicContext";
import { UserContext } from "../../../context/UserContext";
import { LoaderContext } from "../../../context/loader-context";
import { PlanContext } from "../../../context/plan-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../../event-utils/dropchat-event-track";
import { trackEvent } from "../../../event-utils/track-event";
import myAxios from "../../../myaxios";
import { CHATBOT_LOGIN_SETTINGS } from "../../../utils/class-names";
import { parseErrorResponse } from "../../../utils/utils";
import CheckPreviewPopup from "../../utility-component/check-preview-popup";
import BubbleChatProtoType from "./bubble-chat-prototype/bubble-chat-prototype";
import InlineChatProType from "./bubble-chat-prototype/inline-chat-prototype";

const ParentSection = styled(Row)`
  flex: 1;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.2rem;
  }
  @media (min-width: ${TAB.minWidth}px) {
    padding: 0.2rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    padding: 1rem;
  }
`;

const ColumnSection = styled(Col)`
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0rem;
  }
`;

const ContentParent = styled.div`
  flex-direction: column;
  background: #fff;
  flex-direction: column;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  padding: 0.5rem;
  flex: 1;
`;

const PreviewParent = styled.div`
  flex-direction: column;
  background: #fff;
  flex-direction: column;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  padding: 0.5rem;
  flex: 1;
`;

const HeadingText = styled.h2`
  font-size: 20px;
  color: #33303c;
  font-weight: 600;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
  }
`;

const Subheading = styled.p`
  font-size: 16px;
  color: #33303c;
  font-weight: 400;
`;

const ChatbotSettingsSection = styled(Form)`
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 100%;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 100%;
  }
  & label {
    color: #33303c;
    font-weight: 600;
  }
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;

const FieldSectionRadio = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column;
  }
  @media (min-width: ${TAB.minWidth}px) {
    flex-direction: column;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    flex-direction: row;
  }
  & label {
    @media (max-width: ${MOBILE.maxWidth}px) {
      width: 100%;
      margin-bottom: 0.5rem;
    }
    @media (min-width: ${TAB.minWidth}px) {
      width: 100%;
      margin-bottom: 0.5rem;
    }
    @media (min-width: ${MEDIUM.minWidth}px) {
      width: 20%;
      margin-bottom: 0rem;
    }
    margin: 0;
  }
  & input {
    width: auto;
    margin-right: 0.4rem;
    cursor: pointer;
    @media (max-width: ${MOBILE.maxWidth}px) {
      -ms-transform: scale(1); /* IE 9 */
      -webkit-transform: scale(1); /* Chrome, Safari, Opera */
      transform: scale(1);
      margin-right: 0.1rem;
      margin-top: 0.3rem;
    }
    @media (min-width: ${TAB.minWidth}px) {
      -ms-transform: scale(1); /* IE 9 */
      -webkit-transform: scale(1); /* Chrome, Safari, Opera */
      transform: scale(1);
      margin-right: 0.1rem;
      margin-top: 0.3rem;
    }
    @media (min-width: ${MEDIUM.minWidth}px) {
      -ms-transform: scale(1.5); /* IE 9 */
      -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
      transform: scale(1.5);
      margin-right: 1rem;
      margin-top: 0.4rem;
    }
  }
  & div {
    flex: 1;
    @media (max-width: ${MOBILE.maxWidth}px) {
      font-size: 14px;
    }
    @media (min-width: ${TAB.minWidth}px) {
      font-size: 14px;
    }
    @media (min-width: ${MEDIUM.minWidth}px) {
      font-size: 18px;
    }
  }
  & section {
    display: flex;
    align-items: center;
  }
`;

const FieldConditionalSectionRadio = styled.div`
  flex-direction: row;
  & label {
    width: 20%;
    margin: 0;
  }
`;

const InputFieldRadio = styled(Field)`
  width: auto;
  margin-top: 0.4rem;
  margin-right: 0.4rem;
  cursor: pointer;
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
`;

const InputField = styled(Field)`
  width: 100%;
  padding: 10px;
  background: #eaeaea;
  border: 1px solid #eaeaea !important;
  display: inline;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: 40px;
    font-size: 13px;
  }
`;

const SaveButton = styled.button`
  background: #1585b5;
  color: #fff;
  border: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    font-size: 12px;
    padding: 0.4rem 2rem;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 100%;
    font-size: 12px;
    padding: 0.4rem 2rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 50%;
    font-size: 16px;
    padding: 0.4rem 3rem;
  }
  &:hover {
    background: #0b5f82;
  }
`;

const DefaultButton = styled.button`
  background: #fff;
  font-weight: 600;
  color: #1585b5;
  width: 100%;
  border: 2px solid #1585b5;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    padding: 0.4rem 2rem;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 12px;
    padding: 0.4rem 2rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
    padding: 0.4rem 3rem;
  }
`;

// const LoginFormNote = styled.p`
//   color: #33303ccc;
//   margin-top: 0.5rem;
//   & b {
//     color: #33303c;
//   }
//   @media (max-width: ${MOBILE.maxWidth}px) {
//     font-size: 13px;
//   }
// `;

const ToogleSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 2;
  margin-bottom: 0.6rem;
`;
const PlanTextMonthly = styled.span`
  font-weight: bold;
  padding-left: 1rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #a5a3ae4d;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  background: #a5a3ae4d;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const UpgradeSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 5px 25px;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
`;

// const PlanSpan = styled.span`
//   font-style: normal;
//   font-weight: bold;
//   font-size: 12px;
//   line-height: 15px;
//   color: #585858;
//   margin-left: 5px;
//   margin-top: 1px;
//   display: flex;
//   align-items: center;
// `;
const LabelName = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin-left: 5px;
  margin-top: 1px;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  color: rgba(51, 48, 60, 1);
`;

const FormLabels = styled(FormLabel)`
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;
const ChildParagraph = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(51, 48, 60, 0.5);
`;
const InlineMethodMainDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InlineInnerDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputCheckbox = styled(Field)`
  cursor: pointer;
  background: rgba(51, 48, 60, 0.6);
  color: rgba(51, 48, 60, 0.6);
  width: 20px;
`;

const LoginSettings = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { setPlanHighliter } = useContext(PlanContext);
  const { setShowLoader } = useContext(LoaderContext);
  const { selectedBook } = useContext(BookContext);
  const { deviceWidth } = useContext(PublicContext);
  const [showPreviewPopup, setShowPreviewPopup] = useState<boolean>(false);
  const [allFormValues, setAllFormValues] = useState<any>();
  // const [isInlineMethod, setIsInlineMethod] = useState<boolean>(false);
  // eslint-disable-next-line
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [leadSettings, setLeadSettings] = useState({
    bookId: selectedBook?._id,
    title: "Welcome to Dropchat",
    customText: "Fill out form to start chatting",
    loginForm: "none",
    isInlineForm: "inlineForm",
    isUserName:
      user?.currentSubscription &&
      user?.currentSubscription.meta?.leadLoginSettings,
    isEmail:
      user?.currentSubscription &&
      user?.currentSubscription.meta?.leadLoginSettings,
    isMobileNumber:
      user?.currentSubscription &&
      user?.currentSubscription.meta?.leadLoginSettings,
    nameText: "Full Name",
    emailText: "Email",
    mobileNumberText: "Phone",
    footerBranding: "Powered By Dropchat",
    footerBrandingLink: "https://dropchat.co/",
    isNoOfSecond: true,
    isNoOfMessage: false,
    noOfSecond: 0,
    noOfMessage: 0,
    conditionalMessage:
      "You have reached the guest visitor limit on chat questions. Please login to continue with chat.",
  });

  useEffect(() => {
    if (selectedBook && selectedBook._id) {
      setShowLoader(true);
      myAxios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/lead-login-settings/${selectedBook?._id}`
        )
        .then((res) => {
          // console.log("res.data.data ---", res.data.data);
          setShowLoader(false);
          if (res.data.data) {
            setLeadSettings(res.data.data);
            setShowLoader(false);
          }
        });
    }
    // eslint-disable-next-line
  }, [selectedBook && selectedBook?._id]);

  // console.log("leadSettings", leadSettings);
  return (
    <Container fluid>
      <ParentSection>
        <ColumnSection xs={deviceWidth === DEVICE_MEDIUM ? 6 : 12}>
          <ContentParent>
            <HeadingText>Chatbot Login Settings</HeadingText>
            <Subheading>
              {/* The below form settings is common in all 3 formats like (iFrame,
              Embedded, and Shared URL) */}
            </Subheading>
            <Formik
              initialValues={{
                title: leadSettings.title,
                customText: leadSettings.customText,
                loginForm: leadSettings.loginForm,
                isInlineForm: leadSettings.isInlineForm
                  ? "inlineForm"
                  : "loginForm",
                username: leadSettings.nameText,
                email: leadSettings.emailText,
                phone: leadSettings.mobileNumberText,
                footerBranding: leadSettings.footerBranding,
                footerBrandingLink: leadSettings.footerBrandingLink,
                loginConditional: leadSettings.isNoOfMessage
                  ? "isNoOfMessage"
                  : "isNoOfSecond",
                noOfSecond: leadSettings.noOfSecond,
                noOfMessage: leadSettings.noOfMessage,
                conditionalMessage: leadSettings.conditionalMessage,
              }}
              // validate={validateLeadLoginSettings}
              enableReinitialize
              onSubmit={(values) => {
                // console.log("values", values);
                if (
                  !leadSettings.isEmail &&
                  !leadSettings.isMobileNumber &&
                  !leadSettings.isUserName
                ) {
                  setFieldError(true);
                  return NotificationManager.warning(
                    "At least one option is required to be enabled from Username, Email, and Phone!"
                  );
                } else {
                  setShowLoader(true);
                  let dataToSave = {
                    bookId: selectedBook?._id,
                    title: values.title,
                    customText: values.customText,
                    loginForm: values.loginForm,
                    isInlineForm:
                      values.isInlineForm === "inlineForm" ? true : false,
                    isUserName: leadSettings.isUserName,
                    isEmail: leadSettings.isEmail,
                    isMobileNumber: leadSettings.isMobileNumber,
                    nameText: values.username,
                    emailText: values.email,
                    mobileNumberText: values.phone,
                    footerBranding: values.footerBranding,
                    footerBrandingLink: values.footerBrandingLink,
                    isNoOfMessage: values.loginConditional === "isNoOfMessage",
                    isNoOfSecond: values.loginConditional === "isNoOfSecond",
                    noOfSecond: values.noOfSecond,
                    noOfMessage: values.noOfMessage,
                    conditionalMessage: values.conditionalMessage,
                  };
                  // setShowLoader(true);
                  myAxios
                    .post(
                      process.env.REACT_APP_SERVER_URL + `/lead-login-settings`,
                      dataToSave
                    )
                    .then((response) => {
                      // console.log("response is , ", response.data);
                      if (response.data && response.data.success) {
                        NotificationManager.success(
                          response.data.message
                            ? response.data.message
                            : "Source saved successfully."
                        );
                        // updateBookIndex(response.data.data);
                        // setShowAddNewSourcePage(false);
                        setShowLoader(false);
                        trackEvent({
                          email: getUsersEmail(user),
                          eventCategory: EventCategory.CHATBOT,
                          event: ChatBotEvents.USER_UPDATED_LEAD_LOGIN_SETTINGS,
                          subCategory: SubCategory.EVENT,
                          chatbotType: selectedBook?.fileType,
                          bookId: selectedBook?._id,
                          rawData: dataToSave,
                        });
                      } else {
                        setShowLoader(false);
                        NotificationManager.error(
                          response.data.message
                            ? response.data.message
                            : "Unable to process your request, please try later"
                        );
                      }
                    })
                    .catch((error) => {
                      // handle error
                      setShowLoader(false);
                      NotificationManager.error(parseErrorResponse(error));
                    });
                }
              }}
            >
              {({
                errors,
                touched,
                isValidating,
                setFieldValue,
                values,
              }: any) => {
                setAllFormValues(values);
                return (
                  <ChatbotSettingsSection className={CHATBOT_LOGIN_SETTINGS}>
                    {/* <FieldSection>
                      <FormLabels>Title</FormLabels>
                      <InputField
                        type="text"
                        name="title"
                        placeholder="Welcome to Dropchat"
                        disabled={
                          user?.currentSubscription &&
                          !user?.currentSubscription.meta?.leadLoginSettings
                        }
                      />
                    </FieldSection> */}
                    <FieldSection>
                      <FormLabels>Add Custom Text</FormLabels>
                      <InputField
                        type="text"
                        name="customText"
                        placeholder="Fill out form to start chatting"
                        disabled={
                          user?.currentSubscription &&
                          !user?.currentSubscription.meta?.leadLoginSettings
                        }
                      />
                    </FieldSection>
                    <FormLabels>Capturing User Info:</FormLabels>

                    <InlineMethodMainDiv>
                      <InlineInnerDiv>
                        <section
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <InputCheckbox
                            type="radio"
                            name="isInlineForm"
                            value={"loginForm"}
                            disabled={
                              user?.currentSubscription &&
                              !user?.currentSubscription.meta?.leadLoginSettings
                            }
                          />
                          <LabelName>Login Form</LabelName>
                        </section>
                        <ChildParagraph>
                          Form will appear in the starting of the chat.
                        </ChildParagraph>
                      </InlineInnerDiv>

                      <InlineInnerDiv>
                        <section
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <InputCheckbox
                            type="radio"
                            name="isInlineForm"
                            value={"inlineForm"}
                            disabled={
                              user?.currentSubscription &&
                              !user?.currentSubscription.meta?.leadLoginSettings
                            }
                          />
                          <LabelName>Inline Method</LabelName>
                        </section>
                        <ChildParagraph>
                          Below info fields appear in the inline chat.
                        </ChildParagraph>
                      </InlineInnerDiv>
                    </InlineMethodMainDiv>

                    <FieldSectionRadio>
                      <FormLabels>
                        {allFormValues &&
                        allFormValues.isInlineForm === "inlineForm"
                          ? "Inline Form"
                          : "Login Form"}
                      </FormLabels>
                      <div style={{ justifyContent: "space-between" }}>
                        <section style={{ alignItems: "center" }}>
                          <InputField
                            type="radio"
                            name="loginForm"
                            value="none"
                            disabled={
                              user?.currentSubscription &&
                              !user?.currentSubscription.meta?.leadLoginSettings
                            }
                          />{" "}
                          None
                        </section>
                        <section style={{ alignItems: "center" }}>
                          <InputField
                            type="radio"
                            name="loginForm"
                            value="required"
                            disabled={
                              user?.currentSubscription &&
                              !user?.currentSubscription.meta?.leadLoginSettings
                            }
                          />{" "}
                          Required
                        </section>
                        <section style={{ alignItems: "center" }}>
                          <InputField
                            type="radio"
                            name="loginForm"
                            value="optional"
                            disabled={
                              user?.currentSubscription &&
                              !user?.currentSubscription.meta?.leadLoginSettings
                            }
                          />{" "}
                          Optional
                        </section>
                        <section style={{ alignItems: "center" }}>
                          <InputField
                            type="radio"
                            name="loginForm"
                            value="conditional"
                            disabled={
                              user?.currentSubscription &&
                              !user?.currentSubscription.meta?.leadLoginSettings
                            }
                          />
                          Conditional
                        </section>
                      </div>
                    </FieldSectionRadio>
                    {/* <LoginFormNote>
                      <b>Note:</b> In optional, the user will have the option to
                      skip the login form.
                    </LoginFormNote> */}
                    {values.loginForm === "conditional" && (
                      <>
                        <FieldSection>
                          <FormLabel>Conditional Message</FormLabel>
                          <InputField
                            type="text"
                            name="conditionalMessage"
                            placeholder="Enter your conditional message"
                            disabled={
                              user?.currentSubscription &&
                              !user?.currentSubscription.meta?.leadLoginSettings
                            }
                          />
                        </FieldSection>
                        <FieldConditionalSectionRadio>
                          <Col
                            style={{
                              flexDirection: "column",
                              marginRight: "1rem",
                            }}
                          >
                            <Col>
                              <InputFieldRadio
                                type="radio"
                                name="loginConditional"
                                value="isNoOfMessage"
                              />{" "}
                              No. of Messages
                            </Col>
                            <Col>
                              <InputField
                                disabled={
                                  values.loginConditional !== "isNoOfMessage"
                                }
                                type="number"
                                name="noOfMessage"
                                min={1}
                              />
                            </Col>
                          </Col>
                          <Col style={{ flexDirection: "column" }}>
                            <Col>
                              <InputFieldRadio
                                type="radio"
                                name="loginConditional"
                                value="isNoOfSecond"
                              />{" "}
                              No. of Seconds
                            </Col>
                            <Col>
                              <InputField
                                disabled={
                                  values.loginConditional !== "isNoOfSecond"
                                }
                                type="number"
                                name="noOfSecond"
                                min={1}
                              />
                            </Col>
                          </Col>
                          <br />
                        </FieldConditionalSectionRadio>
                      </>
                    )}

                    <FieldSection>
                      <ToogleSection>
                        <CheckBoxWrapper>
                          <>
                            <CheckBox
                              id="checkbox"
                              type="checkbox"
                              checked={leadSettings.isUserName}
                              onClick={() => {
                                const currentValue = leadSettings.isUserName;
                                setLeadSettings({
                                  ...leadSettings,
                                  isUserName: !currentValue,
                                });
                              }}
                            />
                            <CheckBoxLabel htmlFor="checkbox" />
                          </>
                          <PlanTextMonthly>Username</PlanTextMonthly>
                        </CheckBoxWrapper>
                      </ToogleSection>
                      <InputField
                        type="text"
                        name="username"
                        disabled={
                          !leadSettings.isUserName ||
                          (user?.currentSubscription &&
                            !user?.currentSubscription.meta?.leadLoginSettings)
                        }
                        placeholder="Enter label to show at form"
                      />
                    </FieldSection>

                    <FieldSection
                      style={{
                        flexDirection:
                          deviceWidth === DEVICE_MEDIUM ? "row" : "column",
                      }}
                    >
                      <Col
                        sm={deviceWidth === DEVICE_MEDIUM ? 6 : 12}
                        style={{ flexDirection: "column" }}
                      >
                        <ToogleSection>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                checked={leadSettings.isEmail}
                                onClick={() => {
                                  const currentValue = leadSettings.isEmail;
                                  setLeadSettings({
                                    ...leadSettings,
                                    isEmail: !currentValue,
                                  });
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                            </>
                            <PlanTextMonthly>Email</PlanTextMonthly>
                          </CheckBoxWrapper>
                        </ToogleSection>
                        <InputField
                          type="text"
                          name="email"
                          disabled={
                            !leadSettings.isEmail ||
                            (user?.currentSubscription &&
                              !user?.currentSubscription.meta
                                ?.leadLoginSettings)
                          }
                          placeholder="Enter label to show at form"
                        />
                      </Col>
                      {deviceWidth === DEVICE_MEDIUM && <Col sm={1}></Col>}
                      <Col
                        sm={deviceWidth === DEVICE_MEDIUM ? 5 : 12}
                        style={{ flexDirection: "column" }}
                      >
                        <ToogleSection>
                          <CheckBoxWrapper>
                            <>
                              <CheckBox
                                id="checkbox"
                                type="checkbox"
                                checked={leadSettings.isMobileNumber}
                                onClick={() => {
                                  const currentValue =
                                    leadSettings.isMobileNumber;
                                  setLeadSettings({
                                    ...leadSettings,
                                    isMobileNumber: !currentValue,
                                  });
                                }}
                              />
                              <CheckBoxLabel htmlFor="checkbox" />
                            </>
                            <PlanTextMonthly>Phone</PlanTextMonthly>
                          </CheckBoxWrapper>
                        </ToogleSection>
                        <InputField
                          type="text"
                          name="phone"
                          disabled={
                            !leadSettings.isMobileNumber ||
                            (user?.currentSubscription &&
                              !user?.currentSubscription.meta
                                ?.leadLoginSettings)
                          }
                          placeholder="Enter label to show at form"
                        />
                      </Col>
                    </FieldSection>

                    {/* <FieldSection>
                      <FormLabels style={{ display: "flex" }}>
                        <span>Footer Branding &nbsp;</span>
                        {user?.currentSubscription &&
                          !user?.currentSubscription.meta?.removeDropChatBranding && (
                            <UpgradeSpan
                              onClick={(e) => {
                                setPlanHighliter({
                                  isBookHighliter: false,
                                  bookLimit: 0,
                                  isDeleteBookHighliter: false,
                                  isFlashcardHighliter: false,
                                  isCharacterLimit: false,
                                  pdfLimit: 0,
                                  isDeleteAccount: false,
                                  isLeadLoginSettings: false,
                                  isRemoveDropChatBrandingHighliter: true,
                                });
                                navigate("/subscription");
                              }}
                            >
                              Upgrade Plan
                            </UpgradeSpan>
                          )}
                      </FormLabels>
                      <InputField
                        type="text"
                        name="footerBranding"
                        placeholder="Powered By Dropchat"
                        disabled={
                          !user?.currentSubscription?.meta
                            .removeDropChatBranding
                        }
                      />
                    </FieldSection>
                    <FieldSection>
                      <FormLabels>Footer Branding Link</FormLabels>
                      <InputField
                        type="text"
                        name="footerBrandingLink"
                        placeholder="https://dropchat.co/"
                        disabled={
                          !user?.currentSubscription?.meta
                            .removeDropChatBranding
                        }
                      />
                    </FieldSection> */}
                    {user?.currentSubscription ? (
                      user?.currentSubscription.meta?.leadLoginSettings ? (
                        <Row>
                          <Col>
                            <SaveButton type="submit">SAVE</SaveButton>
                          </Col>
                          {deviceWidth !== DEVICE_MEDIUM && (
                            <Col>
                              <DefaultButton
                                type="button"
                                onClick={() => setShowPreviewPopup(true)}
                              >
                                Check Preview
                              </DefaultButton>
                            </Col>
                          )}
                        </Row>
                      ) : (
                        <Col>
                          {(user?.currentSubscription &&
                            user?.currentSubscription.meta
                              ?.leadLoginSettings) ||
                          (user?.currentSubscription &&
                            user?.currentSubscription.meta
                              ?.removeDropChatBranding) ? (
                            <SaveButton
                              type="submit"
                              // style={{ opacity: 0.5, cursor: "not-allowed" }}
                            >
                              SAVE
                            </SaveButton>
                          ) : (
                            <SaveButton
                              type="button"
                              style={{ opacity: 0.5, cursor: "not-allowed" }}
                            >
                              SAVE
                            </SaveButton>
                          )}
                          {/* {deviceWidth !== DEVICE_MEDIUM && (
                            <DefaultButton type="button">
                              Check Preview
                            </DefaultButton>
                          )} */}
                          <div style={{ paddingLeft: "2rem" }}>
                            <UpgradeSpan
                              onClick={(e) => {
                                setPlanHighliter({
                                  isBookHighliter: false,
                                  bookLimit: 0,
                                  isDeleteBookHighliter: false,
                                  isFlashcardHighliter: false,
                                  isCharacterLimit: false,
                                  pdfLimit: 0,
                                  isDeleteAccount: false,
                                  isLeadLoginSettings: true,
                                  isRemoveDropChatBrandingHighliter: false,
                                });
                                navigate("/subscription");
                              }}
                            >
                              Upgrade Plan
                            </UpgradeSpan>
                            {/* <PlanSpan>Your plan</PlanSpan> */}
                          </div>
                        </Col>
                      )
                    ) : (
                      <Col>
                        {(user?.currentSubscription &&
                          user?.currentSubscription.meta?.leadLoginSettings) ||
                        (user?.currentSubscription &&
                          user?.currentSubscription.meta
                            ?.removeDropChatBranding) ? (
                          <SaveButton
                            type="submit"
                            // style={{ opacity: 0.5, cursor: "not-allowed" }}
                          >
                            SAVE
                          </SaveButton>
                        ) : (
                          <SaveButton
                            type="button"
                            style={{ opacity: 0.5, cursor: "not-allowed" }}
                          >
                            SAVE
                          </SaveButton>
                        )}
                        {/* {deviceWidth !== DEVICE_MEDIUM && (
                          <DefaultButton type="button">
                            Check Preview
                          </DefaultButton>
                        )} */}
                        <div style={{ paddingLeft: "2rem" }}>
                          <UpgradeSpan
                            onClick={(e) => {
                              setPlanHighliter({
                                isBookHighliter: false,
                                bookLimit: 0,
                                isDeleteBookHighliter: false,
                                isFlashcardHighliter: false,
                                isCharacterLimit: false,
                                pdfLimit: 0,
                                isDeleteAccount: false,
                                isRemoveDropChatBrandingHighliter: false,
                                isLeadLoginSettings: true,
                              });
                              navigate("/subscription");
                            }}
                          >
                            Purchase Plan
                          </UpgradeSpan>
                        </div>
                      </Col>
                    )}
                  </ChatbotSettingsSection>
                );
              }}
            </Formik>
          </ContentParent>
        </ColumnSection>
        {deviceWidth === DEVICE_MEDIUM && (
          <ColumnSection xs={deviceWidth === DEVICE_MEDIUM ? 6 : 12}>
            <PreviewParent>
              {allFormValues && allFormValues.isInlineForm !== "inlineForm" ? (
                <BubbleChatProtoType
                  isUsername={leadSettings.isUserName}
                  isEmail={leadSettings.isEmail}
                  isPhone={leadSettings.isMobileNumber}
                  allFormValues={allFormValues && allFormValues}
                />
              ) : (
                <InlineChatProType
                  isUsername={leadSettings && leadSettings.isUserName}
                  isEmail={leadSettings.isEmail}
                  isPhone={leadSettings.isMobileNumber}
                  allFormValues={allFormValues && allFormValues}
                />
              )}
            </PreviewParent>
          </ColumnSection>
        )}
      </ParentSection>
      <CheckPreviewPopup
        showPreviewPopup={showPreviewPopup}
        setShowPreviewPopup={setShowPreviewPopup}
        leadSettings={leadSettings}
        allFormValues={allFormValues}
      />
    </Container>
  );
};

export default LoginSettings;
