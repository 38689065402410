import styled from "styled-components";
import { MOBILE } from "../../../../../constants/mobile";
import ReIndexIcon from "../../../../../images/reindex.svg";

const ReIndexActionColumn = ({
  dataSource,
  reindexSource,
  setSelectedSourceToReindex,
}: any) => {
  const ImageTag = styled.img`
    @media (max-width: ${MOBILE.maxWidth}px) {
      width: 20px;
    }
  `;
  return (
    <>
      {dataSource && dataSource.type !== "character" && (
        <ImageTag
          src={ReIndexIcon}
          alt={dataSource.type}
          className="w-4rem"
          style={{ cursor: "pointer" }}
          onClick={() => {
            reindexSource();
            setSelectedSourceToReindex(dataSource);
          }}
        />
      )}
    </>
  );
};

export default ReIndexActionColumn;
