import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { MOBILE } from "../../../../../constants/mobile";
import DeleteIcon from "../../../../../images/trash.svg";
import EditIcon from "../../../../../images/EditButton.svg";
import { useEffect, useRef, useState } from "react";
import EditCharacterChatbot from "../../../../manage-setting/components/edit-character-chatbot";
import EditTextChatbot from "../../../../manage-setting/components/edit-text-chatbot";
import EditPdfChatbot from "../../../../manage-setting/components/edit-pdf-chatbot";

const DeleteActionColumn = ({
  dataSource,
  setSelectedSourceToDelete,
  deleteSource,
  numberOfSources,
  setLast,
  reindexSource,
  setSelectedSourceToReindex,
  toBeEdit,
}: any) => {
  const ImageTag = styled.img`
    @media (max-width: ${MOBILE.maxWidth}px) {
      width: 20px;
    }
  `;
  const [isPopupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const imageRef = useRef<any>();

  useEffect(() => {
    toBeEdit && imageRef.current?.click();
  }, [toBeEdit, imageRef]);

  return (
    <>
      {dataSource.type === "character" ||
      dataSource.type === "text" ||
      dataSource.type === "aws" ? (
        <>
          <ImageTag
            style={{ cursor: "pointer" }}
            src={EditIcon}
            alt={"Edit"}
            className="w-4rem"
            onClick={() => {
              if (dataSource.indexStatus === 1) {
                NotificationManager.warning(
                  "The source is still in progress, please wait!"
                );
              } else togglePopup();
            }}
            ref={imageRef}
          />
          {isPopupVisible && (
            <>
              {dataSource.type === "text" ? (
                <EditTextChatbot
                  editPopUp={setPopupVisible}
                  dataSource={dataSource}
                  reindexSource={reindexSource}
                  setSelectedSourceToReindex={setSelectedSourceToReindex}
                  toBeEdit={imageRef}
                />
              ) : dataSource.type === "aws" ? (
                <EditPdfChatbot
                  editPopUp={setPopupVisible}
                  dataSource={dataSource}
                  reindexSource={reindexSource}
                  setSelectedSourceToReindex={setSelectedSourceToReindex}
                  toBeEdit={imageRef}
                />
              ) : (
                <EditCharacterChatbot
                  editPopUp={setPopupVisible}
                  toBeEdit={imageRef}
                />
              )}
            </>
          )}
        </>
      ) : null}

      <ImageTag
        style={{ cursor: "pointer" }}
        src={DeleteIcon}
        alt={"Delete"}
        className="w-4rem"
        onClick={() => {
          if (dataSource.indexStatus === 1) {
            NotificationManager.warning(
              "The source is still in progress, please wait!"
            );
            return;
          }
          if (numberOfSources === 1) {
            setLast(true);
          } else {
            setLast(false);
          }
          setSelectedSourceToDelete(dataSource);
          deleteSource();
        }}
      />
    </>
  );
};

export default DeleteActionColumn;
