// import { io } from "socket.io-client";
import { createContext, ReactElement, useEffect, useState } from "react";
import { Book, PublicContextProps, PublicContextValues, User } from "../types";
import { useMediaQuery } from "react-responsive";
import {
  DEVICE_MEDIUM,
  DEVICE_MOBILE,
  MEDIUM,
  // MOBILE,
  TAB,
} from "../constants/breakpoints";

export const PublicContext = createContext({} as PublicContextValues);

const PublicContextProvider = (
  props: PublicContextProps
): ReactElement<PublicContextValues> => {
  const [book, setBook] = useState<Book | undefined>(undefined);
  const [publicUser, setPublicUser] = useState<User>();
  const [indexingProgress, setIndexingProgress] = useState<boolean>(false);
  const [bookTypeName, setBookTypeName] = useState<string>("");
  const [deviceWidth, setDeviceWidth] = useState<string>();
  const [guestUserInfo, setGuestUserInfo] = useState<any>("");

  const isDesktopOrLaptop = useMediaQuery({ minWidth: MEDIUM.minWidth });
  const isTabletOrMobile = useMediaQuery({ maxWidth: TAB.minWidth });
  // console.log("isDesktopOrLaptop is, ", isDesktopOrLaptop);
  useEffect(() => {
    if (isDesktopOrLaptop) {
      setDeviceWidth(DEVICE_MEDIUM);
    } else {
      setDeviceWidth(DEVICE_MOBILE);
    }
    // eslint-disable-next-line
  }, []);

  const [ShowConditionalLoginPage, setShowConditionalLoginPage] = useState({
    toShowMagicLinkloginPage: true,
    toShowSignupPage: false,
    toShowForgetPassPage: false,
  });

  return (
    <PublicContext.Provider
      value={{
        book,
        setBook,
        indexingProgress,
        setIndexingProgress,
        publicUser,
        setPublicUser,
        bookTypeName,
        setBookTypeName,
        deviceWidth,
        setDeviceWidth,
        isDesktopOrLaptop,
        isTabletOrMobile,
        ShowConditionalLoginPage,
        setShowConditionalLoginPage,
        guestUserInfo,
        setGuestUserInfo,
      }}
    >
      {props.children}
    </PublicContext.Provider>
  );
};

export default PublicContextProvider;
