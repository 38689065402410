import { useContext } from "react";
import styled from "styled-components";
import { DEVICE_MEDIUM, MOBILE } from "../../../../../constants/breakpoints";
import { PublicContext } from "../../../../../context/PublicContext";
import { getFileNameFromURL } from "../../../../../utils/utils";

import LinkIcon from "../../../../../images/link-icon.svg";
import PDFIcon from "../../../../../images/pdf-icon.svg";
import TextIcon from "../../../../../images/text-ic.svg";
import YouTubeIcon from "../../../../../images/youtube-icon.svg";
import THUMBDOWN from "../../../../../images/thumb-down2.svg";
import FEEDBACKICON from "../../../../../images/feedbackIcon.svg";
import THUMBUP from "../../../../../images/thumb-up2.svg";
import JSONIcon from "../../../../../images/json-icon.svg";
import ShopifyIcon from "../../../../../images/file-icons_shopify.svg";
const TitleColumn = ({
  dataSource,
  setYTTranscriptLink,
  setShowTranscriptBox,
}: any) => {
  const { deviceWidth } = useContext(PublicContext);
  const ImageTag = styled.img`
    @media (max-width: ${MOBILE.maxWidth}px) {
      width: 18px;
    }
  `;

  return (
    <>
      <div className="sourcename" style={{ alignItems: "center" }}>
        <ImageTag
          src={
            dataSource.type === "aws"
              ? PDFIcon
              : dataSource.type === "url"
              ? LinkIcon
              : dataSource.type === "JSON"
              ? JSONIcon
              : dataSource.type === "text" || dataSource.type === "character"
              ? TextIcon
              : dataSource.type === "feedbackUp"
              ? THUMBUP
              : dataSource.type === "feedbackDown"
              ? THUMBDOWN
              : dataSource.type === "feedback"
              ? FEEDBACKICON
              : dataSource.type === "shopify"
              ? ShopifyIcon
              : YouTubeIcon
          }
          alt={dataSource.type}
          className="w-4rem"
          width={30}
        />
        <span
          style={{
            paddingLeft: "1rem",
            fontWeight: "600",
            letterSpacing: ".3px",
          }}
        >
          {dataSource.name
            ? dataSource.type === "aws"
              ? getFileNameFromURL(dataSource.name)
              : dataSource.name
            : dataSource.type === "aws"
            ? getFileNameFromURL(dataSource.awsUrl)
            : dataSource.type === "youtube"
            ? dataSource.ytUrl
            : dataSource.urls && dataSource.urls[0]}
        </span>
      </div>

      {deviceWidth === DEVICE_MEDIUM &&
        dataSource.type === "youtube" &&
        dataSource.ytTranscriptLink &&
        dataSource.ytTranscriptLink.length > 0 && (
          <div>
            <span
              style={{
                paddingLeft: "2.8rem",
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={() => {
                setYTTranscriptLink(dataSource.ytTranscriptLink);
                setShowTranscriptBox(true);
              }}
            >
              show transcript
            </span>
          </div>
        )}
    </>
  );
};

export default TitleColumn;
