import styled from "styled-components";
import { MOBILE } from "../../../../../constants/mobile";
import { DEVICE_MEDIUM } from "../../../../../constants/breakpoints";
import { useContext } from "react";
import { PublicContext } from "../../../../../context/PublicContext";
import ShowSourceIcon from "../../../../../images/show-source-icon.svg";

const ShowSourceCloumn = ({
  dataSource,
  setYTTranscriptLink,
  setShowTranscriptBox,
}: any) => {
  const { deviceWidth } = useContext(PublicContext);

  const ImageTag = styled.img`
    @media (max-width: ${MOBILE.maxWidth}px) {
      width: 20px;
    }
  `;

  return (
    <>
      {deviceWidth !== DEVICE_MEDIUM &&
        dataSource.type === "youtube" &&
        dataSource.ytTranscriptLink &&
        dataSource.ytTranscriptLink.length > 0 && (
          <div>
            <ImageTag
              style={{ cursor: "pointer" }}
              src={ShowSourceIcon}
              alt={"Show Source"}
              className="w-4rem"
              onClick={() => {
                setYTTranscriptLink(dataSource.ytTranscriptLink);
                setShowTranscriptBox(true);
              }}
            />
          </div>
        )}
    </>
  );
};

export default ShowSourceCloumn;
