import { Button, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MOBILE } from "../constants/mobile";

const MainSection = styled(Container)`
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
`;

const CenterContentRow = styled(Row)`
  margin-bottom: 0.5rem;
`;

const CenterContentRowDetails = styled(Row)`
  padding: 20px;
  height: 40vh;
  padding-left: 5vh;
`;

const RedMessageContent = styled.p`
  color: #ea5455;
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
  margin: 0;
  padding: 0;
  text-align: center;
  color: white;
`;

const RedMessageContentSubHeading = styled.p`
  color: #ea5455;
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
  margin: 0;
  padding: 0;
  text-align: center;
  color: white;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    margin: 0;
  }
`;

const NormalMessageContent = styled.p`
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  padding: 0;
  padding-top: 1vh;
  text-align: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 11px;
    margin: 0;
  }
`;

const DivForBtn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
`;

const CallToAction = styled(Button)`
  padding: 1rem 3rem;
  color: white;
  background: #f2ae01;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  width: 224px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  :hover {
    background: #f2ae01;
  }
`;
const CallToActionWhite = styled(Button)`
  padding: 1rem 3rem;
  color: #1585b5;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #1585b5;
  cursor: pointer;
  width: 224px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  :hover {
    background: #f2ae01;
    border: 0px solid #fff;
    color: #fff;
  }
`;

const DivForBtnParas = styled.div`
  display: flex;
  justify-content: center;
`;

const BtnPara = styled.span`
  font-weight: 600;
  line-height: 24px;
  font-size: 15px;
  padding: 0px 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 8px 0;
`;
const Header = styled.div`
  min-height: 50px;
  background: #2f3349;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
`;

const IndexCharacterLimitPopUp = ({
  titleContent,
  subHeadingContent,
  mainContent,
  setShowLimitPopUp,
}: any) => {
  const navigate = useNavigate();
  // eslint-disable-next-line

  return (
    <MainSection fluid>
      <CenterContentRow>
        <Header>
          <RedMessageContent>{titleContent}</RedMessageContent>
        </Header>
      </CenterContentRow>
      <CenterContentRowDetails>
        <RedMessageContentSubHeading style={{ color: "red" }}>
          {subHeadingContent}
        </RedMessageContentSubHeading>
        <NormalMessageContent>{mainContent}</NormalMessageContent>
        <DivForBtn>
          <CallToAction
            color="primary"
            size="sm"
            onClick={() => {
              navigate("/subscription");
            }}
          >
            <DivForBtnParas>
              <BtnPara>Upgrade</BtnPara>
            </DivForBtnParas>
          </CallToAction>
          <CallToActionWhite
            color="primary"
            size="sm"
            onClick={() => {
              setShowLimitPopUp(false);
            }}
          >
            <DivForBtnParas>
              <BtnPara>Cancel</BtnPara>
            </DivForBtnParas>
          </CallToActionWhite>
        </DivForBtn>
      </CenterContentRowDetails>
    </MainSection>
  );
};

export default IndexCharacterLimitPopUp;
