import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  DEVICE_MEDIUM,
  // DEVICE_MOBILE,
  // DEVICE_TAB,
  MEDIUM,
  MOBILE,
} from "../../../../constants/breakpoints";
import { BookContext } from "../../../../context/BookContext";
import { PublicContext } from "../../../../context/PublicContext";
import { UserContext } from "../../../../context/UserContext";
import { LoaderContext } from "../../../../context/loader-context";
import { PaginationContext } from "../../../../context/pagination-context";

import myAxios from "../../../../myaxios";
import { Source } from "../../../../types";
import { parseErrorResponse } from "../../../../utils/utils";
import ConfirmationBox from "../../../utility-component/confirmation-box";
import ShowTranscriptBox from "../show-transcript-box";
import DataSourceFilterSection from "./filter-section";
import DataSourceRow from "./data-source-row";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../../../event-utils/dropchat-event-track";
import { trackEvent } from "../../../../event-utils/track-event";
import { TeamContext } from "../../../../context/team-context";
const ParentSection = styled.div`
  display: block;
  padding: 1rem 0.5rem;
  width: 100%;
`;

const InnerSection = styled.div`
  background: #fff;
  flex-direction: column;
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  @media (min-width: ${MEDIUM.minWidth}px) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
  }
`;

const TableTag = styled.table`
  border: 1px solid #eaeaea;
  background: #fff;
  width: 100%;
  width: 100%;
  @media (max-width: ${MOBILE.maxWidth}px) {
    border: 0px solid #eaeaea;
  }
`;

// { selectedBook }: any
const DataSources = () => {
  const navigate = useNavigate();
  const { user, setTotalBooksCount, updateUserSubscriptionIndexCount } =
    useContext(UserContext);
  const { selectedTeam } = useContext(TeamContext);
  const { deviceWidth } = useContext(PublicContext);
  const {
    selectedBook,
    setSelectedBook,
    setBooks,
    setNoOfBots,
    selectedSource,
  } = useContext(BookContext);
  const { pageLimit, pageSkip } = useContext(PaginationContext);
  const [fileTypeFilter, setFileTypeFilter] = useState<any>("all");
  const [statusFilter, setStatusFilter] = useState<Number>(0);
  const { setShowLoader } = useContext(LoaderContext);
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  const [showTranscriptBox, setShowTranscriptBox] = useState<boolean>(false);
  const [ytTranscriptLink, setYTTranscriptLink] = useState<string>("");
  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");
  const [confirmationAction, setConfirmationAction] = useState<string>("");
  const [selectedSourceToDelete, setSelectedSourceToDelete] =
    useState<Source>();
  const [selectedSourceToReindex, setSelectedSourceToReindex] =
    useState<Source>();

  const [isLast, setLast] = useState<boolean>(false);
  const [filteredSources, setFilteredSources] = useState<Source[]>([]);

  useEffect(() => {
    if (
      selectedBook &&
      selectedBook.sources &&
      selectedBook.sources.length > 0
    ) {
      setFilteredSources(
        selectedBook.sources.filter((source: Source) => {
          let returendSource = true;

          if (fileTypeFilter === "all" && statusFilter === 0)
            returendSource = true;
          else {
            if (fileTypeFilter === "all") {
              returendSource = source.indexStatus === statusFilter;
            } else if (statusFilter === 0) {
              returendSource = source.type === fileTypeFilter;
            } else {
              returendSource =
                source.indexStatus === statusFilter &&
                source.type === fileTypeFilter;
            }
          }

          return returendSource;
        })
      );
    } else setFilteredSources([]);
  }, [selectedBook, fileTypeFilter, statusFilter]);

  /*  let isLast = false; */

  const confirmClick = (action: string) => {
    if (action === "delete" && selectedSourceToDelete) {
      setShowLoader(true);
      const bookID = selectedBook && (selectedBook._id || selectedBook.id);

      myAxios
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/books/source/${bookID}/${selectedSourceToDelete._id}`
        )
        .then((response) => {
          if (response.data && response.data.success) {
            // Update cueent book
            trackEvent({
              email: getUsersEmail(user),
              eventCategory: EventCategory.CHATBOT,
              event: ChatBotEvents.USER_REMOVE_DATASOURCE,
              subCategory: SubCategory.EVENT,
              chatbotType: response.data.data.fileType,
              bookId: bookID,
              rawData: { removedSourceType: selectedSourceToDelete.type },
            });
            setSelectedBook(response.data.data);
            setShowUtilityConfirmationBox(false);
            NotificationManager.success("Source deleted successfully");
            if (isLast) {
              myAxios
                .post(
                  process.env.REACT_APP_SERVER_URL + "/books/paginated-books",
                  {
                    pagination: { limit: pageLimit, skip: pageSkip },
                    team: selectedTeam?._id,
                  }
                )
                .then((response) => {
                  setBooks(response.data.data.books);
                  setTotalBooksCount(response.data.data.count);
                  setNoOfBots(response.data.data.count);
                  // setShowLoader(false);
                })
                .catch((error) => {
                  // console.log("Getting Error at the time of get paginated books ", error);
                  // setShowLoader(false);
                });

              navigate("/my-chatbots/create-pdf-chatbot");
            }
          } else {
            setShowUtilityConfirmationBox(false);
            NotificationManager.error(
              response.data && response.data.message
                ? response.data.message
                : "Not able to delete source, Please try again!"
            );
          }
          // console.log("selectedSourceToDelete", selectedSourceToDelete);

          const totalCharacterCount = user?.currentSubscription
            ?.indexCharacterUsedTotal
            ? user?.currentSubscription?.indexCharacterUsedTotal
            : 0;
          const totalChatBotUsedCharacterCount =
            selectedSourceToDelete?.totalCharacterCount;

          updateUserSubscriptionIndexCount(
            totalCharacterCount - totalChatBotUsedCharacterCount
          );

          setShowLoader(false);
        })
        .catch((error) => {
          setShowUtilityConfirmationBox(false);
          NotificationManager.error(parseErrorResponse(error));
          setShowLoader(false);
        });
    }
    if (action === "reindex") {
      setShowLoader(true);
      let sourceId = selectedSourceToReindex && selectedSourceToReindex._id;
      let bookId = selectedBook && (selectedBook._id || selectedBook.id);
      myAxios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/books/source/reindex/${bookId}/${sourceId}`
        )
        .then((response) => {
          if (response.data && response.data.success) {
            trackEvent({
              email: getUsersEmail(user),
              eventCategory: EventCategory.CHATBOT,
              event: ChatBotEvents.USER_REINDEX_DATASOURCE,
              subCategory: SubCategory.EVENT,
              chatbotType: response.data.data.fileType,
              bookId: bookId,
              rawData: { reIndexSourceId: sourceId },
            });
            // Update cueent book
            setSelectedBook(response.data.data);
            setShowUtilityConfirmationBox(false);
            NotificationManager.success("Source sent to reindex successfully!");
          } else {
            setShowUtilityConfirmationBox(false);
            NotificationManager.error(
              response.data && response.data.message
                ? response.data.message
                : "Not able to reindex source, Please try again!"
            );
          }
          setShowLoader(false);
        })
        .catch((error) => {
          setShowUtilityConfirmationBox(false);
          NotificationManager.error(parseErrorResponse(error));
          setShowLoader(false);
        });
    }
  };

  const deleteSource = () => {
    if (isLast) {
      setConfirmationBoxMessage(
        "Are you sure you want to delete the remaining source of this chatbot? This would also delete chatbot as chatbot needs at least one source to exist. Please confirm?"
      );
    } else {
      setConfirmationBoxMessage("Do you really want to delete this source?");
    }
    setConfirmationAction("delete");
    setShowUtilityConfirmationBox(true);
  };

  const reindexSource = () => {
    setConfirmationBoxMessage("Do you really want to reindex this source?");
    setConfirmationAction("reindex");
    setShowUtilityConfirmationBox(true);
  };

  return (
    <>
      {
        <ParentSection>
          <InnerSection>
            <Col sm={12}>
              <DataSourceFilterSection
                setFileTypeFilter={setFileTypeFilter}
                setStatusFilter={setStatusFilter}
              />
            </Col>
          </InnerSection>
          <Col md={12}>
            <TableTag className="dataSourceTable" role="table">
              {deviceWidth === DEVICE_MEDIUM && (
                <thead className="p-datatable-thead">
                  <tr role="row">
                    <th className="titleColumn" role="columnheader">
                      <div className="p-column-header-content">
                        <span
                          className="p-column-title"
                          style={{
                            paddingLeft: ".6rem",
                          }}
                        >
                          File Name
                        </span>
                      </div>
                    </th>
                    <th className="includedColumn" role="columnheader">
                      <div className="p-column-header-content">
                        <span className="p-column-title">Included Info</span>
                      </div>
                    </th>
                    <th className="statusColumn" role="columnheader">
                      <div className="p-column-header-content">
                        <span className="p-column-title">Status</span>
                      </div>
                    </th>
                    <th className="reindexColumn" role="columnheader">
                      <div className="p-column-header-content">
                        <span className="p-column-title">Reindex</span>
                      </div>
                    </th>
                    <th className="uploadedOnColumn" role="columnheader">
                      <div className="p-column-header-content">
                        <span className="p-column-title">Created At</span>
                      </div>
                    </th>
                    <th className="reindexAtColumn" role="columnheader">
                      <div className="p-column-header-content">
                        <span className="p-column-title">Reindex At</span>
                      </div>
                    </th>
                    <th className="actionColumn" role="columnheader">
                      <div className="p-column-header-content">
                        <span className="p-column-title">Action</span>
                      </div>
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                {filteredSources && filteredSources.length > 0
                  ? filteredSources.map((ele: Source, idx: number) => {
                      const numberOfSources =
                        selectedBook &&
                        selectedBook.sources &&
                        selectedBook.sources.length
                          ? selectedBook.sources.length
                          : 1;
                      return (
                        <DataSourceRow
                          numberOfSources={numberOfSources}
                          dataSource={ele}
                          setYTTranscriptLink={setYTTranscriptLink}
                          setShowTranscriptBox={setShowTranscriptBox}
                          reindexSource={reindexSource}
                          setSelectedSourceToReindex={
                            setSelectedSourceToReindex
                          }
                          setSelectedSourceToDelete={setSelectedSourceToDelete}
                          deleteSource={deleteSource}
                          setLast={setLast}
                          toBeSelected={
                            selectedSource && selectedSource._id === ele._id
                          }
                          toBeEdit={
                            selectedSource && selectedSource._id === ele._id
                          }
                        />
                      );
                    })
                  : null}
              </tbody>
            </TableTag>
          </Col>
          {showUtilityConfirmationBox && (
            <ConfirmationBox
              setShow={setShowUtilityConfirmationBox}
              name="Confirmation"
              message={confirmationBoxMessage}
              okButtonText="Yes"
              action={confirmationAction}
              okButtonClick={confirmClick}
            />
          )}

          {showTranscriptBox && (
            <ShowTranscriptBox
              setShow={setShowTranscriptBox}
              ytTranscriptLink={ytTranscriptLink}
            />
          )}
        </ParentSection>
      }
    </>
  );
};

export default DataSources;
