import { createContext, ReactElement, useState } from "react";
import { ChooseURLValues, NotesProps, UrlData } from "../types";

export const ChooseURLContext = createContext({} as ChooseURLValues);

const ChooseURLContextProvider = (
  props: NotesProps
): ReactElement<ChooseURLValues> => {
  const [showConfirmationBox, setShowConfirmationBox] =
    useState<boolean>(false);
  const [urlList, setUrlList] = useState<UrlData[]>([]);
  const [createBookInput, setCreateBookInput] = useState<any>();
  const [resetForm, setResetForm] = useState<any>();
  return (
    <ChooseURLContext.Provider
      value={{
        showConfirmationBox,
        setShowConfirmationBox,
        urlList,
        setUrlList,
        createBookInput,
        setCreateBookInput,
        resetForm,
        setResetForm,
      }}
    >
      {props.children}
    </ChooseURLContext.Provider>
  );
};

export default ChooseURLContextProvider;
