import axios from "axios";
import { Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { LoaderContext } from "../../context/loader-context";
import { ChatSource } from "../../enums";
import {
  BubbleChatbotEvents,
  EventCategory,
  Source,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { trackEvent } from "../../event-utils/track-event";
import BulbVector from "../../images/bulb-vector.svg";
import SearchArrow from "../../images/chat-arrow.svg";
import ChatBotIcon from "../../images/chat-bot-icon.svg";
import UserImage from "../../images/chat-user.svg";
import ChatLiveIcon from "../../images/chatLive-face-agent1.svg";
import BubbleChatLiveIcon from "../../images/drop-chat-icon.svg";
import EscalateEmailIcon from "../../images/escEmailIcon.svg";
import ReloadIcon from "../../images/reload-icon.svg";
import THUMBDOWNINACTIVE from "../../images/thumb-down-inactive.svg";
import THUMBDOWN from "../../images/thumb-down2.svg";
import THUMBUPINACTIVE from "../../images/thumb-up-inactive.svg";
import THUMBUP from "../../images/thumb-up2.svg";
import TypingLoading from "../../images/typingLoading.gif";
import CustomMarkdown from "../../utils/custom-markdown";
import { CHATBOT_WAIVER_TEXT } from "../../utils/place-holder-consts";
import { isValidEmail, parseErrorResponse } from "../../utils/utils";
import { Chat } from "../book-chat/chat";
import InlineMethodLoginForm from "./inline-method-loginform";
import AgreeComponent from "../utility-component/agree-component";
import EscalateEmailPopup from "../utility-component/escalate-email-popup";
import BubbleChatLoginHandler from "./bubble-chat-login-handler";
import BubbleCustomGreetingText from "./bubble-custom-greeting-text";
import * as BubbleChatStyles from "./styled";
import SuggestedQuestionsSection from "./suggested-questions";
// import { UserContext } from "../../context/UserContext";
import { PublicContext } from "../../context/PublicContext";
import { getDeviceInfoForTrackEvent } from "../../event-utils/dropchat-event-track";

// declare var _cio: any;

// declare global {
//   interface Window {
//     hideBubble?: () => void;
//   }
// }

const ChatAreaSection = styled.div`
  height: 78%;
  overflow: auto;
  padding: 0.3rem;
  flex-direction: column;
  display: flex;
  margin-top: auto;
`;

const ChatSection = styled.section`
  margin-top: auto;
`;

const BottomFormSection = styled.div`
  height: ${isMobile ? "12%" : "15%"};
  padding: 5px;
  align-items: end;
`;

const BookBubbleChat = () => {
  const { setShowLoader } = useContext(LoaderContext);
  const [allQuestionsandAnswers, setAllQuestionsAndAnswers] = useState<any[]>(
    []
  );
  const { setGuestUserInfo, guestUserInfo } = useContext(PublicContext);
  // eslint-disable-next-line
  const [bookStatus, setBookStatus] = useState<any>();
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(true);
  const { bookId } = useParams();
  const [chatbotUserEmail, setChatbotUserEmail] = useState<string>();

  const [leadSettings, setLeadSettings] = useState({
    bookId: bookId,
    title: "Welcome to Dropchat",
    customText: "Fill out form to start chatting",
    loginForm: "none",
    isUserName: true,
    isEmail: true,
    isMobileNumber: true,
    nameText: "Full Name",
    emailText: "Email",
    mobileNumberText: "Phone",
    footerBranding: "Powered By Dropchat",
    footerBrandingLink: "https://dropchat.co/",
    conditionalMessage:
      "You have reached the guest visitor limit on chat questions. Please login to continue with chat.",
  });

  const [leadThemeSettings, setLeadThemeSettings] = useState({
    bookId: bookId,
    source: "bubble",
    titleBackgroundColor: "#1585b5",
    questionBackgroundColor: "#000000",
    questionTextColor: "#ffffff",
    answerBackgroundColor: "#f8f7fa",
    answerTextColor: "#33303c",
    questionTextFieldColor: "#000000",
  });

  const [waiverSettings, setWaiverSettings] = useState({
    bookId: bookId,
    source: "bubble",
    waiverText: CHATBOT_WAIVER_TEXT,
    isWaiver: "",
    id: "",
  });

  const [customGreetingText, setCustomGreetingText] = useState<any>();

  const [showLeadForm, setShowLeadForm] = useState<string>("none");
  const [showConditionalLeadForm, setShowConditionalLeadForm] =
    useState<boolean>(false);
  // const [showWaiver, setShowWaiver] = useState<boolean>(false);

  const [currentChatSession, setCurrentChatSession] = useState<any>();

  const [sampleQuestions, setSampleQuestions] = useState([]);
  const [showSampleQuestions, setShowSampleQuestions] = useState(false);

  const [showAgreePopup, setShowAgreePopup] = useState<boolean>(false);
  const [firstMessageAgreePopup, setFirstMessageAgreePopup] =
    useState<boolean>(true);

  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");

  const [questionValue, setQuestionValue] = useState("");
  const [showEscEmailPopup, setShowEscEmailPopup] = useState<boolean>(false);
  const [loginViaTrigger, setLoginViaTrigger] = useState<boolean>(false);

  const [userIP, setUserIP] = useState("");

  const [isMessageInterface, setIsMessageInterface] = useState<boolean>(false);
  const [formRef, setFormRef] = useState<any>();

  const [isConditional, setConditional] = useState<boolean>(false);
  const [triggerCondition, setTriggerCondition] = useState<string>("");
  const [triggerValue, setTriggerValue] = useState<number>(0);
  const [isResponseOn, setIsResponseOn] = useState<boolean>(false);
  const [showEscEmail, setEscEmail] = useState(false);
  const [showChatLive, setChatLive] = useState(false);
  const [chatURL, setChatURL] = useState<string>("");
  const [isInlineForm, setIsInlineForm] = useState();
  // const [leadChatSessionFlag, setLeadChatSessionFlag] = useState(false);
  const [questionId, setQuestionId] = useState<any>("");
  const [escAnswer, setEscAnswer] = useState<any>("");
  const [escQuestion, setEscQuestion] = useState<any>("");
  const [escTitle, setEscTitle] = useState<any>("");
  const [userLoggedInChatBot, setUserLoggedInChatBot] = useState();
  const [userLoggedDetails, setUserLoggedDetails] = useState<any>();
  const [userFullName, setUserFullName] = useState({
    firstName: undefined,
    lastName: undefined,
  });
  const [chatbotDetails, setChatbotDetails] = useState<any>();

  const checkAndShowLoginForm = () => {
    if (isConditional && triggerCondition === "message") {
      if (!currentChatSession || !currentChatSession._id) {
        if (allQuestionsandAnswers.length + 1 >= triggerValue) {
          if (showLeadForm === "conditional") {
            setLoginViaTrigger(true);
            setShowConditionalLeadForm(true);
          }
        }
      } else {
        if (allQuestionsandAnswers.length + 1 >= triggerValue) {
          if (showLeadForm === "conditional") {
            setLoginViaTrigger(true);
            setShowConditionalLeadForm(true);
          }
        }
      }
    }
  };

  const getUserBrowserData = async () => {
    const browser = await getDeviceInfoForTrackEvent();
    setGuestUserInfo(browser);
  };
  useEffect(() => {
    getUserBrowserData();
    // eslint-disable-next-line
  }, [setGuestUserInfo]);

  useEffect(() => {
    if (triggerCondition === "second") {
      // Need to add timer to show login form

      setTimeout(() => {
        //  Need to check and show login form
        if (!currentChatSession || !currentChatSession._id) {
          // Need to show login form
          if (showLeadForm === "conditional") {
            setShowConditionalLeadForm(true);
            setLoginViaTrigger(true);
          }
        }
      }, triggerValue * 1000);
    }
    // eslint-disable-next-line
  }, [triggerCondition]);

  const getFullAnswer = async (id: any) => {
    const jsonObject = JSON.parse(id);
    const leadChatHistoryResId = jsonObject.leadChatHistoryRes_id;

    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/lead-chat-histories/${leadChatHistoryResId}`
      )
      .then((res: any) => {
        setIsResponseOn(false);
        // console.log("res.data.data ---", res.data.data);
        if (res.data) {
          // updateUserSubscription(res.data.data.currentSubscription);
          //setActivePlan(res.data.data.currentSubscription);

          const data = res.data;

          setAllQuestionsAndAnswers((oldQuestions: any[]) => {
            if (oldQuestions.length > 0) {
              oldQuestions[oldQuestions.length - 1]._id = data._id;
              oldQuestions[oldQuestions.length - 1].id = data.id;
              oldQuestions[oldQuestions.length - 1].answer = data.answer;

              return [...oldQuestions];
            } else return [];
          });
          checkAndShowLoginForm();
        }

        // setShowOverlay(false);
      })
      .catch((error) => {
        setIsResponseOn(false);
      });
  };

  const chatWithDropchat = async (userQuery: string, session?: any) => {
    const currentSession = currentChatSession || session;
    setAllQuestionsAndAnswers(
      allQuestionsandAnswers
        ? [
            ...allQuestionsandAnswers,
            { question: userQuery, answer: "", isChatEnd: false },
          ]
        : [{ question: userQuery, answer: "" }]
    );
    trackEvent({
      email: emailValue || "PUBLIC",
      eventCategory: EventCategory.CHATBOT,
      event: BubbleChatbotEvents.USER_ASKED_QUESTION_ON_BUBBLE_CHAT,
      subCategory: SubCategory.EVENT,
      source: Source.BUBBLE,
      rawData: {
        question: userQuery,
      },
      bookId: bookId,
      leadChatSession: currentChatSession && currentChatSession.id,
    });
    /* if (!isTestUser(bookuser?.email))
    _cio.track(USER_ASKED_A_QUESTION_FROM_BUBBLE_CHATBOT, {
      name: "user-asked-a-question-from-bubble-chatbot",
    }); */
    const chatHistory = loginViaTrigger
      ? currentChatSession
        ? []
        : allQuestionsandAnswers
      : [];
    setLoginViaTrigger(false);
    const requestBody = {
      question: userQuery,
      bookId: bookId,
      source: ChatSource.bubbleChat,
      chatLeadSessionId: currentSession ? currentSession._id : undefined,
      chatHistory,
      questions:
        allQuestionsandAnswers && allQuestionsandAnswers.length > 0
          ? allQuestionsandAnswers.map((ques) => ques.question)
          : [],
    };
    const chat = new Chat(
      requestBody,
      (token: string) => {
        setAllQuestionsAndAnswers((oldQuestions: any[]) => {
          if (oldQuestions.length > 0) {
            oldQuestions[oldQuestions.length - 1].answer = oldQuestions[
              oldQuestions.length - 1
            ].answer
              ? oldQuestions[oldQuestions.length - 1].answer + token
              : token;
            return [...oldQuestions];
          } else return [];
        });
      },
      (data: any) => {
        // setShowOverlay(false);
        setAllQuestionsAndAnswers((oldQuestions: any[]) => {
          if (oldQuestions.length > 0) {
            oldQuestions[oldQuestions.length - 1].isChatEnd = true;
            return [...oldQuestions];
          } else return [];
        });

        getFullAnswer(data);
      },
      (data: any) => {
        NotificationManager.error(data);
        setIsResponseOn(false);
        setAllQuestionsAndAnswers((oldQuestions: any[]) => {
          if (oldQuestions.length > 0) {
            oldQuestions.pop(); //splice(oldQuestions.length - 1, 1);
            return [...oldQuestions];
          } else return [];
        });
      },
      ""
      // user?.jwt
    );

    await chat.startChat();
  };

  const chatStart = (userQuery: string) => {
    console.log("currentChatSession is, ", currentChatSession);
    if (currentChatSession) {
      chatWithDropchat(userQuery);
    } else {
      setShowLoader(true);
      const dataToSave = {
        bookId: bookId,
        name: nameValue,
        email: emailValue,
        mobileNumber: phoneValue,
        source: "bubblechat",
        chatLeadSessionId: currentChatSession
          ? currentChatSession._id
          : undefined,
        extraDetails: guestUserInfo,
      };

      if (dataToSave.email || dataToSave.mobileNumber || dataToSave.name) {
        let data = JSON.stringify(dataToSave);
        localStorage.setItem(`userLoggedIn${bookId}`, data);
      }
      axios
        .post(
          process.env.REACT_APP_SERVER_URL + `/lead-chat-sessions`,
          dataToSave
        )
        .then((response) => {
          if (response.data && response.data.success) {
            // setCurrentChatSession(response.data.data);

            let chatSession: any = localStorage.getItem(
              `bubble_current_lead_chat_session${bookId}`
            );
            if (chatSession === null) {
              setCurrentChatSession(response.data.data);
              let objString = JSON.stringify(response.data.data);
              localStorage.setItem(
                `bubble_current_lead_chat_session${bookId}`,
                objString
              );
            } else {
              // setCurrentChatSession(chatSession);
              if (bookId === chatSession.book) {
                setCurrentChatSession(chatSession);

                localStorage.setItem(
                  `bubble_current_lead_chat_session${bookId}`,
                  chatSession
                );
              } else {
                setCurrentChatSession(response.data.data);
                let objString = JSON.stringify(response.data.data);
                localStorage.setItem(
                  `bubble_current_lead_chat_session${bookId}`,
                  objString
                );
              }
            }
          }
          setShowLoader(false);
          chatWithDropchat(userQuery, response?.data?.data);
        })
        .catch((error) => {
          // handle error
          // NotificationManager.error(parseErrorResponse(error));
          console.log("inside catch chat");
          setShowLoader(false);
          chatWithDropchat(userQuery);
        });
    }
  };

  const listRef = useRef<any>(null);

  useEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [allQuestionsandAnswers]);

  const rating = (questionId: any, rating: any) => {
    setAllQuestionsAndAnswers((oldQuestions: any[]) => {
      if (oldQuestions.length > 0) {
        const questions = oldQuestions.map((question) => {
          if (question._id === questionId)
            return { ...question, rating: rating };
          else return question;
        });

        return questions;
      }
      return oldQuestions;
    });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/questions-and-answers-rating`,
        { questionId: questionId, rating: rating }
      )
      .then((res) => {
        if (res.data.success) {
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/escalate-emails/${bookId}`)
      .then((res) => {
        if (res && res.data && res.data.data) {
          setEscEmail(res.data.data.isEscalateEmail);
          setChatLive(res.data.data.isChatLive);
          setChatURL(res.data.data.redirectLink);
        } else {
          // NotificationManager.error(
          //   "Unable to process your request, please try later"
          // );
        }
      });

    axios
      .get(process.env.REACT_APP_SERVER_URL + "/books/" + bookId)
      .then((response) => {
        if (response.data) {
          setChatbotUserEmail(response.data.user.email);
        }
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const chatLive = (leadChatSession: any, bookId: any) => {
    try {
      window.open(chatURL, "_blank");
    } catch (error) {}
    const dataToSave = {
      leadChatSession: leadChatSession,
      bookId: bookId,
    };
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/chat-live-info`, dataToSave)
      .then((res) => {});
  };

  const submitTerms = (selectedBtn: any) => {
    // setShowWaiver(false);
    const dataToSave = {
      bookId: bookId,
      source: "bubblechat",
      isAgree: selectedBtn === "agree" ? true : false,
      ipAddress: userIP,
      leadChatSession:
        currentChatSession && currentChatSession.id && currentChatSession.id,
      chatbotWaiverSettingId: waiverSettings.id,
    };
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + `/chatbot-waiver-stats`,
        dataToSave
      )
      .then((response) => {
        // console.log("response is , ", response.data);

        if (response.data.data.isAgree) {
          localStorage.setItem("terms", "true");
          setFirstMessageAgreePopup(false);
        }
        if (response.data && response.data.success) {
          // setShowLeadForm("none");
          setShowAgreePopup(false);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
          setShowAgreePopup(false);
        }
      })
      .catch((error) => {
        // handle error
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const validatedLoginSettings = () => {
    if (leadSettings.isUserName)
      if (!nameValue || nameValue.length === 0) {
        NotificationManager.error("Name Required!");
        return false;
      }

    if (leadSettings.isEmail) {
      if (!emailValue || emailValue.length === 0) {
        NotificationManager.error("Email required!");
        return false;
      }

      if (!isValidEmail(emailValue)) {
        NotificationManager.error("Enter valid email address!");
        return false;
      }
    }
    if (leadSettings.isMobileNumber)
      if (!phoneValue || phoneValue.length === 0) {
        NotificationManager.error("Mobile number required!");
        return false;
      }

    return true;
  };

  const createUpdateChatSession = (isFromLoginForm: boolean) => {
    /* if (emailValue && !isValidEmail(emailValue)) {
      NotificationManager.error("Enter valid email address!");
      return;
    } */
    const dataToSave = {
      bookId: bookId,
      name: nameValue,
      email: emailValue,
      mobileNumber: phoneValue,
      source: "bubblechat",
      chatLeadSessionId: currentChatSession
        ? currentChatSession._id
        : undefined,
      extraDetails: guestUserInfo,
    };

    if (dataToSave.email || dataToSave.mobileNumber || dataToSave.name) {
      let data = JSON.stringify(dataToSave);
      localStorage.setItem(`userLoggedIn${bookId}`, data);
    }
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + `/lead-chat-sessions`,
        dataToSave
      )
      .then((response) => {
        // console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Details submitted successfully."
          );
          setCurrentChatSession(response.data.data);
          // setShowLeadForm(response.data.data.loginForm);
          if (isFromLoginForm) setShowLeadForm("none");
          setShowConditionalLeadForm(false);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const handleIncSampleQuestionCount = (questionId: any) => {
    axios
      .post(
        process.env.REACT_APP_SERVER_URL +
          `/lead-sample-questions/${questionId}`
      )
      .then((response) => {})
      .catch((error) => {
        // handle error
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setUserIP(res.data.ip);
  };

  const bubbleChatApis = () => {
    checkUserLoggedIn();
    reloadChatHistory();
    setShowLoader(true);
    setAllQuestionsAndAnswers([]);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/public/get-chatbot-details/${bookId}`
      )
      .then((response) => {
        if (response && response.data) {
          setChatbotDetails(response.data.data);
        }
      });
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/lead-login-settings/${bookId}`)
      .then((res) => {
        // console.log("res.data.data ---", res.data.data);
        setShowLoader(false);
        if (res.data.data) {
          setLeadSettings(res.data.data);
          setShowLeadForm(res.data.data.loginForm);
          setIsInlineForm(res.data.data.isInlineForm);
          setShowLoader(false);

          if (res.data.data.loginForm === "conditional") {
            setConditional(true);

            if (res.data.data.isNoOfMessage) {
              setTriggerCondition("message");
              setTriggerValue(res.data.data.noOfMessage);
            }
            if (res.data.data.isNoOfSecond) {
              setTriggerCondition("second");
              setTriggerValue(res.data.data.noOfSecond);
            }
          }
        }
      })
      .catch((error) => {
        // NotificationManager.error(parseErrorResponse(error));
        setShowLoader(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/lead-theme-settings/${bookId}/bubble`
      )
      .then((res) => {
        // console.log("res.data.data ---", res.data.data);
        if (res.data.data) {
          setLeadThemeSettings(res.data.data);
        }
      })
      .catch((error) => {
        // NotificationManager.error(parseErrorResponse(error));
        setShowLoader(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/lead-sample-questions/${bookId}`
      )
      .then((res) => {
        // console.log("res.data.data ---", res.data.data);
        if (res.data.data) {
          setSampleQuestions(res.data.data);
          if (res.data.data.length > 0) {
            setShowSampleQuestions(true);
          }
        }
      })
      .catch((error) => {
        // NotificationManager.error(parseErrorResponse(error));
        setShowLoader(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/chatbot-waiver-settings/${bookId}`
      )
      .then((res) => {
        // console.log("res.data.data ---", res.data.data);
        if (res.data.data) {
          setWaiverSettings(res.data.data);
          // setShowWaiver(res.data.data.isWaiver);
          setEscTitle(res.data.data.book.title);
        }
      })
      .catch((error) => {
        // NotificationManager.error(parseErrorResponse(error));
        setShowLoader(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/chatbot-greeting-settings/${bookId}`
      )
      .then((res) => {
        if (res.data.data) {
          setCustomGreetingText(res.data.data);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        // NotificationManager.error(parseErrorResponse(error));
        setShowLoader(false);
      });
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/chatbot-interface-settings/${bookId}`
      )
      .then((res) => {
        if (res.data.data) {
          setIsMessageInterface(res.data.data.interfaceType === "message");
          setShowLoader(false);
        }
      })
      .catch((error) => {
        // NotificationManager.error(parseErrorResponse(error));
        setShowLoader(false);
      });
    getData();
    const localUser = localStorage.getItem("terms");
    if (localUser) {
      setFirstMessageAgreePopup(false);
    } else setFirstMessageAgreePopup(true);
  };

  useEffect(() => {
    bubbleChatApis();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    reloadChatHistory();
    // eslint-disable-next-line
  }, []);

  const reloadChatHistory = () => {
    let leadchatSession: any = localStorage.getItem(
      `bubble_current_lead_chat_session${bookId}`
    );
    let retrievedObj: any = JSON.parse(leadchatSession);
    if (leadchatSession !== null && bookId === retrievedObj.book) {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL +
            `/lead-chat-histories/session/${retrievedObj._id}`
        )
        .then((response) => {
          if (response.data && response.data.success) {
            let res: any[] = response.data.message;
            // console.log("res", res);
            const updatedMessages = res.map((message) => {
              return { ...message, isChatEnd: true };
            });
            setAllQuestionsAndAnswers(updatedMessages);
            // setCurrentChatSession(retrievedObj);

            if (bookId === retrievedObj.book) {
              setCurrentChatSession(retrievedObj);
            } else {
              setCurrentChatSession({});
            }
          }

          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };

  const removeLocalStorageSessionData = () => {
    let st = `bubble_current_lead_chat_session${bookId}`;
    if (bookId) {
      localStorage.removeItem(st);
    }
  };

  const checkUserLoggedIn = () => {
    let sharedChatBotUserLoggedIn: any = localStorage.getItem(
      `userLoggedIn${bookId}`
    );

    let retrievedLoginDetails: any = JSON.parse(sharedChatBotUserLoggedIn);

    if (
      retrievedLoginDetails &&
      retrievedLoginDetails.bookId === bookId &&
      retrievedLoginDetails.source === "bubblechat"
    ) {
      let names =
        retrievedLoginDetails && retrievedLoginDetails.name.split(" ");
      console.log("names", names);

      // let lastName: any = "";
      // if (names.length > 1) {
      //   lastName = names.pop();
      // }
      // setUserFullName({
      //   firstName: names[0],
      //   lastName: lastName,
      // });

      if (names && names.length > 0) {
        setUserFullName({
          firstName: names[0],
          lastName: names.length > 1 ? names.pop() : "",
        });
      } else {
        setUserFullName({
          firstName: undefined,
          lastName: undefined,
        });
      }
      setUserLoggedInChatBot(retrievedLoginDetails.bookId);
      setUserLoggedDetails(retrievedLoginDetails);
    }
  };
  useEffect(() => {
    checkUserLoggedIn();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <>
        {/* {showLeadForm === "optional" ||
        showLeadForm === "required" ||
        showConditionalLeadForm ? ( */}
        {!userLoggedInChatBot &&
        !isInlineForm &&
        (showLeadForm === "optional" ||
          showLeadForm === "required" ||
          showConditionalLeadForm) ? (
          <BubbleChatLoginHandler
            leadSettings={leadSettings}
            leadThemeSettings={leadThemeSettings}
            showLeadForm={showLeadForm}
            setShowLeadForm={setShowLeadForm}
            setNameValue={setNameValue}
            setEmailValue={setEmailValue}
            setPhoneValue={setPhoneValue}
            validatedLoginSettings={validatedLoginSettings}
            createUpdateChatSession={createUpdateChatSession}
            bubbleChatApis={bubbleChatApis}
            bookId={bookId}
            emailValue={emailValue}
            nameValue={nameValue}
            phoneValue={phoneValue}
            leadChatSession={currentChatSession}
            chatbotDetails={chatbotDetails}
          />
        ) : (showLeadForm === "optional" ||
            showLeadForm === "required" ||
            showLeadForm === "none" ||
            !showConditionalLeadForm) &&
          showSampleQuestions ? (
          <SuggestedQuestionsSection
            leadSettings={leadSettings}
            leadThemeSettings={leadThemeSettings}
            setShowSampleQuestions={setShowSampleQuestions}
            sampleQuestions={sampleQuestions}
            waiverSettings={waiverSettings}
            firstMessageAgreePopup={firstMessageAgreePopup}
            questionValue={questionValue}
            setShowAgreePopup={setShowAgreePopup}
            chatStart={chatStart}
            setQuestionValue={setQuestionValue}
            setFormRef={setFormRef}
            allQuestionsandAnswers={allQuestionsandAnswers}
            customGreetingText={customGreetingText}
            formRef={formRef}
            handleIncSampleQuestionCount={handleIncSampleQuestionCount}
            isMessageInterface={isMessageInterface}
            isResponseOn={isResponseOn}
            emailValue={emailValue}
            bubbleChatApis={bubbleChatApis}
            bookId={bookId}
            leadChatSession={currentChatSession}
            chatbotDetails={chatbotDetails}
          />
        ) : (
          <>
            <BubbleChatStyles.ChatPageHeader
              className=""
              style={{
                background: leadThemeSettings.titleBackgroundColor
                  ? leadThemeSettings.titleBackgroundColor
                  : "#1585b5",
                paddingRight: isMobile ? "45px" : "0.5rem",
              }}
            >
              <Col sm={1} xs={1} style={{ flex: 1 }}>
                <img src={BubbleChatLiveIcon} alt="online..." width={30} />{" "}
                &nbsp;
              </Col>

              <Col sm={8} xs={8} style={{ alignItems: "center", flex: 8 }}>
                <BubbleChatStyles.ChatPageBold>
                  {chatbotDetails &&
                  chatbotDetails.chatbotHeaderTitle &&
                  chatbotDetails.chatbotHeaderTitle.length > 0
                    ? chatbotDetails.chatbotHeaderTitle
                    : "Welcome to Dropchat"}
                </BubbleChatStyles.ChatPageBold>
              </Col>
              <Col
                sm={3}
                md={3}
                style={{
                  flex: 3,
                  justifyContent: "flex-end",
                  marginRight: "2rem",
                }}
              >
                <img
                  src={ReloadIcon}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    removeLocalStorageSessionData();
                    bubbleChatApis();
                  }}
                  alt={"show"}
                  width={20}
                />
                {sampleQuestions && sampleQuestions.length > 0 && (
                  <img
                    src={BulbVector}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      trackEvent({
                        email: emailValue || "PUBLIC",
                        eventCategory: EventCategory.CHATBOT,
                        event:
                          BubbleChatbotEvents.USER_VIEW_SUGGESTED_QUESTIONS_ON_BUBBLE_CHAT,
                        subCategory: SubCategory.EVENT,
                        source: Source.BUBBLE,
                        bookId: bookId,
                        leadChatSession:
                          currentChatSession && currentChatSession.id,
                        rawData: {
                          suggestedQuestions:
                            sampleQuestions && sampleQuestions.length > 0
                              ? (sampleQuestions.map(
                                  (question: { question: string }) =>
                                    question.question
                                ) as string[])
                              : [],
                        },
                      });
                      setShowSampleQuestions(true);
                    }}
                    alt={"show"}
                    width={15}
                  />
                )}
              </Col>
            </BubbleChatStyles.ChatPageHeader>
            <ChatAreaSection ref={listRef}>
              <ChatSection>
                <BubbleCustomGreetingText
                  leadThemeSettings={leadThemeSettings}
                  customGreetingText={customGreetingText}
                  isMessageInterface={isMessageInterface}
                />
                {allQuestionsandAnswers &&
                  allQuestionsandAnswers.length > 0 &&
                  allQuestionsandAnswers
                    .slice(0)
                    .map((data: any, index: any) => {
                      console.log("chat answer data is, ", data);
                      return (
                        <BubbleChatStyles.SignleQuestionAnswerSection>
                          <div
                            style={{
                              maxWidth: isMessageInterface ? "80%" : "100%",
                              marginBottom: "5px",
                            }}
                          >
                            <img width="20" src={UserImage} alt="user_image" />
                            &nbsp;
                            <BubbleChatStyles.QuestionSection
                              style={{
                                background:
                                  leadThemeSettings.questionBackgroundColor
                                    ? leadThemeSettings.questionBackgroundColor
                                    : "#fff",
                                maxWidth: isMessageInterface ? "80%" : "100%",
                              }}
                            >
                              <h5
                                style={{
                                  color: leadThemeSettings.questionTextColor
                                    ? leadThemeSettings.questionTextColor
                                    : "rgba(51, 48, 60, 1)",
                                }}
                              >
                                {data.question}
                              </h5>
                            </BubbleChatStyles.QuestionSection>
                          </div>
                          <div
                            style={{
                              alignSelf: isMessageInterface
                                ? "flex-end"
                                : "flex-start",
                              maxWidth: isMessageInterface ? "80%" : "100%",
                              flexDirection: isMessageInterface
                                ? "row-reverse"
                                : "row",
                            }}
                          >
                            <img
                              width="20"
                              src={ChatBotIcon}
                              alt="user_image"
                              style={{
                                display: "flex",
                                alignSelf: "baseline",
                                marginTop: "2px",
                              }}
                            />
                            &nbsp;
                            <BubbleChatStyles.ResponseSection
                              style={{
                                background:
                                  leadThemeSettings.answerBackgroundColor
                                    ? leadThemeSettings.answerBackgroundColor
                                    : "#fff",
                                alignSelf: isMessageInterface
                                  ? "flex-end"
                                  : "flex-start",
                              }}
                            >
                              <div
                                style={{
                                  flexDirection: isMessageInterface
                                    ? "row-reverse"
                                    : "row",
                                  alignSelf: isMessageInterface ? "end" : "",
                                }}
                              >
                                {/* {data.answer &&
                                        data.answer.length > 0 ? ( */}
                                <BubbleChatStyles.MarkDownContent
                                  color={
                                    leadThemeSettings.answerTextColor
                                      ? leadThemeSettings.answerTextColor
                                      : "rgba(51, 48, 60, 1)"
                                  }
                                >
                                  <div style={{ flexDirection: "column" }}>
                                    <CustomMarkdown text={data.answer} />
                                    {data.isChatEnd ? null : (
                                      <img
                                        width="30"
                                        src={TypingLoading}
                                        alt="..."
                                      />
                                    )}
                                  </div>
                                </BubbleChatStyles.MarkDownContent>
                              </div>
                            </BubbleChatStyles.ResponseSection>
                          </div>
                          {data.answer && data.answer.length > 0 && data._id ? (
                            <BubbleChatStyles.RatingSection
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "5px 0",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <img
                                  width="30"
                                  src={
                                    data.rating === "Good"
                                      ? THUMBUP
                                      : THUMBUPINACTIVE
                                  }
                                  style={{ cursor: "pointer" }}
                                  alt="..."
                                  onClick={() => {
                                    trackEvent({
                                      email: emailValue || "PUBLIC",
                                      eventCategory: EventCategory.CHATBOT,
                                      event:
                                        BubbleChatbotEvents.USER_USED_THUMB_UP_ON_BUBBLE_CHAT,
                                      subCategory: SubCategory.EVENT,
                                      source: Source.BUBBLE,
                                      bookId: bookId,
                                      leadChatSession:
                                        currentChatSession &&
                                        currentChatSession.id,
                                      rawData: {
                                        question: data.question,
                                        answer: data.answer,
                                      },
                                    });
                                    rating(
                                      data._id,
                                      data.rating === "Good" ? "NA" : "Good"
                                    );
                                  }}
                                />
                                <img
                                  width="30"
                                  src={
                                    data.rating === "Bad"
                                      ? THUMBDOWN
                                      : THUMBDOWNINACTIVE
                                  }
                                  style={{ cursor: "pointer" }}
                                  alt="..."
                                  onClick={() => {
                                    trackEvent({
                                      email: emailValue || "PUBLIC",
                                      eventCategory: EventCategory.CHATBOT,
                                      event:
                                        BubbleChatbotEvents.USER_USED_THUMB_DOWN_ON_BUBBLE_CHAT,
                                      subCategory: SubCategory.EVENT,
                                      source: Source.BUBBLE,
                                      leadChatSession:
                                        currentChatSession &&
                                        currentChatSession.id,
                                      rawData: {
                                        question: data.question,
                                        answer: data.answer,
                                      },
                                      bookId: bookId,
                                    });
                                    rating(
                                      data._id,
                                      data.rating === "Bad" ? "NA" : "Bad"
                                    );
                                  }}
                                />
                                {showEscEmail && (
                                  <img
                                    width="25"
                                    src={EscalateEmailIcon}
                                    alt="..."
                                    onClick={() => {
                                      checkUserLoggedIn();
                                      trackEvent({
                                        email: emailValue || "PUBLIC",
                                        eventCategory: EventCategory.CHATBOT,
                                        event:
                                          BubbleChatbotEvents.USER_USED_EMAIL_ESCALATE_ON_BUBBLE_CHAT,
                                        subCategory: SubCategory.EVENT,
                                        rawData: {
                                          question: data.question,
                                          answer: data.answer,
                                        },
                                        source: Source.BUBBLE,
                                        bookId: bookId,
                                        leadChatSession:
                                          currentChatSession &&
                                          currentChatSession.id,
                                      });
                                      setShowEscEmailPopup(true);
                                      setQuestionId(data._id);
                                      setEscAnswer(data.answer);
                                      setEscQuestion(data.question);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                                {showChatLive && (
                                  <img
                                    width="25"
                                    src={ChatLiveIcon}
                                    alt="..."
                                    onClick={() => {
                                      trackEvent({
                                        email: emailValue || "PUBLIC",
                                        eventCategory: EventCategory.CHATBOT,
                                        event:
                                          BubbleChatbotEvents.USER_USED_LIVE_CHAT_ESCALATE_ON_BUBBLE_CHAT,
                                        subCategory: SubCategory.EVENT,
                                        rawData: {
                                          question: data.question,
                                          answer: data.answer,
                                        },

                                        source: Source.BUBBLE,
                                        bookId: bookId,
                                        leadChatSession:
                                          currentChatSession &&
                                          currentChatSession.id,
                                      });
                                      chatLive(currentChatSession.id, bookId);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                              </div>
                            </BubbleChatStyles.RatingSection>
                          ) : null}
                        </BubbleChatStyles.SignleQuestionAnswerSection>
                      );
                    })}

                {/* ---------------here the Inline Form Component ------------- */}
                {!userLoggedInChatBot &&
                  isInlineForm &&
                  (showLeadForm === "optional" ||
                    showLeadForm === "required" ||
                    (!isResponseOn && showConditionalLeadForm)) && (
                    <div
                      style={{
                        alignSelf: isMessageInterface
                          ? "flex-end"
                          : "flex-start",
                        maxWidth: isMessageInterface ? "80%" : "100%",

                        flexDirection: isMessageInterface
                          ? "row-reverse"
                          : "row",
                      }}
                    >
                      <img
                        width="20"
                        src={ChatBotIcon}
                        alt="user_image"
                        style={{
                          display: "flex",
                          alignSelf: "baseline",
                        }}
                      />
                      &nbsp; &nbsp;
                      <BubbleChatStyles.ResponseSection
                        style={{
                          background: leadThemeSettings.answerBackgroundColor
                            ? leadThemeSettings.answerBackgroundColor
                            : "#fff",
                          alignSelf: isMessageInterface ? "end" : "",
                        }}
                      >
                        <InlineMethodLoginForm
                          emailValue={emailValue}
                          nameValue={nameValue}
                          phoneValue={phoneValue}
                          bookId={bookId}
                          chatbotUserEmail={emailValue}
                          currentChatSession={currentChatSession}
                          validatedLoginSettings={validatedLoginSettings}
                          createUpdateChatSession={createUpdateChatSession}
                          setNameValue={setNameValue}
                          setEmailValue={setEmailValue}
                          setPhoneValue={setPhoneValue}
                          setShowLeadForm={setShowLeadForm}
                          leadSettings={leadSettings}
                          setCurrentSessionHistory={setAllQuestionsAndAnswers}
                          currentSessionHistory={allQuestionsandAnswers}
                        />
                      </BubbleChatStyles.ResponseSection>
                    </div>
                  )}
              </ChatSection>
            </ChatAreaSection>
            <BottomFormSection>
              <Formik
                initialValues={{
                  prompt: questionValue,
                }}
                onSubmit={(values, actions) => {
                  if (firstMessageAgreePopup && waiverSettings.isWaiver) {
                    setShowAgreePopup(true);
                  } else {
                    setIsResponseOn(true);
                    setQuestionValue("");
                    setShowSampleQuestions(false);
                    chatStart(questionValue);
                  }
                }}
                enableReinitialize
              >
                {({ errors, touched, isValidating, setFieldValue }: any) => (
                  <BubbleChatStyles.BookFetchForm ref={(el) => setFormRef(el)}>
                    <BubbleChatStyles.FieldSection>
                      <BubbleChatStyles.FormInput
                        name="prompt"
                        as="textarea"
                        // validate={validatePropmt}
                        // autocomplete="off"
                        placeholder={"Ask me anything"}
                        value={questionValue}
                        // error={errors.prompt ? true : false}
                        onChange={(e) => setQuestionValue(e.target.value)}
                        disabled={isResponseOn}
                        style={{
                          visibility:
                            !userLoggedInChatBot &&
                            isInlineForm &&
                            (showLeadForm === "optional" ||
                              showLeadForm === "required" ||
                              (!isResponseOn && showConditionalLeadForm))
                              ? "hidden"
                              : "visible",
                          paddingRight: "2.6rem",
                          resize: "none",
                          height: "40px",
                          border:
                            leadThemeSettings.questionTextFieldColor &&
                            "2px solid " +
                              leadThemeSettings.questionTextFieldColor,
                          color: leadThemeSettings.questionTextFieldColor
                            ? leadThemeSettings.questionTextFieldColor
                            : "#000",
                        }}
                        onKeyDown={(e) => {
                          // eslint-disable-next-line
                          if (e.keyCode == 13 && e.shiftKey == false) {
                            e.preventDefault();
                            if (!isResponseOn) formRef.requestSubmit();
                          }
                        }}
                      />

                      <BubbleChatStyles.FetchBookButton
                        type={
                          isResponseOn || questionValue.length === 0
                            ? "button"
                            : "submit"
                        }
                      >
                        <img
                          style={{
                            visibility:
                              !userLoggedInChatBot &&
                              isInlineForm &&
                              (showLeadForm === "optional" ||
                                showLeadForm === "required" ||
                                (!isResponseOn && showConditionalLeadForm))
                                ? "hidden"
                                : "visible",
                          }}
                          width={20}
                          src={SearchArrow}
                          alt="search"
                        />
                      </BubbleChatStyles.FetchBookButton>
                    </BubbleChatStyles.FieldSection>
                    <div style={{ justifyContent: "center" }}>
                      <a
                        href={
                          (chatbotDetails &&
                            chatbotDetails.footerBrandingLink) ||
                          "https://dropchat.co/"
                        }
                        rel="noreferrer"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <BubbleChatStyles.PoweredText>
                          {chatbotDetails && chatbotDetails.footerBrandingText
                            ? chatbotDetails.footerBrandingText
                            : "Powered By Dropchat"}
                        </BubbleChatStyles.PoweredText>
                      </a>
                    </div>
                  </BubbleChatStyles.BookFetchForm>
                )}
              </Formik>
            </BottomFormSection>
          </>
        )}
      </>
      <AgreeComponent
        submitTerms={submitTerms}
        showAgreePopup={showAgreePopup}
        waiverSettings={waiverSettings}
      />
      <EscalateEmailPopup
        showEscEmailPopup={showEscEmailPopup}
        setShowEscEmailPopup={setShowEscEmailPopup}
        questionId={questionId}
        bookId={bookId}
        escAnswer={escAnswer}
        escQuestion={escQuestion}
        escSource="BUBBLE"
        chatbotUserEmail={chatbotUserEmail}
        emailValue={emailValue}
        title={escTitle}
        leadChatSession={currentChatSession}
        userLoggedDetails={userLoggedDetails && userLoggedDetails}
        userFullName={userFullName && userFullName}
      />
    </>
  );
};
export default BookBubbleChat;
