import { Field, FieldArray, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import Slider from "react-rangeslider";
import styled from "styled-components";
import { UserContext } from "../../context/UserContext";
import { AdminContext } from "../../context/admin-context";
import myAxios from "../../myaxios";
import { AdminSetting } from "../../types";
import { parseErrorResponse } from "../../utils/utils";
import { ADMIN_LLM_SETTINGS } from "../../utils/class-names";
// To include the default styles

// import ChartContainer from "../charts";

const AddAppFeatureContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
`;

const HeadingText = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #2f4c95;
`;

const Label = styled(FormLabel)`
  width: 46%;
  font-weight: bold;
`;

const ValueField = styled(Field)`
  width: 380px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  height: 200px;
  margin: 0px 0px 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const TextNumberField = styled(Field)`
  width: 380px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  margin: 0px 0px 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const TextValueField = styled(Field)`
  /* width: 380px; */
  width: 56px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  margin: 0px 0px 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const AppSettingsForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const UpadateBtn = styled.button`
  border: none;
  width: 133px;
  height: 40px;
  background: #2f4c95;
  border-radius: 5px;
  color: white;
  /* margin: 23px 30px; */
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 8px 20px;
  &:hover {
    background: #0e2151;
    border: none;
  }
`;
const DivForSubmitBtn = styled.div`
  display: flex;
  justify-content: end;
`;

const OpenSettingContainer = styled.div`
  flex-direction: column;
`;

const ErrorTag = styled(Col)`
  color: red;
  margin: 0px 6px;
  font-size: 11px;
  padding: 3px 0px;
  display: flex;
  justify-content: center;

  @media (max-width: 450px) and (min-width: 320px) {
    margin: 0px 6px;
    font-size: 10px;
  }
`;
const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;
const ValueSelectField = styled.select`
  width: 380px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;
const LLMSettings = () => {
  const { user } = useContext(UserContext);
  const { operandSettings, setOperandSettings } = useContext(AdminContext);
  const [operandSettingErrors, setOperandSettingErrors] = useState<
    AdminSetting[]
  >([]);
  // const [loading, setLoading] = useState<boolean>(false);
  const [tempretureValue, setTempretureValue] = useState<number>(0);
  const [topPValue, setTopPValue] = useState<number>(0);

  const saveAppSettings = (values: any) => {
    // setLoading(true);
    myAxios
      .post(`admin-settings/save-update-admin-settings`, {
        type: "LLM-SETTINGS",
        data: [...values.operandSettings],
      })
      .then((response) => {
        if (response.data.success) {
          NotificationManager.success(
            "Settings save successfully...",
            "",
            1500
          );
          if (response.data && response.data.data) {
            setOperandSettings([...values.operandSettings]);
          }
        } else {
          NotificationManager.error(response.data.message, "", 2000);
        }
        // setLoading(false);
      })
      .catch((err) => {
        // console.log(
        //   "Getting Error At the time of Save Operand Settings --- ",
        //   err
        // );
        NotificationManager.error(parseErrorResponse(err));
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (user) {
      // setLoading(true);
      setTimeout(() => {
        myAxios
          .get(
            `${process.env.REACT_APP_SERVER_URL}/admin-settings/LLM-SETTINGS`
          )
          .then((response) => {
            if (response.data && response.data.data) {
              // console.log("response.data.data ", response.data.data);
              setOperandSettings(response.data.data);

              response.data.data.forEach((dt: any) => {
                if (dt.key === "temperature") {
                  setTempretureValue(dt.value);
                }
                if (dt.key === "top_p") {
                  setTopPValue(dt.value);
                }
              });
            }
            // setLoading(false);
          })
          .catch((error) => {
            // setLoading(false);
            // console.error(error, "error in getting app features");
          });
      }, 1000);
    }
  }, [user, setOperandSettings]);

  return (
    <>
      <Row>
        <Col style={{ flexDirection: "column" }}>
          <AddAppFeatureContainer>
            <HeadingText>LLM Settings</HeadingText>
          </AddAppFeatureContainer>
          <Formik
            enableReinitialize={true}
            validateOnChange={true}
            initialValues={{
              operandSettings: operandSettings && operandSettings,
            }}
            validate={(values) => {
              const errors: {
                openaiSettings?: AdminSetting[];
              } = {};

              let openSettErrors: AdminSetting[] = [];
              let isError = false;
              values.operandSettings.forEach((operandSetting: AdminSetting) => {
                const val = operandSetting.value + "";
                if (val && val.length > 0) {
                  openSettErrors.push({ value: "" });
                } else {
                  isError = true;

                  openSettErrors.push({
                    value: `"${operandSetting.key}" value is required`,
                  });
                }
              });

              setOperandSettingErrors(openSettErrors);
              if (isError) {
                errors.openaiSettings = openSettErrors;
                // errors.subTemplates = [subtemplErrors]
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              // console.log("values on submit ", values);
              saveAppSettings(values);
            }}
          >
            {({ setFieldValue }) => (
              <AppSettingsForm className={ADMIN_LLM_SETTINGS}>
                <FieldArray name="appSettings">
                  {(fieldArrayProps) => {
                    const { form } = fieldArrayProps;
                    const { values } = form;
                    const { operandSettings } = values;

                    return (
                      <OpenSettingContainer>
                        {operandSettings &&
                          operandSettings.map(
                            (operandSetting: any, index: number) => (
                              <>
                                <div key={index}>
                                  {operandSetting.inputType === "textarea" ? (
                                    <Col>
                                      {/* <Label>File Processing Message</Label> */}
                                      <Label>
                                        {operandSetting.placeHolder}
                                      </Label>
                                      <ValueField
                                        type="text"
                                        component="textarea"
                                        name={`operandSettings[${index}].value`}
                                      />
                                    </Col>
                                  ) : operandSetting.inputType ===
                                    "rangeSlider" ? (
                                    <Col>
                                      {/* <Label>File Processing Message</Label> */}
                                      <Label>
                                        {operandSetting.placeHolder}
                                      </Label>
                                      <div className="slider">
                                        <Slider
                                          max={
                                            operandSetting.key === "temperature"
                                              ? 2
                                              : 1
                                          }
                                          min={0}
                                          step={0.05}
                                          tooltip={false}
                                          value={
                                            operandSetting.key === "temperature"
                                              ? tempretureValue
                                              : topPValue
                                          }
                                          onChange={(value) => {
                                            // console.log("value -- ", value);

                                            if (
                                              operandSetting.key ===
                                              "temperature"
                                            ) {
                                              setTempretureValue(
                                                Number(
                                                  parseFloat(
                                                    `${value}`
                                                  ).toFixed(2)
                                                )
                                              );
                                            } else if (
                                              operandSetting.key === "top_p"
                                            ) {
                                              setTopPValue(
                                                Number(
                                                  parseFloat(
                                                    `${value}`
                                                  ).toFixed(2)
                                                )
                                              );
                                            }

                                            setFieldValue(
                                              `operandSettings[${index}].value`,
                                              parseFloat(`${value}`).toFixed(2)
                                            );
                                          }}
                                        />
                                      </div>
                                      <TextValueField
                                        type="number"
                                        min={`operandSettings[${index}].min`}
                                        max={`operandSettings[${index}].max`}
                                        step="0.05"
                                        presicion={2} //very important
                                        novalidate
                                        name={`operandSettings[${index}].value`}
                                        onChange={(e: any) => {
                                          let { value, min, max } = e.target;
                                          //  console.log("value 1 -- ", value);
                                          // value = Number(value);
                                          // console.log("value 1 -- ", value);

                                          //const max = operandSettings[index].max;

                                          const maxLimit = Number(
                                            operandSettings[index].max
                                          );

                                          if (value < 0 || value > maxLimit)
                                            value = Math.max(
                                              Number(min),
                                              Math.min(
                                                Number(max),
                                                Number(value)
                                              )
                                            );

                                          if (
                                            operandSetting.key === "temperature"
                                          ) {
                                            setTempretureValue(value);
                                          } else if (
                                            operandSetting.key === "top_p"
                                          ) {
                                            setTopPValue(value);
                                          }

                                          //  setTempretureValue(value);
                                          setFieldValue(
                                            `operandSettings[${index}].value`,
                                            value
                                          );
                                        }}
                                      />
                                    </Col>
                                  ) : operandSetting.inputType === "number" ? (
                                    <Col>
                                      {/* <Label>File Processing Message</Label> */}
                                      <Label>
                                        {operandSetting.placeHolder}
                                      </Label>

                                      <TextNumberField
                                        type="number"
                                        name={`operandSettings[${index}].value`}
                                      />
                                    </Col>
                                  ) : operandSetting.inputType === "radio" ? (
                                    <Col>
                                      <Label>
                                        {operandSetting.placeHolder}
                                      </Label>
                                      <FieldSection>
                                        <div id="my-radio-group"></div>
                                        <div
                                          role="group"
                                          aria-labelledby="my-radio-group"
                                        >
                                          <Label>
                                            <Field
                                              type="radio"
                                              name={`operandSettings[${index}].value`}
                                              value="true"
                                            />

                                            <span style={{ marginLeft: "8px" }}>
                                              {operandSetting.placeHolder}
                                            </span>
                                          </Label>
                                          <Label
                                            style={{
                                              marginLeft: "5px",
                                              paddingLeft: "5px",
                                            }}
                                          >
                                            <Field
                                              type="radio"
                                              name={`operandSettings[${index}].value`}
                                              value="false"
                                            />

                                            <span style={{ marginLeft: "8px" }}>
                                              {operandSetting.placeHolder1}
                                            </span>
                                          </Label>
                                        </div>
                                      </FieldSection>
                                    </Col>
                                  ) : operandSetting.inputType === "select" ? (
                                    <>
                                      <Label>
                                        <span>
                                          {operandSetting.placeHolder}
                                        </span>
                                      </Label>
                                      <ValueSelectField
                                        name={`operandSettings[${index}].value`}
                                        defaultValue={operandSetting.value}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `operandSettings[${index}].value`,
                                            e.target.value
                                          );
                                        }}
                                      >
                                        <option
                                          value="gpt-3.5-turbo"
                                          selected
                                        >
                                          gpt-3.5-turbo
                                        </option>
                                        <option value="gpt-3.5-turbo-16k">
                                          gpt-3.5-turbo-16k
                                        </option>
                                        <option value="gpt-4o">gpt-4o</option>
                                      </ValueSelectField>
                                    </>
                                  ) : (
                                    <Col>
                                      {/* <Label>File Processing Message</Label> */}
                                      <Label>
                                        {operandSetting.placeHolder}
                                      </Label>
                                      <TextNumberField
                                        type="text"
                                        name={`operandSettings[${index}].value`}
                                      />
                                    </Col>
                                  )}
                                </div>
                                {operandSettingErrors &&
                                  operandSettingErrors.length > 0 &&
                                  operandSettingErrors[index] &&
                                  operandSettingErrors[index].value && (
                                    <Row>
                                      <ErrorTag>
                                        {operandSettingErrors[index].value}
                                      </ErrorTag>
                                    </Row>
                                  )}
                              </>
                            )
                          )}
                      </OpenSettingContainer>
                    );
                  }}
                </FieldArray>

                <DivForSubmitBtn>
                  <UpadateBtn type="submit">Update</UpadateBtn>
                </DivForSubmitBtn>
              </AppSettingsForm>
            )}
          </Formik>
        </Col>
        <Col sm={4}></Col>
      </Row>
    </>
  );
};

export default LLMSettings;
