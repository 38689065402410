import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";

const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 1rem;
`;

const InputField = styled.input`
  height: 46px;
  width: 100%;
  padding: 0.5rem;
  background: #eaeaea;
  border: 1px solid #eaeaea;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  font-size: 14px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const ChatbotHeaderTitle = () => {
  const formik = useFormikContext();
  const { values } = useFormikContext<any>();
  return (
    <>
      <Row style={{ alignItems: "center" }}>
        <Col sm={8} style={{ flexDirection: "column" }}>
          <MenuHeading>Chatbot Header Title</MenuHeading>
        </Col>
      </Row>
      <Row>
        <SubHeading>
          This title will show on all chat screen types. (Share, Bubble, iframe
          Chat)
        </SubHeading>
      </Row>
      <Row>
        <Col sm={12} style={{ flexDirection: "column" }}>
          <InputField
            style={{ marginBottom: "0.5rem" }}
            type="text"
            name="bookSetting.chatbotHeaderTitle"
            value={values.bookSetting && values.bookSetting.chatbotHeaderTitle}
            onChange={(e: any) =>
              formik.setFieldValue(
                "bookSetting.chatbotHeaderTitle",
                e.target.value
              )
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default ChatbotHeaderTitle;
