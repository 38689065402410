import { createContext, ReactElement, useState } from "react";
import { LoaderProps, LoaderValues } from "../types";

export const LoaderContext = createContext({} as LoaderValues);

const LoaderContextProvider = (
  props: LoaderProps
): ReactElement<LoaderValues> => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [loaderMessage, setLoaderMessage] = useState<string>(
    "Verifying Credentials. Please Wait..."
  );

  return (
    <LoaderContext.Provider
      value={{
        setShowLoader,
        showLoader,
        setLoaderMessage,
        loaderMessage,
      }}
    >
      {props.children}
    </LoaderContext.Provider>
  );
};

export default LoaderContextProvider;
