import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MEDIUM } from "../../../constants/desktop";
import { MOBILE } from "../../../constants/mobile";
import { TAB } from "../../../constants/tab";
import { BookContext } from "../../../context/BookContext";
import { UserContext } from "../../../context/UserContext";
import { LoaderContext } from "../../../context/loader-context";
import { PlanContext } from "../../../context/plan-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../../event-utils/dropchat-event-track";
import { trackEvent } from "../../../event-utils/track-event";
import DeleteIcon from "../../../images/trash.svg";
import PDFIcon from "../../../images/youtube-icon.svg";
import myAxios from "../../../myaxios";
import ShopifyIcon from "../../../images/icons_shopify.svg";
import { ADD_YOUTUBE_SOURCE_URL } from "../../../utils/class-names";
import { matchYoutubeUrl, parseErrorResponse } from "../../../utils/utils";
import IndexCharacterLimitPopUp from "../../index-character-limit-popup";
import GetShopifyProducts from "../../manage-setting/components/get-shopify-products";
import { DataTable } from "primereact/datatable";
import ShowLargeImagePopup from "../../shopify/show-large-image-popup";

const ParentDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const EmptyDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;
const SubscriptionExpireOverlay = styled.div`
  position: absolute;
  height: 50vh;
  width: 50vw;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;

  @media (max-width: ${MOBILE.maxWidth}px) {
    position: fixed;
    bottom: 25vh;
    width: 80vw;
    right: 10vw;
  }
`;
const ParentSection = styled.div`
  width: 100%;
  height: 78vh;
`;
const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
`;
const InnerSection = styled.div`
  background: #f8f7fa;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
`;

const ChatbotSettingsSection = styled(Form)`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  & label {
    color: #33303c;
    font-weight: 600;
  }
`;
interface SaveButtonProps {
  displayProp?: boolean; // Declare the custom prop and its type
}

const SaveButton = styled.button<SaveButtonProps>`
  background: #1585b5;
  padding: 0.4rem 3rem;
  color: #fff;
  border: 1px solid transparent;
  width: auto;
  display: flex;
  margin-top: auto;
  justify-content: center;
  display: ${(props) => (props.displayProp ? " flex" : " none")};
  &:disabled {
    display: ${(props) => (props.displayProp ? " flex" : " none")};
    opacity: ${(props) => (props.displayProp ? " 0.5" : " 0")};
    cursor: not-allowed;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 96vw;
    margin-top: 0.2rem;
  }
`;

const AddLinkButton = styled.button`
  background: #1585b5;
  padding: 0.4rem 3rem;
  color: #fff;
  border: 1px solid transparent;
  width: max-content;
  margin-top: 1rem;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
`;

const FooterSection = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px #0000001a;
  padding: 0.5rem;
  margin-top: auto;
  justify-content: space-between;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    justify-content: space-between;
    flex-direction: column-reverse;
  }
`;
const FooterInnerDiv = styled.div`
  align-items: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: flex;
    flex-direction: column;
    // align-items: end;
  }
  @media (min-width: ${TAB.minWidth}px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    flex-direction: row;
  }
`;

const CancelButton = styled.button`
  background: none;
  padding: 0.4rem 3rem;
  color: #2f3349;
  border: 1px solid #2f3349;
  width: auto;
  display: flex;
  margin-top: auto;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const SelectedSourceDetails = styled.div`
  flex-direction: row;
  padding: 1rem;
  border-bottom: 1px solid #eaeaea;
  align-items: center;
  display: flex;
  & span {
    margin: 0rem 5rem 0rem 1rem;
    font-weight: normal;
    color: #33303c;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem 0;
  }
`;

const DeleteImage = styled.img`
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 21px;
  }
`;

const UpgradeSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 5px 25px;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
`;

const PlanSpan = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #585858;
  margin-left: 5px;
  margin-top: 1px;
  display: flex;
  align-items: center;
`;
const YoutubeLinkSpan = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin: 0 !important;
  }

  @media (min-width: ${TAB.minWidth}px) {
    margin: 0rem 5rem 0rem 1rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin: 0;
  }
`;
const SelectedCount = styled.span`
  color: #000;
  font-size: 13px;
  font-weight: 500;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: space-around !important;
    display: flex;
    font-size: 13px;
    padding-left: 1.5rem;
    padding-right: "0rem";
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: space-around !important;
    display: flex;
    font-size: 11px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    color: #000;
    font-size: 13px;
    font-weight: bold;
    padding-left: 1rem;
    align-items: center;
    display: flex;
  }
`;
const CustomButton = styled.button`
  background: #f2ae01;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 15px;
  margin-left: 1rem;
  &:disabled {
    display: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: 0;
    padding: 0.5rem;
    font-size: 13px;
    width: 96vw;
    margin-top: 0.2rem;
  }
`;
const InnerDiv = styled.div`
  height: 100%;
  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column;
  }

  @media (min-width: ${TAB.minWidth}px) {
    flex-direction: column;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    flex-direction: row;
  }
`;
const FormLabels = styled(FormLabel)`
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0;
    border-left: none;
  }

  @media (min-width: ${TAB.minWidth}px) {
    border-left: none;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    border-left: none;
  }
`;
const Column = styled(Col)`
  border-left: "1px solid #2f334933";
  @media (max-width: ${MOBILE.maxWidth}px) {
    border-left: none;
  }
  @media (min-width: ${TAB.minWidth}px) {
    border-left: none;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    border-left: 1px solid #2f334933;
  }
`;

const ParentListUI = styled.div`
  padding: 0.5rem;
  flex-direction: column;
  & .p-datatable-wrapper {
    width: 100%;
    overflow: auto;
    height: 58vh;
  }
`;

const PaginationButton = styled.button`
  background: #1585b5;
  color: #fff;
  padding: 5px 15px;
  border: none;
  margin-left: 10px;
`;

const LeftContent = styled.div`
  flex-direction: column;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
`;

const TotalProductCount = styled.p`
  font-weight: bold;
  margin: 0;
`;

const ProductListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchBar = styled.input`
  padding: 8px;
  background: #f8f7fa;
  border: 1px solid #eaeaea;
  width: 20rem;
  &:focus {
    outline: none;
  }
`;
const SearchSection = styled.div`
  margin: 1rem 0rem;
`;
const SearchButton = styled.button`
  background: #1585b5;
  color: #fff;
  padding: 5px 15px;
  border: none;
`;
const PaginationUI = styled.div`
  display: flex;
  align-self: flex-start;
`;

const LimitSection = styled.select`
  margin-left: 1rem;
`;

const LimitSectionOption = styled.option``;

type YTLink = {
  YTURL: string;
  characterCount: number;
};

const AddShopifyProductsSource = ({ setShowAddNewSourcePage }: any) => {
  const navigate = useNavigate();
  const { user, updateUserSubscription } = useContext(UserContext);
  const { setPlanHighliter } = useContext(PlanContext);
  const { setShowLoader } = useContext(LoaderContext);
  const { selectedBook, updateBookIndex } = useContext(BookContext);
  const [showLimitPopUp, setShowLimitPopUp] = useState<boolean>(false);
  const [disableShopifyProductsLimit, setDisableShopifyProductsLimitState] =
    useState<boolean>(false);
  const [shopifyData, setShopifyData] = useState<any>();
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [selectedProductIds, setSelectedProductIds] = useState(null);
  const [allParams, setAllParams] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(5);
  const [productPopup, setProductPopup] = useState<any>(null);

  const getShopifyProductList = () => {
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/shopify-product-list", {
        limit,
      })
      .then((response: any) => {
        if (response && response.data && response.data.success) {
          setShowLoader(false);
          setShopifyData(response.data.data);
          setAllParams([{ page_info: response.data.data.params.page_info }]);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to fetch the products!"
          );
          setShowLoader(false);
        }
      })
      .catch((error: any) => {
        setShowLoader(false);
      });
  };

  const imageBody = (data: any, idx: any) => {
    let imageOfProduct = data && data.image && data.image.src;
    return (
      <>
        <img
          style={{ cursor: imageOfProduct ? "pointer" : "", width: "30px" }}
          src={imageOfProduct || ShopifyIcon}
          alt={"product image"}
          className="w-4rem"
          onClick={(e: any) => {
            imageOfProduct && setProductPopup(idx.rowIndex);
            e.stopPropogation();
          }}
        />
        {productPopup === idx.rowIndex && (
          <ShowLargeImagePopup
            setProductPopup={setProductPopup}
            productData={data}
            fromAPI={true}
          />
        )}
      </>
    );
  };

  const titleBody = (data: any) => {
    return (
      <div style={{ flexDirection: "column" }}>
        <p style={{ margin: 0 }}>{data.title}</p>
        <span
          style={{ fontSize: "0.8rem", color: "#11b753", fontWeight: "bold" }}
        >
          {data.isHaveChatbot ? "Already Indexed" : ""}
        </span>
      </div>
    );
  };

  const nextButtonClicked = () => {
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/shopify-product-list", {
        nextPageParameters: {
          limit,
          ...allParams[currentPage - 1],
        },
      })
      .then((response: any) => {
        if (response && response.data && response.data.success) {
          setShowLoader(false);
          setShopifyData(response.data.data);
          if (
            response.data.data &&
            response.data.data.params &&
            response.data.data.params.page_info
          ) {
            setAllParams([
              ...allParams,
              { page_info: response.data.data.params.page_info },
            ]);
          }
          setCurrentPage(currentPage + 1);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to fetch the products!"
          );
          setShowLoader(false);
        }
      })
      .catch((error: any) => {
        setShowLoader(false);
      });
  };

  const previousButtonClicked = () => {
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/shopify-product-list", {
        nextPageParameters: {
          limit,
          ...allParams[currentPage - 3],
        },
      })
      .then((response: any) => {
        if (response && response.data && response.data.success) {
          setShowLoader(false);
          setShopifyData(response.data.data);
          setCurrentPage(currentPage - 1);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to fetch the products!"
          );
          setShowLoader(false);
        }
      })
      .catch((error: any) => {
        setShowLoader(false);
      });
  };

  const submitChatbot = () => {
    setShowLoader(true);

    let submitValues = {
      title: ``,
      json: selectedProducts,
      type: "shopify",
      shopifyProductIds: selectedProductIds,
    };
    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL +
          `/books/add-source/${selectedBook?._id}`,
        [submitValues]
      )
      .then((response) => {
        // console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          setShowLoader(false);
          updateBookIndex(response.data.data);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Source saved successfully."
          );
          setShowAddNewSourcePage(false);
          myAxios
            .get(`${process.env.REACT_APP_SERVER_URL}/plans`)
            .then((res) => {
              updateUserSubscription(res.data.data.currentSubscription);
            });
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  // console.log("selectedProducts is at Start, ", selectedProducts);

  useEffect(() => {
    const productShopifyUserdLimitCount = user?.currentSubscription?.meta
      ?.shopifyProductCount
      ? user?.currentSubscription?.meta?.shopifyProductCount
      : 0;

    const selectAndUsedProductCount =
      (selectedProducts?.length || 0) +
      (user?.currentSubscription?.planStatics.shopifyProductUsed || 0);

    if (selectAndUsedProductCount > productShopifyUserdLimitCount) {
      setDisableShopifyProductsLimitState(true);
    } else {
      setDisableShopifyProductsLimitState(false);
    }
  }, [selectedProducts, setSelectedProducts]);

  const fetchLimitProducts = (limit: any) => {
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/shopify-product-list", {
        limit,
      })
      .then((response: any) => {
        if (response && response.data && response.data.success) {
          setShowLoader(false);
          setShopifyData(response.data.data);
          setAllParams([{ page_info: response.data.data.params.page_info }]);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to fetch the products!"
          );
          setShowLoader(false);
        }
      })
      .catch((error: any) => {
        setShowLoader(false);
      });
  };

  return (
    <ParentSection>
      <Col sm={12} xs={12} style={{ flexDirection: "column" }}>
        <InnerDiv style={{ height: "100%" }}>
          <Col md={12} lg={4} sm={12} xs={12}>
            <GetShopifyProducts getShopifyProductList={getShopifyProductList} />
          </Col>
          {shopifyData && shopifyData.count > 0 && (
            <Column
              sm={12}
              xs={12}
              lg={8}
              style={{
                flexDirection: "column",
              }}
            >
              <ParentListUI>
                <ProductListHeader>
                  <LeftContent>
                    <TotalProductCount>
                      Total Fetched Products -{" "}
                      {shopifyData && shopifyData.count}
                    </TotalProductCount>
                  </LeftContent>
                  <PaginationUI>
                    <PaginationButton
                      type="button"
                      onClick={() => previousButtonClicked()}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </PaginationButton>
                    {/* <PaginationButton type="button">1</PaginationButton>
                <PaginationButton type="button">2</PaginationButton>
                <PaginationButton type="button">3</PaginationButton> */}
                    <PaginationButton
                      type="button"
                      onClick={() => nextButtonClicked()}
                      disabled={
                        currentPage ===
                        Math.ceil(shopifyData && shopifyData.count / limit)
                      }
                    >
                      Next
                    </PaginationButton>
                    <LimitSection
                      onChange={(e: any) => {
                        // console.log("values is, ", e.target.value)
                        setLimit(e.target.value);
                        fetchLimitProducts(e.target.value);
                      }}
                      defaultValue={limit}
                    >
                      <LimitSectionOption value={5}>5 Rows</LimitSectionOption>
                      <LimitSectionOption value={10}>
                        10 Rows
                      </LimitSectionOption>
                      <LimitSectionOption value={15}>
                        15 Rows
                      </LimitSectionOption>
                      <LimitSectionOption value={20}>
                        20 Rows
                      </LimitSectionOption>
                    </LimitSection>
                  </PaginationUI>
                </ProductListHeader>
                <DataTable
                  showGridlines
                  value={shopifyData && shopifyData.products}
                  selection={selectedProducts}
                  onSelectionChange={(e: any) => {
                    setSelectedProducts(e.value);
                    const productIds = e.value.map(
                      (product: any) => product.id
                    );
                    setSelectedProductIds(productIds);
                  }}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                  ></Column>
                  <Column header="Image" body={imageBody}></Column>
                  <Column header="Name" body={titleBody}></Column>
                  <Column field="body_html" header="Description"></Column>
                </DataTable>
              </ParentListUI>
            </Column>
          )}
        </InnerDiv>
        <FooterSection>
          <CancelButton
            type="button"
            onClick={() => setShowAddNewSourcePage(false)}
          >
            Cancel
          </CancelButton>
          {user?.currentSubscription ? (
            user?.currentSubscription.meta?.integrationApps ? (
              <FooterInnerDiv>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <SelectedCount style={{ marginRight: ".8rem" }}>
                    {selectedProducts && selectedProducts.length > 1
                      ? "Products Included " +
                        "(" +
                        selectedProducts.length +
                        ")"
                      : "Product Included " +
                        "(" +
                        selectedProducts.length +
                        ")"}
                  </SelectedCount>
                  <SelectedCount
                    style={{
                      color: disableShopifyProductsLimit ? "red" : "grey",
                    }}
                  >
                    {(selectedProducts?.length || 0) +
                      (user?.currentSubscription?.planStatics
                        .shopifyProductUsed || 0)}{" "}
                    {" / "}
                    {user?.currentSubscription?.meta?.shopifyProductCount
                      ? user?.currentSubscription?.meta?.shopifyProductCount
                      : 0}{" "}
                    {disableShopifyProductsLimit
                      ? "Products limit exceeds"
                      : "Products"}
                  </SelectedCount>
                </div>
                <SaveButton
                  disabled={selectedProducts.length <= 0}
                  displayProp={disableShopifyProductsLimit ? false : true}
                  type="button"
                  onClick={() => submitChatbot()}
                >
                  Add Data Source
                </SaveButton>
                <CustomButton
                  type="button"
                  disabled={!disableShopifyProductsLimit}
                  onClick={() => {
                    setShowLimitPopUp(true);
                    setPlanHighliter({
                      isBookHighliter: false,
                      bookLimit: 0,
                      isDeleteBookHighliter: false,
                      isFlashcardHighliter: false,
                      isCharacterLimit: false,
                      pdfLimit: 0,
                      isDeleteAccount: false,
                      isLeadLoginSettings: false,
                      isAllowYTIndex: false,
                      isShopifyProduct: true,
                    });
                  }}
                >
                  Upgrade Plan
                </CustomButton>
              </FooterInnerDiv>
            ) : (
              <div>
                <SaveButton
                  type="button"
                  style={{ opacity: 0.5, cursor: "not-allowed" }}
                >
                  Add Data Source
                </SaveButton>
                <div style={{ paddingLeft: "2rem" }}>
                  <UpgradeSpan
                    onClick={(e) => {
                      setPlanHighliter({
                        isBookHighliter: false,
                        bookLimit: 0,
                        isDeleteBookHighliter: false,
                        isFlashcardHighliter: false,
                        isCharacterLimit: false,
                        pdfLimit: 0,
                        isDeleteAccount: false,
                        isLeadLoginSettings: false,
                        isAllowYTIndex: true,
                      });
                      navigate("/subscription");
                    }}
                  >
                    Upgrade Plan
                  </UpgradeSpan>
                  {/* <PlanSpan>Your plan</PlanSpan> */}
                </div>
              </div>
            )
          ) : (
            <Col>
              <SaveButton
                type="button"
                style={{ opacity: 0.5, cursor: "not-allowed" }}
              >
                Add Data Source
              </SaveButton>
              <div style={{ paddingLeft: "2rem" }}>
                <UpgradeSpan
                  onClick={(e) => {
                    setPlanHighliter({
                      isBookHighliter: false,
                      bookLimit: 0,
                      isDeleteBookHighliter: false,
                      isFlashcardHighliter: false,
                      isCharacterLimit: false,
                      pdfLimit: 0,
                      isDeleteAccount: false,
                      isLeadLoginSettings: false,
                      isAllowYTIndex: true,
                    });
                    navigate("/subscription");
                  }}
                >
                  Purchase Plan
                </UpgradeSpan>
              </div>
            </Col>
          )}
        </FooterSection>
      </Col>
      {showLimitPopUp ? (
        <ParentDiv>
          <EmptyDiv
            onClick={() => {
              setShowLimitPopUp(false);
            }}
          ></EmptyDiv>
          <SubscriptionExpireOverlay>
            <IndexCharacterLimitPopUp
              titleContent={"Shopify Product limit exceeded"}
              subHeadingContent={"Shopify Product limit exceeded"}
              mainContent={`Please note that you have exceeded the Shopify Product count limit of
                 your current subscription plan. To resolve this, you can consider upgrading your plan for a
                 higher products limit.`}
              setShowLimitPopUp={setShowLimitPopUp}
            ></IndexCharacterLimitPopUp>
          </SubscriptionExpireOverlay>
        </ParentDiv>
      ) : null}
    </ParentSection>
  );
};

export default AddShopifyProductsSource;
