import moment from "moment-timezone";

const CreatedAtColumn = ({ dataSource }: any) => {
  return (
    <>
      <span>{moment(dataSource.createdAt).format("DD/MM/YYYY | hh:mm A")}</span>
    </>
  );
};

export default CreatedAtColumn;
