import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { LoaderContext } from "../../context/loader-context";
import myAxios from "../../myaxios";

const SaveBookButton = styled(Button)`
  width: 100%;
`;

const DataTableColumn = styled(Col)`
  flex-direction: column;
  & td {
    padding: 0rem 1rem !important;
  }
`;

const OpenAiQuestionSummary = () => {
  const [requestData, setRequestData] = useState({
    date: "",
    chatGptCount: "",
    operandCount: "",
    questionHistoriesChatgpt: [],
    questionHistoriesOperand: [],
  });
  const { setShowLoader } = useContext(LoaderContext);

  const loadQuestionHistoryData = () => {
    setShowLoader(true);
    myAxios
      .get(`${process.env.REACT_APP_SERVER_URL}/question-history`)
      .then((response) => {
        if (response.data && response.data.success) {
          setRequestData(response.data.data);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        // setLoading(false);
        // console.error(
        //   "Gettings error at the time of updating status of book ",
        //   error
        // );
        setShowLoader(false);
      });
  };

  useEffect(() => {
    loadQuestionHistoryData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row style={{ alignItems: "center" }}>
        <Col sm={2}>
          <b>Date:</b> <span>&nbsp; {requestData.date}</span>
        </Col>
        <Col sm={2}>
          <b>Total Chat GPT Requests:</b>{" "}
          <span>&nbsp; {requestData.chatGptCount}</span>
        </Col>
        <Col sm={2}>
          <b>Total Operand Requests:</b>{" "}
          <span>&nbsp; {requestData.operandCount}</span>
        </Col>
        <Col sm={2}>
          <SaveBookButton
            type="button"
            onClick={() => loadQuestionHistoryData()}
          >
            Refresh Data
          </SaveBookButton>
        </Col>
        <br />
        <br />
      </Row>
      <Row style={{ marginTop: "1rem" }}>
        <DataTableColumn sm={6}>
          <h5>Chat GPT Requests</h5>
          <div className="questionRequestSummaryTable">
            <DataTable
              value={requestData.questionHistoriesChatgpt}
              paginator
              rows={25}
              rowsPerPageOptions={[25, 50, 100, 200]}
            >
              <Column field="time" header="Time"></Column>
              <Column field="noOfRequest" header="Number of Request"></Column>
              <Column field="totalPromptTokens" header="Prompt Tokens"></Column>
              <Column
                field="totalCompletionTokens"
                header="Completion Tokens"
              ></Column>
              <Column field="totalTokens" header="Total Tokens"></Column>
            </DataTable>
          </div>
        </DataTableColumn>
        <DataTableColumn sm={6}>
          <h5>Operand Requests</h5>
          <div className="questionRequestSummaryTable">
            <DataTable
              value={requestData.questionHistoriesOperand}
              paginator
              rows={25}
              rowsPerPageOptions={[25, 50, 100, 200]}
            >
              <Column field="time" header="Time"></Column>
              <Column field="noOfRequest" header="Number of Request"></Column>
            </DataTable>
          </div>
        </DataTableColumn>
      </Row>
    </>
  );
};

export default OpenAiQuestionSummary;
