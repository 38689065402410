import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { MEDIUM, MOBILE, TAB } from "../constants/breakpoints";
import {
  EventCategory,
  Source,
  SubCategory,
  UserEvents,
} from "../event-utils/dropchat-event-enums";
import { trackEvent } from "../event-utils/track-event";
import MagicArrowBtn from "../images/MagicArrow.svg";
import MagicBlue from "../images/magicblue.svg";
import Spinner from "../images/spinner.svg";
import { isTestUser, mixPanel } from "../utils/utils";
const MainDiv = styled.div`
  width: 100%;
  flex-direction: column;
`;

// const TheForm = styled.form`
//   width: 100%;
// `;

const Label = styled.label`
  font-weight: 300;
  font-size: 14px;
  color: #33303c;
  margin-bottom: 2px;
`;

const DivForInput = styled.div`
  flex-direction: column;
  & span {
    color: #33303c;
    font-size: 14px;
  }
`;

const Input = styled.input`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(51, 48, 60, 0.6);
  border-radius: 4px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 16px;
    border-radius: 4px;
    height: 50px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 12px;
    padding: 0.5rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 14px;
    padding: 0.7rem;
  }
`;
const DivForBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 10px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 10px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-top: 20px;
  }
`;
const BtnPara = styled.span`
  color: #ffffff;
  font-weight: 600;
  padding: 0px 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 14px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 12px;
  }
`;

const MagicImg = styled.img`
  display: ${(props) => (props.className === "white" ? "block" : "none")};
`;

const CallToAction = styled.button`
  color: white;
  background: #1585b5;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  width: 100%;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    height: 50px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    padding: 0.5rem 1rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    padding: 1rem 3rem;
  }
`;

const DivForBtnParas = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerImg = styled.img`
  height: 25px;

  @media (max-width: ${MOBILE.maxWidth}px) {
    height: 25px;
  }
`;

const FormError = styled.div`
  margin: 0.2rem 0rem 0.2rem;
  text-align: left;
  color: red;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
  }
`;

const GreenSuccessLabel = styled.p`
  text-align: center;
  color: #fff;
  flex: 1;
  background: green;
  padding: 2px 4px 4px 4px;
`;

const EmailForm = ({ onEmailSubmit, disabled, error }) => {
  const [email, setEmail] = useState("");
  const [queryParameters] = useSearchParams();

  let successValue = queryParameters.get("confirmed");

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    onEmailSubmit(email);
    if (!isTestUser(email))
      mixPanel(email, "Send Magic Link", "Send Magic Link Form");
  };

  useEffect(() => {
    if (successValue === "true") {
      setShowSuccessMsg(true);
    }
    setTimeout(() => {
      setShowSuccessMsg(false);
    }, 10000);
    // eslint-disable-next-line
  }, [setShowSuccessMsg]);

  useEffect(() => {
    // Added hubStaff script conditionly
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = `//js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_ID}.js`;
    // document.head.appendChild(script);
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(script, s);
    return () => {
      // Cleanup: remove the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <MainDiv>
        {showSuccessMsg && (
          <Row>
            <Col>
              <GreenSuccessLabel>
                Your email has been successfully verified. Please login via
                registered credentials.
              </GreenSuccessLabel>
            </Col>
          </Row>
        )}
        <form
          onSubmit={handleSubmit}
          style={{ width: "100%" }}
          className="magic-link-form"
        >
          <Row>
            <Col>
              <Label>Email Address</Label> <br />
            </Col>
          </Row>
          <DivForInput>
            <Input
              // placeholder="Enter your email"
              size="sm"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {error && <FormError>{error}</FormError>}
            <span
              style={{
                color: "#33303C99",
                marginBottom: "2px",
              }}
            >
              We will send a link to your email.
            </span>
          </DivForInput>
          <DivForBtn>
            <CallToAction
              color="primary"
              size="sm"
              disabled={disabled}
              onClick={() => {
                handleSubmit();
                trackEvent({
                  email: email,
                  eventCategory: EventCategory.USER,
                  event: UserEvents.USER_TRY_TO_LOGIN_USING_MAGIC_LINK,
                  subCategory: SubCategory.EVENT,
                  source: Source.PUBLIC,
                });
              }}
            >
              <DivForBtnParas>
                <MagicImg className="white" src={MagicArrowBtn} alt="magic" />
                <MagicImg className="blue" src={MagicBlue} alt="magic" />
                <BtnPara>SEND MAGIC LINK</BtnPara>
                {disabled && <SpinnerImg src={Spinner} alt="Loading" />}
              </DivForBtnParas>
            </CallToAction>
          </DivForBtn>
        </form>
      </MainDiv>
    </>
  );
};

export default EmailForm;
