// import { Field, Form } from "formik";
import { Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { MOBILE } from "../../constants/mobile";
import {
  BubbleChatbotEvents,
  EventCategory,
  Source,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { trackEvent } from "../../event-utils/track-event";
import BubbleCross from "../../images/bubble-cross.svg";
import UserImage from "../../images/chat-user.svg";
import BubbleChatLiveIcon from "../../images/drop-chat-icon.svg";
import ReloadIcon from "../../images/reload-icon.svg";
// import BubbleCustomGreetingText from "./bubble-custom-greeting-text";
import InputFormUsingFormik from "./input-form-using-formik";

const RightColumn = styled(Col)`
  flex-direction: column;
  padding: 0;
  height: 100%;
`;

const ChatPageHeader = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  justify-content: flex-start;
  min-height: ${isMobile ? "7.4%" : "7.4%"};
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
`;

const RightContentRow = styled.div`
  width: 100%;
  padding: 10px;
  flex-direction: column;
`;

const SuggestedQuestionHeading = styled.div`
  justify-content: space-between;
  margin-bottom: 0px;
  & p {
    color: #33303c;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

const ChatBotContent = styled(Col)`
  overflow: auto;
  padding: 0.3rem;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const SignleQuestionAnswerSection = styled.div`
  flex-direction: column;
`;

const QuestionSection = styled.div`
  padding: 4px 8px;
  background: #ffffff;
  max-width: max-content;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  align-items: center;
  border: 1px solid #33303c1a;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
    font-size: 12px;
  }
  & p {
    margin: 0;
    font-size: 12px;
  }
`;

// const BookFetchForm = styled(Form)`
//   margin: auto;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   margin: 0;
//   align-self: end;
// `;

// const GreetingText = styled.p`
//   color: #33303cde;
//   font-size: 12px;
//   margin: 0.2rem 0rem;
// `;

// const FieldSection = styled.div`
//   position: relative;
//   flex-direction: column;
// `;

// const FormInput = styled(Field)`
//   width: 100%;
//   padding: 8px;
//   font-size: 12px;
// `;

// const FetchBookButton = styled.button`
//   width: -webkit-max-content;
//   width: -moz-max-content;
//   width: max-content;
//   font-size: 16px;
//   cursor: pointer;
//   height: 100%;
//   position: absolute;
//   right: 10px;
//   background: none;
//   border: none;
// `;

// const PoweredText = styled.p`
//   color: #33303c4d;
//   font-size: 12px;
//   font-weight: 600;
//   text-align: center;
//   margin-top: 0.5rem;
//   margin-bottom: 0;
// `;
const ChatPageBold = styled.b`
  font-size: 14px;
  //cursor: pointer;
  color: white;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const BottomFormSection = styled.div`
  height: ${isMobile ? "12%" : "20%"};
  padding: 5px;
  align-items: end;
`;

const SuggestedQuestionsSection = ({
  leadSettings,
  leadThemeSettings,
  setShowSampleQuestions,
  sampleQuestions,
  waiverSettings,
  firstMessageAgreePopup,
  questionValue,
  setShowAgreePopup,
  chatStart,
  setQuestionValue,
  setFormRef,
  allQuestionsandAnswers,
  customGreetingText,
  formRef,
  handleIncSampleQuestionCount,
  isMessageInterface,
  isResponseOn,
  emailValue,
  bubbleChatApis,
  bookId,
  leadChatSession,
  chatbotDetails,
}: any) => {
  return (
    <RightColumn>
      <ChatPageHeader
        className="App-header"
        style={{
          background: leadThemeSettings.titleBackgroundColor
            ? leadThemeSettings.titleBackgroundColor
            : "#1585b5",
          paddingRight: isMobile ? "35px" : "0.5rem",
        }}
      >
        <Col md={1} sm={1} style={{ flex: 1 }}>
          <img src={BubbleChatLiveIcon} alt="online..." width={30} /> &nbsp;
        </Col>

        <Col md={10} sm={10} style={{ alignItems: "center", flex: 10 }}>
          <ChatPageBold>
            {chatbotDetails &&
            chatbotDetails.chatbotHeaderTitle &&
            chatbotDetails.chatbotHeaderTitle.length > 0
              ? chatbotDetails.chatbotHeaderTitle
              : "Welcome to Dropchat"}
          </ChatPageBold>
        </Col>

        <Col md={1} sm={1} style={{ flex: 1, marginRight: "1.5rem" }}>
          <img
            src={ReloadIcon}
            style={{ cursor: "pointer" }}
            onClick={() => {
              bubbleChatApis();
            }}
            alt={"show"}
            width={20}
          />
        </Col>
      </ChatPageHeader>
      <Col style={{ height: "68%" }}>
        <RightContentRow className="sampleQuestionParent">
          <SuggestedQuestionHeading>
            <p>Suggested Questions</p>
            <img
              src={BubbleCross}
              alt={"close"}
              onClick={() => setShowSampleQuestions(false)}
              style={{ cursor: "pointer" }}
            />
          </SuggestedQuestionHeading>
          <ChatBotContent sm={12}>
            {/* <BubbleChatStyles.LeadFormScrollDiv> */}
            <div
              style={{
                flexDirection: "column",
                width: "100%",
              }}
            >
              <SignleQuestionAnswerSection>
                {sampleQuestions &&
                  sampleQuestions.length > 0 &&
                  sampleQuestions.map((data: any) => (
                    <QuestionSection
                      onClick={() => {
                        if (firstMessageAgreePopup && waiverSettings.isWaiver) {
                          setShowAgreePopup(true);
                        } else {
                          trackEvent({
                            email: emailValue || "PUBLIC",
                            eventCategory: EventCategory.CHATBOT,
                            event:
                              BubbleChatbotEvents.USER_ASKED_QUESTION_FROM_SUGGESTED_QUESTIONS_ON_BUBBLE_CHAT,
                            subCategory: SubCategory.EVENT,
                            source: Source.BUBBLE,
                            leadChatSession:
                              leadChatSession && leadChatSession.id,
                            rawData: {
                              askedSuggestedQuestion: data.question,
                              allSuggestedQuestions:
                                sampleQuestions && sampleQuestions.length > 0
                                  ? (sampleQuestions.map(
                                      (question: { question: string }) =>
                                        question.question
                                    ) as string[])
                                  : [],
                            },
                            bookId: bookId,
                          });
                          setShowSampleQuestions(false);
                          chatStart(data.question);
                          handleIncSampleQuestionCount(data.id);
                        }
                      }}
                      style={{
                        background: leadThemeSettings.questionBackgroundColor
                          ? leadThemeSettings.questionBackgroundColor
                          : "#fff",
                        marginBottom: "0.5rem",
                        cursor: "pointer",
                      }}
                    >
                      <img width="20" src={UserImage} alt="user_image" />
                      &nbsp;&nbsp;
                      <h5
                        style={{
                          color: leadThemeSettings.questionTextColor
                            ? leadThemeSettings.questionTextColor
                            : "rgba(51, 48, 60, 1)",
                        }}
                      >
                        {data.question}
                      </h5>
                    </QuestionSection>
                  ))}
              </SignleQuestionAnswerSection>
            </div>

            {/* </BubbleChatStyles.LeadFormScrollDiv> */}
          </ChatBotContent>
          {/* <BubbleCustomGreetingText
            leadThemeSettings={leadThemeSettings}
            customGreetingText={customGreetingText}
            isMessageInterface={isMessageInterface}
          /> */}
        </RightContentRow>
      </Col>
      <BottomFormSection>
        <InputFormUsingFormik
          questionValue={questionValue}
          setQuestionValue={setQuestionValue}
          setFormRef={setFormRef}
          chatStart={chatStart}
          leadThemeSettings={leadThemeSettings}
          leadSettings={leadSettings}
          customGreetingText={customGreetingText}
          allQuestionsandAnswers={allQuestionsandAnswers}
          firstMessageAgreePopup={firstMessageAgreePopup}
          waiverSettings={waiverSettings}
          setShowAgreePopup={setShowAgreePopup}
          setShowSampleQuestions={setShowSampleQuestions}
          formRef={formRef}
          isResponseOn={isResponseOn}
          chatbotDetails={chatbotDetails}
        />
      </BottomFormSection>
    </RightColumn>
  );
};

export default SuggestedQuestionsSection;
