import { useFormikContext } from "formik";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import { UserContext } from "../../../context/UserContext";
import HubSpotButtonIcon from "../../../images/logos_hubspot.svg";
import ZendeskIcon from "../../../images/simple-icons_zendesk.svg";
// import ShopifyIcon from "../../../images/Shopify1.svg";

const Main = styled.div`
  flex-direction: column;
`;
const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;
const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;
const InnerSection = styled.div`
  flex-direction: column;
  gap: 15px;
`;
const IntegrationAppsSetting = () => {
  const formik = useFormikContext();
  const { values } = useFormikContext<any>();
  const { integrationAppList } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <Main>
      <MenuHeading>Integration Apps</MenuHeading>
      <Wrapper>
        <InnerSection>
          <img width={"90"} src={HubSpotButtonIcon} alt="edit" />
          <img width={"100"} src={ZendeskIcon} alt="edit" />
        </InnerSection>
        <InnerSection>
          <CheckBoxWrapper>
            <>
              <CheckBox
                id="checkbox-hubspot"
                checked={values.integrationApp.isHubspot}
                type="checkbox"
                name="integrationApp.isHubspot"
                onChange={(e: any) => {
                  if (integrationAppList.hubspot) {
                    formik.setFieldValue(
                      "integrationApp.isHubspot",
                      e.target.checked
                    );
                  } else {
                    navigate("/settings/integration-settings");
                  }
                }}
              />
              <CheckBoxLabel htmlFor="checkbox-hubspot" />
            </>
          </CheckBoxWrapper>
          <CheckBoxWrapper>
            <>
              <CheckBox
                id="checkbox-zendesk"
                checked={values.integrationApp.isZendesk}
                type="checkbox"
                name="integrationApp.isZendesk"
                onChange={(e: any) => {
                  if (integrationAppList.zendesk) {
                    formik.setFieldValue(
                      "integrationApp.isZendesk",
                      e.target.checked
                    );
                  } else {
                    navigate("/settings/integration-settings");
                  }
                }}
              />
              <CheckBoxLabel htmlFor="checkbox-zendesk" />
            </>
          </CheckBoxWrapper>
        </InnerSection>
      </Wrapper>
    </Main>
  );
};
export default IntegrationAppsSetting;
