import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { MOBILE } from "../../constants/mobile";

const ParentDiv = styled.div`
  overflow: hidden;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  align-items: center;
  justify-content: center;
`;
const EmptyDiv = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;
const Container = styled(Col)`
  position: fixed;
  display: block;
  width: 40vw;
  margin: auto;
  overflow: auto;
  max-height: 65vh;
  inset: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 999999;
  border-radius: 10px 10px 0px 0px;
`;
const DltHeader = styled(Row)`
  height: 45px;
  background: rgba(47, 51, 73, 1);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: #2f4c95;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: white;
  letter-spacing: 0.5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    color: white;
  }
`;
const MainFormRow = styled(Row)`
  padding: 0.5rem 2rem;
  margin-top: 1rem;
  text-align: center;
`;

const ButtonRow = styled(Row)`
  padding: 0.5rem 3rem;
  display: flex;
  justify-content: space-between;
`;

// const UpdateButton = styled.button`
//   background: #1585b5;
//   border-radius: 2px;
//   color: white;
//   border: none;
//   font-size: 16px;
//   cursor: pointer;
//   width: auto;
//   padding: 0.4rem 3rem;
//   font-weight: bold;
//   letter-spacing: 0.8px;
//   &:disabled {
//     opacity: 0.5;
//     cursor: not-allowed;
//   }
// `;

const CancelButton = styled.button`
  background: none;
  padding: 0.4rem 3rem;
  color: #2f3349;
  border: 1px solid #2f3349;
  width: auto;
  display: flex;
  margin: auto;
  margin-top: auto;
  justify-content: center;
  font-weight: bold;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 100%;
    margin-top: 0.5rem;
  }
`;
const ShowLargeImagePopup = ({
  setProductPopup,
  productData,
  fromAPI,
}: any) => {
  const hidePopup = () => {
    setProductPopup(false);
  };
  return (
    <>
      <ParentDiv>
        <EmptyDiv onClick={hidePopup}></EmptyDiv>
        <Container>
          <DltHeader>
            <SpanDltPopup>
              {fromAPI ? productData.title : productData.title}
            </SpanDltPopup>
          </DltHeader>
          <MainFormRow>
            <img
              alt="images"
              src={
                fromAPI
                  ? productData.image && productData.image.src
                  : productData.image_src
              }
              style={{ width: "300px", margin: "auto" }}
            />
          </MainFormRow>
          <ButtonRow>
            <CancelButton onClick={hidePopup}>Close</CancelButton>
          </ButtonRow>
        </Container>
      </ParentDiv>
    </>
  );
};
export default ShowLargeImagePopup;
