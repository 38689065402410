import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import { MEDIUM } from "../../../constants/desktop";
import { MOBILE } from "../../../constants/mobile";
import { TAB } from "../../../constants/tab";
import { BookContext } from "../../../context/BookContext";
import { UserContext } from "../../../context/UserContext";
import { LoaderContext } from "../../../context/loader-context";
import BackIcon from "../../../images/left-arrow-white.svg";
import myAxios from "../../../myaxios";
import MainHeader from "../../header";
import * as BookChatStyles from "../styled";
import AddPdfSourceNew from "./add-pdf-source";
import AddShopifyProductsSource from "./add-shopify-products-source";
import AddTextSource from "./add-text-source";
import AddWebsiteSourceRenewed from "./add-website-source";
import AddYoutubeSource from "./add-youtube-source";

const TabContainer = styled.div`
  overflow: hidden;
  background-color: #f8f7fa;
  padding-left: 8px;
  display: flex;
  align-items: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    background-color: transparent !important;
    overflow: scroll;
  }
  @media (min-width: ${TAB.minWidth}px) {
    background-color: transparent !important;
    overflow: scroll;
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    overflow: hidden;
    background-color: #f8f7fa;
    padding-left: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;
const Tab = styled.button<{
  selected?: boolean;
}>`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5rem 3rem;
  min-width: 100px;
  transition: 0.3s;
  font-size: 17px;
  position: relative;
  background-color: #fff;
  color: ${(props) => (props.selected ? "#1585B5" : "#33303C61")};
  font-weight: 700;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0;
    font-size: 14px;
    background-color: transparent;
  }
  @media (min-width: ${TAB.minWidth}px) {
    padding: 0;
    font-size: 14px;
    background-color: transparent;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0.5rem 3rem;
    min-width: 100px;
    transition: 0.3s;
    font-size: 17px;
    position: relative;
    background-color: #fff;
    color: ${(props) => (props.selected ? "#1585B5" : "#33303C61")};
    font-weight: 700;
  }
`;
const HeaderTextSpan = styled.span`
  color: #fff;
  font-size: 16px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
  }
`;
const HeaderColumn = styled(Col)`
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 1.5rem;

  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.2rem 1rem;
  }
`;

const AddNewSource = ({ setShowAddNewSourcePage }: any) => {
  const { setShowLoader } = useContext(LoaderContext);
  const { setSelectedBook, selectedBook } = useContext(BookContext);
  const { integrationAppList, user } = useContext(UserContext);
  console.log("integrationAppList", integrationAppList);
  const [selectTabName, setSelectTabName] = useState("pdf");
  const { bookId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line
  const [bookSettings, setBookSettings] = useState<any>();
  useEffect(() => {
    if (bookId && !selectedBook) {
      setShowLoader(true);
      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/books/" + bookId)
        .then((response) => {
          if (response && response.data) {
            setSelectedBook(response.data);
          }
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
    myAxios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/book-settings/get-book-settings/${bookId}`
      )
      .then((res) => {
        if (res.data.data) {
          setBookSettings(res.data.data);
        }
      });
    // eslint-disable-next-line
  }, [bookId, selectedBook, setSelectedBook]);

  useEffect(() => {
    var url = location.pathname.split("/").pop();
    if (url === "add-pdf-sources") {
      setSelectTabName("pdf");
    }
    if (url === "add-text-sources") {
      setSelectTabName("text");
    }
    if (url === "add-url-sources") {
      setSelectTabName("website");
    }
    if (url === "add-shopify-sources") {
      setSelectTabName("shopify");
    }
  }, [bookId, location]);

  return (
    <>
      <MainHeader isUserPage={true} activeFrom={"fromMain"} />
      <BookChatStyles.ChatPageHeader style={{ backgroundColor: "#2F3349" }}>
        <HeaderColumn sm={12} xs={12}>
          <BookChatStyles.BookContent md={12}>
            <BookChatStyles.BackButton
              src={BackIcon}
              onClick={() => {
                setShowAddNewSourcePage(false);
              }}
            />

            <Col style={{ flexDirection: "column", marginLeft: "1.2rem" }}>
              <Col md={12} xs={12}>
                <HeaderTextSpan>Add new data source to:</HeaderTextSpan>
              </Col>
              <Col md={12} xs={12}>
                <HeaderTextSpan>
                  {selectedBook && selectedBook.title}
                </HeaderTextSpan>
              </Col>
            </Col>
          </BookChatStyles.BookContent>
        </HeaderColumn>
      </BookChatStyles.ChatPageHeader>
      <BookChatStyles.ChatPageHeader
        style={{
          minHeight: "40px",
          flexDirection: "column",
          backgroundColor: "#fff",
        }}
      >
        <TabContainer
          style={{
            width: "100%",
          }}
        >
          <>
            <Tab
              onClick={() => {
                setSelectTabName("pdf");
                navigate(
                  "/books/" +
                    selectedBook?._id +
                    "/chatbot-settings/add-pdf-sources"
                );
              }}
              selected={selectTabName === "pdf"}
            >
              PDF File
            </Tab>
            <Tab
              onClick={() => {
                setSelectTabName("website");
                navigate(
                  "/books/" +
                    selectedBook?._id +
                    "/chatbot-settings/add-url-sources"
                );
              }}
              selected={selectTabName === "website"}
            >
              Website URL
            </Tab>
            {/* <Tab
              onClick={() => {
                setSelectTabName("youtube");
              }}
              selected={selectTabName === "youtube"}
            >
              YouTube URL
            </Tab> */}

            <Tab
              onClick={() => {
                setSelectTabName("text");
                navigate(
                  "/books/" +
                    selectedBook?._id +
                    "/chatbot-settings/add-text-sources"
                );
              }}
              selected={selectTabName === "text"}
            >
              Text
            </Tab>
            {user?.currentSubscription?.meta.integrationApps && (
              <Tab
                onClick={() => {
                  console.log(
                    "integrationAppList.shopify",
                    integrationAppList.shopify
                  );
                  if (!integrationAppList.shopify) {
                    navigate("/settings/integration-settings");
                  } else {
                    setSelectTabName("shopify");
                    navigate(
                      "/books/" +
                        selectedBook?._id +
                        "/chatbot-settings/add-shopify-sources"
                    );
                  }
                }}
                selected={selectTabName === "shopify"}
              >
                Shopify Products
              </Tab>
            )}
          </>
        </TabContainer>
      </BookChatStyles.ChatPageHeader>
      <BookChatStyles.MainContainer fluid>
        {selectTabName === "pdf" && (
          <AddPdfSourceNew setShowAddNewSourcePage={setShowAddNewSourcePage} />
        )}
        {selectTabName === "website" && (
          // <AddWebsiteSource setShowAddNewSourcePage={setShowAddNewSourcePage} />
          <AddWebsiteSourceRenewed
            setShowAddNewSourcePage={setShowAddNewSourcePage}
          />
        )}
        {/* {selectTabName === "youtube" && (
          <AddYoutubeSource setShowAddNewSourcePage={setShowAddNewSourcePage} />
        )} */}
        {selectTabName === "text" && (
          <AddTextSource setShowAddNewSourcePage={setShowAddNewSourcePage} />
        )}
        {selectTabName === "shopify" && (
          <AddShopifyProductsSource
            setShowAddNewSourcePage={setShowAddNewSourcePage}
          />
        )}
      </BookChatStyles.MainContainer>
    </>
  );
};

export default AddNewSource;
