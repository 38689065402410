import "prism-themes/themes/prism-ghcolors.css";
import Markdown from "react-markdown";
import rehypePrism from "rehype-prism-plus";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
const CustomMarkdown = ({ text }: any) => {
  const customTransformLinkUri = (uri: string) => {
    if (/^mailto:/.test(uri)) {
      return uri;
    }
    let finalURL;
    if (/(http(s?)):\/\//i.test(uri)) {
      finalURL = uri;
    } else finalURL = "https://" + uri;
    return finalURL;
  };
  return (
    <Markdown
      className="markdown"
      components={{
        h1: "strong",
        h2: "strong",
        h3: "strong",
        h4: "strong",
        h5: "strong",
        h6: "strong",
        p: ({ node, ...props }: any) => {
          if (
            props.children &&
            typeof props.children == "string" &&
            props.children.includes("(insert ")
          ) {
            return "";
          } else {
            return props.children;
          }
        },
        a: ({ node, ...props }: any) => {
          if (
            props.href.includes("#") ||
            props.href.includes("example.com") ||
            props.href.includes("email.com")
          ) {
            return props.children;
          } else {
            return (
              <a href={props.href} target="_blank" rel="noreferrer">
                {props.children}
              </a>
            );
          }
        },
      }}
      remarkPlugins={[remarkBreaks, remarkGfm]}
      rehypePlugins={[rehypeRaw, rehypePrism]}
      urlTransform={customTransformLinkUri}
    >
      {text}
    </Markdown>
  );
};

export default CustomMarkdown;
