import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { WithContext as ReactTags } from "react-tag-input";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import { isValidEmail } from "../../../utils/utils";

const SidebarHeading = styled.h3`
  color: #33303c;
  font-size: 16px;
  // margin-bottom: 1rem;
  font-weight: 600;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
  }
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 1rem;
`;

const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 14px;
  font-weight: 600;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;

const ChatbotEmailSettings = () => {
  const formik = useFormikContext();
  const { values, setValues, errors } = useFormikContext<any>();

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleToDelete = (i: any) => {
    setValues({
      ...values,
      emailSetting: {
        ...values.emailSetting,
        toEmails: values.emailSetting.toEmails.filter(
          (_: any, index: any) => index !== i
        ),
      },
    });
  };
  const handleToAddition = (email: any) => {
    if (isValidEmail(email.text)) {
      setValues({
        ...values,
        emailSetting: {
          ...values.emailSetting,
          toEmails: [...values.emailSetting.toEmails, email],
        },
      });
    } else {
      NotificationManager.error("Please enter valid TO email address");
    }
  };
  const handleToEmailClick = (index: any) => {};

  const handleBccDelete = (i: any) => {
    setValues({
      ...values,
      emailSetting: {
        ...values.emailSetting,
        bccEmails: values.emailSetting.bccEmails.filter(
          (_: any, index: any) => index !== i
        ),
      },
    });
  };
  const handleBccAddition = (email: any) => {
    if (isValidEmail(email.text)) {
      setValues({
        ...values,
        emailSetting: {
          ...values.emailSetting,
          bccEmails: [...values.emailSetting.bccEmails, email],
        },
      });
    } else {
      NotificationManager.error("Please enter valid BCC email address");
    }
  };
  const handleBccEmailClick = (index: any) => {};

  return (
    <>
      <SidebarHeading>Email Settings</SidebarHeading>
      <SubHeading>
        If the "Send Chatbot Session" feature is activated, transcripts of
        chatbot interactions will be emailed to the addresses provided below. To
        add multiple email addresses, simply type in each address and press
        'Enter' after each one.
      </SubHeading>
      <Row style={{ alignItems: "center" }}>
        <Col sm={7} style={{ flexDirection: "column" }}>
          <MenuHeading>Send Chatbot Session</MenuHeading>
        </Col>
        <Col sm={5} style={{ justifyContent: "flex-end" }}>
          <CheckBoxWrapper>
            <>
              <CheckBox
                id="checkbox"
                checked={values.emailSetting.isSendEmail}
                type="checkbox"
                name="emailSetting.isSendEmail"
                onChange={(e: any) => {
                  formik.setFieldValue(
                    "emailSetting.isSendEmail",
                    e.target.checked
                  );
                }}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </>
          </CheckBoxWrapper>
        </Col>
      </Row>
      <Col sm={12} style={{ flexDirection: "column" }}>
        <MenuHeading style={{ marginBottom: "0.2rem" }}>
          Add To emails
        </MenuHeading>
        <ReactTags
          tags={values.emailSetting.toEmails}
          delimiters={delimiters}
          handleDelete={handleToDelete}
          handleAddition={handleToAddition}
          handleTagClick={handleToEmailClick}
          inline={false}
          autofocus={false}
          placeholder="Please write & press enter to add new To email"
        />
        {errors?.toEmails && typeof errors.toEmails === "string" ? (
          <div style={{ color: "red" }}>{errors.toEmails}</div>
        ) : (
          <div></div>
        )}
      </Col>
      <Col sm={12} style={{ flexDirection: "column" }}>
        <MenuHeading style={{ marginBottom: "0.2rem" }}>
          Add BCC emails
        </MenuHeading>
        <ReactTags
          tags={values.emailSetting.bccEmails}
          delimiters={delimiters}
          handleDelete={handleBccDelete}
          handleAddition={handleBccAddition}
          handleTagClick={handleBccEmailClick}
          inline={false}
          autofocus={false}
          placeholder="Please write & press enter to add new BCC email"
        />
        {errors?.toEmails && typeof errors.bccEmails === "string" ? (
          <div style={{ color: "red" }}>{errors.bccEmails}</div>
        ) : (
          <div></div>
        )}
      </Col>
    </>
  );
};

export default ChatbotEmailSettings;
