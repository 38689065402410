import { Field, Form } from "formik";
import { OverlayPanel } from "primereact/overlaypanel";
import { TreeSelect } from "primereact/treeselect";
import { FormLabel } from "react-bootstrap";
import styled from "styled-components";
import { BackgroundColor } from "../../types";

export const FirstInnerParent = styled.div`
  /* background: ${(props: BoxBackground) => props.background}; */
  flex: 1;
  padding: 10px;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 0.5rem;
  /* width: 30%; */
`;

export const SecondInnerParent = styled.div`
  background: rgba(234, 226, 255, 1);
  flex: 1;
  padding: 10px;
  flex-direction: row;
`;

export const ThirdInnerParent = styled.div`
  background: rgba(253, 226, 255, 1);
  flex: 1;
  padding: 10px;
  flex-direction: row;
`;

export const FourthInnerParent = styled.div`
  background: rgba(255, 226, 226, 1);
  flex: 1;
  padding: 10px;
  flex-direction: row;
`;

export const BookContent = styled.div`
  /* margin-left: 10px; */
  & span {
    color: rgba(51, 48, 60, 0.3);
  }
  & h3 {
    color: #33303c;
    font-size: 18px;
    // line-height: 1.5em;
    //  height: 3em;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  flex-direction: column;
`;

export interface BoxBackground {
  background: string;
}

export const PublicBookForm = styled(Form)`
  flex: 1;
  & button {
    background: #1585b5;
    border-radius: 4px;
    color: #fff;
    border: none;
    box-shadow: none;
    padding: 0.5rem 2rem;
  }
  & select {
    background: #ffffff;
    border: 1px solid rgba(51, 48, 60, 0.6);
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
  }
`;

export const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;

export const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

export const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
`;

export const FormLabelDocInput = styled(FormLabel)`
  background: #ccc;
  width: 50%;
  padding: 5px 10px;
`;

export const IndexStatus = styled.span`
  background: ${(props: BackgroundColor) => props.background};
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  /* position: absolute; */
  cursor: pointer;
  /* top: 0.1rem; */
  /* right: 0.2rem; */
  font-size: 10px;
  width: 71px;
  text-align: center;
`;

export const TreeSelectStyle = styled(TreeSelect)`
  font-size: 14px;
  border: 0;
  & span {
    font-size: 1rem;
    color: #1585b5;
    font-weight: bold;
  }
`;
export const BookActionUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  cursor: pointer;
  justify-content: end;
`;
export const BookActionLi = styled.li`
  display: inline-block;
  border-radius: 50%;
  background: #1585b5;
  margin: 0px 1.5px;
  padding: 2.5px;
  height: 6px;
`;

export const OverlayPanelStyle = styled(OverlayPanel)`
  flex-direction: column;
  /* margin-left: 3rem; */
`;
export const AddCategoryHeading = styled.span`
  color: #33303c;
  font-weight: 600;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  cursor: pointer;
  &:hover {
    background-color: #8080808a;
  }
`;
export const BookTitle = styled.span`
  color: #33303c !important;
  font-weight: 300;
  /* height: 50px; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const BookAuthor = styled.span`
  color: rgba(51, 48, 60, 0.6) !important;

  font-weight: 300;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0 2px;
`;
export const BookAuthorName = styled.span`
  color: rgba(51, 48, 60, 0.6) !important;

  font-weight: 300;
  /* height: 50px; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
`;
export const innerbox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
`;
