import { Col } from "react-bootstrap";
import styled from "styled-components";
import { useFormikContext } from "formik";
import "react-image-crop/dist/ReactCrop.css";
import { MOBILE } from "../../../constants/mobile";
import { CHATBOT_GREETING_TEXT } from "../../../utils/place-holder-consts";

const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 16px;
  font-weight: 600;
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 0.8rem;
  margin-top: 0.2rem;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;

const InputField = styled.input`
  height: 46px;
  width: 100%;
  padding: 0.5rem;
  background: #eaeaea;
  border: 1px solid #eaeaea;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  font-size: 14px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const CustomGreetingText = () => {
  const formik = useFormikContext();
  const { values } = useFormikContext<any>();
  return (
    <>
      <div className="d-flex">
        <Col sm={10} xs={10} style={{ flexDirection: "column" }}>
          <MenuHeading>Greeting Message</MenuHeading>
        </Col>
        <Col
          sm={2}
          xs={2}
          style={{
            justifyContent: "flex-end",
            alignSelf: "flex-start",
          }}
        >
          <CheckBoxWrapper>
            <>
              <CheckBox
                id="checkbox"
                type="checkbox"
                checked={values.greetingSetting.isGreeting}
                name="greetingSetting.isGreeting"
                onChange={(e: any) => {
                  formik.setFieldValue(
                    "greetingSetting.isGreeting",
                    e.target.checked
                  );
                }}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </>
          </CheckBoxWrapper>
        </Col>
      </div>
      <Col>
        <SubHeading>
          This message will show on all chat screen types. (Share, Embed, Bubble
          Chat)
        </SubHeading>
      </Col>
      <Col sm={12} style={{ flexDirection: "column" }}>
        <InputField
          type="text"
          name="greetingSetting.message"
          disabled={!values.greetingSetting.isGreeting}
          value={values.greetingSetting && values.greetingSetting.message}
          placeholder={CHATBOT_GREETING_TEXT}
          onChange={(e: any) => {
            formik.setFieldValue("greetingSetting.message", e.target.value);
          }}
        />
      </Col>
    </>
  );
};

export default CustomGreetingText;
