import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { useContext, useState } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { UserContext } from "../../context/UserContext";
import { LoaderContext } from "../../context/loader-context";
import {
  EventCategory,
  PlanEvents,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import myAxios from "../../myaxios";
import { isValidEmail, parseErrorResponse } from "../../utils/utils";
import ConfirmationBox from "../utility-component/confirmation-box";

const BookFetchForm = styled(Form)`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
`;

const FormLabelText = styled(FormLabel)`
  color: #33303c;
  font-weight: 600;
  font-size: 15px;
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;

const FieldColumnSection = styled.div`
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-evenly;
  & div {
    flex-direction: column;
  }
  & div:nth-child(1) {
    width: 32%;
  }
  & div:nth-child(2) {
    width: 32%;
  }
  & div:nth-child(3) {
    width: 32%;
  }
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
  color: #33303cde;
  border: 1px solid #eaeaea;
  background: #f8f7fa;
`;

const FormError = styled.span`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const FieldToggleSection = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  & div {
    flex-direction: row;
  }
  & div:nth-child(1) {
  }
  & div:nth-child(2) {
  }
  & div:nth-child(3) {
  }
`;

const FormToggleLabelText = styled(FormLabel)`
  color: #33303c;
  font-weight: 600;
  font-size: 15px;
  padding-left: 0.5rem;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;

const FieldDateColumnSection = styled.div`
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  & div {
    flex-direction: column;
  }
`;

const SaveButton = styled(Button)`
  width: 100%;
  background: #1585b5;
  padding: 0.5rem;
  color: #fff;
`;

const ResetButton = styled.p`
  color: #33303c;
  border-bottom: 1px solid #33303c;
  font-size: 15px;
  margin: 0;
  cursor: pointer;
`;

const CustomPurchase = () => {
  const { setShowLoader } = useContext(LoaderContext);
  const [deleteChatbotToggle, setDeleteChatbotToggle] =
    useState<boolean>(false);
  const [flashcardsToggle, setFlashcardsToggle] = useState<boolean>(false);
  const [removeDropchatBrandingToggle, setRemoveDropchatBrandingToggleToggle] =
    useState<boolean>(false);
  const { user, setTotalBooksCount } = useContext(UserContext);
  const [allowPDFIndex, setAllowPDFIndex] = useState<boolean>(false);
  const [allowWebsiteIndex, setAllowWebsiteIndex] = useState<boolean>(false);
  const [allowYTIndex, setAllowYTIndex] = useState<boolean>(false);

  const [gpt35Turbo16k, setGpt35Turbo16k] = useState<boolean>(false);
  const [gpt4TurboPreview, setGpt4TurboPreview] = useState<boolean>(false);
  const [leadLoginSettings, setLeadLoginSettings] = useState<boolean>(false);

  const [deleteAccount, setDeleteAccount] = useState<boolean>(false);
  const [customOpenAPIKey, setCustomOpenAPIKey] = useState<boolean>(false);
  const [integrationApps, setIntegrationApps] = useState<boolean>(false);
  const [APIConfiguration, setAPIConfiguration] = useState<boolean>(false);
  const [discordIntegration, setDiscordIntegration] = useState<boolean>(false);

  const [dateRange, setDateRange] = useState(null);
  const [startDate, setStartDate] = useState<null | string>(null);
  const [endDate, setEndDate] = useState<null | string>(null);

  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);

  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");

  // eslint-disable-next-line
  const [confirmationAction, setConfirmationAction] = useState<string>("");

  const [localDataOfCustomOrder, setLocalDataOfCustomOrder] = useState<any>();

  const [resetFormCustom, setResetForm] = useState<any>();

  let today = new Date();

  const validateCustomPurchaseSubmission = (formData: any) => {
    const errors: any = {};
    if (!isValidEmail(formData.email)) {
      errors.email = "Enter valid email address!";
    }
    return errors;
  };

  const resetCustomPurchaseForm = (resetForm: any) => {
    resetForm();
    setDateRange(null);
    setStartDate(null);
    setEndDate(null);
    setDeleteChatbotToggle(false);
    setFlashcardsToggle(false);
    setRemoveDropchatBrandingToggleToggle(false);
    setAllowPDFIndex(false);
    setAllowWebsiteIndex(false);
    setAllowYTIndex(false);
    setGpt35Turbo16k(false);
    setGpt4TurboPreview(false);
    setLeadLoginSettings(false);
    setDeleteAccount(false);
    setCustomOpenAPIKey(false);
    setIntegrationApps(false);
    setAPIConfiguration(false);
    setDiscordIntegration(false);
  };

  const confirmClick = () => {
    // console.log("confirm clicked");
    setShowLoader(true);
    localDataOfCustomOrder.isOverrideActivePlan = true;
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/custom-plans", {
        ...localDataOfCustomOrder,
      })
      .then((response) => {
        // console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          setShowUtilityConfirmationBox(false);
          setShowLoader(false);
          // resetCustomPurchaseForm(resetFormCustom);
          NotificationManager.success(response.data.message);
        } else {
          setShowUtilityConfirmationBox(false);
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        setShowUtilityConfirmationBox(false);
        // handle error
        // resetForm();
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  return (
    <>
      <Row style={{ marginTop: "1rem" }}>
        <Col sm={6}>
          <Formik
            initialValues={{
              email: "",
              name: "",
              noOfBooks: 5,
              messageCount: 10000,
              pdfPageSupport: 100,
              indexCharacterSupport: 50000,
              deleteChat: false,
              flashCard: false,
              removeDropChatBranding: false,
              startDateString: "",
              endDateString: "",
              isOverrideActivePlan: false,
              allowPDFIndex: false,
              allowWebsiteIndex: false,
              allowYTIndex: false,
              gpt35Turbo16k: false,
              gpt4TurboPreview: false,
              leadLoginSettings: false,
              deleteAccount: false,
              customOpenAPIKey: false,
              integrationApps: false,
              APIConfiguration: false,
              shopifyProductCount: 10,
              discordIntegration: false,
            }}
            validate={validateCustomPurchaseSubmission}
            onSubmit={(values, { resetForm }) => {
              setShowLoader(true);
              let startDateString = JSON.stringify(startDate);
              let endDateString = JSON.stringify(endDate);
              if (
                (!startDate && startDate === "") ||
                startDateString === "null" ||
                (!endDate && endDate === "") ||
                endDateString === "null"
              ) {
                NotificationManager.error("Please select correct date range!");
                setShowLoader(false);
                return;
              }
              // created the correct data to save after all validation success with latest values
              let dataToSave = {
                email: values.email,
                name: values.name,
                meta: {
                  noOfBooks: values.noOfBooks,
                  messageCount: values.messageCount,
                  pdfPageSupport: values.pdfPageSupport,
                  indexCharacterSupport: values.indexCharacterSupport,
                  deleteChat: deleteChatbotToggle,
                  flashCard: flashcardsToggle,
                  removeDropChatBranding: removeDropchatBrandingToggle,
                  allowPDFIndex: allowPDFIndex,
                  allowWebsiteIndex: allowWebsiteIndex,
                  allowYTIndex: allowYTIndex,
                  gpt35Turbo16k: gpt35Turbo16k,
                  gpt4TurboPreview: gpt4TurboPreview,
                  leadLoginSettings: leadLoginSettings,
                  deleteAccount: deleteAccount,
                  customOpenAPIKey: customOpenAPIKey,
                  integrationApps: integrationApps,
                  APIConfiguration: APIConfiguration,
                  shopifyProductCount: values.shopifyProductCount,
                  discordIntegration: discordIntegration,
                },
                startDateString: startDate,
                endDateString: endDate,
              };

              setLocalDataOfCustomOrder({ ...dataToSave });
              setResetForm(resetForm);
              myAxios
                .post(process.env.REACT_APP_SERVER_URL + "/custom-plans", {
                  ...dataToSave,
                })
                .then((response) => {
                  // console.log("response is , ", response.data);
                  if (response.data && response.data.success) {
                    setShowLoader(false);
                    trackEvent({
                      email: getUsersEmail(user),
                      eventCategory: EventCategory.PLAN,
                      event: PlanEvents.CUSTOM_PLAN_CREATED_BY_ADMIN,
                      subCategory: SubCategory.EVENT,
                      rawData: response.data,
                    });
                    trackEvent({
                      email: values.email,
                      eventCategory: EventCategory.PLAN,
                      event: PlanEvents.CUSTOM_PLAN_CREATED,
                      subCategory: SubCategory.EVENT,
                      rawData: response.data,
                    });
                    if (response.data.data.isOverrideActivePlan) {
                      setShowUtilityConfirmationBox(true);
                      setConfirmationBoxMessage(response.data.message);
                    } else {
                      NotificationManager.success(response.data.message);
                      resetCustomPurchaseForm(resetForm);
                    }
                  } else {
                    setShowLoader(false);
                    NotificationManager.error(
                      response.data.message
                        ? response.data.message
                        : "Unable to process your request, please try later"
                    );
                  }
                })
                .catch((error) => {
                  // handle error
                  setShowLoader(false);
                  // resetForm();
                  NotificationManager.error(parseErrorResponse(error));
                });
            }}
          >
            {({ values, errors, touched, resetForm }: any) => (
              <BookFetchForm>
                <>
                  <FormLabelText>Email</FormLabelText>
                  <FieldSection>
                    <FormInput name="email" placeholder="Enter email" />
                    {errors.email && touched.email && (
                      <FormError className="formError">
                        {errors.email}
                      </FormError>
                    )}
                  </FieldSection>
                  {/* <FormLabelText>Plan Name</FormLabelText>
                  <FieldSection>
                    <FormInput name="name" placeholder="Enter plan name" />
                    {errors.name && touched.name && (
                      <FormError className="formError">{errors.name}</FormError>
                    )}
                  </FieldSection> */}
                  <FormLabelText>Plan Meta</FormLabelText>
                  <FieldColumnSection>
                    <Col sm={4}>
                      <FormLabelText>Chatbot Count</FormLabelText>
                      <FormInput
                        name="noOfBooks"
                        type="number"
                        placeholder="Enter chatbot count"
                      />
                      {errors.noOfBooks && touched.noOfBooks && (
                        <FormError className="formError">
                          {errors.noOfBooks}
                        </FormError>
                      )}
                    </Col>

                    <Col sm={4}>
                      <FormLabelText>PDF Page Limit</FormLabelText>
                      <FormInput
                        name="pdfPageSupport"
                        type="number"
                        placeholder="PDF page limit"
                      />
                      {errors.pdfPageSupport && touched.pdfPageSupport && (
                        <FormError className="formError">
                          {errors.pdfPageSupport}
                        </FormError>
                      )}
                    </Col>
                    <Col sm={4}>
                      <FormLabelText>Shopify Products Count</FormLabelText>
                      <FormInput
                        name="shopifyProductCount"
                        type="number"
                        placeholder=""
                      />
                      {errors.shopifyProductCount &&
                        touched.shopifyProductCount && (
                          <FormError className="formError">
                            {errors.shopifyProductCount}
                          </FormError>
                        )}
                    </Col>
                  </FieldColumnSection>
                  <FieldColumnSection>
                    <Col sm={6}>
                      <FormLabelText>Index Character Limit</FormLabelText>
                      <FormInput
                        name="indexCharacterSupport"
                        type="number"
                        placeholder="Index Character Limit"
                      />
                      {errors.pdfPageSupport && touched.pdfPageSupport && (
                        <FormError className="formError">
                          {errors.pdfPageSupport}
                        </FormError>
                      )}
                    </Col>
                    <Col sm={6}>
                      <FormLabelText>Message Limit</FormLabelText>
                      <FormInput
                        name="messageCount"
                        type="number"
                        placeholder="Enter message limit"
                      />
                      {errors.messageCount && touched.messageCount && (
                        <FormError className="formError">
                          {errors.messageCount}
                        </FormError>
                      )}
                    </Col>
                  </FieldColumnSection>
                  <FieldToggleSection>
                    <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            type="checkbox"
                            name="deleteChat"
                            checked={deleteChatbotToggle}
                            onClick={(e: any) => {
                              setDeleteChatbotToggle(!deleteChatbotToggle);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>
                            Delete Chatbot
                          </FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                    <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            type="checkbox"
                            name="flashCard"
                            checked={flashcardsToggle}
                            onClick={(e: any) => {
                              setFlashcardsToggle(!flashcardsToggle);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>Flashcards</FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                    <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            checked={removeDropchatBrandingToggle}
                            type="checkbox"
                            name="removeDropChatBranding"
                            onClick={(e: any) => {
                              setRemoveDropchatBrandingToggleToggle(
                                !removeDropchatBrandingToggle
                              );
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Remove Dropchat Branding
                          </FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                  </FieldToggleSection>

                  <FieldToggleSection>
                    <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            type="checkbox"
                            name="allowPDFIndex"
                            checked={allowPDFIndex}
                            onClick={(e: any) => {
                              setAllowPDFIndex(!allowPDFIndex);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>PDF Chatbot</FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                    <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            type="checkbox"
                            name="allowWebsiteIndex"
                            checked={allowWebsiteIndex}
                            onClick={(e: any) => {
                              setAllowWebsiteIndex(!allowWebsiteIndex);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>
                            Website Chatbot
                          </FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                    <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            checked={allowYTIndex}
                            type="checkbox"
                            name="allowYTIndex"
                            onClick={(e: any) => {
                              setAllowYTIndex(!allowYTIndex);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>
                            YouTube Chatbot
                          </FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                  </FieldToggleSection>

                  <FieldToggleSection>
                    <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            type="checkbox"
                            name="gpt4TurboPreview"
                            checked={gpt4TurboPreview}
                            onClick={(e: any) => {
                              setGpt4TurboPreview(!gpt4TurboPreview);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>gpt-4o</FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                    <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            type="checkbox"
                            name="gpt35Turbo16k"
                            checked={gpt35Turbo16k}
                            onClick={(e: any) => {
                              setGpt35Turbo16k(!gpt35Turbo16k);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>GPT-4o mini</FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                    <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            checked={leadLoginSettings}
                            type="checkbox"
                            name="leadLoginSettings"
                            onClick={(e: any) => {
                              setLeadLoginSettings(!leadLoginSettings);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>
                            Lead Login Settings
                          </FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                  </FieldToggleSection>

                  <FieldToggleSection>
                    <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            type="checkbox"
                            name="deleteAccount"
                            checked={deleteAccount}
                            onClick={(e: any) => {
                              setDeleteAccount(!deleteAccount);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>
                            Delete Account
                          </FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                    <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            type="checkbox"
                            name="integrationApps"
                            checked={integrationApps}
                            onClick={(e: any) => {
                              setIntegrationApps(!integrationApps);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>
                            Integration Apps
                          </FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                    <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            type="checkbox"
                            name="APIConfiguration"
                            checked={APIConfiguration}
                            onClick={(e: any) => {
                              setAPIConfiguration(!APIConfiguration);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>
                            API Configuration
                          </FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                  </FieldToggleSection>
                  <FieldToggleSection>
                    <Col sm={5}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            type="checkbox"
                            name="customOpenAPIKey"
                            checked={customOpenAPIKey}
                            onClick={(e: any) => {
                              setCustomOpenAPIKey(!customOpenAPIKey);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>
                            Use Own OpenAPI Key
                          </FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col>
                    {/* <Col sm={4}>
                      <CheckBoxWrapper>
                        <>
                          <CheckBox
                            id="checkbox"
                            type="checkbox"
                            name="discordIntegration"
                            checked={discordIntegration}
                            onClick={(e: any) => {
                              setDiscordIntegration(!integrationApps);
                            }}
                          />
                          <CheckBoxLabel htmlFor="checkbox" />
                          <FormToggleLabelText>
                            Discord Integration
                          </FormToggleLabelText>
                        </>
                      </CheckBoxWrapper>
                    </Col> */}
                  </FieldToggleSection>
                  <FieldDateColumnSection>
                    <Col sm={12}>
                      <FormLabelText>Date Range</FormLabelText>
                      <Calendar
                        value={dateRange}
                        minDate={today}
                        onChange={(e: any) => {
                          setDateRange(e.value);
                          //startDate conversion
                          if (e.value[0] === null) {
                            setStartDate(null);
                          } else {
                            let momentStartDateConvert = moment(
                              e.value[0],
                              "DD/MM/YYYY"
                            ).toDate();
                            let momentStartDate = moment(
                              momentStartDateConvert
                            ).format("DD/MM/YYYY");
                            setStartDate(momentStartDate);
                          }
                          //endDate conversion
                          if (e.value[1] === null) {
                            setStartDate(null);
                          } else {
                            let momentEndDateConvert = moment(
                              e.value[1],
                              "DD/MM/YYYY"
                            ).toDate();
                            let momentEndDate =
                              moment(momentEndDateConvert).format("DD/MM/YYYY");
                            setEndDate(momentEndDate);
                          }
                        }}
                        showIcon
                        selectionMode="range"
                      />
                    </Col>
                  </FieldDateColumnSection>
                  <Row>
                    <Col>
                      <SaveButton type="submit">Create Plan</SaveButton>
                    </Col>
                    <Col
                      style={{ justifyContent: "end", alignItems: "center" }}
                    >
                      <ResetButton
                        onClick={() => resetCustomPurchaseForm(resetForm)}
                      >
                        Reset Plan
                      </ResetButton>
                    </Col>
                  </Row>
                </>
              </BookFetchForm>
            )}
          </Formik>
        </Col>
      </Row>
      {showUtilityConfirmationBox && (
        <ConfirmationBox
          setShow={setShowUtilityConfirmationBox}
          name="Confirmation"
          message={confirmationBoxMessage}
          okButtonText="Yes"
          action={confirmationAction}
          okButtonClick={confirmClick}
        />
      )}
    </>
  );
};

export default CustomPurchase;
