import styled from "styled-components";
import { MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";

const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 59%);
  z-index: 9999;
`;

const DivForDltPopUp = styled.div`
  position: fixed;
  width: 30%;
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  z-index: 1111;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 90%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 58%;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 32%;
  }
`;

const DltHeader = styled.div`
  width: 100%;
  height: 45px;
  background: rgba(47, 51, 73, 1);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
  }
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #2f4c95;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: white;
  @media (max-width: ${MOBILE.maxWidth}px) {
    color: white;
  }
`;
const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const SpanTextDlt = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(51, 48, 60, 1);
  padding: 1rem;
  padding-bottom: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    padding-bottom: 0;
  }
`;
const OkButton = styled.button`
  background: #ea5455;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 25%;
  padding: 5px 20px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 25%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 47%;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 20%;
  }
`;

const ConfirmationAlertText = styled.span`
  margin-top: 1rem;
  color: #ea5455;
  font-weight: bold;
  padding: 0rem 1rem;
  text-align: center;
`;

const SpanCancel = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #2f4c95;
  margin: 4px 13px;
  cursor: pointer;
`;

const ConfirmationBox = ({
  setShow,
  okButtonClick,
  name,
  message,
  okButtonText,
  cancelButtonText,
  action,
  messageDesc,
}: any) => {
  return (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <SpanDltPopup>{name}</SpanDltPopup>
            {/* <Arrow
              src={DltCrossRedSvg}
              alt="dlt"
              onClick={() => {
                setShow(false);
              }}
            /> */}
          </DltHeader>
          <>
            <CenterDiv>
              <ConfirmationAlertText>{message}</ConfirmationAlertText>
              <SpanTextDlt>{messageDesc}</SpanTextDlt>
            </CenterDiv>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1.2rem",
                marginBottom: "1rem",
              }}
            >
              <OkButton
                type="button"
                onClick={() => {
                  okButtonClick(action);
                }}
              >
                {okButtonText ? okButtonText : "Ok"}
              </OkButton>
              <SpanCancel
                onClick={() => {
                  setShow(false);
                }}
              >
                {cancelButtonText ? cancelButtonText : "Cancel"}
              </SpanCancel>
            </div>
          </>
        </DivForDltPopUp>
      </MainDiv>
    </>
  );
};

export default ConfirmationBox;
