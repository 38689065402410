import { useContext, useEffect } from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { BookContext } from "../../context/BookContext";
import DltrossSvg from "../../images/white-cross.svg";
// import CustomMarkdown from "../../utils/custom-markdown";
import { formattedAnswer } from "../../utils/utils";
import { MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
import { Link, useNavigate } from "react-router-dom";

const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 59%);
  z-index: 9999;
`;

const DivForDltPopUp = styled.div`
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 8%);
  z-index: 1111;
  width: 95% !important;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    position: relative;
  }
  @media (min-width: ${TAB.minWidth}px) {
    position: relative;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    position: fixed;
  }
`;

const DltHeader = styled.div`
  width: 100% !important;
  background: #1585b5;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: space-between;
    height: auto;
    padding: 10px;
    flex-direction: row-reverse;
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: space-between;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    justify-content: space-between;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
`;

const Arrow = styled.img`
  height: 25px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-top: 3.5px;
  }
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const LeftDiv = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin: 10px 16px;
  height: 72vh;
  overflow: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  & label {
    font-weight: bold;
    margin-right: 10px;
    align-self: center;
  }
  & input {
    padding: 5px;
  }
`;

const SingleSourceParent = styled.div`
  border-bottom: 1px dashed #ccc;
  height: max-content;
  padding: 10px 0px;
  width: 98%;
  flex-direction: column;
  & img {
    display: none;
  }
`;

const SourcePageContent = styled.p``;

const SourceFrom = styled.i``;

const AnswerSourcePopup = ({ allAnswerSource }: any) => {
  const {
    showAnswerSourcePopup,
    setShowAnswerSourcePopup,
    selectedBook,
    setSelectedSource,
  } = useContext(BookContext);
  const navigate = useNavigate();

  let allChatbotSources = selectedBook && selectedBook?.sources;

  return showAnswerSourcePopup ? (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <Arrow
              src={DltrossSvg}
              alt="dlt"
              onClick={() => {
                setShowAnswerSourcePopup(false);
              }}
            />
            <SpanDltPopup>Source Details</SpanDltPopup>
          </DltHeader>

          <Row>
            <LeftDiv>
              {allAnswerSource && allAnswerSource.length > 0 ? (
                allAnswerSource.map((source: any) => {
                  const res =
                    allChatbotSources &&
                    allChatbotSources.length > 0 &&
                    allChatbotSources.filter((obj: any) => {
                      let found = JSON.stringify(obj)
                        .toLowerCase()
                        .includes(source.metadata.source.toLowerCase());
                      if (found) {
                        return obj;
                      }
                    });

                  let finalFoundDataSource = res && res.length > 0 && res[0];

                  return (
                    <>
                      <SingleSourceParent style={{ background: "" }}>
                        <SourceFrom>
                          <b>Source:&nbsp;</b>{" "}
                          {finalFoundDataSource ? (
                            <span
                              style={{
                                color: "#1b75d0",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedSource(finalFoundDataSource);
                                navigate(
                                  `/books/${selectedBook?._id}/chatbot-settings/data-sources`
                                );
                                setShowAnswerSourcePopup(false);
                              }}
                            >
                              {source.metadata.source}
                            </span>
                          ) : (
                            source.metadata.source
                          )}
                          {!finalFoundDataSource && (
                            <span
                              style={{
                                background: "#f6eb77",
                                marginLeft: "10px",
                                fontSize: "10px",
                                padding: "5px 8px",
                                color: "#474937",
                                fontWeight: "bold",
                                borderRadius: "4px",
                              }}
                            >
                              Source Changed
                            </span>
                          )}
                        </SourceFrom>
                        <SourcePageContent
                          dangerouslySetInnerHTML={{
                            __html: source.pageContent
                              ? `<b>Content: </b>${formattedAnswer(
                                  source.pageContent
                                )}`
                              : "<p></p>",
                          }}
                        ></SourcePageContent>
                      </SingleSourceParent>
                    </> /* 
                    <SingleSourceParent>
                      <SourceFrom>
                        <b>Source:&nbsp;</b> {source.metadata.source}
                      </SourceFrom>
                      <SourcePageContent>
                      <CustomMarkdown text={source.pageContent} />
                      </SourcePageContent>
                    </SingleSourceParent> */
                  );
                })
              ) : (
                <SourceFrom>
                  <h2>
                    <b>No Source Available!</b>
                  </h2>
                </SourceFrom>
              )}
            </LeftDiv>
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </>
  ) : (
    <></>
  );
};

export default AnswerSourcePopup;
