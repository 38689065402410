import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";
import { NotificationManager } from "react-notifications";
import { WithContext as ReactTags } from "react-tag-input";
import styled from "styled-components";
import { isValidEmail } from "../../../utils/utils";

const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 16px;
  font-weight: 600;
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 0.5rem;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;

const EscalateEmail = ({ setManageChatbotSettingAll }: any) => {
  const formik = useFormikContext();
  const { values, setValues, errors } = useFormikContext<any>();

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleToDeleteEscalateEmail = (i: any) => {
    setValues({
      ...values,
      escalateEmailSetting: {
        ...values.escalateEmailSetting,
        toEmails: values.escalateEmailSetting.toEmails.filter(
          (_: any, index: any) => index !== i
        ),
      },
    });
  };
  const handleToAdditionEscalateEmail = (email: any) => {
  
    if (isValidEmail(email.text.replace("\n", ""))) {
      setValues({
        ...values,
        escalateEmailSetting: {
          ...values.escalateEmailSetting,
          toEmails: [...values.escalateEmailSetting.toEmails, email],
        },
      });
    } else {
      NotificationManager.error("Please enter valid TO email address");
    }
  };

  return (
    <>
      <div className="d-flex">
        <Col sm={10} xs={10} style={{ flexDirection: "column" }}>
          <MenuHeading>Escalate to Email</MenuHeading>
        </Col>
        <Col
          sm={2}
          xs={2}
          style={{
            justifyContent: "flex-end",
            alignSelf: "flex-start",
          }}
        >
          <CheckBoxWrapper>
            <>
              <CheckBox
                id="checkbox"
                checked={values.escalateEmailSetting.isEscalateEmail}
                type="checkbox"
                name="escalateEmailSetting.isEscalateEmail"
                onChange={(e: any) => {
                  formik.setFieldValue(
                    "escalateEmailSetting.isEscalateEmail",
                    e.target.checked
                  );
                }}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </>
          </CheckBoxWrapper>
        </Col>
      </div>
      <Row>
        <SubHeading>
          When a user selects the 'Escalate to Email' option in the chat
          interface, an email will be sent to the following email address(es):
        </SubHeading>
      </Row>
      <ReactTags
        tags={values.escalateEmailSetting.toEmails}
        delimiters={delimiters}
        handleDelete={handleToDeleteEscalateEmail}
        handleAddition={handleToAdditionEscalateEmail}
        // handleTagClick={""}
        inputProps={{
          disabled: !values.escalateEmailSetting.isEscalateEmail,
        }}
        inline={false}
        autofocus={false}
        placeholder="Please write & press enter to add new To email"
      />
      {errors?.toEscalateEmails &&
      typeof errors.toEscalateEmails === "string" ? (
        <div style={{ color: "red" }}>{errors.toEscalateEmails}</div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default EscalateEmail;
