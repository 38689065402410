import CryptoJS from "crypto-js";
import { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { CopyBlock, dracula } from "react-code-blocks";
import { NotificationManager } from "react-notifications";
// import { useNavigate } from "react-router";
import styled from "styled-components";
// import { UserContext } from "../../context/UserContext";
import { CodeBoxContext } from "../../context/code-box-context";
// import { PlanContext } from "../../context/plan-context";
// import { PublicContext } from "../../context/PublicContext";
import { UserContext } from "../../context/UserContext";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import myAxios from "../../myaxios";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import DltrossSvg from "../../images/cross.svg";
import { bubbleChatCode, iFrameCode } from "../../utils/utils";
import { BookContext } from "../../context/BookContext";
import { LoaderContext } from "../../context/loader-context";
import axios from "axios";

const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 59%);
  z-index: 9999;
`;

const DivForDltPopUp = styled.div`
  position: fixed;
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 8%);
  z-index: 1111;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;

const DltHeader = styled.div`
  width: 100%;
  height: 35px;
  background: #1585b5;
  display: flex;
  justify-content: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;

const TextDiv = styled.div`
  width: 100%;
  height: 35px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;

const Arrow = styled.img`
  height: 25px;
  cursor: pointer;
  left: 99%;
  top: -10px;
  z-index: 1111;
  position: absolute;
  background: #fff;
  border-radius: 98%;

  @media (max-width: 1199px) and (min-width: 320px) {
    left: 90%;
  }
`;

const SpanDltPopup = styled.span`
  font-family: "Gill Sans MT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const Text = styled.span`
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

// const LeftDiv = styled.div`
//   display: flex;
//   justify-content: left;
//   flex-direction: row;
//   margin: 10px 16px;
//   & label {
//     font-weight: bold;
//     margin-right: 10px;
//     align-self: center;
//   }
//   & input {
//     padding: 5px;
//     width: 20%;
//     height: 25px;
//   }
// `;
const InputDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 10px 16px;
  & label {
    font-weight: bold;
    margin-right: 10px;
    align-self: center;
  }
  & input {
    padding: 5px;
    width: 25%;
    height: 25px;
    border: 1px solid;
    border-radius: 4px;
    align-self: center;
  }
`;

const SaveButton = styled.button`
  background: #1585b5;
  padding: 0.5rem 2rem;
  color: #fff;
  border: none;
  width: max-content;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
`;

const FormError = styled.span`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const CodeBox = () => {
  const { selectedBook } = useContext(BookContext);
  const { setShowLoader } = useContext(LoaderContext); // const navigate = useNavigate();
  // const { user } = useContext(UserContext);
  // const { setPlanHighliter } = useContext(PlanContext);
  const { showConfirmationBox, setShowConfirmationBox, bookId } =
    useContext(CodeBoxContext);
  // eslint-disable-next-line
  const [header, setHeader] = useState<string>("Powered by Dropchat");
  const { user } = useContext(UserContext);
  const [encrptedData, setEncrptedData] = useState("");
  const [heightError, setHeightError] = useState(false);
  const [widthError, setWidthError] = useState(false);
  // eslint-disable-next-line
  const [iframeWidth, setIframeWidth] = useState("100");
  const [iframeHeight, setIframeHeight] = useState("400");
  const [bubbleChatHeight, setBubbleChatHeight] = useState("500");
  const [bubbleChatWidth, setBubbleChatWidth] = useState("300");

  const textBubble = `To add a chat bubble to the bottom right of your website add this script tag to your html.`;
  const textIframe = `To add the chatbot anywhere on your website, add this iframe to your html.`;
  const secretPass = "XkhZG4fW2t2W";
  const encryptData = () => {
    const data = encodeURIComponent(
      CryptoJS.AES.encrypt(JSON.stringify(header), secretPass).toString()
    );

    setEncrptedData(data);
  };

  useEffect(() => {
    encryptData();
    // eslint-disable-next-line
  }, [header]);

  useEffect(() => {
    if (selectedBook && selectedBook._id) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/bubble-chat-configurations/${
            selectedBook && selectedBook._id
          }`
        )
        .then((res) => {
          if (res.data.data) {
            setBubbleChatWidth(res.data.data.width);
            setBubbleChatHeight(res.data.data.height);
            setShowLoader(false);
          }
        })
        .catch((error) => {
          // NotificationManager.error(parseErrorResponse(error));
          setShowLoader(false);
        });
    }
    // eslint-disable-next-line
  }, [selectedBook && selectedBook._id]);

  const saveBubbleChatSettings = () => {
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + `/bubble-chat-configurations`, {
        chatbotID: selectedBook && selectedBook._id,
        height: parseInt(bubbleChatHeight, 10),
        width: parseInt(bubbleChatWidth, 10),
      })
      .then((response) => {
        if (response.data && response.data.success) {
          setShowLoader(false);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Settings updated successfully!"
          );
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to fetch chat session, please try later!"
          );
        }
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  return showConfirmationBox ? (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <Arrow
              src={DltrossSvg}
              alt="dlt"
              onClick={() => {
                setShowConfirmationBox(false);
              }}
            />
            <SpanDltPopup>Embed on website</SpanDltPopup>
          </DltHeader>

          <TextDiv>
            <Text>{textBubble}</Text>
          </TextDiv>
          <div style={{ display: "flex" }}>
            <InputDiv style={{ width: "50%" }}>
              <div style={{ flexDirection: "row" }}>
                <label>Height (pixel):</label>
                <input
                  type="number"
                  value={bubbleChatHeight}
                  defaultValue={500}
                  min={500}
                  onChange={(e) => {
                    setBubbleChatHeight(e.target.value);
                    if (e.target.value < "500") {
                      setHeightError(true);
                    } else setHeightError(false);
                  }}
                  style={{ width: "50%" }}
                />
              </div>
              {heightError && (
                <FormError className="formError">
                  Minimum height should be 500
                </FormError>
              )}
            </InputDiv>
            <InputDiv style={{ width: "50%" }}>
              <div style={{ flexDirection: "row" }}>
                <label>Width (pixel):</label>
                <input
                  type="number"
                  value={bubbleChatWidth}
                  defaultValue={350}
                  min={350}
                  max={800}
                  onChange={(e) => {
                    setBubbleChatWidth(e.target.value);
                    if (e.target.value < "350") {
                      setWidthError(true);
                    } else if (e.target.value > "800") {
                      setWidthError(true);
                    } else setWidthError(false);
                  }}
                  style={{ width: "50%" }}
                />
              </div>
              {widthError && (
                <FormError className="formError">
                  Width should be between 350 to 800
                </FormError>
              )}
            </InputDiv>
          </div>
          <InputDiv>
            <SaveButton
              type="button"
              onClick={() => saveBubbleChatSettings()}
              disabled={widthError || heightError}
            >
              Save Settings
            </SaveButton>
          </InputDiv>
          <Row>
            <CenterDiv>
              <pre className="code-block">
                <p>Copy Code</p>
                <CopyBlock
                  text={bubbleChatCode(bookId, encrptedData)}
                  language="javascript"
                  theme={dracula}
                  copied={true}
                  onCopy={() => {
                    NotificationManager.success(`Copied script`);

                    trackEvent({
                      email: getUsersEmail(user),
                      eventCategory: EventCategory.CHATBOT,
                      event:
                        ChatBotEvents.USER_COPIED_BUBBLE_SCRIPT_FROM_MANAGE_CHATBOT,
                      subCategory: SubCategory.EVENT,
                      // chatbotType: selectedBook.fileType,
                      bookId: bookId,
                      description: "Bubble script copied.",
                    });
                  }}
                />
              </pre>
            </CenterDiv>
          </Row>

          <TextDiv>
            <Text>{textIframe}</Text>
          </TextDiv>
          <Row>
            {/* <LeftDiv> */}
            <InputDiv>
              <div style={{ flexDirection: "row" }}>
                <label>iFrame Height (pixel):</label>
                <input
                  type="number"
                  value={iframeHeight}
                  onChange={(e) => {
                    setIframeHeight(e.target.value);
                  }}
                />
              </div>
              {/* <label style={{ marginLeft: "10px" }}>
                iFrame Width (percent):
              </label>
              <input
                type="number"
                value={iframeWidth}
                onChange={(e) => {
                  setIframeWidth(e.target.value);
                }}
              /> */}
            </InputDiv>
            {/* </LeftDiv> */}
            <CenterDiv>
              <pre className="code-block-iframe">
                <p>Copy Code</p>
                <CopyBlock
                  text={iFrameCode(bookId, iframeWidth, iframeHeight)}
                  language="javascript"
                  theme={dracula}
                  copied={true}
                  onCopy={() => {
                    NotificationManager.success(`Copied code`);

                    trackEvent({
                      email: getUsersEmail(user),
                      eventCategory: EventCategory.CHATBOT,
                      event:
                        ChatBotEvents.USER_COPIED_IFRAME_SCRIPT_FROM_MANAGE_CHATBOT,
                      subCategory: SubCategory.EVENT,
                      // chatbotType: selectedBook.fileType,
                      bookId: bookId,
                      description: "Iframe script copied.",
                    });
                  }}
                />
              </pre>
            </CenterDiv>
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </>
  ) : (
    <></>
  );
};

export default CodeBox;
