import { createContext, ReactElement, useState } from "react";
import {
  AdminProps,
  AdminValues,
  AdminSetting,
  UserDashboardDetails,
  UserProfileSetting,
} from "../types";

export const AdminContext = createContext({} as AdminValues);

const AdminProvider = (props: AdminProps): ReactElement<AdminValues> => {
  const [openAISettings, setOpenAISettings] = useState<AdminSetting[]>([]);
  const [userDashboardDetails, setUserDashboardDetails] =
    useState<UserDashboardDetails>({
      booksInLibrary: 0,
      lifetimeTokenUsed: 0,
      thisMonthTokenUsed: 0,
    });
  const [userProfileSettings, setUserProfileSettings] =
    useState<UserProfileSetting>({
      id: "",
      email: "",
      firstname: "",
      lastname: "",
    });

  const [appSettings, setAppSettings] = useState<AdminSetting[]>([]);
  const [operandSettings, setOperandSettings] = useState<AdminSetting[]>([]);

  return (
    <AdminContext.Provider
      value={{
        openAISettings,
        setOpenAISettings,
        userProfileSettings,
        setUserProfileSettings,
        userDashboardDetails,
        setUserDashboardDetails,
        appSettings,
        setAppSettings,
        operandSettings,
        setOperandSettings,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
