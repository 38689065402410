import { Field, Form } from "formik";
import { OverlayPanel } from "primereact/overlaypanel";
import { TreeSelect } from "primereact/treeselect";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToBottom from "react-scroll-to-bottom";
import styled from "styled-components";
import { LARGE, MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
const { innerWidth: width } = window;
const isMobileWidth = width < 1000;

interface Selected {
  selected: boolean;
}

interface isChat {
  isChat: boolean;
}

interface fromChatbotBuilder {
  fromChatbotBuilder: boolean;
}

export const MainContainer = styled(Container)`
  padding: 0 !important;
  border-top: 1px #21232c48 solid;
`;

export const Sidebar = styled(Col)`
  background: #2f3349;
  flex-direction: column;
  overflow: auto;
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: ${(props) => (props.fromChatbotBuilder ? "100%" : "450px")};
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${(props) => (props.fromChatbotBuilder ? "100%" : "450px")};
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    height: ${(props) =>
      props.fromChatbotBuilder ? "100%" : "calc(100vh - 168px)"};
  }
`;

export const SideBarHeader = styled.div`
  height: 70px;
  padding: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
export const SideBarContent = styled.div`
  width: 100%;
  padding: 10px;
  flex-direction: column;
`;
export const LogoutButton = styled.img`
  padding: 0;
  width: 30%;
  margin: auto;
  cursor: pointer;
`;

export const BackButton = styled.img`
  padding: 0;
  width: 15px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 10px;
  }
`;

export const BookFetchForm = styled(Form)`
  /* margin: auto; */
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  margin: 0;
  align-self: end;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0rem;
  }
`;

export const FieldSection = styled.div`
  position: relative;
  flex-direction: column;
`;

export const FormInput = styled(Field)`
  width: 100%;
  padding: 15px;
  padding-left: 20px;
  border: ${(props) => (props.error ? "0px solid red" : "1px solid #EAEAEA")};
  border-radius: 5px;
  background-color: #f8f7fa;
  font-size: 1rem;
  &::placeholder {
    color: #33303c;
    opacity: 1;
    font-size: 12px;
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 5px;
  }
`;

export const FetchBookButton = styled.button`
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 16px;
  cursor: pointer;
  top: 0.8rem;
  position: absolute;
  right: 10px;
  background: none;
  border: none;
`;

export const ChatInputBox = styled(Col)`
  /* min-height: 84vh; */
`;

export const ChatHistoryHeading = styled.h5`
  color: #cccfdd;
`;

export const RightColumn = styled(Col)`
  flex-direction: column;
  background-color: #f8f7fa;
`;

export const RightContentRow = styled(Row)`
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    /* height: calc(100vh - 216px); */
    height: calc(100vh - 270px);
    padding: 0;
    margin: 0px 5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: calc(100vh - 216px);
    padding: 0;
    margin: 0px 5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    height: ${(props) =>
      props.fromChatbotBuilder ? "100%" : "calc(100vh - 168px)"};
    padding: 10px 0px 0px 0px;
    margin: 0px;
    margin-top: 0px;
  }
`;

export const ChatBotContent = styled(Col)`
  flex-direction: column;
  position: relative;

  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0 !important;
  }
`;

export const ChatHeader = styled.div`
  background: #2f3349;
  border-radius: 4px;
  justify-content: space-between;
  padding: 0.2rem 0.2rem;
  position: relative;
`;

export const ChatHistoryPopupUI = styled.div`
  background: #2f3349;
  border-radius: 4px;
  position: absolute;
  padding: 0.5rem;
  left: 0;
  top: 2.5rem;
  z-index: 9;
  width: 60%;
`;

export const ChatHistoryHeader = styled.div`
  color: #fff;
  justify-content: space-between;
  flex: 1;
  & p {
    margin: 0;
  }
`;

export const ChatHistorySection = styled.div`
  & img {
    padding-right: 0.5rem;
    cursor: pointer;
  }
  align-items: center;
  color: #fff;
`;

export const InterfaceToggle = styled.div`
  right: 0.5rem;
  top: -0.5rem;
  cursor: pointer;
  z-index: 9;
  border: 1px solid #ccc;
  border-radius: 4px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    position: none;
  }
  @media (min-width: ${TAB.minWidth}px) {
    position: none;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    position: absolute;
  }
`;

export const ChatInterface = styled.div`
  padding: 8px;
  background: ${(props: isChat) => (props.isChat ? "#1585b5" : "#ffffff")};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const MessageInterface = styled.div`
  padding: 8px;
  border-top-right-radius: 4px;
  background: ${(props: isChat) => (props.isChat ? "#1585b5" : "#ffffff")};
  border-bottom-right-radius: 4px;
`;

export const ScrollDiv = styled(ScrollToBottom)`
  width: 100%;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: calc(100vh - 400px);
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: calc(100vh - 400px);
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
  height: ${(props: fromChatbotBuilder) => (props.fromChatbotBuilder ? "397px" : "")};
  }
`;

export const BookContent = styled(Col)``;

export const SignleQuestionAnswerSection = styled.div`
  margin: 5px;
  flex-direction: column;
`;

export const ResponseSection = styled.div`
  padding: 10px;
  color: #282c34;
  background: #f8f7fa;
  box-shadow: 1px 8px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 8px 8px;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 5px;
  align-items: flex-start;
  flex-direction: column;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
    font-size: 1rem;
  }
  & p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const QuestionSection = styled.div`
  padding: 10px;
  background: #f8f7fa;
  box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  align-items: center;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
    font-size: 1rem;
  }
`;

export const QuestionAnswerSection = styled.div`
  flex-direction: column;
  width: 100%;
  margin-top: auto;
`;

export const HistoryQuestion = styled.div`
  align-items: center;
  padding: 10px;
  margin: 0.2rem 0rem;
  background: ${(props: Selected) => (props.selected ? "#3d4156" : "")};
  border-radius: 5px;
  cursor: pointer;
  & p {
    color: #9c9eaf;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & img {
    margin-right: 0.8rem;
  }
`;

export const FieldColumn = styled(Col)`
  position: relative;
`;

export const OverlayFieldColumn = styled(Col)`
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 999;
  background: #00000050;
  cursor: not-allowed;
`;

export const StatusLoaderSection = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99999;
  background: #0001;
`;

export const WaitTitle = styled.p`
  text-align: center;
  font-size: 24px;
  color: #4f83b5;
  font-weight: bold;
`;

export const WaitDescription = styled.p`
  text-align: center;
`;

export const ChatPageHeader = styled.div`
  background-color: #f8f7fa;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-top: 2px solid rgba(47, 51, 73, 0.2);
`;

export const ChatPageHeaderNewStyle = styled(ChatPageHeader)`
  border-top: 0px solid rgba(47, 51, 73, 0.2);
`;

export const RestrictedSection = styled(Row)`
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const RemainingCount = styled(Col)`
  color: #ea5455;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  background: #ffe1e1;
  border-radius: 6px;
  padding: 0.2rem 1rem;
`;

export const LoginLink = styled(Col)`
  color: #1585b5;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;

export const NoteListTitle = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #1585b5;
`;

export const SelectField = styled.select`
  font-size: 14px;
  font-weight: 500;
  color: #1585b5;
  border: none;
  width: 20px;
`;

export const SelectOption = styled.option`
  font-size: 14px;
  color: #33303c;
  padding: 5px 20px 5px 5px;
  &:hover {
    background: #1585b5;
  }
`;

export const TreeSelectStyle = styled(TreeSelect)`
  font-size: 14px;
  border: 0;
  background: #f8f7fa;

  & span {
    font-size: 1rem;
    color: #1585b5;
    font-weight: bold;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin: 0;
    font-size: 13px;
    & span {
      display: none;
    }
    & div.p-treeselect-label-container {
      display: none;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin: 0;
    font-size: 13px;
    text-align: left;
    & span {
      display: none;
    }
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin: 0;
    font-size: 13px;
    text-align: left;
    & span {
      display: block;
    }
  }
`;
export const AddCategoryStyle = styled.p`
  font-size: 14px;
  color: #1585b5;
  font-weight: bold;
  margin: 0;
  margin-left: 3rem;
  cursor: pointer;
  align-self: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin: 0;
    font-size: 13px;
    text-align: left;
    align-self: flex-start;
  }
`;

export const OverlayPanelStyle = styled(OverlayPanel)`
  flex-direction: column;
`;

export const AddCategoryForm = styled(Form)`
  padding: 1.25rem;
  padding-top: 0;
  & input {
    margin: 0.5rem 0rem;
    background: #ffffff;
    border: 1px solid rgba(51, 48, 60, 0.4);
    border-radius: 4px;
    color: rgba(51, 48, 60, 0.6);
    padding: 5px;
    font-size: 14px;
  }
  & label {
    color: rgba(51, 48, 60, 0.8);
    font-size: 14px;
    font-weight: bold;
  }
  & button {
    background: #1585b5;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    border-color: #1585b5;
    box-shadow: none;
  }
`;

export const AddCategoryHeading = styled.h6`
  color: #33303c;
  font-weight: 600;
  padding-left: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e6e6e7;
`;

export const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
  font-size: 12px;
`;

export const ChipParent = styled.div`
  background-color: #dee2e6;
  color: #495057;
  border-radius: 16px;
  padding: 0 0.75rem;
  cursor: pointer;
`;
export const CrossIcon = styled.span`
  font-size: 1rem !important;
  font-weight: bold;
  display: flex;
  align-self: center;
  margin-left: 0.5rem;
`;

export const Column = styled(Col)`
  display: flex;
  justify-content: end;
`;

export const Wrapper = styled.div`
  padding-left: 27px;
`;
export const WrapperHeader = styled.div`
  padding-left: 10px;
  width: 77px;
`;
export const DowngradeInfoText = styled.p<{ limitCount: boolean }>`
  font-weight: 700;
  color: ${(props) => (props.limitCount ? "#EC2D2D" : "#11b753")};
  margin-bottom: 0px;
  align-items: center;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 8px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 8px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 12px;
  }
`;
export const DowngradeContainer = styled.div<{ limitCount: boolean }>`
  margin-bottom: 5px;
  display: flex;
  // width: 300px;
  background: ${(props) => (props.limitCount ? "#EC2D2D1A" : "#11b75326")};
  border-radius: 6px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 5px 7px 5px 7px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    padding: 5px 5px 5px 5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    padding: 5px 25px 5px 20px;
  }
`;
export const UpgradeSpan = styled.span`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 8px;
    padding: 0.5rem 0.7rem;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 8px;
    padding: 0.5rem 0.7rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 12px;
    padding: 0.5rem 1.5rem;
  }
`;

export const SourceElements = styled.p`
  font-size: 14px;
  color: #1585b5;
  font-weight: bold;
  margin: 0;
  margin-left: 3rem;
  cursor: pointer;
  align-self: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin: 0;
    font-size: 13px;
    align-self: flex-start;
  }
`;

export const MarkDownContent = styled.div`
  color: ${(props) => props.color};
  font-size: ${isMobileWidth ? "0.75rem" : "1rem"};
  & h1 {
    color: ${(props) => props.color};
  }
  & h2 {
    color: ${(props) => props.color};
  }
  & h3 {
    color: ${(props) => props.color};
  }
  & h4 {
    color: ${(props) => props.color};
  }
  & h5 {
    color: ${(props) => props.color};
  }
  & h6 {
    color: ${(props) => props.color};
  }
  & p {
    color: ${(props) => props.color};
  }
  & span {
    color: ${(props) => props.color};
  }
  & label {
    color: ${(props) => props.color};
  }
  & table {
    color: ${(props) => props.color};
  }
  & td {
    color: ${(props) => props.color};
  }
  & tr {
    color: ${(props) => props.color};
  }
  & th {
    color: ${(props) => props.color};
  }
  & li {
    color: ${(props) => props.color};
  }
`;
