import { Col } from "react-bootstrap";
import styled from "styled-components";
import ChatBotIcon from "../../../images/chat-bot-icon.svg";
import UserImage from "../../../images/chat-user.svg";
import THUMBDOWN from "../../../images/thumb-down2.svg";
import THUMBUP from "../../../images/thumb-up2.svg";
import CustomMarkdown from "../../../utils/custom-markdown";
import { MarkDownContent } from "../../publicComponent/styled-public";
import moment from "moment";

// interface Color {
//   selected: boolean;
// }

const SessionContentBody = styled.div`
  flex-direction: column;
  overflow: auto;
`;

export const SignleQuestionAnswerSection = styled.div`
  margin: 5px;
  flex-direction: column;
`;

export const ResponseSection = styled.div`
  padding: 1px 8px;
  color: #282c34;
  /* background: #ffffff; */
  border-bottom: 1px solid #ebe8f0;
  align-items: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const QuestionSection = styled.div`
  padding: 8px 8px;
  /* background: #ffffff; */
  align-items: center;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
    font-size: 14px;
  }
`;

export const QuestionAnswerSection = styled.div`
  flex-direction: column;
  width: 100%;
`;
// const DeleteDiv = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `;
const Header = styled.div`
  display: flex;
  /* justify-content: flex-end; */
  background: #2f3349;
  height: 40px;
`;

const ParentDiv = styled.div`
  background: #33303cb2;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: block;
  z-index: 9999;
`;
const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 95%;
  height: 92vh;
  top: 12px;
  left: 10px;
  right: 0;
  /* background-color: white; */
  background-color: #eaeaea;
  z-index: 9999;
  overflow-y: scroll;
  border-radius: 5px;
`;

const MobileChatbotSessionsPopup = ({
  showMobileChatBotPopup,
  setMobileChatPopup,
  selectedChatSession,
}: any) => {
  const hidePopup = () => {
    setMobileChatPopup(false);
  };

  return showMobileChatBotPopup ? (
    <>
      <ParentDiv>
        <MainDiv>
          <Header>
            <Col
              xs={6}
              style={{ alignItems: "center", justifyContent: "space-evenly" }}
            >
              <span style={{ fontSize: "14px", color: "white" }}>
                {selectedChatSession.name
                  ? selectedChatSession.name
                  : selectedChatSession.email
                  ? selectedChatSession.email
                  : selectedChatSession.mobileNumber
                  ? selectedChatSession.mobileNumber
                  : "Guest User"}
              </span>
              <span style={{ fontSize: "13px", color: "white" }}>
                {selectedChatSession.source}
              </span>
            </Col>
            <Col xs={6} style={{ justifyContent: "flex-end" }}>
              <img
                style={{ padding: "10px" }}
                alt="cross"
                src={
                  "https://book-chat.s3.amazonaws.com/5d94cd16-38aa-43d2-86fd-c5e87e77d2be/white-cross.svg-JTP54L.svg"
                }
                onClick={() => {
                  hidePopup();
                }}
              />
            </Col>
          </Header>
          <div
            style={{
              height: "60px",
              padding: "10px",
              borderBottom: "1px solid #EBE8F0",
              background: "white",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {selectedChatSession.name ? selectedChatSession.name : "N/A"}{" "}
              &nbsp;|&nbsp;{" "}
              {selectedChatSession.email ? selectedChatSession.email : "N/A"}{" "}
              &nbsp;|&nbsp;{" "}
              {selectedChatSession.mobileNumber
                ? selectedChatSession.mobileNumber
                : "N/A"}{" "}
              &nbsp;|&nbsp;
              {selectedChatSession.createdAt
                ? moment(selectedChatSession.createdAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )
                : "N/A"}{" "}
            </div>
            <div style={{ fontSize: "15px" }}>
              <img width="20" src={THUMBUP} alt="user_image" />
              &nbsp; {selectedChatSession.goodCount}
              &nbsp;|&nbsp; <img width="20" src={THUMBDOWN} alt="user_image" />
              &nbsp; {selectedChatSession.badCount}
            </div>
          </div>

          <SessionContentBody>
            {selectedChatSession &&
              selectedChatSession.chatHistories &&
              selectedChatSession.chatHistories.length > 0 &&
              selectedChatSession.chatHistories.map((chatHistory: any) => {
                return (
                  <SignleQuestionAnswerSection>
                    <QuestionSection>
                      <img width="20" src={UserImage} alt="user_image" />
                      &nbsp;&nbsp;
                      <h5>{chatHistory.question}</h5>
                    </QuestionSection>
                    <ResponseSection>
                      <div style={{ borderBottom: "1px solid #ebe8f0" }}>
                        <img
                          width="20"
                          src={ChatBotIcon}
                          alt="user_image"
                          style={{
                            display: "flex",
                            alignSelf: "baseline",
                          }}
                        />
                        &nbsp;&nbsp;
                        {chatHistory.answer && chatHistory.answer.length > 0 ? (
                          <MarkDownContent color={"rgba(51, 48, 60, 1)"}>
                            <CustomMarkdown text={chatHistory.answer} />
                          </MarkDownContent>
                        ) : (
                          <h5>{`${chatHistory.answer}`}</h5>
                        )}
                      </div>
                    </ResponseSection>
                    {(chatHistory && chatHistory.rating === "Good") ||
                    chatHistory.rating === "Bad" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "5px 0",
                        }}
                      >
                        <img
                          width="30"
                          src={
                            chatHistory.rating === "Good" ? THUMBUP : THUMBDOWN
                          }
                          alt=""
                        />
                      </div>
                    ) : null}
                  </SignleQuestionAnswerSection>
                );
              })}
          </SessionContentBody>
        </MainDiv>
      </ParentDiv>
    </>
  ) : (
    <></>
  );
};

export default MobileChatbotSessionsPopup;
