import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MOBILE } from "../../constants/mobile";
import { TAB } from "../../constants/tab";
import { UserContext } from "../../context/UserContext";
import { LoaderContext } from "../../context/loader-context";
import { PlanContext } from "../../context/plan-context";
import SaveButtonIcon from "../../images/MagicArrow.svg";
import CancelButtonIcon from "../../images/cross.svg";
import PencilButton from "../../images/flashcard-pencil.svg";
import { Mixpanel } from "../../mixpanel";
import myAxios from "../../myaxios";
import { parseErrorResponse } from "../../utils/utils";

// import ChartContainer from "../charts";

const Label = styled(FormLabel)`
  font-weight: bold;
`;

const ValueField = styled(Field)`
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const UserProfileForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FieldSection = styled.div`
  flex-direction: row;
  justify-content: space-between;
`;
const ActionButton = styled(Button)`
  margin-left: 1rem;
  display: flex;
  align-items: center;
`;
const SubmitButton = styled(Button)`
  margin-left: 1rem;
  display: flex;
  align-items: center;
`;
const UpgradeSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 5px 25px;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
  cursor: pointer;
`;

const PlanSpan = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #585858;
  margin-left: 5px;
  margin-top: 1px;
  display: flex;
  width: 60px;
  align-items: center;
`;

const Column = styled(Col)`
  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column;
    background-color: #fff;
    padding: 0px;
    border-radius: 5px;
    /* height: calc(100vh - 30vh); */
    margin-left: 0;
  }
  @media (min-width: ${TAB.minWidth}px) {
  }
`;
const MobileUserSettings = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { setPlanHighliter } = useContext(PlanContext);
  const { setShowLoader } = useContext(LoaderContext);
  const [userSettings, setUserSettings] = useState<any>();
  const [editOpenAiKey, setEditOpenAiKey] = useState(true);

  const saveUserSettings = (values: any) => {
    setShowLoader(true);
    myAxios
      .post(`/user-settings`, {
        ...values,
      })
      .then((response) => {
        if (response.data.success) {
          NotificationManager.success(
            "Settings save successfully...",
            "",
            1500
          );
          if (response.data && response.data.data) {
            setUserSettings(response.data.data);
            setEditOpenAiKey(true);
            setShowLoader(false);
          }
        } else {
          NotificationManager.error(response.data.message, "", 2000);
          setEditOpenAiKey(true);
          setShowLoader(false);
        }
        // setLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
        setShowLoader(false);
      });
  };

  useEffect(() => {
    setShowLoader(true);
    if (user) {
      myAxios
        .get(`${process.env.REACT_APP_SERVER_URL}/user-settings`)
        .then((res) => {
          // console.log("response, ", res.data.data);
          setShowLoader(false);
          setUserSettings(res.data.data);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
    // eslint-disable-next-line
  }, [user]);

  const deleteMixpanelUser = () => {
    Mixpanel.people.remove({
      $delete: true,
      $distinct_id: user?.email,
      $ignore_alias: true,
    });
  };

  return (
    <>
      <Row
      // style={{
      //   marginTop: "-1rem",
      // }}
      >
        <Column sm={12} xs={12} md={12} lg={6}>
          <Formik
            enableReinitialize={true}
            validateOnChange={true}
            initialValues={{
              openaiKey: userSettings && userSettings.openaiKey,
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (!values.openaiKey || values.openaiKey === "") {
                NotificationManager.error("Please enter valid API key!");
                setShowLoader(false);
                setEditOpenAiKey(true);
                return;
              }
              saveUserSettings(values);
            }}
          >
            {({ setFieldValue }) => (
              <UserProfileForm>
                <Row>
                  <Label>OpenAI API Key</Label>
                  <FieldSection>
                    {editOpenAiKey ? (
                      <ValueField
                        style={{
                          backgroundColor: editOpenAiKey
                            ? "#d7d7d7"
                            : "#ffffff",
                        }}
                        type="text"
                        disabled
                        name={`openaiKey`}
                        value={userSettings && userSettings.openaiKey}
                      />
                    ) : (
                      <ValueField
                        style={{
                          backgroundColor: editOpenAiKey
                            ? "#d7d7d7"
                            : "#ffffff",
                        }}
                        type="text"
                        name={`openaiKey`}
                      />
                    )}
                    {editOpenAiKey ? (
                      <>
                        {user?.currentSubscription ? (
                          user?.currentSubscription.meta?.customOpenAPIKey ? (
                            <ActionButton type="button">
                              <img
                                width={"15"}
                                src={PencilButton}
                                alt="edit"
                                onClick={() => {
                                  setEditOpenAiKey(false);
                                  setFieldValue("openaiKey", "");
                                }}
                              />
                            </ActionButton>
                          ) : (
                            <>
                              <ActionButton type="button">
                                <img
                                  width={"15"}
                                  src={PencilButton}
                                  alt="edit"
                                />
                              </ActionButton>
                              <Row>
                                <div style={{ paddingLeft: "2rem" }}>
                                  <UpgradeSpan
                                    onClick={(e) => {
                                      setPlanHighliter({
                                        isBookHighliter: false,
                                        bookLimit: 0,
                                        isDeleteBookHighliter: false,
                                        isFlashcardHighliter: false,
                                        isCharacterLimit: false,
                                        pdfLimit: 0,
                                        isDeleteAccount: false,
                                        isLeadLoginSettings: false,
                                        isGpt35Turbo16k: false,
                                        isGpt4TurboPreview: false,
                                        isCustomOpenAPIKey: true,
                                      });
                                      navigate("/subscription");
                                    }}
                                  >
                                    Upgrade Plan
                                  </UpgradeSpan>
                                  {/* <PlanSpan>Your plan</PlanSpan> */}
                                </div>
                              </Row>
                            </>
                          )
                        ) : (
                          <>
                            <ActionButton type="button">
                              <img width={"15"} src={PencilButton} alt="edit" />
                            </ActionButton>
                            <div style={{ paddingLeft: "2rem" }}>
                              <UpgradeSpan
                                onClick={(e) => {
                                  setPlanHighliter({
                                    isBookHighliter: false,
                                    bookLimit: 0,
                                    isDeleteBookHighliter: false,
                                    isFlashcardHighliter: false,
                                    isCharacterLimit: false,
                                    pdfLimit: 0,
                                    isDeleteAccount: false,
                                    isLeadLoginSettings: false,
                                    isGpt35Turbo16k: false,
                                    isGpt4TurboPreview: false,
                                    isCustomOpenAPIKey: true,
                                  });
                                  navigate("/subscription");
                                }}
                              >
                                Purchase Plan
                              </UpgradeSpan>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <SubmitButton type="submit">
                          <img width={"15"} src={SaveButtonIcon} alt="edit" />
                        </SubmitButton>
                        <ActionButton type="button">
                          <img
                            width={"20"}
                            src={CancelButtonIcon}
                            alt="edit"
                            onClick={() => {
                              setEditOpenAiKey(true);
                            }}
                          />
                        </ActionButton>
                      </>
                    )}
                  </FieldSection>
                </Row>
              </UserProfileForm>
            )}
          </Formik>
        </Column>
      </Row>
    </>
  );
};

export default MobileUserSettings;
