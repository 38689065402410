import { loadStripe } from "@stripe/stripe-js";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { MOBILE } from "../../constants/mobile";
import { UserContext } from "../../context/UserContext";
import { PlanContext } from "../../context/plan-context";
import {
  EventCategory,
  PlanEvents,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import BackIcon from "../../images/chat-back.svg";
import Tick from "../../images/green_circle-check-fill.svg";
import TickOrange from "../../images/tick-orange.svg";
import { Mixpanel } from "../../mixpanel";
import myAxios from "../../myaxios";
import { SubscriptionPlan } from "../../types";
import {
  isTestUser,
  numberWithComma,
  parseErrorResponse,
} from "../../utils/utils";

declare var window: any;
interface HighLight {
  isHighlight: boolean | undefined;
}
const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeadingCenter = styled.h6`
  text-align: center;
  color: #ff0000;
`;

const HeadingPlans = styled.h4`
  font-weight: bold;
  margin-left: 28px;
`;

const ToogleSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
  margin: 0 10px;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #1585b5;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  margin: 10px;
  padding: 12px;
`;

const PlanTextMonthly = styled.span`
  color: #1585b5;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
`;
const PlanTextYearly = styled.span`
  color: #1585b5;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
`;

const PricingCol = styled(Col)``;

const PricingDiv = styled.div`
  /* background: #f9feff; */
  width: 100%;

  border: ${(props: HighLight) =>
    props.isHighlight ? "2px solid #11b753" : "1.5px solid #d7d9e3"};

  box-shadow: ${(props: HighLight) =>
    props.isHighlight ? " 0 0 5px rgba(17, 183, 83, 1);" : ""};

  border-radius: 0px;
  border: none;
  /* padding: 10px; */
  flex-direction: column;
`;

const PlanName = styled.h4`
  font-size: 14px;
  font-weight: 500;
  padding-left: 15px;
  color: #33303c;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0px;
`;
const PlanAmount = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 1;
`;
const PurchaseButton = styled.button`
  background: #1585b5;
  padding: 5px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 60%;
  font-size: 14px;
`;

const PurchaseButtonDisable = styled.button`
  background: #1585b5;
  padding: 5px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  opacity: 0.5;
  cursor: not-allowed !important;
  width: 60%;
`;

const PlanDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const CurrentPlanProperties = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CurrentPlanName = styled.span`
  font-weight: bold;
  /* margin-right: 50px; */
  color: rgba(17, 183, 83, 1);
  font-size: 16px;
`;

const PlanDesc = styled.h6`
  color: #1f062a;
  margin-right: 50px;
  font-weight: 500;
  font-size: 13px;
  color: #1f062a80;
  line-height: 18px;
`;

const TickImage = styled.img.attrs({
  src: Tick,
})`
  width: 23px;
  margin-right: 4px;
  object-fit: contain;
`;

const CurrentPlanSection = styled.div`
  padding: 20px;
  margin-bottom: 10px;
  border: 1.5px solid rgba(220, 220, 220, 0.3);
  box-shadow: 0px 3px 3px rgba(31, 6, 42, 0.08);
  border-radius: 1px;
  flex-direction: column;
  background-color: #fff;
  margin: 20px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin: 0;
    padding: 7px 0px;
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
`;

const AvailablePlansSection = styled.div`
  /* width: calc(100vw - 45px); */
  margin-top: 20px;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
  margin: 20px;
  padding: 20px;
  border-radius: 1px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin: 0px;
    padding: 7px 10px;
    background-color: transparent;
  }
`;

const PlansContainer = styled.div`
  /* margin: 1rem 3rem 1rem 1rem; */
  flex-direction: column;
`;

const DescriptionBox = styled.div`
  height: 100%;
  /* margin-top: 20px;
  margin-bottom: 18px; */
`;

const DescriptionText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #212529;
  flex-direction: column;
  flex: 1;
`;

const TrialHeading = styled.span`
  font-size: 10px;
  color: #cd0606;
  padding: 5px 12px !important;
  background-color: rgba(205, 6, 6, 0.17);
  flex-grow: 1;
`;

const ActivePlanHeading = styled.span`
  font-size: 10px;
  color: #107414;
  padding: 5px 12px !important;
  background-color: #39e41a2b;
  flex-grow: 1;
  border-radius: 5px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid #1585b5;
  width: 40px;
  height: 40px;
  animation-name: ${spin};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

const DowngradeContainer = styled.div`
  /* margin-top: 20px; */
  /* padding: 0.5rem 2rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fdeeee;
  flex-direction: column;
  margin: 10px;
  padding: 8px;
`;

const DowngradeInfoText = styled.p`
  font-weight: 500;
  color: red;
  font-size: 14px;
  /* margin-right: 20px;
  margin-bottom: 0px; */
`;

const HighlitedText = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: #fc6601;
`;
const BackButton = styled.img`
  padding: 0;
  width: 10px;
  cursor: pointer;
`;

const CancelDowngrade = styled.button`
  background: transparent;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid red;
  color: red;
  font-weight: bold;
  font-size: 14px;
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const SimpleContainer = styled.div`
  display: inline-grid;
`;

const ComparisionSection = styled(Col)`
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-self: center;
  a {
    color: #1585b5;
  }
`;

const ComparisionHeading = styled.h6`
  margin: 1.5rem 0rem;
`;
const ActivePlanSpan = styled.span`
  font-size: 14px;
  margin-left: 10px;
  font-weight: 500;
`;
const ExpireDiv = styled.div`
  width: 150px;
`;

function MobilePlans({
  loading,
  setLoading,
}: {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}) {
  const { user, setUser } = useContext(UserContext);
  const {
    planHighliter,
    plansList,
    activePlan,
    setActivePlan,
    downgradeRequestData,
    setDowngradeRequestData,
  } = useContext(PlanContext);
  const [selectedPlanType, setSelectedPlanType] = useState<string>(
    user && user.currentSubscription?.interval
      ? user.currentSubscription?.interval
      : "monthly"
  );

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
  );
  const navigate = useNavigate();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const redirectSession = async (sessionId: any) => {
    const stripe = await stripePromise;
    stripe?.redirectToCheckout({ sessionId });
  };
  const handleCancelDowngradeSubscription = () => {
    setButtonLoading(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/cancel-downgrade-request", {})
      .then((response) => {
        if (response && response.data && response.data.success) {
          // Need to update user

          setUser({
            ...user,
            downgradeRequested: false,
            downgradePlanStartTime: undefined,
          });
          setDowngradeRequestData({
            downgradeRequested: false,
            downgradePlanStartTime: undefined,
          });

          trackEvent({
            email: getUsersEmail(user),
            eventCategory: EventCategory.PLAN,
            event: PlanEvents.USER_CANCEL_DOWNGRADE_PLAN,
            subCategory: SubCategory.EVENT,
            rawData: response.data.data,
          });

          NotificationManager.success("Change Plan cancelled...");
        } else {
          NotificationManager.error(parseErrorResponse(undefined));
        }
        setButtonLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
        // console.error(
        //   "Gettings error at the time of cancel downgrade ... ",
        //   error
        // );
        setButtonLoading(false);
      });
  };

  const createPurchaseSession = (
    type: string,
    priceId: string,
    oldSubscriptionId: string | undefined,
    planName: string
  ) => {
    setButtonLoading(true);
    if (!isTestUser(user?.email))
      Mixpanel.track("User tyring to purchase new plan", {
        priceId: priceId,
        type: type,
        planName: planName,
        email: user?.email,
      });

    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/purchase-plan", {
        type,
        priceId,
        oldSubscriptionId,
        referral: window && window.Rewardful && window.Rewardful.referral,
      })
      .then((response) => {
        // console.log("response ---- ", response);

        if (response && response.data && response.data.success) {
          setButtonLoading(false);
          if (type === "Purchase" || type === "Upgrade") {
            redirectSession(response.data.data.sessionId);

            if (type === "Upgrade") {
              trackEvent({
                email: getUsersEmail(user),
                eventCategory: EventCategory.PLAN,
                event: PlanEvents.USER_TRY_TO_UPGRADE_PLAN,
                subCategory: SubCategory.EVENT,
                rawData: { priceId: priceId, type: type, planName: planName },
              });
            } else {
              trackEvent({
                email: getUsersEmail(user),
                eventCategory: EventCategory.PLAN,
                event: PlanEvents.USER_TRY_TO_PURCHASE_PLAN,
                subCategory: SubCategory.EVENT,
                rawData: { priceId: priceId, type: type, planName: planName },
              });
            }
          } else {
            // Need to update user
            trackEvent({
              email: getUsersEmail(user),
              eventCategory: EventCategory.PLAN,
              event: PlanEvents.USER_TRY_TO_DOWNGRADE_PLAN,
              subCategory: SubCategory.EVENT,
              rawData: { priceId: priceId, type: type, planName: planName },
            });
            setButtonLoading(false);
            setUser({
              ...user,
              downgradeRequested: response.data.data.downgradeRequested,
              downgradePlanStartTime: response.data.data.downgradePlanStartTime,
            });

            setDowngradeRequestData({
              downgradeRequested: response.data.data.downgradeRequested,
              downgradePlanStartTime: response.data.data.downgradePlanStartTime,
            });
            NotificationManager.success(response.data.data.message);
          }
        }
      })
      .catch((error) => {
        setButtonLoading(false);
        NotificationManager.error(parseErrorResponse(error));
        // console.error(
        //   "Gettings error at the time of Purchase paln ... ",
        //   error
        // );
      });
  };

  const cancelPlan = () => {
    setLoading(true);
    setButtonLoading(true);
    myAxios
      .get(process.env.REACT_APP_SERVER_URL + "/cancel-plan", {})
      .then((response) => {
        if (response && response.data && response.data.success) {
          setActivePlan(response.data.data);
          NotificationManager.success("Plan cancel at period end..");

          trackEvent({
            email: getUsersEmail(user),
            eventCategory: EventCategory.PLAN,
            event: PlanEvents.USER_REQUESTED_TO_CANCEL_PLAN,
            subCategory: SubCategory.EVENT,
            rawData: response.data.data,
          });
        } else {
          NotificationManager.error(parseErrorResponse(undefined));
        }
        setLoading(false);
        setButtonLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
        // console.error("Gettings error at the time of cancel plan ... ", error);
        setButtonLoading(false);
        setLoading(false);
      });
  };
  useEffect(() => {}, [activePlan]);
  let usedFlashcard = activePlan?.meta.flashCard;
  let totalCharacter = activePlan?.meta.messageCount;

  return (
    <>
      {!loading ? (
        <>
          {activePlan ? (
            <>
              <CurrentPlanSection>
                <Row style={{ margin: "0" }}>
                  <Col
                    sm={2}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <BackButton
                      src={BackIcon}
                      onClick={() => {
                        navigate("/my-chatbots/create-pdf-chatbot");
                      }}
                    />
                    <ActivePlanSpan>Active Plan</ActivePlanSpan>
                  </Col>
                  <Col sm={10}></Col>
                </Row>

                <Container>
                  <PlanDetails>
                    <CurrentPlanProperties>
                      <PlansContainer>
                        <CurrentPlanName>
                          <TickImage />
                          {activePlan.name}{" "}
                          {activePlan.isCustomPlan ? (
                            <></>
                          ) : activePlan.subscriptionId === "test" ? (
                            "$0"
                          ) : (
                            <>
                              {"$" + activePlan?.price}
                              <span
                                style={{
                                  fontSize: "16px",

                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                {"/" + activePlan?.interval}
                              </span>
                            </>
                          )}
                        </CurrentPlanName>
                      </PlansContainer>

                      <PlansContainer>
                        <p className="mb-0" style={{ fontSize: "12px" }}>
                          <b>Purchaser</b>: {user && user.email}
                        </p>
                        {activePlan.meta.isFree ||
                        activePlan.isCancelAtEnd ? null : (
                          <>
                            {activePlan?.nextBillingDate && (
                              <PlanDesc style={{ lineHeight: "24px" }}>
                                Next Billing Date:{" "}
                                {moment
                                  .unix(activePlan.nextBillingDate)
                                  .format("Do MMM, YYYY")}
                              </PlanDesc>
                            )}
                          </>
                        )}
                      </PlansContainer>
                      <PlansContainer>
                        {activePlan.isCancelable ? (
                          activePlan.isCancelAtEnd ||
                          downgradeRequestData?.downgradeRequested ? null : (
                            <PurchaseButton
                              style={{ width: "200px" }}
                              onClick={() => cancelPlan()}
                            >
                              Cancel Plan
                            </PurchaseButton>
                          )
                        ) : null}
                      </PlansContainer>
                    </CurrentPlanProperties>
                    <ExpireDiv>
                      {activePlan.status === "trialing" ? (
                        <TrialHeading> {activePlan?.expireText}</TrialHeading>
                      ) : activePlan.status === "active" &&
                        (activePlan.daysRemaining! <= 7 ||
                          activePlan.isCancelAtEnd ||
                          activePlan.meta.isFree) ? (
                        <ActivePlanHeading>
                          {activePlan?.expireText}
                        </ActivePlanHeading>
                      ) : (
                        activePlan?.planDescription
                      )}
                    </ExpireDiv>
                  </PlanDetails>
                </Container>

                {activePlan.isCancelAtEnd &&
                  !downgradeRequestData?.downgradeRequested && (
                    <DowngradeContainer>
                      <DowngradeInfoText>
                        Your subscription will be cancelled at the end of your
                        current billing period.
                      </DowngradeInfoText>
                    </DowngradeContainer>
                  )}
                {downgradeRequestData?.downgradeRequested && (
                  <DowngradeContainer>
                    <DowngradeInfoText>
                      You have requested to change subscription, your
                      subscription will be changed on{" "}
                      {downgradeRequestData?.downgradePlanStartTime &&
                        moment
                          .unix(downgradeRequestData?.downgradePlanStartTime)
                          .format("Do MMM, YYYY")}
                    </DowngradeInfoText>
                    <CancelDowngrade
                      onClick={handleCancelDowngradeSubscription}
                    >
                      Cancel Change Plan request
                    </CancelDowngrade>
                  </DowngradeContainer>
                )}
              </CurrentPlanSection>
            </>
          ) : (
            <CurrentPlanSection>
              <Container>
                <HeadingCenter>no active subscriptions</HeadingCenter>
              </Container>
            </CurrentPlanSection>
          )}
        </>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <AvailablePlansSection>
        <HeadingWrapper>
          {/* <Heading>Change Your Plan</Heading> */}
          <ToogleSection>
            <PlanTextMonthly
              style={{
                color: selectedPlanType === "monthly" ? "#1585b5" : "#1f062a33",
              }}
            >
              Monthly Plans
            </PlanTextMonthly>
            <CheckBoxWrapper>
              {selectedPlanType === "monthly" && (
                <>
                  <CheckBox
                    id="checkbox"
                    type="checkbox"
                    onClick={() => setSelectedPlanType("yearly")}
                  />
                  <CheckBoxLabel htmlFor="checkbox" />
                </>
              )}
              {selectedPlanType === "yearly" && (
                <>
                  <CheckBox
                    id="checkbox"
                    type="checkbox"
                    checked
                    onClick={() => setSelectedPlanType("monthly")}
                  />
                  <CheckBoxLabel htmlFor="checkbox" />
                </>
              )}
            </CheckBoxWrapper>

            <PlanTextYearly
              style={{
                color: selectedPlanType === "yearly" ? "#1585b5" : "#1f062a33",
              }}
            >
              Yearly Plans
            </PlanTextYearly>
          </ToogleSection>
        </HeadingWrapper>

        {!loading ? (
          <>
            {activePlan?.isCustomPlan && (
              <Accordion
                className="acc-item mb-0"
                style={{
                  border: "none",
                }}
              >
                <Accordion.Item
                  key={"1"}
                  eventKey={"1"}
                  style={{
                    display: "block",
                    margin: "10px 0",
                    marginBottom: "0",
                    border: "1px #11B753 solid",
                    backgroundColor: "rgba(17, 183, 83, 0.05)",
                    borderRadius: "0",
                  }}
                >
                  <Accordion.Header>
                    <Col xs={2}>
                      <PlanAmount>-</PlanAmount>
                    </Col>
                    <Col xs={8}>
                      <PlanName>{activePlan?.name}</PlanName>
                    </Col>
                  </Accordion.Header>
                  <Accordion.Body>
                    <PlanDetails>
                      <HeadingWrapper>
                        <HeadingPlans>
                          {activePlan?.status === "trialing" ? (
                            <TrialHeading>
                              {" "}
                              {activePlan?.expireText}
                            </TrialHeading>
                          ) : activePlan?.status === "active" &&
                            (activePlan?.daysRemaining! <= 7 ||
                              activePlan?.isCancelAtEnd ||
                              activePlan?.meta.isFree) ? (
                            <ActivePlanHeading>
                              {activePlan?.expireText}
                            </ActivePlanHeading>
                          ) : (
                            activePlan?.planDescription
                          )}
                        </HeadingPlans>
                      </HeadingWrapper>
                      <h6>custom plan Includes:</h6>
                      <CurrentPlanProperties>
                        <PlansContainer>
                          <CurrentPlanName>
                            {!activePlan?.isCustomPlan && <TickImage />}
                            {activePlan?.isCustomPlan ? (
                              <></>
                            ) : activePlan?.subscriptionId === "test" ? (
                              "$0"
                            ) : (
                              <>
                                {"$" + activePlan?.price}
                                <span
                                  style={{
                                    fontSize: "22px",
                                    marginLeft: "-5px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {" "}
                                  {"/" + activePlan?.interval}
                                </span>
                              </>
                            )}
                          </CurrentPlanName>
                        </PlansContainer>
                        <PlansContainer>
                          <PlanDesc>
                            <b>
                              <img
                                alt="tabTick115"
                                src={Tick}
                                width="15"
                                style={{ marginRight: "10px" }}
                              />
                            </b>{" "}
                            {activePlan?.meta.noOfBooks === -1
                              ? "Unlimited"
                              : activePlan?.planStatics.totalBooksCount +
                                " / " +
                                activePlan?.meta.noOfBooks}{" "}
                            {activePlan &&
                            activePlan.planStatics &&
                            activePlan.planStatics.totalBooksCount &&
                            activePlan.planStatics.totalBooksCount > 1
                              ? "chatbots used"
                              : "chatbot used"}
                          </PlanDesc>
                          <PlanDesc>
                            <b>
                              <img
                                alt="tabTick116"
                                src={Tick}
                                width="15"
                                style={{ marginRight: "10px" }}
                              />
                            </b>{" "}
                            {activePlan?.noOfBook === -1
                              ? "Unlimited"
                              : numberWithComma(
                                  Number(activePlan?.planStatics.usedMessage)
                                ) +
                                " / " +
                                numberWithComma(
                                  Number(activePlan?.meta.messageCount)
                                )}{" "}
                            messages used
                          </PlanDesc>
                          {activePlan?.meta.deleteChat && (
                            <PlanDesc>
                              <b>
                                <img
                                  alt="tabTick117"
                                  src={Tick}
                                  width="15"
                                  style={{ marginRight: "10px" }}
                                />
                              </b>{" "}
                              Delete chatbot
                            </PlanDesc>
                          )}
                          {activePlan?.meta.flashCard && (
                            <PlanDesc>
                              <b>
                                <img
                                  alt="tabTick118"
                                  src={Tick}
                                  width="15"
                                  style={{ marginRight: "10px" }}
                                />
                              </b>{" "}
                              Flashcards
                            </PlanDesc>
                          )}

                          {activePlan.meta &&
                            activePlan.meta.removeDropChatBranding && (
                              <PlanDesc>
                                {planHighliter &&
                                planHighliter.isRemoveDropChatBrandingHighliter ? (
                                  <>
                                    <img
                                      alt="tabTick135"
                                      src={TickOrange}
                                      width="15"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    <HighlitedText>
                                      Remove Dropchat branding
                                    </HighlitedText>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      alt="tabTick136"
                                      src={Tick}
                                      width="15"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    Remove Dropchat branding
                                  </>
                                )}
                              </PlanDesc>
                            )}

                          {activePlan.meta && activePlan.meta.deleteAccount && (
                            <PlanDesc>
                              {planHighliter &&
                              planHighliter.isDeleteAccount ? (
                                <>
                                  <img
                                    alt="tabTick135"
                                    src={TickOrange}
                                    width="15"
                                    style={{ marginRight: "10px" }}
                                  />
                                  <HighlitedText>Delete Account</HighlitedText>
                                </>
                              ) : (
                                <>
                                  <img
                                    alt="tabTick136"
                                    src={Tick}
                                    width="15"
                                    style={{ marginRight: "10px" }}
                                  />
                                  Delete Account
                                </>
                              )}
                            </PlanDesc>
                          )}

                          {activePlan.meta &&
                            activePlan.meta.leadLoginSettings && (
                              <PlanDesc>
                                {planHighliter &&
                                planHighliter.isLeadLoginSettings ? (
                                  <>
                                    <img
                                      alt="tabTick135"
                                      src={TickOrange}
                                      width="15"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    <HighlitedText>
                                      Lead Login Settings
                                    </HighlitedText>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      alt="tabTick136"
                                      src={Tick}
                                      width="15"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    Lead Login Settings
                                  </>
                                )}
                              </PlanDesc>
                            )}
                          {activePlan.meta && activePlan.meta.gpt35Turbo16k && (
                            <PlanDesc>
                              {planHighliter &&
                              planHighliter.isGpt35Turbo16k ? (
                                <>
                                  <img
                                    alt="tabTick135"
                                    src={TickOrange}
                                    width="15"
                                    style={{ marginRight: "10px" }}
                                  />
                                  <HighlitedText>GPT-4o mini</HighlitedText>
                                </>
                              ) : (
                                <>
                                  <img
                                    alt="tabTick136"
                                    src={Tick}
                                    width="15"
                                    style={{ marginRight: "10px" }}
                                  />
                                  GPT-4o mini
                                </>
                              )}
                            </PlanDesc>
                          )}
                          {activePlan.meta &&
                            activePlan.meta.gpt4TurboPreview && (
                              <PlanDesc>
                                {planHighliter &&
                                planHighliter.isGpt4TurboPreview ? (
                                  <>
                                    <img
                                      alt="tabTick135"
                                      src={TickOrange}
                                      width="15"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    <HighlitedText>gpt-4o</HighlitedText>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      alt="tabTick136"
                                      src={Tick}
                                      width="15"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    gpt-4o
                                  </>
                                )}
                              </PlanDesc>
                            )}
                          {activePlan.meta && activePlan.meta.allowYTIndex && (
                            <PlanDesc>
                              {planHighliter && planHighliter.isAllowYTIndex ? (
                                <>
                                  <img
                                    alt="tabTick135"
                                    src={TickOrange}
                                    width="15"
                                    style={{ marginRight: "10px" }}
                                  />
                                  <HighlitedText>YouTube Chatbot</HighlitedText>
                                </>
                              ) : (
                                <>
                                  <img
                                    alt="tabTick136"
                                    src={Tick}
                                    width="15"
                                    style={{ marginRight: "10px" }}
                                  />
                                  YouTube Chatbot
                                </>
                              )}
                            </PlanDesc>
                          )}
                          {activePlan.meta && activePlan.meta.allowPDFIndex && (
                            <PlanDesc>
                              {planHighliter &&
                              planHighliter.isAllowPDFIndex ? (
                                <>
                                  <img
                                    alt="tabTick135"
                                    src={TickOrange}
                                    width="15"
                                    style={{ marginRight: "10px" }}
                                  />
                                  <HighlitedText>PDF Chatbot</HighlitedText>
                                </>
                              ) : (
                                <>
                                  <img
                                    alt="tabTick136"
                                    src={Tick}
                                    width="15"
                                    style={{ marginRight: "10px" }}
                                  />
                                  PDF Chatbot
                                </>
                              )}
                            </PlanDesc>
                          )}
                          {activePlan.meta &&
                            activePlan.meta.allowWebsiteIndex && (
                              <PlanDesc>
                                {planHighliter &&
                                planHighliter.isAllowWebsiteIndex ? (
                                  <>
                                    <img
                                      alt="tabTick135"
                                      src={TickOrange}
                                      width="15"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    <HighlitedText>
                                      Website Chatbot
                                    </HighlitedText>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      alt="tabTick136"
                                      src={Tick}
                                      width="15"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    Website Chatbot
                                  </>
                                )}
                              </PlanDesc>
                            )}
                          {activePlan.meta &&
                            activePlan.meta.customOpenAPIKey && (
                              <PlanDesc>
                                {planHighliter &&
                                planHighliter.isCustomOpenAPIKey ? (
                                  <>
                                    <img
                                      alt="tabTick135"
                                      src={TickOrange}
                                      width="15"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    <HighlitedText>
                                      Use Own OpenAPI Key
                                    </HighlitedText>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      alt="tabTick136"
                                      src={Tick}
                                      width="15"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    Use Own OpenAPI Key
                                  </>
                                )}
                              </PlanDesc>
                            )}
                          {activePlan.meta &&
                            activePlan.meta.integrationApps && (
                              <PlanDesc>
                                {planHighliter &&
                                planHighliter.isIntegrationApps ? (
                                  <>
                                    <img
                                      alt="tabTick135"
                                      src={TickOrange}
                                      width="15"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    <HighlitedText>
                                      Integration Apps
                                    </HighlitedText>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      alt="tabTick136"
                                      src={Tick}
                                      width="15"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    Integration Apps
                                  </>
                                )}
                              </PlanDesc>
                            )}
                          {activePlan.meta &&
                            activePlan.meta.APIConfiguration && (
                              <PlanDesc>
                                <>
                                  <img
                                    alt="tabTick136"
                                    src={Tick}
                                    width="15"
                                    style={{
                                      marginRight: "10px",
                                    }}
                                  />
                                  Live API Configuration
                                </>
                              </PlanDesc>
                            )}
                        </PlansContainer>
                        <PlansContainer>
                          <PlanDesc>Purchaser: {user && user.email}</PlanDesc>
                          {activePlan?.meta.isFree ||
                          activePlan?.isCancelAtEnd ? null : (
                            <>
                              {activePlan?.nextBillingDate && (
                                <PlanDesc>
                                  Next Billing Date:{" "}
                                  {moment
                                    .unix(activePlan.nextBillingDate)
                                    .format("Do MMM, YYYY")}
                                </PlanDesc>
                              )}
                            </>
                          )}
                        </PlansContainer>
                        <PlansContainer>
                          {activePlan?.isCancelable ? (
                            activePlan?.isCancelAtEnd ||
                            downgradeRequestData?.downgradeRequested ? null : (
                              <PurchaseButton
                                style={{ width: "100%" }}
                                onClick={() => cancelPlan()}
                              >
                                Cancel Plan
                              </PurchaseButton>
                            )
                          ) : null}
                        </PlansContainer>
                      </CurrentPlanProperties>
                    </PlanDetails>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}

            {/* ACCORDION -PLANS */}
            <Accordion
              className="acc-item"
              style={{
                border: "none",
                marginTop: "0",
              }}
            >
              {plansList &&
                plansList.length > 0 &&
                plansList
                  .filter(
                    (plan: SubscriptionPlan) =>
                      plan.interval === selectedPlanType && !plan.meta.isSpecial
                  )
                  .map((plan: SubscriptionPlan, id) => {
                    const isActive = plan.id === activePlan?.priceId;
                    return (
                      <Accordion.Item
                        key={id}
                        eventKey={id.toString()}
                        style={{
                          display: "block",
                          margin: "10px 0",
                          borderRadius: "0",
                          border: isActive
                            ? "1px #11B753 solid"
                            : "1px solid rgba(234, 234, 234, 1)",
                          backgroundColor: isActive
                            ? "rgba(17, 183, 83, 0.05)"
                            : "white",
                        }}
                      >
                        <Accordion.Header>
                          <Col xs={2}>
                            <PlanAmount>{`$${plan.price}`}</PlanAmount>
                          </Col>
                          <Col xs={8}>
                            <PlanName>{plan.name}</PlanName>
                          </Col>
                        </Accordion.Header>
                        <Accordion.Body>
                          <PricingCol key={plan.id} sm={3} xs={12}>
                            <PricingDiv
                              isHighlight={
                                (planHighliter &&
                                  ((planHighliter.isBookHighliter &&
                                    Number(plan.meta.noOfBooks) >
                                      planHighliter.bookLimit) ||
                                    (planHighliter.isDeleteBookHighliter &&
                                      plan.meta.deleteChat) ||
                                    (planHighliter.isRemoveDropChatBrandingHighliter &&
                                      plan.meta.removeDropChatBranding) ||
                                    (planHighliter.isFlashcardHighliter &&
                                      plan.meta.flashCard))) ||
                                (planHighliter?.isCharacterLimit &&
                                  Number(plan.meta.messageCount) >
                                    Number(
                                      user?.currentSubscription?.meta
                                        .messageCount
                                    )) ||
                                (planHighliter?.ispfdLimitHighliter &&
                                  Number(plan.meta.pdfPageSupport) >
                                    planHighliter.pdfLimit) ||
                                (planHighliter?.ispfdLimitHighliter &&
                                  plan.meta.pdfPageSupport === -1) ||
                                (planHighliter?.isDeleteAccount &&
                                  plan.meta.deleteAccount)
                              }
                            >
                              <DescriptionBox>
                                <DescriptionText>
                                  {/* <h6>
                                    {plan.name === "Free Plan Monthly"
                                      ? "Free Plan Monthly Includes:"
                                      : plan.name === "Starter Plan Monthly"
                                      ? "Starter Plan Monthly Includes:"
                                      : plan.name ===
                                        "Professional Plan Monthly"
                                      ? "Professional Plan Monthly Includes:"
                                      : "Starter Yearly Plan Includes:"}
                                  </h6> */}
                                  <h6>{plan.name + " Includes:"}</h6>
                                  <br />

                                  {usedFlashcard && isActive ? (
                                    // actived plan flashacard
                                    <p>
                                      <b>
                                        <img
                                          alt="tabTick115"
                                          src={Tick}
                                          width="15"
                                          style={{ marginRight: "10px" }}
                                        />
                                      </b>{" "}
                                      {activePlan?.meta.noOfBooks === -1
                                        ? "Unlimited"
                                        : activePlan?.planStatics
                                            .totalBooksCount +
                                          " / " +
                                          activePlan?.meta.noOfBooks}{" "}
                                      {activePlan &&
                                      activePlan.planStatics &&
                                      activePlan.planStatics.totalBooksCount &&
                                      activePlan.planStatics.totalBooksCount > 1
                                        ? "chatbots used"
                                        : "chatbot used"}
                                    </p>
                                  ) : (
                                    // {/* -------------all plan content ------------ */}
                                    <p>
                                      {planHighliter &&
                                      planHighliter.isBookHighliter &&
                                      plan.meta &&
                                      plan.meta.noOfBooks &&
                                      Number(plan.meta.noOfBooks) >
                                        planHighliter.bookLimit ? (
                                        <>
                                          <img
                                            alt="tabTick119"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            {plan.meta.noOfBooks!}{" "}
                                            {plan.meta.noOfBooks > 1
                                              ? "chatbots"
                                              : "chatbot"}
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        plan.meta.noOfBooks && (
                                          <>
                                            <img
                                              alt="tabTick120"
                                              src={Tick}
                                              width="15"
                                              style={{
                                                marginRight: "10px",
                                              }}
                                            />
                                            {plan.meta.noOfBooks!}{" "}
                                            {plan.meta.noOfBooks > 1
                                              ? "chatbots"
                                              : "chatbot"}
                                          </>
                                        )
                                      )}
                                    </p>
                                  )}
                                  {/* <p>
                                    {planHighliter &&
                                    planHighliter.isBookHighliter &&
                                    plan.meta &&
                                    plan.meta.noOfBooks &&
                                    Number(plan.meta.noOfBooks) >
                                      planHighliter.bookLimit ? (
                                      <>
                                        <img
                                          alt="tabTick119"
                                          src={TickOrange}
                                          width="15"
                                          style={{ marginRight: "10px" }}
                                        />
                                        <HighlitedText>
                                          {plan.meta.noOfBooks!}{" "}
                                          {plan.meta.noOfBooks > 1
                                            ? "chatbots"
                                            : "chatbot"}
                                        </HighlitedText>
                                      </>
                                    ) : (
                                      plan.meta.noOfBooks && (
                                        <>
                                          <img
                                            alt="tabTick120"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          {plan.meta.noOfBooks!}{" "}
                                          {plan.meta.noOfBooks > 1
                                            ? "chatbots"
                                            : "chatbot"}
                                        </>
                                      )
                                    )}
                                  </p> */}

                                  {/* -------------active plan content ------------ */}

                                  {isActive && totalCharacter ? (
                                    <p>
                                      <b>
                                        <img
                                          alt="tabTick116"
                                          src={Tick}
                                          width="15"
                                          style={{ marginRight: "10px" }}
                                        />
                                      </b>{" "}
                                      {activePlan?.noOfBook === -1
                                        ? "Unlimited"
                                        : numberWithComma(
                                            Number(
                                              activePlan?.planStatics
                                                .usedMessage
                                            )
                                          ) +
                                          " / " +
                                          numberWithComma(
                                            Number(
                                              activePlan?.meta.messageCount
                                            )
                                          )}{" "}
                                      messages used
                                    </p>
                                  ) : // {/* -------------all plan content ------------ */}
                                  (plan! && plan.meta.messageCount
                                      ? plan.meta.messageCount
                                      : 0) >
                                    (user?.currentSubscription?.meta
                                      .messageCount
                                      ? user?.currentSubscription?.meta
                                          .messageCount
                                      : 0) ? (
                                    <>
                                      {planHighliter &&
                                      planHighliter.isCharacterLimit ? (
                                        <p>
                                          <img
                                            alt="tabTick121"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            {numberWithComma(
                                              Number(plan.meta?.messageCount)
                                            )}{" "}
                                            message limit
                                          </HighlitedText>
                                        </p>
                                      ) : (
                                        <p>
                                          <img
                                            alt="tabTick122"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          {numberWithComma(
                                            Number(plan.meta?.messageCount)
                                          )}{" "}
                                          message limit
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <p>
                                      <img
                                        alt="tabTick123"
                                        src={Tick}
                                        width="15"
                                        style={{ marginRight: "10px" }}
                                      />
                                      {numberWithComma(
                                        Number(plan.meta?.messageCount)
                                      )}{" "}
                                      message limit
                                    </p>
                                  )}

                                  {(plan && plan.meta.indexCharacterSupport
                                    ? plan.meta.indexCharacterSupport
                                    : 0) >
                                  (user?.currentSubscription?.meta
                                    .indexCharacterSupport
                                    ? user?.currentSubscription?.meta
                                        .indexCharacterSupport
                                    : 0) ? (
                                    <>
                                      {planHighliter &&
                                      planHighliter.isIndexCharacterLimit ? (
                                        <p>
                                          <img
                                            alt="tabTick121"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            {numberWithComma(
                                              Number(
                                                plan.meta?.indexCharacterSupport
                                              )
                                            )}{" "}
                                            Index characters limit
                                          </HighlitedText>
                                        </p>
                                      ) : (
                                        <p>
                                          <img
                                            alt="tabTick122"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          {numberWithComma(
                                            Number(
                                              plan.meta?.indexCharacterSupport
                                            )
                                          )}{" "}
                                          Index characters limit
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <p>
                                      <img
                                        alt="tabTick123"
                                        src={Tick}
                                        width="15"
                                        style={{ marginRight: "10px" }}
                                      />
                                      {numberWithComma(
                                        Number(plan.meta?.indexCharacterSupport)
                                      )}{" "}
                                      Index characters limit
                                    </p>
                                  )}

                                  {plan && plan.meta.pdfPageSupport === -1 ? (
                                    <>
                                      {planHighliter &&
                                      planHighliter.ispfdLimitHighliter ? (
                                        <p>
                                          <img
                                            alt="tabTick124"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            {plan.meta?.pdfPageSupport === -1
                                              ? "Unlimited"
                                              : numberWithComma(
                                                  Number(
                                                    plan.meta?.pdfPageSupport
                                                  )
                                                )}{" "}
                                            PDF page limit
                                          </HighlitedText>
                                        </p>
                                      ) : (
                                        <p>
                                          <img
                                            alt="tabTick125"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          {plan.meta?.pdfPageSupport === -1
                                            ? "Unlimited"
                                            : numberWithComma(
                                                Number(
                                                  plan.meta?.pdfPageSupport
                                                )
                                              )}{" "}
                                          PDF page limit
                                        </p>
                                      )}
                                    </>
                                  ) : (plan && plan.meta.pdfPageSupport
                                      ? plan.meta.pdfPageSupport
                                      : 0) >
                                    (user?.currentSubscription?.meta
                                      .pdfPageSupport
                                      ? user?.currentSubscription?.meta
                                          .pdfPageSupport
                                      : 0) ? (
                                    <>
                                      {planHighliter &&
                                      planHighliter.ispfdLimitHighliter ? (
                                        <p>
                                          <img
                                            alt="tabTick126"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            {plan.meta?.pdfPageSupport === -1
                                              ? "Unlimited"
                                              : numberWithComma(
                                                  Number(
                                                    plan.meta?.pdfPageSupport
                                                  )
                                                )}{" "}
                                            PDF page limit
                                          </HighlitedText>
                                        </p>
                                      ) : (
                                        <p>
                                          <img
                                            alt="tabTick127"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          {plan.meta?.pdfPageSupport === -1
                                            ? "Unlimited"
                                            : numberWithComma(
                                                Number(
                                                  plan.meta?.pdfPageSupport
                                                )
                                              )}{" "}
                                          PDF page limit
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <p>
                                      <img
                                        alt="tabTick128"
                                        src={Tick}
                                        width="15"
                                        style={{ marginRight: "10px" }}
                                      />
                                      {plan.meta?.pdfPageSupport === -1
                                        ? "Unlimited"
                                        : numberWithComma(
                                            Number(plan.meta?.pdfPageSupport)
                                          )}{" "}
                                      PDF page limit
                                    </p>
                                  )}

                                  {plan.meta && plan.meta.deleteChat && (
                                    <p>
                                      {planHighliter &&
                                      planHighliter.isDeleteBookHighliter ? (
                                        <>
                                          <img
                                            alt="tabTick129"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            Delete chatbot
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick130"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          Delete chatbot
                                        </>
                                      )}
                                    </p>
                                  )}
                                  {plan.meta && plan.meta.flashCard && (
                                    <p>
                                      {planHighliter &&
                                      planHighliter.isFlashcardHighliter ? (
                                        <>
                                          <img
                                            alt="tabTick132"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            Flashcards
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick133"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          Flashcards
                                        </>
                                      )}
                                    </p>
                                  )}

                                  {plan.meta &&
                                    plan.meta.removeDropChatBranding && (
                                      <p>
                                        {planHighliter &&
                                        planHighliter.isRemoveDropChatBrandingHighliter ? (
                                          <>
                                            <img
                                              alt="tabTick135"
                                              src={TickOrange}
                                              width="15"
                                              style={{ marginRight: "10px" }}
                                            />
                                            <HighlitedText>
                                              Remove Dropchat branding
                                            </HighlitedText>
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              alt="tabTick136"
                                              src={Tick}
                                              width="15"
                                              style={{ marginRight: "10px" }}
                                            />
                                            Remove Dropchat branding
                                          </>
                                        )}
                                      </p>
                                    )}
                                  {plan.meta && plan.meta.deleteAccount && (
                                    <p>
                                      {planHighliter &&
                                      planHighliter.isDeleteAccount ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            Delete Account
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          Delete Account
                                        </>
                                      )}
                                    </p>
                                  )}
                                  {plan.meta && plan.meta.leadLoginSettings && (
                                    <p>
                                      {planHighliter &&
                                      planHighliter.isLeadLoginSettings ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            Lead Login Settings
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          Lead Login Settings
                                        </>
                                      )}
                                    </p>
                                  )}
                                  {plan.meta && plan.meta.gpt35Turbo16k && (
                                    <p>
                                      {planHighliter &&
                                      planHighliter.isGpt35Turbo16k ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            GPT-4o mini
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          GPT-4o mini
                                        </>
                                      )}
                                    </p>
                                  )}
                                  {plan.meta && plan.meta.gpt4TurboPreview && (
                                    <p>
                                      {planHighliter &&
                                      planHighliter.isGpt4TurboPreview ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>gpt-4o</HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          gpt-4o
                                        </>
                                      )}
                                    </p>
                                  )}
                                  {plan.meta && plan.meta.allowYTIndex && (
                                    <p>
                                      {planHighliter &&
                                      planHighliter.isAllowYTIndex ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            YouTube Chatbot
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          YouTube Chatbot
                                        </>
                                      )}
                                    </p>
                                  )}
                                  {plan.meta && plan.meta.allowPDFIndex && (
                                    <p>
                                      {planHighliter &&
                                      planHighliter.isAllowPDFIndex ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            PDF Chatbot
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          PDF Chatbot
                                        </>
                                      )}
                                    </p>
                                  )}
                                  {plan.meta && plan.meta.allowWebsiteIndex && (
                                    <p>
                                      {planHighliter &&
                                      planHighliter.isAllowWebsiteIndex ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            Website Chatbot
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          Website Chatbot
                                        </>
                                      )}
                                    </p>
                                  )}
                                  {plan.meta && plan.meta.customOpenAPIKey && (
                                    <p>
                                      {planHighliter &&
                                      planHighliter.isCustomOpenAPIKey ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            Use Own OpenAPI Key
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          Use Own OpenAPI Key
                                        </>
                                      )}
                                    </p>
                                  )}
                                  {plan.meta && plan.meta.integrationApps && (
                                    <p>
                                      {planHighliter &&
                                      planHighliter.isIntegrationApps ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>
                                            Integration Apps
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          Integration Apps
                                        </>
                                      )}
                                    </p>
                                  )}
                                  {plan.meta && plan.meta.APIConfiguration && (
                                    <p>
                                      <>
                                        <img
                                          alt="tabTick136"
                                          src={Tick}
                                          width="15"
                                          style={{
                                            marginRight: "10px",
                                          }}
                                        />
                                        Live API Configuration
                                      </>
                                    </p>
                                  )}
                                </DescriptionText>
                              </DescriptionBox>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {downgradeRequestData?.downgradeRequested ? (
                                  plan.type === "Free" ? null : (
                                    <PurchaseButtonDisable>
                                      {plan.buttonShowName}
                                    </PurchaseButtonDisable>
                                  )
                                ) : (
                                  <>
                                    {!buttonLoading ? (
                                      <>
                                        {plan.disabled ? (
                                          <></>
                                        ) : plan.type !== "Active" ? (
                                          plan.type === "Free" ? (
                                            <PurchaseButtonDisable>
                                              {plan.buttonShowName}
                                            </PurchaseButtonDisable>
                                          ) : (
                                            <PurchaseButton
                                              onClick={() => {
                                                // console.log(
                                                //   "activePlan --- ",
                                                //   activePlan
                                                // );
                                                setButtonLoading(true);
                                                createPurchaseSession(
                                                  plan.type,
                                                  plan.id,
                                                  activePlan?.priceId === "test"
                                                    ? undefined
                                                    : activePlan?.id,
                                                  plan.name
                                                );
                                              }}
                                            >
                                              {plan.buttonShowName}
                                            </PurchaseButton>
                                          )
                                        ) : (
                                          <PurchaseButtonDisable>
                                            {plan.buttonShowName}
                                          </PurchaseButtonDisable>
                                        )}
                                      </>
                                    ) : (
                                      <LoaderContainer>
                                        <Loader />
                                      </LoaderContainer>
                                    )}
                                  </>
                                )}
                              </div>
                              <SimpleContainer></SimpleContainer>
                            </PricingDiv>
                          </PricingCol>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
            </Accordion>

            <ComparisionSection sm={9}>
              <a
                target="_blank"
                href={"https://dropchat.co/pricing"}
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <ComparisionHeading>
                  Compare our full set of features
                </ComparisionHeading>
              </a>
            </ComparisionSection>
          </>
        ) : (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
      </AvailablePlansSection>
    </>
  );
}

export default MobilePlans;
