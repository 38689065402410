import { Field, Form } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { BookContext } from "../../context/BookContext";
import { UserContext } from "../../context/UserContext";
import { LoaderContext } from "../../context/loader-context";
import myAxios from "../../myaxios";
import { Book } from "../../types";
import { matchYoutubeUrl, parseErrorResponse } from "../../utils/utils";
import BookContent from "../book/book";
import ConfirmationBox from "../utility-component/confirmation-box";
import ShowChatSessionBox from "./show-chat-sessions-box";
import UpdateChatbotBox from "./update-chatbot-box";

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;

const BookFetchForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
`;
const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
`;

const SaveBookButton = styled(Button)`
  width: 100%;
`;

const FormLabelDocInput = styled(FormLabel)`
  background: #ccc;
  width: 50%;
  padding: 5px 10px;
`;

const FeatureChatbots = () => {
  const { user } = useContext(UserContext);
  const { setShowLoader } = useContext(LoaderContext);
  const [fileObject, setFileObject] = useState<any>();
  const [coverImageObject, setCoverImageObject] = useState<any>();

  const { adminBooks, setAdminBooks } = useContext(BookContext);
  const [showConfirmationBox, setShowConfirmationBox] =
    useState<boolean>(false);

  const [showUpdateBox, setShowUpdateBox] = useState<boolean>(false);
  const [showSessionBox, setShowSessionBox] = useState<boolean>(false);

  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");
  const [confirmationAction, setConfirmationAction] = useState<string>("");
  const [selectedBook, setSelectedBook] = useState<Book>();
  const getFeatureChatbots = () => {
    setShowLoader(true);
    myAxios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/books/paginated-feature-chatbot`,
        {}
      )
      .then((response) => {
        if (response.data && response.data.data) {
          setAdminBooks(response.data.data.books);
        }
        // setLoading(false);
        setShowLoader(false);
      })
      .catch((error) => {
        // setLoading(false);
        setShowLoader(false);
        // console.error(error, "error in getting app features");
      });
  };

  useEffect(() => {
    getFeatureChatbots();
    // eslint-disable-next-line
  }, []);

  const isValidHttpUrl = (urlString: string) => {
    let url;
    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };

  const validateUrl = (value: string) => {
    // console.log("value is, ", value);
    return /^(?:(?:(?:https?|ftp|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  };

  const validateBookSubmission = (formData: any) => {
    const errors: any = {};

    if (!formData.title) {
      errors.title = "title required!";
    }

    if (formData.selectedRadio === "book") {
      if (!formData.authors || formData.authors.length === 0) {
        errors.authors = "authors required!";
      }
    }

    if (formData.selectedRadio === "youtube") {
      if (!formData.url || formData.url.length === 0) {
        // console.log("url is hadga, ", formData.url);

        errors.url = "YouTube URL required!";
      }
      if (!matchYoutubeUrl(formData.url)) {
        errors.url = "valid YouTube URL required!";
      }
    }

    if (!coverImageObject) {
      errors.coverImage = "please select file!";
    }

    if (formData.selectedRadio === "webUrl") {
      // console.log("url is, ", formData.url);
      if (!formData.url || formData.url.length === 0) {
        errors.url = "Website URL required!";
      } else if (!isValidHttpUrl(formData.url)) {
        errors.url = "valid website URL required!";
      } else if (!validateUrl(formData.url)) {
        errors.url = "valid website URL is required!";
      }
    }

    return errors;
  };

  const createBook = async (values: any, resetForm: any) => {
    // console.log("Create Chatbot URl Values ", values);
    let fileExtension =
      fileObject && fileObject.name ? fileObject.name.split(".")[1] : "";
    let dataToSave = {
      title: values.title,
      image: values.image,
      authors: [values.authors],
      industryIdentifiers: [values.industryIdentifiers],
      userID: user && user.id,
      type:
        values.selectedRadio === "file"
          ? "aws"
          : values.selectedRadio === "webUrl"
          ? "url"
          : values.selectedRadio === "youtube"
          ? "youtube"
          : "book",
      fileType:
        fileObject && fileObject.type
          ? fileObject.type
          : values.selectedRadio === "webUrl"
          ? "url"
          : values.selectedRadio === "youtube"
          ? "youtube"
          : fileExtension,
      url: values.url,
      fileName: fileObject ? fileObject.name : "",
      webUrl: values.webUrl,
      isAdmin: true,
    };

    let createBookInput;

    // if (coverImageObject) {
    //   const data = new FormData();

    //   data.append("files.coverImage", coverImageObject);
    //   data.append("data", JSON.stringify(dataToSave));
    //   createBookInput = data;
    // }

    if (fileObject || coverImageObject) {
      const data = new FormData();

      data.append("files.files", fileObject);
      data.append("data", JSON.stringify(dataToSave));
      if (coverImageObject) data.append("files.coverImage", coverImageObject);
      createBookInput = data;
    } else {
      createBookInput = dataToSave;
    }

    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL + "/books/create-book",
        createBookInput
      )
      .then((response) => {
        setAdminBooks(response.data.data);
        resetForm();
        setFileObject(undefined);
        setCoverImageObject(undefined);
        NotificationManager.success(
          response.data.message
            ? response.data.message
            : "Chatbot successfully created!"
        );
        setShowLoader(false);
      })
      .catch((error) => {
        // handle error
        resetForm();
        setShowLoader(false);
        setFileObject(undefined);
        setCoverImageObject(undefined);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const activateDeactivateBook = (book: Book) => {
    setShowLoader(true);
    myAxios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/admin-settings/activate-deactivate-book/${book._id}`,
        { activate: !book.activate }
      )
      .then((response) => {
        if (response.data && response.data.success) {
          // Update cueent book

          let oldBooks = adminBooks;

          const index = oldBooks
            ? oldBooks.findIndex((oldBook: Book) => book._id === oldBook._id)
            : -1;

          // console.log("index ", index);

          if (index > -1 && oldBooks) {
            oldBooks[index].activate = !book.activate;
          }

          setAdminBooks(oldBooks ? [...oldBooks] : []);

          NotificationManager.success("Chatbot status changed successfully");
        } else {
          NotificationManager.error(
            response.data && response.data.message
              ? response.data.message
              : "Not able to change status, Please try again!"
          );
        }
        // setLoading(false);
        // getListOfPublicBooks();
        resetConfirmation();
        setShowLoader(false);
      })
      .catch((error) => {
        // setLoading(false);
        // console.error(
        //   "Gettings error at the time of updating status of chatbot ",
        //   error
        // );
        NotificationManager.error(parseErrorResponse(error));
        resetConfirmation();
        setShowLoader(false);
      });
  };

  const deleteBook = (book: Book) => {
    setShowLoader(true);
    myAxios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/admin-settings/delete-public-book/${book._id}`
      )
      .then((response) => {
        if (response.data && response.data.success) {
          // Update cueent book

          let oldBooks = adminBooks;

          const index = oldBooks
            ? oldBooks.findIndex((oldBook: Book) => book._id === oldBook._id)
            : -1;

          // console.log("index ", index);

          if (index > -1 && oldBooks) {
            oldBooks.splice(index, 1);
          }

          setAdminBooks(oldBooks ? [...oldBooks] : []);

          NotificationManager.success("Chatbot deleted successfully");
        } else {
          NotificationManager.error(
            response.data && response.data.message
              ? response.data.message
              : "Not able to delete chatbot, Please try again!"
          );
        }
        resetConfirmation();
        setShowLoader(false);
      })
      .catch((error) => {
        // setLoading(false);
        // console.error(
        //   "Gettings error at the time of updating status of chatbot ",
        //   error
        // );

        NotificationManager.error(parseErrorResponse(error));
        resetConfirmation();
        setShowLoader(false);
      });
  };

  const resetConfirmation = () => {
    setShowConfirmationBox(false);
    setConfirmationBoxMessage("");
    setSelectedBook(undefined);
    setConfirmationAction("");
  };

  const confirmClick = (action: string) => {
    if (action === "delete") {
      selectedBook && deleteBook(selectedBook);
    } else if (action === "status") {
      selectedBook && activateDeactivateBook(selectedBook);
    } else if (action === "ignore") {
      setShowConfirmationBox(false);
    }
  };

  const onBookStatusClick = (book: Book) => {
    // console.log("User Clicked on chatbot ", book);

    if (book.indexStatus !== 2) {
      setConfirmationBoxMessage(
        `Indexing is going on so please wait for any action on this chatbot!`
      );
      setConfirmationAction("ignore");
      setShowConfirmationBox(true);
    } else {
      setConfirmationBoxMessage(
        `Do you really want to ${
          book.activate ? "Deactivate" : "Activate"
        } this chatbot?`
      );
      setSelectedBook(book);
      setConfirmationAction("status");
      setShowConfirmationBox(true);
    }

    /*  const confirmBox = window.confirm(
      `Do you really want to ${
        book.activate ? "Deactivate" : "Activate"
      } this book?`
    );
    if (confirmBox === true) {
      activateDeactivateBook(book);
    } */
  };

  const onShowSessionClicked = (book: Book) => {
    setSelectedBook(book);
    setShowSessionBox(true);
  };

  const onDeleteBookClick = (book: Book) => {
    setConfirmationBoxMessage("Do you really want to delete this chatbot?");
    setSelectedBook(book);
    setConfirmationAction("delete");
    setShowConfirmationBox(true);
  };

  const onUpdateClick = (book: Book) => {
    setSelectedBook(book);

    setShowUpdateBox(true);
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <Row style={{ width: "100%" }}>
            {adminBooks &&
              adminBooks.length > 0 &&
              adminBooks.map((book: Book, idx: any) => {
                return (
                  <Col sm={2} key={book._id} style={{ height: "max-content" }}>
                    <BookContent
                      book={book}
                      idx={idx}
                      onBookClick={onBookStatusClick}
                      onDeleteBookClick={onDeleteBookClick}
                      onShowSessionClicked={onShowSessionClicked}
                      onUpdateClick={onUpdateClick}
                    />
                  </Col>
                );
              })}
          </Row>
        </Col>
      </Row>
      {showConfirmationBox && (
        <ConfirmationBox
          setShow={setShowConfirmationBox}
          name="Confirmation"
          message={confirmationBoxMessage}
          okButtonText="Yes"
          action={confirmationAction}
          okButtonClick={confirmClick}
        />
      )}

      {showUpdateBox && (
        <UpdateChatbotBox
          setShow={setShowUpdateBox}
          book={selectedBook}
          getFeatureChatbots={getFeatureChatbots}
        />
      )}

      {showSessionBox && (
        <ShowChatSessionBox setShow={setShowSessionBox} book={selectedBook} />
      )}
    </>
  );
};

export default FeatureChatbots;
