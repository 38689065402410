import { createContext, ReactElement, useState } from "react";
import { NotesCategory, NotesProps, NotesValues } from "../types";

export const NotesContext = createContext({} as NotesValues);

const NotesContextProvider = (props: NotesProps): ReactElement<NotesValues> => {
  const [notesCategories, setNotesCategories] = useState<NotesCategory[]>([]);
  const [showFlashcardsOptionPopup, setShowFlashcardsOptionPopup] =
    useState<boolean>(false);
  const addNotesCategory = (notesCategory: NotesCategory) => {
    // console.log("addNotesCategory called for ", notesCategory);
    setNotesCategories(
      notesCategories ? [...notesCategories, notesCategory] : [notesCategory]
    );
  };

  return (
    <NotesContext.Provider
      value={{
        notesCategories,
        setNotesCategories,
        addNotesCategory,
        showFlashcardsOptionPopup,
        setShowFlashcardsOptionPopup,
      }}
    >
      {props.children}
    </NotesContext.Provider>
  );
};

export default NotesContextProvider;
