import axios from "axios";
import { Field, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MOBILE } from "../constants/mobile";
import { UserContext } from "../context/UserContext";
import {
  EventCategory,
  SubCategory,
  UserEvents,
} from "../event-utils/dropchat-event-enums";
import { trackEvent } from "../event-utils/track-event";
import HidePassEye from "../images/hidepasseye.svg";
import Spinner from "../images/loader.gif";
import OrLInes from "../images/oricon.svg";
import ShowPassEye from "../images/showpasseye.svg";
import { setAuthHeader } from "../myaxios";
import { isTestUser, mixPanel } from "../utils/utils";
import GoogleLoginButton from "../utils/google-login-button";
// import GitHubLoginButton from "../utils/github-login-button";

const DivForLoginWithCredintial = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 3%;
`;
const ButtonForCred = styled.button`
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #1585b5;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  color: #1585b5;
  cursor: pointer;
  z-index: 11111;
`;
const BottomDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const SpanAccount = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1585b5;
`;
const SpanSignup = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1585b5;
  margin-left: 7px;
  cursor: pointer;
`;

const DIV = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DivForPara = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LoginPara = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #1585b5;
`;

const LoginSignupFieldsDiv = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* align-items: center; */
`;
const Input = styled(Field)`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(51, 48, 60, 0.6);
  border-radius: 4px;
  padding: 0.7rem;
  margin-bottom: 0.5rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    border-radius: 4px;
  }
`;
const DivForLoginButton = styled.div`
  padding: 10px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  flex-direction: column;
`;
const ShowPasswordImgTag = styled.img`
  position: absolute;
  top: 30%;
  right: 2%;
  z-index: 1;
`;
const LoginButton = styled.button`
  width: 100%;
  height: 50px;
  color: white;
  background: #1585b5;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  &:hover {
    background: #094159bd;
    border: none;
  }
`;
// const SpanErrorLoginSignup = styled.span`
//   font-style: normal;
//   font-weight: 500;
//   font-size: 11px;
//   line-height: 12px;
//   color: #df5c04;
//   width: 100%;
//   display: flex;
// `;
const SpanErrorForPassword = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: #df5c04;
  display: flex;
`;

const SpinnerLoginImg = styled.img`
  height: 35px;
  margin-left: 15px;
`;

const DivForPassShowHide = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`;

const SpanForgetPass = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #1585b5;
  display: flex;
  justify-content: end;
  cursor: pointer;
`;
// const ForgetPassRow = styled(Row)`
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
// `;

const DivForOrLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OrlinesImgTag = styled.img`
  width: 64%;
`;
const Label = styled.label`
  font-weight: 300;
  font-size: 14px;
  color: #33303c;
  margin-bottom: 2px;
`;

const LoginForm = () => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [toShowPasswordLogin, setToShowPasswordLogin] =
    useState<boolean>(false);

  const [loginLoader, setLoginLoader] = useState(false);
  const toShowPasswordInPlainText = () => {
    var x: any = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const login = (values: any, resetForm: any) => {
    setLoginLoader(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/local`, {
        identifier: values.email,
        password: values.password,
      })
      .then((response: any) => {
        if (response.status === 200) {
          resetForm();
          if (response.data && response.data.jwt) {
            // if (process.env.REACT_APP_ENV === "prod") {
            // mixpanel.identify(response.data.email);
            // mixpanel.track("Logged In", {
            //   "Login Type": "Login Form",
            // });
            trackEvent({
              email: values.email,
              eventCategory: EventCategory.USER,
              event: UserEvents.USER_LOGIN_USING_FORM,
              subCategory: SubCategory.EVENT,
              rawData: response.data,
            });
            if (!isTestUser(response.data.email))
              mixPanel(response.data.email, "Logged In", "Login Form");

            // }
            NotificationManager.success(" successfully login!");
            setUser({ ...response.data });
            setLoginLoader(false);
            localStorage.setItem("user", JSON.stringify(response.data));
            localStorage.setItem("verifyCredentialRequired", "yes");

            if (response.data.jwt) setAuthHeader(response.data.jwt);

            const instance = axios.create({
              baseURL: process.env.REACT_APP_SERVER_URL,
            });
            instance.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.jwt;

            instance
              .get(process.env.REACT_APP_SERVER_URL + "/user-profile")
              .then((response) => {
                if (response.data) {
                  localStorage.setItem("user", JSON.stringify(response.data));
                  setUser(response.data);
                }
              })
              .catch((error) => {});

            navigate("/callback");
          } else {
            trackEvent({
              email: values.email,
              eventCategory: EventCategory.USER,
              event: UserEvents.USER_LOGIN_FAILED_USING_FORM,
              subCategory: SubCategory.EVENT,
              rawData: response.data,
            });
            setLoginLoader(false);
            NotificationManager.error(
              response.data.message
                ? response.data.message
                : "Some thing went wrong, Please try again!"
            );
          }
        } else {
          setLoginLoader(false);
          NotificationManager.error("Some thing went wrong, Please try again!");
        }
      })
      .catch((error: any) => {
        // console.error("error", error);
        setLoginLoader(false);
        NotificationManager.error("Some thing went wrong, Please try again!");
      });
  };

  useEffect(() => {
    // Added hubStaff script conditionly
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = `//js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_ID}.js`;
    // document.head.appendChild(script);
    var s: any = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(script, s);
    return () => {
      // Cleanup: remove the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <Container>
        <Row>
          <DIV>
            <DivForPara>
              <LoginPara>Login</LoginPara>
            </DivForPara>
          </DIV>

          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validate={(values: any) => {
              const errors: {
                email?: string;
                password?: string;
              } = {};

              if (!values.email) {
                errors.email = " email required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.password) {
                errors.password = "password required";
              } else if (values.password.length <= 5) {
                errors.password = "minimum 6 characters required";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // console.log("values, ", values);
              trackEvent({
                email: values.email,
                eventCategory: EventCategory.USER,
                event: UserEvents.USER_TRY_TO_LOGIN_USING_FORM,
                subCategory: SubCategory.EVENT,
                rawData: values,
              });
              login(values, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className="login-form">
                <LoginSignupFieldsDiv>
                  <Label>Email Address</Label>
                  <Input
                    type="email"
                    // placeholder="Enter your email"
                    name="email"
                    onChange={handleChange}
                  />

                  {errors.password && (
                    <SpanErrorForPassword>
                      {errors.email && touched.email && errors.email}
                    </SpanErrorForPassword>
                  )}

                  <Label>Password</Label>
                  <DivForPassShowHide>
                    <ShowPasswordImgTag
                      src={toShowPasswordLogin ? ShowPassEye : HidePassEye}
                      onClick={() => {
                        toShowPasswordInPlainText();
                        setToShowPasswordLogin(!toShowPasswordLogin);
                      }}
                    />
                    <Input
                      type="password"
                      id="myInput"
                      // placeholder="Enter your password"
                      name="password"
                      onChange={handleChange}
                    />
                  </DivForPassShowHide>

                  {errors.password && (
                    <SpanErrorForPassword>
                      {errors.password && touched.password && errors.password}
                    </SpanErrorForPassword>
                  )}

                  <SpanForgetPass
                    onClick={() => {
                      navigate("/forgot-password");
                    }}
                  >
                    Forgot Password?
                  </SpanForgetPass>

                  <DivForLoginButton>
                    <LoginButton>
                      LOGIN
                      {loginLoader && (
                        <SpinnerLoginImg src={Spinner} alt="Loading" />
                      )}
                    </LoginButton>
                    <GoogleLoginButton />
                    {/* <GitHubLoginButton /> */}
                  </DivForLoginButton>
                </LoginSignupFieldsDiv>
              </form>
            )}
          </Formik>
          <DivForOrLine>
            <OrlinesImgTag src={OrLInes} alt="or" />
          </DivForOrLine>

          <DivForLoginWithCredintial>
            <ButtonForCred
              onClick={() => {
                navigate("/magic-login");
              }}
            >
              Login With Magic Link
            </ButtonForCred>
          </DivForLoginWithCredintial>

          <BottomDiv>
            <SpanAccount>Don't have an account |</SpanAccount>
            <SpanSignup
              onClick={() => {
                navigate("/signup");
              }}
            >
              SIGNUP
            </SpanSignup>
          </BottomDiv>
        </Row>
      </Container>
    </>
  );
};

export default LoginForm;
