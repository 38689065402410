import { loadStripe } from "@stripe/stripe-js";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled, { keyframes } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { UserContext } from "../../context/UserContext";
import { PlanContext } from "../../context/plan-context";
import {
  EventCategory,
  PlanEvents,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import Tick from "../../images/green_circle-check-fill.svg";
import TickOrange from "../../images/tick-orange.svg";
import { Mixpanel } from "../../mixpanel";
import myAxios from "../../myaxios";
import { SubscriptionPlan } from "../../types";
import {
  isTestUser,
  numberWithComma,
  parseErrorResponse,
} from "../../utils/utils";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Keyboard, Navigation, Scrollbar } from "swiper/modules";

declare var window: any;

interface HighLight {
  isHighlight: boolean | undefined;
}
const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
`;

const Heading = styled.h6`
  font-weight: bold;
  flex-grow: 10;
`;

const HeadingCenter = styled.h1`
  text-align: center;
  color: #ff0000;
`;

const HeadingPlans = styled.h4`
  font-weight: bold;
  display: flex;
  align-items: center;
  border: 1px solid rgba(234, 84, 85, 1);
  border-radius: 4px;
  background-color: rgba(254, 214, 214, 1);
`;

const ToogleSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 2;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #1585b5;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
  align-items: center;
`;

const PlanText = styled.span<{ isSelected: boolean }>`
  color: ${(props) => (props.isSelected ? "#1585b5" : "#1f062a33")};
  font-weight: bold;
`;

const PricingSection = styled.div`
  display: flex;
  margin-top: 1%;
  justify-content: center;
`;

const PricingCol = styled(Col)`
  padding: 6px;
`;

const PricingDiv = styled.div`
  background: #f9feff;
  width: 100%;

  border: ${(props: HighLight) =>
    props.isHighlight ? "2px solid #11b753" : "1.5px solid #d7d9e3"};

  box-shadow: ${(props: HighLight) =>
    props.isHighlight ? " 0 0 5px rgba(17, 183, 83, 1);" : ""};

  border-radius: 0px;
  flex-direction: column;
  height: 100vh;
`;
const PricingDivSe = styled.div`
  background: #f9feff;
  width: 100%;
  border: 1.5px solid #d7d9e3;
  box-shadow: "";
  border-radius: 0px;
  flex-direction: column;
  height: 100vh;
`;

const PlanName = styled.h6`
  text-align: center;
  font-weight: 600;
`;
const PlanAmount = styled.h3`
  font-size: 36px;
  font-weight: 600;
  display: flex;
  justify-content: center;
`;
const PurchaseButton = styled.button`
  background: #1585b5;
  padding: 5px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 60%;
`;

const PurchaseButtonDisable = styled.button`
  background: #1585b5;
  padding: 5px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  opacity: 0.5;
  cursor: not-allowed !important;
  width: 60%;
`;

const PlanDetails = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const CurrentPlanProperties = styled.div`
  display: flex;
  flex-direction: column;
`;

const CurrentPlanName = styled.h3`
  font-weight: 600;
  margin-right: 50px;
  color: rgba(17, 183, 83, 1);
  font-size: 20px;
  line-height: 24px;
`;

const PlanDesc = styled.p`
  color: rgba(31, 6, 42, 0.5);
  font-size: 14px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 34px;
  word-wrap: break-word;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
`;

const TickImage = styled.img.attrs({
  src: Tick,
})`
  width: 35px;
  margin-right: 15px;
  object-fit: contain;
`;

const CurrentPlanSection = styled.div`
  padding: 20px;
  margin-bottom: 10px;
  border: 1.5px solid rgba(220, 220, 220, 0.3);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  flex-direction: column;
  background-color: #fff;
  margin: 10px 20px;
`;

const AvailablePlansSection = styled.div`
  margin-top: 20px;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  margin: 5px 20px;
  padding: 20px;
  border-radius: 1px;
`;

const PlansContainer = styled.div`
  /* margin: 1rem 3rem 1rem 1rem; */
  flex-direction: column;
`;

const DescriptionBox = styled.div`
  height: 100%;
  margin-top: 20px;
  margin-bottom: 18px;
  overflow-y: scroll;
`;

const DescriptionText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #212529;
  flex-direction: column;
  flex: 1;
`;

const TrialHeading = styled.span`
  font-size: 16px;
  color: #cd0606;
  padding: 5px 12px !important;
  background-color: rgba(205, 6, 6, 0.17);
  flex-grow: 1;
  height: 37px;
  align-items: center;
  display: flex;
`;

const ActivePlanHeading = styled.span`
  font-size: 13px;
  color: #107414;
  color: rgba(234, 84, 85, 1);
  padding: 5px 12px !important;
  flex-grow: 1;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid #1585b5;
  width: 40px;
  height: 40px;
  animation-name: ${spin};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

const DowngradeContainer = styled.div`
  margin-top: 20px;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fdeeee;
`;

const DowngradeInfoText = styled.p`
  font-weight: 500;
  color: red;
  margin-right: 20px;
  margin-bottom: 0px;
  font-size: 15px;
`;

const HighlitedText = styled.span`
  font-weight: 700;
  font-size: 16px;
`;

const CancelDowngrade = styled.button`
  background: transparent;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid red;
  color: red;
  font-weight: bold;
  font-size: 14px;
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const SimpleContainer = styled.div`
  display: inline-grid;
`;

const ComparisionSection = styled(Col)`
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-self: center;
  a {
    color: #1585b5;
  }
`;

const ComparisionHeading = styled.h5`
  margin: 1.5rem 0rem;
`;
const ActivePlanHeader = styled.div`
  height: 30px;
  background: rgba(17, 183, 83, 1);
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
`;
const InnerSection = styled.div`
  /* padding: 20px; */
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

function Plans({
  loading,
  setLoading,
}: {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}) {
  const { user, setUser } = useContext(UserContext);
  const {
    planHighliter,
    plansList,
    activePlan,
    setActivePlan,
    downgradeRequestData,
    setDowngradeRequestData,
  } = useContext(PlanContext);
  const [selectedPlanType, setSelectedPlanType] = useState<string>(
    user && user.currentSubscription?.interval
      ? user.currentSubscription?.interval
      : "monthly"
  );

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
  );

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const redirectSession = async (sessionId: any) => {
    const stripe = await stripePromise;
    stripe?.redirectToCheckout({ sessionId });
  };
  const handleCancelDowngradeSubscription = () => {
    setButtonLoading(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/cancel-downgrade-request", {})
      .then((response) => {
        // console.log("response ---- ", response);

        if (response && response.data && response.data.success) {
          // Need to update user

          setUser({
            ...user,
            downgradeRequested: false,
            downgradePlanStartTime: undefined,
          });
          setDowngradeRequestData({
            downgradeRequested: false,
            downgradePlanStartTime: undefined,
          });

          trackEvent({
            email: getUsersEmail(user),
            eventCategory: EventCategory.PLAN,
            event: PlanEvents.USER_CANCEL_DOWNGRADE_PLAN,
            subCategory: SubCategory.EVENT,
            rawData: response.data.data,
          });

          NotificationManager.success("Change Plan cancelled...");
        } else {
          NotificationManager.error(parseErrorResponse(undefined));
        }
        setButtonLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
        // console.error(
        //   "Gettings error at the time of cancel downgrade ... ",
        //   error
        // );
        setButtonLoading(false);
      });
  };
  // console.log("window.Rewardful.referral --- ", window.Rewardful.referral);

  const createPurchaseSession = (
    type: string,
    priceId: string,
    oldSubscriptionId: string | undefined,
    planName: string
  ) => {
    setButtonLoading(true);
    if (!isTestUser(user?.email))
      Mixpanel.track("User tyring to purchase new plan", {
        priceId: priceId,
        type: type,
        planName: planName,
        email: user?.email,
      });

    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/purchase-plan", {
        type,
        priceId,
        oldSubscriptionId,
        referral: window && window.Rewardful && window.Rewardful.referral,
      })
      .then((response) => {
        // console.log("response ---- ", response);

        if (response && response.data && response.data.success) {
          setButtonLoading(false);
          if (type === "Purchase" || type === "Upgrade") {
            redirectSession(response.data.data.sessionId);

            if (type === "Upgrade") {
              trackEvent({
                email: getUsersEmail(user),
                eventCategory: EventCategory.PLAN,
                event: PlanEvents.USER_TRY_TO_UPGRADE_PLAN,
                subCategory: SubCategory.EVENT,
                rawData: { priceId: priceId, type: type, planName: planName },
              });
            } else {
              trackEvent({
                email: getUsersEmail(user),
                eventCategory: EventCategory.PLAN,
                event: PlanEvents.USER_TRY_TO_PURCHASE_PLAN,
                subCategory: SubCategory.EVENT,
                rawData: { priceId: priceId, type: type, planName: planName },
              });
            }
          } else {
            // Need to update user
            trackEvent({
              email: getUsersEmail(user),
              eventCategory: EventCategory.PLAN,
              event: PlanEvents.USER_TRY_TO_DOWNGRADE_PLAN,
              subCategory: SubCategory.EVENT,
              rawData: { priceId: priceId, type: type, planName: planName },
            });
            setButtonLoading(false);
            setUser({
              ...user,
              downgradeRequested: response.data.data.downgradeRequested,
              downgradePlanStartTime: response.data.data.downgradePlanStartTime,
            });

            setDowngradeRequestData({
              downgradeRequested: response.data.data.downgradeRequested,
              downgradePlanStartTime: response.data.data.downgradePlanStartTime,
            });
            NotificationManager.success(response.data.data.message);
          }
        }
      })
      .catch((error) => {
        setButtonLoading(false);
        NotificationManager.error(parseErrorResponse(error));
        // console.error(
        //   "Gettings error at the time of Purchase paln ... ",
        //   error
        // );
      });
  };

  const cancelPlan = () => {
    setLoading(true);
    setButtonLoading(true);
    myAxios
      .get(process.env.REACT_APP_SERVER_URL + "/cancel-plan", {})
      .then((response) => {
        // console.log("response ---- ", response);

        if (response && response.data && response.data.success) {
          setActivePlan(response.data.data);
          NotificationManager.success("Plan cancel at period end..");

          trackEvent({
            email: getUsersEmail(user),
            eventCategory: EventCategory.PLAN,
            event: PlanEvents.USER_REQUESTED_TO_CANCEL_PLAN,
            subCategory: SubCategory.EVENT,
            rawData: response.data.data,
          });
        } else {
          NotificationManager.error(parseErrorResponse(undefined));
        }
        setLoading(false);
        setButtonLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
        // console.error("Gettings error at the time of cancel plan ... ", error);
        setButtonLoading(false);
        setLoading(false);
      });
  };
  useEffect(() => {}, [activePlan]);

  let usedFlashcard = activePlan?.meta.flashCard;
  let totalCharacter = activePlan?.meta.messageCount;

  const handleTogglePlanType = () => {
    setSelectedPlanType((prevType) =>
      prevType === "monthly" ? "yearly" : "monthly"
    );
  };

  return (
    <>
      {!loading ? (
        <>
          {activePlan ? (
            <>
              <CurrentPlanSection>
                <Container>
                  <PlanDetails>
                    <HeadingWrapper></HeadingWrapper>
                    <CurrentPlanProperties>
                      <PlansContainer>
                        <CurrentPlanName>
                          <TickImage />
                          {activePlan.name}{" "}
                          {activePlan.isCustomPlan ? (
                            <></>
                          ) : activePlan.subscriptionId === "test" ? (
                            "$0"
                          ) : (
                            <>
                              {"$" + activePlan?.price}
                              <span
                                style={{
                                  fontSize: "22px",
                                  marginLeft: "-5px",
                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                {"/" + activePlan?.interval}
                              </span>
                            </>
                          )}
                        </CurrentPlanName>
                      </PlansContainer>

                      <PlansContainer>
                        <p className="mb-0" style={{ fontSize: "14px" }}>
                          <b>Purchaser</b>: {user && user.email}
                        </p>
                        {activePlan.meta.isFree ||
                        activePlan.isCancelAtEnd ? null : (
                          <>
                            {activePlan?.nextBillingDate && (
                              <PlanDesc>
                                Next Billing Date:{" "}
                                {moment
                                  .unix(activePlan.nextBillingDate)
                                  .format("Do MMM, YYYY")}
                              </PlanDesc>
                            )}
                          </>
                        )}
                      </PlansContainer>
                      <PlansContainer>
                        {activePlan.isCancelable ? (
                          activePlan.isCancelAtEnd ||
                          downgradeRequestData?.downgradeRequested ? null : (
                            <PurchaseButton
                              style={{ width: "60%" }}
                              onClick={() => cancelPlan()}
                            >
                              Cancel Plan
                            </PurchaseButton>
                          )
                        ) : null}
                      </PlansContainer>
                    </CurrentPlanProperties>
                  </PlanDetails>
                  <HeadingPlans>
                    {activePlan.status === "trialing" ? (
                      <TrialHeading> {activePlan?.expireText}</TrialHeading>
                    ) : activePlan.status === "active" &&
                      (activePlan.daysRemaining! <= 7 ||
                        activePlan.isCancelAtEnd ||
                        activePlan.meta.isFree) ? (
                      <ActivePlanHeading>
                        {activePlan?.expireText}
                      </ActivePlanHeading>
                    ) : (
                      activePlan?.planDescription
                    )}
                  </HeadingPlans>
                </Container>
                {activePlan.isCancelAtEnd &&
                  !downgradeRequestData?.downgradeRequested && (
                    <DowngradeContainer>
                      <DowngradeInfoText>
                        Your subscription will be cancelled at the end of your
                        current billing period.
                      </DowngradeInfoText>
                    </DowngradeContainer>
                  )}
                {downgradeRequestData?.downgradeRequested && (
                  <DowngradeContainer>
                    <DowngradeInfoText>
                      You have requested to change subscription, your
                      subscription will be changed on{" "}
                      {downgradeRequestData?.downgradePlanStartTime &&
                        moment
                          .unix(downgradeRequestData?.downgradePlanStartTime)
                          .format("Do MMM, YYYY")}
                    </DowngradeInfoText>
                    <CancelDowngrade
                      onClick={handleCancelDowngradeSubscription}
                    >
                      Cancel Change Plan request
                    </CancelDowngrade>
                  </DowngradeContainer>
                )}
              </CurrentPlanSection>
            </>
          ) : (
            <CurrentPlanSection>
              <Container>
                <HeadingCenter>no active subscriptions</HeadingCenter>
              </Container>
            </CurrentPlanSection>
          )}
        </>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <AvailablePlansSection>
        <HeadingWrapper>
          <Heading>Change Your Plan</Heading>

          <ToogleSection>
            <PlanText
              isSelected={selectedPlanType === "monthly"}
              onClick={handleTogglePlanType}
            >
              Monthly Plans
            </PlanText>
            <CheckBoxWrapper>
              <CheckBox
                id="checkbox"
                type="checkbox"
                checked={selectedPlanType === "yearly"}
                onChange={handleTogglePlanType}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </CheckBoxWrapper>

            <PlanText
              isSelected={selectedPlanType === "yearly"}
              onClick={handleTogglePlanType}
            >
              Yearly Plans
            </PlanText>
          </ToogleSection>
        </HeadingWrapper>

        {!loading ? (
          <>
            <PricingSection>
              <Swiper
                slidesPerView={4}
                centeredSlides={false}
                spaceBetween={10}
                slidesPerGroupSkip={1}
                grabCursor={true}
                keyboard={{
                  enabled: true,
                }}
                scrollbar={true}
                navigation={true}
                modules={[Keyboard, Scrollbar, Navigation]}
                className="mySwiper"
              >
                {/* **************** this is the CUSTOM PLAN SWIPER **************** */}
                {activePlan?.isCustomPlan && (
                  <SwiperSlide>
                    <PricingCol sm={12} md={12} lg={12}>
                      <PricingDivSe
                        style={{
                          border: true
                            ? "2.5px #11B753 solid"
                            : "1.5px solid #d7d9e3",
                          backgroundColor: true
                            ? "rgba(17, 183, 83, 0.05)"
                            : "",
                        }}
                      >
                        <ActivePlanHeader>Active Plan</ActivePlanHeader>
                        <InnerSection>
                          <div style={{ flexDirection: "column" }}>
                            <PlansContainer>
                              <PlanName>{activePlan?.name}</PlanName>

                              <CurrentPlanName>
                                {activePlan?.isCustomPlan ? (
                                  <></>
                                ) : activePlan?.subscriptionId === "test" ? (
                                  "$0"
                                ) : (
                                  <>
                                    {"$" + activePlan?.price}
                                    <HighlitedText
                                      style={{
                                        fontSize: "22px",
                                        marginLeft: "-5px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {" "}
                                      {"/" + activePlan?.interval}
                                    </HighlitedText>
                                  </>
                                )}
                              </CurrentPlanName>
                            </PlansContainer>
                            <PlanDesc>custom plan Includes:</PlanDesc>
                          </div>
                          {/* <DescriptionBox> */}
                          <PlanDetails>
                            <CurrentPlanProperties>
                              <PlansContainer></PlansContainer>
                              <PlansContainer>
                                <PlanDesc>
                                  <b>
                                    <img
                                      alt="tabTick115"
                                      src={Tick}
                                      width="15"
                                      style={{ marginRight: "10px" }}
                                    />
                                  </b>{" "}
                                  {activePlan?.meta.noOfBooks === -1
                                    ? "Unlimited"
                                    : activePlan?.planStatics.totalBooksCount +
                                      " / " +
                                      activePlan?.meta.noOfBooks}{" "}
                                  {activePlan &&
                                  activePlan.planStatics &&
                                  activePlan.planStatics.totalBooksCount &&
                                  activePlan.planStatics.totalBooksCount > 1
                                    ? "chatbots used"
                                    : "chatbot used"}
                                </PlanDesc>

                                <PlanDesc>
                                  <b>
                                    <img
                                      alt="tabTick116"
                                      src={Tick}
                                      width="15"
                                      style={{ marginRight: "10px" }}
                                    />
                                  </b>{" "}
                                  {activePlan?.noOfBook === -1
                                    ? "Unlimited"
                                    : numberWithComma(
                                        Number(
                                          activePlan?.planStatics.usedMessage
                                        )
                                      ) +
                                      " / " +
                                      numberWithComma(
                                        Number(activePlan?.meta.messageCount)
                                      )}{" "}
                                  messages used
                                </PlanDesc>

                                {activePlan?.meta.deleteChat && (
                                  <PlanDesc>
                                    <b>
                                      <img
                                        alt="tabTick117"
                                        src={Tick}
                                        width="15"
                                        style={{ marginRight: "10px" }}
                                      />
                                    </b>{" "}
                                    Delete chatbot
                                  </PlanDesc>
                                )}
                                {activePlan?.meta.flashCard && (
                                  <PlanDesc>
                                    <b>
                                      <img
                                        alt="tabTick118"
                                        src={Tick}
                                        width="15"
                                        style={{ marginRight: "10px" }}
                                      />
                                    </b>{" "}
                                    Flashcards
                                  </PlanDesc>
                                )}

                                {activePlan.meta &&
                                  activePlan.meta.removeDropChatBranding && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isRemoveDropChatBrandingHighliter ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>
                                            Remove Dropchat branding
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          Remove Dropchat branding
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}

                                {activePlan.meta &&
                                  activePlan.meta.deleteAccount && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isDeleteAccount ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            Delete Account
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          Delete Account
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}

                                {activePlan.meta &&
                                  activePlan.meta.leadLoginSettings && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isLeadLoginSettings ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>
                                            Lead Login Settings
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          Lead Login Settings
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}

                                <PlanDesc>
                                  <>
                                    <img
                                      alt="tabTick136"
                                      src={Tick}
                                      width="15"
                                      style={{ marginRight: "10px" }}
                                    />
                                    GPT-4o mini
                                  </>
                                </PlanDesc>

                                {activePlan.meta &&
                                  activePlan.meta.gpt4TurboPreview && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isGpt4TurboPreview ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>gpt-4o</HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          gpt-4o
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                {activePlan.meta &&
                                  activePlan.meta.allowYTIndex && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isAllowYTIndex ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            YouTube Chatbot
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          YouTube Chatbot
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                {activePlan.meta &&
                                  activePlan.meta.allowPDFIndex && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isAllowPDFIndex ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            PDF Chatbot
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          PDF Chatbot
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                {activePlan.meta &&
                                  activePlan.meta.allowWebsiteIndex && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isAllowWebsiteIndex ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>
                                            Website Chatbot
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          Website Chatbot
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                {activePlan.meta &&
                                  activePlan.meta.customOpenAPIKey && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isCustomOpenAPIKey ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>
                                            Use Own OpenAPI Key
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          Use Own OpenAPI Key
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                {activePlan.meta &&
                                  activePlan.meta.integrationApps && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isIntegrationApps ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>
                                            Integration Apps
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          Integration Apps
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                {activePlan.meta &&
                                  activePlan.meta.APIConfiguration && (
                                    <PlanDesc>
                                      <>
                                        <img
                                          alt="tabTick136"
                                          src={Tick}
                                          width="15"
                                          style={{
                                            marginRight: "10px",
                                          }}
                                        />
                                        Live API Configuration
                                      </>
                                    </PlanDesc>
                                  )}
                                <PlanDesc>
                                  <b>
                                    <img
                                      alt="tabTick115"
                                      src={Tick}
                                      width="15"
                                      style={{ marginRight: "10px" }}
                                    />
                                  </b>{" "}
                                  {activePlan?.meta.shopifyProductCount === -1
                                    ? "Unlimited"
                                    : activePlan?.planStatics
                                        .shopifyProductUsed +
                                      " / " +
                                      activePlan?.meta.shopifyProductCount}{" "}
                                  {activePlan &&
                                  activePlan.planStatics &&
                                  activePlan.planStatics.shopifyProductUsed &&
                                  activePlan.planStatics.shopifyProductUsed > 1
                                    ? "Shopify Products used"
                                    : "Shopify Product used"}
                                </PlanDesc>
                              </PlansContainer>
                              <PlansContainer>
                                {activePlan?.meta.isFree ||
                                activePlan?.isCancelAtEnd ? null : (
                                  <>
                                    {activePlan?.nextBillingDate && (
                                      <PlanDesc>
                                        Next Billing Date:{" "}
                                        {moment
                                          .unix(activePlan?.nextBillingDate)
                                          .format("Do MMM, YYYY")}
                                      </PlanDesc>
                                    )}
                                  </>
                                )}
                              </PlansContainer>
                              <div
                                style={{
                                  height: "36vh",
                                  display: "flex",
                                  justifyContent: "end",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                {activePlan?.isCancelable ? (
                                  activePlan?.isCancelAtEnd ||
                                  downgradeRequestData?.downgradeRequested ? null : (
                                    <PurchaseButton
                                      style={{ width: "68%" }}
                                      onClick={() => cancelPlan()}
                                    >
                                      Cancel Plan
                                    </PurchaseButton>
                                  )
                                ) : null}
                              </div>
                            </CurrentPlanProperties>
                          </PlanDetails>
                          {/* </DescriptionBox> */}
                        </InnerSection>
                      </PricingDivSe>
                    </PricingCol>
                  </SwiperSlide>
                )}
                {activePlan?.meta?.isSpecial && (
                  <SwiperSlide>
                    <PricingCol sm={12} md={12} lg={12}>
                      <PricingDivSe
                        style={{
                          border: true
                            ? "2.5px #11B753 solid"
                            : "1.5px solid #d7d9e3",
                          backgroundColor: true
                            ? "rgba(17, 183, 83, 0.05)"
                            : "",
                        }}
                      >
                        <ActivePlanHeader>Active Plan</ActivePlanHeader>
                        <InnerSection>
                          <div style={{ flexDirection: "column" }}>
                            <PlansContainer>
                              <PlanName>{activePlan?.name}</PlanName>

                              <CurrentPlanName>
                                {activePlan?.subscriptionId === "test" ? (
                                  "$0"
                                ) : (
                                  <>
                                    {"$" + activePlan?.price}
                                    <HighlitedText
                                      style={{
                                        fontSize: "22px",
                                        marginLeft: "-5px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {" "}
                                      {"/" + activePlan?.interval}
                                    </HighlitedText>
                                  </>
                                )}
                              </CurrentPlanName>
                            </PlansContainer>
                            <PlanDesc>Plan Includes:</PlanDesc>
                          </div>
                          {/* <DescriptionBox> */}
                          <PlanDetails>
                            <CurrentPlanProperties>
                              <PlansContainer></PlansContainer>
                              <PlansContainer>
                                <PlanDesc>
                                  <b>
                                    <img
                                      alt="tabTick115"
                                      src={Tick}
                                      width="15"
                                      style={{ marginRight: "10px" }}
                                    />
                                  </b>{" "}
                                  {activePlan?.meta.noOfBooks === -1
                                    ? "Unlimited"
                                    : activePlan?.planStatics.totalBooksCount +
                                      " / " +
                                      activePlan?.meta.noOfBooks}{" "}
                                  {activePlan &&
                                  activePlan.planStatics &&
                                  activePlan.planStatics.totalBooksCount &&
                                  activePlan.planStatics.totalBooksCount > 1
                                    ? "chatbots used"
                                    : "chatbot used"}
                                </PlanDesc>

                                <PlanDesc>
                                  <b>
                                    <img
                                      alt="tabTick116"
                                      src={Tick}
                                      width="15"
                                      style={{ marginRight: "10px" }}
                                    />
                                  </b>{" "}
                                  {activePlan?.noOfBook === -1
                                    ? "Unlimited"
                                    : numberWithComma(
                                        Number(
                                          activePlan?.planStatics.usedMessage
                                        )
                                      ) +
                                      " / " +
                                      numberWithComma(
                                        Number(activePlan?.meta.messageCount)
                                      )}{" "}
                                  messages used
                                </PlanDesc>

                                {activePlan?.meta.deleteChat && (
                                  <PlanDesc>
                                    <b>
                                      <img
                                        alt="tabTick117"
                                        src={Tick}
                                        width="15"
                                        style={{ marginRight: "10px" }}
                                      />
                                    </b>{" "}
                                    Delete chatbot
                                  </PlanDesc>
                                )}
                                {activePlan?.meta.flashCard && (
                                  <PlanDesc>
                                    <b>
                                      <img
                                        alt="tabTick118"
                                        src={Tick}
                                        width="15"
                                        style={{ marginRight: "10px" }}
                                      />
                                    </b>{" "}
                                    Flashcards
                                  </PlanDesc>
                                )}

                                {activePlan.meta &&
                                  activePlan.meta.removeDropChatBranding && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isRemoveDropChatBrandingHighliter ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>
                                            Remove Dropchat branding
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          Remove Dropchat branding
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}

                                {activePlan.meta &&
                                  activePlan.meta.deleteAccount && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isDeleteAccount ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            Delete Account
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          Delete Account
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}

                                {activePlan.meta &&
                                  activePlan.meta.leadLoginSettings && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isLeadLoginSettings ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>
                                            Lead Login Settings
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          Lead Login Settings
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                <PlanDesc>
                                  <>
                                    <img
                                      alt="tabTick136"
                                      src={Tick}
                                      width="15"
                                      style={{ marginRight: "10px" }}
                                    />
                                    GPT-4o mini
                                  </>
                                </PlanDesc>

                                {activePlan.meta &&
                                  activePlan.meta.gpt4TurboPreview && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isGpt4TurboPreview ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>gpt-4o</HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          gpt-4o
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                {activePlan.meta &&
                                  activePlan.meta.allowYTIndex && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isAllowYTIndex ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            YouTube Chatbot
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          YouTube Chatbot
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                {activePlan.meta &&
                                  activePlan.meta.allowPDFIndex && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isAllowPDFIndex ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          <HighlitedText>
                                            PDF Chatbot
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          PDF Chatbot
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                {activePlan.meta &&
                                  activePlan.meta.allowWebsiteIndex && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isAllowWebsiteIndex ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>
                                            Website Chatbot
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          Website Chatbot
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                {activePlan.meta &&
                                  activePlan.meta.customOpenAPIKey && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isCustomOpenAPIKey ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>
                                            Use Own OpenAPI Key
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          Use Own OpenAPI Key
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                {activePlan.meta &&
                                  activePlan.meta.integrationApps && (
                                    <PlanDesc>
                                      {planHighliter &&
                                      planHighliter.isIntegrationApps ? (
                                        <>
                                          <img
                                            alt="tabTick135"
                                            src={TickOrange}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          <HighlitedText>
                                            Integration Apps
                                          </HighlitedText>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            alt="tabTick136"
                                            src={Tick}
                                            width="15"
                                            style={{
                                              marginRight: "10px",
                                            }}
                                          />
                                          Integration Apps
                                        </>
                                      )}
                                    </PlanDesc>
                                  )}
                                {activePlan.meta &&
                                  activePlan.meta.APIConfiguration && (
                                    <PlanDesc>
                                      <>
                                        <img
                                          alt="tabTick136"
                                          src={Tick}
                                          width="15"
                                          style={{
                                            marginRight: "10px",
                                          }}
                                        />
                                        Live API Configuration
                                      </>
                                    </PlanDesc>
                                  )}
                                <PlanDesc>
                                  <b>
                                    <img
                                      alt="tabTick115"
                                      src={Tick}
                                      width="15"
                                      style={{ marginRight: "10px" }}
                                    />
                                  </b>{" "}
                                  {activePlan?.meta.shopifyProductCount === -1
                                    ? "Unlimited"
                                    : activePlan?.planStatics
                                        .shopifyProductUsed +
                                      " / " +
                                      activePlan?.meta.shopifyProductCount}{" "}
                                  {activePlan &&
                                  activePlan.planStatics &&
                                  activePlan.planStatics.shopifyProductUsed &&
                                  activePlan.planStatics.shopifyProductUsed > 1
                                    ? "Shopify Products used"
                                    : "Shopify Product used"}
                                </PlanDesc>
                              </PlansContainer>
                              <PlansContainer>
                                {activePlan?.meta.isFree ||
                                activePlan?.isCancelAtEnd ? null : (
                                  <>
                                    {activePlan?.nextBillingDate && (
                                      <PlanDesc>
                                        Next Billing Date:{" "}
                                        {moment
                                          .unix(activePlan?.nextBillingDate)
                                          .format("Do MMM, YYYY")}
                                      </PlanDesc>
                                    )}
                                  </>
                                )}
                              </PlansContainer>
                              <div
                                style={{
                                  height: "36vh",
                                  display: "flex",
                                  justifyContent: "end",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                {activePlan?.isCancelable ? (
                                  activePlan?.isCancelAtEnd ||
                                  downgradeRequestData?.downgradeRequested ? null : (
                                    <PurchaseButton
                                      style={{ width: "68%" }}
                                      onClick={() => cancelPlan()}
                                    >
                                      Cancel Plan
                                    </PurchaseButton>
                                  )
                                ) : null}
                              </div>
                            </CurrentPlanProperties>
                          </PlanDetails>
                          {/* </DescriptionBox> */}
                        </InnerSection>
                      </PricingDivSe>
                    </PricingCol>
                  </SwiperSlide>
                )}

                {/* **************** this is the MAIN PLAN SWIPER **************** */}
                {plansList &&
                  plansList.length > 0 &&
                  plansList
                    .filter(
                      (plan: SubscriptionPlan) =>
                        plan.interval === selectedPlanType &&
                        !plan.meta.isSpecial
                      // &&
                      // plan.id !== activePlan?.priceId
                    )
                    .map((plan: SubscriptionPlan) => {
                      const isActive = plan.id === activePlan?.priceId;
                      // console.log("plansList is, ", plansList);
                      return (
                        <>
                          <SwiperSlide key={plan.id}>
                            <PricingCol sm={12} md={12} lg={12}>
                              <PricingDiv
                                isHighlight={
                                  (planHighliter &&
                                    ((planHighliter.isBookHighliter &&
                                      Number(plan.meta.noOfBooks) >
                                        planHighliter.bookLimit) ||
                                      (planHighliter.isDeleteBookHighliter &&
                                        plan.meta.deleteChat) ||
                                      (planHighliter.isRemoveDropChatBrandingHighliter &&
                                        plan.meta.removeDropChatBranding) ||
                                      (planHighliter.isFlashcardHighliter &&
                                        plan.meta.flashCard))) ||
                                  (planHighliter?.isCharacterLimit &&
                                    Number(plan.meta.messageCount) >
                                      Number(
                                        user?.currentSubscription?.meta
                                          .messageCount
                                      )) ||
                                  (planHighliter?.isIndexCharacterLimit &&
                                    Number(plan.meta.indexCharacterSupport) >
                                      Number(
                                        user?.currentSubscription?.meta
                                          .indexCharacterSupport
                                      )) ||
                                  (planHighliter?.isShopifyProduct &&
                                    Number(plan.meta.shopifyProductCount) >
                                      Number(
                                        user?.currentSubscription?.meta
                                          .shopifyProductCount
                                      )) ||
                                  (planHighliter?.ispfdLimitHighliter &&
                                    Number(plan.meta.pdfPageSupport) >
                                      planHighliter.pdfLimit) ||
                                  (planHighliter?.ispfdLimitHighliter &&
                                    plan.meta.pdfPageSupport === -1) ||
                                  (planHighliter?.isDeleteAccount &&
                                    plan.meta.deleteAccount) ||
                                  (planHighliter?.isIntegrationApps &&
                                    plan.meta.integrationApps)
                                }
                                style={{
                                  border: isActive
                                    ? "1px #11B753 solid"
                                    : "1.5px solid #d7d9e3",
                                  backgroundColor: isActive
                                    ? "rgba(17, 183, 83, 0.05)"
                                    : "",
                                }}
                              >
                                {isActive && (
                                  <ActivePlanHeader>
                                    Active Plan
                                  </ActivePlanHeader>
                                )}
                                <InnerSection>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "5px",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <PlanName>{plan.name}</PlanName>
                                  </div>
                                  {plan.type === "Free" ? (
                                    <PlanAmount>0$</PlanAmount>
                                  ) : (
                                    <PlanAmount>{`$${plan.price}`}</PlanAmount>
                                  )}
                                  <DescriptionBox>
                                    <DescriptionText>
                                      {/* <PlanDesc>
                                        {plan.name === "Free Plan Monthly"
                                          ? "Free Plan Monthly Includes:"
                                          : plan.name === "Starter Plan Monthly"
                                          ? "Starter Plan Monthly Includes:"
                                          : plan.name ===
                                            "Professional Plan Monthly"
                                          ? "Professional Plan Monthly Includes:"
                                          : "Starter Yearly Plan Includes:"}
                                      </PlanDesc> */}
                                      <PlanDesc>
                                        {plan.name + " Includes:"}
                                      </PlanDesc>
                                      <br />

                                      {usedFlashcard && isActive ? (
                                        // actived plan flashacard
                                        <PlanDesc>
                                          <b>
                                            <img
                                              alt="tabTick115"
                                              src={Tick}
                                              width="15"
                                              style={{ marginRight: "10px" }}
                                            />
                                          </b>{" "}
                                          {activePlan?.meta.noOfBooks === -1
                                            ? "Unlimited"
                                            : activePlan?.planStatics
                                                .totalBooksCount +
                                              " / " +
                                              activePlan?.meta.noOfBooks}{" "}
                                          {activePlan &&
                                          activePlan.planStatics &&
                                          activePlan.planStatics
                                            .totalBooksCount &&
                                          activePlan.planStatics
                                            .totalBooksCount > 1
                                            ? "chatbots used"
                                            : "chatbot used"}
                                        </PlanDesc>
                                      ) : (
                                        // {/* -------------all plan content ------------ */}
                                        <PlanDesc>
                                          {planHighliter &&
                                          planHighliter.isBookHighliter &&
                                          plan.meta &&
                                          plan.meta.noOfBooks &&
                                          Number(plan.meta.noOfBooks) >
                                            planHighliter.bookLimit ? (
                                            <>
                                              <img
                                                alt="tabTick119"
                                                src={TickOrange}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <HighlitedText>
                                                {plan.meta.noOfBooks!}{" "}
                                                {plan.meta.noOfBooks > 1
                                                  ? "chatbots"
                                                  : "chatbot"}
                                              </HighlitedText>
                                            </>
                                          ) : (
                                            plan.meta.noOfBooks && (
                                              <>
                                                <img
                                                  alt="tabTick120"
                                                  src={Tick}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                {plan.meta.noOfBooks!}{" "}
                                                {plan.meta.noOfBooks > 1
                                                  ? "chatbots"
                                                  : "chatbot"}
                                              </>
                                            )
                                          )}
                                        </PlanDesc>
                                      )}

                                      {/* -------------active plan content ------------ */}

                                      {isActive && totalCharacter ? (
                                        <PlanDesc>
                                          <b>
                                            <img
                                              alt="tabTick116"
                                              src={Tick}
                                              width="15"
                                              style={{ marginRight: "10px" }}
                                            />
                                          </b>{" "}
                                          {activePlan?.noOfBook === -1
                                            ? "Unlimited"
                                            : numberWithComma(
                                                Number(
                                                  activePlan?.planStatics
                                                    .usedMessage
                                                )
                                              ) +
                                              " / " +
                                              numberWithComma(
                                                Number(
                                                  activePlan?.meta.messageCount
                                                )
                                              )}{" "}
                                          messages used
                                        </PlanDesc>
                                      ) : // {/* -------------all plan content ------------ */}
                                      (plan! && plan.meta.messageCount
                                          ? Number(plan.meta.messageCount)
                                          : 0) >
                                        (user?.currentSubscription?.meta
                                          .messageCount
                                          ? Number(
                                              user?.currentSubscription?.meta
                                                .messageCount
                                            )
                                          : 0) ? (
                                        <>
                                          {planHighliter &&
                                          planHighliter.isCharacterLimit ? (
                                            <PlanDesc>
                                              <img
                                                alt="tabTick121"
                                                src={TickOrange}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <HighlitedText>
                                                {numberWithComma(
                                                  Number(
                                                    plan.meta?.messageCount
                                                  )
                                                )}{" "}
                                                messages limit
                                              </HighlitedText>
                                            </PlanDesc>
                                          ) : (
                                            <PlanDesc>
                                              <img
                                                alt="tabTick122"
                                                src={Tick}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              {numberWithComma(
                                                Number(plan.meta?.messageCount)
                                              )}{" "}
                                              messages limit
                                            </PlanDesc>
                                          )}
                                        </>
                                      ) : (
                                        <PlanDesc>
                                          <img
                                            alt="tabTick123"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          {numberWithComma(
                                            Number(
                                              plan.meta?.messageCount
                                                ? plan.meta?.messageCount
                                                : 0
                                            )
                                          )}{" "}
                                          messages limit
                                        </PlanDesc>
                                      )}
                                      {(
                                        plan && plan.meta.indexCharacterSupport
                                          ? plan.meta.indexCharacterSupport
                                          : 0
                                      ) ? (
                                        <>
                                          {planHighliter &&
                                          planHighliter.isIndexCharacterLimit &&
                                          (plan &&
                                          plan.meta.indexCharacterSupport
                                            ? plan.meta.indexCharacterSupport
                                            : 0) >
                                            (user?.currentSubscription?.meta
                                              .indexCharacterSupport
                                              ? user?.currentSubscription?.meta
                                                  .indexCharacterSupport
                                              : 0) ? (
                                            <PlanDesc>
                                              <img
                                                alt="tabTick121"
                                                src={TickOrange}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <HighlitedText>
                                                {numberWithComma(
                                                  Number(
                                                    plan.meta
                                                      ?.indexCharacterSupport
                                                  )
                                                )}{" "}
                                                Index characters limit
                                              </HighlitedText>
                                            </PlanDesc>
                                          ) : (
                                            <PlanDesc>
                                              <img
                                                alt="tabTick122"
                                                src={Tick}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              {numberWithComma(
                                                Number(
                                                  plan.meta
                                                    ?.indexCharacterSupport
                                                )
                                              )}{" "}
                                              Index characters limit
                                            </PlanDesc>
                                          )}
                                        </>
                                      ) : null}

                                      {plan &&
                                      plan.meta.pdfPageSupport === -1 ? (
                                        <>
                                          {planHighliter &&
                                          planHighliter.ispfdLimitHighliter ? (
                                            <PlanDesc>
                                              <img
                                                alt="tabTick124"
                                                src={TickOrange}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <HighlitedText>
                                                {plan.meta?.pdfPageSupport ===
                                                -1
                                                  ? "Unlimited"
                                                  : numberWithComma(
                                                      Number(
                                                        plan.meta
                                                          ?.pdfPageSupport
                                                      )
                                                    )}{" "}
                                                PDF page limit
                                              </HighlitedText>
                                            </PlanDesc>
                                          ) : (
                                            <PlanDesc>
                                              <img
                                                alt="tabTick125"
                                                src={Tick}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              {plan.meta?.pdfPageSupport === -1
                                                ? "Unlimited"
                                                : numberWithComma(
                                                    Number(
                                                      plan.meta?.pdfPageSupport
                                                    )
                                                  )}{" "}
                                              PDF page limit
                                            </PlanDesc>
                                          )}
                                        </>
                                      ) : (plan && plan.meta.pdfPageSupport
                                          ? plan.meta.pdfPageSupport
                                          : 0) >
                                        (user?.currentSubscription?.meta
                                          .pdfPageSupport
                                          ? user?.currentSubscription?.meta
                                              .pdfPageSupport
                                          : 0) ? (
                                        <>
                                          {planHighliter &&
                                          planHighliter.ispfdLimitHighliter ? (
                                            <PlanDesc>
                                              <img
                                                alt="tabTick126"
                                                src={TickOrange}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <HighlitedText>
                                                {plan.meta?.pdfPageSupport ===
                                                -1
                                                  ? "Unlimited"
                                                  : numberWithComma(
                                                      Number(
                                                        plan.meta
                                                          ?.pdfPageSupport
                                                      )
                                                    )}{" "}
                                                PDF page limit
                                              </HighlitedText>
                                            </PlanDesc>
                                          ) : (
                                            <PlanDesc>
                                              <img
                                                alt="tabTick127"
                                                src={Tick}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              {plan.meta?.pdfPageSupport === -1
                                                ? "Unlimited"
                                                : numberWithComma(
                                                    Number(
                                                      plan.meta?.pdfPageSupport
                                                    )
                                                  )}{" "}
                                              PDF page limit
                                            </PlanDesc>
                                          )}
                                        </>
                                      ) : (
                                        <PlanDesc>
                                          <img
                                            alt="tabTick128"
                                            src={Tick}
                                            width="15"
                                            style={{ marginRight: "10px" }}
                                          />
                                          {plan.meta?.pdfPageSupport === -1
                                            ? "Unlimited"
                                            : numberWithComma(
                                                Number(
                                                  plan.meta?.pdfPageSupport
                                                )
                                              )}{" "}
                                          PDF page limit
                                        </PlanDesc>
                                      )}

                                      {plan.meta && plan.meta.deleteChat && (
                                        <PlanDesc>
                                          {planHighliter &&
                                          planHighliter.isDeleteBookHighliter ? (
                                            <>
                                              <img
                                                alt="tabTick129"
                                                src={TickOrange}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <HighlitedText>
                                                Delete chatbot
                                              </HighlitedText>
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                alt="tabTick130"
                                                src={Tick}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              Delete chatbot
                                            </>
                                          )}
                                        </PlanDesc>
                                      )}
                                      {plan.meta && plan.meta.flashCard && (
                                        <PlanDesc>
                                          {planHighliter &&
                                          planHighliter.isFlashcardHighliter ? (
                                            <>
                                              <img
                                                alt="tabTick132"
                                                src={TickOrange}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <HighlitedText>
                                                Flashcards
                                              </HighlitedText>
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                alt="tabTick133"
                                                src={Tick}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              Flashcards
                                            </>
                                          )}
                                        </PlanDesc>
                                      )}

                                      {plan.meta &&
                                        plan.meta.removeDropChatBranding && (
                                          <PlanDesc>
                                            {planHighliter &&
                                            planHighliter.isRemoveDropChatBrandingHighliter ? (
                                              <>
                                                <img
                                                  alt="tabTick135"
                                                  src={TickOrange}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                <HighlitedText>
                                                  Remove Dropchat branding
                                                </HighlitedText>
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  alt="tabTick136"
                                                  src={Tick}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                Remove Dropchat branding
                                              </>
                                            )}
                                          </PlanDesc>
                                        )}
                                      {plan.meta && plan.meta.deleteAccount && (
                                        <PlanDesc>
                                          {planHighliter &&
                                          planHighliter.isDeleteAccount ? (
                                            <>
                                              <img
                                                alt="tabTick135"
                                                src={TickOrange}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <HighlitedText>
                                                Delete Account
                                              </HighlitedText>
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                alt="tabTick136"
                                                src={Tick}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              Delete Account
                                            </>
                                          )}
                                        </PlanDesc>
                                      )}

                                      {plan.meta &&
                                        plan.meta.leadLoginSettings && (
                                          <PlanDesc>
                                            {planHighliter &&
                                            planHighliter.isLeadLoginSettings ? (
                                              <>
                                                <img
                                                  alt="tabTick135"
                                                  src={TickOrange}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                <HighlitedText>
                                                  Lead Login Settings
                                                </HighlitedText>
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  alt="tabTick136"
                                                  src={Tick}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                Lead Login Settings
                                              </>
                                            )}
                                          </PlanDesc>
                                        )}

                                      <PlanDesc>
                                        <img
                                          alt="tabTick136"
                                          src={Tick}
                                          width="15"
                                          style={{ marginRight: "10px" }}
                                        />
                                        GPT-4o mini
                                      </PlanDesc>

                                      {plan.meta &&
                                        plan.meta.gpt4TurboPreview && (
                                          <PlanDesc>
                                            {planHighliter &&
                                            planHighliter.isGpt4TurboPreview ? (
                                              <>
                                                <img
                                                  alt="tabTick135"
                                                  src={TickOrange}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                <HighlitedText>
                                                  gpt-4o
                                                </HighlitedText>
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  alt="tabTick136"
                                                  src={Tick}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                gpt-4o
                                              </>
                                            )}
                                          </PlanDesc>
                                        )}
                                      {plan.meta && plan.meta.allowYTIndex && (
                                        <PlanDesc>
                                          {planHighliter &&
                                          planHighliter.isAllowYTIndex ? (
                                            <>
                                              <img
                                                alt="tabTick135"
                                                src={TickOrange}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <HighlitedText>
                                                YouTube Chatbot
                                              </HighlitedText>
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                alt="tabTick136"
                                                src={Tick}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              YouTube Chatbot
                                            </>
                                          )}
                                        </PlanDesc>
                                      )}
                                      {plan.meta && plan.meta.allowPDFIndex && (
                                        <PlanDesc>
                                          {planHighliter &&
                                          planHighliter.isAllowPDFIndex ? (
                                            <>
                                              <img
                                                alt="tabTick135"
                                                src={TickOrange}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <HighlitedText>
                                                PDF Chatbot
                                              </HighlitedText>
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                alt="tabTick136"
                                                src={Tick}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              PDF Chatbot
                                            </>
                                          )}
                                        </PlanDesc>
                                      )}
                                      {plan.meta &&
                                        plan.meta.allowWebsiteIndex && (
                                          <PlanDesc>
                                            {planHighliter &&
                                            planHighliter.isAllowWebsiteIndex ? (
                                              <>
                                                <img
                                                  alt="tabTick135"
                                                  src={TickOrange}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                <HighlitedText>
                                                  Website Chatbot
                                                </HighlitedText>
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  alt="tabTick136"
                                                  src={Tick}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                Website Chatbot
                                              </>
                                            )}
                                          </PlanDesc>
                                        )}
                                      {plan.meta &&
                                        plan.meta.customOpenAPIKey && (
                                          <PlanDesc>
                                            {planHighliter &&
                                            planHighliter.isCustomOpenAPIKey ? (
                                              <>
                                                <img
                                                  alt="tabTick135"
                                                  src={TickOrange}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                <HighlitedText>
                                                  Use Own OpenAPI Key
                                                </HighlitedText>
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  alt="tabTick136"
                                                  src={Tick}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                Use Own OpenAPI Key
                                              </>
                                            )}
                                          </PlanDesc>
                                        )}
                                      {plan.meta &&
                                        plan.meta.integrationApps && (
                                          <PlanDesc>
                                            {planHighliter &&
                                            planHighliter.isIntegrationApps ? (
                                              <>
                                                <img
                                                  alt="tabTick135"
                                                  src={TickOrange}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                <HighlitedText>
                                                  Integration Apps
                                                </HighlitedText>
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  alt="tabTick136"
                                                  src={Tick}
                                                  width="15"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                Integration Apps
                                              </>
                                            )}
                                          </PlanDesc>
                                        )}
                                      {(
                                        plan && plan.meta.shopifyProductCount
                                          ? plan.meta.shopifyProductCount
                                          : 0
                                      ) ? (
                                        <>
                                          {planHighliter &&
                                          planHighliter.isShopifyProduct &&
                                          (plan && plan.meta.shopifyProductCount
                                            ? plan.meta.shopifyProductCount
                                            : 0) >
                                            (user?.currentSubscription?.meta
                                              .shopifyProductCount
                                              ? user?.currentSubscription?.meta
                                                  .shopifyProductCount
                                              : 0) ? (
                                            <PlanDesc>
                                              <img
                                                alt="tabTick121"
                                                src={TickOrange}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <HighlitedText>
                                                {numberWithComma(
                                                  Number(
                                                    plan.meta
                                                      ?.shopifyProductCount
                                                  )
                                                )}{" "}
                                                Shopify Product Count
                                              </HighlitedText>
                                            </PlanDesc>
                                          ) : (
                                            <PlanDesc>
                                              <img
                                                alt="tabTick122"
                                                src={Tick}
                                                width="15"
                                                style={{ marginRight: "10px" }}
                                              />
                                              {numberWithComma(
                                                Number(
                                                  plan.meta?.shopifyProductCount
                                                )
                                              )}{" "}
                                              Shopify Product Count
                                            </PlanDesc>
                                          )}
                                        </>
                                      ) : null}
                                      {plan.meta &&
                                        plan.meta.APIConfiguration && (
                                          <PlanDesc>
                                            <>
                                              <img
                                                alt="tabTick136"
                                                src={Tick}
                                                width="15"
                                                style={{
                                                  marginRight: "10px",
                                                }}
                                              />
                                              Live API Configuration
                                            </>
                                          </PlanDesc>
                                        )}
                                    </DescriptionText>
                                  </DescriptionBox>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginBottom: "14px",
                                    }}
                                  >
                                    {downgradeRequestData?.downgradeRequested ? (
                                      plan.type === "Free" ? null : (
                                        <PurchaseButtonDisable>
                                          {plan.buttonShowName}
                                        </PurchaseButtonDisable>
                                      )
                                    ) : (
                                      <>
                                        {!buttonLoading ? (
                                          <>
                                            {plan.disabled ? (
                                              <></>
                                            ) : plan.type !== "Active" ? (
                                              plan.type === "Free" ? (
                                                <PurchaseButtonDisable>
                                                  {plan.buttonShowName}
                                                </PurchaseButtonDisable>
                                              ) : (
                                                <PurchaseButton
                                                  onClick={() => {
                                                    // console.log(
                                                    //   "activePlan --- ",
                                                    //   activePlan
                                                    // );
                                                    setButtonLoading(true);
                                                    createPurchaseSession(
                                                      plan.type,
                                                      plan.id,
                                                      activePlan?.priceId ===
                                                        "test"
                                                        ? undefined
                                                        : activePlan?.id,
                                                      plan.name
                                                    );
                                                  }}
                                                >
                                                  {plan.buttonShowName}
                                                </PurchaseButton>
                                              )
                                            ) : (
                                              <PurchaseButtonDisable>
                                                {plan.buttonShowName}
                                              </PurchaseButtonDisable>
                                            )}
                                          </>
                                        ) : (
                                          <LoaderContainer>
                                            <Loader />
                                          </LoaderContainer>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <SimpleContainer></SimpleContainer>
                                </InnerSection>
                              </PricingDiv>
                            </PricingCol>
                          </SwiperSlide>
                        </>
                      );
                    })}
              </Swiper>
            </PricingSection>

            <ComparisionSection sm={9} md={5} lg={3}>
              <a
                target="_blank"
                href={"https://dropchat.co/pricing"}
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <ComparisionHeading>
                  Compare our full set of features
                </ComparisionHeading>
              </a>
            </ComparisionSection>
          </>
        ) : (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
      </AvailablePlansSection>
    </>
  );
}

export default Plans;
