import { useContext, useEffect, useState, useRef } from "react";
import { Col, FormLabel } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import { ColorPicker } from "primereact/colorpicker";
import styled from "styled-components";
// import { Field, Form, Formik } from "formik";
import myAxios from "../../../myaxios";
// import BubbleChatProtoType from "./bubble-chat-prototype/bubble-chat-prototype";
import { NotificationManager } from "react-notifications";
import { BookContext } from "../../../context/BookContext";
import { UserContext } from "../../../context/UserContext";
import { LoaderContext } from "../../../context/loader-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../../event-utils/dropchat-event-track";
import { trackEvent } from "../../../event-utils/track-event";
import SessionChevronRight from "../../../images/session-chevron-right.svg";
import DeleteIcon from "../../../images/delete-icon-chat-seesion.svg";
import { parseErrorResponse } from "../../../utils/utils";
import ThemeBubbleChatProtoType from "./bubble-chat-prototype/theme-bubble-chat-prototype";

interface Color {
  selected: boolean;
}

const ParentSection = styled(Col)`
  padding: 1rem;
  flex-direction: row;
  justify-content: space-between;
`;

const ColumnSection = styled.div`
  background: #fff;
  flex-direction: column;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 0.5rem;
  width: 49.5%;
`;

const ContentParent = styled.div`
  flex-direction: column;
`;
const PreviewParent = styled.div``;

const HeadingText = styled.h2`
  font-size: 20px;
  color: #33303c;
  font-weight: 600;
`;

const Subheading = styled.p`
  font-size: 16px;
  color: #33303c;
  font-weight: 400;
`;

const FieldSection = styled.div`
  padding: 10px 0px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  align-items: center;
`;

const Sidebar = styled(Col)``;

const OptionList = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  width: 100%;
`;

const OptionListItem = styled.li`
  background: ${(props: Color) => (props.selected ? "#1585b5" : "#fff")};
  padding: 10px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const OptionItemText = styled.div`
  flex-direction: column;
`;
const OptionItemArrow = styled.div``;

const OptionName = styled.p`
  color: ${(props: Color) =>
    props.selected ? "#fff" : "rgba(51, 48, 60, 0.6)"};
  margin: 0;
  font-size: 16px;
`;

const LeftSectionContent = styled.div`
  flex-direction: row;
`;

const SaveButton = styled.button`
  border: 2px solid #1585b5;
  background: #1585b5;
  padding: 0.4rem 3rem;
  color: #fff;
  width: 45%;
  &:hover {
    background: #0b5f82;
  }
  border-radius: 4px;
  font-weight: 600;
`;

const DefaultButton = styled.button`
  background: #fff;
  font-weight: 600;
  padding: 0.4rem 3rem;
  color: #1585b5;
  width: 45%;
  border: 2px solid #1585b5;
  border-radius: 4px;
`;

const ThemeSettings = () => {
  // const navigate = useNavigate();
  const { setShowLoader } = useContext(LoaderContext);
  const { selectedBook } = useContext(BookContext);
  const showIconInput = useRef<any>(null);
  const hideIconInput = useRef<any>(null);
  // const [allFormValues, setAllFormValues] = useState<any>();
  const { user } = useContext(UserContext);
  const sidebarOptions = [
    {
      id: 1,
      name: "Embedded Theme",
      sourceName: "bubble",
    },
    {
      id: 2,
      name: "iFrame Theme",
      sourceName: "iframe",
    },
    {
      id: 3,
      name: "Shared URL Theme",
      sourceName: "share",
    },
  ];
  const [selectedSidebarOption, setSelectedSidebarOption] = useState<any>(
    sidebarOptions[0]
  );
  const [leadThemeSettings, setLeadThemeSettings] = useState({
    bookId: selectedBook?._id,
    source: "bubble",
    titleBackgroundColor: "#1585b5",
    questionBackgroundColor: "#000000",
    questionTextColor: "#ffffff",
    answerBackgroundColor: "#f8f7fa",
    answerTextColor: "#33303c",
    questionTextFieldColor: "#000000",
    showEmbedChatBgColor: "#1585B5",
    hideEmbedChatBgColor: "#1585B5",
    showEmbedChatIcon:
      "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-show-icon.svg",
    hideEmbedChatIcon:
      "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-hide-icon.svg",
  });

  useEffect(() => {
    if (selectedBook && selectedBook._id)
      myAxios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/lead-theme-settings/${selectedBook?._id}/${selectedSidebarOption.sourceName}`
        )
        .then((res) => {
          // console.log("res.data.data ---", res.data.data);
          if (res.data.data) {
            setLeadThemeSettings(res.data.data);
          } else {
            setLeadThemeSettings({
              bookId: selectedBook?._id,
              source: selectedSidebarOption.sourceName,
              titleBackgroundColor: "#1585b5",
              questionBackgroundColor: "#000000",
              questionTextColor: "#ffffff",
              answerBackgroundColor: "#f8f7fa",
              answerTextColor: "#33303c",
              questionTextFieldColor: "#000000",
              showEmbedChatBgColor: "#1585B5",
              hideEmbedChatBgColor: "#1585B5",
              showEmbedChatIcon:
                "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-show-icon.svg",
              hideEmbedChatIcon:
                "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-hide-icon.svg",
            });
          }
        });
    // eslint-disable-next-line
  }, [selectedBook && selectedBook._id, selectedSidebarOption]);

  const [showIconOfEmbedChat, setShowIconOfEmbedChat] = useState<any>();
  const [hideIconOfEmbedChat, setHideIconOfEmbedChat] = useState<any>();

  const [previewOfShowIcon, setPreviewOfShowIcon] = useState<any>();
  const [previewOfhideIcon, setPreviewOfHideIcon] = useState<any>();

  const saveEmbeddedTheme = () => {
    setShowLoader(true);
    let dataToSave = {
      bookId: selectedBook?._id,
      source: selectedSidebarOption.sourceName, // "bubble",
      titleBackgroundColor: leadThemeSettings.titleBackgroundColor,
      questionBackgroundColor: leadThemeSettings.questionBackgroundColor,
      questionTextColor: leadThemeSettings.questionTextColor,
      answerBackgroundColor: leadThemeSettings.answerBackgroundColor,
      answerTextColor: leadThemeSettings.answerTextColor,
      questionTextFieldColor: leadThemeSettings.questionTextFieldColor,
      showEmbedChatBgColor: leadThemeSettings.showEmbedChatBgColor,
      hideEmbedChatBgColor: leadThemeSettings.hideEmbedChatBgColor,
    };
    // setShowLoader(true);

    let updateThemeSettingsInput: any;

    if (showIconOfEmbedChat || hideIconOfEmbedChat) {
      const data = new FormData();
      if (showIconOfEmbedChat) {
        data.append("files.showEmbedChatIcon", showIconOfEmbedChat);
      }
      if (hideIconOfEmbedChat) {
        data.append("files.hideEmbedChatIcon", hideIconOfEmbedChat);
      }
      data.append("data", JSON.stringify(dataToSave));
      updateThemeSettingsInput = data;
    } else {
      updateThemeSettingsInput = dataToSave;
      if (
        leadThemeSettings.showEmbedChatIcon ===
        "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-show-icon.svg"
      ) {
        updateThemeSettingsInput.showEmbedChatIcon =
          "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-show-icon.svg";
      }
      if (
        leadThemeSettings.hideEmbedChatIcon ===
        "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-hide-icon.svg"
      ) {
        updateThemeSettingsInput.hideEmbedChatIcon =
          "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-hide-icon.svg";
      }
    }

    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL + `/lead-theme-settings`,
        updateThemeSettingsInput
      )
      .then((response) => {
        // console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Theme settings saved successfully."
          );
          // updateBookIndex(response.data.data);
          // setShowAddNewSourcePage(false);
          setShowLoader(false);
          trackEvent({
            email: getUsersEmail(user),
            eventCategory: EventCategory.CHATBOT,
            event:
              selectedSidebarOption.sourceName === "bubble"
                ? ChatBotEvents.USER_UPDATED_LEAD_THEME_SETTINGS_FOR_BUBBLE_CHAT
                : selectedSidebarOption.sourceName === "iframe"
                ? ChatBotEvents.USER_UPDATED_LEAD_THEME_SETTINGS_FOR_IFRAME_CHAT
                : selectedSidebarOption.sourceName === "share"
                ? ChatBotEvents.USER_UPDATED_LEAD_THEME_SETTINGS_FOR_SHARE_CHAT
                : "",
            subCategory: SubCategory.EVENT,
            bookId: selectedBook?._id,
            rawData: dataToSave,
          });
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const handleShowIconChange = (image: any) => {
    setShowIconOfEmbedChat(image);
    setPreviewOfShowIcon(URL.createObjectURL(image));
  };

  const handleHideIconChange = (image: any) => {
    setHideIconOfEmbedChat(image);
    setPreviewOfHideIcon(URL.createObjectURL(image));
  };

  return (
    <>
      <ParentSection sm={12}>
        <ColumnSection>
          <ContentParent>
            <HeadingText>Chatbot Theme Settings</HeadingText>
            <Subheading>
              {/* The below form settings is common in all 3 formats like (iFrame,
              Embedded, and Shared URL) */}
            </Subheading>
            <LeftSectionContent>
              <Col sm={4}>
                <Sidebar sm={12}>
                  <OptionList>
                    {sidebarOptions &&
                      sidebarOptions.length > 0 &&
                      sidebarOptions.map((option: any) => {
                        return (
                          <OptionListItem
                            selected={selectedSidebarOption.id === option.id}
                            onClick={() => {
                              setSelectedSidebarOption(option);
                            }}
                          >
                            <OptionItemText>
                              <OptionName
                                selected={
                                  selectedSidebarOption.id === option.id
                                }
                              >
                                {option.name && option.name}
                              </OptionName>
                            </OptionItemText>
                            <OptionItemArrow>
                              <img src={SessionChevronRight} alt="select" />
                            </OptionItemArrow>
                          </OptionListItem>
                        );
                      })}
                  </OptionList>
                </Sidebar>
              </Col>
              <Col
                sm={8}
                style={{
                  border: "1px solid #eaeaea",
                  padding: "0.5rem 1rem",
                  flexDirection: "column",
                }}
              >
                <HeadingText>
                  {" "}
                  {selectedSidebarOption.sourceName === "iframe"
                    ? "iFrame"
                    : selectedSidebarOption.sourceName === "share"
                    ? "Shared URL"
                    : "Embedded"}{" "}
                  Theme Settings
                </HeadingText>

                <FieldSection>
                  <FormLabel style={{ margin: 0 }}>
                    Title Background Color
                  </FormLabel>
                  <div
                    style={{
                      border: "1px solid #EAEAEA",
                      padding: "3px",
                      borderRadius: "4px",
                      width: "130px",
                    }}
                  >
                    <ColorPicker
                      value={leadThemeSettings.titleBackgroundColor}
                      onChange={(e: any) =>
                        setLeadThemeSettings({
                          ...leadThemeSettings,
                          titleBackgroundColor: "#" + e.value,
                        })
                      }
                    />
                    <input
                      type="text"
                      value={
                        leadThemeSettings.titleBackgroundColor &&
                        leadThemeSettings.titleBackgroundColor
                      }
                      onChange={(e: any) => {
                        setLeadThemeSettings({
                          ...leadThemeSettings,
                          titleBackgroundColor: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        color: "#33303C",
                        fontSize: "16px",
                        alignSelf: "center",
                        paddingLeft: "10px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    />
                    {/* <span
                      style={{
                        color: "#33303C",
                        fontSize: "16px",
                        alignSelf: "center",
                        margin: "0px 10px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      #
                      {leadThemeSettings.titleBackgroundColor &&
                        leadThemeSettings.titleBackgroundColor}
                    </span> */}
                  </div>
                </FieldSection>

                <FieldSection>
                  <FormLabel style={{ margin: 0 }}>
                    Question Background Color
                  </FormLabel>
                  <div
                    style={{
                      border: "1px solid #EAEAEA",
                      padding: "3px",
                      borderRadius: "4px",
                      width: "130px",
                    }}
                  >
                    <ColorPicker
                      value={leadThemeSettings.questionBackgroundColor}
                      onChange={(e: any) =>
                        setLeadThemeSettings({
                          ...leadThemeSettings,
                          questionBackgroundColor: "#" + e.value,
                        })
                      }
                    />
                    <input
                      type="text"
                      value={
                        leadThemeSettings.questionBackgroundColor &&
                        leadThemeSettings.questionBackgroundColor
                      }
                      onChange={(e: any) => {
                        setLeadThemeSettings({
                          ...leadThemeSettings,
                          questionBackgroundColor: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        color: "#33303C",
                        fontSize: "16px",
                        alignSelf: "center",
                        paddingLeft: "10px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    />
                    {/* <span
                      style={{
                        color: "#33303C",
                        fontSize: "16px",
                        alignSelf: "center",
                        margin: "0px 10px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {leadThemeSettings.questionBackgroundColor &&
                        leadThemeSettings.questionBackgroundColor}
                    </span> */}
                  </div>
                </FieldSection>
                <FieldSection>
                  <FormLabel style={{ margin: 0 }}>
                    Question Text Color
                  </FormLabel>
                  <div
                    style={{
                      border: "1px solid #EAEAEA",
                      padding: "3px",
                      borderRadius: "4px",
                      width: "130px",
                    }}
                  >
                    <ColorPicker
                      value={leadThemeSettings.questionTextColor}
                      onChange={(e: any) =>
                        setLeadThemeSettings({
                          ...leadThemeSettings,
                          questionTextColor: "#" + e.value,
                        })
                      }
                    />
                    <input
                      type="text"
                      value={
                        leadThemeSettings.questionTextColor &&
                        leadThemeSettings.questionTextColor
                      }
                      onChange={(e: any) => {
                        setLeadThemeSettings({
                          ...leadThemeSettings,
                          questionTextColor: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        color: "#33303C",
                        fontSize: "16px",
                        alignSelf: "center",
                        paddingLeft: "10px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    />
                    {/* <span
                      style={{
                        color: "#33303C",
                        fontSize: "16px",
                        alignSelf: "center",
                        margin: "0px 10px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {leadThemeSettings.questionTextColor &&
                        leadThemeSettings.questionTextColor}
                    </span> */}
                  </div>
                </FieldSection>
                <FieldSection>
                  <FormLabel style={{ margin: 0 }}>
                    Answer Background Color
                  </FormLabel>
                  <div
                    style={{
                      border: "1px solid #EAEAEA",
                      padding: "3px",
                      borderRadius: "4px",
                      width: "130px",
                    }}
                  >
                    <ColorPicker
                      value={leadThemeSettings.answerBackgroundColor}
                      onChange={(e: any) =>
                        setLeadThemeSettings({
                          ...leadThemeSettings,
                          answerBackgroundColor: "#" + e.value,
                        })
                      }
                    />
                    <input
                      type="text"
                      value={
                        leadThemeSettings.answerBackgroundColor &&
                        leadThemeSettings.answerBackgroundColor
                      }
                      onChange={(e: any) => {
                        setLeadThemeSettings({
                          ...leadThemeSettings,
                          answerBackgroundColor: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        color: "#33303C",
                        fontSize: "16px",
                        alignSelf: "center",
                        paddingLeft: "10px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    />
                    {/* <span
                      style={{
                        color: "#33303C",
                        fontSize: "16px",
                        alignSelf: "center",
                        margin: "0px 10px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {leadThemeSettings.answerBackgroundColor &&
                        leadThemeSettings.answerBackgroundColor}
                    </span> */}
                  </div>
                </FieldSection>
                <FieldSection>
                  <FormLabel style={{ margin: 0 }}>Answer Text Color</FormLabel>
                  <div
                    style={{
                      border: "1px solid #EAEAEA",
                      padding: "3px",
                      borderRadius: "4px",
                      width: "130px",
                    }}
                  >
                    <ColorPicker
                      value={leadThemeSettings.answerTextColor}
                      onChange={(e: any) =>
                        setLeadThemeSettings({
                          ...leadThemeSettings,
                          answerTextColor: "#" + e.value,
                        })
                      }
                    />
                    <input
                      type="text"
                      value={
                        leadThemeSettings.answerTextColor &&
                        leadThemeSettings.answerTextColor
                      }
                      onChange={(e: any) => {
                        setLeadThemeSettings({
                          ...leadThemeSettings,
                          answerTextColor: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        color: "#33303C",
                        fontSize: "16px",
                        alignSelf: "center",
                        paddingLeft: "10px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    />
                    {/* <span
                      style={{
                        color: "#33303C",
                        fontSize: "16px",
                        alignSelf: "center",
                        margin: "0px 10px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {leadThemeSettings.answerTextColor &&
                        leadThemeSettings.answerTextColor}
                    </span> */}
                  </div>
                </FieldSection>
                <FieldSection>
                  <FormLabel style={{ margin: 0 }}>
                    Question Text Field Color
                  </FormLabel>
                  <div
                    style={{
                      border: "1px solid #EAEAEA",
                      padding: "3px",
                      borderRadius: "4px",
                      width: "130px",
                    }}
                  >
                    <ColorPicker
                      value={leadThemeSettings.questionTextFieldColor}
                      onChange={(e: any) =>
                        setLeadThemeSettings({
                          ...leadThemeSettings,
                          questionTextFieldColor: "#" + e.value,
                        })
                      }
                    />
                    <input
                      type="text"
                      value={
                        leadThemeSettings.questionTextFieldColor &&
                        leadThemeSettings.questionTextFieldColor
                      }
                      onChange={(e: any) => {
                        setLeadThemeSettings({
                          ...leadThemeSettings,
                          questionTextFieldColor: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        color: "#33303C",
                        fontSize: "16px",
                        alignSelf: "center",
                        paddingLeft: "10px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    />
                    {/* <span
                      style={{
                        color: "#33303C",
                        fontSize: "16px",
                        alignSelf: "center",
                        margin: "0px 10px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {leadThemeSettings.questionTextFieldColor &&
                        leadThemeSettings.questionTextFieldColor}
                    </span> */}
                  </div>
                </FieldSection>
                {selectedSidebarOption.sourceName === "bubble" && (
                  <>
                    <FieldSection>
                      <FormLabel style={{ margin: 0 }}>
                        Chatbot Show Icon Background Color
                      </FormLabel>
                      <div
                        style={{
                          border: "1px solid #EAEAEA",
                          padding: "3px",
                          borderRadius: "4px",
                          width: "130px",
                        }}
                      >
                        <ColorPicker
                          value={leadThemeSettings.showEmbedChatBgColor}
                          onChange={(e: any) =>
                            setLeadThemeSettings({
                              ...leadThemeSettings,
                              showEmbedChatBgColor: "#" + e.value,
                            })
                          }
                        />
                        <input
                          type="text"
                          value={
                            leadThemeSettings.showEmbedChatBgColor &&
                            leadThemeSettings.showEmbedChatBgColor
                          }
                          onChange={(e: any) => {
                            setLeadThemeSettings({
                              ...leadThemeSettings,
                              showEmbedChatBgColor: e.target.value,
                            });
                          }}
                          style={{
                            width: "100%",
                            color: "#33303C",
                            fontSize: "16px",
                            alignSelf: "center",
                            paddingLeft: "10px",
                            fontWeight: "600",
                            textTransform: "uppercase",
                          }}
                        />
                      </div>
                    </FieldSection>
                    <FieldSection>
                      <FormLabel style={{ margin: 0 }}>
                        Chatbot Hide Icon Background Color
                      </FormLabel>
                      <div
                        style={{
                          border: "1px solid #EAEAEA",
                          padding: "3px",
                          borderRadius: "4px",
                          width: "130px",
                        }}
                      >
                        <ColorPicker
                          value={leadThemeSettings.hideEmbedChatBgColor}
                          onChange={(e: any) =>
                            setLeadThemeSettings({
                              ...leadThemeSettings,
                              hideEmbedChatBgColor: "#" + e.value,
                            })
                          }
                        />
                        <input
                          type="text"
                          value={
                            leadThemeSettings.hideEmbedChatBgColor &&
                            leadThemeSettings.hideEmbedChatBgColor
                          }
                          onChange={(e: any) => {
                            setLeadThemeSettings({
                              ...leadThemeSettings,
                              hideEmbedChatBgColor: e.target.value,
                            });
                          }}
                          style={{
                            width: "100%",
                            color: "#33303C",
                            fontSize: "16px",
                            alignSelf: "center",
                            paddingLeft: "10px",
                            fontWeight: "600",
                            textTransform: "uppercase",
                          }}
                        />
                      </div>
                    </FieldSection>
                    <FieldSection>
                      <FormLabel style={{ margin: 0 }}>
                        Chatbot Show Icon
                        <br />
                        <span style={{ fontSize: "12px" }}>
                          suggested size of image 30*30 or ratio 1:1
                          <br />
                          {showIconOfEmbedChat && (
                            <>
                              {showIconOfEmbedChat.name}
                              <img
                                src={DeleteIcon}
                                alt="delete"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "0.5rem",
                                }}
                                width={16}
                                onClick={() => {
                                  setShowIconOfEmbedChat(undefined);
                                  setPreviewOfShowIcon(
                                    leadThemeSettings.showEmbedChatIcon
                                  );
                                  if (showIconInput.current) {
                                    showIconInput.current.value = "";
                                  }
                                }}
                              />
                            </>
                          )}
                        </span>
                      </FormLabel>
                      <div
                        style={{
                          border: "1px solid #EAEAEA",
                          padding: "3px",
                          borderRadius: "4px",
                          width: "130px",
                        }}
                      >
                        <label
                          htmlFor="showIconID"
                          style={{
                            background: "lightgrey",
                            padding: "5px",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "11px",
                          }}
                        >
                          Browse file
                        </label>
                        <input
                          type="file"
                          id="showIconID"
                          ref={showIconInput}
                          accept="image/png, image/svg"
                          onChange={(e: any) => {
                            handleShowIconChange(e.target.files[0]);
                          }}
                          hidden
                        />
                      </div>
                    </FieldSection>
                    <FieldSection>
                      <FormLabel style={{ margin: 0 }}>
                        Chatbot Hide Icon
                        <br />
                        <span style={{ fontSize: "12px" }}>
                          suggested size of image 30*30 or ratio 1:1
                          <br />
                          {hideIconOfEmbedChat && (
                            <>
                              {hideIconOfEmbedChat.name}
                              <img
                                src={DeleteIcon}
                                alt="delete"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "0.5rem",
                                }}
                                width={16}
                                onClick={() => {
                                  setHideIconOfEmbedChat(undefined);
                                  setPreviewOfHideIcon(
                                    leadThemeSettings.hideEmbedChatIcon
                                  );
                                  if (hideIconInput.current) {
                                    hideIconInput.current.value = "";
                                  }
                                }}
                              />
                            </>
                          )}
                        </span>
                      </FormLabel>
                      <div
                        style={{
                          border: "1px solid #EAEAEA",
                          padding: "3px",
                          borderRadius: "4px",
                          width: "130px",
                        }}
                      >
                        <label
                          htmlFor="hideIconID"
                          style={{
                            background: "lightgrey",
                            padding: "5px",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "11px",
                          }}
                        >
                          Browse file
                        </label>
                        <input
                          type="file"
                          id="hideIconID"
                          ref={hideIconInput}
                          accept="image/png, image/svg"
                          onChange={(e: any) => {
                            handleHideIconChange(e.target.files[0]);
                          }}
                          hidden
                        />
                      </div>
                    </FieldSection>
                  </>
                )}
                <FieldSection>
                  <SaveButton type="button" onClick={() => saveEmbeddedTheme()}>
                    SAVE
                  </SaveButton>
                  <DefaultButton
                    type="button"
                    onClick={() => {
                      console.log(
                        "showIconInput.current.value is, ",
                        showIconInput.current.value
                      );
                      setLeadThemeSettings({
                        bookId: selectedBook?._id,
                        source: selectedSidebarOption.sourceName,
                        titleBackgroundColor: "#1585b5",
                        questionBackgroundColor: "#000000",
                        questionTextColor: "#ffffff",
                        answerBackgroundColor: "#f8f7fa",
                        answerTextColor: "#33303c",
                        questionTextFieldColor: "#000000",
                        showEmbedChatBgColor: "#1585B5",
                        hideEmbedChatBgColor: "#1585B5",
                        showEmbedChatIcon:
                          "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-show-icon.svg",
                        hideEmbedChatIcon:
                          "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-hide-icon.svg",
                      });
                      setHideIconOfEmbedChat(undefined);
                      setPreviewOfHideIcon(leadThemeSettings.hideEmbedChatIcon);
                      if (hideIconInput.current) {
                        hideIconInput.current.value = "";
                      }
                      setShowIconOfEmbedChat(undefined);
                      setPreviewOfShowIcon(leadThemeSettings.showEmbedChatIcon);
                      if (showIconInput.current) {
                        showIconInput.current.value = "";
                      }
                    }}
                  >
                    USE DEFAULT
                  </DefaultButton>
                </FieldSection>
              </Col>
            </LeftSectionContent>
          </ContentParent>
        </ColumnSection>
        <ColumnSection>
          <PreviewParent>
            <ThemeBubbleChatProtoType
              titleBackgroundColor={leadThemeSettings.titleBackgroundColor}
              questionBackgroundColor={
                leadThemeSettings.questionBackgroundColor
              }
              questionTextColor={leadThemeSettings.questionTextColor}
              answerBackgroundColor={leadThemeSettings.answerBackgroundColor}
              answerTextColor={leadThemeSettings.answerTextColor}
              questionTextFieldColor={leadThemeSettings.questionTextFieldColor}
              source={selectedSidebarOption.sourceName}
              showEmbedChatBgColor={leadThemeSettings.showEmbedChatBgColor}
              hideEmbedChatBgColor={leadThemeSettings.hideEmbedChatBgColor}
              showEmbedChatIcon={leadThemeSettings.showEmbedChatIcon}
              hideEmbedChatIcon={leadThemeSettings.hideEmbedChatIcon}
              previewOfShowIcon={previewOfShowIcon}
              previewOfHideIcon={previewOfhideIcon}
            />
          </PreviewParent>
        </ColumnSection>
      </ParentSection>
    </>
  );
};

export default ThemeSettings;
