import React, { useContext } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import LoaderGif from "../images/loader.gif";
import { LoaderContext } from "../context/loader-context";

export const StatusLoaderSection = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99999;
  background: #0001;
`;

export const WaitTitle = styled.p`
  text-align: center;
  font-size: 24px;
  color: #4f83b5;
  font-weight: bold;
`;

const AppLoader = () => {
  const { showLoader } = useContext(LoaderContext);

  return (
    <>
      {showLoader && (
        <StatusLoaderSection>
          <img src={LoaderGif} alt="loading..." width={60} />
          <WaitTitle>Please Wait</WaitTitle>
        </StatusLoaderSection>
      )}
    </>
  );
};

export default AppLoader;
