import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import ArrowIcon from "../../images/akar-icons_send1.svg";
import DropChatIcon from "../../images/dropchat-color-icon.svg";
import MonthIcon from "../../images/formkit_month1.svg";
import WeekIcon from "../../images/formkit_week1.svg";
import SampleCountIcon from "../../images/sample-count-icon.svg";
import ThumbsUpIcon from "../../images/thumb-up1.svg";
import ThumbsDownIcon from "../../images/thump-down.svg";
import myAxios from "../../myaxios";

import { LARGE, MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
declare var _cio: any;
const BoxStyle = styled.div`
  width: 350px;
  height: 120px;
  top: 303px;
  left: 42px;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  background-color: white;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  padding: 1rem;
  justify-content: center;
  gap: 6px;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.contentWidth}%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.contentWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.contentWidth}%;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.contentWidth}%;
  }
`;
const BoldHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 12px 5px;
`;
const ParentRow = styled.div`
  padding: 1rem;
  padding-top: 0;
  flex-direction: column;
`;

const InnersectionSpan = styled.span`
  color: #33303c;
  font-weight: 600;
  font-size: 23px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.contentWidth}%;
    font-size: 13px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.contentWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.contentWidth}%;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.contentWidth}%;
  }
`;
const InnersectionText = styled.span`
  color: #33303c;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-family: "Poppins", sans-serif;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.contentWidth}%;
    font-size: 11px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.contentWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.contentWidth}%;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.contentWidth}%;
  }
`;

const InnersectionPara = styled.span`
  color: #797979;
  // font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
// overflow: hidden;
// text-overflow: ellipsis;
// white-space: nowrap;

const InnersectionDate = styled.span`
  color: #797979;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins", sans-serif;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.contentWidth}%;
    font-size: 11px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.contentWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.contentWidth}%;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.contentWidth}%;
  }
`;

const ImageTag = styled.img`
  width: 26px;
  margin-right: 10px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 20px;
  }
`;
const InnerDiv = styled.div`
  display: flex;
`;
const FirstMainRow = styled(Row)`
  /* gap: 10px 0px; */
  gap: 20px 0px;
`;
const SecondMainRow = styled(Row)`
  gap: 10px 0px;
  margin-top: 5vh;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 2vh;
  }
`;

const formatDate = (date: string | number | Date) => {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
  };

  return new Date(date).toLocaleDateString(undefined, options);
};

const AnalyticsArea = ({ selectedBook }: any) => {
  const [analyticsData, setAnalyticsData] = useState<any>();
  //const [questionWithMaxCount, setQuestionWithMaxCount] = useState<string>();
  useEffect(() => {
    myAxios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/chat-session-analytics/${selectedBook?._id}`
      )
      .then((res: any) => {
        if (res.data.data) {
          setAnalyticsData(res.data.data);
          // setQuestionWithMaxCount(res.data.data.maxCountQuestion.question);
        }
      });
  }, []);

  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  return (
    <ParentRow>
      <div>
        <BoldHeader>Overview</BoldHeader>
      </div>
      <FirstMainRow>
        <Col xs={6} sm={6} md={3}>
          <BoxStyle>
            <InnerDiv>
              <ImageTag src={DropChatIcon} alt="analytics" />{" "}
              <InnersectionSpan>
                {" "}
                {analyticsData?.totalChatSession}
              </InnersectionSpan>
            </InnerDiv>
            <InnersectionText>Total Chat Sessions</InnersectionText>
          </BoxStyle>
        </Col>
        <Col xs={6} sm={6} md={3}>
          <BoxStyle className="box">
            <InnerDiv>
              <ImageTag src={MonthIcon} alt="analytics" />{" "}
              <InnersectionSpan>
                {" "}
                {analyticsData?.oneMonthMsgCount}
              </InnersectionSpan>
            </InnerDiv>
            <InnersectionText>Total message last month</InnersectionText>
            <InnersectionDate>
              {formatDate(oneMonthAgo)} - Present
            </InnersectionDate>
          </BoxStyle>
        </Col>
        <Col xs={6} sm={6} md={3}>
          <BoxStyle className="box">
            <InnerDiv>
              <ImageTag src={WeekIcon} alt="analytics" />{" "}
              <InnersectionSpan>
                {analyticsData?.oneWeekMsgCount}
              </InnersectionSpan>
            </InnerDiv>
            <InnersectionText>Total message last week</InnersectionText>
            <InnersectionDate>
              {formatDate(oneWeekAgo)} - Present
            </InnersectionDate>
          </BoxStyle>
        </Col>
        <Col xs={6} sm={6} md={3}>
          <BoxStyle className="box">
            <InnerDiv>
              <ImageTag src={ArrowIcon} alt="analytics" />{" "}
              <InnersectionSpan>
                {" "}
                {analyticsData?.avgPerSessionChat}
              </InnersectionSpan>
            </InnerDiv>
            <InnersectionText>
              Average No. of message per chat session
            </InnersectionText>
          </BoxStyle>
        </Col>
        <Col xs={6} sm={6} md={3}>
          <BoxStyle>
            <InnerDiv>
              <ImageTag src={ThumbsUpIcon} alt="analytics" />{" "}
              <InnersectionSpan>
                {" "}
                {analyticsData?.totalGoodChat}
              </InnersectionSpan>
            </InnerDiv>
            <InnersectionText>Number of good chat sessions</InnersectionText>
          </BoxStyle>
        </Col>
        <Col xs={6} sm={6} md={3}>
          <BoxStyle className="box">
            <InnerDiv>
              <ImageTag src={ThumbsDownIcon} alt="analytics" />{" "}
              <InnersectionSpan>
                {" "}
                {analyticsData?.totalBadChat}
              </InnersectionSpan>
            </InnerDiv>
            <InnersectionText>Number of bad chat sessions</InnersectionText>
          </BoxStyle>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <BoxStyle className="box">
            <InnerDiv>
              <ImageTag src={SampleCountIcon} alt="analytics" />{" "}
              <InnersectionSpan>
                {analyticsData &&
                analyticsData.suggestQuestion &&
                analyticsData.suggestQuestion.count
                  ? analyticsData.suggestQuestion.count
                  : 0}
              </InnersectionSpan>
            </InnerDiv>
            <InnersectionPara>
              {analyticsData &&
              analyticsData.suggestQuestion &&
              analyticsData.suggestQuestion.question
                ? analyticsData.suggestQuestion.question
                : "No data available"}
            </InnersectionPara>
            <InnersectionText>
              Top sample question asked by users
            </InnersectionText>
          </BoxStyle>
        </Col>
        <Col xs={6} sm={6} md={3}></Col>
      </FirstMainRow>
    </ParentRow>
  );
};

export default AnalyticsArea;
