import axios from "axios";
import { Field, Formik } from "formik";
import { Chip } from "primereact/chip";
import { useContext, useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { DEVICE_MEDIUM, MOBILE } from "../../constants/breakpoints";
import { BookContext } from "../../context/BookContext";
import { PublicContext } from "../../context/PublicContext";
import { UserContext } from "../../context/UserContext";
import { LoaderContext } from "../../context/loader-context";
import { NotesContext } from "../../context/notes-context";
import { PlanContext } from "../../context/plan-context";
import {
  USER_ASK_BOOK_A_QUESTION,
  USER_GOES_ABOVE_TOKEN_COUNT,
} from "../../customer-io/events";
import { ChatSource } from "../../enums";
import {
  ChatBotEvents,
  EventCategory,
  Source,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import AddCategoryIcon from "../../images/add-category-icon.svg";
import SearchArrow from "../../images/chat-arrow.svg";
import ChatBotIcon from "../../images/chat-bot-icon.svg";
import UserImage from "../../images/chat-user.svg";
import ChatInterfaceIcon from "../../images/dropchat-icon-small.svg";
import ExistingCategoryIcon from "../../images/existing-category-icon.svg";
import IconHistory from "../../images/icon-history.svg";
import LoaderGif from "../../images/loader.gif";
import MessageInterfaceIcon from "../../images/message-interface-icon.svg";
import ShowSourceIcon from "../../images/show-source-icon.svg";
import TypingLoading from "../../images/typingLoading.gif";
import WhiteChatInterfaceIcon from "../../images/white-dropchat-icon-small.svg";
import WhiteMessageInterfaceIcon from "../../images/white-message-interface-icon.svg";
import { Mixpanel } from "../../mixpanel";
import myAxios from "../../myaxios";
import useDebounce from "../../use-debounce";
import {
  ADD_FLASHCARD_CATAGORY,
  CHATBOT_PROMPT,
} from "../../utils/class-names";
import CustomMarkdown from "../../utils/custom-markdown";
import {
  isTestUser,
  numberWithComma,
  parseErrorResponse,
} from "../../utils/utils";
import AddCategoryPopup from "../utility-component/add-category-popup";
import AnswerSourcePopup from "../utility-component/answer-source-popup";
import ConfirmationBox from "../utility-component/confirmation-box";
import ExistingCategoryPopup from "../utility-component/existing-category-popup";
import { Chat } from "./chat";
import MobileQuestionsSideBar from "./mobile-questions-sidebar";
import QuestionsSideBar from "./old-questions-sidebar";
import * as BookChatStyles from "./styled";
declare var _cio: any;

const UpgradeText = styled.p`
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 10px;
    margin-top: 0.5rem;
  }
`;

const ChatArea = ({fromChatbotBuilder}:any) => {
  const { innerWidth: width } = window;
  const isMobileWidth = width < 1000;
  const { user } = useContext(UserContext);
  const { setShowLoader } = useContext(LoaderContext);
  const { deviceWidth } = useContext(PublicContext);
  const {
    selectedBook,
    setShowAnswerSourcePopup,
    setSelectedBook,
    updateBookInterfaceType,
  } = useContext(BookContext);
  const { notesCategories, addNotesCategory } = useContext(NotesContext);

  const [currentSessionHistory, setCurrentSessionHistory] = useState<any[]>([]);
  const [allSessionHistory, setAllSessionHistory] = useState<any[]>([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [allAnswerSource, setAllAnswerSource] = useState([]);
  const [questionsData, setQuestionsData] = useState<any>([]);
  const [notesCategoryData, setNotesCategoryData] = useState<any>([]);
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");
  const [confirmationAction, setConfirmationAction] = useState<string>("");

  const navigate = useNavigate();

  const [questionValue, setQuestionValue] = useState("");
  const [formRef, setFormRef] = useState<any>();

  const [chatInterfaceSetting, setChatInterfaceSetting] = useState<any>(
    selectedBook?.interfaceType
  );

  const [showhistoryPopup, setShowHistoryPopup] = useState(false);

  const [showAddCategoryPopup, setShowAddCategoryPopup] =
    useState<boolean>(false);

  const { bookId } = useParams();

  const [activeCharacterCountDiff, setActiveCharacterCountDiff] = useState(0);
  const { setPlanHighliter, activePlan, setActivePlan } =
    useContext(PlanContext);
  const [characterToShowUpgrade] = useState(5);

  const [showOverlay, setShowOverlay] = useState(false);

  const [showExistingCategoryPopup, setShowExistingCategoryPopup] =
    useState(false);

  const [fileProcessingMessage, setFileProcessingMessage] =
    useState<string>("");

  const op = useRef<any>(null);
  const [isResponseOn, setIsResponseOn] = useState<boolean>(false);
  const validatePropmt = (prompt: string) => {
    let error;
    if (!prompt) {
      error = "This is Required!";
    }
    return error;
  };

  useEffect(() => {
    if (bookId && !selectedBook) {
      //updateSelectedBook(bookId);
      setLoading(true);
      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/books/book/" + bookId)
        .then((response) => {
          if (response && response.data) {
            setSelectedBook(response.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Getting error ", error);
          setLoading(false);
        });
    }
  }, [bookId, selectedBook, setSelectedBook]);

  const validateNotesCategory = (formData: any) => {
    const errors: any = {};

    if (!formData.categoryName) {
      errors.categoryName = "Flashcards category name required!";
    }

    if (formData.categoryName && formData.categoryName.length < 3) {
      errors.categoryName =
        "Invalid flashcards category name, minimum 3 charecter required!";
    }

    return errors;
  };

  const createNotesCategory = (values: any, { resetForm }: any) => {
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/notes-categories", {
        name: values.categoryName,
      })
      .then((response) => {
        if (response.data.success) {
          if (!isTestUser(user?.email))
            Mixpanel.track("Flashcard category created from chat page", {
              bookId: selectedBook?._id ? selectedBook?._id : selectedBook?.id,
              bookTitle: selectedBook?.title,
              bookAuthors: selectedBook?.authors,
              categoryName: values.categoryName,
              email: user?.email,
            });
          trackEvent({
            email: getUsersEmail(user),
            eventCategory: EventCategory.CHATBOT,
            event:
              ChatBotEvents.USER_ADDED_QUESTION_TO_NEW_FLASHCARD_CATEGORY_FROM_CHAT_PAGE,
            subCategory: SubCategory.EVENT,
            bookId: selectedBook?._id,
            rawData: {
              bookId: selectedBook?._id ? selectedBook?._id : selectedBook?.id,
              bookTitle: selectedBook?.title,
              bookAuthors: selectedBook?.authors,
              categoryName: values.categoryName,
            },
          });
          addNotesCategory(response.data.data);
          // NotificationManager.success(
          //   "Flashcards category added successfully!"
          // );
          resetForm({ values: "" });
          setShowAddCategoryPopup(false);
          const currentQuestion = questionsData;
          const categoryId = response.data.data._id;
          createNotes(currentQuestion, categoryId);
          try {
            op.current.toggle();
          } catch (error) {
            // console.error(
            //   "Getting error at the time of close create flashcards category form --- ",
            //   error
            // );
          }
        } else
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Not able to create flashcards category, Please try again!"
          );
        resetForm({ values: "" });

        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
        resetForm({ values: "" });
        // console.error(
        //   "Getting error at the time of create flashcards category --- ",
        //   error
        // );
      });
  };

  const createNotes = (question: any, notesCategoryId: any) => {
    setShowLoader(true);
    if (!isTestUser(user?.email))
      Mixpanel.track("Message saved into flashcard category", {
        bookId: selectedBook?._id ? selectedBook?._id : selectedBook?.id,
        bookTitle: selectedBook?.title,
        bookAuthors: selectedBook?.authors,
        question: question,
        notesCategoryId: notesCategoryId,
        email: user?.email,
      });
    trackEvent({
      email: getUsersEmail(user),
      eventCategory: EventCategory.CHATBOT,
      event: ChatBotEvents.USER_ADDED_QUESTION_TO_EXISTING_FLASHCARD_CATEGORY,
      subCategory: SubCategory.EVENT,
      bookId: selectedBook?._id,
      rawData: {
        bookId: selectedBook?._id ? selectedBook?._id : selectedBook?.id,
        bookTitle: selectedBook?.title,
        bookAuthors: selectedBook?.authors,
        question: question.question,
        notesCategoryId: notesCategoryId,
      },
    });
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/notes", {
        question: question.question,
        answer: question.answer,
        bookId: selectedBook?._id,
        questionId: question._id,
        notesCategoryId,
      })
      .then((response) => {
        if (response.data.success) {
          // console.log("response.data.data --- ", response.data.data);
          if (response.data.data) {
            let questions = [...currentSessionHistory];
            let allHistory = [...allSessionHistory];

            const index = questions.findIndex(
              (que) => que._id === question._id
            );

            const historyIndex = allHistory.findIndex(
              (que) => que._id === question._id
            );

            if (index > -1) {
              questions[index].notesCategories =
                response.data.data.notesCategories;
              questions[index].notes = response.data.data.notes;
            }
            if (historyIndex > -1) {
              allHistory[historyIndex].notesCategories =
                response.data.data.notesCategories;
              allHistory[historyIndex].notes = response.data.data.notes;
            }

            setCurrentSessionHistory(questions);
            setAllSessionHistory(allHistory);
          }
          setShowExistingCategoryPopup(false);

          NotificationManager.success("Flashcards saved successfully!");
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Not able to save flashcards, Please try again!"
          );
          setShowExistingCategoryPopup(false);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
        // console.error(
        //   "Getting error at the time of create flashcards --- ",
        //   error
        // );
      });
  };

  const removeCategory = (noteCategoryId: string, questionID: string) => {
    trackEvent({
      email: getUsersEmail(user),
      eventCategory: EventCategory.CHATBOT,
      event:
        ChatBotEvents.USER_REMOVED_QUESTION_FROM_FLASHCARD_CATEGORY_FROM_CHAT_PAGE,
      subCategory: SubCategory.EVENT,
      bookId: selectedBook?._id,
      rawData: {
        bookId: selectedBook?._id ? selectedBook?._id : selectedBook?.id,
        bookTitle: selectedBook?.title,
        bookAuthors: selectedBook?.authors,
        questionID: questionID,
        notesCategoryId: noteCategoryId,
      },
    });
    setShowLoader(true);
    myAxios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/delete-notes/${noteCategoryId}/${questionID}`
      )
      .then((response) => {
        if (response.data && response.data.success) {
          NotificationManager.success(
            "Flashcards category has been removed successfully"
          );
          let questions = [...currentSessionHistory];

          const index = questions.findIndex((que) => que._id === questionID);
          if (index > -1) {
            /* const filteredCategory = questions[index].notesCategories.filter(
              (category: any) => category.id !== noteCategoryId
            ); */
            questions[index].notesCategories =
              response.data.data.notesCategories;
            questions[index].notes = response.data.data.notes;
          }
          setCurrentSessionHistory(questions);
        } else {
          NotificationManager.error(
            "Not able to delete flashcards category, Please try again!"
          );
        }
        setShowLoader(false);
      })
      .catch((error) => {
        // console.error(
        //   "Gettings error at the time of updating status of chatbot ",
        //   error
        // );
        NotificationManager.error(parseErrorResponse(error));
        setShowLoader(false);
      });
  };

  useDebounce(
    () => {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL + "/public/getFileProcessingMessage"
        )
        .then((response) => {
          if (response && response.data) {
            setFileProcessingMessage(response.data.data);
          }
        });
    },
    [],
    1000
  );

  useEffect(() => {
    if (user && selectedBook) {
      setShowLoader(true);
      myAxios
        .get(
          process.env.REACT_APP_SERVER_URL +
            "/get-history-of-chats/" +
            user?.id +
            "/" +
            selectedBook?._id
        )
        .then((response) => {
          if (response.data) {
            setAllSessionHistory(response.data.data.allSessionHistory);
            setCurrentSessionHistory(response.data.data.currentSessionHistory);
          }
          setShowLoader(false);
        })
        .catch((error) => {
          // console.error(
          //   "Getting error at the time of get chat history ",
          //   error
          // );
          setShowLoader(false);
        });
    }
    // eslint-disable-next-line
  }, [setAllSessionHistory, selectedBook, selectedBook?.id, user]);

  useEffect(() => {
    if (user) {
      // setActivePlan(user && user?.currentSubscription);
      myAxios.get(`${process.env.REACT_APP_SERVER_URL}/plans`).then((res) => {
        // console.log("res.data.data ---", res.data.data);
        if (res.data.data) {
          // updateUserSubscription(res.data.data.currentSubscription);
          setActivePlan(res.data.data.currentSubscription);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    setActiveCharacterCountDiff(
      (user?.currentSubscription?.meta?.messageCount
        ? user?.currentSubscription?.meta?.messageCount
        : 0) -
        (activePlan?.planStatics.usedMessage
          ? activePlan?.planStatics.usedMessage
          : 0)
    );
  }, [activePlan, user]);

  const getFullAnswer = async (id: string) => {
    myAxios
      .get(`${process.env.REACT_APP_SERVER_URL}/questions-and-answers/${id}`)
      .then((res: any) => {
        setIsResponseOn(false);
        // console.log("res.data.data ---", res.data.data);
        if (res.data.data) {
          // updateUserSubscription(res.data.data.currentSubscription);
          //setActivePlan(res.data.data.currentSubscription);

          const data = res.data.data;

          setCurrentSessionHistory((oldQuestions: any[]) => {
            if (oldQuestions.length > 0) {
              oldQuestions[oldQuestions.length - 1]._id = data._id;
              oldQuestions[oldQuestions.length - 1].id = data.id;
              oldQuestions[oldQuestions.length - 1].answer = data.answer;
              oldQuestions[oldQuestions.length - 1].source = data.source;
              oldQuestions[oldQuestions.length - 1].notes = data.notes;
              oldQuestions[oldQuestions.length - 1].notesCategories =
                data.notesCategories;
              return [...oldQuestions];
            } else return [];
          });
          setAllSessionHistory((oldQuestions: any[]) => {
            if (oldQuestions.length > 0) {
              oldQuestions[oldQuestions.length - 1]._id = data._id;
              oldQuestions[oldQuestions.length - 1].id = data.id;
              oldQuestions[oldQuestions.length - 1].answer = data.answer;
              oldQuestions[oldQuestions.length - 1].source = data.source;
              oldQuestions[oldQuestions.length - 1].notes = data.notes;
              oldQuestions[oldQuestions.length - 1].notesCategories =
                data.notesCategories;
              return [...oldQuestions];
            } else return [];
          });
        }

        myAxios.get(`${process.env.REACT_APP_SERVER_URL}/plans`).then((res) => {
          if (res.data.data) {
            // updateUserSubscription(res.data.data.currentSubscription);
            setActivePlan(res.data.data.currentSubscription);
          }
        });
        setShowOverlay(false);
      })
      .catch((error) => {
        setShowOverlay(false);
        setIsResponseOn(false);
      });
  };

  const chatWithDropchat = async (userQuery: string) => {
    setShowOverlay(true);
    setCurrentSessionHistory(
      currentSessionHistory
        ? [...currentSessionHistory, { question: userQuery, answer: "" }]
        : [{ question: userQuery, answer: "" }]
    );
    setAllSessionHistory(
      allSessionHistory
        ? [...allSessionHistory, { question: userQuery, answer: "" }]
        : [{ question: userQuery, answer: "" }]
    );
    trackEvent({
      email: getUsersEmail(user),
      eventCategory: EventCategory.CHATBOT,
      event: ChatBotEvents.USER_ASKED_QUESTION_ON_CHATBOT,
      subCategory: SubCategory.EVENT,
      source: Source.CHAT,
      rawData: {
        question: userQuery,
      },
      bookId: bookId,
    });
    if (!isTestUser(user?.email))
      Mixpanel.track("Loggedin User Try to Chat", {
        bookId: selectedBook?._id ? selectedBook?._id : selectedBook?.id,
        bookTitle: selectedBook?.title,
        bookAuthors: selectedBook?.authors,
        question: userQuery,
        email: user?.email,
      });

    // Use the response body as a stream

    const requestBody = {
      question: userQuery,
      bookId: selectedBook?._id,
      source: ChatSource.chatbot,
    };

    const chat = new Chat(
      requestBody,
      (token: string) => {
        setCurrentSessionHistory((oldQuestions: any[]) => {
          if (oldQuestions.length > 0) {
            oldQuestions[oldQuestions.length - 1].answer = oldQuestions[
              oldQuestions.length - 1
            ].answer
              ? oldQuestions[oldQuestions.length - 1].answer + token
              : token;
            return [...oldQuestions];
          } else return [];
        });
        setAllSessionHistory((oldQuestions: any[]) => {
          if (oldQuestions.length > 0) {
            oldQuestions[oldQuestions.length - 1].answer = oldQuestions[
              oldQuestions.length - 1
            ].answer
              ? oldQuestions[oldQuestions.length - 1].answer + token
              : token;
            return [...oldQuestions];
          } else return [];
        });
      },
      (data: any) => {
        getFullAnswer(data);
      },
      (data: any) => {
        setIsResponseOn(false);
        NotificationManager.error(data);
      },
      user?.jwt
    );

    await chat.startChat();
  };

  const filteredCategoryList = (categories: any, removedCategories: any) => {
    if (removedCategories) {
      for (var i = categories.length - 1; i >= 0; i--) {
        for (var j = 0; j < removedCategories.length; j++) {
          if (
            categories[i] &&
            (categories[i].name === removedCategories[j].name ||
              categories[i].type === "default")
          ) {
            categories.splice(i, 1);
          }
        }
      }
    }

    return categories;
  };

  const submitQuestion = (values: any, actions: any) => {
    actions.resetForm({
      values: {
        prompt: "",
      },
    });
    // Need to check if user is able to ask question

    setShowLoader(true);
    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "/check-if-question-charactor-limit-remain",
        { characterLength: values.prompt.length }
      )
      .then((response) => {
        setShowLoader(false);
        if (response && response.data && response.data.success) {
          if (!isTestUser(user?.email))
            _cio.track(USER_ASK_BOOK_A_QUESTION, {
              name: "user-asks-book-a-question",
            });
          chatWithDropchat(values.prompt);
        } else {
          if (!isTestUser(user?.email))
            _cio.track(USER_GOES_ABOVE_TOKEN_COUNT, {
              name: "user-goes-above-token-count-configured-in-admin-settings",
            });
          setIsResponseOn(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Not able to get result, please try again later"
          );
        }
      })
      .catch((error) => {
        // handle error
        setIsResponseOn(false);
        setShowLoader(false);
        // console.log(
        //   "Gettings error  at the time to check token limit status --- ",
        //   error
        // );

        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const checkSourceIndexStatus = (selectedBook: any) => {
    if (selectedBook && selectedBook.indexStatus === 2) {
      return false;
    } else if (
      selectedBook &&
      selectedBook.sources &&
      selectedBook.sources.length > 0
    ) {
      selectedBook.sources.forEach((source: any) => {
        if (source.indexStatus === 2) return false;
      });
    } else return true;
  };

  const updateInterfaceType = (interfaceType: string) => {
    // console.log("interfaceType is, ", interfaceType);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/books/interfaceType", {
        bookId: selectedBook?._id,
        interfaceType,
      })
      .then((response) => {
        if (response && response.data.success && response.data.data) {
          setChatInterfaceSetting(response.data.data.interfaceType);
          updateBookInterfaceType(
            response.data.data.id,
            response.data.data.interfaceType
          );
        }
      })
      .catch((error) => {
        // handle error
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  useEffect(() => {
    // console.log("useEffect Called, ", chatInterfaceSetting);
    if (selectedBook && selectedBook.interfaceType) {
      setChatInterfaceSetting(selectedBook.interfaceType);
    } else {
      setChatInterfaceSetting("chat");
    }
    // eslint-disable-next-line
  }, [selectedBook]);

  const confirmClick = (action: string) => {
    if (action === "deleteCategory") {
      removeCategory(
        notesCategoryData.notesCategory._id,
        notesCategoryData.data._id
      );
      setShowUtilityConfirmationBox(false);
    }
  };
  const [newData, setNewData] = useState<any>();
  return (
    <>
      {deviceWidth === DEVICE_MEDIUM && (
        <QuestionsSideBar
        fromChatbotBuilder={fromChatbotBuilder}
          questionAnswerHistory={allSessionHistory}
          setCurrentSessionHistory={setCurrentSessionHistory}
        />
      )}
      <BookChatStyles.RightColumn sm={12} md={12} lg={10} xs={12}>
        <BookChatStyles.ChatInputBox sm={12} xs={12}>
          <BookChatStyles.RightContentRow fromChatbotBuilder={fromChatbotBuilder}>
            <BookChatStyles.ChatBotContent sm={12} xs={12}>
              {deviceWidth === DEVICE_MEDIUM ? (
                <>
                  <BookChatStyles.InterfaceToggle>
                    <BookChatStyles.ChatInterface
                      isChat={chatInterfaceSetting === "chat"}
                      onClick={() => {
                        updateInterfaceType("chat");
                        setChatInterfaceSetting("chat");
                      }}
                    >
                      <img
                        src={
                          chatInterfaceSetting === "chat"
                            ? WhiteChatInterfaceIcon
                            : ChatInterfaceIcon
                        }
                        alt="chatInterface"
                      />
                    </BookChatStyles.ChatInterface>
                    <BookChatStyles.MessageInterface
                      isChat={chatInterfaceSetting !== "chat"}
                      onClick={() => {
                        updateInterfaceType("message");
                        setChatInterfaceSetting("message");
                      }}
                    >
                      <img
                        src={
                          chatInterfaceSetting !== "chat"
                            ? WhiteMessageInterfaceIcon
                            : MessageInterfaceIcon
                        }
                        alt="messageInterface"
                      />
                    </BookChatStyles.MessageInterface>
                  </BookChatStyles.InterfaceToggle>
                </>
              ) : (
                <BookChatStyles.ChatHeader style={{ marginTop: "6px" }}>
                  <BookChatStyles.ChatHistorySection>
                    <img
                      src={IconHistory}
                      alt="historyIcon"
                      onClick={() => {
                        setShowHistoryPopup(true);
                      }}
                    />{" "}
                    Chatbot
                  </BookChatStyles.ChatHistorySection>
                  {showhistoryPopup && (
                    <BookChatStyles.ChatHistoryPopupUI>
                      <MobileQuestionsSideBar
                        questionAnswerHistory={allSessionHistory}
                        setCurrentSessionHistory={setCurrentSessionHistory}
                        setShowHistoryPopup={setShowHistoryPopup}
                      />
                    </BookChatStyles.ChatHistoryPopupUI>
                  )}
                  <BookChatStyles.InterfaceToggle>
                    <BookChatStyles.ChatInterface
                      isChat={chatInterfaceSetting === "chat"}
                      onClick={() => {
                        updateInterfaceType("chat");
                        setChatInterfaceSetting("chat");
                      }}
                    >
                      <img
                        src={
                          chatInterfaceSetting === "chat"
                            ? WhiteChatInterfaceIcon
                            : ChatInterfaceIcon
                        }
                        alt="chatInterface"
                      />
                    </BookChatStyles.ChatInterface>
                    <BookChatStyles.MessageInterface
                      isChat={chatInterfaceSetting !== "chat"}
                      onClick={() => {
                        updateInterfaceType("message");
                        setChatInterfaceSetting("message");
                      }}
                    >
                      <img
                        src={
                          chatInterfaceSetting !== "chat"
                            ? WhiteMessageInterfaceIcon
                            : MessageInterfaceIcon
                        }
                        alt="messageInterface"
                      />
                    </BookChatStyles.MessageInterface>
                  </BookChatStyles.InterfaceToggle>
                </BookChatStyles.ChatHeader>
              )}
              <BookChatStyles.ScrollDiv fromChatbotBuilder={fromChatbotBuilder} className="chatArea">
                {checkSourceIndexStatus(selectedBook) &&
                selectedBook?.chatType === "dropchat" ? (
                  <BookChatStyles.StatusLoaderSection>
                    <img src={LoaderGif} alt="loading..." width={60} />
                    <BookChatStyles.WaitTitle>
                      Please Wait
                    </BookChatStyles.WaitTitle>
                    <BookChatStyles.WaitDescription>
                      {fileProcessingMessage &&
                      fileProcessingMessage.length > 0 ? (
                        fileProcessingMessage
                      ) : (
                        <BookChatStyles.WaitDescription>
                          Hello! We wanted to let you know that it will take a
                          few minutes to process this item. Don't worry, though
                          - once the file processing is finished, you'll be able
                          to ask any questions you have. You can leave this
                          screen and return a few minutes later to check status.
                          Or you can wait for status to chance to “Ready” on
                          main page.
                        </BookChatStyles.WaitDescription>
                      )}
                    </BookChatStyles.WaitDescription>
                  </BookChatStyles.StatusLoaderSection>
                ) : (
                  <BookChatStyles.QuestionAnswerSection>
                    {loading && (
                      <BookChatStyles.StatusLoaderSection>
                        <img src={LoaderGif} alt="loading..." width={60} />
                        <BookChatStyles.WaitTitle>
                          Please Wait
                        </BookChatStyles.WaitTitle>
                      </BookChatStyles.StatusLoaderSection>
                    )}

                    {currentSessionHistory &&
                      currentSessionHistory.length > 0 &&
                      currentSessionHistory.map((data: any) => {
                        return (
                          <BookChatStyles.SignleQuestionAnswerSection>
                            <div
                              style={{
                                maxWidth:
                                  chatInterfaceSetting === "message"
                                    ? "80%"
                                    : "100%",
                                marginBottom: "5px",
                              }}
                            >
                              <img
                                width="25"
                                src={UserImage}
                                alt="user_image"
                              />
                              &nbsp;&nbsp;
                              <BookChatStyles.QuestionSection
                                style={{
                                  maxWidth:
                                    chatInterfaceSetting === "message"
                                      ? "80%"
                                      : "100%",
                                }}
                              >
                                <h5
                                  style={{
                                    fontSize: isMobileWidth
                                      ? "0.75rem"
                                      : "1rem",
                                  }}
                                >
                                  {data.question}
                                </h5>
                              </BookChatStyles.QuestionSection>
                            </div>
                            <div
                              style={{
                                alignSelf:
                                  chatInterfaceSetting === "message"
                                    ? "flex-end"
                                    : "flex-start",
                                maxWidth:
                                  chatInterfaceSetting === "message"
                                    ? "80%"
                                    : "100%",

                                flexDirection:
                                  chatInterfaceSetting === "message"
                                    ? "row-reverse"
                                    : "row",
                              }}
                            >
                              <img
                                width="25"
                                src={ChatBotIcon}
                                alt="user_image"
                                style={{
                                  display: "flex",
                                  alignSelf: "baseline",
                                }}
                              />
                              &nbsp; &nbsp;
                              <BookChatStyles.ResponseSection
                                style={{
                                  alignSelf:
                                    chatInterfaceSetting === "message"
                                      ? "end"
                                      : "",

                                  maxWidth:
                                    chatInterfaceSetting === "message"
                                      ? "80%"
                                      : "100%",
                                }}
                              >
                                <div>
                                  {data._id ? (
                                    <BookChatStyles.MarkDownContent
                                      color={"rgba(51, 48, 60, 1)"}
                                    >
                                      <CustomMarkdown text={data.answer} />
                                    </BookChatStyles.MarkDownContent>
                                  ) : (
                                    <div style={{ flexDirection: "column" }}>
                                      <CustomMarkdown text={data.answer} />
                                      {data._id ? null : (
                                        <img
                                          width="30"
                                          src={TypingLoading}
                                          alt="..."
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                                {data._id &&
                                activePlan &&
                                activePlan.meta.flashCard ? (
                                  <>
                                    <div
                                      className="card flex flex-wrap gap-2 border-0 mt-2 mb-2 flex-row"
                                      style={{ background: "none" }}
                                    >
                                      {data.notesCategories &&
                                        data.notesCategories.length > 0 &&
                                        data.notesCategories?.map(
                                          (notesCategory: any) => (
                                            <BookChatStyles.ChipParent
                                              // `Do you really want to remove ${notesCategory.name} flashcard category from this question?`
                                              onClick={() => {
                                                setConfirmationBoxMessage(
                                                  `Do you really want to remove selected flashcards category from this question?`
                                                );
                                                setConfirmationAction(
                                                  "deleteCategory"
                                                );
                                                setNotesCategoryData({
                                                  notesCategory,
                                                  data,
                                                });
                                                setShowUtilityConfirmationBox(
                                                  true
                                                );
                                              }}
                                            >
                                              <Chip
                                                style={{
                                                  padding: "0",
                                                }}
                                                label={notesCategory.name}
                                              />
                                              {notesCategory.type !==
                                                "default" && (
                                                <BookChatStyles.CrossIcon className="p-chip-remove-icon pi pi-times-circle"></BookChatStyles.CrossIcon>
                                              )}
                                            </BookChatStyles.ChipParent>
                                          )
                                        )}
                                    </div>
                                    <div
                                      className={
                                        deviceWidth === DEVICE_MEDIUM
                                          ? "flex-row align-left"
                                          : "flex-row" + " align-baseline"
                                      }
                                    >
                                      {deviceWidth === DEVICE_MEDIUM ? (
                                        <BookChatStyles.TreeSelectStyle
                                          onChange={(e) =>
                                            createNotes(data, e.value)
                                          }
                                          options={
                                            notesCategories &&
                                            filteredCategoryList(
                                              [...notesCategories],
                                              data.notesCategories
                                            )
                                          }
                                          filter
                                          className="md:w-20rem w-full"
                                          placeholder="Save to existing flashcards category"
                                        ></BookChatStyles.TreeSelectStyle>
                                      ) : (
                                        <img
                                          src={ExistingCategoryIcon}
                                          alt="ExistingCategoryIcon"
                                          onClick={() => {
                                            setShowExistingCategoryPopup(true);
                                            setNewData(data);
                                          }}
                                          style={{ marginRight: "10px" }}
                                        />
                                      )}
                                      {deviceWidth === DEVICE_MEDIUM ? (
                                        <BookChatStyles.AddCategoryStyle
                                          onClick={(e) => {
                                            op.current.toggle(e);
                                            setQuestionsData(data);
                                          }}
                                        >
                                          Add to new flashcards category
                                        </BookChatStyles.AddCategoryStyle>
                                      ) : (
                                        <img
                                          src={AddCategoryIcon}
                                          alt="AddCategoryIcon"
                                          onClick={() => {
                                            setShowAddCategoryPopup(true);
                                            setQuestionsData(data);
                                          }}
                                          style={{ marginRight: "10px" }}
                                        />
                                      )}
                                      <BookChatStyles.OverlayPanelStyle
                                        ref={op}
                                      >
                                        <BookChatStyles.AddCategoryHeading>
                                          Add to New Flashcards
                                        </BookChatStyles.AddCategoryHeading>
                                        <Formik
                                          initialValues={{
                                            categoryName: "",
                                          }}
                                          validate={validateNotesCategory}
                                          onSubmit={createNotesCategory}
                                        >
                                          {({
                                            values,
                                            errors,
                                            touched,
                                          }: any) => (
                                            <BookChatStyles.AddCategoryForm
                                              className={ADD_FLASHCARD_CATAGORY}
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <label htmlFor="categoryName">
                                                Category
                                              </label>
                                              <Field
                                                id="categoryName"
                                                name="categoryName"
                                                placeholder="Category"
                                              />
                                              {errors.categoryName &&
                                                touched.categoryName && (
                                                  <BookChatStyles.FormError className="formError">
                                                    {errors.categoryName}
                                                  </BookChatStyles.FormError>
                                                )}
                                              <button type="submit">ADD</button>
                                            </BookChatStyles.AddCategoryForm>
                                          )}
                                        </Formik>
                                      </BookChatStyles.OverlayPanelStyle>

                                      {data.source &&
                                        data.source.length > 0 && (
                                          <BookChatStyles.SourceElements
                                            onClick={() => {
                                              setShowAnswerSourcePopup(true);
                                              setAllAnswerSource(data.source);
                                            }}
                                          >
                                            {deviceWidth === DEVICE_MEDIUM ? (
                                              "Show Source"
                                            ) : (
                                              <img
                                                src={ShowSourceIcon}
                                                alt="ShowSourceIcon"
                                              />
                                            )}
                                          </BookChatStyles.SourceElements>
                                        )}
                                    </div>
                                  </>
                                ) : (
                                  <div className="flex-row align-baseline">
                                    {data.answer &&
                                      data.source &&
                                      data.source.length > 0 && (
                                        <BookChatStyles.SourceElements
                                          onClick={() => {
                                            setShowAnswerSourcePopup(true);
                                            setAllAnswerSource(data.source);
                                          }}
                                        >
                                          Show Source
                                        </BookChatStyles.SourceElements>
                                      )}
                                  </div>
                                )}
                              </BookChatStyles.ResponseSection>
                            </div>
                          </BookChatStyles.SignleQuestionAnswerSection>
                        );
                      })}
                  </BookChatStyles.QuestionAnswerSection>
                )}
              </BookChatStyles.ScrollDiv>
            </BookChatStyles.ChatBotContent>
            <BookChatStyles.FieldColumn sm={12}>
              {(showOverlay ||
                (checkSourceIndexStatus(selectedBook) &&
                  selectedBook?.chatType === "dropchat")) && (
                <BookChatStyles.OverlayFieldColumn
                  sm={12}
                ></BookChatStyles.OverlayFieldColumn>
              )}

              <Formik
                initialValues={{
                  prompt: questionValue,
                }}
                onSubmit={(values, actions) => {
                  submitQuestion(values, actions);
                  setQuestionValue("");
                  setIsResponseOn(true);
                }}
                enableReinitialize
              >
                {({ errors, touched, isValidating }: any) => (
                  <BookChatStyles.BookFetchForm
                    ref={(el) => setFormRef(el)}
                    className={CHATBOT_PROMPT}
                  >
                    {selectedBook ? (
                      activePlan && activePlan.planStatics ? (
                        activePlan.planStatics.usedMessage && (
                          <div style={{ display: "flex" }}>
                            <BookChatStyles.DowngradeContainer
                              limitCount={
                                activeCharacterCountDiff <
                                characterToShowUpgrade
                                  ? true
                                  : false
                              }
                            >
                              <BookChatStyles.DowngradeInfoText
                                limitCount={
                                  activeCharacterCountDiff <
                                  characterToShowUpgrade
                                    ? true
                                    : false
                                }
                              >
                                {numberWithComma(
                                  Number(activePlan?.planStatics.usedMessage)
                                )}{" "}
                                /{" "}
                                {numberWithComma(
                                  Number(
                                    user?.currentSubscription?.meta.messageCount
                                  )
                                )}{" "}
                                Message limit used
                              </BookChatStyles.DowngradeInfoText>
                            </BookChatStyles.DowngradeContainer>
                            <UpgradeText
                              style={{
                                paddingTop: ".5rem",
                                marginLeft: "1rem",
                                display:
                                  activeCharacterCountDiff <
                                  characterToShowUpgrade
                                    ? "flex"
                                    : "none",
                              }}
                            >
                              For more message please upgrade your plan.
                            </UpgradeText>
                            <BookChatStyles.UpgradeSpan
                              style={{
                                display:
                                  activeCharacterCountDiff <
                                  characterToShowUpgrade
                                    ? "flex"
                                    : "none",
                              }}
                              onClick={(e) => {
                                trackEvent({
                                  email: getUsersEmail(user),
                                  eventCategory: EventCategory.CHATBOT,
                                  event:
                                    ChatBotEvents.USER_USED_CHANGE_PLAN_OPTION_FROM_CHAT_PAGE,
                                  subCategory: SubCategory.EVENT,
                                  source: Source.CHAT,
                                  chatbotType: selectedBook.type,
                                  bookId: bookId,
                                });
                                setPlanHighliter({
                                  isBookHighliter: false,
                                  bookLimit: 0,
                                  isDeleteBookHighliter: false,
                                  isFlashcardHighliter: false,
                                  isCharacterLimit: true,
                                  pdfLimit: 0,
                                });
                                navigate("/subscription");
                              }}
                            >
                              Upgrade Plan
                            </BookChatStyles.UpgradeSpan>
                          </div>
                        )
                      ) : (
                        <div style={{ display: "flex" }}>
                          <BookChatStyles.DowngradeContainer limitCount={true}>
                            <BookChatStyles.DowngradeInfoText limitCount={true}>
                              no active plan
                            </BookChatStyles.DowngradeInfoText>
                          </BookChatStyles.DowngradeContainer>
                          <p
                            style={{
                              paddingTop: ".5rem",
                              marginLeft:
                                deviceWidth !== DEVICE_MEDIUM
                                  ? "0.5rem"
                                  : "1rem",
                              display:
                                activeCharacterCountDiff <
                                characterToShowUpgrade
                                  ? "flex"
                                  : "none",
                              fontSize:
                                deviceWidth !== DEVICE_MEDIUM
                                  ? "12px"
                                  : "inherit",
                            }}
                          >
                            To ask more questions please purchase a plan.
                          </p>
                          <BookChatStyles.UpgradeSpan
                            style={{
                              display:
                                activeCharacterCountDiff <
                                characterToShowUpgrade
                                  ? "flex"
                                  : "none",
                            }}
                            onClick={(e) => {
                              setPlanHighliter({
                                isBookHighliter: false,
                                bookLimit: 0,
                                isDeleteBookHighliter: false,
                                isFlashcardHighliter: false,
                                isCharacterLimit: true,
                                pdfLimit: 0,
                              });
                              navigate("/subscription");
                            }}
                          >
                            Purchase Plan
                          </BookChatStyles.UpgradeSpan>
                        </div>
                      )
                    ) : null}
                    <BookChatStyles.FieldSection>
                      <BookChatStyles.FormInput
                        name="prompt"
                        as="textarea"
                        disabled={isResponseOn}
                        onChange={(e) => setQuestionValue(e.target.value)}
                        value={questionValue}
                        placeholder={
                          'Ask me anything from "' + selectedBook?.title + '"'
                        }
                        style={{
                          paddingRight: "2.6rem",
                          resize: "none",
                          height: "50px",
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode == 13 && e.shiftKey == false) {
                            e.preventDefault();
                            if (!isResponseOn) formRef.requestSubmit();
                          }
                        }}
                      />

                      <BookChatStyles.FetchBookButton
                        type={
                          isResponseOn || questionValue.length === 0
                            ? "button"
                            : "submit"
                        }
                      >
                        <img width={25} src={SearchArrow} alt="search" />
                      </BookChatStyles.FetchBookButton>
                    </BookChatStyles.FieldSection>
                  </BookChatStyles.BookFetchForm>
                )}
              </Formik>
            </BookChatStyles.FieldColumn>
          </BookChatStyles.RightContentRow>
        </BookChatStyles.ChatInputBox>
      </BookChatStyles.RightColumn>
      <AnswerSourcePopup allAnswerSource={allAnswerSource} />

      <ExistingCategoryPopup
        showModal={showExistingCategoryPopup}
        setShowModal={setShowExistingCategoryPopup}
        createNotes={createNotes}
        filteredCategoryList={filteredCategoryList}
        allNotesCategories={notesCategories && notesCategories}
        data={newData && newData}
      />
      {showUtilityConfirmationBox && (
        <ConfirmationBox
          setShow={setShowUtilityConfirmationBox}
          name="Confirmation"
          message={confirmationBoxMessage}
          okButtonText="Yes"
          action={confirmationAction}
          okButtonClick={confirmClick}
        />
      )}
      <AddCategoryPopup
        allAnswerSource={allAnswerSource}
        showModal={showAddCategoryPopup}
        setShowModal={setShowAddCategoryPopup}
        validateNotesCategory={validateNotesCategory}
        createNotesCategory={createNotesCategory}
      />
    </>
  );
};

export default ChatArea;
