export const CREATE_PUBLIC_BOOK = "createPublicBook";
export const GUEST_USER_CHATBOT = "guestUserChatbot";
export const PUBLIC_USER_CHATBOT = "PublicUserChatbot";
export const CREATE_BOOK = "createBook";
export const CHATBOT_PROMPT = "chatbotPrompt";
export const ADD_FLASHCARD_CATAGORY = "addFlashCardCategory";
export const MANAGE_CHATBOT = "manageChatbot";
export const CHATBOT_LOGIN_SETTINGS = "chatbotLoginSettings";
export const SAMPLE_QUESTION = "sampleQuestion";
export const FLASHCARD_QUESTION_STATUS = "flashCardQuestionStatus";
export const USER_PROFILE = "userProfile";
export const USER_SETTING_OPEN_AI_KEY = "userSettingOpenAiKey";
export const ADMIN_APP_SETTINGS = "adminAppSettings";
export const ADMIN_LLM_SETTINGS = "adminLLMSettings";
export const ADMIN_OPEN_AI_SETTINGS = "adminOpenAISettings";
export const ADMIN_UPDATE_CUSTOM_PLAN = "adminUpdateCustomPlan";
export const ADD_PDF_SOURCE = "addPdfSource";
export const ADD_WEBSITE_SOURCE_CRAWL_URL = "addWebsiteSourceCrwalUrl";
export const ADD_WEBSITE_SOURCE_SITEMAP_URL = "addWebsiteSourceSiteMapUrl";
export const ADD_YOUTUBE_SOURCE_URL = "addYoutubeSourceUrl";
export const ADD_TEXT_SOURCE_URL = "addTextSourceUrl";
