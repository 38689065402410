import { useState } from "react";
import { convertSecondsToDuration } from "../../../../../utils/utils";
import ShowShopifyProducts from "../../../../shopify/show-shopify-products";

const ProductList = ({ productList }: any) => {
  const [isShowShopifyProductList, setIsShowShopifyProductList] =
    useState(false);
  return (
    <>
      <span
        onClick={() => setIsShowShopifyProductList(true)}
        style={{ cursor: "pointer", color: "#1585b5" }}
      >
        {productList.length} Products
      </span>
      {isShowShopifyProductList && (
        <ShowShopifyProducts
          setIsShowShopifyProductList={setIsShowShopifyProductList}
          allProductsList={productList}
        />
      )}
    </>
  );
};

const IncludedInfoColumn = ({ dataSource }: any) => {
  return (
    <>
      {dataSource.type === "aws" ? (
        !dataSource.totalPages || dataSource.totalPages === 0 ? (
          "---"
        ) : (
          `${dataSource.totalPages} Pages`
        )
      ) : dataSource.type === "url" ? (
        dataSource.urls && dataSource.urls?.length > 0 ? (
          `${dataSource.urls?.length} URLs`
        ) : (
          "---"
        )
      ) : dataSource.type === "youtube" ? (
        dataSource.ytVideoDuration ? (
          convertSecondsToDuration(dataSource.ytVideoDuration)
        ) : (
          "---"
        )
      ) : dataSource.type === "shopify" ? (
        dataSource.shopifyAddedProductData &&
        dataSource.shopifyAddedProductData ? (
          <ProductList productList={dataSource.shopifyAddedProductData} />
        ) : (
          "---"
        )
      ) : (
        "---"
      )}
    </>
  );
};

export default IncludedInfoColumn;
