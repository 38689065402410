import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styled from "styled-components";
import ShopifyIcon from "../../images/icons_shopify.svg";
import Cross from "../../images/cross.svg";
import ShowLargeImagePopup from "./show-large-image-popup";

const ParentDiv = styled.div`
  overflow: hidden;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  align-items: center;
  justify-content: center;
`;
const EmptyDiv = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;
const Container = styled(Col)`
  position: fixed;
  display: block;
  width: 90vw;
  margin: auto;
  overflow: hidden;
  max-height: 90vh;
  inset: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 999999;
  border-radius: 10px 10px 0px 0px;
  border: 2px solid #2f3349;
`;
const CancelButton = styled.button`
  background: none;
  padding: 0.4rem 3rem;
  color: #2f3349;
  border: 1px solid #2f3349;
  width: max-content;
  margin-top: 1rem;
`;

const PopupHeader = styled.h4`
  background: #2f3349;
  text-align: center;
  padding: 5px;
  color: #fff;
`;

// const PaginationUI = styled.div`
//   display: flex;
//   align-self: flex-start;
// `;

const ParentListUI = styled.div`
  padding: 0.5rem;
  flex-direction: column;
  & .p-datatable-wrapper {
    width: 100%;
    overflow: auto;
    height: 58vh;
  }
`;

// const PaginationButton = styled.button`
//   background: #1585b5;
//   color: #fff;
//   padding: 5px 15px;
//   border: none;
//   margin-left: 10px;
// `;

// const LeftContent = styled.div`
//   flex-direction: column;
// `;

// const TotalProductCount = styled.p`
//   font-weight: bold;
//   margin: 0;
// `;

// const ProductListHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// const SearchBar = styled.input`
//   padding: 8px;
//   background: #f8f7fa;
//   border: 1px solid #eaeaea;
//   width: 20rem;
//   &:focus {
//     outline: none;
//   }
// `;
// const SearchSection = styled.div`
//   margin: 1rem 0rem;
// `;
// const SearchButton = styled.button`
//   background: #1585b5;
//   color: #fff;
//   padding: 5px 15px;
//   border: none;
// `;
// interface SaveButtonProps {
//   displayProp?: boolean; // Declare the custom prop and its type
// }
// const SaveButton = styled.button<SaveButtonProps>`
//   background: #1585b5;
//   padding: 0.4rem 3rem;
//   color: #fff;
//   border: 1px solid transparent;
//   width: auto;
//   display: flex;
//   margin-top: auto;
//   justify-content: center;
// `;
const CrossImg = styled.img`
  position: absolute;
  right: -12px;
  top: -12px;
  cursor: pointer;
`;

const ShowShopifyProducts = ({
  setIsShowShopifyProductList,
  allProductsList,
}: any) => {
  const [showMorePrices, setShowMorePrices] = useState<any>(null);
  const [productPopup, setProductPopup] = useState<any>(null);

  const hidePopup = () => {
    setIsShowShopifyProductList(false);
  };

  const imageBody = (data: any, idx: any) => {
    let imageOfProduct = data && data.image_src;
    return (
      <>
        <img
          style={{ cursor: imageOfProduct ? "pointer" : "", width: "30px" }}
          src={imageOfProduct || ShopifyIcon}
          alt="product"
          className="w-4rem"
          onClick={() => imageOfProduct && setProductPopup(idx.rowIndex)}
        />
        {productPopup === idx.rowIndex && (
          <ShowLargeImagePopup
            setProductPopup={setProductPopup}
            productData={data}
          />
        )}
      </>
    );
  };

  const priceBody = (data: any, idx: any) => {
    let allVariantsPrice = data && data.variants;
    return (
      <span>
        ${allVariantsPrice[0].price}{" "}
        {allVariantsPrice.length > 1 ? (
          <span
            style={{
              fontWeight: "bold",
              color: "#1585b5",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <span onClick={() => setShowMorePrices(idx.rowIndex)}>
              +{allVariantsPrice.length - 1}More
            </span>
            {showMorePrices === idx.rowIndex && (
              <span
                style={{
                  position: "absolute",
                  right: "0px",
                  top: "35px",
                  background: "#fff",
                  border: "1px solid #ddd",
                  display: "flex",
                  flexDirection: "column",
                  width: "max-content",
                  padding: "0.5rem",
                  fontWeight: "normal",
                  color: "#6c6c6c",
                  fontSize: "1rem",
                  borderRadius: "4px",
                  zIndex: 9,
                }}
              >
                <CrossImg
                  style={{
                    cursor: "pointer",
                  }}
                  src={Cross}
                  alt="cross"
                  onClick={() => setShowMorePrices(null)}
                />
                {allVariantsPrice &&
                  allVariantsPrice.length > 0 &&
                  allVariantsPrice.map((variant: any, idx: number) => {
                    return (
                      <span>
                        variant {idx + 1}:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          ${variant.price}
                        </span>
                      </span>
                    );
                  })}
              </span>
            )}
          </span>
        ) : null}
      </span>
    );
  };

  return (
    <>
      <ParentDiv>
        <EmptyDiv onClick={hidePopup}></EmptyDiv>
        <Container>
          <PopupHeader>List of Products</PopupHeader>
          <ParentListUI>
            <DataTable showGridlines value={allProductsList}>
              <Column header="Image" body={imageBody}></Column>
              <Column field="title" header="Name"></Column>
              <Column field="body_html" header="Description"></Column>
              <Column header="Price" body={priceBody}></Column>
            </DataTable>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: " 0 2rem",
              }}
            >
              <CancelButton onClick={() => hidePopup()}>Close</CancelButton>
            </Row>
          </ParentListUI>
        </Container>
      </ParentDiv>
    </>
  );
};

export default ShowShopifyProducts;
