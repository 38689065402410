import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "../../context/UserContext";
import { PlanContext } from "../../context/plan-context";
import BackIcon from "../../images/chat-back.svg";
import MainHeader from "../header";
import AppSettings from "./app-settings";
import Gpt3Settings from "./gpt-3-settings";
import LLMSettings from "./llm-settings";
import OpenAiQuestionSummary from "./open-ai-question-summary";
import FeatureChatbots from "./feature-chatbots";
import UserSubscriptionsHistory from "./user-subscriptions-history";
import CustomPurchase from "./custom-purchase";
import { MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
import DeleteAccount from "./delete-account";
import HTMLScriptsSettings from "./html-scripts-settings";

const MainDiv = styled.div`
  flex-direction: column;
`;

// const HeaderDiv = styled.div`
//   width: 100%;
//   background: #2f4c95;
//   justify-content: center;
//   @media (max-width: 450px) and (min-width: 320px) {
//     width: 100%;
//     /* height: 5%; */
//   }
// `;
const TabContainer = styled.div`
  overflow: hidden;
  background-color: #f8f7fa;
  padding-left: 8px;
  padding-top: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  /* border-top: 1px #21232c48 solid; */
`;
const Tab = styled.button<{
  selected?: boolean;
}>`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6.5px 16px;
  min-width: 100px;
  transition: 0.3s;
  font-size: 17px;
  position: relative;
  background-color: #fff;
  border-bottom: ${(props) =>
    props.selected ? "3px solid #1585b5" : "3px solid #eaeaea"};
  color: ${(props) => (props.selected ? "#1585B5" : "#33303C61")};
  font-weight: 700;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 16px;
  }
`;
const HeaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderText = styled.h3`
  text-align: center;
  color: red;
`;

// const InnerDiv = styled.div`
//   width: 100%;
//   padding: 1rem;
// `;

// const TemplateSpan = styled.p`
//   font-style: normal;
//   font-weight: 600;
//   font-size: 20px;
//   color: #fff;
//   margin: 0;
// `;

const AppFeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;
const BackButton = styled.img`
  padding: 0;
  width: 12px;
  cursor: pointer;
`;

const Gpt3SettingsTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <Gpt3Settings />
      </AppFeatureContainer>
    </>
  );
};

const AppSettingsTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <AppSettings />
      </AppFeatureContainer>
    </>
  );
};

const LLMSettingsTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <LLMSettings />
      </AppFeatureContainer>
    </>
  );
};

const PublicBooksTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <FeatureChatbots />
      </AppFeatureContainer>
    </>
  );
};

const OpenAuQuestionSummaryTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <OpenAiQuestionSummary />
      </AppFeatureContainer>
    </>
  );
};

const UserSubscriptionsTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <UserSubscriptionsHistory />
      </AppFeatureContainer>
    </>
  );
};

const CustomPurchaseTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <CustomPurchase />
      </AppFeatureContainer>
    </>
  );
};

const DeleteAccountTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <DeleteAccount />
      </AppFeatureContainer>
    </>
  );
};

const HTMLScriptsTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <HTMLScriptsSettings />
      </AppFeatureContainer>
    </>
  );
};

const AdminView = ({ isOpen }: any) => {
  let navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { setPlanHighliter } = useContext(PlanContext);
  const [selectTabName, setSelectTabName] = useState("App Settings");
  return (
    <>
      <MainDiv>
        {user && user.role === "public" ? (
          <HeaderContainer>
            <HeaderText>You are not authorized to view this page</HeaderText>
          </HeaderContainer>
        ) : (
          <MainHeader isUserPage={true} activeFrom={"fromAdmin"} />
        )}
        <TabContainer>
          <Col
            style={{
              backgroundColor: "#f8f7fa",
              marginTop: "1vh",
              marginLeft: "3rem",
            }}
          >
            <BackButton
              src={BackIcon}
              onClick={() => {
                navigate("/my-chatbots/create-pdf-chatbot");
              }}
            />
            <span
              style={{
                fontSize: "18px",
                marginLeft: "2rem",
                fontWeight: "600",
              }}
            >
              {selectTabName}
            </span>
          </Col>
        </TabContainer>
        <TabContainer
          style={{
            borderTop: "0",
            backgroundColor: "#fff",
            width: "90vw",
            marginLeft: "3rem",
            marginTop: "1rem",
          }}
        >
          {user && user.role === "super_admin" && (
            <>
              <Tab
                onClick={() => {
                  navigate("/adminsettings/app-settings");
                  setSelectTabName("App Settings");
                }}
                selected={location.pathname === "/adminsettings/app-settings"}
              >
                App Settings
              </Tab>

              <Tab
                onClick={() => {
                  navigate("/adminsettings/llm-settings");
                  setSelectTabName("LLM Settings");
                }}
                selected={location.pathname === "/adminsettings/llm-settings"}
              >
                LLM Settings
              </Tab>

              <Tab
                onClick={() => {
                  navigate("/adminsettings");
                  setSelectTabName("Open AI Setting");
                }}
                selected={location.pathname === "/adminsettings"}
              >
                Open AI Setting
              </Tab>
              <Tab
                onClick={() => {
                  navigate("/adminsettings/featured-chatbots");
                  setSelectTabName("Featured Chatbots");
                }}
                selected={
                  location.pathname === "/adminsettings/featured-chatbots"
                }
              >
                Featured Chatbots
              </Tab>
              {/* <Tab
                onClick={() => {
                  navigate("/adminsettings/open-ai-request-summary");
                }}
                selected={
                  location.pathname === "/adminsettings/open-ai-request-summary"
                }
              >
                Open AI Request Summary
              </Tab> */}
            </>
          )}
          {/* <Tab
            onClick={() => {
              navigate("/settings/user-profile");
            }}
            selected={location.pathname === "/settings/user-profile"}
          >
            User Profile
          </Tab>*/}
          <Tab
            onClick={() => {
              setPlanHighliter(undefined);
              navigate("/adminsettings/user-subscriptions-history");
              setSelectTabName("User Subscriptions History");
            }}
            selected={
              location.pathname === "/adminsettings/user-subscriptions-history"
            }
          >
            User Subscriptions History
          </Tab>
          <Tab
            onClick={() => {
              // setPlanHighliter(undefined);
              navigate("/adminsettings/custom-purchase");
              setSelectTabName("Custom Purchase");
            }}
            selected={location.pathname === "/adminsettings/custom-purchase"}
          >
            Custom Purchase
          </Tab>
          <Tab
            onClick={() => {
              // setPlanHighliter(undefined);
              navigate("/adminsettings/delete-account");
              setSelectTabName("Delete Account");
            }}
            selected={location.pathname === "/adminsettings/delete-account"}
          >
            Delete Account
          </Tab>
          <Tab
            onClick={() => {
              // setPlanHighliter(undefined);
              navigate("/adminsettings/html-scripts");
              setSelectTabName("HTML Scripts");
            }}
            selected={location.pathname === "/adminsettings/html-scripts"}
          >
            HTML Scripts
          </Tab>
          {/* </Row> */}
        </TabContainer>

        <Row
          style={{
            backgroundColor: "#fff",
            width: "90vw",
            marginLeft: "3rem",
            marginTop: "0",
          }}
        >
          <Routes>
            {user && user.role === "super_admin" && (
              <>
                <Route path="/" element={<Gpt3SettingsTab />} />
                <Route path="/app-settings" element={<AppSettingsTab />} />
                <Route path="/llm-settings" element={<LLMSettingsTab />} />
                <Route path="/featured-chatbots" element={<PublicBooksTab />} />
                <Route
                  path="/open-ai-request-summary"
                  element={<OpenAuQuestionSummaryTab />}
                />
                <Route
                  path="/user-subscriptions-history"
                  element={<UserSubscriptionsTab />}
                />
                <Route
                  path="/custom-purchase"
                  element={<CustomPurchaseTab />}
                />
                <Route path="/delete-account" element={<DeleteAccountTab />} />
                <Route path="/html-scripts" element={<HTMLScriptsTab />} />
              </>
            )}
            {/* <Route path="/user-profile" element={<UserProfileTab />} />*/}
          </Routes>
        </Row>
      </MainDiv>
    </>
  );
};

export default AdminView;
