import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToBottom from "react-scroll-to-bottom";
import styled from "styled-components";
import { UserContext } from "../context/UserContext";
import SearchArrow from "../images/chat-arrow.svg";
import BackIcon from "../images/chat-back.svg";
import ChatBotIcon from "../images/chat-bot-icon.svg";
import LogoutIcon from "../images/chat-logout.svg";
import MessageVector from "../images/chat-msg-icon.svg";
import UserImage from "../images/chat-user.svg";
import EPUBSign from "../images/epubSign.svg";
import FLACSign from "../images/flacSign.svg";
import HTMLSign from "../images/htmlSign.svg";
import LoaderGif from "../images/loader.gif";
import MDSign from "../images/mdSign.svg";
import MDXSign from "../images/mdxSign.svg";
import MP3Sign from "../images/mp3Sign.svg";
import MP4Sign from "../images/mp4Sign.svg";
import OGGSign from "../images/oggSign.svg";
import PDFSign from "../images/pdfSign.svg";
import TXTSign from "../images/txtSign.svg";
import TypingLoading from "../images/typingLoading.gif";
import URLSign from "../images/urlSign.svg";
import WAVSign from "../images/wavIcon.svg";
import WhiteDropChatLogo from "../images/white-dropchat-logo.svg";
import { magic } from "../magic";
import myAxios from "../myaxios";
import { parseErrorResponse } from "../utils/utils";
import { LoaderContext } from "../context/loader-context";

const MainContainer = styled(Container)`
  padding: 0;
`;

const Sidebar = styled(Col)`
  background: #2f3349;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;
const SideBarHeader = styled.div`
  height: 70px;
  padding: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const SideBarContent = styled.div`
  width: 100%;
  padding: 10px;
  flex-direction: column;
`;
const LogoutButton = styled.img`
  padding: 0;
  width: 30%;
  margin: auto;
  cursor: pointer;
`;

const BackButton = styled.img`
  padding: 0;
  width: 15%;
  cursor: pointer;
`;

const BookFetchForm = styled(Form)`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  margin: 0;
  align-self: end;
`;

const FieldSection = styled.div`
  position: relative;
  flex-direction: column;
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
  border: ${(props) => (props.error ? "2px solid red" : "2px solid #282c34")};
`;

const FetchBookButton = styled.button`
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 16px;
  cursor: pointer;
  top: 0.5rem;
  position: absolute;
  right: 10px;
  background: none;
  border: none;
`;

const ChatInputBox = styled(Col)`
  height: calc(100vh - 90px);
`;

const ChatHistoryHeading = styled.h5`
  color: #cccfdd;
`;

const RightColumn = styled(Col)`
  flex-direction: column;
`;

const RightContentRow = styled(Row)`
  width: 100%;
  padding: 10px;
`;

const ChatBotContent = styled(Col)`
  flex-direction: column;
`;

const ScrollDiv = styled(ScrollToBottom)`
  height: calc(100vh - 30vh);
  width: 100%;
  flex-direction: column;
`;

const BookContent = styled.div`
  flex-direction: column;
  & h5 {
    color: #33303c;
    font-size: 18px;
  }
  & h6 {
    color: rgba(51, 48, 60, 0.6);
    font-size: 16px;
  }
`;

const SignleQuestionAnswerSection = styled.div`
  margin: 5px 5px 15px 5px;
  flex-direction: column;
`;

const ResponseSection = styled.div`
  padding: 15px 10px;
  color: #282c34;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 5px;
  align-items: flex-start;
  & span {
    font-size: 2rem;
  }
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
  }
`;

const QuestionSection = styled.div`
  padding: 15px 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  align-items: center;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
  }
`;

const QuestionAnswerSection = styled.div`
  flex-direction: column;
  width: 100%;
`;

const HistoryQuestion = styled.div`
  align-items: center;
  padding: 10px;
  margin: 0.2rem 0rem;
  background: #3d4156;
  border-radius: 5px;
  cursor: pointer;
  & p {
    color: #9c9eaf;
    margin: 0;
  }
  & img {
    margin-right: 0.8rem;
  }
`;

const FieldColumn = styled(Col)`
  position: relative;
`;

const OverlayFieldColumn = styled(Col)`
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 999;
  background: #00000050;
  cursor: not-allowed;
`;

const StatusLoaderSection = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WaitTitle = styled.p`
  text-align: center;
  font-size: 24px;
  color: #4f83b5;
  font-weight: bold;
`;

const WaitDescription = styled.p`
  text-align: center;
`;

const ChatPageHeader = styled.div`
  margin: 10px;
  background: #bedcdd;
  border: 1px solid #bedcdd;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const ChatWithBook = () => {
  const { user, setUser } = useContext(UserContext);
  const { setShowLoader } = useContext(LoaderContext);
  const [allQuestionsandAnswers, setAllQuestionsAndAnswers] = useState<any[]>(
    []
  );

  const [questionAnswerHistory, setQuestionAnswerHistory] = useState([]);

  // eslint-disable-next-line
  const [typingLoading, setTypingLoading] = useState(false);
  // eslint-disable-next-line
  const [bookStatus, setBookStatus] = useState<any>();

  const [bookData, setBookData] = useState<any>("");

  const navigate = useNavigate();

  const location = useLocation();

  const validatePropmt = (prompt: string) => {
    let error;
    if (!prompt) {
      error = "This is Required!";
    }
    return error;
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  useEffect(() => {
    let bookID = location.search.split("?").pop();

    if (bookID) {
      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/books/" + bookID)
        .then(async (response) => {
          if (response.data) {
            setBookData(response.data);
          }
        })
        .catch((error) => {
          // handle error
          // console.log(error.response.data);
        });
    }
  }, [setBookData, location.search]);

  useEffect(() => {
    let bookID = location.search.split("?").pop();
    myAxios
      .get(
        process.env.REACT_APP_SERVER_URL +
          "/get-history-of-chats/" +
          user?.id +
          "/" +
          bookID
      )
      .then((response) => {
        if (response.data) {
          setQuestionAnswerHistory(response.data.data);
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error.response.data);
      });
  }, [setQuestionAnswerHistory, location.search, user?.id]);

  const Logout = async () => {
    setShowLoader(true);
    localStorage.setItem("verifyCredentialRequired", "no");
    setUser(undefined);
    navigate("/landing");
    if (await magic.user.isLoggedIn()) await magic.user.logout();
    setShowLoader(false);
  };

  const getAnswerViaQuestion = (questionID: string) => {
    myAxios
      .get(
        process.env.REACT_APP_SERVER_URL +
          "/questions-and-answers/" +
          questionID
      )
      .then((response) => {
        if (response.data) {
          setAllQuestionsAndAnswers([response.data]);
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error.response.data);
      });
  };

  // console.log("response is, ", response);

  const chatWithGPT = (userQuery: string) => {
    setAllQuestionsAndAnswers(
      allQuestionsandAnswers
        ? [...allQuestionsandAnswers, { question: userQuery, answer: "" }]
        : [{ question: userQuery, answer: "" }]
    );
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/chat-with-book", {
        question: userQuery,
        bookName: bookData.title,
        authorName: bookData.authors[0],
        userID: user && user?.id,
        bookID: bookData.id,
      })
      .then((response) => {
        // handle success
        if (response.data.success) {
          let data = response.data.data;
          setAllQuestionsAndAnswers(data.allQuestionsAndAnswers);
          setQuestionAnswerHistory(data.currentHistory);
          setTypingLoading(false);
        } else {
          setTypingLoading(false);
        }
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
        setTypingLoading(false);
      });
  };

  return (
    <>
      <MainContainer fluid>
        <Sidebar sm={3}>
          <SideBarHeader>
            <img src={WhiteDropChatLogo} alt="DropChatLogo" />
          </SideBarHeader>
          <SideBarContent>
            <ChatHistoryHeading>Chat History</ChatHistoryHeading>
            <>
              {questionAnswerHistory &&
                questionAnswerHistory.length > 0 &&
                questionAnswerHistory.map((data: any) => (
                  <>
                    <HistoryQuestion
                      onClick={() => getAnswerViaQuestion(data.id)}
                    >
                      <img width="18" src={MessageVector} alt="message" />
                      <p>{data.question}</p>
                    </HistoryQuestion>
                  </>
                ))}
            </>
          </SideBarContent>
        </Sidebar>
        <RightColumn sm={9}>
          <ChatPageHeader className="App-header">
            <Col sm={1}>
              <BackButton
                src={BackIcon}
                onClick={() => {
                  navigate("/my-chatbots/create-pdf-chatbot");
                }}
              />
            </Col>
            <Col sm={10} style={{ alignItems: "center" }}>
              <img
                width="30"
                style={{ marginRight: "1rem" }}
                src={
                  bookData.type !== "custom"
                    ? bookData.imageUrl
                      ? bookData.imageUrl
                      : PDFSign
                    : bookData.fileType === "audio/mpeg"
                    ? MP3Sign
                    : bookData.fileType === "audio/ogg"
                    ? OGGSign
                    : bookData.fileType === "audio/wav"
                    ? WAVSign
                    : bookData.fileType === "audio/flac"
                    ? FLACSign
                    : bookData.fileType === "video/mp4"
                    ? MP4Sign
                    : bookData.fileType === "application/epub+zip"
                    ? EPUBSign
                    : bookData.fileType === "md"
                    ? MDSign
                    : bookData.fileType === "mdx"
                    ? MDXSign
                    : bookData.fileType === "text/plain"
                    ? TXTSign
                    : bookData.fileType === "text/html"
                    ? HTMLSign
                    : bookData.fileType === "url"
                    ? URLSign
                    : PDFSign
                }
                alt="book_image"
              />
              <BookContent>
                <h5 style={{ margin: 0 }}>{bookData && bookData.title}</h5>
                <h6>
                  {bookData &&
                    bookData.authors.length > 0 &&
                    bookData.authors.map((author: any) => author).join(", ")}
                </h6>
              </BookContent>
            </Col>
            <Col sm={1}>
              <LogoutButton
                src={LogoutIcon}
                onClick={() => {
                  const confirmBox = window.confirm(
                    "Do you really want to logout?"
                  );
                  if (confirmBox === true) {
                    Logout();
                  }
                }}
              />
            </Col>
          </ChatPageHeader>
          <ChatInputBox sm={12}>
            <RightContentRow>
              <ChatBotContent sm={12}>
                <ScrollDiv>
                  {bookStatus !== "READY" && bookData.type === "custom" ? (
                    <StatusLoaderSection>
                      <img src={LoaderGif} alt="loading..." width={60} />
                      <WaitTitle>Please Wait</WaitTitle>
                      <WaitDescription>
                        {user && user.fileProcessingMessage}
                      </WaitDescription>
                    </StatusLoaderSection>
                  ) : (
                    <QuestionAnswerSection>
                      {allQuestionsandAnswers &&
                        allQuestionsandAnswers.length > 0 &&
                        allQuestionsandAnswers.map((data: any) => (
                          <SignleQuestionAnswerSection>
                            <QuestionSection>
                              <img
                                width="30"
                                src={UserImage}
                                alt="user_image"
                              />
                              &nbsp;&nbsp;
                              <h5>{data.question}</h5>
                            </QuestionSection>
                            <ResponseSection>
                              {/* <img width="30" src={BotImage} /> */}
                              <img
                                width="30"
                                src={ChatBotIcon}
                                alt="user_image"
                              />
                              &nbsp;&nbsp;
                              <h5>
                                {data.answer}
                                {data._id ? null : (
                                  <img
                                    width="30"
                                    src={TypingLoading}
                                    alt="..."
                                  />
                                )}
                              </h5>
                            </ResponseSection>
                          </SignleQuestionAnswerSection>
                        ))}
                      {/* {typingLoading && (
                        <>
                          <QuestionSection>
                            <img width="30" src={UserImage} alt="user_image" />
                            <h5>{question}</h5>
                          </QuestionSection>
                          <ResponseSection style={{ marginTop: "10px" }}>
                            <span>🔮</span>
                            <h5>{response}</h5>
                            <img width="30" src={TypingLoading} alt="..." />
                          </ResponseSection>
                        </>
                      )} */}
                    </QuestionAnswerSection>
                  )}
                </ScrollDiv>
              </ChatBotContent>
              <FieldColumn sm={12}>
                {bookStatus !== "READY" && bookData.type === "custom" && (
                  <OverlayFieldColumn sm={12}></OverlayFieldColumn>
                )}
                <Formik
                  initialValues={{
                    prompt: "",
                  }}
                  onSubmit={(values, actions) => {
                    setTypingLoading(true);
                    actions.resetForm({
                      values: {
                        prompt: "",
                      },
                    });
                    bookData.chatType === "chatgpt" ||
                      (bookData.type === "existing" &&
                        chatWithGPT(values.prompt));
                  }}
                >
                  {({ errors, touched, isValidating }: any) => (
                    <BookFetchForm>
                      <FieldSection>
                        <FormInput
                          name="prompt"
                          validate={validatePropmt}
                          autocomplete="off"
                          placeholder={
                            'Ask me anything from "' + bookData.title + '"'
                          }
                          error={errors.prompt ? true : false}
                        />
                        <FetchBookButton type="submit">
                          <img width={25} src={SearchArrow} alt="search" />
                        </FetchBookButton>
                      </FieldSection>
                    </BookFetchForm>
                  )}
                </Formik>
              </FieldColumn>
            </RightContentRow>
          </ChatInputBox>
        </RightColumn>
      </MainContainer>
    </>
  );
};

export default ChatWithBook;
