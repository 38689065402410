import { useContext, useRef } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BookContext } from "../../context/BookContext";
import ProgressIndicator from "../../images/loading_bar.gif";
import PDFSign from "../../images/pdf-ic.svg";
import PdfUrl from "../../images/pdf-url.svg";
import PdfYoutubeUrl from "../../images/pdf-youtube-url.svg";
import PdfYoutube from "../../images/pdf-youtube.svg";
import URLSign from "../../images/url-ic.svg";
import YouTubeSign from "../../images/youtube-ic.svg";
import YoutubeUrl from "../../images/youtube-url.svg";
import { Book } from "../../types";
import * as BookStyles from "./styled";
import CopyToClipboard from "react-copy-to-clipboard";
import { NotificationManager } from "react-notifications";
interface BookComponent {
  book: any;
  idx: number;
  onBookClick: any;
  onDeleteBookClick: any;
  onShowSessionClicked: any;
  onUpdateClick: any;
}
const BookActions = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0;
  & a {
    display: flex;
  }
`;
const ProgressBarSpan = styled.span`
  font-size: 12px;
  color: #33303c99;
  font-weight: 600;
`;
const BookImage = styled.img`
  width: 100%;
  box-shadow: 2px 2px 10px -4px #000000bd;
  margin-bottom: 5px;
  border-radius: 10px;
  cursor: pointer;
`;

const FirstInnerParent = styled.div`
  flex: 1;
  padding: 10px;
  flex-direction: column;
  position: relative;

  margin-bottom: 0.5rem;
`;

const BookContent = ({
  book,
  idx,
  onBookClick,
  onDeleteBookClick,
  onShowSessionClicked,
  onUpdateClick,
}: BookComponent) => {
  const op = useRef<any>(null);

  const { setSelectedBook } = useContext(BookContext);

  const bookImageURL = (book: Book) => {
    if (book.imageUrl && book.imageUrl.length > 0) return book.imageUrl;

    let imageUrl = book.imageUrl ? book.imageUrl : PDFSign;

    let isPdf = false;
    let isUrl = false;
    let isYT = false;

    if (!(book.chatType === "chatgpt" || book.type === "existing")) {
      if (book.sources && book.sources.length <= 0) {
        if (book.type === "aws") {
          imageUrl = PDFSign;
          return imageUrl;
        }
        if (book.type === "url") {
          imageUrl = URLSign;
          return imageUrl;
        }
        if (book.type === "youtube") {
          imageUrl = YouTubeSign;
          return imageUrl;
        }
        return imageUrl;
      } else if (book.sources && book.sources.length > 1) {
        // eslint-disable-next-line
        book.sources.map((source: any) => {
          if (source.type === "aws") {
            isPdf = true;
          } else if (source.type === "url") {
            isUrl = true;
          } else if (source.type === "youtube") {
            isYT = true;
          }
          if (isPdf && isYT && isUrl) {
            imageUrl = PdfYoutubeUrl;
          }
          if (isPdf && isYT && !isUrl) {
            imageUrl = PdfYoutube;
          }
          if (isPdf && !isYT && isUrl) {
            imageUrl = PdfUrl;
          }
          if (!isPdf && isYT && isUrl) {
            imageUrl = YoutubeUrl;
          }
          if (!isPdf && isYT && !isUrl) {
            imageUrl = YouTubeSign;
          }
          if (isPdf && !isYT && !isUrl) {
            imageUrl = PDFSign;
          }
          if (!isPdf && !isYT && isUrl) {
            imageUrl = URLSign;
          }
        });
        return imageUrl;
      } else {
        book.sources &&
          book.sources.length === 1 &&
          book.sources.forEach((source: any) => {
            if (source.type === "aws") {
              imageUrl = PDFSign;
            } else if (source.type === "url") {
              imageUrl = URLSign;
            } else if (source.type === "youtube") {
              imageUrl = YouTubeSign;
            }
          });
        return imageUrl;
      }
    }

    return imageUrl;
  };

  return (
    <>
      <FirstInnerParent
        onClick={() => {
          setSelectedBook(book);
        }}
      >
        {/*  <img
          src={bookImageURL(book)}
          alt="chatbot"
          style={{
            // width: "100%",
            boxShadow: "2px 2px 10px -4px #000000bd",
            width: "100%",
            minWidth: "150px",
            height: "150px",
            objectFit: "contain",
          }}
        /> */}
        <Link to={"/books/" + book._id + "/chatbot-settings/chat"}>
          <BookImage src={bookImageURL(book)} />
        </Link>
        {/* eslint-disable-next-line */}
        <BookStyles.innerbox>
          {book.indexStatus === 1 &&
          (book.type === "aws" ||
            book.type === "url" ||
            book.type === "youtube") ? (
            <>
              <BookActions
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px",
                }}
              >
                {book.chatbotId ? (
                  <>
                    <ProgressBarSpan>Processing</ProgressBarSpan>
                    <ProgressBarSpan style={{ marginLeft: "8px" }}>
                      {book.roundProgressPercentage}%
                    </ProgressBarSpan>
                  </>
                ) : (
                  <ProgressBarSpan>initializing....</ProgressBarSpan>
                )}
              </BookActions>
            </>
          ) : (
            <BookStyles.IndexStatus
              onClick={() => {
                onBookClick(book);
              }}
              background={book.activate ? "#21CA51" : "#EA4335"}
            >
              {book.activate ? "Activated" : "Deactivated"}
            </BookStyles.IndexStatus>
          )}

          <BookStyles.BookActionUl onClick={(e) => op.current.toggle(e)}>
            <BookStyles.BookActionLi></BookStyles.BookActionLi>
            <BookStyles.BookActionLi></BookStyles.BookActionLi>
            <BookStyles.BookActionLi></BookStyles.BookActionLi>
          </BookStyles.BookActionUl>
        </BookStyles.innerbox>
        <div>
          {book.indexStatus === 1 &&
          (book.type === "aws" ||
            book.type === "url" ||
            book.type === "youtube") ? (
            <>
              {book.roundProgressPercentage < 1 ? (
                <div>
                  <img
                    alt="imgProgress"
                    src={ProgressIndicator}
                    style={{ width: "100%" }}
                  ></img>
                </div>
              ) : (
                <ProgressBar
                  animated
                  variant={
                    book.roundProgressPercentage < 34
                      ? "warning"
                      : book.roundProgressPercentage < 68
                      ? "info"
                      : "success"
                  }
                  now={book.roundProgressPercentage}
                  style={{ height: "8px", width: "100%" }}
                />
              )}
            </>
          ) : null}
        </div>

        <BookStyles.BookContent>
          <BookStyles.BookTitle>{book.title}</BookStyles.BookTitle>

          {book.authors && book.authors[0].length > 0 && (
            <div>
              <BookStyles.BookAuthor>
                Author: {book.authors?.map((author: any) => author).join(" ,")}
              </BookStyles.BookAuthor>
            </div>
          )}

          <div style={{ display: "flex", justifyContent: "end" }}>
            <BookStyles.OverlayPanelStyle ref={op}>
              <CopyToClipboard
                text={`${process.env.REACT_APP_FRONTEND_URL}/guest-books/${
                  book._id || book.id
                }`}
                onCopy={(e) => {
                  NotificationManager.success(" Guest Chatbot URL copied.");

                  try {
                    op.current.toggle(e);
                  } catch (error) {}
                }}
              >
                <BookStyles.AddCategoryHeading
                /*  onClick={(e) => {
                  op.current.toggle(e);
                  onUpdateClick(book);
                }} */
                >
                  Copy guest chatbot URL
                </BookStyles.AddCategoryHeading>
              </CopyToClipboard>

              <BookStyles.AddCategoryHeading
                onClick={(e) => {
                  op.current.toggle(e);
                  onUpdateClick(book);
                }}
              >
                Edit chatbot
              </BookStyles.AddCategoryHeading>

              <BookStyles.AddCategoryHeading
                onClick={(e) => {
                  op.current.toggle(e);
                  onShowSessionClicked(book);
                }}
              >
                Show sessions
              </BookStyles.AddCategoryHeading>
            </BookStyles.OverlayPanelStyle>
          </div>
        </BookStyles.BookContent>
      </FirstInnerParent>
    </>
  );
};

export default BookContent;
