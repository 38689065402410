// import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import styled from "styled-components";
import MainHeader from "../header";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const HtmlTable = styled.div`
  table,
  th,
  td {
    border: 1px solid black;
  }
`;

const ButtonAction = styled.button`
  font-size: 12px;
  height: auto;
  width: auto;
  padding: 5px;
`;

const ActionMain = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`;

const ActionSection = () => {
  return (
    <ActionMain>
      <ButtonAction>Move</ButtonAction>
      <ButtonAction>Merge</ButtonAction>
    </ActionMain>
  );
};

const PdfTextExtrator = () => {
  const [text, setText] = useState("");
  const [error, setError] = useState("");

  const [mainArray, setMainArray] = useState<any>([]);

  const extractContent = async (pdf: any) => {
    let extractedText = "";
    const numPages = pdf.numPages;
    const updatedMainArray: any = [];

    let currentHeader = "";
    let lastHeader = "";
    let currentPara: string[] = [];
    let stringSplitArray = [];
    let requiredPara = "";
    let result: string = "";
    let isLastItemHeading = false;
    let lastHeadingFontSize = "";

    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      textContent.items.forEach((textItem: any) => {
        extractedText += textItem.str;
        console.log("textItem, ", textItem);

        // code here
        if (textItem.height > 12) {
          if (
            currentHeader &&
            isLastItemHeading &&
            lastHeadingFontSize === textItem.height
          ) {
            let newHeader = "";
            currentHeader = newHeader.concat(currentHeader, " ", textItem.str);
          } else {
            if (currentHeader) {
              updatedMainArray.push({
                header: currentHeader,
                para: currentPara,
              });
              // console.log("inside header: ");
            }
            currentHeader = textItem.str;
            currentPara = [];
          }
          isLastItemHeading = true;
          lastHeadingFontSize = textItem.height;
        } else if (
          currentHeader &&
          textItem.height <= 12 &&
          (textItem.height !== 0 || textItem.width !== 0) &&
          textItem.str !== "" &&
          textItem.str !== " "
        ) {
          isLastItemHeading = false;
          // currentPara.push(textItem.str);

          //code for seperating paragraphs by "."
          if (textItem.str.includes(".")) {
            stringSplitArray = textItem.str.split(" ");
            // console.log("stringsplitarray: ", stringSplitArray);
            stringSplitArray.forEach((item: any) => {
              if (item.endsWith(".")) {
                result = requiredPara.concat(result, " ", item);
                currentPara.push(result);
                // console.log("new PARA: ", result);
                // mainPara = result
                result = "";
                requiredPara = "";
                // console.log("paragraph changed.", mainPara);
              } else {
                result = requiredPara.concat(result, " ", item);
              }
            });
            // console.log(
            //   "stringsplitarray: ",
            //   stringSplitArray,
            //   " text: ",
            //   textItem.str
            // );
            // console.log("new PARA: ", result);
          } else {
            result = requiredPara.concat(result, " ", textItem.str);
          }

          // console.log("array: ", currentPara);
        }
      });

      if (lastHeader) {
        updatedMainArray.push({ header: lastHeader, para: currentPara });

        lastHeader = currentHeader;
        currentHeader = "";
        currentPara = [];
      }
    }
    if (currentHeader) {
      updatedMainArray.push({ header: currentHeader, para: currentPara });
      lastHeader = "";
      currentHeader = "";
      currentPara = [];
    }
    setMainArray(updatedMainArray);

    return extractedText;
  };
  // console.log("Main array : ", mainArray);

  const onFileChange = async (event: any) => {
    // step 1: Get the uploaded file
    const file = event.target.files[0];
    // step 2: Check if the file has been uploaded
    if (!file) {
      return;
    }
    // step 3: Check if the file is a PDF
    if (file.type !== "application/pdf") {
      setError("Only .pdf files are allowed!");
      return;
    }
    // step 4: Clear any previous error message;
    setError("");
    // step 5: Read the file contents
    const reader = new FileReader();
    reader.onload = async (event: any) => {
      // Convert the file to Unit8Array
      const typedArray = new Uint8Array(event.target?.result);
      // Parse the PDF content
      const pdf = await pdfjs.getDocument({ data: typedArray }).promise;
      // Extract text from PDF
      const extractedText = await extractContent(pdf);
      // Update the component state with the extracted text
      setText(extractedText);
      // Log the extracted text to the console
      // console.log("Extracted Text: ", extractedText);
    };
    // Read the file as an ArrayBuffer
    reader.readAsArrayBuffer(file);
  };

  let questionKeys = ["shall", "will", "must", "should"];

  return (
    <>
      <MainHeader isUserPage={true} activeFrom={"rfp"} />

      <input type="file" accept=".pdf" onChange={onFileChange} />
      {error && <div style={{ color: "red" }}>{error}</div>}
      {text && (
        <HtmlTable>
          {/* <h2>Text Extracted from PDF:</h2>
          <p>{text}</p> */}

          <table>
            <thead>
              <tr>
                <th>Headings</th>
                <th>Paragraphs</th>
              </tr>
            </thead>
            <tbody>
              {mainArray &&
                mainArray.length > 0 &&
                mainArray.map((item: any, idx: number) => (
                  <tr key={idx} style={{ borderBottom: "1px solid #000" }}>
                    <td
                      style={{ display: "flex", border: 0, fontWeight: "bold" }}
                    >
                      {item.header}
                    </td>
                    <td>
                      {item.para.map((paraItem: any, idx: number) => (
                        <div
                          key={idx}
                          style={{
                            borderBottom: "1px dashed #ccc",
                            background: questionKeys.some((key) => {
                              return paraItem.includes(key);
                            })
                              ? "yellow"
                              : "",
                            position: "relative",
                          }}
                        >
                          <p> {paraItem} </p>
                          <ActionSection />
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {/* <ul>
            {mainArray &&
              mainArray.length > 0 &&
              mainArray.map((item: any) => (
                <li>
                  <span>Heading :{item.header} </span>
                  <span>
                    ParaGraphs :{" "}
                    {item.para.map((paraItem: any) => (
                      <p>* {paraItem}</p>
                    ))}
                  </span>
                </li>
              ))}
          </ul> */}
        </HtmlTable>
      )}
    </>
  );
};

export default PdfTextExtrator;
