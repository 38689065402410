import { useContext } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import {
  DEVICE_MEDIUM,
  MEDIUM,
  MOBILE,
  TAB,
} from "../../../../constants/breakpoints";
import { PublicContext } from "../../../../context/PublicContext";
import DltrossSvg from "../../../../images/black-cross.svg";
import BubbleChatLiveIcon from "../../../../images/drop-chat-icon.svg";
import * as BubbleChatStyles from "./styled";

const Arrow = styled.img`
  height: 25px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-top: 3.5px;
  }
`;

const BubbleChatProtoType = ({
  isUsername,
  isEmail,
  isPhone,
  allFormValues,
  setShowPreviewPopup,
}: any) => {
  const { deviceWidth } = useContext(PublicContext);

  // console.log("allFormValues", allFormValues);
  return (
    <div style={{ flexDirection: "column", width: "100%" }}>
      <Col
        xs={12}
        style={{
          justifyContent: "space-between",
          padding: deviceWidth !== DEVICE_MEDIUM ? "0.5rem" : "0",
        }}
      >
        <BubbleChatStyles.HeadingText>
          Chatbot Preview
        </BubbleChatStyles.HeadingText>
        {deviceWidth !== DEVICE_MEDIUM && (
          <Arrow
            src={DltrossSvg}
            alt="dlt"
            onClick={() => {
              setShowPreviewPopup(false);
            }}
          />
        )}
      </Col>
      <BubbleChatStyles.MainContainer sm={6}>
        <BubbleChatStyles.RightColumn>
          <BubbleChatStyles.ChatPageHeader className="App-header">
            <Col sm={1}>
              <img src={BubbleChatLiveIcon} alt="online..." width={30} /> &nbsp;
            </Col>
            <Col
              sm={10}
              style={{
                alignItems: "center",
                cursor: "pointer",
                paddingLeft: "1rem",
              }}
            >
              <b style={{ fontSize: "18px" }}>
                {allFormValues && allFormValues.title
                  ? allFormValues.title
                  : "Welcome to Dropchat"}
              </b>
            </Col>
          </BubbleChatStyles.ChatPageHeader>

          <BubbleChatStyles.ChatInputBox>
            <BubbleChatStyles.RightContentRow>
              <BubbleChatStyles.ChatBotContent sm={12}>
                <BubbleChatStyles.ScrollDiv>
                  <BubbleChatStyles.QuestionAnswerSection>
                    {allFormValues &&
                      allFormValues.loginForm &&
                      allFormValues.loginForm !== "none" && (
                        <BubbleChatStyles.FormHeading>
                          {allFormValues && allFormValues.customText
                            ? allFormValues.customText
                            : "Fill out form to start chatting"}
                        </BubbleChatStyles.FormHeading>
                      )}
                    {allFormValues &&
                      allFormValues.loginForm &&
                      allFormValues.loginForm !== "none" &&
                      isUsername && (
                        <BubbleChatStyles.InputSection>
                          <BubbleChatStyles.FormLabelText>
                            {allFormValues && allFormValues.username
                              ? allFormValues.username
                              : "Full Name"}
                          </BubbleChatStyles.FormLabelText>
                          <BubbleChatStyles.FormBubbleInput />
                        </BubbleChatStyles.InputSection>
                      )}
                    {allFormValues &&
                      allFormValues.loginForm &&
                      allFormValues.loginForm !== "none" &&
                      isEmail && (
                        <BubbleChatStyles.InputSection>
                          <BubbleChatStyles.FormLabelText>
                            {allFormValues && allFormValues.email
                              ? allFormValues.email
                              : "Email"}
                          </BubbleChatStyles.FormLabelText>
                          <BubbleChatStyles.FormBubbleInput />
                        </BubbleChatStyles.InputSection>
                      )}
                    {allFormValues &&
                      allFormValues.loginForm &&
                      allFormValues.loginForm !== "none" &&
                      isPhone && (
                        <BubbleChatStyles.InputSection>
                          <BubbleChatStyles.FormLabelText>
                            {allFormValues && allFormValues.phone
                              ? allFormValues.phone
                              : "Phone"}
                          </BubbleChatStyles.FormLabelText>
                          <BubbleChatStyles.FormBubbleInput />
                        </BubbleChatStyles.InputSection>
                      )}
                  </BubbleChatStyles.QuestionAnswerSection>
                </BubbleChatStyles.ScrollDiv>
              </BubbleChatStyles.ChatBotContent>
              <BubbleChatStyles.FieldColumn sm={12}>
                <BubbleChatStyles.BookFetchForm>
                  {allFormValues && allFormValues.loginForm === "optional" && (
                    <BubbleChatStyles.SkipText>
                      Skip Form
                    </BubbleChatStyles.SkipText>
                  )}
                  <BubbleChatStyles.FieldSection>
                    <BubbleChatStyles.SaveButton type="button">
                      START CHAT
                    </BubbleChatStyles.SaveButton>
                  </BubbleChatStyles.FieldSection>
                  <a
                    href={
                      (allFormValues &&
                        allFormValues.loginForm &&
                        allFormValues.loginForm !== "none" &&
                        allFormValues.footerBrandingLink) ||
                      "https://dropchat.co/"
                    }
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <BubbleChatStyles.PoweredText>
                      {allFormValues && allFormValues.footerBranding
                        ? allFormValues.footerBranding
                        : "Powered By Dropchat"}
                    </BubbleChatStyles.PoweredText>
                  </a>
                </BubbleChatStyles.BookFetchForm>
              </BubbleChatStyles.FieldColumn>
            </BubbleChatStyles.RightContentRow>
          </BubbleChatStyles.ChatInputBox>
        </BubbleChatStyles.RightColumn>
      </BubbleChatStyles.MainContainer>
    </div>
  );
};

export default BubbleChatProtoType;
