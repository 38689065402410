import { Field, FieldArray, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import Slider from "react-rangeslider";
import styled from "styled-components";
import { UserContext } from "../../context/UserContext";
import { AdminContext } from "../../context/admin-context";
import myAxios from "../../myaxios";
import { AdminSetting } from "../../types";
import { parseErrorResponse } from "../../utils/utils";

const AppFeatureListContainer = styled.div`
  background: #ffffff;
  border: 1.5px solid rgba(220, 220, 220, 0.3);
  box-shadow: 0px 3px 3px rgba(31, 6, 42, 0.08);
  border-radius: 0px;
  padding: 30px;
  /* min-width: 850px; */
  width: 679px;
  position: relative;
  flex-direction: column;
`;

const AddAppFeatureContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const HeadingText = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #2f4c95;
`;

const OpenSettingContainer = styled.div`
  flex-direction: column;
`;

const KeyField = styled(Field)`
  border: none;
  color: #2f4c95;
  background: white;
`;

const ValueField = styled(Field)`
  width: 380px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  margin: 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const ValueFieldTextarea = styled(Field)`
  width: 380px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  height: 100px;
  margin: 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const ValueSelectField = styled.select`
  width: 380px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  margin: 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

// const TextNumberField = styled(Field)`
//   width: 380px;
//   background: #ffffff;
//   border: 1px solid #e6e6e6;
//   border-radius: 5px;
//   display: inline;
//   padding: 5px;
//   margin: 0px 0px 10px;
//   ::-webkit-input-placeholder {
//     font-style: normal;
//     font-weight: 400;
//     font-size: 13px;
//     line-height: 24px;
//     color: rgba(60, 59, 91, 0.8);
//     padding: 5px;
//   }
// `;

const TextValueField = styled(Field)`
  /* width: 380px; */
  width: 56px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  margin: 0px 0px 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const UpadateBtn = styled.button`
  border: none;
  width: 133px;
  height: 40px;
  background: #2f4c95;
  border-radius: 5px;
  color: white;
  /* margin: 23px 30px; */
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 8px 20px;
  &:hover {
    background: #0e2151;
    border: none;
  }
`;
const ErrorTag = styled(Col)`
  color: red;
  margin: 0px 6px;
  font-size: 11px;
  padding: 3px 0px;
  display: flex;
  justify-content: center;

  @media (max-width: 450px) and (min-width: 320px) {
    margin: 0px 6px;
    font-size: 10px;
  }
`;
const DivForSubmitBtn = styled.div`
  display: flex;
  justify-content: end;
`;
/* const Label = styled(FormLabel)`
  width: 46%;
  font-weight: bold;
`; */

const Gpt3Settings = () => {
  const { user } = useContext(UserContext);
  const { openAISettings, setOpenAISettings } = useContext(AdminContext);

  const [openSettingErrors, setOpenSettingErrors] = useState<AdminSetting[]>(
    []
  );
  // const [loading, setLoading] = useState<boolean>(false);
  const [tempretureValue, setTempretureValue] = useState<number>(0);
  const [topPValue, setTopPValue] = useState<number>(0);

  const saveOpenAISettings = (values: any) => {
    // setLoading(true);
    myAxios
      .post(`admin-settings/save-update-admin-settings`, {
        type: "OPENAI-SETTINGS",
        data: [...values.openaiSettings],
      })
      .then((response) => {
        if (response.data.success) {
          NotificationManager.success(
            "Settings save successfully...",
            "",
            1500
          );
          if (response.data && response.data.data) {
            setOpenAISettings(response.data.data);
          }
        } else {
          NotificationManager.error(response.data.message, "", 2000);
        }
        // setLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (user) {
      // setLoading(true);
      setTimeout(() => {
        myAxios
          .get(
            `${process.env.REACT_APP_SERVER_URL}/admin-settings/OPENAI-SETTINGS`
          )
          .then((response) => {
            if (response.data && response.data.data) {
              setOpenAISettings(response.data.data);
              response.data.data.forEach((dt: any) => {
                if (dt.key === "temperature") {
                  setTempretureValue(dt.value);
                }
                if (dt.key === "top_p") {
                  setTopPValue(dt.value);
                }
              });
            }
            // setLoading(false);
          })
          .catch((error) => {
            // setLoading(false);
            console.error(error, "error in getting app features");
          });
      }, 1000);
    }
  }, [user, setOpenAISettings]);

  return (
    <>
      <AppFeatureListContainer>
        <AddAppFeatureContainer>
          <HeadingText>OpenAI(GPT-3) Settings</HeadingText>
        </AddAppFeatureContainer>
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={{ openaiSettings: openAISettings && openAISettings }}
          validate={(values) => {
            const errors: {
              openaiSettings?: AdminSetting[];
            } = {};

            let openSettErrors: AdminSetting[] = [];
            let isError = false;
            values.openaiSettings.forEach((openSetting: AdminSetting) => {
              const val = openSetting.value + "";
              if (val && val.length > 0) {
                openSettErrors.push({ value: "" });
              } else {
                isError = true;

                openSettErrors.push({
                  value: `"${openSetting.key}" value is required`,
                });
              }
            });

            setOpenSettingErrors(openSettErrors);
            if (isError) {
              errors.openaiSettings = openSettErrors;
              // errors.subTemplates = [subtemplErrors]
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            saveOpenAISettings(values);
          }}
        >
          {({ isSubmitting, values, setFieldValue, errors }) => (
            <Form>
              <FieldArray name="openaiSettings">
                {(fieldArrayProps) => {
                  const { form } = fieldArrayProps;
                  const { values } = form;
                  const { openaiSettings } = values;

                  return (
                    <OpenSettingContainer>
                      {openaiSettings.map((openSetting: any, index: number) => (
                        <>
                          <div key={index} style={{ margin: "0px 10px" }}>
                            {openSetting.inputType === "rangeSlider" ? (
                              <Col>
                                {/* <Label>File Processing Message</Label> */}
                                {/* <Label>
                                        {openaiSettings.placeHolder}
                                      </Label> */}
                                <KeyField
                                  type="text"
                                  name={`openaiSettings[${index}].key`}
                                  disabled
                                />
                                <div className="slider">
                                  <Slider
                                    max={
                                      openSetting.key === "temperature" ? 2 : 1
                                    }
                                    min={0}
                                    step={0.05}
                                    tooltip={false}
                                    value={
                                      openSetting.key === "temperature"
                                        ? tempretureValue
                                        : topPValue
                                    }
                                    onChange={(value) => {
                                      // console.log("value -- ", value);

                                      if (openSetting.key === "temperature") {
                                        setTempretureValue(
                                          Number(
                                            parseFloat(`${value}`).toFixed(2)
                                          )
                                        );
                                      } else if (openSetting.key === "top_p") {
                                        setTopPValue(
                                          Number(
                                            parseFloat(`${value}`).toFixed(2)
                                          )
                                        );
                                      }

                                      setFieldValue(
                                        `openaiSettings[${index}].value`,
                                        parseFloat(`${value}`).toFixed(2)
                                      );
                                    }}
                                  />
                                </div>
                                <TextValueField
                                  type="number"
                                  min={`openaiSettings[${index}].min`}
                                  max={`openaiSettings[${index}].max`}
                                  step="0.05"
                                  presicion={2} //very important
                                  novalidate
                                  name={`openaiSettings[${index}].value`}
                                  onChange={(e: any) => {
                                    let { value, min, max } = e.target;
                                    //  console.log("value 1 -- ", value);
                                    // value = Number(value);
                                    // console.log("value 1 -- ", value);
                                    const maxLimit = Number(
                                      openaiSettings[index].max
                                    );
                                    if (value < 0 || value > maxLimit)
                                      value = Math.max(
                                        Number(min),
                                        Math.min(Number(max), Number(value))
                                      );

                                    if (openSetting.key === "temperature") {
                                      setTempretureValue(value);
                                    } else if (openSetting.key === "top_p") {
                                      setTopPValue(value);
                                    }

                                    //  setTempretureValue(value);
                                    setFieldValue(
                                      `openaiSettings[${index}].value`,
                                      value
                                    );
                                  }}
                                />
                              </Col>
                            ) : (
                              <div>
                                <KeyField
                                  type="text"
                                  name={`openaiSettings[${index}].key`}
                                  disabled
                                />
                                {openSetting.key === "model" ? (
                                  <ValueSelectField
                                    name={`openaiSettings[${index}].value`}
                                    defaultValue={openSetting.value}
                                    // className="ExpandedpriorityDropDown"
                                    // value
                                    onChange={(e) => {
                                      setFieldValue(
                                        `openaiSettings[${index}].value`,
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option value="gpt-3.5-turbo" selected>
                                      gpt-3.5-turbo
                                    </option>
                                    <option value="gpt-3.5-turbo-16k">
                                      gpt-3.5-turbo-16k
                                    </option>
                                    <option value="gpt-4o">gpt-4o</option>
                                  </ValueSelectField>
                                ) : openSetting.key !== "prompt" ? (
                                  <ValueField
                                    type="number"
                                    name={`openaiSettings[${index}].value`}
                                  />
                                ) : (
                                  <ValueFieldTextarea
                                    type="text"
                                    component="textarea"
                                    name={`openaiSettings[${index}].value`}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                          {openSettingErrors &&
                            openSettingErrors.length > 0 &&
                            openSettingErrors[index] &&
                            openSettingErrors[index].value && (
                              <Row>
                                <ErrorTag>
                                  {openSettingErrors[index].value}
                                </ErrorTag>
                              </Row>
                            )}
                        </>
                      ))}
                    </OpenSettingContainer>
                  );
                }}
              </FieldArray>
              <DivForSubmitBtn>
                <UpadateBtn type="submit">Update</UpadateBtn>
              </DivForSubmitBtn>
            </Form>
          )}
        </Formik>
      </AppFeatureListContainer>
    </>
  );
};

export default Gpt3Settings;
