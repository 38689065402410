import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { LoaderContext } from "../../../context/loader-context";
import DltrossSvg from "../../../images/white-cross.svg";
import CustomMarkdown from "../../../utils/custom-markdown";
import { MEDIUM, MOBILE, TAB } from "../../../constants/breakpoints";

const DivForDltPopUp = styled.div`
  position: fixed;
  height: 500px;
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  z-index: 111;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 90%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 90%;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 50%;
  }
`;

const DltHeader = styled.div`
  width: 100%;
  background: #1585b5;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: space-between;
    height: auto;
    padding: 10px;
    flex-direction: row-reverse;
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: space-between;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    justify-content: space-between;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
`;
const Arrow = styled.img`
  height: 25px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-top: 3.5px;
  }
`;

const SpanDltPopup = styled.span`
  font-family: "Gill Sans MT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const ShowTranscriptBox = ({ setShow, ytTranscriptLink }: any) => {
  const { setShowLoader } = useContext(LoaderContext);

  const [transcript, setTranscript] = useState<any>();

  useEffect(() => {
    if (ytTranscriptLink && ytTranscriptLink.length > 0) {
      setShowLoader(true);

      axios
        .get(ytTranscriptLink)
        .then((response) => {
          setTranscript(response.data);
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  }, [ytTranscriptLink, setShowLoader]);

  return (
    <DivForDltPopUp>
      <DltHeader>
        <Arrow
          src={DltrossSvg}
          alt="dlt"
          onClick={() => {
            setShow(false);
          }}
        />
        <SpanDltPopup>YouTube Transcript</SpanDltPopup>
      </DltHeader>
      <Row>
        <Col sm={12}>
          <p
            style={{
              padding: "10px",
              height: "460px",
              overflowY: "scroll",
              width: "100%",
            }}
          >
            <CustomMarkdown text={transcript} />
          </p>
        </Col>
      </Row>
    </DivForDltPopUp>
  );
};

export default ShowTranscriptBox;
