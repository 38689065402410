import mixpanel from "mixpanel-browser";
import moment from "moment";
export const isValidEmail = (email: string) => {
  const regx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regx.test(email)) {
    return true;
  }
  return false;
};

export const isPhoneNumber = (phone: string) => {
  const regx =
    /^(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})$/im;
  if (regx.test(phone)) {
    return true;
  }
  return false;
};

export const getFileNameFromURL = (url: string) => {
  if (!url || url.length === 0) return "";

  // Split the URL by slashes
  var segments = url.split("/");

  // Get the last segment which should be the file name
  var fileName = segments[segments.length - 1];

  // If the file name contains a query parameter, remove it
  if (fileName.includes("?")) {
    fileName = fileName.split("?")[0];
  }

  return fileName;
};

export const parseErrorResponse = (error: any) => {
  // Unable to process your request, please try later

  try {
    return error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message[0].messages &&
      error.response.data.message[0].messages[0].message
      ? error.response.data.message[0].messages[0].message
      : "Unable to process your request, please try later";
  } catch (error) {
    return "Unable to process your request, please try later";
  }
};

export const matchYoutubeUrl = (url: string) => {
  if (!url || url.length === 0) return false;

  var p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const matches = url.match(p);
  if (matches) {
    // console.log("url.match(p)[1] --- ", matches[1]);
    return matches[1];
  }
  return false;
};

export const bubbleChatCode = (bookId: string, encrptedData: string) => {
  // const bookUrl = `${process.env.REACT_APP_FRONTEND_URL}/public/bubble-chat/${bookId}/${encrptedData}`;

  return `
  
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js" type="text/javascript"></script> 
    <script type="text/javascript" async src="${process.env.REACT_APP_SERVER_URL}/public/bubble-script/${bookId}/false"></script>
`;
};

export const iFrameCode = (
  bookId: string,
  iframeWidth: string,
  iframeHeight: string
) => {
  const bookUrl = `${process.env.REACT_APP_FRONTEND_URL}/public/iframe-chat/${bookId}`;

  return `

<iframe style="min-height: ${iframeHeight}px; margin: 0px auto; display: block;" xml="lang" src="${bookUrl}" width="${iframeWidth}%" height="${iframeHeight}" frameborder="0"></iframe>
`;
};

export const mixPanel = (email: string, type: string, From: string) => {
  if (process.env.REACT_APP_ENV === "production" && !isTestUser(email)) {
    mixpanel.init("63627b4630aa19daf56cad2dd23779ed", {
      debug: true,
    });

    mixpanel.identify(email);

    mixpanel.track(type, {
      eventType: From,
    });
  }
};

export const numberWithComma = (number: number | string | undefined) => {
  const numberLocale = number?.toLocaleString("en-US");
  return numberLocale;
};

export const convertSecondsToDuration = (second: number) => {
  try {
    const duration = moment.duration(second, "seconds");
    const days = String(duration.days()).padStart(2, "0");
    const hours = String(duration.hours()).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");

    if (duration.days() > 0) return `${days}:${hours}:${minutes}:${seconds}`;
    if (duration.hours() > 0) return `${hours}:${minutes}:${seconds}`;
    if (duration.minutes() > 0) return `${minutes}:${seconds}`;

    return `${seconds}`;
  } catch {
    return "---";
  }
};
export const formattedAnswer = (answer: string) => {
  const urlRegex = /(\bhttps?:\/\/\S+\b)/gi;

  const emailRegex = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

  if (/<a\s+[^>]*href\s*=/i.test(answer)) {
    let modifiedTextWithEmail = answer.replace(
      emailRegex,
      '<a href="mailto:$1" target="_blank">$1</a>'
    );
    modifiedTextWithEmail = modifiedTextWithEmail.replaceAll("\n", "<br>");

    return modifiedTextWithEmail;
  } else {
    // Replace URLs with clickable anchor tags
    const modifiedText = answer.replace(
      urlRegex,
      '<a href="$1" target="_blank">$1</a>'
    );

    let modifiedTextWithEmail = modifiedText.replace(
      emailRegex,
      '<a href="mailto:$1" target="_blank">$1</a>'
    );

    modifiedTextWithEmail = modifiedTextWithEmail.replaceAll("\n", "<br>");

    return modifiedTextWithEmail;
  }
};

export const formattedMarkdown = (answer: string) => {
  const text = answer; //.replaceAll("\n\n", " &nbsp;  \n &nbsp; \n");

  return text;
};

export const isTestUser = (email: string | undefined | null) => {
  if (process.env.REACT_APP_ENV !== "production") {
    return true;
  }
  if (
    email &&
    (email.includes("@aripratech.com") ||
      email.includes("+arp") ||
      email.includes("aripra"))
  ) {
    return true;
  }

  return false;
};
export const checkSettingsChanges = (oldSettings: any, newSettings: any) => {
  const hasCustomTitleChanged =
    JSON.stringify(oldSettings.bookSetting.title) !==
    JSON.stringify(newSettings.bookSetting.title);
  const hasChatModelChanged =
    JSON.stringify(oldSettings.bookSetting.model) !==
    JSON.stringify(newSettings.bookSetting.model);

  const hasTemperatureChanged =
    JSON.stringify(oldSettings.bookSetting.temperature) !==
    JSON.stringify(newSettings.bookSetting.temperature);
  const hasTopPChanged =
    JSON.stringify(oldSettings.bookSetting.topP) !==
    JSON.stringify(newSettings.bookSetting.topP);
  const hasAllowMemoryChanged =
    JSON.stringify(oldSettings.bookSetting.allowMemory) !==
    JSON.stringify(newSettings.bookSetting.allowMemory);
  const hasCustomAnswerChanged =
    JSON.stringify(oldSettings.bookSetting.customAnswer) !==
    JSON.stringify(newSettings.bookSetting.customAnswer);
  const hasQaTemplateChanged =
    JSON.stringify(oldSettings.bookSetting.qaTemplate) !==
    JSON.stringify(newSettings.bookSetting.qaTemplate);

  const hasIsSummarizedChanged =
    JSON.stringify(oldSettings.bookSetting.isSummarized) !==
    JSON.stringify(newSettings.bookSetting.isSummarized);

  const hasIsFeatureChatbotChanged =
    JSON.stringify(oldSettings.isFeatureChatbot) !==
    JSON.stringify(newSettings.isFeatureChatbot);
  const hasChatInterfaceTypeChanged =
    JSON.stringify(oldSettings.chatInterfaceType) !==
    JSON.stringify(newSettings.chatInterfaceType);
  const hasGreetingSettingChanged =
    JSON.stringify(oldSettings.greetingSetting) !==
    JSON.stringify(newSettings.greetingSetting);
  const hasWaiverSettingChanged =
    JSON.stringify(oldSettings.waiverSetting) !==
    JSON.stringify(newSettings.waiverSetting);
  const hasChatWithHumanSettingChanged =
    JSON.stringify(oldSettings.chatWithHumanSetting) !==
    JSON.stringify(newSettings.chatWithHumanSetting);
  const hasSeoMetaSettingChanged =
    JSON.stringify(oldSettings.seoMetaSetting) !==
    JSON.stringify(newSettings.seoMetaSetting);

  const hasEscalateEmailSettingsChanged =
    JSON.stringify(oldSettings.escalateEmailSetting) !==
    JSON.stringify(newSettings.escalateEmailSetting);
  const hasEmailSettingsChanged =
    JSON.stringify(oldSettings.emailSetting) !==
    JSON.stringify(newSettings.emailSetting);

  const updatedSettings: any = {};

  if (hasEmailSettingsChanged) {
    updatedSettings.emailSetting = newSettings.emailSetting;
  }

  if (hasEscalateEmailSettingsChanged) {
    updatedSettings.escalateEmailSetting = newSettings.escalateEmailSetting;
  }
  if (hasCustomTitleChanged) {
    updatedSettings.title = {
      new: newSettings.bookSetting.title,
      old: oldSettings.bookSetting.title,
    };
  }
  if (hasChatModelChanged) {
    updatedSettings.model = {
      new: newSettings.bookSetting.model,
      old: oldSettings.bookSetting.model,
    };
  }

  if (hasTemperatureChanged) {
    updatedSettings.temperature = {
      new: newSettings.bookSetting.temperature,
      old: oldSettings.bookSetting.temperature,
    };
  }
  if (hasTopPChanged) {
    updatedSettings.topP = {
      new: newSettings.bookSetting.topP,
      old: oldSettings.bookSetting.topP,
    };
  }
  if (hasAllowMemoryChanged) {
    updatedSettings.allowMemory = {
      new: newSettings.bookSetting.allowMemory,
      old: oldSettings.bookSetting.allowMemory,
    };
  }
  if (hasCustomAnswerChanged) {
    updatedSettings.customAnswer = {
      new: newSettings.bookSetting.customAnswer,
      old: oldSettings.bookSetting.customAnswer,
    };
  }
  if (hasQaTemplateChanged) {
    updatedSettings.qaTemplate = {
      new: newSettings.bookSetting.qaTemplate,
      old: oldSettings.bookSetting.qaTemplate,
    };
  }
  if (hasIsSummarizedChanged) {
    updatedSettings.isSummarized = {
      new: newSettings.bookSetting.isSummarized,
      old: oldSettings.bookSetting.isSummarized,
    };
  }

  if (hasIsFeatureChatbotChanged) {
    updatedSettings.isFeatureChatbot = {
      new: newSettings.isFeatureChatbot,
      old: oldSettings.isFeatureChatbot,
    };
  }
  if (hasChatInterfaceTypeChanged) {
    updatedSettings.chatInterfaceType = {
      new: newSettings.chatInterfaceType,
      old: oldSettings.chatInterfaceType,
    };
  }
  if (hasGreetingSettingChanged) {
    updatedSettings.greetingSetting = {
      new: newSettings.greetingSetting,
      old: oldSettings.greetingSetting,
    };
  }
  if (hasWaiverSettingChanged) {
    updatedSettings.waiverSetting = {
      new: newSettings.waiverSetting,
      old: oldSettings.waiverSetting,
    };
  }
  if (hasChatWithHumanSettingChanged) {
    updatedSettings.chatWithHumanSetting = {
      new: newSettings.chatWithHumanSetting,
      old: oldSettings.chatWithHumanSetting,
    };
  }
  if (hasSeoMetaSettingChanged) {
    updatedSettings.seoMetaSetting = {
      new: newSettings.seoMetaSetting,
      old: oldSettings.seoMetaSetting,
    };
  }

  return updatedSettings;
};
