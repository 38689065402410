import { useContext, useState } from "react";
import { Col } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { MEDIUM } from "../../constants/desktop";
import { MOBILE } from "../../constants/mobile";
import { TAB } from "../../constants/tab";
import { UserContext } from "../../context/UserContext";
import { LoaderContext } from "../../context/loader-context";
import { NotesContext } from "../../context/notes-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import myAxios from "../../myaxios";
import { parseErrorResponse } from "../../utils/utils";

const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 59%);
  z-index: 9999;
`;

const DivForDltPopUp = styled.div`
  position: fixed;
  width: 30%;
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  z-index: 1111;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 90%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 58%;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 32%;
  }
`;

const DltHeader = styled.div`
  width: 100%;
  height: 45px;
  background: rgba(47, 51, 73, 1);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
  }
`;

// const Arrow = styled.img`
//   height: 25px;
//   cursor: pointer;
//   left: 98.5% !important;
//   top: -10px;
//   z-index: 1111;
//   position: absolute;
//   background: #fff;
//   border-radius: 98%;

//   @media (max-width: 1199px) and (min-width: 320px) {
//     left: 90%;
//   }
// `;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #2f4c95;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: white;
  @media (max-width: ${MOBILE.maxWidth}px) {
    color: white;
  }
`;

const LeftDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & label {
    font-weight: bold;
    margin-right: 10px;
    align-self: flex-start;
  }
  & input {
    padding: 5px;
  }
`;

const ConfirmationAlertText = styled.span`
  margin-top: 1rem;
  color: #ea5455;
  font-weight: bold;
  padding: 0rem 1rem;
  text-align: center;
`;

const SubmitButton = styled.button`
  background: #ea5455;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 20px;
  width: 25%;
  &:hover {
    background: #0e328eb0;
    border: none;
  }
`;

const SubmitButtonColumn = styled(Col)`
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpanCancel = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #2f4c95;
  margin: 4px 13px;
  cursor: pointer;
`;

const FlashcardsCatActionBox = ({ selectedDetails }: any) => {
  const {
    showFlashcardsOptionPopup,
    setShowFlashcardsOptionPopup,
    setNotesCategories,
    notesCategories,
  } = useContext(NotesContext);
  const { setShowLoader } = useContext(LoaderContext);
  const [newNameValue, setNewNameValue] = useState("");
  const { user } = useContext(UserContext);
  return showFlashcardsOptionPopup && selectedDetails.action === "rename" ? (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <SpanDltPopup>Rename Flashcards Category</SpanDltPopup>
          </DltHeader>

          {/* <Row> */}
          <LeftDiv>
            <ConfirmationAlertText>
              Do you really want to rename this flashcards category?
            </ConfirmationAlertText>

            <Col
              style={{
                marginTop: "1rem",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  paddingRight: ".5rem",
                }}
              >
                Name:
              </span>
              <input
                type="text"
                value={newNameValue}
                onChange={(e) => {
                  setNewNameValue(e.target.value);
                }}
                style={{
                  width: "100%",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </Col>
          </LeftDiv>
          {/* </Row> */}

          <SubmitButtonColumn>
            <SubmitButton
              type="button"
              onClick={() => {
                setShowLoader(true);
                myAxios
                  .post(
                    `${process.env.REACT_APP_SERVER_URL}/notes-categories/edit`,
                    {
                      name: newNameValue,
                      noteCategoryId: selectedDetails.notesCategoryId,
                    }
                  )
                  .then((response) => {
                    if (response.data && response.data.success) {
                      NotificationManager.success(
                        "Flashcards category name is successfully changed."
                      );
                      trackEvent({
                        email: getUsersEmail(user),
                        eventCategory: EventCategory.CHATBOT,
                        event: ChatBotEvents.USER_EDITED_FLASHCARD_CATEGORY,
                        subCategory: SubCategory.EVENT,
                        rawData: {
                          newName: newNameValue,
                          noteCategoryId: selectedDetails.notesCategoryId,
                        },
                      });
                      if (notesCategories) {
                        let objIndex: any = notesCategories.findIndex(
                          (obj) => obj._id === selectedDetails.notesCategoryId
                        );

                        notesCategories[objIndex].name =
                          response.data.data.name;

                        setNotesCategories(notesCategories);
                      }
                      setShowLoader(false);
                      setShowFlashcardsOptionPopup(false);
                      setNewNameValue("");
                    } else {
                      NotificationManager.error(
                        "Not able to change flashcards category name, Please try again!"
                      );
                      setShowLoader(false);
                      setShowFlashcardsOptionPopup(false);
                      setNewNameValue("");
                    }
                  })
                  .catch((error) => {
                    NotificationManager.error(parseErrorResponse(error));
                    setShowLoader(false);
                    setShowFlashcardsOptionPopup(false);
                    setNewNameValue("");
                  });
              }}
            >
              Submit
            </SubmitButton>
            <SpanCancel
              onClick={() => {
                setShowFlashcardsOptionPopup(false);
                setNewNameValue("");
              }}
            >
              {"Cancel"}
            </SpanCancel>
          </SubmitButtonColumn>
        </DivForDltPopUp>
      </MainDiv>
    </>
  ) : showFlashcardsOptionPopup && selectedDetails.action === "remove" ? (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <SpanDltPopup>Remove Flashcards Category</SpanDltPopup>
          </DltHeader>

          {/* <Row> */}
          <LeftDiv style={{ marginTop: ".8rem", marginBottom: "1rem" }}>
            <ConfirmationAlertText>
              Do you really want to remove this flashcards category?
            </ConfirmationAlertText>
          </LeftDiv>
          {/* </Row> */}

          <SubmitButtonColumn>
            <SubmitButton
              type="button"
              onClick={() => {
                setShowLoader(true);
                myAxios
                  .delete(
                    `${process.env.REACT_APP_SERVER_URL}/notes-categories/${selectedDetails.notesCategoryId}`
                  )
                  .then((response) => {
                    if (response.data && response.data.success) {
                      NotificationManager.success(
                        "Flashcards category has been removed successfully"
                      );
                      trackEvent({
                        email: getUsersEmail(user),
                        eventCategory: EventCategory.CHATBOT,
                        event: ChatBotEvents.USER_REMOVED_FLASHCARD_CATEGORY,
                        subCategory: SubCategory.EVENT,
                        rawData: {
                          noteCategoryId: selectedDetails.notesCategoryId,
                        },
                      });
                      setNotesCategories(response.data.data);
                      setShowLoader(false);
                      setShowFlashcardsOptionPopup(false);
                    } else {
                      NotificationManager.error(
                        "Not able to remove flashcards category, Please try again!"
                      );
                      setShowLoader(false);
                      setShowFlashcardsOptionPopup(false);
                    }
                  })
                  .catch((error) => {
                    NotificationManager.error(parseErrorResponse(error));
                    setShowLoader(false);
                    setShowFlashcardsOptionPopup(false);
                  });
              }}
            >
              Submit
            </SubmitButton>
            <SpanCancel
              onClick={() => {
                setShowFlashcardsOptionPopup(false);
              }}
            >
              {"Cancel"}
            </SpanCancel>
          </SubmitButtonColumn>
        </DivForDltPopUp>
      </MainDiv>
    </>
  ) : (
    <></>
  );
};

export default FlashcardsCatActionBox;
