import { Button, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CrossButton from "../../../images/cross.svg";

import { useContext, useState } from "react";
// import "./App.css";

const MainSection = styled(Container)`
  margin: 5%;
  margin-top: 15%;
  position: fixed;
  display: block;
  width: 100%;
  background-color: rgb(0 0 0 / 59%);
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const FormInputTextareaQuestion = styled.textarea`
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
  height: 2rem;
  overflow: hidden;
  padding-left: 5px;
  min-width: 100%;
`;

const AddButton = styled.button`
  margin-top: 2%;
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 16%;
  background: #1585b5;
  padding: 0.5rem;
  color: #fff;
  marginright: 0.5rem;
  &:hover {
    background: #0b5f82;
  }
  &:focus {
    background: #0b5f82;
  }
`;

const CloseButton = styled.button`
  margin-left: 96.5%;
  border:none;  
  background: #fff;
   &:hover {
    // background: #0b5f82;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }
  
 
`;

const Content = styled.div`
  align-items: center;
  flex-direction: column;
  margin-top: 2%;
  padding-bottom: 2%;
`;

const EditQuestionPopup = ({
  setShowEditPopup,
  questionToEdit,
  setQuestionToEdit,
  setNewQuestion,
}: any) => {
  return (
    <MainSection>
      <CloseButton
        type="button"
        onClick={() => {
          setShowEditPopup(false);
        }}
      >
        <img src={CrossButton} alt="" />
      </CloseButton>
      <Content>
        <FormInputTextareaQuestion
          value={questionToEdit}
          onChange={(e: any) => {
            setQuestionToEdit(e.target.value);
          }}
        />
        <AddButton
          type="button"
          onClick={() => {
            setShowEditPopup(false);
            setNewQuestion(questionToEdit)
          }}
        >
          {" "}
          + Add Question
        </AddButton>
      </Content>
    </MainSection>
  );
};

export default EditQuestionPopup;
