import { Field, Form, Formik, FormikHelpers, FormikValues } from "formik";
import React from "react";
import { FieldSection } from "../styled-public";
import styled from "styled-components";

const FieldLabel = styled.label`
  margin-top: 2%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(51, 48, 60, 0.8);
  text-overflow: ellipsis;
`;

const FormInputTextarea = styled.textarea`
  padding: 8px;
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
  height: 7rem;
  overflow: hidden;
`;

function Overview({ setFieldValue, values }: any) {
  return (
    <>
      <h5>
        <b>No worries—you can always edit it later.</b>
      </h5>
      <h6>Just enter some info, and our AI will handle the rest.</h6>

      <FieldSection>
        <FieldLabel>Company Overview</FieldLabel>
        <FormInputTextarea
          defaultValue={values.companyOverview}
          name="companyOverview"
          onChange={(e: any) => {
            setFieldValue(`companyOverview`, e.target.value);
          }}
        />
      </FieldSection>
    </>
  );
}

export default Overview;
