import { Field, Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { BookContext } from "../../context/BookContext";
import { LoaderContext } from "../../context/loader-context";
import { USER_ADDED_SOURCE_IN_A_CHATBOT } from "../../customer-io/events";
import DltrossSvg from "../../images/cross.svg";
import myAxios from "../../myaxios";
import { isTestUser, parseErrorResponse } from "../../utils/utils";
import { UserContext } from "../../context/UserContext";

declare var _cio: any;

const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 59%);
  z-index: 11;
`;

const DivForDltPopUp = styled.div`
  position: fixed;
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 8%);
  z-index: 1111;
  width: 30% !important;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;

const DltHeader = styled.div`
  width: 100% !important;
  height: 35px;
  background: #1585b5;
  display: flex;
  justify-content: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;

const Arrow = styled.img`
  height: 25px;
  cursor: pointer;
  left: 98.5% !important;
  top: -10px;
  z-index: 1111;
  position: absolute;
  background: #fff;
  border-radius: 98%;

  @media (max-width: 1199px) and (min-width: 320px) {
    left: 90%;
  }
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const LeftDiv = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  overflow: auto;
  & label {
    font-weight: bold;
    margin-right: 10px;
    align-self: center;
  }
  & input {
    padding: 5px;
  }
`;

const SubmitButton = styled.button`
  background: #1585b5;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer !important;
  padding: 5px 15px;
  flex: 1;
  &:hover {
    background: #0e328eb0;
    border: none;
  }
`;

const SubmitButtonColumn = styled(Col)`
  padding: 20px 15px 10px 15px;
  justify-content: space-between;
  box-shadow: 0px -4px 5px 3px #00000030;
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;

const BookFetchForm = styled(Form)`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
`;
const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  justify-content: center;
  color: red;
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
`;

const CancelButton = styled(Button)`
  width: 100%;
  background: none;
  flex: 1;
  color: #1585b5;
  border: none;
  font-weight: bold;
  &:hover {
    color: #1585b5;
    background: none;
  }
`;

const FormLabelDocInput = styled(FormLabel)`
  background: #ccc;
  width: 50%;
  padding: 5px 10px;
  text-align: center;
`;

const AddMoreFileBox = ({ chatbot, pageLimitChange, pageLimit }: any) => {
  const { user } = useContext(UserContext);
  const { setShowLoader } = useContext(LoaderContext);
  const { showAddMoreFileBox, setShowAddMoreFileBox } = useContext(BookContext);
  const [fileObject, setFileObject] = useState<any>();

  const validateFileSubmission = (formData: any) => {
    const errors: any = {};
    if (!fileObject) {
      errors.file = "Please Select Source!";
    }

    return errors;
  };

  const addMoreBook = async (values: any, resetForm: any) => {
    let dataToSave = {
      id: chatbot._id,
    };

    let addFileInput;

    if (fileObject) {
      const data = new FormData();
      data.append("files.files", fileObject);
      data.append("data", JSON.stringify(dataToSave));
      addFileInput = data;
    } else {
      addFileInput = dataToSave;
    }

    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/books/add-file", addFileInput)
      .then((response) => {
        if (response.data && response.data.success) {
          resetForm();
          setFileObject(undefined);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "File added to chatbot successfully!"
          );
          setShowLoader(false);
          setShowAddMoreFileBox(false);
          //refresh paginated book list
          pageLimitChange(pageLimit);
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
          setShowAddMoreFileBox(false);
        }
      })
      .catch((error) => {
        // handle error
        resetForm();
        setShowLoader(false);
        setFileObject(undefined);
        NotificationManager.error(parseErrorResponse(error));
        setShowAddMoreFileBox(false);
      });
    if (!isTestUser(user?.email))
      _cio.track(USER_ADDED_SOURCE_IN_A_CHATBOT, {
        name: "User add a book using create chatbot form",
      });
  };

  return showAddMoreFileBox ? (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <Arrow
              src={DltrossSvg}
              alt="dlt"
              onClick={() => {
                setShowAddMoreFileBox(false);
              }}
            />
            <SpanDltPopup>Add Source</SpanDltPopup>
          </DltHeader>

          <Row>
            <LeftDiv>
              <Formik
                initialValues={{
                  title: "",
                  image: "",
                  authors: [],
                  industryIdentifiers: [],
                  file: "",
                  selectedRadio: "book",
                  url: "",
                  webUrl: "",
                }}
                validate={validateFileSubmission}
                onSubmit={(values, { resetForm }) => {
                  setShowLoader(true);
                  addMoreBook(values, resetForm);
                }}
              >
                {({ values, errors, touched }: any) => (
                  <BookFetchForm>
                    <FieldSection>
                      <FormLabel></FormLabel>
                      <FormLabelDocInput htmlFor="document">
                        Select Source
                      </FormLabelDocInput>
                      <FormInput
                        type="file"
                        id="document"
                        name="file"
                        accept=".pdf"
                        hidden
                        placeholder="select custom document/file"
                        onChange={(e: any) => {
                          setFileObject(e.target.files[0]);
                        }}
                      />

                      {/* , .txt, .mp3, .html, .ogg, .wav, .flac, .mp4, .epub, .md, .mdx */}

                      <FormLabel>
                        {fileObject ? fileObject.name : "No Source Selected"}
                      </FormLabel>
                      {errors.file && touched.file && (
                        <FormError className="formError">
                          {errors.file}
                        </FormError>
                      )}
                    </FieldSection>
                    <SubmitButtonColumn>
                      <SubmitButton>Add Source</SubmitButton>
                      <CancelButton
                        type="button"
                        onClick={() => setShowAddMoreFileBox(false)}
                      >
                        Cancel
                      </CancelButton>
                    </SubmitButtonColumn>
                  </BookFetchForm>
                )}
              </Formik>
            </LeftDiv>
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </>
  ) : (
    <></>
  );
};

export default AddMoreFileBox;
