import axios from "axios";
import { Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
import {
  BubbleChatbotEvents,
  EventCategory,
  IFrameChatbotEvents,
  ShareChatbotEvents,
  Source,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { trackEvent } from "../../event-utils/track-event";
// import { isPhoneNumber } from "../../utils/utils";
// import { useEffect } from "react";
const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 59%);
  z-index: 9999;
`;

const DivForDltPopUp = styled.div`
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 8%);
  z-index: 1111;
  width: 30%;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    top: 50%;
    height: 100%;
    width: 100%;
    position: relative;
  }
  @media (min-width: ${TAB.minWidth}px) {
    position: relative;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    position: fixed;
    width: 30%;
  }
`;

const DltHeader = styled.div`
  width: 100% !important;
  background: #2f3349;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: center;
    height: auto;
    padding: 10px;
    flex-direction: row-reverse;
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: center;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    justify-content: center;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;
const LabelHeading = styled.label`
  /* padding: 2rem 3rem; */
  width: 100%;
  color: #000;
  font-size: 16px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
  }
`;

const InputField = styled.input`
  border: 1px solid #eaeaea;
  /* padding: 2rem 3rem; */
  width: 100%;
  height: 40px;
  background: #eaeaea;
  border-radius: 4px;
  padding: 10px 10px 10px 10px;
  &::placeholder {
    padding-left: 10px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    padding: 5px;
    height: 30px;
    &::placeholder {
      padding-left: 5px;
    }
  }
`;
const TextArea = styled.textarea`
  border: 1px solid #eaeaea;
  /* padding: 2rem 3rem; */
  width: 100%;
  height: 80px;
  background: #eaeaea;
  border-radius: 4px;
  padding: 10px 10px 10px 10px;
  &::placeholder {
    padding-left: 10px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    padding: 5px;
    height: 60px;
    &::placeholder {
      padding-left: 5px;
    }
  }
`;
const FormMainDiv = styled.div`
  margin-bottom: 10px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-bottom: 0px;
  }
`;
const SubmitBtn = styled.button`
  background: #1585b5;
  padding: 0.3rem 0.1rem;
  color: #fff;
  border: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    padding: 0.3rem 0.1rem;
    background: #0b5f82;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    padding: 0.1rem 0.1rem;
    &:hover {
      padding: 0.1rem 0.1rem;
      background: #0b5f82;
    }
  }
`;
const CancelBtn = styled.div`
  width: 100%;
  align-items: center;
  padding: 0.3rem 0.1rem;
  justify-content: center;
  color: #1585b5;
  border: 1px solid #1585b5;
  border-radius: 4px;
  cursor: pointer;
`;
const ROW = styled(Row)`
  justify-content: space-between;
  margin-top: 15px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 0px;
  }
`;
const SpanError = styled.span`
  font-style: normal;
  margin-top: 5px;
  font-weight: 500;
  font-size: 11px;
  color: #df5c04;
  padding: 3px;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    margin-top: 0px;
    font-size: 9px;
  }
`;
const Paragragh = styled.p`
  font-size: 16px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    margin-bottom: 5px;
  }
`;
const EscEmailForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    padding: 10px;
  }
`;
const EscalateEmailPopup = ({
  showEscEmailPopup,
  setShowEscEmailPopup,
  questionId,
  bookId,
  escAnswer,
  escQuestion,
  escSource,
  emailValue,
  chatbotUserEmail,
  title,
  leadChatSession,
  userLoggedDetails,
  userFullName,
}: any) => {
  const hideComponent = () => {
    setShowEscEmailPopup(false);
  };
  const getStyles = (error: any) => ({
    border: error ? "1px solid rgba(242, 86, 86, 1)" : "1px solid #eaeaea",
  });
  const handleSubmit = (values: any) => {
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/escalate-email-infos", {
        firstName: values.firstName,
        lastName: values.lastName,
        description: values.description,
        phone: values.phone,
        email: values.email,
        subject: values.subject,
        questionId: questionId,
        bookId: bookId,
        question: escQuestion,
        answer: escAnswer,
        source: escSource,
        title: title,
        chatbotUserEmail: chatbotUserEmail,
        leadChatSession: leadChatSession,
      })
      .then((response) => {
        if (response && response.data && response.data.success) {
          // hideComponent();
          trackEvent({
            email: emailValue || "PUBLIC",
            eventCategory: EventCategory.CHATBOT,
            event:
              escSource === "SHARE"
                ? ShareChatbotEvents.USER_USED_FILLED_EMAIL_ESCALATE_FORM_ON_SHARE_CHAT
                : escSource === "IFRAME"
                ? IFrameChatbotEvents.USER_USED_FILLED_EMAIL_ESCALATE_FORM_ON_IFRAME_CHAT
                : escSource === "BUBBLE"
                ? BubbleChatbotEvents.USER_USED_FILLED_EMAIL_ESCALATE_FORM_ON_BUBBLE_CHAT
                : "",
            subCategory: SubCategory.EVENT,
            source:
              escSource === "SHARE"
                ? Source.SHARE
                : escSource === "IFRAME"
                ? Source.IFRAME
                : escSource === "BUBBLE"
                ? Source.BUBBLE
                : "",
            bookId: bookId,
            chatbotUserEmail: chatbotUserEmail,
            leadChatSession: leadChatSession && leadChatSession.id,
            rawData: values,
          });
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Successfully Escalated Email!"
          );
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Not able to Escalate your request, please try again later"
          );
        }
      })
      .catch((error) => {});
  };

  return showEscEmailPopup ? (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <SpanDltPopup>Chat with an email</SpanDltPopup>
          </DltHeader>

          <Row style={{ margin: "0" }}>
            <Col xs={12} lg={12} md={12}>
              <EscEmailForm>
                <Paragragh>
                  Share your details below and a team member will be in touch
                  shortly
                </Paragragh>

                <Formik
                  enableReinitialize
                  //   initialValues={formValues}
                  initialValues={{
                    firstName:
                      userFullName && userFullName.firstName
                        ? userFullName.firstName
                        : "",
                    lastName:
                      userFullName && userFullName ? userFullName.lastName : "",
                    description: "",
                    phone:
                      userLoggedDetails && userLoggedDetails.mobileNumber
                        ? userLoggedDetails.mobileNumber
                        : "",
                    email:
                      userLoggedDetails && userLoggedDetails.email
                        ? userLoggedDetails.email
                        : "",
                    questionId: "",
                    bookId: "",
                    question: "",
                    answer: "",
                    subject: "",
                  }}
                  validate={(values: any) => {
                    const errors: {
                      firstName?: string;
                      lastName?: string;
                      // phone?: string;
                      email?: string;
                      description?: string;
                      subject?: string;
                    } = {};

                    if (!values.firstName) {
                      errors.firstName = "First name is required";
                    }
                    if (!values.lastName) {
                      errors.lastName = "Last name is required";
                    }
                    if (!values.email) {
                      errors.email = "Email is required";
                    }
                    if (!values.email) {
                      errors.email = "Email is required";
                    }
                    // if (!isPhoneNumber(values.phone))
                    //   errors.phone = "Valid phone required";

                    if (!values.description) {
                      errors.description = "Description is required";
                    }
                    if (!values.subject) {
                      errors.subject = "Subject is required";
                    }
                    return errors;
                  }}
                  onSubmit={(values) => {
                    hideComponent();
                    handleSubmit(values);
                  }}
                >
                  {({
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col style={{ flexDirection: "column" }}>
                          <FormMainDiv className=" d-block">
                            <LabelHeading>First Name</LabelHeading>
                            <InputField
                              type="text"
                              placeholder="First Name"
                              name="firstName"
                              value={values.firstName}
                              style={getStyles(
                                errors.firstName && touched.firstName
                              )}
                              onChange={handleChange}
                            />
                            {/* {errors.firstName && touched.firstName && (
                              <SpanError>{errors.firstName}</SpanError>
                            )} */}
                            {errors &&
                              errors.firstName &&
                              touched.firstName && (
                                <SpanError>
                                  {errors.firstName &&
                                  typeof errors.firstName === "string"
                                    ? errors.firstName
                                    : Array.isArray(errors.firstName)
                                    ? errors.firstName.join(", ")
                                    : null}
                                </SpanError>
                              )}
                          </FormMainDiv>

                          <FormMainDiv className="d-block">
                            <LabelHeading>Last Name</LabelHeading>
                            <InputField
                              type="text"
                              placeholder="Last Name"
                              name="lastName"
                              value={values.lastName}
                              style={getStyles(
                                errors.lastName && touched.lastName
                              )}
                              onChange={handleChange}
                            />
                            {/* {errors.lastName && touched.lastName && (
                              <SpanError>{errors.lastName}</SpanError>
                            )} */}
                            {errors && errors.lastName && touched.lastName && (
                              <SpanError>
                                {errors.lastName &&
                                typeof errors.lastName === "string"
                                  ? errors.lastName
                                  : Array.isArray(errors.lastName)
                                  ? errors.lastName.join(", ")
                                  : null}
                              </SpanError>
                            )}
                          </FormMainDiv>
                        </Col>
                        <Col style={{ flexDirection: "column" }}>
                          <FormMainDiv className=" d-block">
                            <LabelHeading>Phone</LabelHeading>
                            <InputField
                              type="text"
                              placeholder="Phone"
                              name="phone"
                              value={values.phone}
                              style={getStyles(errors.phone && touched.phone)}
                              onChange={handleChange}
                            />
                            {/* {errors.phone && touched.phone && (
                              <SpanError>{errors.phone}</SpanError>
                            )} */}
                          </FormMainDiv>
                          <FormMainDiv className=" d-block">
                            <LabelHeading>Email</LabelHeading>
                            <InputField
                              type="email"
                              placeholder="Email"
                              name="email"
                              value={values.email}
                              style={getStyles(errors.email && touched.email)}
                              onChange={handleChange}
                            />
                            {/* {errors.email && touched.email && (
                              <SpanError>{errors.email}</SpanError>
                            )} */}

                            {errors && errors.email && touched.email && (
                              <SpanError>
                                {errors.email &&
                                typeof errors.email === "string"
                                  ? errors.email
                                  : Array.isArray(errors.email)
                                  ? errors.email.join(", ")
                                  : null}
                              </SpanError>
                            )}
                          </FormMainDiv>
                        </Col>
                      </Row>

                      <FormMainDiv className="d-block">
                        <LabelHeading>Subject</LabelHeading>
                        <InputField
                          placeholder="Subject"
                          name="subject"
                          onChange={handleChange}
                          style={getStyles(errors.subject && touched.subject)}
                        />
                        {errors.subject && touched.subject && (
                          <SpanError>{errors.subject}</SpanError>
                        )}
                      </FormMainDiv>

                      <FormMainDiv className="d-block">
                        <LabelHeading>Message</LabelHeading>
                        <TextArea
                          placeholder="Description"
                          name="description"
                          onChange={handleChange}
                          style={getStyles(
                            errors.description && touched.description
                          )}
                        />
                        {errors.description && touched.description && (
                          <SpanError>{errors.description}</SpanError>
                        )}
                      </FormMainDiv>
                      <ROW>
                        <Col lg={5} xs={6}>
                          <SubmitBtn type="submit">Submit</SubmitBtn>
                        </Col>
                        <Col lg={5} xs={6}>
                          <CancelBtn onClick={hideComponent}>Cancel</CancelBtn>
                        </Col>
                      </ROW>
                    </form>
                  )}
                </Formik>
              </EscEmailForm>
            </Col>
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </>
  ) : (
    <></>
  );
};

export default EscalateEmailPopup;
