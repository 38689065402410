import axios from "axios";
import bowser from "bowser";

export const getDeviceInfoForTrackEvent = async () => {
  let ipAddress;
  try {
    const res = await axios.get("https://api.ipify.org/?format=json");

    if (res && res.data) {
      ipAddress = res.data.ip;
    }
  } catch (error) {}
  try {
    const browser = bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName();
    const browserVersion = browser.getBrowserVersion();
    const platformType = browser.getPlatformType();
    let osInfo = "";

    if (platformType === "mobile" || platformType === "tablet") {
      const os = browser.getOS();
      osInfo = `${os.name} ${os.version}`;
    } else if (platformType === "desktop") {
      const os = browser.getOS();
      osInfo = `${os.name} ${os.version}`;
    }

    const deviceInfo = {
      browserName,
      browserVersion,
      platformType,
      osInfo,
      ipAddress,
    };

    return deviceInfo;
  } catch (error) {}
  return {};
};

export const getUsersEmail = (user: any) => {
  return user && user.email ? user.email : "Public";
};

export const trackDropChatEvent = async (inputTrackData: any) => {
  const deviceInfo = await getDeviceInfoForTrackEvent();

  const input = {
    ...deviceInfo,
    ...inputTrackData,
  };

  axios.post(`${process.env.REACT_APP_SERVER_URL}/event-tracks`, {
    ...input,
  });

  return true;
};
