// import { useContext } from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
// import { BookContext } from "../../context/BookContext";
import DltrossSvg from "../../images/white-cross.svg";
// import CustomMarkdown from "../../utils/custom-markdown";
// import { formattedAnswer } from "../../utils/utils";
import { MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
// import { OverlayPanel } from "primereact/overlaypanel";
import { Field, Form, Formik } from "formik";

const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 59%);
  z-index: 9999;
`;

const DivForDltPopUp = styled.div`
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 8%);
  z-index: 1111;
  width: 50% !important;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    position: relative;
  }
  @media (min-width: ${TAB.minWidth}px) {
    position: relative;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    position: fixed;
  }
`;

const DltHeader = styled.div`
  width: 100% !important;
  background: #1585b5;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: space-between;
    height: auto;
    padding: 10px;
    flex-direction: row-reverse;
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: space-between;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    justify-content: space-between;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
`;

const Arrow = styled.img`
  height: 25px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-top: 3.5px;
  }
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  letter-spacing: 1px;
`;

const LeftDiv = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin: 10px 16px;
  height: 20vh;
  flex: 1;
  overflow: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  & label {
    font-weight: bold;
    margin-right: 10px;
    align-self: center;
  }
  & input {
    padding: 5px;
  }
`;

const OverlayPanelStyle = styled.div`
  flex-direction: column;
`;

const AddCategoryForm = styled(Form)`
  width: 100%;
  padding: 1.25rem;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  & input {
    margin: 0.5rem 0rem;
    background: #ffffff;
    border: 1px solid rgba(51, 48, 60, 0.4);
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
  }
  & button {
    background: #1585b5;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    border-color: #1585b5;
    box-shadow: none;
    width: max-content;
    padding: 10px 20px;
    letter-spacing: 1px;
  }
`;

const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
  font-size: 12px;
`;

const EditSampleQuestionModal = ({
  showModal,
  setShowModal,
  questionToEdit,
  updateSampleQuestion,
}: any) => {
  return showModal ? (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <Arrow
              src={DltrossSvg}
              alt="dlt"
              onClick={() => {
                setShowModal(false);
              }}
            />
            <SpanDltPopup>Update sample question</SpanDltPopup>
          </DltHeader>
          <Row>
            <LeftDiv>
              <OverlayPanelStyle>
                {/* <AddCategoryHeading>Add to New Flashcards</AddCategoryHeading> */}
                <Formik
                  initialValues={{
                    questionID: questionToEdit.id,
                    question: questionToEdit.question,
                  }}
                  onSubmit={updateSampleQuestion}
                >
                  {({ values, errors, touched }: any) => (
                    <AddCategoryForm className="d-flex flex-column">
                      <Field
                        id="question"
                        name="question"
                        placeholder="Question"
                      />
                      {errors.question && touched.question && (
                        <FormError className="formError">
                          {errors.question}
                        </FormError>
                      )}
                      <button type="submit">UPDATE QUESTION</button>
                    </AddCategoryForm>
                  )}
                </Formik>
              </OverlayPanelStyle>
            </LeftDiv>
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </>
  ) : (
    <></>
  );
};

export default EditSampleQuestionModal;
