import { Field, useFormikContext } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import myAxios from "../../../myaxios";
import { NotificationManager } from "react-notifications";
import { parseErrorResponse } from "../../../utils/utils";
import { LoaderContext } from "../../../context/loader-context";
import { useContext } from "react";

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;

const InputField = styled(Field)`
  width: 100%;
  padding: 10px;
  background: #eaeaea;
  border: 1px solid #eaeaea !important;
  display: inline;
  border-radius: 4px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    border-radius: 4px;
  }
`;

const MenuMainHeading = styled.h3`
  color: #33303c;
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
`;
const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 1rem;
`;
const ColForRadioInputCom = styled(Col)`
  align-items: center;
  justify-content: space-between;
`;
const ColForInputFields = styled(Col)`
  flex-direction: column;
`;
const TextAreas = styled.textarea`
  border: 1px solid rgba(234, 234, 234, 1);
  background: rgba(248, 247, 250, 1);
  border-radius: 4px;
  height: 90px;
  padding: 10px;
`;
const UrlInputField = styled.input`
  border: 1px solid rgba(234, 234, 234, 1);
  background: rgba(248, 247, 250, 1);
  border-radius: 4px;
  position: relative;
  height: 40px;
  padding: 0 0 0 10px;
  width: 100%;
`;
// const ColForRadioTime = styled(Col)`
//   align-items: center;
//   justify-content: space-between;
// `;
// const PrefillSpan = styled.span`
//   font-size: 14px;
//   font-family: "Poppins", sans-serif;
//   right: 38px;
//   color: rgba(51, 48, 60, 0.5);
// `;
// const PrefillDiv = styled.div`
//   background: rgba(234, 234, 234, 1);
//   color: white;
//   position: absolute;
//   border-radius: 0;
//   z-index: 1;
//   left: 1px;
//   height: 36px;
//   margin-top: 2px;
//   display: flex;
//   align-items: center;
//   padding: 0 16px;
//   border: 1px solid rgba(234, 234, 234, 1);
// `;

const FormError = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: red;
`;

const SaveButton = styled(Button)`
  width: 100%;
  background: #1585b5;
  padding: 0.5rem;
  color: #fff;
  margin-top: 1rem;
`;

const ExternalIndexingApi = ({ setSelectedTabName }: any) => {
  const { setShowLoader } = useContext(LoaderContext);
  const formik = useFormikContext();
  const { values, errors } = useFormikContext<any>();

  const saveTheApiInputs = () => {
    setShowLoader(true);
    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "/chatbot/manage-setting/fetch-bid-a-car-data",
        {
          ...values.apiConfiguration,
          bookId: values.bookId,
        }
      )
      .then((response) => {
        // console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          setShowLoader(false);
          // resetCustomPurchaseForm(resetFormCustom);
          NotificationManager.success(response.data.message);
          // setSelectedTabName("Data Sources");
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        // resetForm();
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  return (
    <>
      <Row style={{ alignItems: "center" }}>
        <Col sm={8}>
          <Row>
            <MenuMainHeading>Chatbot API Configuration</MenuMainHeading>
            <SubHeading>Config your API’s to fetch new data</SubHeading>
          </Row>
        </Col>
        <Col sm={4} style={{ justifyContent: "flex-end" }}>
          <CheckBoxWrapper>
            <>
              <CheckBox
                id="checkbox"
                checked={values.apiConfiguration.isApiConfiguration}
                type="checkbox"
                name="apiConfiguration.isApiConfiguration"
                onChange={(e: any) => {
                  formik.setFieldValue(
                    "apiConfiguration.isApiConfiguration",
                    e.target.checked
                  );
                }}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </>
          </CheckBoxWrapper>
        </Col>
      </Row>
      {values.apiConfiguration.isApiConfiguration && (
        <Row style={{ flexDirection: "column" }}>
          <MenuMainHeading>HTTP Method</MenuMainHeading>

          <ColForRadioInputCom md={6}>
            <div>
              <InputField
                type="radio"
                value="Get"
                disabled
                name="apiConfiguration.methodType"
                onChange={(e: any) => {
                  formik.setFieldValue("apiConfiguration.methodType", "Get");
                }}
              />
              <span
                style={{ marginLeft: "10px", color: "rgba(234,234,234,1)" }}
              >
                Get
              </span>
            </div>

            <div>
              <InputField
                type="radio"
                value="Post"
                name="apiConfiguration.methodType"
                onChange={(e: any) => {
                  formik.setFieldValue("apiConfiguration.methodType", "Post");
                }}
              />
              <span style={{ marginLeft: "10px" }}>Post</span>
            </div>
          </ColForRadioInputCom>

          <ColForInputFields>
            <MenuMainHeading>URL</MenuMainHeading>
            <div style={{ position: "relative", flexDirection: "column" }}>
              {/* <PrefillDiv>
                <PrefillSpan>https://</PrefillSpan>
              </PrefillDiv> */}
              <UrlInputField
                value={values.apiConfiguration && values.apiConfiguration.url}
                name="apiConfiguration.url"
                onChange={(e: any) => {
                  formik.setFieldValue("apiConfiguration.url", e.target.value);
                }}
              />
              {errors?.url && typeof errors.url === "string" && (
                <FormError>{errors.url}</FormError>
              )}
            </div>
          </ColForInputFields>

          <ColForInputFields>
            <MenuMainHeading>Header</MenuMainHeading>
            <TextAreas
              name="apiConfiguration.header"
              value={values.apiConfiguration && values.apiConfiguration.header}
              onChange={(e: any) => {
                formik.setFieldValue("apiConfiguration.header", e.target.value);
              }}
            />

            {errors?.header && typeof errors.header === "string" && (
              <FormError>{errors.header}</FormError>
            )}
          </ColForInputFields>

          <ColForInputFields>
            <MenuMainHeading>Vehicle Count</MenuMainHeading>
            <InputField
              type="number"
              name="apiConfiguration.body"
              value={
                values.apiConfiguration && values.apiConfiguration.body.pageSize
              }
              onChange={(e: any) => {
                // let pageSize = e.target.value;
                const body = {
                  pageNumber: 1,
                  pageSize: e.target.value,
                };

                formik.setFieldValue("apiConfiguration.body", body);
              }}
            />

            {/* {errors?.body && typeof errors.body === "string" && (
              <FormError>{errors.body}</FormError>
            )} */}
          </ColForInputFields>

          {/* <MenuMainHeading>Time</MenuMainHeading>
          <Row>
            <ColForRadioTime>
              <div>
                <InputField
                  type="radio"
                  name="apiConfiguration.isMidNight"
                  checked={
                    values.apiConfiguration &&
                    !values.apiConfiguration.isAfternoon &&
                    values.apiConfiguration.isMidNight
                  }
                  style={{ width: "10%" }}
                  onChange={(e: any) => {
                    formik.setFieldValue(
                      "apiConfiguration.isMidNight",
                      e.target.checked
                    );
                    formik.setFieldValue(
                      "apiConfiguration.isAfternoon",
                      !e.target.checked
                    );
                  }}
                />
                <span style={{ marginLeft: "10px", fontSize: "15px" }}>
                  Once in a day
                </span>
              </div>
            </ColForRadioTime>

            <ColForRadioTime style={{ justifyContent: "flex-start" }}>
              <div>
                <InputField
                  type="radio"
                  name="apiConfiguration.isAfternoon"
                  checked={
                    values.apiConfiguration &&
                    values.apiConfiguration.isAfternoon &&
                    values.apiConfiguration.isMidNight
                  }
                  style={{ width: "10%" }}
                  onChange={(e: any) => {
                    formik.setFieldValue(
                      "apiConfiguration.isAfternoon",
                      e.target.checked
                    );
                    formik.setFieldValue(
                      "apiConfiguration.isMidNight",
                      e.target.checked
                    );
                    handleDoubleChecked(e);
                  }}
                />
                <span style={{ marginLeft: "10px", fontSize: "15px" }}>
                  Twice in a day
                </span>
              </div>
            </ColForRadioTime>
          </Row>
          <div>
            <div>
              <span style={{ fontSize: "11px" }}>
                It will fetch new data at midnight 12:00AM
              </span>
            </div>
            <div>
              <span style={{ fontSize: "11px" }}>
                It will fetch new data at midnight 12:00AM and afternoon
                12:00PM.
              </span>
            </div>
          </div>

          {errors?.isAfternoon && typeof errors.isAfternoon === "string" && (
            <FormError>{errors.isAfternoon}</FormError>
          )} */}
          <ColForInputFields md={6}>
            <SaveButton type="button" onClick={() => saveTheApiInputs()}>
              Get Test Data Now
            </SaveButton>
          </ColForInputFields>
        </Row>
      )}
    </>
  );
};
export default ExternalIndexingApi;
