import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MOBILE } from "../../constants/mobile";
import { UserContext } from "../../context/UserContext";
import { LoaderContext } from "../../context/loader-context";
import { PlanContext } from "../../context/plan-context";
import ShopifyIcon from "../../images/Shopify1.svg";
import DiscordIcon from "../../images/discord-icon.svg";
import HubSpotIcon from "../../images/logos_hubspot.svg";
import ZendeskIcon from "../../images/simple-icons_zendesk.svg";
import myAxios from "../../myaxios";
import IndexCharacterLimitPopUp from "../index-character-limit-popup";
import ShopifyTokenForm from "./shopify-token-form";
import ZendeskConnectForm from "./zendesk-connect-form";
// import DiscordTokenForm from "./discord-token-form";
import { NotificationManager } from "react-notifications";

const ParentDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const EmptyDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;
const MainRow = styled(Row)`
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  height: calc(100vh - 35vh);
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: 100%;
    padding: 5px 0px 0px 5px;
  }
`;

const FirstRow = styled(Row)`
  gap: 10px 0px;
`;

const ColumnBox = styled.div`
  height: 200px;
  padding: 10px;
  border: 1px solid #eaeaea;
  background-color: #f8f7fa;
  border-radius: 8px;
  flex-direction: column;
  font-size: 14px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    /* width: auto; */
    height: 35vh;
  }
`;

const BaseButton = styled.button`
  height: fit-content;
  padding: 10px 30px;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  color: #fff;
  position: absolute;
  width: 170px;
  font-weight: 600;
  letter-spacing: 1px;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 155px;
    padding: 5px 30px;
  }
`;
const SubscriptionExpireOverlay = styled.div`
  position: absolute;
  height: 50vh;
  width: 50vw;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;

  @media (max-width: ${MOBILE.maxWidth}px) {
    position: fixed;
    bottom: 25vh;
    width: 80vw;
    right: 10vw;
  }
`;
const ButtonConnect = styled(BaseButton)`
  background-color: #1585b5;
`;

const ButtonDisconnect = styled(BaseButton)`
  background-color: #ec2d2d;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 155px;
    padding: 5px 30px;
  }
`;

const FieldToggleSection = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  & div {
    flex-direction: row;
  }
  & div:nth-child(1) {
  }
  & div:nth-child(2) {
  }
  & div:nth-child(3) {
  }
`;

// const FormToggleLabelText = styled(FormLabel)`
//   color: #33303c;
//   font-weight: 600;
//   font-size: 15px;
//   padding-left: 0.5rem;
// `;

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;

const IntegrationsApps = () => {
  const navigate = useNavigate();

  const { integrationAppList, setIntegrationAppsList, user } =
    useContext(UserContext);
  const { setPlanHighliter } = useContext(PlanContext);
  const { setShowLoader } = useContext(LoaderContext);

  const [showZendeskConnectForm, setShowZendeskConnectForm] =
    useState<boolean>(false);
  const [showShopifyConnectForm, setShowShopifyConnectForm] =
    useState<boolean>(false);

  const [showLimitPopUp, setShowLimitPopUp] = useState<boolean>(
    user?.currentSubscription?.meta?.integrationApps || false
  );
  const [discordToggle, setDiscordToggle] = useState<boolean>(
    integrationAppList.discord
  );

  const onClickForHubSpot = () => {
    myAxios
      .get(`${process.env.REACT_APP_SERVER_URL}/temp-state-token`)
      .then((response: any) => {
        window.open(
          `https://app.hubspot.com/oauth/authorize?client_id=9b9cd794-e911-46cd-a1de-b0643749b512&redirect_uri=${process.env.REACT_APP_SERVER_URL}/hubspot-connection&state=${response.data}&scope=crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.lists.write`,
          "_blank"
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const DisConnectApp = (appName: any) => {
    setShowLoader(true);
    myAxios
      .post(`${process.env.REACT_APP_SERVER_URL}/disconnect-integration-apps`, {
        disConnectApp: appName,
      })
      .then((response: any) => {
        NotificationManager.success(
          response.data.message
            ? response.data.message
            : "Disconnect saved successfully!"
        );
        if (response.data.success) {
          myAxios
            .get(process.env.REACT_APP_SERVER_URL + "/integration-apps")
            .then((response) => {
              if (response.data.success) {
                setIntegrationAppsList(response.data.data);
              }
            });
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        console.log("error", error);
      });
  };

  const changeDiscordStat = () => {
    setShowLoader(true);
    if (!discordToggle) {
      // Allow Discord API goes here...
      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/discord-integration-settings")
        .then((response) => {
          if (response.data.success) {
            // setIntegrationAppsList(response.data.data);
            setIntegrationAppsList({ ...integrationAppList, discord: true });
            NotificationManager.success(
              response.data.message
                ? response.data.message
                : "Discord connect successfully!"
            );
          }
        });
      setShowLoader(false);
    } else {
      DisConnectApp("discord");
    }
  };

  useEffect(() => {
    myAxios
      .get(process.env.REACT_APP_SERVER_URL + "/integration-apps")
      .then((response) => {
        if (response.data.success) {
          setIntegrationAppsList(response.data.data);
        }
      });

    setShowLimitPopUp(
      user?.currentSubscription?.meta?.integrationApps
        ? user?.currentSubscription?.meta?.integrationApps
        : false
    );
    if (!showLimitPopUp) {
      setPlanHighliter({
        isBookHighliter: false,
        bookLimit: 0,
        isDeleteBookHighliter: false,
        isFlashcardHighliter: false,
        isCharacterLimit: false,
        pdfLimit: 0,
        isDeleteAccount: false,
        isLeadLoginSettings: false,
        isAllowYTIndex: false,
        isShopifyProduct: false,
        isIntegrationApps: true,
      });
    }
    // eslint-disable-next-line
  }, []);

  const cancelFunc = () => {
    navigate("/settings/user-profile");
  };

  return (
    <MainRow>
      <FirstRow>
        <Col xs={3} style={{ padding: "5px" }}>
          <ColumnBox>
            <Col>
              <img width={"100"} src={HubSpotIcon} alt="edit" />
            </Col>
            <Col>
              <span>
                Automatically add your leads collected on Dropchat to HubSpot
              </span>
            </Col>
            <Col>
              {!integrationAppList.hubspot && (
                <Col>
                  <ButtonConnect onClick={onClickForHubSpot}>
                    Connect
                  </ButtonConnect>
                </Col>
              )}
              {integrationAppList.hubspot && (
                <Col style={{ justifyContent: "end" }}>
                  <ButtonDisconnect onClick={() => DisConnectApp("hubspot")}>
                    Disconnect
                  </ButtonDisconnect>
                </Col>
              )}
            </Col>
          </ColumnBox>
        </Col>
        <Col xs={3} style={{ padding: "5px" }}>
          <ColumnBox>
            <Col>
              <img width={"120"} src={ZendeskIcon} alt="edit" />
            </Col>
            <Col>
              <span>Integrate Zendesk with Dropchat chatbots</span>
            </Col>
            <Col>
              {!integrationAppList.zendesk && (
                <Col>
                  {showZendeskConnectForm ? (
                    <ZendeskConnectForm />
                  ) : (
                    <ButtonConnect
                      onClick={() => setShowZendeskConnectForm(true)}
                    >
                      Connect
                    </ButtonConnect>
                  )}
                </Col>
              )}
              {integrationAppList.zendesk && (
                <Col style={{ justifyContent: "end" }}>
                  <ButtonDisconnect onClick={() => DisConnectApp("zendesk")}>
                    Disconnect
                  </ButtonDisconnect>
                </Col>
              )}
            </Col>
          </ColumnBox>
        </Col>
        <Col xs={3} style={{ padding: "5px" }}>
          <ColumnBox>
            <Col>
              <img width={"100"} src={ShopifyIcon} alt="edit" />
            </Col>
            <Col>
              <span>
                Add Shopify Product List into Dropchat as Chatbot/Add as Data
                source
              </span>
            </Col>
            <Col>
              {!integrationAppList.shopify && (
                <Col>
                  <ButtonConnect
                    onClick={() => setShowShopifyConnectForm(true)}
                  >
                    Connect
                  </ButtonConnect>
                </Col>
              )}
              {showShopifyConnectForm && (
                <ShopifyTokenForm
                  setShowShopifyConnectForm={setShowShopifyConnectForm}
                />
              )}
              {integrationAppList.shopify && (
                <Col style={{ justifyContent: "end" }}>
                  <ButtonDisconnect onClick={() => DisConnectApp("shopify")}>
                    Disconnect
                  </ButtonDisconnect>
                </Col>
              )}
            </Col>
          </ColumnBox>
        </Col>
        <Col xs={3} style={{ padding: "5px" }}>
          <ColumnBox>
            <Col>
              <img width={"100"} src={DiscordIcon} alt="discord" />
            </Col>
            <Col>
              <span>Integrate your Discord Bot with Dropchat. </span>
            </Col>
            <Col>
              <FieldToggleSection>
                <CheckBoxWrapper>
                  <>
                    <CheckBox
                      id="checkbox"
                      type="checkbox"
                      name="deleteChat"
                      checked={discordToggle}
                      onClick={(e: any) => {
                        setDiscordToggle(!discordToggle);
                        changeDiscordStat();
                      }}
                    />
                    <CheckBoxLabel htmlFor="checkbox" />
                  </>
                </CheckBoxWrapper>
              </FieldToggleSection>
            </Col>
          </ColumnBox>
        </Col>
      </FirstRow>

      <>
        {!showLimitPopUp ? (
          <ParentDiv>
            <EmptyDiv
              onClick={() => {
                cancelFunc();
              }}
            ></EmptyDiv>
            <SubscriptionExpireOverlay>
              <IndexCharacterLimitPopUp
                titleContent={"Integration Apps Unavailable"}
                subHeadingContent={"Access Denied: Integration Apps"}
                mainContent={`Please note that you do not have access to integration App in
                your current subscription plan. To resolve this, you can upgrade your plan to a
                higher tier that includes access to the integration app.`}
                setShowLimitPopUp={cancelFunc}
              ></IndexCharacterLimitPopUp>
            </SubscriptionExpireOverlay>
          </ParentDiv>
        ) : null}
      </>

      <ZendeskConnectForm
        setShowZendeskConnectForm={setShowZendeskConnectForm}
        showZendeskConnectForm={showZendeskConnectForm}
      />
    </MainRow>
  );
};

export default IntegrationsApps;
