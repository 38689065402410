import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { BookContext } from "../../../context/BookContext";
import { UserContext } from "../../../context/UserContext";
import { LoaderContext } from "../../../context/loader-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../../event-utils/dropchat-event-track";
import { trackEvent } from "../../../event-utils/track-event";
import DeleteIcon from "../../../images/delete-icon-chat-seesion.svg";
import SessionChevronRight from "../../../images/session-chevron-right.svg";
import myAxios from "../../../myaxios";
import { parseErrorResponse } from "../../../utils/utils";
import ConfirmationBox from "../../utility-component/confirmation-box";
import MobileChatbotSessionsPopup from "./mobile-chatbot-sessions-popup";

interface Color {
  selected: boolean;
}

const ParentSection = styled(Col)`
  padding: 0.5rem;
  flex-direction: row;
  justify-content: space-between;
`;

const ColumnSection = styled.div`
  flex-direction: column;
  width: 100%;
`;

const ContentParent = styled.div`
  flex-direction: column;
`;

const HeadingText = styled.h2`
  font-size: 14px;
  color: #33303c;
  font-weight: 600;
  padding: 0.5rem;
`;

// const Subheading = styled.p`
//   font-size: 16px;
//   color: #33303c;
//   font-weight: 400;
//   padding-left: 0.5rem;
// `;

const SessionListItem = styled.li`
  /* background: ${(props: Color) => (props.selected ? "#1585b5" : "#fff")}; */
  padding: 10px;
  flex-direction: row;
  display: flex;
  border: 1px solid #eaeaea;
  background: #ffffff;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
`;

const SessionItemText = styled.div`
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
const SessionItemArrow = styled.div``;

const SessionUserName = styled.p`
  /* color: ${(props: Color) =>
    props.selected ? "#fff" : "rgba(51, 48, 60, 0.6)"}; */
  margin: 0;
  font-size: 16px;
  color: #33303c;
`;

const SessionThorugh = styled.p`
  /* color: ${(props: Color) =>
    props.selected ? "#fff" : "rgba(51, 48, 60, 0.6)"}; */
  margin: 0;
  font-size: 11px;
  font-weight: 400;
  color: #33303c;
`;

export const SignleQuestionAnswerSection = styled.div`
  margin: 5px;
  flex-direction: column;
`;

export const ResponseSection = styled.div`
  padding: 8px 8px;
  color: #282c34;
  background: #ffffff;
  border-bottom: 1px solid #ebe8f0;
  align-items: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const QuestionSection = styled.div`
  padding: 8px 8px;
  background: #ffffff;
  align-items: center;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
    font-size: 14px;
  }
`;

export const QuestionAnswerSection = styled.div`
  flex-direction: column;
  width: 100%;
`;
const DeleteDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MobileChatbotSessions = () => {
  const { setShowLoader } = useContext(LoaderContext);
  const { selectedBook } = useContext(BookContext);
  const [chatSessions, setChatSessions] = useState<any>();
  const [selectedChatSession, setSelectedChatSession] = useState<any>();
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");
  const [confirmationAction, setConfirmationAction] = useState<string>("");
  const [selectedChatSessionToDelete, setSelectedChatSessionToDelete] =
    useState<any>();
  const { user } = useContext(UserContext);
  const [showMobileChatBotPopup, setMobileChatPopup] = useState(false);
  let isLast = false;
  useEffect(() => {
    setShowLoader(true);
    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL +
          `/lead-chat-sessions/paginated-chat-sessions`,
        {
          bookId: selectedBook && selectedBook._id,
        }
      )
      .then((response) => {
        if (response.data && response.data.success) {
          setChatSessions(response.data.data.chatSessions);
          setSelectedChatSession(response.data.data.chatSessions[0]);
          setShowLoader(false);
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to fetch chat session, please try later!"
          );
        }
      })
      .catch((error) => {
        // handle error
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
    // eslint-disable-next-line
  }, []);
  const deleteSource = (isLast: boolean) => {
    if (isLast) {
      setConfirmationBoxMessage(
        "Are you sure you want to delete the remaining session of this chatbot? This would also delete chat history. Please confirm?"
      );
    } else {
      setConfirmationBoxMessage("Do you really want to delete this session?");
    }
  };

  const actionBodyTemplate = (id: any) => {
    if (selectedChatSession?.sources?.length === 1) {
      isLast = true;
    }

    return (
      <>
        <img
          style={{ cursor: "pointer", width: "20px" }}
          src={DeleteIcon}
          alt={"Delete"}
          className="w-4rem"
          onClick={() => {
            deleteSource(isLast);
            setConfirmationAction("delete");
            setShowUtilityConfirmationBox(true);
            setSelectedChatSessionToDelete(id);
          }}
        />
      </>
    );
  };

  const confirmClick = (action: string) => {
    if (action === "delete" && selectedChatSessionToDelete) {
      setShowLoader(true);
      const sessionId =
        selectedChatSession &&
        (selectedChatSession._id || selectedChatSession.id);
      myAxios
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/lead-chat-sessions/${sessionId}`
        )
        .then((response) => {
          if (response.data && response.data.success) {
            setShowUtilityConfirmationBox(false);
            const newchatSessions = chatSessions.filter(
              // eslint-disable-next-line
              (newData: any) => newData._id != selectedChatSessionToDelete
            );
            setChatSessions(newchatSessions);
            if (newchatSessions && newchatSessions.length > 0) {
              setSelectedChatSession(newchatSessions[0]);
            } else {
              setSelectedChatSession(undefined);
            }

            NotificationManager.success("Session deleted successfully");
            // if (isLast) {
            //   navigate("/");
            // }
            trackEvent({
              email: getUsersEmail(user),
              eventCategory: EventCategory.CHATBOT,
              event: ChatBotEvents.USER_DELETE_LEAD_CHATBOT_SESSIONS,
              subCategory: SubCategory.EVENT,
              bookId: selectedBook?._id,
              rawData: selectedChatSession,
            });
          } else {
            setShowUtilityConfirmationBox(false);
            NotificationManager.error(
              response.data && response.data.message
                ? response.data.message
                : "Not able to delete session, Please try again!"
            );
          }
          setShowLoader(false);
        })
        .catch((error) => {
          setShowUtilityConfirmationBox(false);
          NotificationManager.error(parseErrorResponse(error));
          setShowLoader(false);
        });
    }
  };

  return (
    <>
      <ParentSection sm={12} xs={12}>
        <ColumnSection>
          <ContentParent>
            <HeadingText>Chatbot Sessions</HeadingText>
          </ContentParent>

          {chatSessions &&
            chatSessions.length > 0 &&
            chatSessions.map((chatSession: any) => {
              return (
                <div
                  style={{ marginBottom: "10px" }}
                  onClick={() => {
                    setMobileChatPopup(true);
                  }}
                >
                  <SessionListItem
                    selected={selectedChatSession._id === chatSession._id}
                    onClick={() => {
                      setSelectedChatSession(chatSession);
                    }}
                  >
                    <Col
                      xs={11}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div>
                        <SessionItemText>
                          <SessionUserName
                            selected={
                              selectedChatSession._id === chatSession._id
                            }
                            style={{ fontWeight: "600" }}
                          >
                            {chatSession.name
                              ? chatSession.name
                              : chatSession.email
                              ? chatSession.email
                              : chatSession.mobileNumber
                              ? chatSession.mobileNumber
                              : "Guest User"}
                          </SessionUserName>
                          <SessionThorugh
                            selected={
                              selectedChatSession._id === chatSession._id
                            }
                          >
                            {chatSession.source === "bubblechat"
                              ? "Embedded Chat Lead"
                              : chatSession.source === "share"
                              ? "Shared URL Lead"
                              : chatSession.source === "iframe"
                              ? "iFrame Lead"
                              : "N/A"}
                          </SessionThorugh>
                        </SessionItemText>
                        <SessionItemArrow>
                          <img src={SessionChevronRight} alt="select" />
                        </SessionItemArrow>
                      </div>

                      <div style={{ fontSize: "13px" }}>
                        {/* {selectedChatSession.name
                        ? selectedChatSession.name
                        : "N/A"}{" "}
                      &nbsp;|&nbsp;{" "} */}
                        {selectedChatSession.email
                          ? selectedChatSession.email
                          : "N/A"}{" "}
                        &nbsp;|&nbsp; &nbsp;&nbsp;
                        {selectedChatSession.mobileNumber
                          ? selectedChatSession.mobileNumber
                          : "N/A"}{" "}
                        &nbsp;|&nbsp; &nbsp;&nbsp;
                        {selectedChatSession.createdAt
                          ? moment(selectedChatSession.createdAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )
                          : "N/A"}
                      </div>
                    </Col>
                    <Col xs={1}>
                      <DeleteDiv
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                      >
                        {actionBodyTemplate(selectedChatSession._id)}
                      </DeleteDiv>
                    </Col>
                  </SessionListItem>
                </div>
              );
            })}
        </ColumnSection>

        <MobileChatbotSessionsPopup
          showMobileChatBotPopup={showMobileChatBotPopup}
          setMobileChatPopup={setMobileChatPopup}
          selectedChatSession={selectedChatSession}
        />
        {showUtilityConfirmationBox && (
          <ConfirmationBox
            setShow={setShowUtilityConfirmationBox}
            name="Confirmation"
            message={confirmationBoxMessage}
            okButtonText="Yes"
            action={confirmationAction}
            okButtonClick={confirmClick}
          />
        )}
      </ParentSection>
    </>
  );
};

export default MobileChatbotSessions;
