// import { useContext, useEffect } from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
// import { BookContext } from "../../context/BookContext";
import DltrossSvg from "../../images/white-cross.svg";
// import CustomMarkdown from "../../utils/custom-markdown";
// import { formattedAnswer } from "../../utils/utils";
import { MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
// import { TreeSelect } from "primereact/treeselect";
// import { NotesContext } from "../../context/notes-context";

const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 59%);
  z-index: 9999;
`;

const DivForDltPopUp = styled.div`
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 8%);
  z-index: 1111;
  width: 95% !important;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    position: relative;
  }
  @media (min-width: ${TAB.minWidth}px) {
    position: relative;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    position: fixed;
  }
`;

const DltHeader = styled.div`
  width: 100% !important;
  background: #1585b5;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: space-between;
    height: auto;
    padding: 10px;
    flex-direction: row-reverse;
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: space-between;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    justify-content: space-between;
    padding: 10px;
    height: auto;
    flex-direction: row-reverse;
  }
`;

const Arrow = styled.img`
  height: 25px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-top: 3.5px;
  }
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const LeftDiv = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin: 10px 16px;
  height: 72vh;
  overflow: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  & label {
    font-weight: bold;
    margin-right: 10px;
    align-self: center;
  }
  & input {
    padding: 5px;
  }
`;

// const SingleSourceParent = styled.div`
//   border-bottom: 1px dashed #ccc;
//   height: max-content;
//   padding: 10px 0px;
//   width: 98%;
//   flex-direction: column;
//   & img {
//     display: none;
//   }
// `;

const ExistingCategoryPopup = ({
  showModal,
  setShowModal,
  createNotes,
  filteredCategoryList,
  allNotesCategories,
  data,
}: any) => {
  let filteredNotes: any;

  filteredNotes = allNotesCategories
    ? allNotesCategories.length > 0 &&
      filteredCategoryList(
        [...allNotesCategories],
        data && data.notesCategories
      )
    : [];

  return showModal ? (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <Arrow
              src={DltrossSvg}
              alt="dlt"
              onClick={() => {
                setShowModal(false);
              }}
            />
            <SpanDltPopup>Save to existing flashcards category</SpanDltPopup>
          </DltHeader>

          <Row>
            <LeftDiv>
              {/* <TreeSelectStyle
                onChange={(e) => createNotes(data, e.value)}
                options={
                  notesCategories &&
                  filteredCategoryList(
                    [...notesCategories],
                    data.notesCategories
                  )
                }
                filter
                className="md:w-20rem w-full"
                placeholder="Save to existing flashcards category"
              ></TreeSelectStyle> */}
              {filteredNotes &&
                filteredNotes.length > 0 &&
                filteredNotes.map((category: any) => {
                  return (
                    <>
                      <p onClick={() => createNotes(data, category._id)}>
                        {category.name}
                      </p>
                    </>
                  );
                })}

              {filteredNotes && filteredNotes.length <= 0 && (
                <p>no result found</p>
              )}
            </LeftDiv>
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </>
  ) : (
    <></>
  );
};

export default ExistingCategoryPopup;
