export enum EventCategory {
  TRIAL = "TRIAL",
  USER = "USER",
  CHATBOT = "CHATBOT",
  PLAN = "PLAN",
}

export enum SubCategory {
  EVENT = "EVENT",
  PAGEVIEW = "PAGEVIEW",
}

export enum Source {
  PUBLIC = "PUBLIC",
  CHAT = "CHAT",
  BUBBLE = "BUBBLE",
  SHARE = "SHARE",
  IFRAME = "IFRAME",
  GUEST = "GUEST",
}

export enum TrailEvents {
  USER_ASKED_QUESTION_ON_FEATURE_CHATBOT = "USER_ASKED_QUESTION_ON_FEATURE_CHATBOT",
  USER_GOES_AVOVE_QUESTION_LIMIT_ON_FEATURE_CHATBOT = "USER_GOES_AVOVE_QUESTION_LIMIT_ON_FEATURE_CHATBOT",
  USER_TRY_TO_LOGIN_FROM_FEATURE_CHATBOT = "USER_TRY_TO_LOGIN_FROM_FEATURE_CHATBOT",
}

export enum UserEvents {
  USER_TRY_TO_SIGNUP_USING_FORM = "USER_TRY_TO_SIGNUP_USING_FORM",
  USER_SIGNUP_FAILED_USING_FORM = "USER_SIGNUP_FAILED_USING_FORM",
  USER_SIGNUP_USING_FORM = "USER_SIGNUP_USING_FORM",
  USER_TRY_TO_LOGIN_USING_FORM = "USER_TRY_TO_LOGIN_USING_FORM",
  USER_LOGIN_FAILED_USING_FORM = "USER_LOGIN_FAILED_USING_FORM",
  USER_LOGIN_USING_FORM = "USER_LOGIN_USING_FORM",
  USER_TRY_TO_LOGIN_USING_MAGIC_LINK = "USER_TRY_TO_LOGIN_USING_MAGIC_LINK",
  USER_SIGNUP_USING_MAGIC_LINK = "USER_SIGNUP_USING_MAGIC_LINK",
  USER_LOGIN_USING_MAGIC_LINK = "USER_LOGIN_USING_MAGIC_LINK",
  USER_TRY_TO_LOGIN_FROM_PUBLIC_CHATBOT = "USER_TRY_TO_LOGIN_FROM_PUBLIC_CHATBOT",
  USER_SAVE_UPDATE_PROFILE = "USER_SAVE_UPDATE_PROFILE",
}

export enum PlanEvents {
  USER_TRY_TO_PURCHASE_PLAN = "USER_TRY_TO_PURCHASE_PLAN",
  USER_PURCHASE_PLAN_FAILED = "USER_PURCHASE_PLAN_FAILED",
  USER_PURCHASE_PLAN_SUCCEED = "USER_PURCHASE_PLAN_SUCCEED",
  USER_TRY_TO_UPGRADE_PLAN = "USER_TRY_TO_UPGRADE_PLAN",
  USER_TRY_TO_DOWNGRADE_PLAN = "USER_TRY_TO_DOWNGRADE_PLAN",
  USER_REQUESTED_TO_CANCEL_PLAN = "USER_REQUESTED_TO_CANCEL_PLAN",
  USER_CANCEL_DOWNGRADE_PLAN = "USER_CANCEL_DOWNGRADE_PLAN",
  CUSTOM_PLAN_CREATED_BY_ADMIN = "CUSTOM_PLAN_CREATED_BY_ADMIN",
  CUSTOM_PLAN_UPDATED_BY_ADMIN = "CUSTOM_PLAN_UPDATED_BY_ADMIN",
  CUSTOM_PLAN_CREATED = "CUSTOM_PLAN_CREATED",
  CUSTOM_PLAN_UPDATED = "CUSTOM_PLAN_UPDATED",
}

export enum ChatBotEvents {
  USER_CREATE_CHATBOT_FROM_TRIAL_PAGE = "USER_CREATE_CHATBOT_FROM_TRIAL_PAGE",
  USER_ASKED_QUESTION_AT_PUBLIC_CHATBOT = "USER_ASKED_QUESTION_AT_PUBLIC_CHATBOT",
  USER_GOES_AVOVE_QUESTION_LIMIT_ON_PUBLIC_CHATBOT = "USER_GOES_AVOVE_QUESTION_LIMIT_ON_PUBLIC_CHATBOT",
  USER_CREATED_NEW_CHATBOT = "USER_CREATED_NEW_CHATBOT",
  USER_GOES_AVOVE_CHATBOT_LIMIT = "USER_GOES_AVOVE_CHATBOT_LIMIT",
  USER_USED_CHANGE_PLAN_OPTION_FROM_CREATE_CHATBOT = "USER_USED_CHANGE_PLAN_OPTION_FROM_CREATE_CHATBOT",
  USER_ASKED_QUESTION_ON_CHATBOT = "USER_ASKED_QUESTION_ON_CHATBOT",
  USER_USED_CHANGE_PLAN_OPTION_FROM_CHAT_PAGE = "USER_USED_CHANGE_PLAN_OPTION_FROM_CHAT_PAGE",
  USER_ADDED_NEW_DATASOURCE_IN_EXISTING_CHATBOT = "USER_ADDED_NEW_DATASOURCE_IN_EXISTING_CHATBOT",
  USER_DELETE_CHATBOT = "USER_DELETE_CHATBOT",
  USER_REMOVE_DATASOURCE = "USER_REMOVE_DATASOURCE",
  USER_REINDEX_DATASOURCE = "USER_REINDEX_DATASOURCE",
  USER_COPIED_SHARE_URL_FROM_MANAGE_CHATBOT = "USER_COPIED_SHARE_URL_FROM_MANAGE_CHATBOT",
  USER_COPIED_BUBBLE_SCRIPT_FROM_MANAGE_CHATBOT = "USER_COPIED_BUBBLE_SCRIPT_FROM_MANAGE_CHATBOT",
  USER_COPIED_IFRAME_SCRIPT_FROM_MANAGE_CHATBOT = "USER_COPIED_IFRAME_SCRIPT_FROM_MANAGE_CHATBOT",
  USER_UPDATED_CUSTOM_TITLE_FROM_MANAGE_CHATBOT = "USER_UPDATED_CUSTOM_TITLE_FROM_MANAGE_CHATBOT",
  USER_UPDATED_CHAT_MODEL_FROM_MANAGE_CHATBOT = "USER_UPDATED_CHAT_MODEL_FROM_MANAGE_CHATBOT",
  USER_UPDATED_TEMPERATURE_FROM_MANAGE_CHATBOT = "USER_UPDATED_TEMPERATURE_FROM_MANAGE_CHATBOT",
  USER_UPDATED_TOP_P_FROM_MANAGE_CHATBOT = "USER_UPDATED_TOP_P_FROM_MANAGE_CHATBOT",
  USER_UPDATED_ALLOW_CHATBOT_MEMORY_FROM_MANAGE_CHATBOT = " USER_UPDATED_ALLOW_CHATBOT_MEMORY_FROM_MANAGE_CHATBOT",
  USER_UPDATED_CUSTOM_PROMPT_FROM_MANAGE_CHATBOT = " USER_UPDATED_CUSTOM_PROMPT_FROM_MANAGE_CHATBOT",
  USER_UPDATED_CUSTOM_ERROR_RESPONSE_FROM_MANAGE_CHATBOT = " USER_UPDATED_CUSTOM_ERROR_RESPONSE_FROM_MANAGE_CHATBOT",
  USER_UPDATED_MANAGE_ANSWER_LENGTH_SETTINGS_FROM_MANAGE_CHATBOT = " USER_UPDATED_MANAGE_ANSWER_LENGTH_SETTINGS_FROM_MANAGE_CHATBOT",
  USER_UPDATED_SOCIAL_MEDIA_SETTINGS_FROM_MANAGE_CHATBOT = "USER_UPDATED_SOCIAL_MEDIA_SETTINGS_FROM_MANAGE_CHATBOT",
  USER_UPDATED_CHATBOT_INTERFACE_SETTINGS_FROM_MANAGE_CHATBOT = "USER_UPDATED_CHATBOT_INTERFACE_FROM_MANAGE_CHATBOT",
  USER_UPDATED_GREETING_MESSAGE_SETTINGS_FROM_MANAGE_CHATBOT = "USER_UPDATED_GREETING_MESSAGE_SETTINGS_FROM_MANAGE_CHATBOT",
  USER_UPDATED_CHATBOT_WAVIER_SETTINGS_FROM_MANAGE_CHATBOT = "USER_UPDATED_CHATBOT_WAVIER_SETTINGS_FROM_MANAGE_CHATBOT",
  USER_UPDATED_MARK_AS_FEATURE_CHATBOT_FROM_MANAGE_CHATBOT = "USER_UPDATED_MARK_AS_FEATURE_CHATBOT_FROM_MANAGE_CHATBOT",
  USER_UPDATED_EMAIL_SETTINGS_FROM_MANAGE_CHATBOT = "USER_UPDATED_EMAIL_SETTINGS_FROM_MANAGE_CHATBOT",
  USER_UPDATED_ESCALATE_TO_EMAIL_SETTINGS_FROM_MANAGE_CHATBOT = "USER_UPDATED_ESCALATE_TO_EMAIL_SETTINGS_FROM_MANAGE_CHATBOT",
  USER_UPDATED_CHAT_WITH_HUMAN_LIVE_SETTINGS_FROM_MANAGE_CHATBOT = " USER_UPDATED_CHAT_WITH_HUMAN_LIVE_SETTINGS_FROM_MANAGE_CHATBOT",
  USER_UPDATED_LEAD_LOGIN_SETTINGS = "USER_UPDATED_LEAD_LOGIN_SETTINGS",
  USER_UPDATED_LEAD_THEME_SETTINGS_FOR_BUBBLE_CHAT = "USER_UPDATED_LEAD_THEME_SETTINGS_FOR_BUBBLE_CHAT",
  USER_UPDATED_LEAD_THEME_SETTINGS_FOR_IFRAME_CHAT = "USER_UPDATED_LEAD_THEME_SETTINGS_FOR_IFRAME_CHAT",
  USER_UPDATED_LEAD_THEME_SETTINGS_FOR_SHARE_CHAT = "USER_UPDATED_LEAD_THEME_SETTINGS_FOR_SHARE_CHAT",
  USER_DELETE_LEAD_CHATBOT_SESSIONS = "USER_DELETE_CHATBOT_SESSIONS",
  USER_ADDED_LEAD_SAMPLE_QUESTIONS = "USER_ADDED_LEAD_SAMPLE_QUESTIONS",
  USER_DELETE_LEAD_SAMPLE_QUESTIONS = "USER_DELETE_LEAD_SAMPLE_QUESTIONS",
  USER_ADDED_QUESTION_TO_EXISTING_FLASHCARD_CATEGORY = "USER_ADDED_QUESTION_TO_EXISTING_FLASHCARD_CATEGORY",
  USER_ADDED_QUESTION_TO_NEW_FLASHCARD_CATEGORY_FROM_CHAT_PAGE = "USER_ADDED_QUESTION_TO_NEW_FLASHCARD_CATEGORY_FROM_CHAT_PAGE",
  USER_REMOVED_QUESTION_FROM_FLASHCARD_CATEGORY_FROM_CHAT_PAGE = " USER_REMOVED_QUESTION_FROM_FLASHCARD_CATEGORY_FROM_CHAT_PAGE",
  USER_ADDED_NEW_FLASHCARD_CATEGORY = "USER_ADDED_NEW_FLASHCARD_CATEGORY",
  USER_EDITED_FLASHCARD_CATEGORY = "USER_EDITED_FLASHCARD_CATEGORY",
  USER_REVIEW_FLASHCARD_CATEGORY = "USER_REVIEW_FLASHCARD_CATEGORY",
  USER_REMOVED_FLASHCARD_CATEGORY = "USER_REMOVED_FLASHCARD_CATEGORY",
}

export enum ShareChatbotEvents {
  USER_FILL_LOGIN_FORM_ON_SHARE_CHAT = "USER_FILL_LOGIN_FORM_ON_SHARE_CHAT",
  USER_SKIP_LOGIN_FORM_ON_SHARE_CHAT = "USER_SKIP_LOGIN_FORM_ON_SHARE_CHAT",
  USER_ASKED_QUESTION_ON_SHARE_CHAT = "USER_ASKED_QUESTION_ON_SHARE_CHAT",
  USER_VIEW_SUGGESTED_QUESTIONS_ON_SHARE_CHAT = "USER_VIEW_SUGGESTED_QUESTIONS_ON_SHARE_CHAT",
  USER_ASKED_QUESTION_FROM_SUGGESTED_QUESTIONS_ON_SHARE_CHAT = "USER_ASKED_QUESTION_FROM_SUGGESTED_QUESTIONS_ON_SHARE_CHAT",
  USER_USED_THUMB_UP_ON_SHARE_CHAT = "USER_USED_THUMB_UP_ON_SHARE_CHAT",
  USER_USED_THUMB_DOWN_ON_SHARE_CHAT = "USER_USED_THUMB_DOWN_ON_SHARE_CHAT",
  USER_USED_EMAIL_ESCALATE_ON_SHARE_CHAT = "USER_USED_EMAIL_ESCALATE _ON_SHARE_CHAT",
  USER_USED_FILLED_EMAIL_ESCALATE_FORM_ON_SHARE_CHAT = "USER_USED_FILLED_EMAIL_ESCALATE_FORM _ON_SHARE_CHAT",
  USER_USED_LIVE_CHAT_ESCALATE_ON_SHARE_CHAT = "USER_USED_LIVE_CHAT_ESCALATE _ON_SHARE_CHAT",
}

export enum IFrameChatbotEvents {
  USER_FILL_LOGIN_FORM_ON_IFRAME_CHAT = "USER_FILL_LOGIN_FORM_ON_IFRAME_CHAT",
  USER_SKIP_LOGIN_FORM_ON_IFRAME_CHAT = "USER_SKIP_LOGIN_FORM_ON_IFRAME_CHAT",
  USER_ASKED_QUESTION_ON_IFRAME_CHAT = "USER_ASKED_QUESTION_ON_IFRAME_CHAT",
  USER_VIEW_SUGGESTED_QUESTIONS_ON_IFRAME_CHAT = "USER_VIEW_SUGGESTED_QUESTIONS_ON_IFRAME_CHAT",
  USER_ASKED_QUESTION_FROM_SUGGESTED_QUESTIONS_ON_IFRAME_CHAT = "USER_ASKED_QUESTION_FROM_SUGGESTED_QUESTIONS_ON_IFRAME_CHAT",
  USER_USED_THUMB_UP_ON_IFRAME_CHAT = "USER_USED_THUMB_UP_ON_IFRAME_CHAT",
  USER_USED_THUMB_DOWN_ON_IFRAME_CHAT = "USER_USED_THUMB_DOWN_ON_IFRAME_CHAT",
  USER_USED_EMAIL_ESCALATE_ON_IFRAME_CHAT = "USER_USED_EMAIL_ESCALATE _ON_IFRAME_CHAT",
  USER_USED_FILLED_EMAIL_ESCALATE_FORM_ON_IFRAME_CHAT = "USER_USED_FILLED_EMAIL_ESCALATE_FORM _ON_IFRAME_CHAT",
  USER_USED_LIVE_CHAT_ESCALATE_ON_IFRAME_CHAT = "USER_USED_LIVE_CHAT_ESCALATE _ON_IFRAME_CHAT",
}

export enum BubbleChatbotEvents {
  USER_FILL_LOGIN_FORM_ON_BUBBLE_CHAT = "USER_FILL_LOGIN_FORM_ON_BUBBLE_CHAT",
  USER_SKIP_LOGIN_FORM_ON_BUBBLE_CHAT = "USER_SKIP_LOGIN_FORM_ON_BUBBLE_CHAT",
  USER_ASKED_QUESTION_ON_BUBBLE_CHAT = "USER_ASKED_QUESTION_ON_BUBBLE_CHAT",
  USER_VIEW_SUGGESTED_QUESTIONS_ON_BUBBLE_CHAT = "USER_VIEW_SUGGESTED_QUESTIONS_ON_BUBBLE_CHAT",
  USER_ASKED_QUESTION_FROM_SUGGESTED_QUESTIONS_ON_BUBBLE_CHAT = "USER_ASKED_QUESTION_FROM_SUGGESTED_QUESTIONS_ON_BUBBLE_CHAT",
  USER_USED_THUMB_UP_ON_BUBBLE_CHAT = "USER_USED_THUMB_UP_ON_BUBBLE_CHAT",
  USER_USED_THUMB_DOWN_ON_BUBBLE_CHAT = "USER_USED_THUMB_DOWN_ON_BUBBLE_CHAT",
  USER_USED_EMAIL_ESCALATE_ON_BUBBLE_CHAT = "USER_USED_EMAIL_ESCALATE _ON_BUBBLE_CHAT",
  USER_USED_FILLED_EMAIL_ESCALATE_FORM_ON_BUBBLE_CHAT = "USER_USED_FILLED_EMAIL_ESCALATE_FORM _ON_BUBBLE_CHAT",
  USER_USED_LIVE_CHAT_ESCALATE_ON_BUBBLE_CHAT = "USER_USED_LIVE_CHAT_ESCALATE _ON_BUBBLE_CHAT",
}
