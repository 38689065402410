import React, { useState } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";

const SearchBook = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [books, setBooks] = useState<any>({ items: [] });
  const onInputChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  let API_URL = `https://www.googleapis.com/books/v1/volumes`;

  const fetchBooks = async () => {
    const result = await axios.get(`${API_URL}?q=${searchTerm}&startIndex=0&maxResults=24`);
    setBooks(result.data);
  };

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    fetchBooks();
  };

  const bookAuthors = (authors: any) => {
    if (authors && authors.length <= 2) {
      authors = authors.join(" and ");
    } else if (authors && authors.length > 2) {
      let lastAuthor = " and " + authors.slice(-1);
      authors.pop();
      authors = authors.join(", ");
      authors += lastAuthor;
    }
    return authors;
  };

  return (
    <section>
      <form onSubmit={onSubmitHandler}>
        <label>
          <span>Search for books</span>
          <input
            type="search"
            placeholder="microservice, restful design, etc.,"
            value={searchTerm}
            onChange={onInputChange}
          />
          <button type="submit">Search</button>
        </label>
      </form>
      <Container>
        <Row style={{ padding: "1rem" }}>
          {books &&
            books.items &&
            books.items.length > 0 &&
            books.items.map((book: any) => {
              return (
                <Col
                  sm={2}
                  style={{
                    display: "flex",
                    whiteSpace: "break-spaces",
                    flexDirection: "column",
                    margin: "2rem 0rem",
                  }}
                >
                  <img
                    alt={`${book.volumeInfo.title} book`}
                    src={`http://books.google.com/books/content?id=${book.id}&printsec=frontcover&img=1&zoom=1&source=gbs_api`}
                  />
                  <h5
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      marginTop: "1rem",
                    }}
                  >
                    {book.volumeInfo.title}
                  </h5>
                  <span>{bookAuthors(book.volumeInfo.authors)}</span>
                  <span>{book.volumeInfo.publishedDate}</span>
                </Col>
              );
            })}
        </Row>
      </Container>
    </section>
  );
};

export default SearchBook;
