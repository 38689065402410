import React, { useContext } from "react";
import styled from "styled-components";
import { LoaderContext } from "./context/loader-context";

const LoaderText = styled.p`
  margin: 0.7rem;
`;

const LoadingSpinner = () => {
  const { loaderMessage } = useContext(LoaderContext);
  return (
    <div className="spinner-container">
      <div>
        <div className="loading-spinner"></div>
        <LoaderText> {loaderMessage} </LoaderText>
      </div>
    </div>
  );
};

export default LoadingSpinner;
