import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import DropChatLogo from "../images/dropchat-logo.svg";
import HandIcon from "../images/hand-icon.svg";
import BackButton from "../images/left-pagination-arrow.svg";
import Signup from "./signup-form";
import { MOBILE } from "../constants/mobile";
// import { TAB } from "../constants/tab";
// import { MEDIUM } from "../constants/desktop";

const MainSection = styled(Container)`
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 2rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    box-shadow: none;
    padding: 0.4rem;
    border-radius: 0px;
    background: transparent;
  }
`;

const CenterContentRow = styled(Row)`
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
`;

const CenterContentColumn = styled(Col)`
  justify-content: center;
  align-items: center;
`;

const LoginMainSection = styled.div`
  justify-content: center;
  align-content: center;
  height: 100vh;
`;
const LoginColumn = styled(Col)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: flex-start;
    padding: 2rem 0;
  }
`;

const BackButtonSection = styled.a`
  color: #1585b5;
  font-weight: bold;
  margin-top: 1rem;
  text-decoration: none;
  cursor: pointer;
  & img {
    margin-right: 1rem;
  }
  &:hover {
    color: #1585b5;
  }
`;

const CredentialSignupView = ({ loadingState }: any) => {
  return (
    <LoginMainSection>
      <LoginColumn lg={4}>
        <MainSection fluid>
          <CenterContentRow>
            <CenterContentColumn>
              <img src={DropChatLogo} alt="logo" />
            </CenterContentColumn>
          </CenterContentRow>
          <CenterContentRow>
            <CenterContentColumn>
              Welcome to Dropchat &nbsp;
              <img src={HandIcon} alt="logo" />
            </CenterContentColumn>
          </CenterContentRow>

          <CenterContentRow>
            <CenterContentColumn>
              <Signup />
            </CenterContentColumn>
          </CenterContentRow>
        </MainSection>
        <BackButtonSection href={process.env.REACT_APP_FRONTEND_URL}>
          <img alt="imgback" src={BackButton} />
          <span>Back to DropChat</span>
        </BackButtonSection>
      </LoginColumn>
    </LoginMainSection>
  );
};

export default CredentialSignupView;
