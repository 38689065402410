import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { LoaderContext } from "../../context/loader-context";
import BackIcon from "../../images/chat-back.svg";
import ChatBotIcon from "../../images/chat-bot-icon.svg";
import UserImage from "../../images/chat-user.svg";
import SessionChevronRight from "../../images/session-chevron-right.svg";
import DeleteIcon from "../../images/trash-disabled.svg";
import myAxios from "../../myaxios";
import CustomMarkdown from "../../utils/custom-markdown";
import { parseErrorResponse } from "../../utils/utils";
import ConfirmationBox from "../utility-component/confirmation-box";

const DivForDltPopUp = styled.div`
  position: fixed;
  display: block;
  width: 80%;
  /* height: 70%; */
  height: 500px;
  background: #ffffff;
  /* box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 5%); */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  z-index: 111;
  left: 50%;
  top: 46%;
  /* padding-bottom: 15px; */
  transform: translate(-50%, -50%);
  /* display: flex;
  flex-direction: column; */
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;

const DltHeader = styled.div`
  width: 100%;
  height: 35px;
  /* background: #1585b5; */
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: start;
`;
const SpanDltPopup = styled.span`
  font-family: "Gill Sans MT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
`;
interface Color {
  selected: boolean;
}

const ParentSection = styled(Col)`
  /* padding: 1rem; */
  flex-direction: row;
  justify-content: space-between;
`;

const ColumnSection = styled.div`
  background: #fff;
  flex-direction: column;
  /* border: 1px solid #eaeaea; */
  /* border-radius: 8px; */
  width: 100%;
`;

const ContentParent = styled.div`
  flex-direction: column;
`;

const HeadingText = styled.p`
  font-size: 12px;
  color: #33303c;
  font-weight: 400;
  /* padding: 0.5rem; */
  z-index: 11;
  /* margin: 0; */
  margin: 0 0px 0 23px;
`;

const Subheading = styled.p`
  font-size: 16px;
  color: #33303c;
  font-weight: 400;
  padding-left: 0.5rem;
`;

const DataSection = styled.div`
  flex-direction: row;
`;
const Sidebar = styled(Col)``;

const SelectedLead = styled(Col)`
  border: 1px solid #eaeaea;
  flex-direction: column;
`;

const SessionList = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  width: 100%;
  overflow-y: scroll;
  height: 520px;
`;

const SessionListItem = styled.li`
  background: ${(props: Color) => (props.selected ? "#1585b5" : "#fff")};
  padding: 10px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const SessionItemText = styled.div`
  flex-direction: column;
`;
const SessionItemArrow = styled.div``;

const SessionUserName = styled.p`
  color: ${(props: Color) =>
    props.selected ? "#fff" : "rgba(51, 48, 60, 0.6)"};
  margin: 0;
  font-size: 16px;
`;

const SessionThorugh = styled.p`
  color: ${(props: Color) =>
    props.selected ? "#fff" : "rgba(51, 48, 60, 0.6)"};
  margin: 0;
  font-size: 14px;
`;

const SessionContentHeader = styled.div`
  background: #2f3349;
  padding: 20px;
  color: #ffffffcc;
`;

const SessionContentBody = styled.div`
  flex-direction: column;
  height: 480px;
  overflow-y: scroll;
`;

export const SignleQuestionAnswerSection = styled.div`
  margin: 5px;
  flex-direction: column;
`;

export const ResponseSection = styled.div`
  padding: 8px 8px;
  color: #282c34;
  background: #ffffff;
  border-bottom: 1px solid #ebe8f0;
  align-items: center;
  align-items: flex-start;
  flex-direction: column;
  & span {
    font-size: 2rem;
  }
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
    font-size: 14px;
  }
`;

export const QuestionSection = styled.div`
  padding: 8px 8px;
  background: #ffffff;
  align-items: center;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
    font-size: 14px;
  }
`;

export const QuestionAnswerSection = styled.div`
  flex-direction: column;
  width: 100%;
`;
export const ImgTag = styled.img`
  width: 10px;
  cursor: pointer;
`;
const DeleteDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 55%;
`;
const ShowChatSessionBox = ({ setShow, book }: any) => {
  const { setShowLoader } = useContext(LoaderContext);
  const [chatSessions, setChatSessions] = useState<any>();
  const [selectedChatSession, setSelectedChatSession] = useState<any>();
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");
  const [confirmationAction, setConfirmationAction] = useState<string>("");
  const [selectedChatSessionToDelete, setSelectedChatSessionToDelete] =
    useState<any>();
  let isLast = false;

  useEffect(() => {
    if (book) {
      setShowLoader(true);
      myAxios
        .post(
          process.env.REACT_APP_SERVER_URL +
            `/chat-sessions/paginated-chat-sessions`,
          {
            bookId: book.id || book._id,
          }
        )
        .then((response) => {
          if (response.data && response.data.success) {
            setChatSessions(response.data.data.chatSessions);
            if (
              response.data.data.chatSessions &&
              response.data.data.chatSessions.length > 0
            )
              setSelectedChatSession(response.data.data.chatSessions[0]);
            setShowLoader(false);
          } else {
            setShowLoader(false);
            NotificationManager.error(
              response.data.message
                ? response.data.message
                : "Unable to fetch chat session, please try later!"
            );
          }
        })
        .catch((error) => {
          // handle error
          setShowLoader(false);
          NotificationManager.error(parseErrorResponse(error));
        });
    }
  }, [book, setShowLoader]);

  const deleteSource = (isLast: boolean) => {
    if (isLast) {
      setConfirmationBoxMessage(
        "Are you sure you want to delete the remaining session of this chatbot? This would also delete chat history. Please confirm?"
      );
    } else {
      setConfirmationBoxMessage("Do you really want to delete this session?");
    }
  };

  const actionBodyTemplate = (id: any) => {
    if (selectedChatSession?.sources?.length === 1) {
      isLast = true;
    }

    return (
      <>
        <img
          style={{ cursor: "pointer", width: "20px" }}
          src={DeleteIcon}
          alt={"Delete"}
          className="w-4rem"
          onClick={() => {
            // if (dataSource.indexStatus === 1) {
            //   NotificationManager.warning(
            //     "The source is still in progress, please wait!"
            //   );
            //   return;
            // }
            deleteSource(isLast);
            setConfirmationAction("delete");
            setShowUtilityConfirmationBox(true);
            setSelectedChatSessionToDelete(id);
          }}
        />
      </>
    );
  };

  const confirmClick = (action: string) => {
    if (action === "delete" && selectedChatSessionToDelete) {
      setShowLoader(true);
      const sessionId =
        selectedChatSession &&
        (selectedChatSession._id || selectedChatSession.id);
      myAxios
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/chat-sessions/${sessionId}`
        )
        .then((response) => {
          if (response.data && response.data.success) {
            // Update cueent book
            // setSelectedBook(response.data.data);
            setShowUtilityConfirmationBox(false);
            const newchatSessions = chatSessions.filter(
              (newData: any) => newData._id != selectedChatSessionToDelete
            );
            setChatSessions(newchatSessions);
            if (newchatSessions && newchatSessions.length > 0) {
              setSelectedChatSession(newchatSessions[0]);
            } else {
              setSelectedChatSession(undefined);
            }

            NotificationManager.success("Session deleted successfully");
            // if (isLast) {
            //   navigate("/");
            // }
          } else {
            setShowUtilityConfirmationBox(false);
            NotificationManager.error(
              response.data && response.data.message
                ? response.data.message
                : "Not able to delete session, Please try again!"
            );
          }
          setShowLoader(false);
        })
        .catch((error) => {
          setShowUtilityConfirmationBox(false);
          NotificationManager.error(parseErrorResponse(error));
          setShowLoader(false);
        });
    }
  };

  return (
    <DivForDltPopUp>
      <DltHeader>
        {/* <Arrow
          src={DltrossSvg}
          alt="dlt"
          onClick={() => {
            setShow(false);
          }}
        /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              alignItems: "center",
              margin: "0 21px",
              flexDirection: "row",
            }}
          >
            {/* <span>ico</span> */}
            <ImgTag src={BackIcon} alt="back" onClick={() => setShow(false)} />
            <SpanDltPopup>{book && book.title}</SpanDltPopup>
          </div>
          <HeadingText>
            Below session is the guest user session, which is generated from the
            Dropchat.co site.
          </HeadingText>
        </div>
      </DltHeader>
      <Row>
        {/* style={{ margin: "3rem" }} */}
        <Col sm={12}>
          <ParentSection sm={12}>
            <ColumnSection>
              <ContentParent>
                {/* <HeadingText>Chatbot Sessions</HeadingText> */}
                <Subheading>
                  {/* You can use different theme settings in different formats */}
                </Subheading>
              </ContentParent>
              <DataSection>
                <Sidebar sm={3}>
                  <SessionList>
                    {chatSessions &&
                      chatSessions.length > 0 &&
                      chatSessions.map((chatSession: any, index: number) => {
                        return (
                          <SessionListItem
                            selected={
                              selectedChatSession._id === chatSession._id
                            }
                            onClick={() => {
                              setSelectedChatSession(chatSession);
                            }}
                          >
                            <SessionItemText>
                              <SessionUserName
                                selected={
                                  selectedChatSession._id === chatSession._id
                                }
                              >
                                {chatSession.name
                                  ? chatSession.name
                                  : chatSession.email
                                  ? chatSession.email
                                  : chatSession.mobileNumber
                                  ? chatSession.mobileNumber
                                  : `Guest User - ${index + 1}`}
                              </SessionUserName>
                              <SessionThorugh
                                selected={
                                  selectedChatSession._id === chatSession._id
                                }
                              >
                                {chatSession.source === "bubblechat"
                                  ? "Embedded Chat Lead"
                                  : chatSession.source === "share"
                                  ? "Shared URL Lead"
                                  : chatSession.source === "iframe"
                                  ? "iFrame Lead"
                                  : selectedChatSession.source === "guest"
                                  ? "Guest"
                                  : "N/A"}
                              </SessionThorugh>
                            </SessionItemText>
                            <SessionItemArrow>
                              <img src={SessionChevronRight} alt="select" />
                            </SessionItemArrow>
                          </SessionListItem>
                        );
                      })}
                  </SessionList>
                </Sidebar>
                {selectedChatSession && (
                  <SelectedLead sm={9}>
                    <SessionContentHeader>
                      {selectedChatSession.name
                        ? selectedChatSession.name
                        : "Guest User"}{" "}
                      &nbsp;|&nbsp;{" "}
                      {selectedChatSession.ipAddress
                        ? selectedChatSession.ipAddress
                        : "N/A"}{" "}
                      &nbsp;|&nbsp;{" "}
                      {selectedChatSession.mobileNumber
                        ? selectedChatSession.mobileNumber
                        : "N/A"}{" "}
                      &nbsp;|&nbsp; Date:{" "}
                      {selectedChatSession.createdAt
                        ? moment(selectedChatSession.createdAt).format(
                            "DD/MM/YYYY hh:mm A"
                          )
                        : "N/A"}{" "}
                      &nbsp;|&nbsp; From:{" "}
                      {selectedChatSession.source === "bubblechat"
                        ? "Embedded Chat Lead"
                        : selectedChatSession.source === "share"
                        ? "Shared URL Lead"
                        : selectedChatSession.source === "iframe"
                        ? "iFrame Lead"
                        : selectedChatSession.source === "guest"
                        ? "Guest"
                        : "N/A"}
                      <DeleteDiv>
                        {actionBodyTemplate(selectedChatSession._id)}
                      </DeleteDiv>
                    </SessionContentHeader>
                    <SessionContentBody>
                      {selectedChatSession &&
                        selectedChatSession.chats &&
                        selectedChatSession.chats.length > 0 &&
                        selectedChatSession.chats.map((chatHistory: any) => {
                          return (
                            <SignleQuestionAnswerSection>
                              <QuestionSection>
                                <img
                                  width="20"
                                  src={UserImage}
                                  alt="user_image"
                                />
                                &nbsp;&nbsp;
                                <h5>{chatHistory.question}</h5>
                              </QuestionSection>
                              <ResponseSection>
                                <div>
                                  <img
                                    width="20"
                                    src={ChatBotIcon}
                                    alt="user_image"
                                    style={{
                                      display: "flex",
                                      alignSelf: "baseline",
                                    }}
                                  />
                                  &nbsp;&nbsp;
                                  {chatHistory.answer &&
                                  chatHistory.answer.length > 0 ? (
                                    <CustomMarkdown text={chatHistory.answer} />
                                  ) : (
                                    <h5>{`${chatHistory.answer}`}</h5>
                                  )}
                                </div>
                              </ResponseSection>
                            </SignleQuestionAnswerSection>
                          );
                        })}
                    </SessionContentBody>
                  </SelectedLead>
                )}
              </DataSection>
            </ColumnSection>
            {showUtilityConfirmationBox && (
              <ConfirmationBox
                setShow={setShowUtilityConfirmationBox}
                name="Confirmation"
                message={confirmationBoxMessage}
                okButtonText="Yes"
                action={confirmationAction}
                okButtonClick={confirmClick}
              />
            )}
          </ParentSection>
        </Col>
      </Row>
    </DivForDltPopUp>
  );
};

export default ShowChatSessionBox;
