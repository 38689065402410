import moment from "moment-timezone";

const ReindexAtColumn = ({ dataSource }: any) => {
  if (dataSource.reindexTime && dataSource.reindexTime.length > 0)
    return (
      <>
        <span>
          {moment(dataSource.reindexTime).format("DD/MM/YYYY | hh:mm A")}
        </span>
      </>
    );
  else
    return (
      <>
        <span>-</span>
      </>
    );
};

export default ReindexAtColumn;
