import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  DEVICE_MEDIUM,
  MEDIUM,
  MOBILE,
  TAB,
} from "../../../../constants/breakpoints";
import { PublicContext } from "../../../../context/PublicContext";
import DltrossSvg from "../../../../images/black-cross.svg";
import ChatBotIcon from "../../../../images/chat-bot-icon.svg";
import UserImage from "../../../../images/chat-user.svg";
import BubbleChatLiveIcon from "../../../../images/drop-chat-icon.svg";
import * as BubbleChatStyles from "./styled";

import ChatLiveIcon from "../../../../images/chatLive-face-agent1.svg";
import EscalateEmailIcon from "../../../../images/escEmailIcon.svg";
import THUMBDOWN from "../../../../images/thumb-down2.svg";
import THUMBUPINACTIVE from "../../../../images/thumb-up-inactive.svg";

const Arrow = styled.img`
  height: 25px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-top: 3.5px;
  }
`;

const InnerDiv = styled.div`
  height: auto;
  width: 100%;
  background: linear-gradient(0deg, #eaeaea, #eaeaea),
    linear-gradient(0deg, #f8f7fa, #f8f7fa);
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(234, 234, 234, 1);
  border-radius: 4px;
  display: block;
  padding: 10px;
`;
const InputField = styled.input`
  width: 100%;
  border: 1px solid rgba(51, 48, 60, 0.2);
  height: 25px;
  border-radius: 4px;
`;
const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;
const SkipFormSpan = styled.span`
  font-family: "Poppins", sans-serif;
  color: rgba(21, 133, 181, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
`;
const SubmitButton = styled.button`
  background: rgba(21, 133, 181, 1);
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  width: 40%;
  border-radius: 4px;
`;
const Labels = styled.label`
  color: rgba(51, 48, 60, 0.8);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

const InlineChatProType = ({
  isUsername,
  isEmail,
  isPhone,
  allFormValues,
  setShowPreviewPopup,
}: any) => {
  const { deviceWidth } = useContext(PublicContext);

  //   console.log("allFormValues inline component", allFormValues);

  return (
    <>
      <div style={{ flexDirection: "column", width: "100%" }}>
        <Col
          xs={12}
          style={{
            justifyContent: "space-between",
            padding: deviceWidth !== DEVICE_MEDIUM ? "0.5rem" : "0",
          }}
        >
          <BubbleChatStyles.HeadingText>
            Chatbot Preview
          </BubbleChatStyles.HeadingText>
          {deviceWidth !== DEVICE_MEDIUM && (
            <Arrow
              src={DltrossSvg}
              alt="dlt"
              onClick={() => {
                setShowPreviewPopup(false);
              }}
            />
          )}
        </Col>
        <BubbleChatStyles.MainContainer sm={6}>
          <BubbleChatStyles.RightColumn>
            <BubbleChatStyles.ChatPageHeader className="App-header">
              <Col sm={1}>
                <img src={BubbleChatLiveIcon} alt="online..." width={30} />{" "}
                &nbsp;
              </Col>
              <Col
                sm={10}
                style={{
                  alignItems: "center",
                  cursor: "pointer",
                  paddingLeft: "1rem",
                }}
              >
                <b style={{ fontSize: "18px" }}>
                  {allFormValues && allFormValues.title
                    ? allFormValues.title
                    : "Welcome to Dropchat"}
                </b>
              </Col>
            </BubbleChatStyles.ChatPageHeader>

            <BubbleChatStyles.ChatInputBox>
              <BubbleChatStyles.RightContentRow>
                <BubbleChatStyles.ChatBotContent sm={12}>
                  <div style={{ display: "block", height: "420px" }}>
                    <BubbleChatStyles.QuestionAnswerSection
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <BubbleChatStyles.QuestionSection
                        style={{
                          // background: "#1585b5",
                          background: "rgba(208, 231, 240, 1)",
                          color: "rgba(51, 48, 60, 1)",
                        }}
                      >
                        <img width="20" src={UserImage} alt="user_image" />
                        &nbsp;&nbsp;
                        <h5
                          style={{
                            color: "#rgba(51, 48, 60, 1)",
                          }}
                        >
                          How you can locate the titanic under the sea?
                        </h5>
                      </BubbleChatStyles.QuestionSection>

                      <BubbleChatStyles.ResponseSection
                        style={{
                          background: "rgba(248, 247, 250, 1)",
                        }}
                      >
                        <div>
                          <img
                            width="20"
                            src={ChatBotIcon}
                            alt="user_image"
                            style={{
                              display: "flex",
                              alignSelf: "baseline",
                            }}
                          />
                          &nbsp;&nbsp;
                          <h5
                            style={{
                              color: "rgba(51, 48, 60, 1)",
                            }}
                          >
                            Exploring the Deep: As I explain in the book, the
                            Titanic was located in the North
                          </h5>
                        </div>
                      </BubbleChatStyles.ResponseSection>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <img
                          width="17"
                          src={THUMBUPINACTIVE}
                          alt="..."
                          style={{
                            marginRight: "6px",
                          }}
                        />
                        <img
                          width="17"
                          src={THUMBDOWN}
                          alt="..."
                          style={{
                            marginRight: "6px",
                          }}
                        />

                        <img
                          width="17"
                          src={EscalateEmailIcon}
                          alt="..."
                          style={{
                            marginRight: "6px",
                          }}
                        />

                        <img
                          width="17"
                          src={ChatLiveIcon}
                          alt="..."
                          style={{
                            marginRight: "6px",
                          }}
                        />
                      </div>
                    </BubbleChatStyles.QuestionAnswerSection>
                    {allFormValues &&
                      allFormValues.loginForm &&
                      allFormValues.loginForm !== "none" && (
                        <Row style={{ marginTop: "10px" }}>
                          <Col xs={12} sm={12} md={12}>
                            <InnerDiv>
                              {allFormValues &&
                                allFormValues.loginForm &&
                                allFormValues.loginForm !== "none" && (
                                  <BubbleChatStyles.FormHeading
                                    style={{
                                      fontSize: "14px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {allFormValues && allFormValues.customText
                                      ? allFormValues.customText
                                      : "Fill out form to start chatting"}
                                  </BubbleChatStyles.FormHeading>
                                )}
                              {allFormValues &&
                                allFormValues.loginForm &&
                                allFormValues.loginForm !== "none" &&
                                isUsername && (
                                  <>
                                    <Labels>
                                      {allFormValues && allFormValues.username
                                        ? allFormValues.username
                                        : "Full Name"}
                                    </Labels>
                                    <InputField type="text" />
                                  </>
                                )}
                              {allFormValues &&
                                allFormValues.loginForm &&
                                allFormValues.loginForm !== "none" &&
                                isEmail && (
                                  <>
                                    <Labels style={{ marginTop: "10px" }}>
                                      Email Address
                                    </Labels>
                                    <InputField type="text" />
                                  </>
                                )}
                              {allFormValues &&
                                allFormValues.loginForm &&
                                allFormValues.loginForm !== "none" &&
                                isPhone && (
                                  <>
                                    <Labels style={{ marginTop: "10px" }}>
                                      {allFormValues && allFormValues.phone
                                        ? allFormValues.phone
                                        : "Phone"}
                                    </Labels>
                                    <InputField type="text" maxLength={13} />
                                  </>
                                )}
                              <ButtonDiv>
                                {allFormValues &&
                                  allFormValues.loginForm === "optional" && (
                                    <SkipFormSpan>Skip Form</SkipFormSpan>
                                  )}
                                <SubmitButton>Send</SubmitButton>
                              </ButtonDiv>
                            </InnerDiv>
                          </Col>
                        </Row>
                      )}
                  </div>
                  <BubbleChatStyles.FieldColumn sm={12}>
                    <BubbleChatStyles.BookFetchForm>
                      <BubbleChatStyles.FieldSection></BubbleChatStyles.FieldSection>
                      <a
                        href={
                          (allFormValues &&
                            allFormValues.inlineForm &&
                            allFormValues.inlineForm !== "none" &&
                            allFormValues.footerBrandingLink) ||
                          "https://dropchat.co/"
                        }
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <BubbleChatStyles.PoweredText>
                          {allFormValues && allFormValues.footerBranding
                            ? allFormValues.footerBranding
                            : "Powered By Dropchat"}
                        </BubbleChatStyles.PoweredText>
                      </a>
                    </BubbleChatStyles.BookFetchForm>
                  </BubbleChatStyles.FieldColumn>
                </BubbleChatStyles.ChatBotContent>
              </BubbleChatStyles.RightContentRow>
            </BubbleChatStyles.ChatInputBox>
          </BubbleChatStyles.RightColumn>
        </BubbleChatStyles.MainContainer>
      </div>
    </>
  );
};

export default InlineChatProType;
