import { useContext, useEffect, useRef, useState } from "react";
import { Col, FormLabel, Row } from "react-bootstrap";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import { BookContext } from "../../../context/BookContext";
import { Field, Formik } from "formik";
import { Document } from "react-pdf";
import { LoaderContext } from "../../../context/loader-context";
import myAxios from "../../../myaxios";
import { NotificationManager } from "react-notifications";
import { UserContext } from "../../../context/UserContext";
import { TAB } from "../../../constants/tab";
import { MEDIUM } from "../../../constants/desktop";
import { parseErrorResponse } from "../../../utils/utils";
import UploadComponent from "../../utility-component/drag-drop-component";

interface SaveButtonProps {
  displayProp?: boolean; // Declare the custom prop and its type
}

const ParentDiv = styled.div`
  overflow: hidden;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  align-items: center;
  justify-content: center;
`;
const EmptyDiv = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;
const FormLabelNew = styled(FormLabel)`
  font-weight: 600;
  font-size: 14px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;
const TextAREANew = styled.textarea`
  border-radius: 4px;
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 10px;
  font-size: 14px;
  min-height: 50vh;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
  }
`;
const InputField = styled.input`
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #eaeaea !important;
  display: inline;
  border-radius: 4px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    border-radius: 4px;
  }
`;
const Container = styled(Col)`
  position: fixed;
  display: block;
  width: 40vw;
  margin: auto;
  overflow: auto;
  max-height: 85vh;
  inset: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 999999;
  border-radius: 10px 10px 0px 0px;
`;
const DltHeader = styled(Row)`
  height: 45px;
  background: rgba(47, 51, 73, 1);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #2f4c95;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: white;
  @media (max-width: ${MOBILE.maxWidth}px) {
    color: white;
  }
`;
const MainFormRow = styled(Row)`
  padding: 0.5rem 2rem;
`;

const ButtonRow = styled(Row)`
  padding: 0rem 3rem;
  display: flex;
  justify-content: space-between;
  //   align-items: center;
`;

const UpdateButton = styled.button<SaveButtonProps>`
  background: #1585b5;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: auto !important;
  padding: 0.4rem 3rem;
  display: ${(props) => (props.displayProp ? " flex" : " none")};
  &:disabled {
    display: ${(props) => (props.displayProp ? " flex" : " none")};
    opacity: ${(props) => (props.displayProp ? " 0.5" : " 0")};
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const CancelButton = styled.button`
  background: none;
  padding: 0.4rem 3rem;
  color: #2f3349;
  border: 1px solid #2f3349;
  width: auto !important;
  display: flex;
  margin-top: auto;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const TextAreaLimitSpan = styled.span`
  max-width: max-content;
  padding: 2px 10px;
  background: #1585b5;
  color: #fff;
  font-size: 12px;
`;

const SelectedCount = styled.span`
  color: #000;
  font-size: 12px;
  font-weight: 500;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: left;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: flex;
    font-size: 13px;
    padding-right: "0rem";
  }
  @media (min-width: ${TAB.minWidth}px) {
    display: flex;
    font-size: 11px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    color: #000;
    font-size: 13px;
    font-weight: bold;
    padding-left: 1rem;
    align-items: left;
    display: flex;
  }
`;
const ColPDFMainSubSection = styled(Col)`
  align-items: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column;
    align-items: start;
  }
`;
const ColForPDFSelection = styled(Col)`
  flex-direction: column;
  padding-left: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 60px;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: start;
    padding-left: 0;
  }
`;

const FormInputTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  height: 8rem;
`;

const PDFNameFormLabel = styled(FormLabel)`
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #33303c;

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    letter-spacing: 0.9px;
    margin-bottom: -1px;
    font-weight: 600;
    width: 86vw !important;
  }
`;
const FormLabelDocInput = styled(FormLabel)`
  width: 35%;
  height: 70%;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #1585b5;
  background: linear-gradient(
    0deg,
    rgba(21, 133, 181, 0.2),
    rgba(21, 133, 181, 0.2)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: #33303ccc;
  font-weight: 500;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 8rem;
    height: 5vh;
    align-items: center;
    justify-content: center;
  }
`;

const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #33303c99;
  background: linear-gradient(0deg, #ffffff, #ffffff);

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 14px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
  }
`;

interface fileWithCharacter {
  file: File;
  characterCount: number;
  characterCountDone: boolean;
}

const EditPdfChatbot = ({ editPopUp, dataSource, toBeEdit }: any) => {
  const { selectedBook, setSelectedBook, updateBookTitle } =
    useContext(BookContext);
  const { user, updateUserSubscriptionIndexCount, updateUserSubscription } =
    useContext(UserContext);
  const { setShowLoader } = useContext(LoaderContext);
  const [textContent, setTextContent] = useState<string>("");
  const [fileObject, setFileObject] = useState<any>();
  const [numPages, setNumPages] = useState(0);
  const [lastPdfPageLimitStatus, setLastPdfPageLimitStatus] =
    useState<Boolean>(false);
  const [pdfCountLimit, setPdfCountLimit] = useState<Boolean>(false);
  const [totalPdfCharacterCount, setTotalPdfCharacterCount] = useState(0);
  const [disablePdfIndexLimit, setDisablePdfIndexLimitState] =
    useState<boolean>(false);
  const [characterCount, setCharacterCount] = useState(0);

  const hidePopup = () => {
    editPopUp(false);
  };

  useEffect(() => {
    // console.log("inside subscription")
    if (user) {
      // setLoading(true);
      myAxios.get(`${process.env.REACT_APP_SERVER_URL}/plans`).then((res) => {
        if (res.data.success) {
          // setPlansList(res.data.data.plans);
          // setActivePlan(res.data.data.currentSubscription);
          // setDowngradeRequestData(res.data.data.downgradeRequestData);
          // // setLoading(false);

          // // user state need to update
          updateUserSubscription(res.data.data.currentSubscription);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const checkPdfPageLimit = (count: number) => {
    if (
      user &&
      user.currentSubscription &&
      user.currentSubscription.meta &&
      user.currentSubscription.meta.pdfPageSupport === -1
    ) {
      setLastPdfPageLimitStatus(false);
      setLastPdfPageLimitStatus(false);
      setPdfCountLimit(false);
    } else {
      setPdfCountLimit(
        (user &&
        user.currentSubscription &&
        user.currentSubscription.meta &&
        user.currentSubscription.meta.pdfPageSupport
          ? user.currentSubscription.meta.pdfPageSupport
          : 500) < count
          ? true
          : false
      );
      setLastPdfPageLimitStatus(
        (user &&
        user.currentSubscription &&
        user.currentSubscription.meta &&
        user.currentSubscription.meta.pdfPageSupport
          ? user.currentSubscription.meta.pdfPageSupport
          : 500) < count
          ? true
          : false
      );
    }
  };

  const onLoadSuccess = async (pdf: any) => {
    const numPages = pdf.numPages;

    setNumPages(numPages);
    checkPdfPageLimit(numPages);

    let extractedText = "";
    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map((item: any) => item.str).join(" ");
      extractedText += pageText;
    }
    setCharacterCount(extractedText.length);
  };
  useEffect(() => {
    const indexingCharacterLimitCount = Math.max(
      (user?.currentSubscription?.meta?.indexCharacterSupport
        ? user?.currentSubscription?.meta?.indexCharacterSupport
        : 0) -
        (user?.currentSubscription?.indexCharacterUsedTotal
          ? user?.currentSubscription?.indexCharacterUsedTotal
          : 0),
      0
    );
    if (
      characterCount >
      indexingCharacterLimitCount + dataSource.totalCharacterCount
    ) {
      setDisablePdfIndexLimitState(true);
    } else {
      setDisablePdfIndexLimitState(false);
    }
    // eslint-disable-next-line
  }, [setCharacterCount, characterCount]);

  return (
    <ParentDiv>
      <Document
        file={fileObject && fileObject}
        onLoadSuccess={onLoadSuccess}
      ></Document>
      <EmptyDiv onClick={hidePopup}></EmptyDiv>
      <Container>
        <Formik
          initialValues={{
            bookId: selectedBook?._id,
            chatbotId: dataSource._id,
          }}
          // validate={}
          enableReinitialize
          onSubmit={(values: any) => {
            let dataToSave = {
              bookId: values.bookId,
              chatbotId: values.chatbotId,
            };
            let updateChatbotInput: any;
            if (fileObject) {
              const data = new FormData();
              data.append("files.files", fileObject);
              data.append("data", JSON.stringify(dataToSave));
              updateChatbotInput = data;
            } else {
              updateChatbotInput = dataToSave;
            }
            setShowLoader(true);
            myAxios
              .post(
                process.env.REACT_APP_SERVER_URL + "/books/update-pdf-chatbot",
                updateChatbotInput
              )
              .then((response) => {
                setShowLoader(false);
                updateUserSubscriptionIndexCount(
                  (user?.currentSubscription?.indexCharacterUsedTotal
                    ? user?.currentSubscription?.indexCharacterUsedTotal
                    : 0) + totalPdfCharacterCount
                );
                NotificationManager.success(
                  response.data.message
                    ? response.data.message
                    : "Source updated successfully."
                );
              })
              .catch((error) => {
                // handle error
                setShowLoader(false);
                NotificationManager.error(parseErrorResponse(error));
              });
            hidePopup();
            toBeEdit = null;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <DltHeader>
                  <SpanDltPopup>Edit PDF Chatbot</SpanDltPopup>
                </DltHeader>
                <MainFormRow>
                  <FormLabelNew
                    style={{ textAlign: "center", fontSize: "16px" }}
                  >
                    Please upload an updated version if necessary.
                  </FormLabelNew>
                  <p
                    style={{
                      color: "grey",
                      textAlign: "center",
                    }}
                  >
                   
                  </p>

                  <FieldSection>
                    <ColPDFMainSubSection>
                      <FormLabelDocInput htmlFor="docFile">
                        Select File
                      </FormLabelDocInput>
                      <FormInput
                        type="file"
                        id="docFile"
                        name="file"
                        accept=".pdf"
                        hidden
                        placeholder="select custom document/file"
                        onChange={(e: any) => {
                          setFileObject(e.target.files[0]);
                        }}
                      />
                      {/* , .txt, .mp3, .html, .ogg, .wav, .flac, .mp4, .epub, .md, .mdx */}

                      <ColForPDFSelection>
                        <PDFNameFormLabel>
                          {fileObject ? fileObject.name : "No file selected"}
                        </PDFNameFormLabel>

                        {fileObject && (
                          <FormLabel
                            style={{
                              padding: "0",
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            <SelectedCount
                              style={{
                                color: disablePdfIndexLimit ? "red" : "#33303c",
                              }}
                            >
                              {characterCount} {" / "}
                              {Math.max(
                                (user?.currentSubscription?.meta
                                  ?.indexCharacterSupport
                                  ? user?.currentSubscription?.meta
                                      ?.indexCharacterSupport +
                                    dataSource.totalCharacterCount
                                  : 0) -
                                  (user?.currentSubscription
                                    ?.indexCharacterUsedTotal
                                    ? user?.currentSubscription
                                        ?.indexCharacterUsedTotal
                                    : 0),
                                0
                              )}{" "}
                              {disablePdfIndexLimit
                                ? "Characters limit exceeds"
                                : "Characters"}
                            </SelectedCount>
                          </FormLabel>
                        )}
                      </ColForPDFSelection>
                    </ColPDFMainSubSection>
                  </FieldSection>
                </MainFormRow>
                <ButtonRow>
                  <CancelButton onClick={hidePopup}>Cancel</CancelButton>
                  <UpdateButton
                    type="submit"
                    disabled={characterCount === 0}
                    displayProp={characterCount === 0 ? false : true}
                  >
                    Update
                  </UpdateButton>
                </ButtonRow>
              </form>
            </>
          )}
        </Formik>
      </Container>
    </ParentDiv>
  );
};

export default EditPdfChatbot;
