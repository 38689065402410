// import { Row } from "react-bootstrap";
import styled from "styled-components";
import ShopifyIcon from "../../../images/Shopify1.svg";

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 1rem;
`;

const UpdateButton = styled.button`
  background: #1585b5;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: auto;
  padding: 0.3rem 1.2rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const GetShopifyProducts = ({ getShopifyProductList }: any) => {
  return (
    <div
      style={{
        flexDirection: "column",
        background: "#fff",
        margin: "0.5rem",
        padding: "1rem",
      }}
    >
      <img width={"100"} src={ShopifyIcon} alt="edit" />
      <SubHeading>
        You can fetch product from Shopify and adding products in Dropchat
        chatbot.
      </SubHeading>
      <UpdateButton type="button" onClick={() => getShopifyProductList()}>
        Get Products
      </UpdateButton>
    </div>
  );
};

export default GetShopifyProducts;
