export class ChatGuest {
  private input: any;
  private streamCallBack: any;
  private finalResponse: any;
  private jwt: string;

  constructor(input: any, streamCallBack: any, finalResponse: any, jwt: any) {
    this.input = input;
    this.streamCallBack = streamCallBack;
    this.finalResponse = finalResponse;
    this.jwt = jwt;
  }

  public async startChatGuest() {
    let responseDataFull;
    let isEnd = false;
    const response: any = await fetch(
      process.env.REACT_APP_SERVER_URL + "/chats/chat",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(this.jwt &&
            this.jwt.length > 0 && { Authorization: `Bearer ${this.jwt}` }),
        },
        body: JSON.stringify(this.input),
      }
    );
    const reader = response.body.getReader();
    const textDecoder = new TextDecoder(); //"utf-8"
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      const textValue = textDecoder
        .decode(value)
        //.replace(/\\n/g, "")
        .replace(/\n\n\n\n/g, "")
        .replace(/"""(.*?)"""/g, (_, content) => `\`${content}\``);

      // Process the text data
      if (textValue.includes("ENDSTREAM")) {
        this.streamCallBack(textValue.replace("ENDSTREAM", ""));
        isEnd = true;
      } else {
        if (isEnd) {
          this.finalResponse(textValue.trim());
        } else {
          this.streamCallBack(textValue);
        }
      }

      //setvalue((prev) => prev + value)
    }
  }
}
