import { Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { MOBILE } from "../../constants/mobile";
import {
  BubbleChatbotEvents,
  EventCategory,
  Source,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { trackEvent } from "../../event-utils/track-event";
import BubbleChatLiveIcon from "../../images/drop-chat-icon.svg";
import ReloadIcon from "../../images/reload-icon.svg";
// import { useSearchParams } from "react-router-dom";
// import { useEffect } from "react";

const { innerWidth: width } = window;
const isMobileWidth = width < 1000;

const ChatPageBold = styled.b`
  font-size: 14px;
  // cursor: pointer;
  color: white;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const BubbleChatForm = styled.div`
  /*
  width: 100%;
  padding-top: 12px;
  margin: 0; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: end;
  margin: 0px auto;
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: 16vh;
  }
`;

const FieldSection = styled.div`
  position: relative;
  flex-direction: column;
`;

const ChatInputBox = styled(Col)`
  height: calc(100vh - 90px);
`;

const RightColumn = styled(Col)`
  flex-direction: column;
`;

const RightContentRow = styled.div`
  width: 100%;
  padding: 10px;
  flex-direction: column;
`;

const ChatBotContent = styled(Col)`
  flex-direction: column;
  padding: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: 67vh;
  }
`;

const QuestionAnswerSection = styled.div`
  flex-direction: column;
  width: 100%;
`;

const FormHeading = styled.p`
  font-weight: 600;
  color: #33303c;
  font-size: ${isMobileWidth ? "0.75rem" : "1rem"};
`;

const FormConditionalHeading = styled.p`
  font-weight: 600;
  color: #1585b5;
  font-size: ${isMobileWidth ? "0.75rem" : "1.5rem"};
`;

const InputSection = styled.div`
  flex-direction: column;
  margin-bottom: ${isMobileWidth ? "0.75rem" : "1rem"};
`;

const FormLabelText = styled.span`
  color: #33303ccc;
  font-size: ${isMobileWidth ? "0.75rem" : "1rem"};
  font-weight: 500;
`;

const FormBubbleInput = styled.input`
  height: ${isMobileWidth ? "35px" : "45px"};
  border: 1px solid #33303c33;
  padding: 10px;
  font-size: ${isMobileWidth ? "0.75rem" : "1rem"};
`;

const SkipText = styled.p`
  color: #1585b5;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 5px;
  cursor: pointer;
`;

const PoweredText = styled.p`
  color: #33303c4d;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0;
`;

const SaveButton = styled.button`
  background: #1585b5;
  padding: 0.4rem 3rem;
  color: #fff;
  border: 0;
  width: 100%;
`;

// const FieldColumn = styled.div`
//   /* position: relative; */
// `;

const ChatPageHeader = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  justify-content: flex-start;
  min-height: ${isMobile ? "8%" : "50px"};
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
`;

const BubbleChatLoginHandler = ({
  leadSettings,
  leadThemeSettings,
  showLeadForm,
  setShowLeadForm,
  setNameValue,
  setEmailValue,
  setPhoneValue,
  validatedLoginSettings,
  createUpdateChatSession,
  bubbleChatApis,
  bookId,
  emailValue,
  phoneValue,
  nameValue,
  leadChatSession,
  chatbotDetails,
}: any) => {
  // const [searchParams] = useSearchParams();
  // let email: any = "";
  // let name: any = "";
  // let phone: any = "";
  // useEffect(() => {
  //   email = searchParams.get("email");
  //   name = searchParams.get("name");
  //   phone = searchParams.get("phone");
  //   setNameValue(name);
  //   setEmailValue(email);
  //   setPhoneValue(phone);
  // }, [searchParams]);
  // console.log("searchParams are, ", searchParams.get("email"));
  return (
    <>
      <RightColumn xs={12} sm={12} md={12} style={{ padding: "0" }}>
        <ChatPageHeader
          className="App-header"
          style={{
            background: leadThemeSettings.titleBackgroundColor
              ? leadThemeSettings.titleBackgroundColor
              : "#1585b5",
            paddingRight: isMobile ? "35px" : "0.5rem",
          }}
        >
          <Col sm={1} style={{ flex: 1 }}>
            <img src={BubbleChatLiveIcon} alt="online..." width={30} /> &nbsp;
          </Col>
          <Col sm={10} style={{ alignItems: "center", flex: 10 }}>
            <ChatPageBold>
              {chatbotDetails &&
              chatbotDetails.chatbotHeaderTitle &&
              chatbotDetails.chatbotHeaderTitle.length > 0
                ? chatbotDetails.chatbotHeaderTitle
                : "Welcome to Dropchat"}
            </ChatPageBold>
          </Col>
          <Col md={1} sm={1} style={{ flex: 1, marginRight: "1.5rem" }}>
            <img
              src={ReloadIcon}
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                bubbleChatApis();
              }}
              alt={"show"}
              width={20}
            />
          </Col>
        </ChatPageHeader>
        <ChatInputBox>
          <RightContentRow>
            <ChatBotContent sm={12}>
              <ChatBotContent>
                <QuestionAnswerSection>
                  {showLeadForm === "conditional" && (
                    <FormConditionalHeading>
                      {leadSettings.conditionalMessage}
                    </FormConditionalHeading>
                  )}
                  <FormHeading>{leadSettings.customText}</FormHeading>
                  {leadSettings.isUserName && (
                    <InputSection>
                      <FormLabelText>{leadSettings.nameText}</FormLabelText>
                      <FormBubbleInput
                        onChange={(e: any) => setNameValue(e.target.value)}
                      />
                    </InputSection>
                  )}
                  {leadSettings.isEmail && (
                    <InputSection>
                      <FormLabelText>{leadSettings.emailText}</FormLabelText>
                      <FormBubbleInput
                        onChange={(e: any) => setEmailValue(e.target.value)}
                      />
                    </InputSection>
                  )}
                  {leadSettings.isMobileNumber && (
                    <InputSection>
                      <FormLabelText>
                        {leadSettings.mobileNumberText}
                      </FormLabelText>
                      <FormBubbleInput
                        maxLength={13}
                        onChange={(e: any) => setPhoneValue(e.target.value)}
                      />
                    </InputSection>
                  )}
                </QuestionAnswerSection>
              </ChatBotContent>
            </ChatBotContent>
            {/* <FieldColumn> */}
            <BubbleChatForm>
              {leadSettings.loginForm === "optional" && (
                <SkipText
                  onClick={() => {
                    trackEvent({
                      email: emailValue || "PUBLIC",
                      eventCategory: EventCategory.CHATBOT,
                      event:
                        BubbleChatbotEvents.USER_SKIP_LOGIN_FORM_ON_BUBBLE_CHAT,
                      subCategory: SubCategory.EVENT,
                      source: Source.BUBBLE,
                      bookId: bookId,
                      leadChatSession: leadChatSession && leadChatSession.id,
                    });
                    setShowLeadForm("none");
                  }}
                >
                  Skip Form
                </SkipText>
              )}
              <FieldSection>
                <SaveButton
                  type="button"
                  onClick={() => {
                    trackEvent({
                      email: emailValue || "PUBLIC",
                      eventCategory: EventCategory.CHATBOT,
                      event:
                        BubbleChatbotEvents.USER_FILL_LOGIN_FORM_ON_BUBBLE_CHAT,
                      subCategory: SubCategory.EVENT,
                      source: Source.BUBBLE,
                      rawData: {
                        name: nameValue,
                        email: emailValue,
                        phone: phoneValue,
                      },
                      bookId: bookId,
                    });
                    if (validatedLoginSettings()) createUpdateChatSession(true);
                  }}
                >
                  START CHAT
                </SaveButton>
              </FieldSection>
              <a
                href={
                  (chatbotDetails && chatbotDetails.footerBrandingLink) ||
                  "https://dropchat.co/"
                }
                rel="noreferrer"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <PoweredText>
                  {chatbotDetails && chatbotDetails.footerBrandingText
                    ? chatbotDetails.footerBrandingText
                    : "Powered By Dropchat"}
                </PoweredText>
              </a>
            </BubbleChatForm>

            {/* </FieldColumn> */}
          </RightContentRow>
        </ChatInputBox>
      </RightColumn>
    </>
  );
};

export default BubbleChatLoginHandler;
