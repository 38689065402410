import { createContext, ReactElement, useState } from "react";
import { CodeBoxValues, NotesProps } from "../types";

export const CodeBoxContext = createContext({} as CodeBoxValues);

const CodeBoxContextProvider = (
  props: NotesProps
): ReactElement<CodeBoxValues> => {
  const [showConfirmationBox, setShowConfirmationBox] =
    useState<boolean>(false);
  const [showIframeCodeBox, setShowIframeCodeBox] = useState<boolean>(false);
  const [bookId, setBookId] = useState<string>("");
  return (
    <CodeBoxContext.Provider
      value={{
        showConfirmationBox,
        setShowConfirmationBox,
        bookId,
        setBookId,
        showIframeCodeBox,
        setShowIframeCodeBox,
      }}
    >
      {props.children}
    </CodeBoxContext.Provider>
  );
};

export default CodeBoxContextProvider;
