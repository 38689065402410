import { useFormikContext } from "formik";
import { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";

const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 1rem;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;
const TextAREANew = styled.textarea`
  height: 110px;
  width: 100%;
  padding: 0.5rem;
  background: #eaeaea;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  font-size: 14px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    // margin-top: 10px;
  }
`;

const ChatBotWaiverSetting = () => {
  const formik = useFormikContext();
  const { values } = useFormikContext<any>();

  const useAutosizeTextArea = (
    textAreaRef: HTMLTextAreaElement | null,
    value: string,
    extraHeight: number = 0
  ) => {
    useEffect(() => {
      if (textAreaRef) {
        textAreaRef.style.height = "0px";
        const scrollHeight = textAreaRef.scrollHeight + extraHeight;
        textAreaRef.style.height = scrollHeight + "px";
      }
      // eslint-disable-next-line
    }, [textAreaRef, value, extraHeight, values]);
  };
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, values.waiverSetting.waiverText, 50);

  return (
    <>
      <Row style={{ alignItems: "center" }}>
        <Col sm={8} style={{ flexDirection: "column" }}>
          <MenuHeading>Chatbot Waiver</MenuHeading>
        </Col>
        <Col sm={4} style={{ justifyContent: "flex-end" }}>
          <CheckBoxWrapper>
            <>
              <CheckBox
                id="checkbox"
                checked={values.waiverSetting.isWaiver}
                type="checkbox"
                name="waiverSetting.isWaiver"
                onChange={(e: any) => {
                  formik.setFieldValue(
                    "waiverSetting.isWaiver",
                    e.target.checked
                  );
                }}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </>
          </CheckBoxWrapper>
        </Col>
      </Row>
      <Row>
        <SubHeading>You can write below a custom text for waiver.</SubHeading>
      </Row>
      <Row>
        <Col sm={12} style={{ flexDirection: "column" }}>
          <TextAREANew
            // style={{ height: "100px" }}
            ref={textAreaRef}
            name="waiverSetting.waiverText"
            disabled={!values.waiverSetting.isWaiver}
            value={values.waiverSetting.waiverText}
            onChange={(e: any) => {
              formik.setFieldValue("waiverSetting.waiverText", e.target.value);
            }}
          ></TextAREANew>
        </Col>
      </Row>
    </>
  );
};

export default ChatBotWaiverSetting;
