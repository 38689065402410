import * as BubbleChatStyles from "./styled";
import ChatBotIcon from "../../images/chat-bot-icon.svg";

const BubbleCustomGreetingText = ({
  leadThemeSettings,
  customGreetingText,
  isMessageInterface,
}: any) => {
  return (
    <>
      {customGreetingText && customGreetingText.isGreeting && (
        <div
          style={{
            alignSelf: isMessageInterface ? "flex-end" : "flex-start",
            maxWidth: isMessageInterface ? "80%" : "100%",
            flexDirection: isMessageInterface ? "row-reverse" : "row",
          }}
        >
          <img
            width="20"
            src={ChatBotIcon}
            alt="user_image"
            style={{
              display: "flex",
              alignSelf: "baseline",
              margin: "7px 2px",
              width: leadThemeSettings.source === "bubble" ? "20px" : "25px",
            }}
          />
          &nbsp;
          <BubbleChatStyles.ResponseSection
            style={{
              background: leadThemeSettings.answerBackgroundColor
                ? leadThemeSettings.answerBackgroundColor
                : "#fff",
              alignSelf: isMessageInterface ? "flex-end" : "flex-start",
            }}
          >
            <div
              style={{
                flexDirection: isMessageInterface ? "row-reverse" : "row",
                alignSelf: isMessageInterface ? "end" : "",
              }}
            >
              <BubbleChatStyles.MarkDownContent
                color={
                  leadThemeSettings.answerTextColor
                    ? leadThemeSettings.answerTextColor
                    : "rgba(51, 48, 60, 1)"
                }
              >
                <div style={{ flexDirection: "column" }}>
                  {customGreetingText.message}
                </div>
              </BubbleChatStyles.MarkDownContent>
            </div>
          </BubbleChatStyles.ResponseSection>
        </div>
      )}
    </>
  );
};

export default BubbleCustomGreetingText;
