import { Field, FieldArray, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
import { UserContext } from "../../context/UserContext";
import { AdminContext } from "../../context/admin-context";
import myAxios from "../../myaxios";
import { AdminSetting } from "../../types";
import { ADMIN_APP_SETTINGS } from "../../utils/class-names";
import { parseErrorResponse } from "../../utils/utils";

const AddAppFeatureContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
`;

const HeadingText = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #2f4c95;
  @media (max-width: ${MOBILE.maxWidth}px) {
  }
  @media (min-width: ${TAB.minWidth}px) {
    // font-size: 16px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
  }
`;

const Label = styled(FormLabel)`
  width: 75%;
  font-weight: bold;
`;

const ValueField = styled(Field)`
  width: 380px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  height: 200px;
  margin: 0px 0px 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const TextValueField = styled(Field)`
  width: 380px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  padding-left: 8px;
  margin: 0px 0px 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const AppSettingsForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const UpadateBtn = styled.button`
  border: none;
  width: 133px;
  height: 40px;
  background: #2f4c95;
  border-radius: 5px;
  color: white;

  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 8px 0px 0px 0px;
  &:hover {
    background: #0e2151;
    border: none;
  }
`;
const DivForSubmitBtn = styled(Col)`
  justify-content: end;
`;

const OpenSettingContainer = styled.div`
  flex-direction: column;
`;

const ErrorTag = styled(Col)`
  color: red;
  margin: 0px 6px;
  font-size: 11px;
  padding: 3px 0px;
  display: flex;
  justify-content: center;

  @media (max-width: 450px) and (min-width: 320px) {
    margin: 0px 6px;
    font-size: 10px;
  }
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;

const AppSettings = () => {
  const { user } = useContext(UserContext);
  const { appSettings, setAppSettings } = useContext(AdminContext);
  const [appSettingErrors, setAppSettingErrors] = useState<AdminSetting[]>([]);

  const saveAppSettings = (values: any) => {
    // setLoading(true);
    myAxios
      .post(`admin-settings/save-update-admin-settings`, {
        type: "APP-SETTINGS",
        data: [...values.appSettings],
      })
      .then((response) => {
        if (response.data.success) {
          NotificationManager.success(
            "Settings save successfully...",
            "",
            1500
          );
          if (response.data && response.data.data) {
            setAppSettings([...values.appSettings]);
          }
        } else {
          NotificationManager.error(response.data.message, "", 2000);
        }
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  useEffect(() => {
    if (user) {
      // setLoading(true);
      setTimeout(() => {
        myAxios
          .get(
            `${process.env.REACT_APP_SERVER_URL}/admin-settings/APP-SETTINGS`
          )
          .then((response) => {
            if (response.data && response.data.data) {
              setAppSettings(response.data.data);
            }
          })
          .catch((error) => {});
      }, 1000);
    }
  }, [user, setAppSettings]);

  return (
    <>
      <Row>
        <Col style={{ flexDirection: "column" }}>
          <AddAppFeatureContainer>
            <HeadingText>App Settings</HeadingText>
          </AddAppFeatureContainer>
          <Formik
            enableReinitialize={true}
            validateOnChange={true}
            initialValues={{
              appSettings: appSettings && appSettings,
            }}
            validate={(values) => {
              const errors: {
                appSettings?: AdminSetting[];
              } = {};

              let appSettings: AdminSetting[] = [];
              let isError = false;
              values.appSettings.forEach((appSetting: AdminSetting) => {
                const val = appSetting.value + "";

                if (
                  appSetting.key === "featureChatbotHeading" ||
                  appSetting.key === "featureChatbotSubHeading"
                ) {
                  appSettings.push({ value: "" });
                } else if (val && val.length > 0) {
                  appSettings.push({ value: "" });
                } else {
                  isError = true;

                  appSettings.push({
                    value: `"${appSetting.key}" value is required`,
                  });
                }
              });

              setAppSettingErrors(appSettings);
              if (isError) {
                errors.appSettings = appSettings;
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              saveAppSettings(values);
            }}
          >
            {({ setFieldValue }) => (
              <AppSettingsForm className={ADMIN_APP_SETTINGS}>
                <FieldArray name="appSettings">
                  {(fieldArrayProps) => {
                    const { form } = fieldArrayProps;
                    const { values } = form;
                    const { appSettings } = values;

                    return (
                      <OpenSettingContainer>
                        {appSettings &&
                          appSettings.map(
                            (appSetting: AdminSetting, index: number) => (
                              <>
                                <div key={index}>
                                  {appSetting.inputType === "textarea" ? (
                                    <Col>
                                      <Label>{appSetting.placeHolder}</Label>
                                      <ValueField
                                        type="text"
                                        component="textarea"
                                        name={`appSettings[${index}].value`}
                                      />
                                    </Col>
                                  ) : appSetting.inputType === "number" ? (
                                    <>
                                      <Col
                                        style={{
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Label>{appSetting.placeHolder}</Label>
                                        <TextValueField
                                          type="number"
                                          name={`appSettings[${index}].value`}
                                        />
                                      </Col>
                                      <Col sm={3}></Col>
                                    </>
                                  ) : appSetting.inputType === "boolean" ? (
                                    <>
                                      <Col
                                        style={{
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Label>{appSetting.placeHolder}</Label>
                                        <CheckBoxWrapper>
                                          <>
                                            <CheckBox
                                              id={`checkbox-${index}`}
                                              checked={
                                                appSettings[index].value ==
                                                "true"
                                              }
                                              type="checkbox"
                                              onChange={(e) => {
                                                const isChecked =
                                                  e.target.checked;
                                                setFieldValue(
                                                  `appSettings[${index}].value`,
                                                  isChecked ? "true" : "false"
                                                );
                                                // const isChecked =
                                                //   e.target.checked;
                                                // const updatedAppSettings = [
                                                //   ...values.appSettings,
                                                // ];
                                                // updatedAppSettings[
                                                //   index
                                                // ].value = isChecked
                                                //   ? "true"
                                                //   : "false";
                                                // setFieldValue(
                                                //   `appSettings`,
                                                //   updatedAppSettings
                                                // );
                                              }}
                                            />
                                            <CheckBoxLabel htmlFor="checkbox" />
                                          </>
                                        </CheckBoxWrapper>
                                      </Col>
                                      <Col sm={3}></Col>
                                    </>
                                  ) : (
                                    <>
                                      <Col
                                        style={{
                                          alignItems: "center",

                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Label>{appSetting.placeHolder}</Label>
                                        <TextValueField
                                          type="text"
                                          name={`appSettings[${index}].value`}
                                        />
                                      </Col>
                                      <Col sm={3}></Col>
                                    </>
                                  )}
                                </div>
                                {appSettingErrors &&
                                  appSettingErrors.length > 0 &&
                                  appSettingErrors[index] &&
                                  appSettingErrors[index].value && (
                                    <Row>
                                      <ErrorTag>
                                        {appSettingErrors[index].value}
                                      </ErrorTag>
                                    </Row>
                                  )}
                              </>
                            )
                          )}
                      </OpenSettingContainer>
                    );
                  }}
                </FieldArray>
                <Row>
                  {/* <Col sm={8}></Col> */}
                  <DivForSubmitBtn>
                    <UpadateBtn type="submit">Update</UpadateBtn>
                  </DivForSubmitBtn>
                  <Col sm={3}></Col>
                </Row>
              </AppSettingsForm>
            )}
          </Formik>
        </Col>
        {/* <Col sm={1}></Col> */}
      </Row>
    </>
  );
};

export default AppSettings;
