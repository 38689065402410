import { createContext, ReactElement, useState } from "react";
import { Team, Member, TeamProps, TeamValues } from "../types";

export const TeamContext = createContext({} as TeamValues);

const TeamContextProvider = (props: TeamProps): ReactElement<TeamValues> => {
  const [userTeams, setUserTeams] = useState<Team[]>();
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [teamMembers, setTeamMembers] = useState<Member[]>();
  const addUserTeams = (teams: Team[]) => {
    setUserTeams(teams);
  };

  const updateUserTeam = (team: Team, teamId: string) => {
    setUserTeams(
      userTeams?.map((t: Team) => {
        if (t.id === teamId) {
          setSelectedTeam(team);
          return { ...t, team };
        } else return t;
      })
    );
  };

  return (
    <TeamContext.Provider
      value={{
        userTeams,
        setUserTeams,
        selectedTeam,
        setSelectedTeam,
        addUserTeams,
        teamMembers,
        setTeamMembers,
        updateUserTeam,
      }}
    >
      {props.children}
    </TeamContext.Provider>
  );
};

export default TeamContextProvider;
