import { useContext, useEffect, useState } from "react";
// import "./App.css";
import axios from "axios";
import { Button, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MOBILE } from "../constants/mobile";
import { TAB } from "../constants/tab";
import { UserContext } from "../context/UserContext";
import { PlanContext } from "../context/plan-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../event-utils/dropchat-event-track";
import { trackEvent } from "../event-utils/track-event";
import MagicBlue from "../images/magicblue.svg";
import Tick from "../images/pricing-tick.svg";
const MainSection = styled(Container)`
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 2.5rem;
  padding-top: 2rem;
`;

const CenterContentRow = styled(Row)`
  margin-bottom: 0.5rem;
  margin-top: 1vh;
`;

const CenterContentRowDetails = styled(Row)`
  background: #f9fdff;
  border: 1px solid #d8e4eb;
  padding: 20px;
  height: 40vh;
  padding-left: 5vh;

  @media (max-width: ${MOBILE.maxWidth}px) {
    height: auto;
    padding: 5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: auto;
    padding: 5px;
  }
`;

// const CenterContentColumn = styled(Col)`
//   justify-content: center;
//   margin-top: 5rem;
//   @media (max-width: ${MOBILE.maxWidth}px) {
//     margin-top: 1rem;
//   }
// `;

// const CopyRightSpan = styled.span`
//   font-size: 15px;
//   font-weight: 700;
//   @media (max-width: ${MOBILE.maxWidth}px) {
//     font-size: 13px;
//     font-weight: 600;
//   }
// `;

const RedMessageContent = styled.p`
  color: #ea5455;
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
  margin: 0;
  padding: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    font-weight: 600;
  }
`;

const BoldMessageContent = styled.p`
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
  margin-top: 2vh;
  padding: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    font-weight: 600;
  }
`;

const NormalMessageContent = styled.p`
  // display: flex;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  padding: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    font-weight: 400;
  }
`;

const DivForBtn = styled.div`
  display: flex;
  align-items: end;
  /* justify-content: center; */
  /* padding-top: 100px; */
  margin-left: -12px;
`;

const CallToAction = styled(Button)`
  padding: 1rem 3rem;
  color: white;
  background: #f2ae01;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  width: 224px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background: #f2ae01;
  }
`;

const DivForBtnParas = styled.div`
  display: flex;
  justify-content: center;
`;

const MagicImg = styled.img`
  display: ${(props) => (props.className === "white" ? "block" : "none")};
`;

const BtnPara = styled.span`
  color: #ffffff;
  font-weight: 600;
  line-height: 24px;
  font-size: 15px;
  padding: 0px 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    font-weight: 600;
  }
`;

const SubscriptionLimitPopup = ({
  loadingState,
  restrictionMessage,
  messageregardingrest,
  highliter,
}: any) => {
  const { user } = useContext(UserContext);
  const { setPlanHighliter } = useContext(PlanContext);
  const navigate = useNavigate();
  const [subscriptionLimit, setSubscriptionLimit] = useState<any>({
    subscriptionLimitHeader: "Do more by changing your plan",
    subscriptionLimitMessage: "Get more features",
  });
  // console.log("subscriptionLimit", subscriptionLimit);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/public/getExpireMessageMesage")
      .then((response) => {
        if (response && response.data && response.data.success) {
          // console.log("response", response);
          setSubscriptionLimit(response.data.data);
        }
      });
  }, []);

  return (
    <MainSection fluid>
      <CenterContentRow>
        <RedMessageContent>{restrictionMessage}</RedMessageContent>
        <BoldMessageContent style={{ marginTop: "0vh" }}>
          {messageregardingrest}
        </BoldMessageContent>
      </CenterContentRow>
      <CenterContentRowDetails>
        <BoldMessageContent>
          {subscriptionLimit && subscriptionLimit.subscriptionLimitHeader}
        </BoldMessageContent>
        <NormalMessageContent>
          <img
            alt="img"
            src={Tick}
            width="15"
            style={{ marginRight: "10px" }}
          />
          {subscriptionLimit && subscriptionLimit.subscriptionLimitMessage}
        </NormalMessageContent>
        <DivForBtn>
          {highliter === "isFlashcardHighliter" ? (
            <CallToAction
              // leadingIcon={MonochromeIcons.PaperPlane}
              color="primary"
              size="sm"
              onClick={() => {
                trackEvent({
                  email: getUsersEmail(user),
                  eventCategory: EventCategory.CHATBOT,
                  event:
                    ChatBotEvents.USER_USED_CHANGE_PLAN_OPTION_FROM_CREATE_CHATBOT,
                  subCategory: SubCategory.EVENT,
                });
                setPlanHighliter({
                  isBookHighliter: false,
                  bookLimit: user?.currentSubscription?.meta?.noOfBooks
                    ? user.currentSubscription?.meta?.noOfBooks
                    : 0,
                  isDeleteBookHighliter: false,
                  isFlashcardHighliter: true,
                  isCharacterLimit: false,
                  pdfLimit: 0,
                });
                navigate("/subscription");
              }}
            >
              <DivForBtnParas>
                <MagicImg className="blue" src={MagicBlue} alt="magic" />
                <BtnPara>Upgrade Plan</BtnPara>
              </DivForBtnParas>
            </CallToAction>
          ) : highliter === "isIntegrationApps" ? (
            <CallToAction
              // leadingIcon={MonochromeIcons.PaperPlane}
              color="primary"
              size="sm"
              onClick={() => {
                trackEvent({
                  email: getUsersEmail(user),
                  eventCategory: EventCategory.CHATBOT,
                  event:
                    ChatBotEvents.USER_USED_CHANGE_PLAN_OPTION_FROM_CREATE_CHATBOT,
                  subCategory: SubCategory.EVENT,
                });
                setPlanHighliter({
                  isBookHighliter: false,
                  bookLimit: user?.currentSubscription?.meta?.noOfBooks
                    ? user.currentSubscription?.meta?.noOfBooks
                    : 0,
                  isDeleteBookHighliter: false,
                  isFlashcardHighliter: false,
                  isCharacterLimit: false,
                  pdfLimit: 0,
                  isIntegrationApps: true,
                });
                navigate("/subscription");
              }}
            >
              <DivForBtnParas>
                <MagicImg className="blue" src={MagicBlue} alt="magic" />
                <BtnPara>Upgrade Plan</BtnPara>
              </DivForBtnParas>
            </CallToAction>
          ) : (
            <CallToAction
              // leadingIcon={MonochromeIcons.PaperPlane}
              color="primary"
              size="sm"
              onClick={() => {
                trackEvent({
                  email: getUsersEmail(user),
                  eventCategory: EventCategory.CHATBOT,
                  event:
                    ChatBotEvents.USER_USED_CHANGE_PLAN_OPTION_FROM_CREATE_CHATBOT,
                  subCategory: SubCategory.EVENT,
                });
                setPlanHighliter({
                  isBookHighliter: true,
                  bookLimit: user?.currentSubscription?.meta?.noOfBooks
                    ? user.currentSubscription?.meta?.noOfBooks
                    : 0,
                  isDeleteBookHighliter: false,
                  isFlashcardHighliter: false,
                  isCharacterLimit: false,
                  pdfLimit: 0,
                });
                navigate("/subscription");
              }}
            >
              <DivForBtnParas>
                <MagicImg className="blue" src={MagicBlue} alt="magic" />
                <BtnPara>Upgrade Plan</BtnPara>
              </DivForBtnParas>
            </CallToAction>
          )}
        </DivForBtn>
      </CenterContentRowDetails>

      {/* <Row>
        <CenterContentColumn>
          <CopyRightSpan>© Copyright 2023. All rights reserved</CopyRightSpan>
        </CenterContentColumn>
      </Row> */}
    </MainSection>
  );
};

export default SubscriptionLimitPopup;
