import { Field, Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Button, Col, Container, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { LoaderContext } from "../../context/loader-context";
import myAxios from "../../myaxios";
import { parseErrorResponse } from "../../utils/utils";
import { ADMIN_OPEN_AI_SETTINGS } from "../../utils/class-names";

const BookFetchForm = styled(Form)`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
`;

const FormLabelText = styled(FormLabel)`
  color: #33303c;
  font-weight: 600;
  font-size: 15px;
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;

const FieldColumnSection = styled.div`
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  & div {
    flex-direction: column;
  }
  & div:nth-child(1) {
    width: 32%;
  }
  & div:nth-child(2) {
    width: 32%;
  }
  & div:nth-child(3) {
    width: 32%;
  }
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
  color: #33303cde;
  border: 1px solid #eaeaea;
  background: #f8f7fa;
`;

const FormError = styled.span`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const FieldToggleSection = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  & div {
    flex-direction: row;
  }
  & div:nth-child(1) {
  }
  & div:nth-child(2) {
  }
  & div:nth-child(3) {
  }
`;

const FormToggleLabelText = styled(FormLabel)`
  color: #33303c;
  font-weight: 600;
  font-size: 15px;
  padding-left: 0.5rem;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;

const FieldDateColumnSection = styled.div`
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  & div {
    flex-direction: column;
  }
`;

const SaveButton = styled(Button)`
  width: 100%;
  background: #1585b5;
  padding: 0.5rem;
  color: #fff;
`;

const ResetButton = styled.p`
  color: #33303c;
  border-bottom: 1px solid #33303c;
  font-size: 15px;
  margin: 0;
  cursor: pointer;
`;

const MainSection = styled(Container)`
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 2.5rem;
  padding-top: 2rem;
`;

const MainDiv = styled.div``;

const DivForDltPopUp = styled.div`
  position: fixed;
  // width: 367px;
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  z-index: 111;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 40%;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;

const FormLabelDocInput = styled(FormLabel)`
  background: #ccc;
  width: 50%;
  padding: 5px 10px;
`;

const UpdateChatbotBox = ({ setShow, book, getFeatureChatbots }: any) => {
  const { setShowLoader } = useContext(LoaderContext);
  const [coverImageObject, setCoverImageObject] = useState<any>();
  const validateForm = (formData: any) => {
    const errors: any = {};

    if (!formData.title) {
      errors.title = "title required!";
    }

    if (!coverImageObject) {
      errors.coverImage = "please select file!";
    }

    return errors;
  };

  const updateBook = (values: any, resetForm: any) => {
    setShowLoader(true);

    let dataToSave = {
      bookId: book._id || book.id,
      title: values.title,
    };

    let updateChatbotEvent;

    if (coverImageObject) {
      const data = new FormData();

      data.append("files.coverImage", coverImageObject);
      data.append("data", JSON.stringify(dataToSave));

      updateChatbotEvent = data;
    } else {
      updateChatbotEvent = dataToSave;
    }

    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL + "/books/update-chatbot",
        updateChatbotEvent
      )
      .then((response) => {
        // console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          setShowLoader(false);

          getFeatureChatbots();

          NotificationManager.success(
            response.data.message ? response.data.message : "Chabot updated."
          );

          setShow(false);
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  return (
    /*  <MainSection fluid> */
    <MainDiv>
      <DivForDltPopUp>
        <Row style={{ margin: "3rem" }}>
          <Col sm={12}>
            <Formik
              initialValues={{
                title: book && book.title ? book.title : "",
                coverImage: "",
              }}
              validate={validateForm}
              onSubmit={(values, { resetForm }) => {
                updateBook(values, resetForm);
              }}
            >
              {({ values, errors, touched, resetForm }: any) => (
                <BookFetchForm className={ADMIN_OPEN_AI_SETTINGS}>
                  <>
                    <FormLabelText>Title</FormLabelText>
                    <FieldSection>
                      <FormInput name="title" placeholder="Enter title" />
                      {errors.title && touched.title && (
                        <FormError className="formError">
                          {errors.title}
                        </FormError>
                      )}
                    </FieldSection>

                    <FieldSection>
                      <FormLabelText>Cover image</FormLabelText>
                      <FormLabelDocInput htmlFor="coverImage">
                        Select Cover Image
                      </FormLabelDocInput>

                      <FormInput
                        type="file"
                        id="coverImage"
                        name="coverImage"
                        accept=".jpg, .jpeg, .png"
                        hidden
                        placeholder="select cover image"
                        onChange={(e: any) => {
                          setCoverImageObject(e.target.files[0]);
                        }}
                      />
                      <FormLabel>
                        {coverImageObject
                          ? coverImageObject.name
                          : "No file selected"}
                      </FormLabel>
                      {errors.coverImage && touched.coverImage && (
                        <FormError className="formError">
                          {errors.coverImage}
                        </FormError>
                      )}
                    </FieldSection>

                    <Row>
                      <Col>
                        <SaveButton type="submit">Update </SaveButton>
                      </Col>
                      <Col
                        style={{
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <ResetButton onClick={() => setShow(false)}>
                          Cancel
                        </ResetButton>
                      </Col>
                    </Row>
                  </>
                </BookFetchForm>
              )}
            </Formik>
          </Col>
        </Row>
      </DivForDltPopUp>
    </MainDiv>
    /*   </MainSection> */
  );
};

export default UpdateChatbotBox;
