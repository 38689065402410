import { FcGoogle } from "react-icons/fc";
import styled from "styled-components";
import { magic } from "../magic";

const Button = styled.button`
  align-items: center;
  align-self: center;
  background-color: white;
  color: #3c4043;
  border-radius: 300px;
  cursor: pointer;
  border: 1px solid #dadce0;
  padding: 0.4rem 4rem;
  font-weight: 600;
  font-size: 1rem;
  width: max-content;
  line-height: 0;
  margin: 1rem 0rem;
  &:hover {
    background-color: #f0f1f5;
  }
`;

const GoogleLoginButton = () => {
  const handleSocialLogin = async () => {
    try {
      localStorage.setItem("verifyCredentialRequired", "yes");

      await magic.oauth.loginWithRedirect({
        provider: "google",
        redirectURI: new URL("/callback-social", window.location.origin).href,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Button onClick={handleSocialLogin}>
      <FcGoogle size={"2.5rem"} /> &nbsp; Log in with Google
    </Button>
  );
};

export default GoogleLoginButton;
