import { Col } from "react-bootstrap";
import styled from "styled-components";
import * as BookChatStyles from "./styled";
import { useContext, useEffect, useState } from "react";
import { BookContext } from "../../context/BookContext";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { TeamContext } from "../../context/team-context";
import { NotificationManager } from "react-notifications";

const TabContainer = styled.div`
  background-color: #f8f7fa;
  display: flex;
  align-items: center;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  padding: 0px 0px;
  min-height: 50px;
`;
const Tab = styled.button<{
  selected?: boolean;
}>`
  border: none;
  border-bottom: ${(props) =>
    props.selected ? "2px solid #1585B5" : "2px solid transparent"};
  outline: none;
  cursor: pointer;
  padding: 0.5rem 2.3rem;
  min-width: max-content;
  transition: 0.3s;
  font-size: 15px;
  background-color: #f8f7fa;
  position: relative;
  color: ${(props) => (props.selected ? "#1585B5" : "#33303C61")};
  font-weight: 700;
`;

const TabBlue = styled.button<{
  selected?: boolean;
}>`
  border: none;
  border-bottom: ${(props) =>
    props.selected ? "2px solid #1585B5" : "2px solid transparent"};
  outline: none;
  cursor: pointer;
  padding: 0.5rem 2.3rem;
  min-width: max-content;
  transition: 0.3s;
  font-size: 15px;
  background-color: #f8f7fa;
  position: relative;
  color: ${(props) => (props.selected ? "#1585B5" : "#33303C61")};
  font-weight: 700;
`;

const TabParent = styled.div<{
  selected?: boolean;
}>`
  flex-direction: column;
  flex: auto;
  border-bottom: ${(props) =>
    props.selected ? "2px solid #1585B5" : "2px solid transparent"};
  color: ${(props) => (props.selected ? "#1585B5" : "#33303C61")};
  & div {
    font-size: 16px;
    font-weight: 600;
    background: rgb(255, 255, 255);
    padding: 0.5rem 1rem;
    text-align: center;
    justify-content: center;
  }
`;

const MobileTabComponent = ({ setSelectTabName, selectTabName }: any) => {
  const { selectedTeam } = useContext(TeamContext);
  const { selectedBook } = useContext(BookContext);
  const [canView, setCanView] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const [selectParentTab, setSelectParentTab] =
    useState<any>("chatbotSettings");
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("selectedTeam", selectedTeam);
    if (selectedTeam) {
      let currentTeamMember: any =
        selectedTeam &&
        selectedTeam.members &&
        selectedTeam?.members.find((m: any) => m.email === user?.email);
      // console.log("currentTeamMember is, ", currentTeamMember);
      if (currentTeamMember && currentTeamMember.role !== "owner") {
        setCanView(false);
      } else {
        setCanView(true);
      }
    } else {
      setCanView(true);
    }
    // eslint-disable-next-line
  }, [selectedTeam]);

  return (
    <>
      <BookChatStyles.ChatPageHeader
        style={{
          minHeight: "45px",
          flexDirection: "row",
        }}
      >
        <TabParent selected={selectParentTab === "chatbotSettings"}>
          <Col
            md={12}
            onClick={() => {
              setSelectParentTab("chatbotSettings");
              setSelectTabName("Chat");
            }}
          >
            Chatbot Settings
          </Col>
        </TabParent>

        {canView && <TabParent selected={selectParentTab === "leadSettings"}>
          <Col
            md={12}
            onClick={() => {
              setSelectParentTab("leadSettings");
              setSelectTabName("Login Settings");
            }}
          >
            Lead Settings
          </Col>
        </TabParent>}
      </BookChatStyles.ChatPageHeader>
      {selectParentTab === "chatbotSettings" && (
        <TabContainer
          style={{
            width: "100%",
            minHeight: "50px",
          }}
        >
          <>
            <Tab
              onClick={() => {
                navigate(
                  "/books/" + selectedBook?._id + "/chatbot-settings/chat"
                );
                setSelectTabName("Chat");
              }}
              selected={selectTabName === "Chat"}
            >
              Chat
            </Tab>
            {canView && <> {selectedBook?.type === "google" ||
              selectedBook?.chatType === "chatgpt" ? null : (
              <Tab
                onClick={() => {
                  if (canView) {
                    navigate(
                      "/books/" +
                      selectedBook?._id +
                      "/chatbot-settings/data-sources"
                    );
                    setSelectTabName("Data Sources");
                  } else {
                    NotificationManager.error("Permission not allowed");
                  }
                }}
                selected={selectTabName === "Data Sources"}
              >
                Data Sources
              </Tab>
            )}
              <Tab
                onClick={() => {
                  if (canView) {
                    navigate(
                      "/books/" +
                      selectedBook?._id +
                      "/chatbot-settings/manage-chatbot"
                    );
                    setSelectTabName("Manage Chatbot");
                  } else {
                    NotificationManager.error("Permission not allowed");
                  }
                }}
                selected={selectTabName === "Manage Chatbot"}
              >
                Manage
              </Tab>

              <Tab
                onClick={() => {
                  if (canView) {
                    navigate(
                      "/books/" +
                      selectedBook?._id +
                      "/chatbot-settings/analytics"
                    );
                    setSelectTabName("Analytics");
                  } else {
                    NotificationManager.error("Permission not allowed");
                  }
                }}
                selected={selectTabName === "Analytics"}
              >
                Analytics
              </Tab></>}
          </>
        </TabContainer>
      )}
      {selectParentTab === "leadSettings" && (
        <TabContainer
          style={{
            width: "100%",
            minHeight: "50px",
          }}
        >
          <>
            <TabBlue
              onClick={() => {
                if (canView) {
                  navigate(
                    "/books/" +
                    selectedBook?._id +
                    "/lead-settings/login-settings"
                  );
                  setSelectTabName("Login Settings");
                } else {
                  NotificationManager.error("Permission not allowed");
                }
              }}
              selected={selectTabName === "Login Settings"}
            >
              Login Settings
            </TabBlue>
            <TabBlue
              onClick={() => {
                if (canView) {
                  navigate(
                    "/books/" +
                    selectedBook?._id +
                    "/lead-settings/theme-settings"
                  );
                  setSelectTabName("Theme Settings");
                } else {
                  NotificationManager.error("Permission not allowed");
                }
              }}
              selected={selectTabName === "Theme Settings"}
            >
              Theme Settings
            </TabBlue>
            <TabBlue
              onClick={() => {
                if (canView) {
                  navigate(
                    "/books/" +
                    selectedBook?._id +
                    "/lead-settings/chatbot-sessions"
                  );
                  setSelectTabName("Chatbot Sessions");
                } else {
                  NotificationManager.error("Permission not allowed");
                }
              }}
              selected={selectTabName === "Chatbot Sessions"}
            >
              Chatbot Sessions
            </TabBlue>

            <TabBlue
              onClick={() => {
                if (canView) {
                  navigate(
                    "/books/" +
                    selectedBook?._id +
                    "/lead-settings/sample-questions"
                  );
                  setSelectTabName("Sample Questions");
                } else {
                  NotificationManager.error("Permission not allowed");
                }
              }}
              selected={selectTabName === "Sample Questions"}
            >
              Sample Questions
            </TabBlue>
          </>
        </TabContainer>
      )}
    </>
  );
};

export default MobileTabComponent;
