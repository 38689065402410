// import { io } from "socket.io-client";
import { createContext, ReactElement, useState } from "react";
import {
  DowngradeRequest,
  PlanContextProps,
  PlanContextValues,
  PlanHighlighter,
  PublicContextValues,
  SubscriptionPlan,
  UserSubscription,
} from "../types";

export const PlanContext = createContext({} as PlanContextValues);

const PlanContextProvider = (
  props: PlanContextProps
): ReactElement<PublicContextValues> => {
  const [planHighliter, setPlanHighliter] = useState<
    PlanHighlighter | undefined
  >(undefined);
  const [plansList, setPlansList] = useState<SubscriptionPlan[]>([]);
  const [activePlan, setActivePlan] = useState<UserSubscription>();
  const [downgradeRequestData, setDowngradeRequestData] =
    useState<DowngradeRequest>();

  return (
    <PlanContext.Provider
      value={{
        planHighliter,
        setPlanHighliter,
        plansList,
        setPlansList,
        activePlan,
        setActivePlan,
        downgradeRequestData,
        setDowngradeRequestData,
      }}
    >
      {props.children}
    </PlanContext.Provider>
  );
};

export default PlanContextProvider;
