import { Button, Col, FormLabel } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { MOBILE } from "../../constants/mobile";
import { TAB } from "../../constants/tab";

const DropZone = styled.div`
  width: 100%;
  border: 1px solid rgba(51, 48, 60, 0.6);
  border-radius: 8px;
`;

const DropArea = styled.div`
  width: 100%;
  padding: 1rem;
  flex-direction: column;
  text-align: center;
`;

const DropAreaActive = styled.div`
  width: 100%;
  padding: 1rem;
  flex-direction: column;
  text-align: center;
  background: #0000ff80;
`;

const DragDropBrowseBtn = styled(Button)`
  width: 40%;
  align-self: center;
  background: #1585b5;
  border-radius: 10px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 80%;
    border-radius: 3px;
    font-size: 13px;
  }
`;

const InfoText = styled.span`
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;
const FormLabelText = styled(FormLabel)`
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  @media (min-width: ${TAB.minWidth}px) {
  }
`;
const ParagraphText = styled.p`
  font-size: 14px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    margin-bottom: 0.5rem;
  }
`;
const SelectedFileData = styled.span``;

const UploadComponent = (props: any) => {
  const {
    setFileObject,
    fileObject,
    setFieldValue,
    setTotalTextCharacterCount,
  } = props;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "text/plain": [".txt"],
    },
    onDrop: (acceptedFiles) => {
      setFileObject(acceptedFiles);
      let reader = new FileReader();
      reader.readAsText(acceptedFiles[0]);
      reader.onload = function () {
        let content: any = reader.result;
        setFieldValue("text", content);
        setTotalTextCharacterCount(content.length);
      };
    },
  });

  return (
    <Col style={{ flexDirection: "column" }}>
      <FormLabelText>Attach File</FormLabelText>
      <Col md={12} style={{ background: "#fff" }} xs={12} sm={12}>
        <DropZone {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <DropAreaActive>
              <p>Drop the file here...</p>
              <DragDropBrowseBtn>Browse Text File</DragDropBrowseBtn>
              <InfoText>Only TXT file is accepted</InfoText>
              {/* <SelectedFileData></SelectedFileData> */}
            </DropAreaActive>
          ) : fileObject && fileObject.length > 0 ? (
            <DropArea>
              <p>Drop the file here...</p>
              <DragDropBrowseBtn>Browse another File</DragDropBrowseBtn>
              <InfoText></InfoText>
              {fileObject && fileObject.length > 0 ? (
                fileObject.map((object: File) => {
                  return (
                    <SelectedFileData>{object && object.name}</SelectedFileData>
                  );
                })
              ) : (
                <SelectedFileData></SelectedFileData>
              )}
            </DropArea>
          ) : (
            <DropArea>
              <p>Drop the file here...</p>
              <DragDropBrowseBtn>Browse Text File</DragDropBrowseBtn>
              <InfoText>Only TXT file is accepted</InfoText>
            </DropArea>
          )}
        </DropZone>
      </Col>
    </Col>
  );
};

export default UploadComponent;
