import { Field, Form } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToBottom from "react-scroll-to-bottom";
import styled from "styled-components";
import { TreeSelect } from "primereact/treeselect";
import { OverlayPanel } from "primereact/overlaypanel";
import { MOBILE } from "../../constants/mobile";
import { MEDIUM } from "../../constants/desktop";
import { TAB } from "../../constants/tab";

interface Selected {
  selected: boolean;
}

export const MainContainer = styled(Container)`
  padding: 0;
  /* border-top: 1px #21232c48 solid; */
  height: calc(100vh - 47px);
`;

export const Sidebar = styled(Col)`
  background: #2f3349;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 50px);

  @media (max-width: ${MOBILE.maxWidth}px) {
    position: fixed;
    display: block;
    width: 93%;
    height: 92vh;
  }
  @media (min-width: ${TAB.minWidth}px) {
    position: fixed;
    display: block;
    width: 60%;
    height: 94vh;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    background: #2f3349;
    flex-direction: column;
    overflow: auto;
    position: relative;
    width: 15%;
    height: 100vh;
  }
`;
export const SideBarHeader = styled.div`
  height: 20px;
  padding: 15px;
  width: 100%;
  padding-left: 20px;
  margin-top: 10px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 0;
  }
  @media (min-width: ${TAB.minWidth}px) {
    display: flex;
    align-items: center;
  }
`;
export const SideBarContent = styled.div`
  width: 100%;
  padding: 10px;
  flex-direction: column;
`;
export const LogoutButton = styled.img`
  padding: 0;
  width: 30%;
  margin: auto;
  cursor: pointer;
`;

export const BackButton = styled.img`
  padding: 0;
  width: 12%;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 20%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 20%;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 20%;
  }
`;

export const BookFetchForm = styled(Form)`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  margin: 0;
  align-self: end;
`;

export const FieldSection = styled.div`
  position: relative;
  flex-direction: column;
`;

export const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
  border: ${(props) => (props.error ? "2px solid red" : "2px solid #282c34")};
`;

export const FetchBookButton = styled.button`
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 16px;
  cursor: pointer;
  top: 0.5rem;
  position: absolute;
  right: 10px;
  background: none;
  border: none;
`;

export const ChatInputBox = styled(Col)`
  // height: calc(100vh - 90px);
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 2px;
  }
`;

export const ChatHistoryHeading = styled.h5`
  color: #cccfdd;
`;

export const ActionIcon = styled.img`
  margin: 0 !important;
`;

export const RightColumn = styled(Col)`
  flex-direction: column;
  background-color: #f8f7fa;
`;

export const RightContentRow = styled(Row)`
  width: 100%;
  padding: 20px;
  background-color: #fff;
  margin: 15px;
  margin-top: 0px;
  height: calc(100vh - 148px);
  border-radius: 1px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin: 0px;
    padding: 0px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin: 2px;
    padding: 2px 0;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin: 15px;
    padding: 20px;
  }
`;

export const MainConent = styled(Col)`
  flex-direction: column;
`;

export const ScrollDiv = styled(ScrollToBottom)`
  height: calc(100vh - 30vh);
  width: 100%;
  flex-direction: column;
`;

export const BookContent = styled.div`
  flex-direction: column;
  margin-left: 30px;
  & h5 {
    color: #33303c;
    font-size: 18px;
  }
  & h6 {
    color: rgba(51, 48, 60, 0.6);
    font-size: 16px;
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
    & h5 {
      color: #33303c;
      font-size: 14px;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 12px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
  }
`;

export const SignleQuestionAnswerSection = styled.div`
  margin: 5px 5px 15px 5px;
  flex-direction: column;
`;

export const ResponseSection = styled.div`
  padding: 15px 10px;
  color: #282c34;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 5px;
  align-items: flex-start;
  flex-direction: column;
  & span {
    font-size: 2rem;
  }
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
  }
`;

export const QuestionSection = styled.div`
  padding: 15px 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  align-items: center;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
  }
`;

export const QuestionAnswerSection = styled.div`
  flex-direction: column;
  width: 100%;
`;

export const NotesName = styled.div`
  align-items: center;
  padding: 10px;
  margin: 0.2rem 0rem;
  background: ${(props: Selected) => (props.selected ? "#3d4156" : "")};
  border-radius: 5px;
  cursor: pointer;
  & p {
    color: #fff;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
  }
  & img {
    margin-right: 0.8rem;
  }
`;

export const FieldColumn = styled(Col)`
  position: relative;
`;

export const OverlayFieldColumn = styled(Col)`
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 999;
  background: #00000050;
  cursor: not-allowed;
`;

export const StatusLoaderSection = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const WaitTitle = styled.p`
  text-align: center;
  font-size: 24px;
  color: #4f83b5;
  font-weight: bold;
`;

export const WaitDescription = styled.p`
  text-align: center;
`;

export const ChatPageHeader = styled.div`
  background-color: #f8f7fa;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0.2rem 0rem 0.2rem 1.5rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.2rem 0rem 0.2rem 0.5rem;
  }
`;

export const RestrictedSection = styled(Row)`
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const RemainingCount = styled(Col)`
  color: #ea5455;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  background: #ffe1e1;
  border-radius: 6px;
  padding: 0.2rem 1rem;
`;

export const LoginLink = styled(Col)`
  color: #1585b5;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;

export const NoteListTitle = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #1585b5;
`;

export const SelectField = styled.select`
  font-size: 14px;
  font-weight: 500;
  color: #1585b5;
  border: none;
  width: 20px;
`;

export const SelectOption = styled.option`
  font-size: 14px;
  color: #33303c;
  padding: 5px 20px 5px 5px;
  &:hover {
    background: #1585b5;
  }
`;

export const TreeSelectStyle = styled(TreeSelect)`
  font-size: 14px;
  border: 0;
  & span {
    font-size: 1rem;
    color: #1585b5;
    font-weight: bold;
  }
`;
export const AddCategoryStyle = styled.p`
  font-size: 14px;
  color: #1585b5;
  font-weight: bold;
  margin: 0;
  margin-left: 3rem;
  cursor: pointer;
  align-self: center;
`;

export const OverlayPanelStyle = styled(OverlayPanel)`
  flex-direction: column;
`;

export const AddCategoryForm = styled(Form)`
  margin-bottom: 1rem;
  position: relative;
  & input {
    background: none;
    border: 1.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.3);
    padding: 8px;
    font-size: 14px;
  }
  & label {
    color: rgba(51, 48, 60, 0.8);
    font-size: 14px;
    font-weight: bold;
  }
  & button {
    background: none;
    border: none;
    border-color: #1585b5;
    box-shadow: none;
    position: absolute;
    right: 2%;
    top: 42%;
  }
`;

export const AddCategoryHeading = styled.h6`
  color: #33303c;
  font-weight: 600;
  padding-left: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e6e6e7;
`;

export const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
  font-size: 12px;
`;

export const CategoryHeading = styled.h2`
  color: #33303c;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 2rem;
  margin-left: 2px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
    margin-left: 0px;
    margin-bottom: 0.5rem;
    margin-top: 10px;
  }

  @media (min-width: ${TAB.minWidth}px) {
    font-size: 16px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 22px;
  }
`;

export const CategorySummaryParent = styled(Col)`
  background: #f8f7fa;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    background: transparent;
    box-shadow: none;
    border-radius: 0px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    background: transparent;
    box-shadow: none;
    border-radius: 0px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    background: #f8f7fa;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
`;

export const CategorySummaryHeader = styled.div`
  border-bottom: 1px solid #e6e6e7;
  flex: 1;
  padding: 16px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 2px 0;
    border-bottom: none;
  }

  @media (min-width: ${TAB.minWidth}px) {
    padding: 16px 0;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    padding: 16px;
  }
`;

export const SummaryQuestionCount = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: #33303c;
  text-transform: Capitalize;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 11px;
    font-weight: 400;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 16px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 18px;
  }
`;

export const SummaryLastChecked = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: #33303c;
`;

export const CategorySummaryDetails = styled.div`
  padding: 16px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 0;
  }

  @media (min-width: ${TAB.minWidth}px) {
    padding: 16px 0;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    padding: 16px;
  }
`;

export const ForgottenBox = styled.div`
  background: #ec2d2d;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 20px;
    height: 20px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 20px;
    height: 20px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 25px;
    height: 25px;
  }
`;

export const RememberedBox = styled.div`
  background: #11b753;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 20px;
    height: 20px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 20px;
    height: 20px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 25px;
    height: 25px;
  }
`;

export const SkippedBox = styled.div`
  background: #fe9f5e;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 20px;
    height: 20px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 20px;
    height: 20px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 25px;
    height: 25px;
  }
`;

export const SummaryCountText = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: #33303c;
  padding-left: 10px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
    font-weight: 600;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 16px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 18px;
  }
`;

export const SummaryCountValues = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: #33303c;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    font-weight: 500;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 16px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 18px;
  }
`;

export const QuestionCategoryActions = styled.div`
  padding: 16px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 2px 0px;
  }
`;

export const QuestionActionForm = styled(Form)`
  flex: 1;
  & button {
    background: #1585b5;
    border-radius: 4px;
    color: #fff;
    border: none;
    box-shadow: none;
    padding: 0.5rem 2rem;
    margin-left: 1rem;
  }
  & select {
    background: #ffffff;
    border: 1px solid rgba(51, 48, 60, 0.6);
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    & button {
      background: #1585b5;
      border-radius: 4px;
      color: #fff;
      border: none;
      box-shadow: none;
      padding: 0.5rem 2rem;
      margin-left: 0;
      width: 76%;
    }
    & select {
      background: #ffffff;
      border: 1px solid rgba(51, 48, 60, 0.6);
      border-radius: 4px;
      font-weight: 400;
      font-size: 12px;
      padding: 10px;
      width: 76%;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    & button {
      background: #1585b5;
      border-radius: 4px;
      color: #fff;
      border: none;
      box-shadow: none;
      padding: 0.5rem 2rem;
      margin-left: 1rem;
    }
    & select {
      background: #ffffff;
      border: 1px solid rgba(51, 48, 60, 0.6);
      border-radius: 4px;
      font-weight: 400;
      font-size: 12px;
      padding: 10px;
    }
  }
`;

export const NotesQuestionParent = styled.div`
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
  }
`;

export const QuestionContent = styled.p`
  font-weight: 500;
  font-size: 20px;
  color: #282c34;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

export const AnswerContent = styled.p`
  font-size: 20px;
  color: #282c34;
  margin-top: 1rem;
  & h3 {
    font-weight: 500;
    font-size: 18px;
    color: #33303c;
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    & h3 {
      font-size: 13px;
    }
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    margin-top: 0.2rem;
  }
`;

export const ShowAnswerButton = styled.span`
  font-size: 16px;
  color: #1585b5;
  font-weight: 600;
  display: flex;
  cursor: pointer;
  margin-top: 2rem;
  & img {
    margin-right: 10px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

export const ButtonSection = styled.div`
  margin-top: 5rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const ForgottenBtn = styled.button`
  background: #ec2d2d;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  border: none;
  box-shadow: none;
  padding: 0.5rem 2rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0;
    width: 30%;
    font-size: 13px;
    height: 30px;
  }
`;

export const RememberedBtn = styled.button`
  background: #11b753;
  border-radius: 4px;
  margin: 0rem 2rem;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  border: none;
  box-shadow: none;
  padding: 0.5rem 2rem;

  @media (max-width: ${MOBILE.maxWidth}px) {
    margin: 0;
    padding: 0rem;
    width: 30%;
    font-size: 13px;
    margin: 0rem 1rem;
    height: 30px;
  }
`;

export const SkipBtn = styled.button`
  background: #fe9f5e;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  border: none;
  box-shadow: none;
  padding: 0.5rem 2rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0;
    font-size: 13px;
    height: 30px;
    width: 30%;
  }
`;
