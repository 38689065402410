import { Mixpanel } from "../mixpanel";
import { isTestUser } from "../utils/utils";
import { trackDropChatEvent } from "./dropchat-event-track";

declare var _cio: any;

export const trackEvent = (eventInput: any) => {
  if (!isTestUser(eventInput.email))
    if (!isTestUser(eventInput.chatbotUserEmail)) {
      if (process.env.REACT_APP_ENV === "production") {
        // Need to add dropchat event here
        trackDropChatEvent(eventInput);
        // need to Add mixpanel event here
        Mixpanel.track(eventInput.event, {
          email: eventInput.email,
          ...eventInput,
        });

        // need to Add customerio event here

        _cio.track(eventInput.event, {
          name: eventInput.description,
          ...eventInput,
        });
      }
    }
};
