import { Col } from "react-bootstrap";
import styled from "styled-components";
import * as BookChatStyles from "./styled";
import { useContext, useEffect, useState } from "react";
import { BookContext } from "../../context/BookContext";
import { useNavigate } from "react-router-dom";
import { TeamContext } from "../../context/team-context";
import { UserContext } from "../../context/UserContext";
import { NotificationManager } from "react-notifications";

const TabContainer = styled.div`
  overflow: hidden;
  background-color: #f8f7fa;
  /* padding-left: 8px; */
  display: flex;
  align-items: center;
`;
const Tab = styled.button<{
  selected?: boolean;
}>`
  border: none;
  border-bottom: ${(props) => (props.selected ? "2px solid #1585B5" : "none")};
  outline: none;
  cursor: pointer;
  padding: 0.5rem 2rem;
  min-width: 100px;
  transition: 0.3s;
  font-size: 13px;
  position: relative;
  background-color: #fff;
  color: ${(props) => (props.selected ? "#1585B5" : "#33303C61")};
  font-weight: 700;
`;

const TabBlue = styled.button<{
  selected?: boolean;
}>`
  border: none;
  border-bottom: ${(props) => (props.selected ? "2px solid #1585B5" : "none")};
  outline: none;
  cursor: pointer;
  padding: 8px 3rem;
  min-width: 100px;
  transition: 0.3s;
  font-size: 13px;
  position: relative;
  background-color: #b6e3f6;
  color: ${(props) => (props.selected ? "#1585B5" : "#33303C61")};
  font-weight: 700;
`;

const TabParent = styled.div`
  flex-direction: column;
  flex: auto;
`;

const DesktopTabComponent = ({ setSelectTabName, selectTabName }: any) => {
  const navigate = useNavigate();
  const { selectedTeam } = useContext(TeamContext);
  const { selectedBook } = useContext(BookContext);
  const [canView, setCanView] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    // console.log("selectedTeam", selectedTeam);
    if (selectedTeam) {
      let currentTeamMember: any =
        selectedTeam &&
        selectedTeam.members &&
        selectedTeam?.members.find((m: any) => m.email === user?.email);
      // console.log("currentTeamMember is, ", currentTeamMember);
      if (currentTeamMember && currentTeamMember.role !== "owner") {
        setCanView(false);
      } else {
        setCanView(true);
      }
    } else {
      setCanView(true);
    }
    // eslint-disable-next-line
  }, [selectedTeam]);

  return (
    <BookChatStyles.ChatPageHeader
      style={{
        minHeight: "57px",
        flexDirection: "row",
      }}
    >
      <TabParent>
        <Col
          md={12}
          style={{
            color: "#33303C",
            fontSize: "13px",
            fontWeight: 600,
            background: "#fff",
            padding: "0rem 1rem",
          }}
          onClick={() => setSelectTabName("Chat")}
        >
          Chatbot Settings
        </Col>
        <TabContainer
          style={{
            backgroundColor: "#fff",
            width: "100%",
          }}
        >
          <>
            <Tab
              onClick={() => {
                navigate(
                  "/books/" + selectedBook?._id + "/chatbot-settings/chat"
                );
                setSelectTabName("Chat");
              }}
              selected={selectTabName === "Chat"}
            >
              Chat
            </Tab>
            {canView && <>{selectedBook?.type === "google" ||
              selectedBook?.chatType === "chatgpt" ? null : (
              <Tab
                onClick={() => {
                  if (canView) {
                    navigate(
                      "/books/" +
                      selectedBook?._id +
                      "/chatbot-settings/data-sources"
                    );
                    setSelectTabName("Data Sources");
                  } else {
                    NotificationManager.error("Permission not allowed");
                  }
                }}
                selected={selectTabName === "Data Sources"}
              >
                Data Sources
              </Tab>
            )}
              <Tab
                onClick={() => {
                  if (canView) {
                    navigate(
                      "/books/" +
                      selectedBook?._id +
                      "/chatbot-settings/manage-chatbot"
                    );
                    setSelectTabName("Manage Chatbot");
                  } else {
                    NotificationManager.error("Permission not allowed");
                  }
                }}
                selected={selectTabName === "Manage Chatbot"}
              >
                Manage Chatbot
              </Tab>
              <Tab
                onClick={() => {
                  if (canView) {
                    navigate(
                      "/books/" +
                      selectedBook?._id +
                      "/chatbot-settings/analytics"
                    );
                    setSelectTabName("Analytics");
                  } else {
                    NotificationManager.error("Permission not allowed");
                  }
                }}
                selected={selectTabName === "Analytics"}
              >
                Analytics
              </Tab></>}
          </>
        </TabContainer>
      </TabParent>
      {canView && <TabParent>
        <Col
          md={12}
          style={{
            color: "#33303C",
            fontSize: "13px",
            fontWeight: 600,
            background: "#B6E3F6",
            padding: "0rem 1rem",
          }}
          onClick={() => setSelectTabName("Login Settings")}
        >
          Lead Settings
        </Col>
        <TabContainer
          style={{
            backgroundColor: "#B6E3F6",
            width: "100%",
          }}
        >
          <>
            <TabBlue
              onClick={() => {
                if (canView) {
                  navigate(
                    "/books/" +
                    selectedBook?._id +
                    "/lead-settings/login-settings"
                  );
                  setSelectTabName("Login Settings");
                } else {
                  NotificationManager.error("Permission not allowed");
                }
              }}
              selected={selectTabName === "Login Settings"}
            >
              Login Settings
            </TabBlue>
            <TabBlue
              onClick={() => {
                if (canView) {
                  navigate(
                    "/books/" +
                    selectedBook?._id +
                    "/lead-settings/theme-settings"
                  );
                  setSelectTabName("Theme Settings");
                } else {
                  NotificationManager.error("Permission not allowed");
                }
              }}
              selected={selectTabName === "Theme Settings"}
            >
              Theme Settings
            </TabBlue>
            <TabBlue
              onClick={() => {
                if (canView) {
                  navigate(
                    "/books/" +
                    selectedBook?._id +
                    "/lead-settings/chatbot-sessions"
                  );
                  setSelectTabName("Chatbot Sessions");
                } else {
                  NotificationManager.error("Permission not allowed");
                }
              }}
              selected={selectTabName === "Chatbot Sessions"}
            >
              Chatbot Sessions
            </TabBlue>

            <TabBlue
              onClick={() => {
                if (canView) {
                  navigate(
                    "/books/" +
                    selectedBook?._id +
                    "/lead-settings/sample-questions"
                  );
                  setSelectTabName("Sample Questions");
                } else {
                  NotificationManager.error("Permission not allowed");
                }
              }}
              selected={selectTabName === "Sample Questions"}
            >
              Sample Questions
            </TabBlue>
          </>
        </TabContainer>
      </TabParent>}
    </BookChatStyles.ChatPageHeader>
  );
};

export default DesktopTabComponent;
