import { Col } from "react-bootstrap";
import styled from "styled-components";
import EmailIcon from "../images/email-icon.svg";
import WhiteDropChatLogo from "../images/white-dropchat-logo.svg";
import { MEDIUM, MOBILE, TAB } from "../constants/breakpoints";
import { useEffect, useState } from "react";
import axios from "axios";

const FooterMain = styled.div`
  width: 100%;
  background: #33303c;
  padding: 0 40px;
  height: 48px;
  color: #fff;
  position: inherit;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 10px 40px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    flex-direction: row;
    height: 48px;
  }
`;

const CopyrightCol = styled(Col)`
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 21px;

  @media (max-width: ${MOBILE.maxWidth}px) {
    margin: 0.5rem 0rem;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin: 1rem 0rem;
  }
`;

const EmailCol = styled(Col)`
  align-items: center;
  justify-content: right;
  & a {
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    line-height: 21px;
  }
`;

const MainFooter = () => {
  // eslint-disable-next-line
  const [publicBookCount, setPublicBookCount] = useState<any>(0);

  const today = new Date();
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_SERVER_URL +
          "/books/chatbots/active-feature-chatbots"
      )
      .then((response) => {
        if (response.data) {
          setPublicBookCount(response.data.data);
        }
      })
      .catch((error) => {
        // handle error
      });
  }, []);

  // style={{
  //   position:
  //     publicBookCount && publicBookCount.length === 0
  //       ? "absolute"
  //       : "inherit",
  //   bottom: 0,
  // }}

  return (
    <FooterMain>
      <Col>
        <img src={WhiteDropChatLogo} width={100} alt="DropChatLogo" />
      </Col>
      <CopyrightCol>
        © Copyright {today.getFullYear()}. All rights reserved
      </CopyrightCol>
      <EmailCol>
        <img src={EmailIcon} width={20} alt="emailIcon" />
        <a href="mailto:support@dropchat.co">&nbsp; support@dropchat.co</a>
      </EmailCol>
    </FooterMain>
  );
};

export default MainFooter;
