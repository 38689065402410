import { useContext, useState } from "react";
// import "./App.css";
import { Button, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// import { PublicContext } from "../context/PublicContext";
import { UserContext } from "../context/UserContext";
import { PlanContext } from "../context/plan-context";
import MagicBlue from "../images/magicblue.svg";
import Tick from "../images/pricing-tick.svg";

const MainSection = styled(Container)`
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 2.5rem;
  padding-top: 2rem;
`;

const CenterContentRow = styled(Row)`
  margin-bottom: 0.5rem;
  margin-top: 1vh;
`;

const CenterContentRowDetails = styled(Row)`
  background: #f9fdff;
  border: 1px solid #d8e4eb;
  padding: 20px;
  height: 40vh;
  padding-left: 5vh;
`;

// const CenterContentColumn = styled(Col)`
//   justify-content: center;
// `;

const RedMessageContent = styled.p`
  color: #ea5455;
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
  margin: 0;
  padding: 0;
`;

const BoldMessageContent = styled.p`
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
  margin-top: 2vh;
  padding: 0;
`;

const NormalMessageContent = styled.p`
  // display: flex;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  padding: 0;
`;

const DivForBtn = styled.div`
  display: flex;
  align-items: end;
  /* justify-content: center; */
  /* padding-top: 100px; */
  margin-left: -12px;
`;

const CallToAction = styled(Button)`
  padding: 1rem 3rem;
  color: white;
  background: #f2ae01;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  width: 224px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background: #f2ae01;
  }
`;

const DivForBtnParas = styled.div`
  display: flex;
  justify-content: center;
`;

const MagicImg = styled.img`
  display: ${(props) => (props.className === "white" ? "block" : "none")};
`;

const BtnPara = styled.span`
  color: #ffffff;
  font-weight: 600;
  line-height: 24px;
  font-size: 15px;
  padding: 0px 10px;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const SubscriptionExpiredPopup = ({
  loadingState,
  restrictionMessage,
  messageregardingrest,
  highliter,
}: any) => {
  // const { deviceWidth } = useContext(PublicContext);
  const { user } = useContext(UserContext);
  const { setPlanHighliter } = useContext(PlanContext);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [disabled, setDisabled] = useState(false);

  return (
    <MainSection fluid>
      <CenterContentRow>
        <RedMessageContent>{restrictionMessage}</RedMessageContent>
        <BoldMessageContent>{messageregardingrest}</BoldMessageContent>
      </CenterContentRow>
      <CenterContentRowDetails>
        <BoldMessageContent>Do more by purchasing Plan</BoldMessageContent>
        <NormalMessageContent>
          <img
            alt="imagTick"
            src={Tick}
            width="15"
            style={{ marginRight: "10px" }}
          />
          Get more features
        </NormalMessageContent>
        <DivForBtn>
          {highliter === "isFlashcardHighliter" ? (
            <CallToAction
              // leadingIcon={MonochromeIcons.PaperPlane}
              color="primary"
              size="sm"
              disabled={disabled}
              onClick={() => {
                setPlanHighliter({
                  isBookHighliter: false,
                  bookLimit: user?.currentSubscription?.meta?.noOfBooks
                    ? user.currentSubscription?.meta?.noOfBooks
                    : 0,
                  isDeleteBookHighliter: false,
                  isFlashcardHighliter: true,
                  isCharacterLimit: false,
                  pdfLimit: 0,
                });
                navigate("/subscription");
              }}
            >
              <DivForBtnParas>
                <MagicImg className="blue" src={MagicBlue} alt="magic" />
                <BtnPara>Purchase Plan</BtnPara>
              </DivForBtnParas>
            </CallToAction>
          ) : (
            <CallToAction
              // leadingIcon={MonochromeIcons.PaperPlane}
              color="primary"
              size="sm"
              disabled={disabled}
              onClick={() => {
                setPlanHighliter({
                  isBookHighliter: true,
                  bookLimit: user?.currentSubscription?.meta?.noOfBooks
                    ? user.currentSubscription?.meta?.noOfBooks
                    : 1,
                  isDeleteBookHighliter: false,
                  isFlashcardHighliter: false,
                  isCharacterLimit: false,
                  pdfLimit: 0,
                });
                navigate("/subscription");
              }}
            >
              <DivForBtnParas>
                <MagicImg className="blue" src={MagicBlue} alt="magic" />
                <BtnPara>Purchase Plan</BtnPara>
              </DivForBtnParas>
            </CallToAction>
          )}
        </DivForBtn>
      </CenterContentRowDetails>

      {/* <Row>
        <CenterContentColumn
          style={{ marginTop: deviceWidth === DEVICE_MEDIUM ? "5rem" : "0" }}
        >
          <span>© Copyright 2023. All rights reserved</span>
        </CenterContentColumn>
      </Row> */}
    </MainSection>
  );
};

export default SubscriptionExpiredPopup;
