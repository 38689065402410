import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Accordion, FormLabel } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MEDIUM } from "../../constants/desktop";
import { MOBILE } from "../../constants/mobile";
import { TAB } from "../../constants/tab";
import { UserContext } from "../../context/UserContext";
import { LoaderContext } from "../../context/loader-context";
import { PlanContext } from "../../context/plan-context";
import {
  EventCategory,
  SubCategory,
  UserEvents,
} from "../../event-utils/dropchat-event-enums";
import { trackEvent } from "../../event-utils/track-event";
import Tick from "../../images/pricing-tick.svg";
import { magic } from "../../magic";
import myAxios, { setAuthHeader } from "../../myaxios";
import { UserSubscription } from "../../types";
import { USER_PROFILE } from "../../utils/class-names";
import { numberWithComma, parseErrorResponse } from "../../utils/utils";
import DeleteAccountConfirmationBox from "../utility-component/delete-account-confirmation-box";
import MobileUserSettings from "./mobile-user-settings";
import IntegrationsApps from "../user-settings/integrations-apps";

const AddAppFeatureContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
`;

const HeadingText = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #2f4c95;
`;
const PlanDesc = styled.h6`
  color: #1f062a;
  //margin-right: 50px;
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  /* color: #2f4c95; */
  color: rgba(31, 6, 42, 0.5);
  line-height: 18px;
`;
const Label = styled(FormLabel)`
  font-weight: bold;
`;

const ValueField = styled(Field)`
  width: 100%;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  margin: 0px 0px 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: 40px;
  }
`;

const UserProfileForm = styled(Form)`
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 100%;
  }
`;

const UpadateBtn = styled.button`
  border: none;
  width: 133px;
  height: 40px;
  background: #2f4c95;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 8px 20px;
  &:hover {
    background: #0e2151;
    border: none;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin: 0;
    background: rgba(21, 133, 181, 1);
    width: 172px;
    height: 37px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin: 0;
    background: rgba(21, 133, 181, 1);
    width: 172px;
    height: 37px;
  }
`;
const DivForSubmitBtn = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: flex-start;
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: flex-start;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    justify-content: center;
  }
`;

const DeleteAccountSection = styled.div`
  flex-direction: column;
  margin-top: 2rem;
  padding-bottom: 2rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 0;
    padding-bottom: 0;
  }
`;

const DeleteHeadingText = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #2f4c95;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 16px;
  }
`;

const DeleteParagraphText = styled.p`
  font-size: 16px;
  color: #33303c;
  margin-top: 1rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 0.5rem;
    font-size: 14px;
  }
`;

const DeleteButton = styled.button`
  background: #ea5455;
  color: #fff;
  padding: 8px 40px 8px 40px;
  border: none;
  border-radius: 4px;
  width: max-content;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
  }
`;

const UpgradeSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 5px 25px;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
  cursor: pointer;
`;
const ButtonDiv = styled.div`
  display: block;
`;

const PlanSpan = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #585858;
  margin-left: 5px;
  margin-top: 1px;
  display: flex;
  align-items: center;
`;

const MobileUserProfile = () => {
  let navigate = useNavigate();
  const { setUser, user } = useContext(UserContext);
  const { setPlanHighliter, activePlan, setActivePlan } =
    useContext(PlanContext);
  const { setShowLoader } = useContext(LoaderContext);
  const { userProfileSettings, setUserProfileSettings } =
    useContext(UserContext);
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  // const [activePlan, setActivePlan] = useState<UserSubscription>();
  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");
  const [confirmationAction, setConfirmationAction] = useState<string>("");

  const saveUserProfileSettings = (values: any) => {
    myAxios
      .post(`/user/update-user-profile`, {
        ...values.userProfileSettings,
      })
      .then((response) => {
        if (response.data.success) {
          trackEvent({
            email: values.email,
            eventCategory: EventCategory.USER,
            event: UserEvents.USER_SAVE_UPDATE_PROFILE,
            subCategory: SubCategory.EVENT,
            rawData: response.data,
          });

          NotificationManager.success(
            "Settings save successfully...",
            "",
            1500
          );
          if (response.data && response.data.data) {
            setUserProfileSettings(response.data.data);
          }
        } else {
          NotificationManager.error(response.data.message, "", 2000);
        }
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  useEffect(() => {
    if (user) {
      myAxios.get(`${process.env.REACT_APP_SERVER_URL}/plans`).then((res) => {
        setActivePlan(res.data.data.currentSubscription);
      });
    }
  }, [user]);

  const getCurrentChatbotUses = () => {
    if (!activePlan) return "";

    const txt = `${activePlan?.planStatics.totalBooksCount} -
                ${
                  activePlan?.planStatics.totalBooksCount &&
                  activePlan?.planStatics.totalBooksCount > 1
                    ? "Chatbots used"
                    : "Chatbot used"
                }
                out of
                ${
                  activePlan?.meta.noOfBooks === -1
                    ? "Unlimited"
                    : activePlan?.meta.noOfBooks
                } 
                ${
                  activePlan?.meta &&
                  activePlan?.meta.noOfBooks &&
                  (activePlan?.meta.noOfBooks > 1 ||
                    activePlan?.meta.noOfBooks === -1)
                    ? "chatbots "
                    : "chatbot "
                }
                plan limit`;
    return txt;
  };

  const getTotalCharactersUses = () => {
    if (!activePlan) return "";

    const txt = `${
      activePlan?.planStatics.usedMessage
        ? numberWithComma(Number(activePlan?.planStatics.usedMessage))
        : 0
    } - Total messages used out of ${
      activePlan?.noOfBook === -1
        ? "Unlimited"
        : numberWithComma(Number(activePlan?.meta.messageCount))
    }  messages plan limit`;
    return txt;
  };

  const getQuestionCharactersUses = () => {
    if (!activePlan) return "";

    const txt = `${
      activePlan?.planStatics.inputCharacterCount
        ? numberWithComma(Number(activePlan?.planStatics.inputCharacterCount))
        : 0
    } - Question characters used`;
    return txt;
  };

  const getAnswerCharactersUses = () => {
    if (!activePlan) return "";

    const txt = `${
      activePlan?.planStatics.outputCharacterCount
        ? numberWithComma(Number(activePlan?.planStatics.outputCharacterCount))
        : 0
    } - Answer characters used`;
    return txt;
  };

  const getSharedURLQuestionCharactersUses = () => {
    if (!activePlan) return "";

    const txt = `${
      activePlan?.planStatics.shareUsedMessage
        ? numberWithComma(Number(activePlan?.planStatics.shareUsedMessage))
        : 0
    } - Shared chatbot URL messages used`;
    return txt;
  };
  const getSharedURLAnswerCharactersUses = () => {
    if (!activePlan) return "";

    const txt = `${
      activePlan?.planStatics.shareUrlOutputCharacterCount
        ? numberWithComma(
            Number(activePlan?.planStatics.shareUrlOutputCharacterCount)
          )
        : 0
    } - Shared chatbot URL answer characters used`;
    return txt;
  };
  const getBubbleChatQuestionCharactersUses = () => {
    if (!activePlan) return "";

    const txt = `${
      activePlan?.planStatics.bubbleUsedMessage
        ? numberWithComma(Number(activePlan?.planStatics.bubbleUsedMessage))
        : 0
    } - Bubble(Embedded) chat messages used`;
    return txt;
  };
  const getBubbleChatAnswerCharactersUses = () => {
    if (!activePlan) return "";

    const txt = `${
      activePlan?.planStatics.bubbleOutputCharacterCount
        ? numberWithComma(
            Number(activePlan?.planStatics.bubbleOutputCharacterCount)
          )
        : 0
    } - Bubble(Embedded) chat answer characters used`;
    return txt;
  };
  const getIndexCharacterUsedCount = () => {
    if (!activePlan) return "";

    const txt = `${
      activePlan?.indexCharacterUsedTotal
        ? numberWithComma(Number(activePlan?.indexCharacterUsedTotal))
        : 0
    } - Total index characters used out of ${
      activePlan?.noOfBook === -1
        ? "Unlimited"
        : numberWithComma(Number(activePlan?.meta.indexCharacterSupport))
    } index characters plan limit`;
    return txt;
  };

  const deleteMyAccount = () => {
    setShowLoader(true);
    myAxios
      .post(`/user/user-profile-delete`, {
        email: user?.email,
      })
      .then(async (response) => {
        if (response.data.success) {
          NotificationManager.success("User successfully deleted.", "", 1500);
          if (response.data && response.data.data) {
            localStorage.clear();
            setAuthHeader("");
            if (await magic.user.isLoggedIn()) await magic.user.logout();
            setUser(undefined);
            setShowLoader(false);
            navigate("/landing");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        } else {
          setShowLoader(false);
          NotificationManager.error(response.data.message, "", 2000);
        }
        // setLoading(false);
      })
      .catch((error) => {
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
        // setLoading(false);
      });
  };

  const deleteConfirmationBox = () => {
    setConfirmationBoxMessage(
      "Deleting user account will remove all of user information from app.dropchat.co. This can not be undone. If you need to use the service again, you will need to create a new account."
    );
    setConfirmationAction("delete");
    setShowUtilityConfirmationBox(true);
  };

  const confirmClick = (action: string) => {
    if (action === "delete") {
      deleteMyAccount();
    }
  };

  return (
    <>
      {showUtilityConfirmationBox && (
        <DeleteAccountConfirmationBox
          setShow={setShowUtilityConfirmationBox}
          name="Confirmation"
          message={confirmationBoxMessage}
          okButtonText="Delete"
          action={confirmationAction}
          okButtonClick={confirmClick}
        />
      )}

      <Accordion
        className="acc-item mb-0 mt-0"
        style={{
          border: "none",
        }}
      >
        {/* ------------current plan usage-------- */}
        <Accordion.Item
          key={"1"}
          eventKey={"1"}
          style={{
            display: "block",
            margin: "10px 10px",
            marginBottom: "0",
            borderRadius: "0",
          }}
        >
          <Accordion.Header>Current Plan Usage</Accordion.Header>
          <Accordion.Body>
            {activePlan && (
              <AddAppFeatureContainer>
                <div>
                  <img
                    alt="img"
                    src={Tick}
                    width="20"
                    style={{ marginRight: "10px" }}
                  />
                  <PlanDesc style={{ marginTop: "10px" }}>
                    <b></b>
                    {getCurrentChatbotUses()}
                  </PlanDesc>
                </div>

                <div>
                  <img
                    alt="img1"
                    src={Tick}
                    width="20"
                    style={{ marginRight: "10px" }}
                  />
                  <PlanDesc>{getTotalCharactersUses()}</PlanDesc>
                </div>

                {/* <div>
                  <img
                    alt="img2"
                    src={Tick}
                    width="20"
                    style={{ marginRight: "10px" }}
                  />
                  <PlanDesc>{getQuestionCharactersUses()}</PlanDesc>
                </div>
                <div>
                  <img
                    alt="img3"
                    src={Tick}
                    width="20"
                    style={{ marginRight: "10px" }}
                  />
                  <PlanDesc>{getAnswerCharactersUses()}</PlanDesc>
                </div> */}

                <div>
                  <img
                    alt="img4"
                    src={Tick}
                    width="20"
                    style={{ marginRight: "10px" }}
                  />
                  <PlanDesc>{getSharedURLQuestionCharactersUses()}</PlanDesc>
                </div>

                {/* <div>
                  <img
                    alt="img5"
                    src={Tick}
                    width="20"
                    style={{ marginRight: "10px" }}
                  />
                  <PlanDesc>{getSharedURLAnswerCharactersUses()}</PlanDesc>
                </div> */}

                <div>
                  <img
                    alt="img7"
                    src={Tick}
                    width="20"
                    style={{ marginRight: "10px" }}
                  />
                  <PlanDesc>{getBubbleChatQuestionCharactersUses()}</PlanDesc>
                </div>

                {/* <div>
                  <img
                    alt="img6"
                    src={Tick}
                    width="20"
                    style={{ marginRight: "10px" }}
                  />
                  <PlanDesc>{getBubbleChatAnswerCharactersUses()}</PlanDesc>
                </div> */}

                <div>
                  <img
                    alt="img7"
                    src={Tick}
                    width="20"
                    style={{ marginRight: "10px" }}
                  />
                  <PlanDesc>{getIndexCharacterUsedCount()}</PlanDesc>
                </div>
              </AddAppFeatureContainer>
            )}
          </Accordion.Body>
        </Accordion.Item>

        {/* ------------user Profile-------- */}
        <Accordion.Item
          key={"2"}
          eventKey={"2"}
          style={{
            display: "block",
            margin: "10px 10px",
            marginBottom: "0",
            borderRadius: "0",
          }}
        >
          <Accordion.Header>User Profile</Accordion.Header>
          <Accordion.Body>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={{
                userProfileSettings: userProfileSettings && userProfileSettings,
              }}
              onSubmit={(values, { setSubmitting }) => {
                saveUserProfileSettings(values);
              }}
            >
              {({ setFieldValue }) => (
                <UserProfileForm className={USER_PROFILE}>
                  <>
                    <ValueField type="hidden" name={`userProfileSettings.id`} />
                  </>
                  <>
                    <Label>Email</Label>
                    <ValueField
                      type="text"
                      disabled
                      name={`userProfileSettings.email`}
                    />
                  </>
                  <>
                    <Label>Firstname</Label>
                    <ValueField
                      type="text"
                      name={`userProfileSettings.firstname`}
                    />
                  </>
                  <Label>Lastname</Label>
                  <ValueField
                    type="text"
                    name={`userProfileSettings.lastname`}
                  />
                  <DivForSubmitBtn>
                    <UpadateBtn type="submit">Update</UpadateBtn>
                  </DivForSubmitBtn>
                </UserProfileForm>
              )}
            </Formik>
          </Accordion.Body>
        </Accordion.Item>

        {/* ------------user Settings-------- */}
        <Accordion.Item
          key={"3"}
          eventKey={"3"}
          style={{
            display: "block",
            margin: "10px 10px",
            marginBottom: "0",
            borderRadius: "0",
          }}
        >
          <Accordion.Header>User Settings</Accordion.Header>
          <Accordion.Body>
            <MobileUserSettings />
          </Accordion.Body>
        </Accordion.Item>

        {/* ------------user Settings-------- */}
        <Accordion.Item
          key={"4"}
          eventKey={"4"}
          style={{
            display: "block",
            margin: "10px 10px",
            marginBottom: "0",
            borderRadius: "0",
          }}
        >
          <Accordion.Header>Integration</Accordion.Header>
          <Accordion.Body>
            <IntegrationsApps />
          </Accordion.Body>
        </Accordion.Item>

        {/* ------------Delet Account-------- */}
        <Accordion.Item
          key={"5"}
          eventKey={"5"}
          style={{
            display: "block",
            margin: "10px 10px",
            marginBottom: "0",
            borderRadius: "0",
          }}
        >
          <Accordion.Header>Delete Account</Accordion.Header>
          <Accordion.Body>
            <DeleteAccountSection>
              <DeleteHeadingText>Deleting Account</DeleteHeadingText>
              <DeleteParagraphText>
                Deleting user account will remove all of user information from
                app.dropchat.co. This can not be undone. If you need to use the
                service again, you will need to create a new account.
              </DeleteParagraphText>
              {user?.currentSubscription ? (
                user?.currentSubscription.meta?.deleteAccount ? (
                  <DeleteButton onClick={() => deleteConfirmationBox()}>
                    Delete My Account
                  </DeleteButton>
                ) : (
                  <ButtonDiv>
                    <DeleteButton
                      type="button"
                      style={{ opacity: 0.5, cursor: "not-allowed" }}
                    >
                      Delete My Account
                    </DeleteButton>
                    <div style={{ marginTop: "10px" }}>
                      <UpgradeSpan
                        onClick={(e) => {
                          setPlanHighliter({
                            isBookHighliter: false,
                            bookLimit: 0,
                            isDeleteBookHighliter: false,
                            isFlashcardHighliter: false,
                            isCharacterLimit: false,
                            pdfLimit: 0,
                            isDeleteAccount: true,
                          });
                          navigate("/subscription");
                        }}
                      >
                        Upgrade Plan
                      </UpgradeSpan>
                      {/* <PlanSpan>Your plan</PlanSpan> */}
                    </div>
                  </ButtonDiv>
                )
              ) : (
                <div style={{ paddingLeft: "2rem" }}>
                  <UpgradeSpan
                    onClick={(e) => {
                      setPlanHighliter({
                        isBookHighliter: false,
                        bookLimit: 0,
                        isDeleteBookHighliter: false,
                        isFlashcardHighliter: false,
                        isCharacterLimit: false,
                        pdfLimit: 0,
                        isDeleteAccount: true,
                      });
                      navigate("/subscription");
                    }}
                  >
                    Purchase Plan
                  </UpgradeSpan>
                </div>
              )}
            </DeleteAccountSection>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {showUtilityConfirmationBox && (
        <DeleteAccountConfirmationBox
          setShow={setShowUtilityConfirmationBox}
          name="Confirmation"
          message={confirmationBoxMessage}
          okButtonText="Delete"
          action={confirmationAction}
          okButtonClick={confirmClick}
        />
      )}
    </>
  );
};

export default MobileUserProfile;
