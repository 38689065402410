import { useContext } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import BubbleChatLiveIcon from "../../../../images/drop-chat-icon.svg";
import ChatBotIcon from "../../../../images/chat-bot-icon.svg";
import UserImage from "../../../../images/chat-user.svg";
import SearchArrow from "../../../../images/chat-arrow.svg";
import DltrossSvg from "../../../../images/black-cross.svg";
import * as BubbleChatStyles from "./styled";
import { PublicContext } from "../../../../context/PublicContext";
import {
  DEVICE_MEDIUM,
  MEDIUM,
  MOBILE,
  TAB,
} from "../../../../constants/breakpoints";

const Arrow = styled.img`
  height: 25px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-top: 3.5px;
  }
`;

const SampleQuestionPrototype = ({
  sampleQuestions,
  setShowPreviewPopup,
}: any) => {
  const { deviceWidth } = useContext(PublicContext);

  return (
    <div style={{ flexDirection: "column", width: "100%" }}>
      <Col
        xs={12}
        style={{
          justifyContent: "space-between",
          padding: deviceWidth !== DEVICE_MEDIUM ? "0.5rem" : "0",
        }}
      >
        <BubbleChatStyles.HeadingText>
          Sample Question Preview
        </BubbleChatStyles.HeadingText>
        {deviceWidth !== DEVICE_MEDIUM && (
          <Arrow
            src={DltrossSvg}
            alt="dlt"
            onClick={() => {
              setShowPreviewPopup(false);
            }}
          />
        )}
      </Col>
      <BubbleChatStyles.MainContainer sm={12}>
        <BubbleChatStyles.RightColumn>
          <BubbleChatStyles.ChatPageHeader className="App-header">
            <Col sm={1}>
              <img src={BubbleChatLiveIcon} alt="online..." width={30} /> &nbsp;
            </Col>
            <Col
              sm={10}
              style={{
                alignItems: "center",
                cursor: "pointer",
                paddingLeft: "1rem",
              }}
            >
              <b style={{ fontSize: "18px" }}>Powered By Dropchat</b>
            </Col>
          </BubbleChatStyles.ChatPageHeader>
          <BubbleChatStyles.ChatInputBox>
            <BubbleChatStyles.RightContentRow>
              <BubbleChatStyles.ChatBotContent
                sm={12}
                style={{ marginBottom: "1rem" }}
              >
                <BubbleChatStyles.ScrollDiv>
                  <BubbleChatStyles.QuestionAnswerSection
                    style={{ margin: "1rem" }}
                  >
                    {sampleQuestions &&
                      sampleQuestions.length > 0 &&
                      sampleQuestions.map((question: any, index: number) => {
                        return (
                          <BubbleChatStyles.QuestionSection
                            style={{
                              background: "#F8F7FA",
                              marginBottom: "0.8rem",
                            }}
                          >
                            <img width="30" src={UserImage} alt="user_image" />
                            &nbsp;&nbsp;
                            <h5
                              style={{
                                color: "#33303C",
                                fontSize: "16px",
                              }}
                            >
                              {question.question}
                            </h5>
                          </BubbleChatStyles.QuestionSection>
                        );
                      })}
                  </BubbleChatStyles.QuestionAnswerSection>
                </BubbleChatStyles.ScrollDiv>
              </BubbleChatStyles.ChatBotContent>
              <BubbleChatStyles.FieldSection>
                <BubbleChatStyles.FormInput
                  style={{
                    color: "#33303C",
                    border: "2px solid #F2E5DB",
                    fontSize:
                      deviceWidth !== DEVICE_MEDIUM ? "12px" : "inherit",
                  }}
                  // name="prompt"
                  // placeholder={"Ask me anything"}
                >
                  Ask me anything from “Exploring the Deep”
                </BubbleChatStyles.FormInput>
                <BubbleChatStyles.FetchBookButton type="submit">
                  <img width={25} src={SearchArrow} alt="search" />
                </BubbleChatStyles.FetchBookButton>
              </BubbleChatStyles.FieldSection>
            </BubbleChatStyles.RightContentRow>
          </BubbleChatStyles.ChatInputBox>
        </BubbleChatStyles.RightColumn>
      </BubbleChatStyles.MainContainer>
    </div>
  );
};

export default SampleQuestionPrototype;
