import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BookContext } from "../../context/BookContext";
import { UserContext } from "../../context/UserContext";
import { CodeBoxContext } from "../../context/code-box-context";
import { LoaderContext } from "../../context/loader-context";
import { PaginationContext } from "../../context/pagination-context";
import { PlanContext } from "../../context/plan-context";
import {
  USER_CREATED_A_SHARE_URL_CHATBOT,
  USER_DELETE_A_BOOK,
} from "../../customer-io/events";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import myAxios from "../../myaxios";
import { Book, manageChatbotSettingAll } from "../../types";
import { MANAGE_CHATBOT } from "../../utils/class-names";
import {
  BUBBLE_CHATBOT_WELCOME_MESSAGE,
  CHATBOT_GREETING_TEXT,
  CHATBOT_WAIVER_TEXT,
  CUSTOM_ERROR_RESP,
  QA_PROMPT,
} from "../../utils/place-holder-consts";
import {
  checkSettingsChanges,
  isTestUser,
  parseErrorResponse,
} from "../../utils/utils";
import CodeBox from "../utility-component/code-box";
import ConfirmationBox from "../utility-component/confirmation-box";
import ChatbotEmailSettings from "./components/chat-email-setting";
import ChatInterfaceSettings from "./components/chat-interface-settings";
import ChatLive from "./components/chat-live";
import ChatbotSetting from "./components/chatbot-setting";
import ChatBotWaiverSetting from "./components/chatbot-waiver";
import CustomGreetingText from "./components/custom-greeting-text";
import EscalateEmail from "./components/escalate-email";
import ExternalIndexingApi from "./components/external-indexing-api";
import IntegrationAppsSetting from "./components/integration-apps-setting";
import MarkChatbotAsFeatureChatbot from "./components/mark-feature-chatbot";
import SocialMediaSettings from "./components/social-media-settings";
import SummarizedAnswer from "./components/summarized-answer";
import DiscordIntegrationAppsSetting from "./components/discord-integration-app-settings";
import CustomBubbleChatMessage from "./components/custom-bubble-chat-message";
import ChatbotHeaderTitle from "./components/chatbot-header-title";
import FooterBrandingSection from "./components/footer-branding-section";
import LockIcon from "../../images/lock-icon.svg";
import { TeamContext } from "../../context/team-context";
import MarkChatbotForViewAll from "./components/mark-view-all-chatbot";
import MarkChatbotAsViewAll from "./components/mark-view-all-chatbot";

// import GetShopifyProducts from "./components/get-shopify-products";
declare var _cio: any;

const ParentSection = styled.div`
  padding: 1rem;
  width: 100%;
`;
const SidebarHeading = styled.h3`
  color: #33303c;
  font-size: 16px;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const MenuHeading = styled.h3`
  color: #33303c;
  font-weight: 600;
  font-size: 14px;
  margin-top: 0.5rem;
`;
const MenuMainHeading = styled.h3`
  color: #33303c;
  font-weight: 600;
  font-size: 16px;
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 1rem;
`;

const ParentInnerSection = styled.div`
  position: relative;
`;

const InnerSection = styled.div`
  background: #fff;
  padding: 1rem;
  flex-direction: column;
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

const BaseButton = styled.button`
  background: #1585b5;
  padding: 0.3rem 0.1rem;
  color: #fff;
  border: 0;
  text-align: center;
  border-radius: 4px;
`;

const BlueButton = styled(BaseButton)`
  width: 100%;
  &:hover {
    background: #0b5f82;
  }
`;

const RedButton = styled(BaseButton)`
  background: #ec2d2d;
  width: 40%;
  &:hover {
    background: #b52020;
  }
`;

const UpgradeSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 5px 25px;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
`;

const SaveButton = styled(BaseButton)`
  padding: 0.4rem 3rem;
  width: 25% !important;
  border-radius: 8px;
  &:hover {
    background: #0b5f82;
  }
`;

const ChatbotSettingsSection = styled(Form)`
  margin-bottom: 20px;
`;

const OverlayRestriction = styled.div`
  background: rgba(51, 48, 60, 0.4);
  position: absolute;
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  height: 100%;
  justify-content: flex-end;
  padding: 10px;
`;
const UpgradeContent = styled.span`
  color: #f2ae01;
  font-size: 16px;
  background: #fff;
  padding: 0.2rem 1rem;
  height: max-content;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
`;

const ManageChatbot = ({
  bookSettings,
  setBookSettings,
  selectedBook,
  setSelectTabName,
}: any) => {
  const navigate = useNavigate();

  //Context's
  const { setShowLoader } = useContext(LoaderContext);
  const {
    user,
    setTotalBooksCount,
    updateUserSubscriptionIndexCount,
    integrationAppList,
    // setIntegrationAppsList,
  } = useContext(UserContext);
  const { setBooks, setNoOfBots, updateBookTitle } = useContext(BookContext);
  const { selectedTeam } = useContext(TeamContext);
  const { setPlanHighliter } = useContext(PlanContext);
  const { pageLimit, pageSkip } = useContext(PaginationContext);
  const { setBookId, setShowConfirmationBox, setShowIframeCodeBox } =
    useContext(CodeBoxContext);

  //State's
  const [confirmationAction, setConfirmationAction] = useState<string>("");
  const [file, setSelectedFile] = useState<any>(null);
  const [setIsSmallImage] = useState(false);
  const [isTokenVerified, setIsTokenVerified] = useState<boolean>(false);

  useEffect(() => {
    if (!integrationAppList.discord) {
      setIsTokenVerified(true);
    } else {
      setIsTokenVerified(false);
    }
    // eslint-disable-next-line
  }, []);

  const [manageChatbotSettingAll, setManageChatbotSettingAll] =
    useState<manageChatbotSettingAll>({
      greetingSetting: {
        message: CHATBOT_GREETING_TEXT,
        isGreeting: false,
      },
      bubbleIconMessageSettings: {
        message: BUBBLE_CHATBOT_WELCOME_MESSAGE,
        isMessage: false,
      },
      isFeatureChatbot: false,
      canTeamMembersView: false,
      waiverSetting: {
        waiverText: CHATBOT_WAIVER_TEXT,
        isWaiver: false,
      },
      interfaceSetting: {
        interfaceType: "chat",
      },
      emailSetting: {
        isSendEmail: false,
        toEmails: [],
        bccEmails: [],
      },
      escalateEmailSetting: {
        isEscalateEmail: false,
        toEmails: [],
      },
      chatWithHumanSetting: {
        isChatLive: false,
        redirectLink: "",
      },
      seoMetaSetting: {
        seoMetaSettings: false,
        socialMediaTitle: "",
        socialMediaDescription: "",
        socialMediaCustomImage: "",
      },
      integrationApp: {
        isHubspot: false,
        isZendesk: false,
        isShopify: false,
        isDiscord: false,
        discordBotToken: "",
      },

      apiConfiguration: {
        isApiConfiguration: false,
        methodType: "Post",
        url: "",
        header: "",
        body: "",
        isAfternoon: false,
        isMidNight: false,
      },
    });

  const [tempretureValue, setTempretureValue] = useState<number>(
    bookSettings && bookSettings.temperature ? bookSettings.temperature : 0.5
  );
  const [topPValue, setTopPValue] = useState<number>(
    bookSettings && bookSettings.topP ? bookSettings.topP : 0.5
  );

  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);

  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");

  // UseEffect

  useEffect(() => {
    if (selectedBook) {
      setShowLoader(true);
      myAxios
        .get(`${process.env.REACT_APP_SERVER_URL}/chatbot/${selectedBook._id}`)
        .then((res) => {
          if (res.data.data) {
            setManageChatbotSettingAll((prevSettings) => ({
              ...prevSettings,
              ...res.data.data,
              emailSetting: {
                ...res.data.data.emailSetting,
                toEmails: (res.data.data.emailSetting?.toEmails || []).map(
                  (email: any) => ({
                    id: email,
                    text: email,
                  })
                ),
                bccEmails: (res.data.data.emailSetting?.bccEmails || []).map(
                  (email: any) => ({
                    id: email,
                    text: email,
                  })
                ),
              },
              escalateEmailSetting: {
                ...res.data.data.escalateEmailSetting,
                toEmails: (
                  res.data.data.escalateEmailSetting?.toEmails || []
                ).map((email: any) => ({
                  id: email,
                  text: email,
                })),
              },
            }));

            setShowLoader(false);
          }
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
    // eslint-disable-next-line
  }, [selectedBook]);

  //Function
  const isURLValid = (url: string) => {
    const urlRegex =
      /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;%=]*)?$/i;

    return urlRegex.test(url);
  };

  const validateChatbotSettings = (values: any) => {
    const errors: any = {};

    if (values.chatWithHumanSetting.isChatLive) {
      if (values.chatWithHumanSetting.redirectLink.length < 1) {
        errors.chatWithHumanSetting = "URL is required";
      }

      // Check if the input value is a valid URL
      if (!isURLValid(values.chatWithHumanSetting.redirectLink)) {
        errors.chatWithHumanSetting = "Invalid URL";
      }
    }
    if (values.emailSetting.isSendEmail) {
      if (values.emailSetting.toEmails < 1) {
        errors.toEmails = "Please Add toEmail";
      }

      // Check if the input value is a valid URL
      // if (values.emailSetting.bccEmails < 1) {
      //   errors.bccEmails = "Please Add bccEmail";
      // }
    }
    if (values.escalateEmailSetting.isEscalateEmail) {
      if (values.escalateEmailSetting.toEmails < 1) {
        errors.toEscalateEmails = "Please Add Email";
      } else {
        let restrict = "zendesk";

        for (let i = 0; i < values.escalateEmailSetting.toEmails.length; i++) {
          if (values.escalateEmailSetting.toEmails[i].text.includes(restrict)) {
            errors.toEscalateEmails = "Zendesk emails are not allowed!";
            break;
          }
        }
      }
    }

    if (
      values.apiConfiguration.isApiConfiguration &&
      !values.apiConfiguration.url
    ) {
      errors.url = "URL field required";
    }
    // if (values.apiConfiguration.isApiConfiguration) {
    //   if (
    //     !values.apiConfiguration.isAfternoon &&
    //     !values.apiConfiguration.isMidNight
    //   ) {
    //     errors.isAfternoon = "Select at least one option";
    //   }
    // }
    if (
      values.apiConfiguration.isApiConfiguration &&
      !values.apiConfiguration.header
    ) {
      errors.header = "Header field required";
    } else {
      if (values.apiConfiguration.isApiConfiguration) {
        try {
          let cleanHeader = values.apiConfiguration.header.replace(
            /[^\x20-\x7E]/g,
            ""
          );
          JSON.parse(cleanHeader);
        } catch (error) {
          errors.header = "Please add valid JSON";
        }
      }
    }

    if (values.integrationApp.isDiscord) {
      if (
        !values.integrationApp.discordBotToken ||
        values.integrationApp.discordBotToken < 1
      ) {
        errors.discordBotToken = "Please add token";
      }
    }

    console.log("error are, ", errors);

    return errors;
  };

  const deleteBook = async (book: Book) => {
    let dataToDelete = {
      bookID: book._id,
      userID: user?.id,
    };
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/delete-book", {
        dataToDelete,
      })
      .then((response) => {
        if (response.data.success) {
          if (!isTestUser(user?.email))
            _cio.track(USER_DELETE_A_BOOK, {
              name: "user-delete-a-book",
            });
          NotificationManager.success(response.data.message);

          myAxios
            .post(process.env.REACT_APP_SERVER_URL + "/books/paginated-books", {
              pagination: { limit: pageLimit, skip: pageSkip },
              team: selectedTeam?._id,
            })
            .then((response) => {
              setBooks(response.data.data.books);
              setTotalBooksCount(response.data.data.count);
              setNoOfBots(response.data.data.count);

              if (book.type === "url") {
                const totalCharacterCount = user?.currentSubscription
                  ?.indexCharacterUsedTotal
                  ? user?.currentSubscription?.indexCharacterUsedTotal
                  : 0;

                const totalChatBotUsedCharacterCount =
                  selectedBook.sources.reduce(
                    (total: any, source: { totalCharacterCount: any }) =>
                      total + source.totalCharacterCount,
                    0
                  );

                updateUserSubscriptionIndexCount(
                  totalCharacterCount - totalChatBotUsedCharacterCount
                );
              }
              // setShowLoader(false);
            })
            .catch((error) => {
              // console.log("Getting Error at the time of get paginated books ", error);
              // setShowLoader(false);
            });

          setShowLoader(false);
          navigate("/my-chatbots/create-pdf-chatbot");
        } else {
          NotificationManager.error(response.data.message);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
        setShowLoader(false);
        // console.error("Getting Error at the time of delete chatbot ", error);
      });
  };

  const deleteConfirmationBox = () => {
    setConfirmationBoxMessage("Do you really want to delete this chatbot?");
    setConfirmationAction("delete");
    setShowUtilityConfirmationBox(true);
  };

  const confirmClick = (action: string) => {
    if (action === "delete") {
      trackEvent({
        email: getUsersEmail(user),
        eventCategory: EventCategory.CHATBOT,
        event: ChatBotEvents.USER_DELETE_CHATBOT,
        subCategory: SubCategory.EVENT,
        chatbotType: selectedBook.fileType,
        bookId: selectedBook._id,
        rawData: {
          bookId: selectedBook._id,
          title: selectedBook.title,
        },
      });
      deleteBook(selectedBook);
    }
  };

  const [additionalInitialValues, setAdditionalInitialValues] = useState({
    bookId: "",
    bookSetting: {
      model: "gpt-4o",
      customAnswer: CUSTOM_ERROR_RESP,
      title: "",
      qaTemplate: QA_PROMPT,
      temperature: 0.5,
      topP: 0.5,
      allowMemory: false,
      isSummarized: false,
      chatbotHeaderTitle: "",
      footerBrandingText: "",
      footerBrandingLink: "",
    },
    isFeatureChatbot:
      manageChatbotSettingAll && manageChatbotSettingAll.isFeatureChatbot,
    canTeamMembersView:
      manageChatbotSettingAll && manageChatbotSettingAll.canTeamMembersView,
    chatInterfaceType: manageChatbotSettingAll.interfaceSetting.interfaceType,
    greetingSetting: {
      message: manageChatbotSettingAll.greetingSetting.message,
      isGreeting: manageChatbotSettingAll.greetingSetting.isGreeting,
    },
    bubbleIconMessageSettings: {
      message: manageChatbotSettingAll.bubbleIconMessageSettings.message,
      isMessage: manageChatbotSettingAll.bubbleIconMessageSettings.isMessage,
    },
    waiverSetting: {
      isWaiver: manageChatbotSettingAll.waiverSetting.isWaiver,
      waiverText: manageChatbotSettingAll.waiverSetting.waiverText,
    },
    emailSetting: {
      isSendEmail: manageChatbotSettingAll.emailSetting.isSendEmail,
      toEmails: manageChatbotSettingAll.emailSetting.toEmails,
      bccEmails: manageChatbotSettingAll.emailSetting.bccEmails,
    },
    escalateEmailSetting: {
      isEscalateEmail:
        manageChatbotSettingAll.escalateEmailSetting.isEscalateEmail,
      toEmails: manageChatbotSettingAll.escalateEmailSetting.toEmails,
    },
    chatWithHumanSetting: {
      isChatLive: manageChatbotSettingAll.chatWithHumanSetting.isChatLive,
      redirectLink: manageChatbotSettingAll.chatWithHumanSetting.redirectLink,
    },
    seoMetaSetting: {
      seoMetaSettings: manageChatbotSettingAll.seoMetaSetting.seoMetaSettings,
      socialMediaTitle: manageChatbotSettingAll.seoMetaSetting.socialMediaTitle,
      socialMediaDescription:
        manageChatbotSettingAll.seoMetaSetting.socialMediaDescription,
      socialMediaCustomImage:
        manageChatbotSettingAll.seoMetaSetting.socialMediaCustomImage,
    },
    integrationApp: {
      isHubspot: manageChatbotSettingAll.integrationApp.isHubspot,
      isZendesk: manageChatbotSettingAll.integrationApp.isZendesk,
      isShopify: manageChatbotSettingAll.integrationApp.isShopify,
      isDiscord: manageChatbotSettingAll.integrationApp.isDiscord,
      discordBotToken: manageChatbotSettingAll.integrationApp.discordBotToken,
    },

    apiConfiguration: {
      isApiConfiguration:
        manageChatbotSettingAll.apiConfiguration.isApiConfiguration,
      methodType: manageChatbotSettingAll.apiConfiguration.methodType,
      url: manageChatbotSettingAll.apiConfiguration.url,
      header: manageChatbotSettingAll.apiConfiguration.header,
      body: manageChatbotSettingAll.apiConfiguration.body,
      isAfternoon: manageChatbotSettingAll.apiConfiguration.isAfternoon,
      isMidNight: manageChatbotSettingAll.apiConfiguration.isMidNight,
    },
  });

  useEffect(() => {
    setAdditionalInitialValues({
      bookId: selectedBook && selectedBook._id,
      bookSetting: {
        model:
          bookSettings && bookSettings.model ? bookSettings.model : "gpt-4o",
        customAnswer:
          bookSettings && bookSettings.customAnswer
            ? bookSettings.customAnswer
            : CUSTOM_ERROR_RESP,
        title: selectedBook && selectedBook.title ? selectedBook.title : "",
        qaTemplate:
          bookSettings &&
            bookSettings.qaTemplate &&
            bookSettings.qaTemplate.length > 0
            ? bookSettings.qaTemplate
            : QA_PROMPT,
        temperature:
          bookSettings && bookSettings.temperature
            ? bookSettings.temperature
            : 0.5,
        topP: bookSettings && bookSettings.topP ? bookSettings.topP : 0.5,
        allowMemory: bookSettings && bookSettings.allowMemory,
        isSummarized:
          bookSettings && bookSettings.isSummarized
            ? bookSettings.isSummarized
            : false,
        chatbotHeaderTitle:
          bookSettings && bookSettings.chatbotHeaderTitle
            ? bookSettings.chatbotHeaderTitle
            : "Welcome to Dropchat",
        footerBrandingText:
          bookSettings && bookSettings.footerBrandingText
            ? bookSettings.footerBrandingText
            : "Powered By Dropchat",
        footerBrandingLink:
          bookSettings && bookSettings.footerBrandingLink
            ? bookSettings.footerBrandingLink
            : "https://dropchat.co/",
      },
      isFeatureChatbot:
        manageChatbotSettingAll && manageChatbotSettingAll.isFeatureChatbot,
      canTeamMembersView:
        manageChatbotSettingAll && manageChatbotSettingAll.canTeamMembersView,
      chatInterfaceType: manageChatbotSettingAll.interfaceSetting.interfaceType,
      greetingSetting: {
        message: manageChatbotSettingAll.greetingSetting.message,
        isGreeting: manageChatbotSettingAll.greetingSetting.isGreeting,
      },
      bubbleIconMessageSettings: {
        message: manageChatbotSettingAll.bubbleIconMessageSettings.message,
        isMessage: manageChatbotSettingAll.bubbleIconMessageSettings.isMessage,
      },
      waiverSetting: {
        isWaiver: manageChatbotSettingAll.waiverSetting.isWaiver,
        waiverText: manageChatbotSettingAll.waiverSetting.waiverText,
      },
      emailSetting: {
        isSendEmail: manageChatbotSettingAll.emailSetting.isSendEmail,
        toEmails: manageChatbotSettingAll.emailSetting.toEmails,
        bccEmails: manageChatbotSettingAll.emailSetting.bccEmails,
      },
      escalateEmailSetting: {
        isEscalateEmail:
          manageChatbotSettingAll.escalateEmailSetting.isEscalateEmail,
        toEmails: manageChatbotSettingAll.escalateEmailSetting.toEmails,
      },
      chatWithHumanSetting: {
        isChatLive: manageChatbotSettingAll.chatWithHumanSetting.isChatLive,
        redirectLink: manageChatbotSettingAll.chatWithHumanSetting.redirectLink,
      },
      seoMetaSetting: {
        seoMetaSettings: manageChatbotSettingAll.seoMetaSetting.seoMetaSettings,
        socialMediaTitle:
          manageChatbotSettingAll.seoMetaSetting.socialMediaTitle,
        socialMediaDescription:
          manageChatbotSettingAll.seoMetaSetting.socialMediaDescription,
        socialMediaCustomImage:
          manageChatbotSettingAll.seoMetaSetting.socialMediaCustomImage,
      },
      integrationApp: {
        isHubspot: manageChatbotSettingAll.integrationApp.isHubspot,
        isZendesk: manageChatbotSettingAll.integrationApp.isZendesk,
        isShopify: manageChatbotSettingAll.integrationApp.isShopify,
        isDiscord: manageChatbotSettingAll.integrationApp.isDiscord,
        discordBotToken: manageChatbotSettingAll.integrationApp.discordBotToken,
      },

      apiConfiguration: {
        isApiConfiguration:
          manageChatbotSettingAll.apiConfiguration.isApiConfiguration,
        methodType: manageChatbotSettingAll.apiConfiguration.methodType,
        url:
          manageChatbotSettingAll.apiConfiguration.url ||
          "api.bidacar.com/vehicles-auction",
        header:
          manageChatbotSettingAll.apiConfiguration.header ||
          `[
              {
                "key":"Content-Type",
                "value":"application/json"
              },
              {
              "key":"x-access-token",
              "value":"wj!gxJPC3Q$lZjsfn67jJcClJoP8GZ"
              }
           ]
     `,
        body: manageChatbotSettingAll.apiConfiguration.body,
        isAfternoon: manageChatbotSettingAll.apiConfiguration.isAfternoon,
        isMidNight: manageChatbotSettingAll.apiConfiguration.isMidNight,
      },
    });
    // eslint-disable-next-line
  }, [selectedBook, manageChatbotSettingAll, setManageChatbotSettingAll]);

  return (
    <ParentSection>
      <Formik
        initialValues={additionalInitialValues}
        validate={validateChatbotSettings}
        enableReinitialize
        onSubmit={(values) => {
          // console.log("Do you have ford f-150 is, ");
          setShowLoader(true);
          let toEmailsData: any = [];
          values.emailSetting.toEmails.map((email: any) => {
            return toEmailsData.push(email.text);
          });
          let bccEmailsData: any = [];
          values.emailSetting.bccEmails.map((email: any) => {
            return bccEmailsData.push(email.text);
          });

          let toEscalateEmailsData: any = [];
          values.escalateEmailSetting.toEmails.map((email: any) => {
            return toEscalateEmailsData.push(email.text);
          });
          let dataToSave = {
            ...values,
            emailSetting: {
              ...values.emailSetting,
              toEmails: toEmailsData,
              bccEmails: bccEmailsData,
            },
            escalateEmailSetting: {
              ...values.escalateEmailSetting,
              toEmails: toEscalateEmailsData,
            },
          };
          let createSocialDetails;
          if (file) {
            const newData = new FormData();
            newData.append("files.image", file);
            newData.append("data", JSON.stringify(dataToSave));
            createSocialDetails = newData;
          } else {
            createSocialDetails = dataToSave;
          }
          myAxios
            .post(
              process.env.REACT_APP_SERVER_URL + "/chatbot/manage-setting",
              createSocialDetails
            )
            .then((response) => {
              // console.log("response is , ", response.data);
              if (response.data && response.data.success) {
                setShowLoader(false);
                setBookSettings(response.data.data.saveBookSettings);
                updateBookTitle(selectedBook._id, values.bookSetting.title);
                NotificationManager.success(
                  response.data.message
                    ? response.data.message
                    : "Settings saved successfully."
                );
                myAxios
                  .get(
                    `${process.env.REACT_APP_SERVER_URL}/book-settings/get-book-settings/${selectedBook._id}`
                  )
                  .then((res) => {
                    // console.log("res.data.data ---", res.data.data);
                    if (res.data.data) {
                      // updateUserSubscription(res.data.data.currentSubscription);
                      setBookSettings(res.data.data);
                    }
                  });
              } else {
                setShowLoader(false);
                NotificationManager.error(
                  response.data.message
                    ? response.data.message
                    : "Unable to process your request, please try later"
                );
              }
            })
            .catch((error) => {
              // handle error
              setShowLoader(false);
              NotificationManager.error(parseErrorResponse(error));
            });
          const updatedSettings = checkSettingsChanges(
            additionalInitialValues,
            values
          );
          if (Object.keys(updatedSettings).length > 0) {
            for (const settingKey in updatedSettings) {
              if (updatedSettings.hasOwnProperty(settingKey)) {
                trackEvent({
                  email: getUsersEmail(user),
                  eventCategory: EventCategory.CHATBOT,
                  event:
                    settingKey === "title"
                      ? ChatBotEvents.USER_UPDATED_CUSTOM_TITLE_FROM_MANAGE_CHATBOT
                      : settingKey === "model"
                        ? ChatBotEvents.USER_UPDATED_CHAT_MODEL_FROM_MANAGE_CHATBOT
                        : settingKey === "temperature"
                          ? ChatBotEvents.USER_UPDATED_TEMPERATURE_FROM_MANAGE_CHATBOT
                          : settingKey === "topP"
                            ? ChatBotEvents.USER_UPDATED_TOP_P_FROM_MANAGE_CHATBOT
                            : settingKey === "allowMemory"
                              ? ChatBotEvents.USER_UPDATED_ALLOW_CHATBOT_MEMORY_FROM_MANAGE_CHATBOT
                              : settingKey === "qaTemplate"
                                ? ChatBotEvents.USER_UPDATED_CUSTOM_PROMPT_FROM_MANAGE_CHATBOT
                                : settingKey === "customAnswer"
                                  ? ChatBotEvents.USER_UPDATED_CUSTOM_ERROR_RESPONSE_FROM_MANAGE_CHATBOT
                                  : settingKey === "isSummarized"
                                    ? ChatBotEvents.USER_UPDATED_MANAGE_ANSWER_LENGTH_SETTINGS_FROM_MANAGE_CHATBOT
                                    : settingKey === "seoMetaSetting"
                                      ? ChatBotEvents.USER_UPDATED_SOCIAL_MEDIA_SETTINGS_FROM_MANAGE_CHATBOT
                                      : settingKey === "chatInterfaceType"
                                        ? ChatBotEvents.USER_UPDATED_CHATBOT_INTERFACE_SETTINGS_FROM_MANAGE_CHATBOT
                                        : settingKey === "greetingSetting"
                                          ? ChatBotEvents.USER_UPDATED_GREETING_MESSAGE_SETTINGS_FROM_MANAGE_CHATBOT
                                          : settingKey === "waiverSetting"
                                            ? ChatBotEvents.USER_UPDATED_CHATBOT_WAVIER_SETTINGS_FROM_MANAGE_CHATBOT
                                            : settingKey === "isFeatureChatbot"
                                              ? ChatBotEvents.USER_UPDATED_MARK_AS_FEATURE_CHATBOT_FROM_MANAGE_CHATBOT
                                              : settingKey === "emailSetting"
                                                ? ChatBotEvents.USER_UPDATED_EMAIL_SETTINGS_FROM_MANAGE_CHATBOT
                                                : settingKey === "escalateEmailSetting"
                                                  ? ChatBotEvents.USER_UPDATED_ESCALATE_TO_EMAIL_SETTINGS_FROM_MANAGE_CHATBOT
                                                  : settingKey === "chatWithHumanSetting"
                                                    ? ChatBotEvents.USER_UPDATED_CHAT_WITH_HUMAN_LIVE_SETTINGS_FROM_MANAGE_CHATBOT
                                                    : "",
                  subCategory: SubCategory.EVENT,
                  chatbotType: selectedBook.fileType,
                  bookId: selectedBook._id,
                  rawData: updatedSettings[settingKey],
                });
              }
            }
          }
        }}
      >
        {() => (
          <ChatbotSettingsSection className={MANAGE_CHATBOT}>
            <Row>
              <Col
                sm={4}
                style={{ padding: "0.5rem", flexDirection: "column" }}
              >
                {selectedBook?.type === "google" ||
                  selectedBook?.chatType === "chatgpt" ||
                  !user?.currentSubscription?.meta.APIConfiguration ? null : (
                  <>
                    <InnerSection>
                      <ExternalIndexingApi
                        setSelectTabName={setSelectTabName}
                      />
                    </InnerSection>
                  </>
                )}
                <InnerSection>
                  <ChatbotSetting
                    bookSettings={bookSettings}
                    selectedBook={selectedBook}
                    tempretureValue={tempretureValue}
                    setTempretureValue={setTempretureValue}
                    topPValue={topPValue}
                    setTopPValue={setTopPValue}
                  />
                </InnerSection>
              </Col>

              <Col
                sm={4}
                style={{ padding: "0.5rem", flexDirection: "column" }}
              >
                <InnerSection>
                  <Row style={{ alignItems: "center" }}>
                    <Col sm={8}>
                      <Row style={{ alignItems: "center" }}>
                        <MenuMainHeading>Chatbot ID</MenuMainHeading>
                        <SubHeading>
                          Copy the chatbot id for the external usage. (ex.
                          wordpress dropchat plugin etc.)
                        </SubHeading>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <BlueButton
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(selectedBook._id);
                          NotificationManager.success("Chatbot ID copied.");
                        }}
                      >
                        Copy ID
                      </BlueButton>
                    </Col>
                  </Row>
                </InnerSection>

                <InnerSection>
                  <SidebarHeading>Share Chatbot</SidebarHeading>
                  <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
                    <Col sm={12} style={{ flexDirection: "column" }}>
                      <Row
                        style={{ alignItems: "center", marginBottom: ".5rem" }}
                      >
                        <Col sm={8}>
                          <MenuHeading>Embed on website</MenuHeading>
                        </Col>
                        <Col sm={4}>
                          <BlueButton
                            type="button"
                            onClick={() => {
                              setBookId(selectedBook._id);
                              setShowConfirmationBox(true);
                              setShowIframeCodeBox(true);
                            }}
                          >
                            Embed
                          </BlueButton>
                        </Col>
                      </Row>
                      <Row>
                        <SubHeading>
                          Integrate a chatbot into a website using one of two
                          distinct methods. The first method involves
                          implementing a chat bubble, which will appear in the
                          lower right corner of the website. The second method
                          requires using iframe code, enabling the chatbot to be
                          directly displayed within the webpage itself.
                        </SubHeading>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ alignItems: "center" }}>
                    <Col sm={12} style={{ flexDirection: "column" }}>
                      <Row
                        style={{ alignItems: "center", marginBottom: ".5rem" }}
                      >
                        <Col sm={8}>
                          <MenuHeading>Shared URL</MenuHeading>
                        </Col>
                        <Col sm={4}>
                          <CopyToClipboard
                            text={`${process.env.REACT_APP_SERVER_URL}/public/shared-chat/${selectedBook && selectedBook?._id}`}
                            onCopy={(e) => {
                              NotificationManager.success(
                                "Chatbot URL copied."
                              );
                              trackEvent({
                                email: getUsersEmail(user),
                                eventCategory: EventCategory.CHATBOT,
                                event:
                                  ChatBotEvents.USER_COPIED_SHARE_URL_FROM_MANAGE_CHATBOT,
                                subCategory: SubCategory.EVENT,
                                chatbotType: selectedBook.fileType,
                                bookId: selectedBook._id,
                                description: "Chatbot URL copied.",
                              });
                              if (!isTestUser(user?.email))
                                _cio.track(USER_CREATED_A_SHARE_URL_CHATBOT, {
                                  name: "user-created-a-share-url-chatbot",
                                });
                              try {
                                // op.current.toggle(e);
                              } catch (error) { }
                            }}
                          >
                            <BlueButton type="button">Copy URL</BlueButton>
                          </CopyToClipboard>
                        </Col>
                      </Row>
                      <SubHeading>
                        Create a shareable public URL for your chatbot, allowing
                        others to access it through the link.
                      </SubHeading>
                    </Col>
                  </Row>
                  <InnerSection>
                    <Row>
                      <SummarizedAnswer />
                    </Row>
                  </InnerSection>
                  <InnerSection>
                    <Row>
                      <SocialMediaSettings
                        setSelectedFile={setSelectedFile}
                        setIsSmallImage={setIsSmallImage}
                      />
                    </Row>
                  </InnerSection>
                </InnerSection>

                <InnerSection>
                  <Row>
                    <ChatInterfaceSettings />
                  </Row>
                </InnerSection>
                <InnerSection>
                  <Row>
                    <CustomGreetingText />
                  </Row>
                </InnerSection>
                <InnerSection>
                  <ChatBotWaiverSetting />
                </InnerSection>

                <InnerSection>
                  <ChatbotHeaderTitle />
                </InnerSection>

                <ParentInnerSection>
                  {user?.currentSubscription &&
                    !user?.currentSubscription.meta?.removeDropChatBranding && (
                      <OverlayRestriction>
                        <UpgradeContent
                          onClick={(e) => {
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: false,
                              isFlashcardHighliter: false,
                              isCharacterLimit: false,
                              pdfLimit: 0,
                              isDeleteAccount: false,
                              isLeadLoginSettings: false,
                              isRemoveDropChatBrandingHighliter: true,
                            });
                            navigate("/subscription");
                          }}
                        >
                          <img
                            src={LockIcon}
                            width={20}
                            alt="lock-icon"
                            style={{ marginRight: "5px" }}
                          />{" "}
                          Upgrade Required
                        </UpgradeContent>
                      </OverlayRestriction>
                    )}
                  <InnerSection>
                    <FooterBrandingSection />
                  </InnerSection>
                </ParentInnerSection>
              </Col>
              <Col
                sm={4}
                style={{ padding: "0.5rem", flexDirection: "column" }}
              >
                {user && user.role === "super_admin" && (
                  <>
                    <InnerSection>
                      <MarkChatbotAsFeatureChatbot />
                    </InnerSection>
                  </>
                )}
                <InnerSection>
                  <MarkChatbotAsViewAll />
                </InnerSection>
                <InnerSection>
                  <Row>
                    <CustomBubbleChatMessage />
                  </Row>
                </InnerSection>
                <InnerSection>
                  <ChatbotEmailSettings />
                </InnerSection>
                <InnerSection>
                  <EscalateEmail
                    setManageChatbotSettingAll={setManageChatbotSettingAll}
                  />
                </InnerSection>
                <InnerSection>
                  <IntegrationAppsSetting />
                </InnerSection>
                {integrationAppList.discord && (
                  <InnerSection>
                    <DiscordIntegrationAppsSetting
                      additionalInitialValues={additionalInitialValues}
                      isTokenVerified={isTokenVerified}
                      setIsTokenVerified={setIsTokenVerified}
                    />
                  </InnerSection>
                )}
                <InnerSection>
                  <ChatLive />
                </InnerSection>
                <InnerSection>
                  <SidebarHeading>Delete chatbot</SidebarHeading>
                  <SubHeading>
                    Do you really want to delete chatbot. It includes all
                    coversations and all data sources deleted permanently.
                  </SubHeading>
                  {user?.currentSubscription ? (
                    user?.currentSubscription.meta?.deleteChat ? (
                      <RedButton
                        type="button"
                        onClick={(e) => {
                          // op.current.toggle(e);
                          deleteConfirmationBox();
                        }}
                      >
                        Delete Chatbot
                      </RedButton>
                    ) : (
                      <Col>
                        <RedButton
                          type="button"
                          style={{ opacity: 0.5, cursor: "not-allowed" }}
                        >
                          Delete Chatbot
                        </RedButton>
                        <div style={{ paddingLeft: "2rem" }}>
                          <UpgradeSpan
                            onClick={(e) => {
                              setPlanHighliter({
                                isBookHighliter: false,
                                bookLimit: 0,
                                isDeleteBookHighliter: true,
                                isFlashcardHighliter: false,
                                isCharacterLimit: false,
                                pdfLimit: 0,
                              });
                              navigate("/subscription");
                            }}
                          >
                            Upgrade Plan
                          </UpgradeSpan>
                          {/* <PlanSpan>Your plan</PlanSpan> */}
                        </div>
                      </Col>
                    )
                  ) : (
                    <Col>
                      <RedButton
                        type="button"
                        style={{ opacity: 0.5, cursor: "not-allowed" }}
                      >
                        Delete Chatbot
                      </RedButton>
                      <div style={{ paddingLeft: "2rem" }}>
                        <UpgradeSpan
                          onClick={(e) => {
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: true,
                              isFlashcardHighliter: false,
                              isCharacterLimit: false,
                              pdfLimit: 0,
                            });
                            navigate("/subscription");
                          }}
                        >
                          Purchase Plan
                        </UpgradeSpan>
                        {/* <PlanSpan>Your plan</PlanSpan> */}
                      </div>
                    </Col>
                  )}
                </InnerSection>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0.5rem ",
                background: "white",
                alignItems: "end",
                position: "fixed",
                bottom: 0,
                right: 0,
                width: "100%",
              }}
            >
              <SaveButton type="submit" disabled={!isTokenVerified}>
                SAVE
              </SaveButton>
            </Row>
          </ChatbotSettingsSection>
        )}
      </Formik>

      {showUtilityConfirmationBox && (
        <ConfirmationBox
          setShow={setShowUtilityConfirmationBox}
          name="Confirmation"
          message={confirmationBoxMessage}
          okButtonText="Yes"
          messageDesc={`All conversations and all data sources will be permanently deleted.`}
          action={confirmationAction}
          okButtonClick={confirmClick}
        />
      )}
      <CodeBox />
    </ParentSection>
  );
};

export default ManageChatbot;
