import { useContext } from "react";
import { Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import ScrollToBottom from "react-scroll-to-bottom";
import BubbleChatLiveIcon from "../../../../images/drop-chat-icon.svg";
import ChatBotIcon from "../../../../images/chat-bot-icon.svg";
import UserImage from "../../../../images/chat-user.svg";
import SearchArrow from "../../../../images/chat-arrow.svg";
import DltrossSvg from "../../../../images/black-cross.svg";
import * as BubbleChatStyles from "./styled";
import { PublicContext } from "../../../../context/PublicContext";
import {
  DEVICE_MEDIUM,
  MOBILE,
  TAB,
  MEDIUM,
} from "../../../../constants/breakpoints";
import styled from "styled-components";

const Arrow = styled.img`
  height: 25px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 3.5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-top: 3.5px;
  }
`;

const ScrollDiv = styled(ScrollToBottom)`
  height: ${isMobile ? "210px" : "320px"};
  width: 100%;
  flex-direction: column;
`;

const ThemeBubbleChatProtoType = ({
  titleBackgroundColor,
  questionBackgroundColor,
  questionTextColor,
  answerBackgroundColor,
  answerTextColor,
  questionTextFieldColor,
  source,
  setShowPreviewPopup,
  showEmbedChatBgColor,
  hideEmbedChatBgColor,
  showEmbedChatIcon,
  hideEmbedChatIcon,
  previewOfShowIcon,
  previewOfHideIcon,
}: any) => {
  const { deviceWidth } = useContext(PublicContext);

  return (
    <div style={{ flexDirection: "column", width: "100%" }}>
      <Col
        xs={12}
        style={{
          justifyContent: "space-between",
          padding: deviceWidth !== DEVICE_MEDIUM ? "0.5rem" : "0",
        }}
      >
        <BubbleChatStyles.HeadingText>
          {" "}
          {source === "iframe"
            ? "iFrame"
            : source === "share"
            ? "Shared URL"
            : "Embedded"}{" "}
          Chatbot Preview
        </BubbleChatStyles.HeadingText>
        {deviceWidth !== DEVICE_MEDIUM && (
          <Arrow
            src={DltrossSvg}
            alt="dlt"
            onClick={() => {
              setShowPreviewPopup(false);
            }}
          />
        )}
      </Col>
      <BubbleChatStyles.MainContainer sm={source === "bubble" ? 6 : 12}>
        <BubbleChatStyles.RightColumn>
          <BubbleChatStyles.ChatPageHeader
            className="App-header"
            style={{
              background: titleBackgroundColor
                ? titleBackgroundColor
                : "#1585b5",
            }}
          >
            <Col sm={1}>
              <img src={BubbleChatLiveIcon} alt="online..." width={30} /> &nbsp;
            </Col>
            <Col
              sm={10}
              style={{
                alignItems: "center",
                cursor: "pointer",
                paddingLeft: "1rem",
              }}
            >
              <b style={{ fontSize: "18px" }}>Powered By Dropchat</b>
            </Col>
          </BubbleChatStyles.ChatPageHeader>
          <BubbleChatStyles.ChatInputBox>
            <BubbleChatStyles.RightContentRow>
              <BubbleChatStyles.ChatBotContent sm={12}>
                <ScrollDiv>
                  <BubbleChatStyles.QuestionAnswerSection>
                    <BubbleChatStyles.QuestionSection
                      style={{
                        background: questionBackgroundColor
                          ? questionBackgroundColor
                          : "#1585b5",
                      }}
                    >
                      <img width="20" src={UserImage} alt="user_image" />
                      &nbsp;&nbsp;
                      <h5
                        style={{
                          color: questionTextColor
                            ? questionTextColor
                            : "#BEDCDD",
                        }}
                      >
                        How you can locate the titanic under the sea?
                      </h5>
                    </BubbleChatStyles.QuestionSection>
                    <BubbleChatStyles.ResponseSection
                      style={{
                        background: answerBackgroundColor
                          ? answerBackgroundColor
                          : "#1585b5",
                      }}
                    >
                      {/* <div>
                                <img
                                  width="20"
                                  src={ChatBotIcon}
                                  alt="user_image"
                                />
                                &nbsp;&nbsp;
                                <h5>
                                  {data.answer}
                                  {data.answer ? null : (
                                    <img
                                      width="30"
                                      src={TypingLoading}
                                      alt="..."
                                    />
                                  )}
                                </h5>
                              </div> */}
                      <div>
                        <img
                          width="20"
                          src={ChatBotIcon}
                          alt="user_image"
                          style={{
                            display: "flex",
                            alignSelf: "baseline",
                          }}
                        />
                        &nbsp;&nbsp;
                        <h5
                          style={{
                            color: answerTextColor
                              ? answerTextColor
                              : "#BEDCDD",
                          }}
                        >
                          Exploring the Deep: As I explain in the book, the
                          Titanic was located in the North Atlantic Ocean at a
                          depth of about 2.5 miles. My team and I used an array
                          of advanced sonar mapping technology.
                        </h5>
                      </div>
                    </BubbleChatStyles.ResponseSection>
                  </BubbleChatStyles.QuestionAnswerSection>
                </ScrollDiv>
              </BubbleChatStyles.ChatBotContent>
              <BubbleChatStyles.FieldSection>
                <BubbleChatStyles.FormInput
                  style={{
                    color: questionTextFieldColor
                      ? questionTextFieldColor
                      : "#f8f7fa",
                    border: questionTextFieldColor
                      ? "2px solid " + questionTextFieldColor
                      : "#f8f7fa",
                  }}
                  // name="prompt"
                  // placeholder={"Ask me anything"}
                >
                  Ask me anything
                </BubbleChatStyles.FormInput>
                <BubbleChatStyles.FetchBookButton type="submit">
                  <img width={25} src={SearchArrow} alt="search" />
                </BubbleChatStyles.FetchBookButton>
              </BubbleChatStyles.FieldSection>
              <BubbleChatStyles.PoweredText>
                Powered By Dropchat
              </BubbleChatStyles.PoweredText>
            </BubbleChatStyles.RightContentRow>
          </BubbleChatStyles.ChatInputBox>
        </BubbleChatStyles.RightColumn>
      </BubbleChatStyles.MainContainer>
      <br />
      {source === "bubble" && (
        <BubbleChatStyles.BubbleChatStylesIcons>
          <div
            style={{
              background: showEmbedChatBgColor
                ? showEmbedChatBgColor
                : "#1585B5",
              borderRadius: "50%",
              padding: "10px",
              width: "max-content",
            }}
          >
            <img
              src={previewOfShowIcon || showEmbedChatIcon}
              style={{ width: "30px", height: "30px" }}
            />
          </div>
          &nbsp; &nbsp;
          <div
            style={{
              background: hideEmbedChatBgColor
                ? hideEmbedChatBgColor
                : "#1585B5",
              borderRadius: "50%",
              padding: "10px",
              width: "max-content",
            }}
          >
            <img
              src={previewOfHideIcon || hideEmbedChatIcon}
              style={{ width: "30px", height: "30px" }}
            />
          </div>
        </BubbleChatStyles.BubbleChatStylesIcons>
      )}
    </div>
  );
};

export default ThemeBubbleChatProtoType;
