import { Formik } from "formik";
import { useContext, useRef } from "react";
import { Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { MOBILE } from "../../constants/mobile";
import { BookContext } from "../../context/BookContext";
import { LoaderContext } from "../../context/loader-context";
import myAxios from "../../myaxios";

const ParentDiv = styled.div`
  overflow: hidden;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  align-items: center;
  justify-content: center;
`;
const EmptyDiv = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;
const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;
const FormLabelNew = styled(FormLabel)`
  font-weight: 600;
  font-size: 15px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;
const TextAREANew = styled.textarea`
  border-radius: 4px;
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 10px;
  font-size: 14px;
  min-height: 22vh;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
  }
`;
const InputField = styled.input`
  width: 100%;
  padding: 15px 10px;
  background: #fff;
  border: 1px solid #eaeaea !important;
  display: inline;
  border-radius: 4px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    border-radius: 4px;
  }
`;
const Container = styled(Col)`
  position: fixed;
  display: block;
  width: max-content;
  margin: auto;
  overflow: auto;
  max-height: 65vh;
  inset: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 999999;
  border-radius: 10px 10px 0px 0px;
`;
const DltHeader = styled(Row)`
  height: 45px;
  background: rgba(47, 51, 73, 1);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  color: #2f4c95;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: white;
  letter-spacing: 0.5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    color: white;
  }
`;
const MainFormRow = styled(Row)`
  padding: 0.5rem 2rem;
  margin-top: 1rem;
`;

const ButtonRow = styled(Row)`
  padding: 0.5rem 3rem;
  display: flex;
  justify-content: space-between;
`;

const UpdateButton = styled.button`
  background: #1585b5;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: auto;
  padding: 0.4rem 3rem;
  font-weight: bold;
  letter-spacing: 0.8px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CancelButton = styled.button`
  background: none;
  padding: 0.4rem 3rem;
  color: #2f3349;
  border: 1px solid #2f3349;
  width: auto;
  display: flex;
  margin-top: auto;
  justify-content: center;
  font-weight: bold;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 100%;
    margin-top: 0.5rem;
  }
`;
const FeedbackAddDataSource = ({
  editPopUp,
  question,
  answer,
  chatHistoriesId,
  chatHistoryRating,
  setChatSessions,
  setSelectedChatSession,
  setAllAnswerSource,
  answerSourceList,
}: any) => {
  const { selectedBook, updateBookIndex, setShowAnswerSourcePopup } =
    useContext(BookContext);
  const { setShowLoader } = useContext(LoaderContext);

  const textAreaRef1 = useRef<HTMLTextAreaElement>(null);

  const hidePopup = () => {
    editPopUp(false);
  };
  return (
    <>
      <ParentDiv>
        <EmptyDiv onClick={hidePopup}></EmptyDiv>
        <Container>
          <Formik
            initialValues={{
              title: question || "",
              text: answer || "",
              type: "",
            }}
            // validate={}
            enableReinitialize
            onSubmit={(values: any) => {
              const input = {
                text: `${values.title} \n ${values.text}`,
                title: values.title,
                type: chatHistoryRating
                  ? chatHistoryRating === "Good"
                    ? "feedbackUp"
                    : "feedbackDown"
                  : "feedback",
                chatHistoriesId,
              };
              setShowLoader(true);
              myAxios
                .post(
                  process.env.REACT_APP_SERVER_URL +
                    `/books/add-source/${selectedBook?._id}`,
                  [input]
                )
                .then((response) => {
                  // console.log("response is , ", response.data);
                  if (response.data && response.data.success) {
                    setShowLoader(false);
                    updateBookIndex(response.data.data);

                    myAxios
                      .post(
                        process.env.REACT_APP_SERVER_URL +
                          `/lead-chat-sessions/paginated-chat-sessions`,
                        {
                          bookId: selectedBook && selectedBook._id,
                        }
                      )
                      .then((response) => {
                        if (response.data && response.data.success) {
                          setChatSessions(response.data.data.chatSessions);
                          setSelectedChatSession(
                            response.data.data.chatSessions[0]
                          );
                          setShowLoader(false);
                        } else {
                          setShowLoader(false);
                          NotificationManager.error(
                            response.data.message
                              ? response.data.message
                              : "Unable to fetch chat session, please try later!"
                          );
                        }
                      })
                      .catch((error) => {});
                  } else {
                    setShowLoader(false);
                    NotificationManager.error(
                      response.data.message
                        ? response.data.message
                        : "Unable to process your request, please try later"
                    );
                  }
                })
                .catch((error) => {
                  // handle error
                  setShowLoader(false);
                  NotificationManager.error(error);
                });
              hidePopup();
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <DltHeader>
                    <SpanDltPopup>Improve chatbot from feedback</SpanDltPopup>
                  </DltHeader>
                  <MainFormRow>
                    <FieldSection>
                      <FormLabelNew>Question</FormLabelNew>
                      <InputField
                        style={{ marginBottom: "0.5rem" }}
                        type="text"
                        name="title"
                        defaultValue={values.title}
                        placeholder="Character Name"
                        onChange={handleChange}
                      />
                    </FieldSection>
                    <FieldSection>
                      <FormLabelNew>Answer</FormLabelNew>
                      <TextAREANew
                        ref={textAreaRef1}
                        name="text"
                        defaultValue={values.text}
                        value={values.text}
                        onChange={handleChange}
                      ></TextAREANew>
                    </FieldSection>
                  </MainFormRow>
                  <ButtonRow>
                    <CancelButton style={{ width: "auto" }} onClick={hidePopup}>
                      Cancel
                    </CancelButton>
                    <UpdateButton
                      type="button"
                      style={{ margin: "0rem 2rem", width: "auto" }}
                      onClick={() => {
                        setShowAnswerSourcePopup(true);
                        setAllAnswerSource(
                          answerSourceList && answerSourceList.length > 0
                            ? answerSourceList
                            : []
                        );
                      }}
                    >
                      Show Source
                    </UpdateButton>
                    <UpdateButton style={{ width: "auto" }} type="submit">
                      Add to Data Source
                    </UpdateButton>
                  </ButtonRow>
                </form>
              </>
            )}
          </Formik>
        </Container>
      </ParentDiv>
    </>
  );
};
export default FeedbackAddDataSource;
