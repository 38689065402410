import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import DiscordIcon from "../../../images/discord-icon.svg";
import myAxios from "../../../myaxios";
import { NotificationManager } from "react-notifications";
import { useContext, useEffect } from "react";
import { LoaderContext } from "../../../context/loader-context";
import { parseErrorResponse } from "../../../utils/utils";
import { BookContext } from "../../../context/BookContext";

const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 0.5rem;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;
const InputField = styled.input`
  height: 46px;
  width: 100%;
  padding: 0.5rem;
  background: #eaeaea;
  border: 1px solid #eaeaea;
  margin-bottom: 0.5rem;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
  &:focus {
    outline: none;
  }
`;

const VerifyButton = styled.button`
  height: 46px;
  font-size: 12px;
  border: none;
  font-weight: bold;
  padding: 5px 15px;
  background: #1585b5;
  color: #fff;
  letter-spacing: 1px;
`;

const DiscordIntegrationAppsSetting = ({
  additionalInitialValues,
  isTokenVerified,
  setIsTokenVerified,
}: any) => {
  const { setShowLoader } = useContext(LoaderContext);
  const formik = useFormikContext();
  const { values, errors } = useFormikContext<any>();

  const { selectedBook } = useContext(BookContext);

  const verifyToken = (callFunc: string) => {
    if (callFunc === "VERIFY") {
      setShowLoader(true);
      myAxios
        .post(process.env.REACT_APP_SERVER_URL + "/discord-token", {
          token: values.integrationApp.discordBotToken,
          bookId: selectedBook?._id || selectedBook?.id,
        })
        .then((response) => {
          // console.log("response is , ", response.data);
          if (response.data && response.data.success) {
            setIsTokenVerified(true);
            NotificationManager.success(response.data.message);
            setShowLoader(false);
          } else {
            NotificationManager.error(
              response.data.message
                ? response.data.message
                : "Unable to process your request, please try later"
            );
            setIsTokenVerified(false);
            setShowLoader(false);
          }
        })
        .catch((error) => {
          // handle error
          // resetForm();
          setShowLoader(false);
          NotificationManager.error(parseErrorResponse(error));
        });
    } else if (callFunc === "REMOVE") {
      setShowLoader(true);
      setIsTokenVerified(false);
      setShowLoader(false);
    }
  };
  useEffect(() => {
    if (additionalInitialValues.integrationApp.discordBotToken) {
      setIsTokenVerified(true);
    }
    if (!additionalInitialValues.integrationApp.isDiscord) {
      setIsTokenVerified(true);
    }
    // eslint-disable-next-line
  }, [additionalInitialValues]);

  return (
    <>
      <div className="d-flex">
        <Col sm={10} xs={10} style={{ flexDirection: "column" }}>
          <MenuHeading>
            <img width={"100"} src={DiscordIcon} alt="discord" />
          </MenuHeading>
        </Col>
        <Col
          sm={2}
          xs={2}
          style={{
            justifyContent: "flex-end",
            alignSelf: "flex-start",
          }}
        >
          <CheckBoxWrapper>
            <>
              <CheckBox
                id="checkbox"
                checked={values.integrationApp.isDiscord}
                type="checkbox"
                name="integrationApp.isDiscord"
                onChange={(e: any) => {
                  formik.setFieldValue(
                    "integrationApp.isDiscord",
                    e.target.checked
                  );
                  setIsTokenVerified(!e.target.checked);
                  if (
                    e.target.checked &&
                    additionalInitialValues.integrationApp.discordBotToken &&
                    additionalInitialValues.integrationApp.discordBotToken !==
                      ""
                  ) {
                    verifyToken("VERIFY");
                  }
                }}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </>
          </CheckBoxWrapper>
        </Col>
      </div>
      {values.integrationApp.isDiscord && (
        <>
          <Row>
            <SubHeading>
              Log in to Discord Application and paste your Bot Token below.
            </SubHeading>
          </Row>
          <Col sm={12} style={{ flexDirection: "column" }}>
            <div>
              <InputField
                type="text"
                name="integrationApp.discordBotToken"
                disabled={isTokenVerified}
                defaultValue={values.integrationApp.discordBotToken}
                onChange={(e: any) => {
                  formik.setFieldValue(
                    "integrationApp.discordBotToken",
                    e.target.value
                  );
                }}
                placeholder=""
              />{" "}
              <VerifyButton
                type="button"
                onClick={() =>
                  verifyToken(isTokenVerified ? "REMOVE" : "VERIFY")
                }
              >
                {isTokenVerified ? "REMOVE" : "VERIFY"}
              </VerifyButton>
            </div>
            {errors?.discordBotToken &&
            typeof errors.discordBotToken === "string" ? (
              <div style={{ color: "red" }}>{errors.discordBotToken}</div>
            ) : (
              <div></div>
            )}
          </Col>
        </>
      )}
    </>
  );
};

export default DiscordIntegrationAppsSetting;
