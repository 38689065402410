import axios from "axios";
import { Formik } from "formik";
import { useContext, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import { ChatSource } from "../../enums";
import SearchArrow from "../../images/chat-arrow.svg";
import BackIcon from "../../images/chat-back.svg";
import ChatBotIcon from "../../images/chat-bot-icon.svg";
import UserImage from "../../images/chat-user.svg";
import EPUBSign from "../../images/epubSign.svg";
import FLACSign from "../../images/flacSign.svg";
import HTMLSign from "../../images/htmlSign.svg";
import LoaderGif from "../../images/loader.gif";
import MDSign from "../../images/mdSign.svg";
import MDXSign from "../../images/mdxSign.svg";
import MP3Sign from "../../images/mp3Sign.svg";
import MP4Sign from "../../images/mp4Sign.svg";
import URLSign from "../../images/ogg1.svg";
import OGGSign from "../../images/oggSign.svg";
import PDFSign from "../../images/pdf1.svg";
import YouTubeSign from "../../images/ps_youtube.svg";
import TXTSign from "../../images/txtSign.svg";
import TypingLoading from "../../images/typingLoading.gif";
import WAVSign from "../../images/wavIcon.svg";
import { Book } from "../../types";
import useDebounce from "../../use-debounce";
import CustomMarkdown from "../../utils/custom-markdown";
import { parseErrorResponse } from "../../utils/utils";
// import QuestionsSideBar from "./old-questions-sidebar";
import { DEVICE_MEDIUM, DEVICE_MOBILE } from "../../constants/breakpoints";
import { PublicContext } from "../../context/PublicContext";
import {
  EventCategory,
  Source,
  SubCategory,
  TrailEvents,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import { Mixpanel } from "../../mixpanel";
import { GUEST_USER_CHATBOT } from "../../utils/class-names";
import { ChatGuest } from "../book-chat/chat-stream-guest";
import MagicLinkLoginPopup from "../magic-link-login-popup";
import PublicQuestionsSideBar from "./public-questions-sidebar";
import * as BookChatStyles from "./styled-guest-chat-page";

const GuestBookChat = ({ showBack }: any) => {
  const { deviceWidth } = useContext(PublicContext);
  const [currentSessionHistory, setCurrentSessionHistory] = useState<any[]>([]);
  const [publicUserQuestionAskedCount, setPublicUserQuestionAskedCount] =
    useState<number>(0);
  const [trialQuestionLimit, setTrialQuestionLimit] = useState<number>(5);
  const [isTrialQuestionLoaded, setTrialQuestionLoaded] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const { bookId } = useParams();
  const [formRef, setFormRef] = useState<any>();
  const [questionValue, setQuestionValue] = useState("");

  const navigate = useNavigate();

  // const validatePropmt = (prompt: string) => {
  //   let error;
  //   if (!prompt) {
  //     error = "This is Required!";
  //   }
  //   return error;
  // };

  const [localBook, setLocalBook] = useState<Book>({});
  const [showOverlay, setShowOverlay] = useState(false);

  const [fileProcessingMessage, setFileProcessingMessage] =
    useState<string>("");

  const [chatSession, setChatSession] = useState<any>();

  // const createChatSession = async () => {
  //   let ipAddress = "";
  //   try {
  //     const res = await axios.get("https://api.ipify.org/?format=json");

  //     ipAddress = res.data.ip;
  //   } catch (error) {}

  //   axios
  //     .post(process.env.REACT_APP_SERVER_URL + "/chat-sessions", {
  //       bookId: bookId,
  //       source: ChatSource.guest,
  //       ipAddress: ipAddress,
  //     })
  //     .then((response) => {
  //       if (response && response.data) {
  //         setChatSession(response.data.data);
  //       }
  //     });
  // };

  useDebounce(
    () => {
      if (bookId) {
        //  createChatSession();

        axios
          .get(
            process.env.REACT_APP_SERVER_URL +
              "/public/getFileProcessingMessage"
          )
          .then((response) => {
            if (response && response.data) {
              setFileProcessingMessage(response.data.data);
            }
          });
        axios
          .get(
            process.env.REACT_APP_SERVER_URL +
              "/public/get-trial-question-limit"
          )
          .then((response) => {
            if (response && response.data && response.data.data > 0) {
              setTrialQuestionLoaded(true);
              setTrialQuestionLimit(response.data.data);
            }
          });
        axios
          .get(process.env.REACT_APP_SERVER_URL + "/books/" + bookId)
          .then((response) => {
            if (response.data) {
              setLocalBook(response.data);
              setPublicUserQuestionAskedCount(
                response.data.user.questionAskedCount
              );
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            // console.error("Gettings Error at the time of load book ", error);
          });
      }
    },
    [setLocalBook, bookId],
    1000
  );

  const chatStart = async (userQuery: string) => {
    if (chatSession) {
      chatWithDropchat(userQuery);
    } else {
      setLoading(true);

      let ipAddress = "";
      try {
        const res = await axios.get("https://api.ipify.org/?format=json");

        ipAddress = res.data.ip;
      } catch (error) {}

      const dataToSave = {
        bookId: bookId,
        source: ChatSource.guest,
        ipAddress: ipAddress,
      };
      axios
        .post(process.env.REACT_APP_SERVER_URL + "/chat-sessions", dataToSave)
        .then((response) => {
          if (response.data && response.data.success) {
            setChatSession(response.data.data);
            // if (isFromLoginForm) setShowLeadForm("none");
            // setShowConditionalLeadForm(false);
          }
          setLoading(false);
          chatWithDropchat(userQuery, response?.data?.data);
        })
        .catch((error) => {
          // handle error
          // NotificationManager.error(parseErrorResponse(error));
          setLoading(false);
          chatWithDropchat(userQuery);
        });
    }
  };

  const getFullAnswer = async (id: any, userQuery: any) => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/chats/${id}`)
      .then((res: any) => {
        if (res.data) {
          const data = res.data;

          setCurrentSessionHistory((oldQuestions: any[]) => {
            if (oldQuestions.length > 0) {
              oldQuestions[oldQuestions.length - 1]._id = data._id;
              oldQuestions[oldQuestions.length - 1].id = data.id;
              oldQuestions[oldQuestions.length - 1].answer = data.answer;

              return [...oldQuestions];
            } else return [];
          });
          Mixpanel.track("Demo Book User Chat Response", {
            bookId: localBook._id ? localBook._id : localBook.id,
            bookTitle: localBook.title,
            bookAuthors: localBook.authors,
            question: userQuery,
            answer: data.answer,
          });
          setShowOverlay(false);
        } else {
          setShowOverlay(false);
          NotificationManager.error(
            res.data && res.data.message
              ? res.data.message
              : "Unable to process your request, please try later!"
          );
          Mixpanel.track("Demo Book User Chat Response", {
            bookId: localBook._id ? localBook._id : localBook.id,
            bookTitle: localBook.title,
            bookAuthors: localBook.authors,
            question: userQuery,
            answer: "Getting Error",
            errror:
              res.data && res.data.message
                ? res.data.message
                : "Unable to process your request, please try later!",
          });
        }
      })
      .catch((error) => {
        setShowOverlay(false);
        NotificationManager.error(parseErrorResponse(error));
        Mixpanel.track("Demo Book User Chat Response", {
          bookId: localBook._id ? localBook._id : localBook.id,
          bookTitle: localBook.title,
          bookAuthors: localBook.authors,
          question: userQuery,
          answer: "Getting Error",
          errror: parseErrorResponse(error),
        });
      });
  };

  const chatWithDropchat = async (userQuery: string, session?: any) => {
    const currentSession = chatSession || session;
    setShowOverlay(true);
    setCurrentSessionHistory(
      currentSessionHistory
        ? [...currentSessionHistory, { question: userQuery, answer: "" }]
        : [{ question: userQuery, answer: "" }]
    );

    Mixpanel.track("Demo Book User Try to Chat", {
      bookId: localBook._id ? localBook._id : localBook.id,
      bookTitle: localBook.title,
      bookAuthors: localBook.authors,
      question: userQuery,
    });

    trackEvent({
      email: getUsersEmail(undefined),
      eventCategory: EventCategory.TRIAL,
      subCategory: SubCategory.EVENT,
      event: TrailEvents.USER_ASKED_QUESTION_ON_FEATURE_CHATBOT,
      source: Source.GUEST,
      description: "User Asked Question",
      bookId: bookId,
      chatSession: chatSession && chatSession._id,
      rawData: {
        question: userQuery,
      },
    });
    const requestBody = {
      question: userQuery,
      bookId: bookId,
      source: ChatSource.guest,
      chatSessionId: currentSession.id || currentSession._id,
    };
    const chat = new ChatGuest(
      requestBody,
      (token: string) => {
        setCurrentSessionHistory((oldQuestions: any[]) => {
          if (oldQuestions.length > 0) {
            oldQuestions[oldQuestions.length - 1].answer = oldQuestions[
              oldQuestions.length - 1
            ].answer
              ? oldQuestions[oldQuestions.length - 1].answer + token
              : token;
            return [...oldQuestions];
          } else return [];
        });
      },
      (data: any) => {
        getFullAnswer(data, userQuery);
      },
      ""
      // user?.jwt
    );

    await chat.startChatGuest();
  };

  const submitQuestion = (values: any, actions: any) => {
    actions.resetForm({
      values: {
        prompt: "",
      },
    });

    if (publicUserQuestionAskedCount >= trialQuestionLimit) {
      setShowLoginPopup(true);
      trackEvent({
        email: getUsersEmail(undefined),
        eventCategory: EventCategory.TRIAL,
        subCategory: SubCategory.EVENT,
        event: TrailEvents.USER_GOES_AVOVE_QUESTION_LIMIT_ON_FEATURE_CHATBOT,
        description: "User exceed the limit of feature chatbot",
        source: Source.GUEST,
        bookId: bookId,
      });

      return;
    }
    chatStart(values.prompt);
    setPublicUserQuestionAskedCount((oldState) => oldState + 1);
  };

  return (
    <>
      <BookChatStyles.MainContainer fluid>
        {deviceWidth === DEVICE_MEDIUM && <PublicQuestionsSideBar />}
        <BookChatStyles.RightColumn
          xs={deviceWidth === DEVICE_MOBILE ? 12 : 10}
        >
          <BookChatStyles.ChatPageHeader className="App-header">
            {showBack && (
              <Col sm={11}>
                <BookChatStyles.BackButton
                  src={BackIcon}
                  onClick={() => {
                    navigate("/landing");
                  }}
                  width={deviceWidth === DEVICE_MOBILE ? "10" : "15"}
                  style={{ marginRight: "1rem" }}
                />

                <img
                  width={deviceWidth === DEVICE_MOBILE ? "15" : "30"}
                  style={{ marginRight: "1rem" }}
                  src={
                    localBook?.imageUrl
                      ? localBook?.imageUrl
                      : localBook?.fileType === "audio/mpeg"
                      ? MP3Sign
                      : localBook?.fileType === "audio/ogg"
                      ? OGGSign
                      : localBook?.fileType === "audio/wav"
                      ? WAVSign
                      : localBook?.fileType === "audio/flac"
                      ? FLACSign
                      : localBook?.fileType === "video/mp4"
                      ? MP4Sign
                      : localBook?.fileType === "application/epub+zip"
                      ? EPUBSign
                      : localBook?.fileType === "md"
                      ? MDSign
                      : localBook?.fileType === "mdx"
                      ? MDXSign
                      : localBook?.fileType === "text/plain"
                      ? TXTSign
                      : localBook?.fileType === "text/html"
                      ? HTMLSign
                      : localBook?.fileType === "youtube"
                      ? YouTubeSign
                      : localBook?.fileType === "url"
                      ? URLSign
                      : localBook?.fileType === "application/pdf"
                      ? PDFSign
                      : ""
                  }
                  alt=""
                />

                <BookChatStyles.BookContent>
                  <span
                    style={{
                      margin: 0,
                      color: "#33303c",
                      fontSize:
                        deviceWidth === DEVICE_MOBILE ? "0.75rem" : "1rem",
                    }}
                  >
                    {localBook && localBook.title}
                  </span>
                  <span
                    style={{
                      color: "rgba(51, 48, 60, 0.6)",
                      fontSize:
                        deviceWidth === DEVICE_MOBILE ? "0.75rem" : "1rem",
                    }}
                  >
                    {localBook &&
                      localBook.authors &&
                      localBook.authors.length > 0 &&
                      localBook.authors.map((author: any) => author).join(", ")}
                  </span>
                </BookChatStyles.BookContent>
              </Col>
            )}
            <Col sm={8} style={{ alignItems: "center", paddingLeft: "1rem" }}>
              {/* <img
                width={deviceWidth === DEVICE_MOBILE ? "15" : "30"}
                style={{ marginRight: "1rem" }}
                src={
                  localBook?.imageUrl
                    ? localBook?.imageUrl
                    : localBook?.fileType === "audio/mpeg"
                    ? MP3Sign
                    : localBook?.fileType === "audio/ogg"
                    ? OGGSign
                    : localBook?.fileType === "audio/wav"
                    ? WAVSign
                    : localBook?.fileType === "audio/flac"
                    ? FLACSign
                    : localBook?.fileType === "video/mp4"
                    ? MP4Sign
                    : localBook?.fileType === "application/epub+zip"
                    ? EPUBSign
                    : localBook?.fileType === "md"
                    ? MDSign
                    : localBook?.fileType === "mdx"
                    ? MDXSign
                    : localBook?.fileType === "text/plain"
                    ? TXTSign
                    : localBook?.fileType === "text/html"
                    ? HTMLSign
                    : localBook?.fileType === "youtube"
                    ? YouTubeSign
                    : localBook?.fileType === "url"
                    ? URLSign
                    : localBook?.fileType === "application/pdf"
                    ? PDFSign
                    : ""
                }
                alt=""
              /> */}
              {/* <BookChatStyles.BookContent>
                <h5
                  style={{
                    margin: 0,
                    color: "#33303c",
                    fontSize:
                      deviceWidth === DEVICE_MOBILE ? "0.75rem" : "1.2rem",
                  }}
                >
                  {localBook && localBook.title}
                </h5>
                <h6
                  style={{
                    color: "rgba(51, 48, 60, 0.6)",
                    fontSize:
                      deviceWidth === DEVICE_MOBILE ? "0.75rem" : "1.2rem",
                  }}
                >
                  {localBook &&
                    localBook.authors &&
                    localBook.authors.length > 0 &&
                    localBook.authors.map((author: any) => author).join(", ")}
                </h6>
              </BookChatStyles.BookContent> */}
            </Col>

            <BookChatStyles.Column sm={1}></BookChatStyles.Column>
          </BookChatStyles.ChatPageHeader>

          <BookChatStyles.ChatInputBox sm={12}>
            <BookChatStyles.RightContentRow
              style={{ margin: "0", padding: "0" }}
            >
              <BookChatStyles.ChatBotContent sm={12}>
                <BookChatStyles.ScrollDiv>
                  {localBook.indexStatus !== 2 &&
                  (localBook.type === "custom" ||
                    localBook.chatType === "dropchat") ? (
                    <BookChatStyles.StatusLoaderSection>
                      <img src={LoaderGif} alt="loading..." width={60} />
                      <BookChatStyles.WaitTitle>
                        Please Wait
                      </BookChatStyles.WaitTitle>
                      <BookChatStyles.WaitDescription>
                        {fileProcessingMessage &&
                        fileProcessingMessage.length > 0
                          ? fileProcessingMessage
                          : ` Hello! We wanted to let you know that it will take a few
                        minutes to process this item. Don't worry, though - once
                        the file processing is finished, you'll be able to ask
                        any questions you have. You can leave this screen and
                        return a few minutes later to check status. Or you can
                        wait for status to chance to “Ready” on main page.`}
                      </BookChatStyles.WaitDescription>
                    </BookChatStyles.StatusLoaderSection>
                  ) : (
                    <BookChatStyles.QuestionAnswerSection>
                      {loading && (
                        <BookChatStyles.StatusLoaderSection>
                          <img src={LoaderGif} alt="loading..." width={60} />
                          <BookChatStyles.WaitTitle>
                            Please Wait
                          </BookChatStyles.WaitTitle>
                        </BookChatStyles.StatusLoaderSection>
                      )}

                      {currentSessionHistory &&
                        currentSessionHistory.length > 0 &&
                        currentSessionHistory.map((data: any) => (
                          <BookChatStyles.SignleQuestionAnswerSection>
                            <BookChatStyles.QuestionSection>
                              <img
                                width={
                                  deviceWidth === DEVICE_MOBILE ? "15" : "30"
                                }
                                src={UserImage}
                                alt="user_image"
                              />
                              &nbsp;&nbsp;
                              <h5
                                style={{
                                  fontSize:
                                    deviceWidth === DEVICE_MOBILE
                                      ? "0.75rem"
                                      : "1rem",
                                }}
                              >
                                {data.question}
                              </h5>
                            </BookChatStyles.QuestionSection>
                            <BookChatStyles.ResponseSection>
                              <div>
                                <img
                                  width={
                                    deviceWidth === DEVICE_MOBILE ? "15" : "30"
                                  }
                                  src={ChatBotIcon}
                                  alt="user_image"
                                  style={{
                                    display: "flex",
                                    alignSelf: "baseline",
                                  }}
                                />
                                &nbsp;&nbsp;
                                {data.answer && data.answer.length > 0 ? (
                                  <BookChatStyles.MarkDownContent>
                                    <CustomMarkdown text={data.answer} />
                                  </BookChatStyles.MarkDownContent>
                                ) : (
                                  <h5 style={{ margin: 0 }}>
                                    {`${data.answer}`}
                                    {data.answer &&
                                    data.answer.length > 0 ? null : (
                                      <img
                                        width={
                                          deviceWidth === DEVICE_MOBILE
                                            ? "15"
                                            : "30"
                                        }
                                        src={TypingLoading}
                                        alt="..."
                                      />
                                    )}
                                  </h5>
                                )}
                              </div>
                            </BookChatStyles.ResponseSection>
                          </BookChatStyles.SignleQuestionAnswerSection>
                        ))}
                    </BookChatStyles.QuestionAnswerSection>
                  )}
                </BookChatStyles.ScrollDiv>
              </BookChatStyles.ChatBotContent>
              <BookChatStyles.FieldColumn sm={12}>
                {(showOverlay ||
                  (localBook.chatType === "dropchat" &&
                    localBook.indexStatus !== 2)) && (
                  <BookChatStyles.OverlayFieldColumn
                    sm={12}
                  ></BookChatStyles.OverlayFieldColumn>
                )}

                <Formik
                  initialValues={{
                    prompt: questionValue,
                  }}
                  onSubmit={(values, actions) => {
                    //  setTypingLoading(true);
                    setQuestionValue("");
                    submitQuestion(values, actions);
                  }}
                  enableReinitialize
                >
                  {({ errors, touched, isValidating }: any) => (
                    <BookChatStyles.BookFetchForm
                      ref={(el) => setFormRef(el)}
                      style={{ padding: "0", paddingBottom: "6px" }}
                      className={GUEST_USER_CHATBOT}
                    >
                      {isTrialQuestionLoaded && (
                        <BookChatStyles.RestrictedSection
                          style={{
                            margin: "0 0 0.5rem 0",
                            justifyContent: "start",
                          }}
                        >
                          <BookChatStyles.RemainingCount
                            style={{ padding: "0.2rem 0" }}
                            xs={deviceWidth === DEVICE_MOBILE ? 8 : 3}
                          >
                            {isTrialQuestionLoaded && (
                              <>
                                {publicUserQuestionAskedCount
                                  ? trialQuestionLimit -
                                      publicUserQuestionAskedCount <
                                    0
                                    ? 0
                                    : trialQuestionLimit -
                                      publicUserQuestionAskedCount
                                  : trialQuestionLimit}
                                /{trialQuestionLimit} Demo questions remaining
                              </>
                            )}
                          </BookChatStyles.RemainingCount>
                          <BookChatStyles.LoginLink
                            xs={deviceWidth === DEVICE_MOBILE ? 4 : 2}
                            onClick={() => {
                              trackEvent({
                                email: getUsersEmail(undefined),
                                eventCategory: EventCategory.TRIAL,
                                subCategory: SubCategory.EVENT,
                                event:
                                  TrailEvents.USER_TRY_TO_LOGIN_FROM_FEATURE_CHATBOT,
                                description:
                                  "User exceed the limit of feature chatbot",
                                source: Source.GUEST,
                                bookId: bookId,
                              });
                              navigate("/magic-login");
                            }}
                          >
                            Login to Dropchat
                          </BookChatStyles.LoginLink>
                        </BookChatStyles.RestrictedSection>
                      )}
                      <BookChatStyles.FieldSection>
                        <BookChatStyles.FormInput
                          name="prompt"
                          as="textarea"
                          // validate={validatePropmt}
                          // autocomplete="off"
                          value={questionValue}
                          placeholder={
                            'Ask me anything from "' + localBook.title + '"'
                          }
                          // error={errors.prompt ? true : false}
                          onChange={(e) => setQuestionValue(e.target.value)}
                          onKeyDown={(e) => {
                            // eslint-disable-next-line
                            if (e.keyCode == 13 && e.shiftKey == false) {
                              e.preventDefault();
                              formRef.requestSubmit();
                            }
                          }}
                          style={{
                            paddingRight: "2.6rem",
                            resize: "none",
                            height: "46px",
                            padding: "11px",
                          }}
                        />
                        <BookChatStyles.FetchBookButton
                          type="submit"
                          style={{ top: "10px" }}
                        >
                          <img width={25} src={SearchArrow} alt="search" />
                        </BookChatStyles.FetchBookButton>
                      </BookChatStyles.FieldSection>
                    </BookChatStyles.BookFetchForm>
                  )}
                </Formik>
              </BookChatStyles.FieldColumn>
            </BookChatStyles.RightContentRow>
          </BookChatStyles.ChatInputBox>
        </BookChatStyles.RightColumn>

        {showLoginPopup && (
          <>
            <Modal
              show={true}
              backdrop="static"
              keyboard={false}
              style={{ top: "10%" }}
            >
              <MagicLinkLoginPopup
                showLoginPopup={showLoginPopup}
                // setShowLoginPage={setShowLoginPage}
                // setShowForgotPasswordPage={setShowForgotPasswordPage}
              />
            </Modal>
          </>
        )}
      </BookChatStyles.MainContainer>
    </>
  );
};

export default GuestBookChat;
