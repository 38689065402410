import { Field, Formik } from "formik";
import { OverlayPanel } from "primereact/overlaypanel";
import { useContext, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { DEVICE_MOBILE } from "../../constants/breakpoints";
import { PublicContext } from "../../context/PublicContext";
import { UserContext } from "../../context/UserContext";
import { NotesContext } from "../../context/notes-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import MessageVector from "../../images/flashcard-icon.svg";
import PencilButton from "../../images/flashcard-pencil.svg";
import ThreeDots from "../../images/flashcard-three-dots.svg";
import TrashButton from "../../images/flashcard-trash.svg";
import PlusIcon from "../../images/plus-icon.svg";
import WhiteBackArrow from "../../images/white-back-arrow.svg";
import { Mixpanel } from "../../mixpanel";
import myAxios from "../../myaxios";
import { ADD_FLASHCARD_CATAGORY } from "../../utils/class-names";
import { isTestUser, parseErrorResponse } from "../../utils/utils";
import ConfirmationBox from "../utility-component/confirmation-box";
import FlashcardsCatActionBox from "../utility-component/flashcards-cat-action-box";
import * as NotesStyles from "./styled";
const OverlayPanelStyle = styled(OverlayPanel)`
  flex-direction: column;
`;

const AddCategoryHeading = styled.span`
  font-weight: 600;
  padding-right: 1.25rem;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 10px;
  color: #fff;
  &:hover {
    background: #2f3349;
  }
`;

const FlashcardsOption = ({ notesCategory, setSelectedDetails }: any) => {
  // const { user } = useContext(UserContext);
  const { setShowFlashcardsOptionPopup } = useContext(NotesContext);
  const op = useRef<any>(null);
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [confirmationAction, setConfirmationAction] = useState<string>("");

  const confirmClick = (action: string) => {
    if (action === "delete") {
      // deleteBook(book);
    }
  };

  return (
    <>
      <div>
        <img
          src={ThreeDots}
          alt={"three dots"}
          onClick={(e) => {
            op.current.toggle(e);
            e.stopPropagation();
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            backgroundColor: "#1585b5",
          }}
        >
          <OverlayPanelStyle
            ref={op}
            style={{
              background: "#3d4156",
            }}
          >
            <AddCategoryHeading
              onClick={(e) => {
                op.current.toggle(e);
                setSelectedDetails({
                  notesCategoryId: notesCategory._id,
                  action: "rename",
                });
                setShowFlashcardsOptionPopup(true);
                e.stopPropagation();
              }}
            >
              <img
                alt="img4"
                src={PencilButton}
                width="16"
                height="16"
                style={{
                  marginLeft: "10px",
                  marginRight: "7px",
                }}
              />
              Rename
            </AddCategoryHeading>
            {notesCategory.type !== "default" && (
              <AddCategoryHeading
                onClick={(e) => {
                  op.current.toggle(e);
                  setSelectedDetails({
                    notesCategoryId: notesCategory._id,
                    action: "remove",
                  });
                  setShowFlashcardsOptionPopup(true);
                  e.stopPropagation();
                }}
              >
                <img
                  alt="img4"
                  src={TrashButton}
                  width="16"
                  height="16"
                  style={{
                    marginLeft: "10px",
                    marginRight: "7px",
                  }}
                />
                Remove
              </AddCategoryHeading>
            )}
          </OverlayPanelStyle>
        </div>
        {showUtilityConfirmationBox && (
          <ConfirmationBox
            setShow={setShowUtilityConfirmationBox}
            name="Confirmation"
            message={confirmationBoxMessage}
            okButtonText="Yes"
            action={confirmationAction}
            okButtonClick={confirmClick}
          />
        )}
      </div>
    </>
  );
};

const NotesSideBar = ({
  setCategorySummary,
  selectedNoteCategory,
  setSelectedNoteCategory,
  setReviewStarted,
  setShowSideBar,
}: any) => {
  const { deviceWidth } = useContext(PublicContext);
  const { user } = useContext(UserContext);
  // eslint-disable-next-line
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const { notesCategories, addNotesCategory } = useContext(NotesContext);
  const [selectedDetails, setSelectedDetails] = useState({
    action: "",
    notesCategoryId: "",
  });
  // const op = useRef<any>(null);
  const validateNotesCategory = (formData: any) => {
    const errors: any = {};

    if (!formData.categoryName) {
      errors.categoryName = "Flashcards category name required!";
    }

    if (formData.categoryName && formData.categoryName.length < 3) {
      errors.categoryName =
        "Invalid flashcards category name, minimum 3 charecter required!";
    }

    return errors;
  };

  const getCategorySummary = (categoryID: string) => {
    myAxios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/notes-categories/get-summary/${categoryID}`
      )
      .then((response) => {
        if (response.data && response.data.data) {
          setCategorySummary(response.data.data);
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error.response.data);
      });
  };

  const createNotesCategory = (values: any, { resetForm }: any) => {
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/notes-categories", {
        name: values.categoryName,
      })
      .then((response) => {
        if (response.data.success) {
          if (!isTestUser(user?.email))
            Mixpanel.track("Flashcard category created from Falshcard page", {
              categoryName: values.categoryName,
              email: user?.email,
            });
          trackEvent({
            email: getUsersEmail(user),
            eventCategory: EventCategory.CHATBOT,
            event: ChatBotEvents.USER_ADDED_NEW_FLASHCARD_CATEGORY,
            subCategory: SubCategory.EVENT,
            rawData: {
              categoryName: values.categoryName,
              email: user?.email,
            },
          });
          addNotesCategory(response.data.data);
          NotificationManager.success(
            "Flashcards category added successfully!"
          );
          resetForm({ values: "" });
        } else
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Not able to create flashcards category, Please try again!"
          );
        resetForm({ values: "" });
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
        resetForm({ values: "" });
        // console.error(
        //   "Getting error at the time of create flashcards category --- ",
        //   error
        // );
      });
  };

  const hideCategoryModal = () => {
    setShowSideBar(false);
  };
  return (
    <>
      <NotesStyles.Sidebar sm={10} lg={2} xs={10}>
        <NotesStyles.SideBarHeader>
          {deviceWidth === DEVICE_MOBILE && (
            <img
              src={WhiteBackArrow}
              alt="back"
              style={{
                zIndex: "9999",
                position: "absolute",
                left: "10px",
                top: "19px",
              }}
              onClick={hideCategoryModal}
            />
          )}
          <span
            style={{
              color: "white",
              paddingLeft: deviceWidth === DEVICE_MOBILE ? "20px" : "0",
            }}
          >
            {" "}
            Categories
          </span>
        </NotesStyles.SideBarHeader>
        {user &&
        user.currentSubscription &&
        user.currentSubscription.meta &&
        user.currentSubscription?.meta?.flashCard ? (
          <>
            <NotesStyles.SideBarContent>
              <Formik
                initialValues={{
                  categoryName: "",
                }}
                validate={validateNotesCategory}
                onSubmit={createNotesCategory}
              >
                {({ values, errors, touched }: any) => (
                  <>
                    <NotesStyles.AddCategoryForm
                      style={{ display: "flex", flexDirection: "column" }}
                      className={ADD_FLASHCARD_CATAGORY}
                    >
                      <label htmlFor="categoryName">Category</label>
                      <Field
                        id="categoryName"
                        name="categoryName"
                        placeholder="Add New Category"
                      />
                      <button type="submit">
                        <img src={PlusIcon} alt="add" />
                      </button>
                    </NotesStyles.AddCategoryForm>
                    {errors.categoryName && touched.categoryName && (
                      <NotesStyles.FormError className="formError">
                        {errors.categoryName}
                      </NotesStyles.FormError>
                    )}
                  </>
                )}
              </Formik>
              <NotesStyles.ChatHistoryHeading>
                Categories
              </NotesStyles.ChatHistoryHeading>
              <>
                {notesCategories &&
                  notesCategories.length > 0 &&
                  notesCategories.map((notesCategory: any) => (
                    <>
                      <NotesStyles.NotesName
                        onClick={() => {
                          getCategorySummary(notesCategory._id);
                          setSelectedNoteCategory(notesCategory._id);
                          setReviewStarted(false);
                          hideCategoryModal();
                        }}
                        selected={
                          notesCategory._id === selectedNoteCategory
                            ? true
                            : false
                        }
                      >
                        <img width="18" src={MessageVector} alt="message" />
                        <p>{notesCategory.name}</p>
                        <FlashcardsOption
                          notesCategory={notesCategory}
                          setSelectedDetails={setSelectedDetails}
                        />
                      </NotesStyles.NotesName>
                    </>
                  ))}
              </>
            </NotesStyles.SideBarContent>
          </>
        ) : null}
      </NotesStyles.Sidebar>
      <FlashcardsCatActionBox selectedDetails={selectedDetails} />
    </>
  );
};

export default NotesSideBar;
