import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DEVICE_MOBILE, DEVICE_TAB } from "../../../constants/breakpoints";
import { MEDIUM } from "../../../constants/desktop";
import { MOBILE } from "../../../constants/mobile";
import { TAB } from "../../../constants/tab";
import { BookContext } from "../../../context/BookContext";
import { PublicContext } from "../../../context/PublicContext";
import { UserContext } from "../../../context/UserContext";
import { LoaderContext } from "../../../context/loader-context";
import { PlanContext } from "../../../context/plan-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../../event-utils/dropchat-event-track";
import { trackEvent } from "../../../event-utils/track-event";
import BluePlusIcon from "../../../images/blue-plus-icon.svg";
import TrashIcon from "../../../images/trash-normal.svg";
import myAxios from "../../../myaxios";
import { UrlData } from "../../../types";
import { ADD_WEBSITE_SOURCE_CRAWL_URL } from "../../../utils/class-names";
import { parseErrorResponse } from "../../../utils/utils";
import IndexCharacterLimitPopUp from "../../index-character-limit-popup";
import ConfirmationBox from "../../utility-component/confirmation-box";
import MobileWebsiteSourceAccordian from "./mobile-website--source-accordion";

const ParentSection = styled.div`
  width: 100%;
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: 75vh;
  }
`;

const InnerSection = styled.div`
  background: #f8f7fa;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
`;

const FieldSection = styled.div`
  margin-bottom: 5px;
  flex-direction: column;
`;

const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
`;

const MainDiv = styled.div`
  width: 100%;
`;

const DivForDltPopUp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 78vh;
`;

interface SaveButtonProps {
  displayProp?: boolean;
}

const SubmitButton = styled.button<SaveButtonProps>`
  background: #1585b5;
  padding: 0.4rem 3rem;
  color: #fff;
  border: 1px solid transparent;
  width: auto;
  display: flex;
  margin-top: auto;
  justify-content: center;
  display: ${(props) => (props.displayProp ? "flex" : " none")};
  &:disabled {
    display: ${(props) => (props.displayProp ? "flex" : " none")};
    opacity: ${(props) => (props.displayProp ? " 0.5" : " 0")};
    cursor: not-allowed;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 96vw;
  }
`;

const SelectedCount = styled.span`
  color: #000;
  font-size: 16px;
  font-weight: bold;
  padding-left: 1rem;
  align-items: center;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: space-around !important;
    display: flex;
    font-size: 13px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: space-around !important;
    display: flex;
    font-size: 13px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    padding-left: 1rem;
    align-items: center;
    display: flex;
  }
`;

const URLFetchForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const UrlFormLabel = styled(FormLabel)`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  font-family: "Poppins", sans-serif;

  margin-bottom: 3px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
    margin: 0;
  }
`;

const FormInput = styled(Field)`
  padding: 10px;
  border: 1px solid rgba(51, 48, 60, 0.6) !important;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 7px;
  }
`;

const NormalInput = styled.input`
  width: 100%;
  padding: 10px;

  padding-right: 46px;
`;

const ParentDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const EmptyDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;

const SubscriptionExpireOverlay = styled.div`
  position: absolute;
  height: 50vh;
  width: 50vw;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;

  @media (max-width: ${MOBILE.maxWidth}px) {
    position: fixed;
    bottom: 25vh;
    width: 80vw;
    right: 10vw;
  }
`;
const CustomButton = styled.button`
  background: #f2ae01;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 15px;
  margin-left: 1rem;
  &:disabled {
    display: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: 0;
    padding: 0.5rem;
    font-size: 13px;
    width: 96vw;
    margin-top: 0.2rem;
  }
`;

const UrlSection = styled.div`
  margin: 0rem;
  flex-direction: column;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    margin: 0.5rem 0rem;
    overflow: auto;
  }

  @media (min-width: ${TAB.minWidth}px) {
    overflow: auto;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin: 0rem;
    flex-direction: column;
  }
`;

const FetchCrawlUrlButton = styled.button`
  padding: 8px 30px 8px 30px;
  background: #1585b5;
  color: #fff;
  border: none;
  width: max-content;
  font-size: 14px;
  border-radius: 4px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    padding: 6px 35px 6px 35px;
  }
`;
const AddLinkButton = styled.button`
  padding: 8px 30px 8px 30px;
  background: linear-gradient(0deg, #1585b5, #1585b5),
    linear-gradient(0deg, #ffffff, #ffffff);
  background: #ffff;
  border: 1px solid rgba(21, 133, 181, 1);
  color: rgba(21, 133, 181, 1);
  width: max-content;
  font-size: 14px;
  border-radius: 4px;
  line-height: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  letter-spacing: 0.5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    padding: 5px 20px 5px 20px;
  }
`;

const ButtonDiv = styled.div`
  justify-content: space-between;
  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column;
    align-items: center;
    gap: 14px;
  }
`;
const ExtraUrlHeader = styled.div`
  justify-content: space-between;
  margin-top: 1rem;
`;

const NormalFieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: row;
  position: relative;
`;

const SetExtraUrlButton = styled.button`
  /* background: #1585b5;
  color: #fff;
  border: none;
  width: max-content; */

  background: none;
  border: none;
  width: max-content;
  right: 0px;
  position: absolute;
  margin-top: 4px;
`;

const FooterSection = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px #0000001a;
  padding: 0.5rem;
  margin-top: auto;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    justify-content: space-between;
    flex-direction: column-reverse;
    position: fixed;
    bottom: 0;
    z-index: 111;
  }
`;
const CancelButton = styled.button`
  background: none;
  padding: 0.4rem 3rem;
  color: #2f3349;
  border: 1px solid #2f3349;
  width: auto;
  display: flex;
  margin-top: auto;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const UpgradeSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 5px 25px;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
`;

const InnerRow = styled(Row)`
  margin: 0;
  height: 100vh;
  /* height: 86%; */
  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column;
    padding: 0.5rem;
    overflow: scroll;
    padding-bottom: 8rem;
    height: 100vh;
  }
  @media (min-width: ${TAB.minWidth}px) {
    flex-direction: column;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    flex-direction: row;
  }
`;
const NormalSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 48, 60, 1);
  font-family: "Poppins", sans-serif;
  line-height: 21px;

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const Column = styled(Col)`
  border-right: 1px solid rgba(47, 51, 73, 0.2);
  margin: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    border: none;
    padding: 0;
  }
  @media (min-width: ${TAB.minWidth}px) {
    border: none;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    border-right: 1px solid rgba(47, 51, 73, 0.2);
  }
`;

const FooterInnerDiv = styled.div`
  align-items: center;

  @media (max-width: ${MOBILE.maxWidth}px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${TAB.minWidth}px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    flex-direction: row;
  }
`;
const CrawlUrlRow = styled(Row)`
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  /* border: 1px solid rgba(21, 133, 181, 1); */
  border: 1px solid rgba(220, 236, 242, 1);
  margin: 0;
  padding: 6px;
  background: white;
  border-radius: 6px;
  margin-top: 9px;
  cursor: pointer;
`;
const CrawlUrlFirstCol = styled(Col)`
  flex-direction: column;
`;
const CrawlUrlSecondCol = styled(Col)`
  align-items: center;
  justify-content: end;
`;
const LinkCountSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 48, 60, 1);
  font-family: "Poppins", sans-serif;
  line-height: 27px;
`;
const UrlSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: rgba(51, 48, 60, 1);
  font-family: "Poppins", sans-serif;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AddWebsiteSourceRenewed = ({ setShowAddNewSourcePage }: any) => {
  const navigate = useNavigate();
  const { setPlanHighliter } = useContext(PlanContext);
  const { deviceWidth } = useContext(PublicContext);
  const { user, updateUserSubscriptionIndexCount } = useContext(UserContext);
  const { setShowLoader } = useContext(LoaderContext);
  const { selectedBook, updateBookIndex } = useContext(BookContext);
  const [urls, setUrls] = useState<UrlData[]>([]);
  const [onChangeExtraUrl, setOnChangeExtraUrl] = useState<string>("");
  const [currentUrl, setCurrentUrl] = useState<string>("");
  const [allCurrentUrls, setAllCurrentUrls] = useState<any[]>([]);
  const [selectedKey, setSelectedKey] = useState(currentUrl);
  const [allUrlsWithSubUrls, setallUrlsWithSubUrls] = useState<any>({});
  const [disableButtonState, setDisableButtonState] = useState<boolean>(false);
  const [showLimitPopUp, setShowLimitPopUp] = useState<boolean>(false);
  const [showDeleteConfirmationBox, setShowDeleteConfirmationBox] =
    useState<boolean>(false);

  const isValidHttpUrl = (urlString: string) => {
    let url;
    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };

  const validateUrl = (value: string) => {
    return /^(?:(?:(?:https?|ftp|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  };

  const validateUrlSubmission = (formData: any) => {
    const errors: any = {};
    if (!formData.crawlUrl) {
      errors.crawlUrl = "Please enter any URL!";
    }
    if (!isValidHttpUrl(formData.crawlUrl)) {
      errors.crawlUrl = "valid URL is required!";
    }
    if (!validateUrl(formData.crawlUrl)) {
      errors.crawlUrl = "valid URL is required!";
    }
    return errors;
  };

  useEffect(() => {
    if (urls.length > 0) {
      const indexingCharacterLimitCount = user?.currentSubscription?.meta
        ?.indexCharacterSupport
        ? user?.currentSubscription?.meta?.indexCharacterSupport
        : 0;
      const urlsCharacterCount = urls.reduce(
        (total, urlData) => total + urlData.characterCount,
        user?.currentSubscription?.indexCharacterUsedTotal
          ? user?.currentSubscription?.indexCharacterUsedTotal
          : 0
      );

      if (urlsCharacterCount > indexingCharacterLimitCount) {
        setDisableButtonState(true);
      } else {
        setDisableButtonState(false);
      }
    }
  }, [urls, user, setDisableButtonState, setUrls]);

  const fetchUrls = (url: any) => {
    if (allUrlsWithSubUrls.hasOwnProperty(url)) {
      NotificationManager.info(`${url} is already available in the list!`);
      setShowLoader(false);
    } else {
      setCurrentUrl(url);
      setAllCurrentUrls([...allCurrentUrls, url]);
      setShowLoader(true);
      myAxios
        .post(process.env.REACT_APP_SERVER_URL + "/books/urls", {
          url,
        })
        .then((response) => {
          if (response.data && response.data.success) {
            setShowLoader(false);

            setSelectedKey(url);
            setUrls([...urls, ...response.data.data]);
            // storing url and sub urls
            setallUrlsWithSubUrls({
              ...allUrlsWithSubUrls,
              [url]: [...response.data.data],
            });
            // these conditon added for set seleteced at once if component mount -
            // if (count === 1) {
            // }

            if (response.data.data.length <= 0) {
              NotificationManager.info(
                "No URLs found on provided location or it provides URLs in the format that is not yet supported."
              );
            }
          } else {
            setShowLoader(false);
            NotificationManager.error(
              response.data.message
                ? response.data.message
                : "Unable to process your request, please try later"
            );
          }
        })
        .catch((error) => {
          // handle error
          // resetForm();
          setShowLoader(false);
          NotificationManager.error(parseErrorResponse(error));
        });
    }
  };

  const handleDeleteMainUrl = (keyToDelete: any) => {
    const updatedState = { ...allUrlsWithSubUrls };
    delete updatedState[keyToDelete];
    // these flatmap used for getting all array on updateState and set all the array on the setUrls state
    const allArrayValues: any = Object.values(updatedState).flatMap(
      (array) => array
    );
    setUrls(allArrayValues);
    setallUrlsWithSubUrls(updatedState);
    setShowDeleteConfirmationBox(false);
  };

  const removeSubUrls = (selectedKey: any, index: any) => {
    if (selectedKey && allUrlsWithSubUrls[selectedKey]) {
      const updatedUrls = [...allUrlsWithSubUrls[selectedKey]];
      updatedUrls.splice(index, 1);
      setallUrlsWithSubUrls({
        ...allUrlsWithSubUrls,
        [selectedKey]: updatedUrls,
      });
    }

    // below code just removing all website sub urls ---- shouldn't remove
    setUrls([
      ...urls.filter((item, idx) => {
        return idx !== index;
      }),
    ]);
  };

  const sendAllUrlsToTheServer = () => {
    let entry = {};
    let data: any = [];
    Object.entries(allUrlsWithSubUrls).forEach(([name, urls]: any) => {
      entry = {
        type: "url",
        name: name,
        urls: urls && urls.length && urls.map((url: any) => url.url),
      };
      // console.log("urls", urls);

      data.push(entry);
    });

    // console.log("data", data);
    setShowLoader(true);
    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL +
          `/books/add-source/${selectedBook?._id}`,
        data
      )
      .then((response) => {
        if (response.data && response.data.success) {
          trackEvent({
            email: getUsersEmail(user),
            eventCategory: EventCategory.CHATBOT,
            event: ChatBotEvents.USER_ADDED_NEW_DATASOURCE_IN_EXISTING_CHATBOT,
            subCategory: SubCategory.EVENT,
            chatbotType: response.data.data.fileType,
            bookId: response.data.data._id,
            rawData: {
              addedSourceType: "WEBSITE",
            },
          });
          setShowLoader(false);
          updateBookIndex(response.data.data);
          setShowAddNewSourcePage(false);
          updateUserSubscriptionIndexCount(
            urls.reduce(
              (total, urlData) => total + urlData.characterCount,
              user?.currentSubscription?.indexCharacterUsedTotal
                ? user?.currentSubscription?.indexCharacterUsedTotal
                : 0
            )
          );
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Source saved successfully."
          );
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const addsingleSubUrls = (onChangeExtraUrl: any) => {
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/books/url", {
        url: onChangeExtraUrl,
      })
      .then((response) => {
        // here updating the new suburls with the matched selected key-->
        if (selectedKey) {
          const updatedUrlsWithSubUrls = {
            ...allUrlsWithSubUrls,
          };
          const selectedKeyValues = updatedUrlsWithSubUrls[selectedKey] || [];
          selectedKeyValues.push({
            url: response.data.data.url,
            characterCount: response.data.data.characterCount,
          });

          updatedUrlsWithSubUrls[selectedKey] = selectedKeyValues;

          setallUrlsWithSubUrls(updatedUrlsWithSubUrls);
        }

        setShowLoader(false);
        let allUrls = [...urls];
        allUrls.unshift(response.data.data);
        setUrls(allUrls);
      });
  };

  const addUrlsWithoutSubUrls = (onChangeExtraUrl: any) => {
    if (onChangeExtraUrl) {
      if (allUrlsWithSubUrls.hasOwnProperty(onChangeExtraUrl)) {
        NotificationManager.info(
          `${onChangeExtraUrl} is already available in the list!`
        );
        setShowLoader(false);
      } else {
        setShowLoader(true);
        myAxios
          .post(process.env.REACT_APP_SERVER_URL + "/books/url", {
            url: onChangeExtraUrl,
          })
          .then((response) => {
            // console.log("response", response.data.data);

            setSelectedKey(onChangeExtraUrl);
            setallUrlsWithSubUrls({
              ...allUrlsWithSubUrls,
              [onChangeExtraUrl]: [response.data.data],
            });

            setShowLoader(false);
            let allUrls = [...urls];
            allUrls.unshift(response.data.data);
            setUrls(allUrls);
          });
      }
    } else {
      NotificationManager.error("Enter valid URL");
    }
  };

  const deleteUrlIfsubUrlslengthOne = (keyToDelete: any) => {
    const updatedState = { ...allUrlsWithSubUrls };
    delete updatedState[keyToDelete];

    if (selectedKey && allUrlsWithSubUrls[selectedKey]) {
      const valuesForSelectedKey = allUrlsWithSubUrls[selectedKey];

      // Check if the length of valuesForSelectedKey is 1
      if (valuesForSelectedKey.length === 1) {
        const allArrayValues: any = Object.values(updatedState).flatMap(
          (array) => array
        );

        setUrls(allArrayValues);
        setallUrlsWithSubUrls(updatedState);
        setShowDeleteConfirmationBox(false);
      } else {
        console.log("Length of selected key values is not less than 1.");
      }
    } else {
      console.log("Selected key has no values or does not exist.");
    }
  };

  return (
    <ParentSection>
      <Col sm={12} xs={12} lg={12}>
        <InnerSection>
          <MainDiv>
            <DivForDltPopUp>
              <InnerRow>
                <Column md={12} xs={12} sm={12} lg={4}>
                  <UrlSection>
                    <Formik
                      initialValues={{
                        crawlUrl: "",
                      }}
                      validate={validateUrlSubmission}
                      onSubmit={(values, { resetForm }) => {
                        fetchUrls(values.crawlUrl);
                        resetForm();
                      }}
                    >
                      {({ values, errors, touched, resetForm }: any) => (
                        <URLFetchForm className={ADD_WEBSITE_SOURCE_CRAWL_URL}>
                          <UrlFormLabel>
                            Crawl Website URL / Sitemap URL
                          </UrlFormLabel>
                          <FieldSection>
                            <FormInput name="crawlUrl" />
                            <NormalSpan>
                              This will crawl all the links starting with the
                              URL (not including files on the website).
                            </NormalSpan>
                          </FieldSection>
                          {errors.crawlUrl && touched.crawlUrl && (
                            <FormError className="formError">
                              {errors.crawlUrl}
                            </FormError>
                          )}
                          <ButtonDiv>
                            <FetchCrawlUrlButton type="submit">
                              Fetch Embedded links
                            </FetchCrawlUrlButton>
                            <AddLinkButton
                              type="button"
                              onClick={() => {
                                resetForm();
                                // addsingleSubUrls(values.crawlUrl);

                                addUrlsWithoutSubUrls(values.crawlUrl);
                                // let selectedKeyValue = values.crawlUrl;
                                // console.log(
                                //   "selectedKeyValue",
                                //   selectedKeyValue
                                // );

                                // setallUrlsWithSubUrls({
                                //   ...allUrlsWithSubUrls,
                                //   [selectedKeyValue]: [],
                                // });

                                // console.log("obj", allUrlsWithSubUrls);
                              }}
                            >
                              Add Just This Link
                            </AddLinkButton>
                          </ButtonDiv>
                        </URLFetchForm>
                      )}
                    </Formik>

                    {deviceWidth !== DEVICE_MOBILE && DEVICE_TAB ? (
                      <div
                        style={{
                          marginTop: "2%",
                          flexDirection: "column",
                          height: "42vh",
                          overflow: "scroll",
                          paddingBottom: "3rem",
                        }}
                      >
                        {Object.entries(allUrlsWithSubUrls).map(
                          ([key, values]: any) => {
                            return (
                              <>
                                <CrawlUrlRow
                                  key={key}
                                  style={{
                                    backgroundColor:
                                      key === selectedKey
                                        ? "rgba(220, 236, 242, 1)"
                                        : "white",
                                    boxShadow:
                                      key === selectedKey &&
                                      "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
                                  }}
                                  onClick={() => {
                                    setSelectedKey(key);
                                  }}
                                >
                                  <CrawlUrlFirstCol md={10}>
                                    <UrlSpan>{key}</UrlSpan>
                                    {/* {values  ( */}
                                    <LinkCountSpan>
                                      {values.length > 0
                                        ? `${values.length} Links found`
                                        : "0 Link"}
                                    </LinkCountSpan>
                                    {/* )} */}
                                  </CrawlUrlFirstCol>
                                  <CrawlUrlSecondCol md={2}>
                                    <img
                                      src={TrashIcon}
                                      alt="remove"
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        if (key) {
                                          // console.log("key", key);
                                          setSelectedKey(key);
                                          setShowDeleteConfirmationBox(true);
                                          // handleDeleteMainUrl(key);
                                        }
                                      }}
                                    />
                                  </CrawlUrlSecondCol>
                                  <Col></Col>
                                </CrawlUrlRow>
                              </>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      // this accordion visible only mobile and tab devices
                      <MobileWebsiteSourceAccordian
                        allUrlsWithSubUrls={allUrlsWithSubUrls}
                        selectedKey={selectedKey}
                        setSelectedKey={setSelectedKey}
                        handleDeleteMainUrl={handleDeleteMainUrl}
                        setOnChangeExtraUrl={setOnChangeExtraUrl}
                        onChangeExtraUrl={onChangeExtraUrl}
                        isValidHttpUrl={isValidHttpUrl}
                        validateUrl={validateUrl}
                        urls={urls}
                        setUrls={setUrls}
                        setallUrlsWithSubUrls={setallUrlsWithSubUrls}
                        removeSubUrls={removeSubUrls}
                        showDeleteConfirmationBox={showDeleteConfirmationBox}
                        setShowDeleteConfirmationBox={
                          setShowDeleteConfirmationBox
                        }
                        deleteUrlIfsubUrlslengthOne={
                          deleteUrlIfsubUrlslengthOne
                        }
                      />
                    )}
                  </UrlSection>
                </Column>

                {/* this section visible only desktiop devices */}
                {deviceWidth !== DEVICE_MOBILE && DEVICE_TAB && (
                  <Col
                    sm={12}
                    lg={7}
                    xs={12}
                    style={{ flexDirection: "column" }}
                  >
                    <UrlSection>
                      <ExtraUrlHeader>
                        <UrlFormLabel>Included Links</UrlFormLabel>
                      </ExtraUrlHeader>
                      <NormalFieldSection>
                        <NormalInput
                          name="extraUrl"
                          onChange={(e) => {
                            setOnChangeExtraUrl(e.target.value);
                          }}
                          value={onChangeExtraUrl}
                        />
                        <SetExtraUrlButton type="button">
                          <img
                            src={BluePlusIcon}
                            alt="add"
                            onClick={() => {
                              setOnChangeExtraUrl("");
                              if (
                                !isValidHttpUrl(onChangeExtraUrl) ||
                                !validateUrl(onChangeExtraUrl)
                              ) {
                                NotificationManager.error(
                                  "valid URL is required!"
                                );
                              } else if (
                                urls.some(
                                  (urlData) => urlData.url === onChangeExtraUrl
                                )
                              ) {
                                NotificationManager.info(
                                  onChangeExtraUrl +
                                    " is already available in the list!"
                                );
                              } else {
                                addsingleSubUrls(onChangeExtraUrl);
                              }
                            }}
                          />
                        </SetExtraUrlButton>
                      </NormalFieldSection>
                      <Col
                        style={{
                          flexDirection: "column",
                          overflowY: "auto",
                          maxHeight: "56vh",
                          paddingRight: "20px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "2%",
                            flexDirection: "column",
                            paddingBottom: "3rem",
                          }}
                        >
                          {selectedKey && allUrlsWithSubUrls[selectedKey] ? (
                            Object.values(allUrlsWithSubUrls[selectedKey]).map(
                              (value: any, index: number) => (
                                <CrawlUrlRow key={index}>
                                  <CrawlUrlFirstCol md={10}>
                                    <UrlSpan>{value.url}</UrlSpan>
                                    <LinkCountSpan>
                                      ({value.characterCount}) Characters
                                    </LinkCountSpan>
                                  </CrawlUrlFirstCol>
                                  <CrawlUrlSecondCol md={2}>
                                    <img
                                      src={TrashIcon}
                                      alt="remove"
                                      onClick={() => {
                                        removeSubUrls(selectedKey, index);
                                        deleteUrlIfsubUrlslengthOne(
                                          selectedKey
                                        );
                                      }}
                                    />
                                  </CrawlUrlSecondCol>
                                </CrawlUrlRow>
                              )
                            )
                          ) : (
                            <p>No data available.</p>
                          )}
                        </div>
                      </Col>
                    </UrlSection>
                  </Col>
                )}
              </InnerRow>

              <FooterSection>
                <CancelButton
                  type="button"
                  onClick={() => setShowAddNewSourcePage(false)}
                >
                  Cancel
                </CancelButton>
                {user?.currentSubscription ? (
                  user?.currentSubscription.meta?.allowWebsiteIndex ? (
                    <FooterInnerDiv>
                      <SelectedCount
                        style={{
                          paddingRight: "1rem",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {urls && urls.length > 0 && disableButtonState ? (
                          urls.reduce(
                            (total, urlData) => total + urlData.characterCount,
                            0
                          ) +
                          "/" +
                          `${Math.max(
                            (user?.currentSubscription?.meta
                              ?.indexCharacterSupport
                              ? user?.currentSubscription?.meta
                                  ?.indexCharacterSupport
                              : 0) -
                              (user?.currentSubscription
                                ?.indexCharacterUsedTotal
                                ? user?.currentSubscription
                                    ?.indexCharacterUsedTotal
                                : 0),
                            0
                          )}` +
                          " Characters limit exceeds"
                        ) : (
                          <SelectedCount
                            style={{
                              paddingRight: "1rem",
                              color: "grey",
                              fontSize: "12px",
                            }}
                          >
                            {urls.reduce(
                              (total, urlData) =>
                                total + urlData.characterCount,
                              0
                            ) +
                              "/" +
                              `${Math.max(
                                (user?.currentSubscription?.meta
                                  ?.indexCharacterSupport
                                  ? user?.currentSubscription?.meta
                                      ?.indexCharacterSupport
                                  : 0) -
                                  (user?.currentSubscription
                                    ?.indexCharacterUsedTotal
                                    ? user?.currentSubscription
                                        ?.indexCharacterUsedTotal
                                    : 0),
                                0
                              )}` +
                              " Characters"}
                          </SelectedCount>
                        )}
                      </SelectedCount>
                      <SelectedCount style={{ marginRight: ".8rem" }}>
                        {urls && urls.length > 1
                          ? urls.length + " URLs"
                          : urls.length + " URL"}
                      </SelectedCount>
                      <SubmitButton
                        type="button"
                        disabled={urls.length === 0}
                        displayProp={disableButtonState ? false : true}
                        onClick={() => {
                          if (urls.length === 0) {
                            NotificationManager.error("No URL selected!");
                            return;
                          }

                          sendAllUrlsToTheServer();
                        }}
                      >
                        Add Data Source
                      </SubmitButton>
                      <CustomButton
                        type="button"
                        disabled={!disableButtonState}
                        onClick={() => {
                          setShowLimitPopUp(true);
                          setPlanHighliter({
                            isBookHighliter: false,
                            bookLimit: 0,
                            isDeleteBookHighliter: false,
                            isFlashcardHighliter: false,
                            isCharacterLimit: false,
                            isIndexCharacterLimit: true,
                            pdfLimit: 0,
                            isDeleteAccount: false,
                            isLeadLoginSettings: false,
                            isAllowYTIndex: false,
                          });
                        }}
                      >
                        Upgrade Plan
                      </CustomButton>
                    </FooterInnerDiv>
                  ) : (
                    <div>
                      <SubmitButton
                        type="button"
                        style={{ opacity: 0.5, cursor: "not-allowed" }}
                      >
                        Add Data Source
                      </SubmitButton>
                      <div style={{ paddingLeft: "2rem" }}>
                        <UpgradeSpan
                          onClick={(e) => {
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: false,
                              isFlashcardHighliter: false,
                              isCharacterLimit: false,
                              pdfLimit: 0,
                              isDeleteAccount: false,
                              isLeadLoginSettings: false,
                              isAllowYTIndex: false,
                              isAllowPDFIndex: false,
                              isAllowWebsiteIndex: false,
                            });
                            navigate("/subscription");
                          }}
                        >
                          Upgrade Plan
                        </UpgradeSpan>
                        {/* <PlanSpan>Your plan</PlanSpan> */}
                      </div>
                    </div>
                  )
                ) : (
                  <Col>
                    <SubmitButton
                      type="button"
                      style={{ opacity: 0.5, cursor: "not-allowed" }}
                    >
                      Add Data Source
                    </SubmitButton>
                    <div style={{ paddingLeft: "2rem" }}>
                      <UpgradeSpan
                        onClick={(e) => {
                          setPlanHighliter({
                            isBookHighliter: false,
                            bookLimit: 0,
                            isDeleteBookHighliter: false,
                            isFlashcardHighliter: false,
                            isCharacterLimit: false,
                            pdfLimit: 0,
                            isDeleteAccount: false,
                            isLeadLoginSettings: false,
                            isAllowYTIndex: false,
                            isAllowPDFIndex: false,
                            isAllowWebsiteIndex: false,
                          });
                          navigate("/subscription");
                        }}
                      >
                        Purchase Plan
                      </UpgradeSpan>
                    </div>
                  </Col>
                )}
              </FooterSection>
            </DivForDltPopUp>
          </MainDiv>
        </InnerSection>
      </Col>
      {showLimitPopUp ? (
        <ParentDiv>
          <EmptyDiv
            onClick={() => {
              setShowLimitPopUp(false);
            }}
          ></EmptyDiv>
          <SubscriptionExpireOverlay>
            <IndexCharacterLimitPopUp
              titleContent={"Website character limit exceeded"}
              subHeadingContent={"Website character limit exceeded"}
              mainContent={`Please note that you have exceeded the website URL character limit of
                your current subscription plan. To resolve this, you can either remove
                some of the existing links or consider upgrading your plan for a
                higher character limit.`}
              setShowLimitPopUp={setShowLimitPopUp}
            ></IndexCharacterLimitPopUp>
          </SubscriptionExpireOverlay>
        </ParentDiv>
      ) : null}

      {showDeleteConfirmationBox && (
        <ConfirmationBox
          setShow={setShowDeleteConfirmationBox}
          name="Confirmation"
          message={"Are you sure you want to delete this URL ?"}
          okButtonText="Yes"
          action={selectedKey}
          okButtonClick={handleDeleteMainUrl}
        />
      )}
    </ParentSection>
  );
};

export default AddWebsiteSourceRenewed;
