import { Formik } from "formik";
import * as BubbleChatStyles from "./styled";
import SearchArrow from "../../images/chat-arrow.svg";
const InputFormUsingFormik = ({
  questionValue,
  setQuestionValue,
  setFormRef,
  chatStart,
  leadThemeSettings,
  leadSettings,
  customGreetingText,
  allQuestionsandAnswers,
  firstMessageAgreePopup,
  waiverSettings,
  setShowAgreePopup,
  setShowSampleQuestions,
  formRef,
  isResponseOn,
  chatbotDetails,
}: any) => {
  return (
    <Formik
      initialValues={{
        prompt: questionValue,
      }}
      onSubmit={(values, actions) => {
        if (firstMessageAgreePopup && waiverSettings.isWaiver) {
          setShowAgreePopup(true);
        } else {
          setQuestionValue("");
          setShowSampleQuestions(false);
          chatStart(questionValue);
        }
      }}
      enableReinitialize
    >
      {({ errors, touched, isValidating, setFieldValue }: any) => (
        <BubbleChatStyles.BookFetchForm ref={(el) => setFormRef(el)}>
          <BubbleChatStyles.FieldSection>
            <BubbleChatStyles.FormInput
              name="prompt"
              as="textarea"
              // validate={validatePropmt}
              // autocomplete="off"
              placeholder={"Ask me anything"}
              value={questionValue}
              // error={errors.prompt ? true : false}
              onChange={(e) => setQuestionValue(e.target.value)}
              disabled={isResponseOn}
              style={{
                paddingRight: "2.6rem",
                resize: "none",
                height: "40px",
                border:
                  leadThemeSettings.questionTextFieldColor &&
                  "2px solid " + leadThemeSettings.questionTextFieldColor,
                color: leadThemeSettings.questionTextFieldColor
                  ? leadThemeSettings.questionTextFieldColor
                  : "#000",
              }}
              onKeyDown={(e) => {
                // eslint-disable-next-line
                if (e.keyCode == 13 && e.shiftKey == false) {
                  e.preventDefault();
                  if (!isResponseOn) formRef.requestSubmit();
                }
              }}
            />
            <BubbleChatStyles.FetchBookButton
              type={
                isResponseOn || questionValue.length === 0 ? "button" : "submit"
              }
            >
              <img width={20} src={SearchArrow} alt="search" />
            </BubbleChatStyles.FetchBookButton>
          </BubbleChatStyles.FieldSection>
          <a
            href={
              (chatbotDetails && chatbotDetails.footerBrandingLink) ||
              "https://dropchat.co/"
            }
            rel="noreferrer"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <BubbleChatStyles.PoweredText>
              {chatbotDetails && chatbotDetails.footerBrandingText
                ? chatbotDetails.footerBrandingText
                : "Powered By Dropchat"}
            </BubbleChatStyles.PoweredText>
          </a>
        </BubbleChatStyles.BookFetchForm>
      )}
    </Formik>
  );
};

export default InputFormUsingFormik;
