import { Col } from "react-bootstrap";
import styled from "styled-components";
import BubbleChatLiveIcon from "../../images/drop-chat-icon.svg";
import { MOBILE } from "../../constants/mobile";
// import sampleQuestions from "../book-chat/tab-components/sample-questions";
import ReloadIcon from "../../images/reload-icon.svg";

const ChatPageHeader = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  /* justify-content: flex-start; */
  /* height: 10%; */
  /* padding: 0.2rem 0.5rem 0.2rem 0.5rem; */
`;
const ChatPageBold = styled.b`
  font-size: 14px;
  //cursor: pointer;
  color: white;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;
const ChatCommanHeader = ({
  leadThemeSettings,
  leadSettings,
  IframeChatApis,
  source,
  chatbotDetails,
}: any) => {
  return (
    <>
      <ChatPageHeader
        style={{
          background: leadThemeSettings.titleBackgroundColor
            ? leadThemeSettings.titleBackgroundColor
            : "#1585b5",
        }}
        className="App-header"
      >
        <Col sm={3} xs={8} style={{ alignItems: "center" }}>
          <img
            src={BubbleChatLiveIcon}
            alt="online..."
            width={30}
            // style={{ marginLeft: "15px" }}
          />{" "}
          &nbsp;
          <ChatPageBold>
            {chatbotDetails &&
            chatbotDetails.chatbotHeaderTitle &&
            chatbotDetails.chatbotHeaderTitle.length > 0
              ? chatbotDetails.chatbotHeaderTitle
              : "Welcome to Dropchat"}
          </ChatPageBold>
        </Col>

        <Col sm={8} xs={3} style={{ alignItems: "center" }}>
          {/* <ChatPageBold>
            {leadSettings.title && leadSettings.title.length > 0
              ? leadSettings.title
              : "Welcome to Dropchat"}
          </ChatPageBold> */}
        </Col>

        <Col md={1} sm={1} style={{ flex: 1 }}>
          {source === "IFRAME" && (
            <img
              src={ReloadIcon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                IframeChatApis();
              }}
              alt={"show"}
              width={20}
            />
          )}
        </Col>
      </ChatPageHeader>
    </>
  );
};

export default ChatCommanHeader;
