import { ProgressBar } from "react-bootstrap";
import FailedBadge from "../../../../../images/failed-badge.svg";
import ReadyBadge from "../../../../../images/ready-badge.svg";

const StatusColumn = ({ dataSource }: any) => {
  return (
    <>
      {dataSource.indexStatus === 1 ? (
        <>
          <ProgressBar
            animated
            variant={
              dataSource.roundProgressPercentage < 34
                ? "warning"
                : dataSource.roundProgressPercentage < 68
                ? "info"
                : "success"
            }
            now={dataSource.roundProgressPercentage}
            style={{ height: "10px" }}
          />
          <b>
            {dataSource.roundProgressPercentage}
            {"%"}
          </b>
        </>
      ) : (
        <img
          src={dataSource.indexStatus === 2 ? ReadyBadge : FailedBadge}
          alt={dataSource.type}
          className="w-4rem"
        />
      )}
    </>
  );
};

export default StatusColumn;
