import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  DEVICE_MEDIUM,
  MEDIUM,
  MOBILE,
  TAB,
} from "../../constants/breakpoints";
// import BubbleChatProtoType from "../book-chat/tab-components/bubble-chat-prototype/bubble-chat-prototype";
import { PublicContext } from "../../context/PublicContext";
import ThemeBubbleChatProtoType from "../book-chat/tab-components/bubble-chat-prototype/theme-bubble-chat-prototype";

const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 59%);
  z-index: 9999;
`;

const DivForDltPopUp = styled.div`
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 8%);
  z-index: 1111;
  width: 95% !important;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    position: relative;
  }
  @media (min-width: ${TAB.minWidth}px) {
    position: relative;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    position: fixed;
  }
`;

const DefaultButton = styled.button`
  background: #fff;
  font-weight: 600;
  color: #1585b5;
  width: 50%;
  border: 2px solid #1585b5;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    padding: 0.4rem 2rem;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 12px;
    padding: 0.4rem 2rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
    padding: 0.4rem 3rem;
  }
`;

const CheckThemeSettingsPreviewPopup = ({
  showPreviewPopup,
  setShowPreviewPopup,
  leadThemeSettings,
  selectedSidebarOption,
  showEmbedChatBgColor,
  hideEmbedChatBgColor,
  showEmbedChatIcon,
  hideEmbedChatIcon,
  previewOfShowIcon,
  previewOfHideIcon,
}: any) => {
  const { deviceWidth } = useContext(PublicContext);

  return showPreviewPopup ? (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <Row>
            <ThemeBubbleChatProtoType
              titleBackgroundColor={leadThemeSettings.titleBackgroundColor}
              questionBackgroundColor={
                leadThemeSettings.questionBackgroundColor
              }
              questionTextColor={leadThemeSettings.questionTextColor}
              answerBackgroundColor={leadThemeSettings.answerBackgroundColor}
              answerTextColor={leadThemeSettings.answerTextColor}
              questionTextFieldColor={leadThemeSettings.questionTextFieldColor}
              source={selectedSidebarOption.sourceName}
              setShowPreviewPopup={setShowPreviewPopup}
              showEmbedChatBgColor={showEmbedChatBgColor}
              hideEmbedChatBgColor={hideEmbedChatBgColor}
              showEmbedChatIcon={showEmbedChatIcon}
              hideEmbedChatIcon={hideEmbedChatIcon}
              previewOfShowIcon={previewOfShowIcon}
              previewOfHideIcon={previewOfHideIcon}
            />
            {deviceWidth !== DEVICE_MEDIUM && (
              <Col style={{ margin: "1rem", justifyContent: "center" }}>
                <DefaultButton
                  type="button"
                  onClick={() => setShowPreviewPopup(false)}
                >
                  Close Preview
                </DefaultButton>
              </Col>
            )}
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </>
  ) : (
    <></>
  );
};

export default CheckThemeSettingsPreviewPopup;
