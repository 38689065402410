import { createContext, ReactElement, useState } from "react";
import { PaginationProps, PaginationValues, UserValues } from "../types";

export const PaginationContext = createContext({} as PaginationValues);

const PaginationContextProvider = (
  props: PaginationProps
): ReactElement<UserValues> => {
  const [pageLimit, setPageLimit] = useState(8);
  const [pageSkip, setPageSkip] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isPrevBtnActive, setIsPrevBtnActive] = useState<string>("disabled");
  const [isNextBtnActive, setIsNextBtnActive] = useState<string>("");

  return (
    <PaginationContext.Provider
      value={{
        pageLimit,
        setPageLimit,
        pageSkip,
        setPageSkip,
        currentPage,
        setCurrentPage,
        isPrevBtnActive,
        setIsPrevBtnActive,
        isNextBtnActive,
        setIsNextBtnActive,
      }}
    >
      {props.children}
    </PaginationContext.Provider>
  );
};

export default PaginationContextProvider;
