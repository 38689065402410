import { Field, Form } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToBottom from "react-scroll-to-bottom";
import styled from "styled-components";
import { TreeSelect } from "primereact/treeselect";
import { OverlayPanel } from "primereact/overlaypanel";
import { MOBILE } from "../../constants/mobile";
import { isMobile } from "react-device-detect";

const { innerWidth: width } = window;
const isMobileWidth = width < 1000;

interface Selected {
  selected: boolean;
}

interface isGreeting {
  isGreeting: boolean;
}

export const MainContainer = styled(Container)`
  padding: 0;
  height: 100dvh;
  flex-direction: column;
`;

export const Sidebar = styled(Col)`
  background: #2f3349;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;
export const SideBarHeader = styled.div`
  height: 70px;
  padding: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
export const SideBarContent = styled.div`
  width: 100%;
  padding: 10px;
  flex-direction: column;
`;
export const LogoutButton = styled.img`
  padding: 0;
  width: 30%;
  margin: auto;
  cursor: pointer;
`;

export const BackButton = styled.img`
  padding: 0;
  width: 15%;
  cursor: pointer;
`;

export const BookFetchForm = styled(Form)`
  width: 100%;
`;

export const GreetingText = styled.p`
  color: #33303cde;
  font-size: 12px;
  /* margin: 0.2rem 0rem; */
  margin: 0;
`;

export const BubbleChatForm = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 12px;
  margin: 0;
  align-self: end;
`;

export const FieldSection = styled.div`
  position: relative;
  flex-direction: column;
`;

export const FormInput = styled(Field)`
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  &::placeholder {
    font-size: 12px;
  }
`;

export const FetchBookButton = styled.button`
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 16px;
  cursor: pointer;
  height: 100%;
  position: absolute;
  right: 10px;
  background: none;
  border: none;
`;

export const ChatInputBox = styled(Col)`
  height: calc(100vh - 90px);
`;

export const ChatHistoryHeading = styled.h5`
  color: #cccfdd;
`;

export const RightColumn = styled(Col)`
  flex-direction: column;
  padding: 0;
`;

export const RightContentRow = styled.div`
  width: 100%;
  /* padding: 10px; */
  padding: 5px;
  flex-direction: column;
`;

export const ChatBotContent = styled(Col)`
  flex-direction: column;
  padding: 0;
`;

export const LeadFormScrollDiv = styled(ScrollToBottom)`
  height: calc(100vh - 38vh);
  width: 100%;
  flex-direction: column;
`;

export const ScrollDiv = styled(ScrollToBottom)`
  height: calc(100vh - 32vh);
  width: 100%;
  flex-direction: column;
`;

export const ScrollDivBubbleChat = styled(ScrollToBottom)`
  height: ${(props: isGreeting) =>
    props.isGreeting || !isMobile
      ? "calc(100vh - 32vh)"
      : "calc(100vh - 22vh)"};
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-self: self-end;
`;

export const BookContent = styled.div`
  flex-direction: column;
  & h5 {
    color: #33303c;
    font-size: 18px;
  }
  & h6 {
    color: rgba(51, 48, 60, 0.6);
    font-size: 16px;
  }
`;

export const SignleQuestionAnswerSection = styled.div`
  flex-direction: column;
  width: 100%;
`;

export const ResponseSection = styled.div`
  /* border: 1px solid #33303c1a; */
  padding: 4px 8px;
  color: #282c34;
  background: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
  align-items: flex-start;
  flex-direction: column;
  /* & span {
    font-size: 1rem;
  }
  & h5 {
    margin: 0 !important;
    color: rgba(51, 48, 60, 1);
    font-size: 12px;
    display: flex;
    align-items: center;
  } */
  & p {
    margin: 0;
    font-size: 12px;
  }
`;

export const RatingSection = styled.div``;

export const QuestionSection = styled.div`
  padding: 4px 8px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  align-items: center;
  border: 1px solid #33303c1a;
  & h5 {
    margin: 0;
    color: rgba(51, 48, 60, 1);
    font-size: 12px;
  }
  & p {
    margin: 0;
    font-size: 12px;
  }
`;

export const QuestionAnswerSection = styled.div`
  flex-direction: column;
  width: 100%;
  gap: 12px;
  align-items: flex-start;
  align-self: stretch;
  justify-content: flex-end;
  height: fill-available;
  height: -webkit-fill-available;
  height: -moz-available;
`;

export const FormHeading = styled.p`
  font-weight: 600;
  color: #33303c;
  font-size: ${isMobileWidth ? "0.75rem" : "1rem"};
`;

export const FormConditionalHeading = styled.p`
  font-weight: 600;
  color: #1585b5;
  font-size: ${isMobileWidth ? "0.75rem" : "1.5rem"};
`;

export const InputSection = styled.div`
  flex-direction: column;
  margin-bottom: ${isMobileWidth ? "0.75rem" : "1rem"};
`;

export const FormLabelText = styled.span`
  color: #33303ccc;
  font-size: ${isMobileWidth ? "0.75rem" : "1rem"};
  font-weight: 500;
`;

export const FormBubbleInput = styled.input`
  height: ${isMobileWidth ? "35px" : "45px"};
  border: 1px solid #33303c33;
  padding: 10px;
  font-size: ${isMobileWidth ? "0.75rem" : "1rem"};
`;

export const SkipText = styled.p`
  color: #1585b5;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const PoweredText = styled.p`
  color: #33303c4d;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0;
`;

export const SaveButton = styled.button`
  background: #1585b5;
  padding: 0.4rem 3rem;
  color: #fff;
  border: 0;
  width: 100%;
`;

export const CancelButton = styled.button`
  background: #f0ad4e;
  padding: 0.4rem 3rem;
  color: #fff;
  border: 0;
  width: 100%;
`;

export const HistoryQuestion = styled.div`
  align-items: center;
  padding: 10px;
  margin: 0.2rem 0rem;
  background: ${(props: Selected) => (props.selected ? "#3d4156" : "")};
  border-radius: 5px;
  cursor: pointer;
  & p {
    color: #9c9eaf;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & img {
    margin-right: 0.8rem;
  }
`;

export const FieldColumn = styled(Col)`
  /* position: relative; */
`;

export const OverlayFieldColumn = styled(Col)`
  position: absolute;
  top: 0;
  height: 14%;
  z-index: 999;
  background: #00000050;
  cursor: not-allowed;
`;

export const StatusLoaderSection = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99999;
  background: #0001;
`;

export const WaitTitle = styled.p`
  text-align: center;
  font-size: 24px;
  color: #4f83b5;
  font-weight: bold;
`;

export const WaitDescription = styled.p`
  text-align: center;
`;

export const ChatPageHeader = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  justify-content: flex-start;
  height: ${isMobile ? "7.4%" : "7.4%"};
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
`;

export const ChatPageBold = styled.b`
  font-size: 14px;
  //cursor: pointer;
  color: white;
  margin-left: 0.7rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

export const RestrictedSection = styled(Row)`
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const RemainingCount = styled(Col)`
  color: #ea5455;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  background: #ffe1e1;
  border-radius: 6px;
  padding: 0.2rem 1rem;
`;

export const LoginLink = styled(Col)`
  color: #1585b5;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;

export const NoteListTitle = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #1585b5;
`;

export const SelectField = styled.select`
  font-size: 14px;
  font-weight: 500;
  color: #1585b5;
  border: none;
  width: 20px;
`;

export const SelectOption = styled.option`
  font-size: 14px;
  color: #33303c;
  padding: 5px 20px 5px 5px;
  &:hover {
    background: #1585b5;
  }
`;

export const TreeSelectStyle = styled(TreeSelect)`
  font-size: 14px;
  border: 0;
  & span {
    font-size: 1rem;
    color: #1585b5;
    font-weight: bold;
  }
`;
export const AddCategoryStyle = styled.p`
  font-size: 14px;
  color: #1585b5;
  font-weight: bold;
  margin: 0;
  margin-left: 3rem;
  cursor: pointer;
  align-self: center;
`;

export const OverlayPanelStyle = styled(OverlayPanel)`
  flex-direction: column;
`;

export const AddCategoryForm = styled(Form)`
  padding: 1.25rem;
  padding-top: 0;
  & input {
    margin: 0.5rem 0rem;
    background: #ffffff;
    border: 1px solid rgba(51, 48, 60, 0.4);
    border-radius: 4px;
    color: rgba(51, 48, 60, 0.6);
    padding: 5px;
    font-size: 14px;
  }
  & label {
    color: rgba(51, 48, 60, 0.8);
    font-size: 14px;
    font-weight: bold;
  }
  & button {
    background: #1585b5;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    border-color: #1585b5;
    box-shadow: none;
  }
`;

export const AddCategoryHeading = styled.h6`
  color: #33303c;
  font-weight: 600;
  padding-left: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e6e6e7;
`;

export const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
  font-size: 12px;
`;

export const ChipParent = styled.div`
  background-color: #dee2e6;
  color: #495057;
  border-radius: 16px;
  padding: 0 0.75rem;
  cursor: pointer;
`;
export const CrossIcon = styled.span`
  font-size: 1rem !important;
  font-weight: bold;
  display: flex;
  align-self: center;
  margin-left: 0.5rem;
`;
export const SuggestedQuestionHeading = styled.div`
  justify-content: space-between;
  margin-bottom: 0px;
  & p {
    color: #33303c;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export const MarkDownContent = styled.div`
  color: ${(props) => props.color};
  font-size: ${isMobileWidth ? "0.75rem" : "1rem"};
  & h1 {
    color: ${(props) => props.color};
  }
  & h2 {
    color: ${(props) => props.color};
  }
  & h3 {
    color: ${(props) => props.color};
  }
  & h4 {
    color: ${(props) => props.color};
  }
  & h5 {
    color: ${(props) => props.color};
  }
  & h6 {
    color: ${(props) => props.color};
  }
  & p {
    color: ${(props) => props.color};
  }
  & span {
    color: ${(props) => props.color};
  }
  & label {
    color: ${(props) => props.color};
  }
  & table {
    color: ${(props) => props.color};
  }
  & td {
    color: ${(props) => props.color};
  }
  & tr {
    color: ${(props) => props.color};
  }
  & th {
    color: ${(props) => props.color};
  }
  & li {
    color: ${(props) => props.color};
  }
`;
