import { Col } from "react-bootstrap";
import styled from "styled-components";
import { Field, Form, useFormikContext } from "formik";
import "react-image-crop/dist/ReactCrop.css";
import { MEDIUM, MOBILE, TAB } from "../../../constants/breakpoints";

const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 16px;
  font-weight: 600;
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 1rem;
`;

const FieldSectionRadio = styled.div`
  margin-right: 1rem;
  & label {
    width: 20%;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  & input {
    width: auto;
    /* margin-top: 0.4rem; */
    margin-right: 0.4rem;
    cursor: pointer;
    margin-bottom: 0rem;
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 14px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
  }
`;

const InputField = styled(Field)`
  width: 100%;
  padding: 0.5rem;
  background: #eaeaea;
  border: 1px solid #eaeaea;
  margin-bottom: 1rem;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const ChatInterfaceForm = styled(Form)`
  flex-direction: row;
  display: flex;
  width: 100%;
  & label {
    color: #33303c;
    font-weight: 600;
  }
`;

const ChatInterfaceSettings = () => {
  const formik = useFormikContext();

  return (
    <>
      <Col sm={12} style={{ flexDirection: "column" }}>
        <MenuHeading>Chatbot Interface</MenuHeading>
        <SubHeading>
          In Chat interface question / answer will starts from left, and in
          message interface question will appear left and answer in right.
        </SubHeading>
      </Col>
      <Col sm={12}>
        <ChatInterfaceForm>
          <FieldSectionRadio>
            <Col>
              <InputField
                type="radio"
                name="chatInterfaceType"
                value="chat"
                onChange={(e: any) => {
                  formik.setFieldValue("chatInterfaceType", "chat");
                }}
              />
              Chat Interface
            </Col>
          </FieldSectionRadio>
          <FieldSectionRadio>
            <Col>
              <InputField
                type="radio"
                name="chatInterfaceType"
                value="message"
                onChange={(e: any) => {
                  formik.setFieldValue("chatInterfaceType", "message");
                }}
              />
              Message Interface
            </Col>
          </FieldSectionRadio>
        </ChatInterfaceForm>
      </Col>
    </>
  );
};

export default ChatInterfaceSettings;
