import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { MEDIUM } from "../../../constants/desktop";
import { MOBILE } from "../../../constants/mobile";
import { TAB } from "../../../constants/tab";
import { BookContext } from "../../../context/BookContext";
import { UserContext } from "../../../context/UserContext";
import { LoaderContext } from "../../../context/loader-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../../event-utils/dropchat-event-track";
import { trackEvent } from "../../../event-utils/track-event";
import AddSvg from "../../../images/basil_add-solid.svg";
import TrashSampleSvg from "../../../images/trash.svg";
import myAxios from "../../../myaxios";
import { SAMPLE_QUESTION } from "../../../utils/class-names";
import { parseErrorResponse } from "../../../utils/utils";
import CheckSampleQuestionPreviewPopup from "../../utility-component/check-sample-question-preview-popup";
import ConfirmationBox from "../../utility-component/confirmation-box";
const ParentSection = styled(Col)`
  padding: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
`;

const ColumnSection = styled.div`
  background: #fff;
  flex-direction: column;
  border: 1px solid #eaeaea;

  padding: 0.5rem;

  width: 100%;
`;

const Div = styled.div`
  background: #fff;
  border: 1px solid #eaeaea;
  width: 100%;
  padding: 0.5rem;
  flex-direction: column;
  margin-bottom: 12px;
`;

const ContentParent = styled.div`
  flex-direction: column;
`;
// const PreviewParent = styled.div``;

const HeadingText = styled.h2`
  font-size: 14px;
  color: #33303c;
  font-weight: 600;
`;

// const Subheading = styled.p`
//   font-size: 13px;
//   color: #33303c;
//   font-weight: 400;
// `;

const LeftSectionContent = styled.div`
  flex-direction: column;
`;

const SaveButton = styled.button`
  border: none;
  background: none;
  display: flex;
`;

const InputField = styled(Field)`
  width: 100%;
  padding: 10px;
  background: #eaeaea;
  border: 1px solid #eaeaea !important;
  display: inline;
  margin-bottom: 0.5rem;
  height: 40px;
  padding-right: 36px;
  position: relative;
`;

const ParaAskQuestion = styled.p`
  width: 100%;
  padding: 10px;
  background: #eaeaea;
  border: 1px solid #eaeaea !important;
  display: inline;
  /* margin-bottom: 0.5rem; */
  height: auto;
  border-radius: 4px;
  font-size: 13px;
`;

const AddSampleSvg = styled.img`
  width: 40px;
  margin-left: 10px;
  margin-top: -6px;
  cursor: pointer;
`;
const SpanQuestion = styled.span`
  font-size: 13px;
  line-height: 24px;
  color: #33303ccc;
  font-weight: 500;
`;
const DefaultButton = styled.button`
  background: #fff;
  font-weight: 600;
  color: #1585b5;
  width: 63%;
  border: 2px solid #1585b5;
  border-radius: 6px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    padding: 0.4rem 2rem;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 12px;
    padding: 0.4rem 2rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
    padding: 0.4rem 3rem;
  }
`;

const SampleQuestionField = styled(Field)`
  padding: 9px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    border: 1px solid #ccc;
    box-shadow: none;
    outline: none;
  }
  &:focus-visible {
    border: 1px solid #ccc;
    box-shadow: none;
    outline: none;
  }
`;

const MobileSampleQuestions = () => {
  // const navigate = useNavigate();
  const { setShowLoader } = useContext(LoaderContext);
  const { selectedBook } = useContext(BookContext);
  const [sampleQuestions, setSampleQuestions] = useState<any>();
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");
  const [confirmationAction, setConfirmationAction] = useState<string>("");
  const [selectedQuestionDelete, setSelectedQuestionDelete] = useState<any>();
  const [showPreviewPopup, setShowPreviewPopup] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const [selectedQuestionEdit, setSelectedQuestionEdit] = useState<any>();
  // eslint-disable-next-line
  const [showEditQuestionModal, setShowEditQuestionModal] =
    useState<boolean>(false);
  useEffect(() => {
    myAxios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/lead-sample-questions/${selectedBook?._id}`
      )
      .then((res) => {
        if (res.data.data) {
          setSampleQuestions(res.data.data);
        }
      });
    // eslint-disable-next-line
  }, []);

  const saveSampleQuestion = (question: string, resetForm: any) => {
    setShowLoader(true);
    let dataToSave = {
      bookId: selectedBook?._id,
      question: question,
    };
    // setShowLoader(true);
    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL + `/lead-sample-questions`,
        dataToSave
      )
      .then((response) => {
        // console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Question added successfully."
          );
          trackEvent({
            email: getUsersEmail(user),
            eventCategory: EventCategory.CHATBOT,
            event: ChatBotEvents.USER_ADDED_LEAD_SAMPLE_QUESTIONS,
            subCategory: SubCategory.EVENT,
            bookId: selectedBook?._id,
            rawData: dataToSave,
          });
          // updateBookIndex(response.data.data);
          // setShowAddNewSourcePage(false);
          if (response.data.data) {
            setSampleQuestions(response.data.data);
          }
          resetForm();
          setShowLoader(false);
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const confirmClick = (action: string) => {
    if (action === "delete" && selectedQuestionDelete) {
      setShowLoader(true);
      const bookID = selectedBook && (selectedBook._id || selectedBook.id);
      myAxios
        .delete(
          process.env.REACT_APP_SERVER_URL +
            `/lead-sample-questions/` +
            bookID +
            "/" +
            selectedQuestionDelete
        )
        .then((response) => {
          // console.log("response is , ", response.data);
          if (response.data && response.data.success) {
            NotificationManager.success(
              response.data.message
                ? response.data.message
                : "Question deleted successfully."
            );
            trackEvent({
              email: getUsersEmail(user),
              eventCategory: EventCategory.CHATBOT,
              event: ChatBotEvents.USER_DELETE_LEAD_SAMPLE_QUESTIONS,
              subCategory: SubCategory.EVENT,
              bookId: selectedBook?._id,
            });
            // updateBookIndex(response.data.data);
            // setShowAddNewSourcePage(false);
            if (response.data.data) {
              setSampleQuestions(response.data.data);
              setShowUtilityConfirmationBox(false);
            }
            setShowLoader(false);
          } else {
            setShowLoader(false);
            setShowUtilityConfirmationBox(false);
            NotificationManager.error(
              response.data.message
                ? response.data.message
                : "Unable to process your request, please try later"
            );
          }
        })
        .catch((error) => {
          // handle error
          setShowLoader(false);
          NotificationManager.error(parseErrorResponse(error));
        });
    }
  };

  const deleteSampleQuestion = () => {
    setConfirmationBoxMessage("Do you really want to delete this question?");
    setConfirmationAction("delete");
    setShowUtilityConfirmationBox(true);
  };

  const updateSampleQuestion = (values: any) => {
    if (!values.question) {
      return myAxios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/lead-sample-questions/${selectedBook?._id}`
        )
        .then((res) => {
          if (res.data.data) {
            setSampleQuestions(res.data.data);
            setSelectedQuestionEdit(undefined);
            NotificationManager.warning("Sample question should not blank!");
          }
        });
    }
    if (selectedQuestionEdit.question !== values.question) {
      setShowLoader(true);
      myAxios
        .put(
          process.env.REACT_APP_SERVER_URL +
            `/lead-sample-questions/` +
            values.questionID,
          {
            question: values.question,
            bookID: selectedBook?._id,
          }
        )
        .then((response) => {
          // console.log("response is , ", response.data);
          if (response.data && response.data.success) {
            NotificationManager.success(
              response.data.message
                ? response.data.message
                : "Question updated successfully."
            );
            if (response.data.data) {
              setSampleQuestions(response.data.data);
              setSelectedQuestionEdit(undefined);
              setShowEditQuestionModal(false);
            }
            setShowLoader(false);
          } else {
            setShowLoader(false);
            setSelectedQuestionEdit(undefined);
            setShowEditQuestionModal(false);
            NotificationManager.error(
              response.data.message
                ? response.data.message
                : "Unable to process your request, please try later"
            );
          }
        })
        .catch((error) => {
          // handle error
          setShowLoader(false);
          NotificationManager.error(parseErrorResponse(error));
        });
    } else {
      setShowLoader(false);
      setSelectedQuestionEdit(undefined);
    }
  };

  return (
    <>
      <ParentSection sm={12} xs={12}>
        <Div>
          <HeadingText>Sample Questions</HeadingText>

          <Formik
            initialValues={{
              question: "",
            }}
            enableReinitialize
            onSubmit={(values, { resetForm }) => {
              if (values && values.question && values.question.length > 0) {
                saveSampleQuestion(values.question, resetForm);
              } else {
                return NotificationManager.warning(
                  "Sample question should not blank!"
                );
              }
            }}
          >
            <Form className={SAMPLE_QUESTION}>
              <Col lg={12} xs={12} sm={12} style={{ position: "relative" }}>
                <InputField
                  type="text"
                  name="question"
                  placeholder="Enter Question"
                />
                <button
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "13%",
                    border: "none",
                    background: "none",
                  }}
                  type="submit"
                >
                  <AddSampleSvg src={AddSvg} alt="add" />
                </button>
              </Col>
            </Form>
          </Formik>
        </Div>

        <ColumnSection>
          <ContentParent>
            <LeftSectionContent>
              <div
                style={{
                  flexDirection: "column",
                  overflow: "auto",
                  height: "360px",
                  padding: "1rem 0rem",
                }}
              >
                {sampleQuestions &&
                  sampleQuestions.length > 0 &&
                  sampleQuestions.map((question: any, index: number) => {
                    return (
                      <>
                        <SpanQuestion>Question {index + 1}</SpanQuestion>
                        <Col sm={12}>
                          {selectedQuestionEdit &&
                          selectedQuestionEdit.id === question.id ? (
                            <Formik
                              initialValues={{
                                questionID: selectedQuestionEdit.id,
                                question: selectedQuestionEdit.question,
                              }}
                              onSubmit={updateSampleQuestion}
                            >
                              {({ values, errors, touched }: any) => (
                                <Form
                                  className="d-flex flex-column"
                                  style={{ width: "90%" }}
                                >
                                  <SampleQuestionField
                                    id="question"
                                    name="question"
                                    placeholder="Question"
                                    autoFocus
                                    onBlur={() => updateSampleQuestion(values)}
                                  />
                                </Form>
                              )}
                            </Formik>
                          ) : (
                            <ParaAskQuestion
                              onClick={() => {
                                setSelectedQuestionEdit(question);
                              }}
                            >
                              {question.question}
                            </ParaAskQuestion>
                          )}
                          <SaveButton>
                            <img
                              src={TrashSampleSvg}
                              alt="add"
                              style={{ marginTop: "6px", cursor: "pointer" }}
                              onClick={() => {
                                deleteSampleQuestion();
                                setSelectedQuestionDelete(question.id);
                              }}
                            />
                          </SaveButton>
                        </Col>
                      </>
                    );
                  })}
              </div>
            </LeftSectionContent>
          </ContentParent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DefaultButton
              type="button"
              onClick={() => {
                setShowPreviewPopup(true);
              }}
            >
              Check Preview
            </DefaultButton>
          </div>
        </ColumnSection>
      </ParentSection>
      {showUtilityConfirmationBox && (
        <ConfirmationBox
          setShow={setShowUtilityConfirmationBox}
          name="Confirmation"
          message={confirmationBoxMessage}
          okButtonText="Yes"
          action={confirmationAction}
          okButtonClick={confirmClick}
        />
      )}
      <CheckSampleQuestionPreviewPopup
        sampleQuestions={sampleQuestions}
        showPreviewPopup={showPreviewPopup}
        setShowPreviewPopup={setShowPreviewPopup}
      />
    </>
  );
};

export default MobileSampleQuestions;
