import { useContext, useEffect, useState, useRef } from "react";
import { Accordion, Col, FormLabel } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import { ColorPicker } from "primereact/colorpicker";
import styled from "styled-components";
// import { Field, Form, Formik } from "formik";
import myAxios from "../../../myaxios";
// import BubbleChatProtoType from "./bubble-chat-prototype/bubble-chat-prototype";
import { NotificationManager } from "react-notifications";
import { BookContext } from "../../../context/BookContext";
import { UserContext } from "../../../context/UserContext";
import { LoaderContext } from "../../../context/loader-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../../event-utils/dropchat-event-enums";
import DeleteIcon from "../../../images/delete-icon-chat-seesion.svg";
import { getUsersEmail } from "../../../event-utils/dropchat-event-track";
import { trackEvent } from "../../../event-utils/track-event";
import { parseErrorResponse } from "../../../utils/utils";
import CheckThemeSettingsPreviewPopup from "../../utility-component/check-theme-settings-preview-popup";
// interface Color {
//   selected: boolean;
// }

const ParentSection = styled(Col)`
  padding: 0.6rem;
  flex-direction: column;
`;

const ColumnSection = styled.div`
  /* background: #fff; */
  flex-direction: column;
  /* border: 1px solid #eaeaea; */
  /* border-radius: 8px; */
  /* padding: 0.5rem; */
  /* width: 49.5%; */
  width: 100%;
`;

const ContentParent = styled.div`
  flex-direction: column;
`;
// const PreviewParent = styled.div``;

const HeadingText = styled.h2`
  font-size: 14px;
  margin: 0;
  color: #33303c;
  font-weight: 600;
`;

// const Subheading = styled.p`
//   font-size: 16px;
//   color: #33303c;
//   font-weight: 400;
// `;

const FieldSection = styled.div`
  padding: 10px 0px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  align-items: center;
`;

// const Sidebar = styled(Col)``;

// const OptionList = styled.ul`
//   margin: 0;
//   list-style: none;
//   padding: 0;
//   width: 100%;
// `;

// const OptionListItem = styled.li`
//   background: ${(props: Color) => (props.selected ? "#1585b5" : "#fff")};
//   padding: 10px;
//   flex-direction: row;
//   display: flex;
//   justify-content: space-between;
//   cursor: pointer;
// `;

// const OptionItemText = styled.div`
//   flex-direction: column;
// `;
// const OptionItemArrow = styled.div``;

// const OptionName = styled.p`
//   color: ${(props: Color) =>
//     props.selected ? "#fff" : "rgba(51, 48, 60, 0.6)"};
//   margin: 0;
//   font-size: 16px;
// `;

const LeftSectionContent = styled.div`
  flex-direction: row;
`;

const SaveButton = styled.button`
  border: 2px solid #1585b5;
  background: #1585b5;
  padding: 0.4rem 1rem;
  color: #fff;
  width: 100%;
  font-size: 13px;

  &:hover {
    background: #0b5f82;
  }
  border-radius: 4px;
  font-weight: 600;
`;

const DefaultButton = styled.button`
  background: #fff;
  font-weight: 600;
  padding: 0.4rem 0.6rem;
  color: #1585b5;
  width: 45%;
  border: 2px solid #1585b5;
  border-radius: 4px;
  font-size: 13px;
`;

const MobileThemeSettings = () => {
  // const navigate = useNavigate();
  const { setShowLoader } = useContext(LoaderContext);
  const { selectedBook } = useContext(BookContext);
  const [showPreviewPopup, setShowPreviewPopup] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const showIconInput = useRef<any>(null);
  const hideIconInput = useRef<any>(null);
  // const [allFormValues, setAllFormValues] = useState<any>();4

  const sidebarOptions = [
    {
      id: 1,
      name: "Embedded Theme",
      sourceName: "bubble",
    },
    {
      id: 2,
      name: "iFrame Theme",
      sourceName: "iframe",
    },
    {
      id: 3,
      name: "Shared URL Theme",
      sourceName: "share",
    },
  ];
  const [selectedSidebarOption, setSelectedSidebarOption] = useState<any>(
    sidebarOptions[0]
  );
  const [leadThemeSettings, setLeadThemeSettings] = useState({
    bookId: selectedBook?._id,
    source: "bubble",
    titleBackgroundColor: "#1585b5",
    questionBackgroundColor: "#000000",
    questionTextColor: "#ffffff",
    answerBackgroundColor: "#f8f7fa",
    answerTextColor: "#33303c",
    questionTextFieldColor: "#000000",
    showEmbedChatBgColor: "#1585B5",
    hideEmbedChatBgColor: "#1585B5",
    showEmbedChatIcon:
      "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-show-icon.svg",
    hideEmbedChatIcon:
      "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-hide-icon.svg",
  });

  const [showIconOfEmbedChat, setShowIconOfEmbedChat] = useState<any>();
  const [hideIconOfEmbedChat, setHideIconOfEmbedChat] = useState<any>();

  const [previewOfShowIcon, setPreviewOfShowIcon] = useState<any>();
  const [previewOfhideIcon, setPreviewOfHideIcon] = useState<any>();

  useEffect(() => {
    myAxios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/lead-theme-settings/${selectedBook?._id}/${selectedSidebarOption.sourceName}`
      )
      .then((res) => {
        // console.log("res.data.data ---", res.data.data);
        if (res.data.data) {
          setLeadThemeSettings(res.data.data);
        } else {
          setLeadThemeSettings({
            bookId: selectedBook?._id,
            source: selectedSidebarOption.sourceName,
            titleBackgroundColor: "#1585b5",
            questionBackgroundColor: "#000000",
            questionTextColor: "#ffffff",
            answerBackgroundColor: "#f8f7fa",
            answerTextColor: "#33303c",
            questionTextFieldColor: "#000000",
            showEmbedChatBgColor: "#1585B5",
            hideEmbedChatBgColor: "#1585B5",
            showEmbedChatIcon:
              "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-show-icon.svg",
            hideEmbedChatIcon:
              "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-hide-icon.svg",
          });
        }
      });
    // eslint-disable-next-line
  }, [selectedSidebarOption]);

  const saveEmbeddedTheme = () => {
    setShowLoader(true);
    let dataToSave = {
      bookId: selectedBook?._id,
      source: selectedSidebarOption.sourceName, // "bubble",
      titleBackgroundColor: leadThemeSettings.titleBackgroundColor,
      questionBackgroundColor: leadThemeSettings.questionBackgroundColor,
      questionTextColor: leadThemeSettings.questionTextColor,
      answerBackgroundColor: leadThemeSettings.answerBackgroundColor,
      answerTextColor: leadThemeSettings.answerTextColor,
      questionTextFieldColor: leadThemeSettings.questionTextFieldColor,
    };
    // setShowLoader(true);
    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL + `/lead-theme-settings`,
        dataToSave
      )
      .then((response) => {
        // console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Theme settings saved successfully."
          );
          // updateBookIndex(response.data.data);
          // setShowAddNewSourcePage(false);
          setShowLoader(false);
          trackEvent({
            email: getUsersEmail(user),
            eventCategory: EventCategory.CHATBOT,
            event:
              selectedSidebarOption.sourceName === "bubble"
                ? ChatBotEvents.USER_UPDATED_LEAD_THEME_SETTINGS_FOR_BUBBLE_CHAT
                : selectedSidebarOption.sourceName === "iframe"
                ? ChatBotEvents.USER_UPDATED_LEAD_THEME_SETTINGS_FOR_IFRAME_CHAT
                : selectedSidebarOption.sourceName === "share"
                ? ChatBotEvents.USER_UPDATED_LEAD_THEME_SETTINGS_FOR_SHARE_CHAT
                : "",
            subCategory: SubCategory.EVENT,
            bookId: selectedBook?._id,
            rawData: dataToSave,
          });
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const handleShowIconChange = (image: any) => {
    setShowIconOfEmbedChat(image);
    setPreviewOfShowIcon(URL.createObjectURL(image));
  };

  const handleHideIconChange = (image: any) => {
    setHideIconOfEmbedChat(image);
    setPreviewOfHideIcon(URL.createObjectURL(image));
  };

  return (
    <>
      <ParentSection sm={12} xs={12}>
        <ColumnSection>
          <ContentParent>
            <HeadingText>Chatbot Theme Settings</HeadingText>
            <LeftSectionContent>
              <Accordion
                style={{ display: "block", width: "100%" }}
                defaultActiveKey={(sidebarOptions[0].id - 1).toString()}
              >
                {sidebarOptions &&
                  sidebarOptions.length > 0 &&
                  sidebarOptions.map((option, id) => (
                    <Accordion.Item
                      key={id}
                      eventKey={id.toString()}
                      className="acc-item"
                    >
                      <Accordion.Header
                        onClick={(e: any) => {
                          setSelectedSidebarOption(option);
                        }}
                      >
                        {option.name && option.name}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Col
                          sm={8}
                          xs={12}
                          style={{
                            // border: "1px solid #eaeaea",
                            padding: "0.5rem 1rem",
                            flexDirection: "column",
                          }}
                        >
                          <HeadingText>
                            {" "}
                            {selectedSidebarOption.sourceName === "iframe"
                              ? "iFrame"
                              : selectedSidebarOption.sourceName === "share"
                              ? "Shared URL"
                              : "Embedded"}{" "}
                            Theme Settings
                          </HeadingText>
                          <FieldSection>
                            <FormLabel style={{ margin: 0, fontSize: "13px" }}>
                              Title Background Color
                            </FormLabel>
                            <div
                              style={{
                                border: "1px solid #EAEAEA",
                                padding: "3px",
                                borderRadius: "4px",
                                width: "130px",
                              }}
                            >
                              <ColorPicker
                                value={leadThemeSettings.titleBackgroundColor}
                                onChange={(e: any) =>
                                  setLeadThemeSettings({
                                    ...leadThemeSettings,
                                    titleBackgroundColor: "#" + e.value,
                                  })
                                }
                              />
                              <input
                                type="text"
                                value={
                                  leadThemeSettings.titleBackgroundColor &&
                                  leadThemeSettings.titleBackgroundColor
                                }
                                onChange={(e: any) => {
                                  setLeadThemeSettings({
                                    ...leadThemeSettings,
                                    titleBackgroundColor: e.target.value,
                                  });
                                }}
                                style={{
                                  width: "100%",
                                  color: "#33303C",
                                  fontSize: "16px",
                                  alignSelf: "center",
                                  paddingLeft: "10px",
                                  fontWeight: "600",
                                  textTransform: "uppercase",
                                }}
                              />
                            </div>
                          </FieldSection>
                          <FieldSection>
                            <FormLabel style={{ margin: 0, fontSize: "13px" }}>
                              Question Background Color
                            </FormLabel>
                            <div
                              style={{
                                border: "1px solid #EAEAEA",
                                padding: "3px",
                                borderRadius: "4px",
                                width: "130px",
                              }}
                            >
                              <ColorPicker
                                value={
                                  leadThemeSettings.questionBackgroundColor
                                }
                                onChange={(e: any) =>
                                  setLeadThemeSettings({
                                    ...leadThemeSettings,
                                    questionBackgroundColor: "#" + e.value,
                                  })
                                }
                              />
                              <input
                                type="text"
                                value={
                                  leadThemeSettings.questionBackgroundColor &&
                                  leadThemeSettings.questionBackgroundColor
                                }
                                onChange={(e: any) => {
                                  setLeadThemeSettings({
                                    ...leadThemeSettings,
                                    questionBackgroundColor: e.target.value,
                                  });
                                }}
                                style={{
                                  width: "100%",
                                  color: "#33303C",
                                  fontSize: "16px",
                                  alignSelf: "center",
                                  paddingLeft: "10px",
                                  fontWeight: "600",
                                  textTransform: "uppercase",
                                }}
                              />
                            </div>
                          </FieldSection>
                          <FieldSection>
                            <FormLabel style={{ margin: 0, fontSize: "13px" }}>
                              Question Text Color
                            </FormLabel>
                            <div
                              style={{
                                border: "1px solid #EAEAEA",
                                padding: "3px",
                                borderRadius: "4px",
                                width: "130px",
                              }}
                            >
                              <ColorPicker
                                value={leadThemeSettings.questionTextColor}
                                onChange={(e: any) =>
                                  setLeadThemeSettings({
                                    ...leadThemeSettings,
                                    questionTextColor: "#" + e.value,
                                  })
                                }
                              />
                              <input
                                type="text"
                                value={
                                  leadThemeSettings.questionTextColor &&
                                  leadThemeSettings.questionTextColor
                                }
                                onChange={(e: any) => {
                                  setLeadThemeSettings({
                                    ...leadThemeSettings,
                                    questionTextColor: e.target.value,
                                  });
                                }}
                                style={{
                                  width: "100%",
                                  color: "#33303C",
                                  fontSize: "16px",
                                  alignSelf: "center",
                                  paddingLeft: "10px",
                                  fontWeight: "600",
                                  textTransform: "uppercase",
                                }}
                              />
                            </div>
                          </FieldSection>
                          <FieldSection>
                            <FormLabel style={{ margin: 0, fontSize: "13px" }}>
                              Answer Background Color
                            </FormLabel>
                            <div
                              style={{
                                border: "1px solid #EAEAEA",
                                padding: "3px",
                                borderRadius: "4px",
                                width: "130px",
                              }}
                            >
                              <ColorPicker
                                value={leadThemeSettings.answerBackgroundColor}
                                onChange={(e: any) =>
                                  setLeadThemeSettings({
                                    ...leadThemeSettings,
                                    answerBackgroundColor: "#" + e.value,
                                  })
                                }
                              />
                              <input
                                type="text"
                                value={
                                  leadThemeSettings.answerBackgroundColor &&
                                  leadThemeSettings.answerBackgroundColor
                                }
                                onChange={(e: any) => {
                                  setLeadThemeSettings({
                                    ...leadThemeSettings,
                                    answerBackgroundColor: e.target.value,
                                  });
                                }}
                                style={{
                                  width: "100%",
                                  color: "#33303C",
                                  fontSize: "16px",
                                  alignSelf: "center",
                                  paddingLeft: "10px",
                                  fontWeight: "600",
                                  textTransform: "uppercase",
                                }}
                              />
                            </div>
                          </FieldSection>
                          <FieldSection>
                            <FormLabel style={{ margin: 0, fontSize: "13px" }}>
                              Answer Text Color
                            </FormLabel>
                            <div
                              style={{
                                border: "1px solid #EAEAEA",
                                padding: "3px",
                                borderRadius: "4px",
                                width: "130px",
                              }}
                            >
                              <ColorPicker
                                value={leadThemeSettings.answerTextColor}
                                onChange={(e: any) =>
                                  setLeadThemeSettings({
                                    ...leadThemeSettings,
                                    answerTextColor: "#" + e.value,
                                  })
                                }
                              />
                              <input
                                type="text"
                                value={
                                  leadThemeSettings.answerTextColor &&
                                  leadThemeSettings.answerTextColor
                                }
                                onChange={(e: any) => {
                                  setLeadThemeSettings({
                                    ...leadThemeSettings,
                                    answerTextColor: e.target.value,
                                  });
                                }}
                                style={{
                                  width: "100%",
                                  color: "#33303C",
                                  fontSize: "16px",
                                  alignSelf: "center",
                                  paddingLeft: "10px",
                                  fontWeight: "600",
                                  textTransform: "uppercase",
                                }}
                              />
                            </div>
                          </FieldSection>
                          <FieldSection>
                            <FormLabel style={{ margin: 0, fontSize: "13px" }}>
                              Question Text Field Color
                            </FormLabel>
                            <div
                              style={{
                                border: "1px solid #EAEAEA",
                                padding: "3px",
                                borderRadius: "4px",
                                width: "130px",
                              }}
                            >
                              <ColorPicker
                                value={leadThemeSettings.questionTextFieldColor}
                                onChange={(e: any) =>
                                  setLeadThemeSettings({
                                    ...leadThemeSettings,
                                    questionTextFieldColor: "#" + e.value,
                                  })
                                }
                              />
                              <input
                                type="text"
                                value={
                                  leadThemeSettings.questionTextFieldColor &&
                                  leadThemeSettings.questionTextFieldColor
                                }
                                onChange={(e: any) => {
                                  setLeadThemeSettings({
                                    ...leadThemeSettings,
                                    questionTextFieldColor: e.target.value,
                                  });
                                }}
                                style={{
                                  width: "100%",
                                  color: "#33303C",
                                  fontSize: "16px",
                                  alignSelf: "center",
                                  paddingLeft: "10px",
                                  fontWeight: "600",
                                  textTransform: "uppercase",
                                }}
                              />
                            </div>
                          </FieldSection>
                          {selectedSidebarOption.sourceName === "bubble" && (
                            <>
                              <FieldSection>
                                <FormLabel
                                  style={{ margin: 0, fontSize: "13px" }}
                                >
                                  Chatbot Show Icon Background Color
                                </FormLabel>
                                <div
                                  style={{
                                    border: "1px solid #EAEAEA",
                                    padding: "3px",
                                    borderRadius: "4px",
                                    width: "130px",
                                  }}
                                >
                                  <ColorPicker
                                    value={
                                      leadThemeSettings.showEmbedChatBgColor
                                    }
                                    onChange={(e: any) =>
                                      setLeadThemeSettings({
                                        ...leadThemeSettings,
                                        showEmbedChatBgColor: "#" + e.value,
                                      })
                                    }
                                  />
                                  <input
                                    type="text"
                                    value={
                                      leadThemeSettings.showEmbedChatBgColor &&
                                      leadThemeSettings.showEmbedChatBgColor
                                    }
                                    onChange={(e: any) => {
                                      setLeadThemeSettings({
                                        ...leadThemeSettings,
                                        showEmbedChatBgColor: e.target.value,
                                      });
                                    }}
                                    style={{
                                      width: "100%",
                                      color: "#33303C",
                                      fontSize: "16px",
                                      alignSelf: "center",
                                      paddingLeft: "10px",
                                      fontWeight: "600",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                </div>
                              </FieldSection>
                              <FieldSection>
                                <FormLabel
                                  style={{ margin: 0, fontSize: "13px" }}
                                >
                                  Chatbot Hide Icon Background Color
                                </FormLabel>
                                <div
                                  style={{
                                    border: "1px solid #EAEAEA",
                                    padding: "3px",
                                    borderRadius: "4px",
                                    width: "130px",
                                  }}
                                >
                                  <ColorPicker
                                    value={
                                      leadThemeSettings.hideEmbedChatBgColor
                                    }
                                    onChange={(e: any) =>
                                      setLeadThemeSettings({
                                        ...leadThemeSettings,
                                        hideEmbedChatBgColor: "#" + e.value,
                                      })
                                    }
                                  />
                                  <input
                                    type="text"
                                    value={
                                      leadThemeSettings.hideEmbedChatBgColor &&
                                      leadThemeSettings.hideEmbedChatBgColor
                                    }
                                    onChange={(e: any) => {
                                      setLeadThemeSettings({
                                        ...leadThemeSettings,
                                        hideEmbedChatBgColor: e.target.value,
                                      });
                                    }}
                                    style={{
                                      width: "100%",
                                      color: "#33303C",
                                      fontSize: "16px",
                                      alignSelf: "center",
                                      paddingLeft: "10px",
                                      fontWeight: "600",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                </div>
                              </FieldSection>
                              <FieldSection>
                                <FormLabel
                                  style={{ margin: 0, fontSize: "13px" }}
                                >
                                  Chatbot Show Icon
                                  <br />
                                  <span style={{ fontSize: "12px" }}>
                                    suggested size of image 30*30 or ratio 1:1
                                    <br />
                                    {showIconOfEmbedChat && (
                                      <>
                                        {showIconOfEmbedChat.name}
                                        <img
                                          src={DeleteIcon}
                                          alt="delete"
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "0.5rem",
                                          }}
                                          width={16}
                                          onClick={() => {
                                            setShowIconOfEmbedChat(undefined);
                                            setPreviewOfShowIcon(
                                              leadThemeSettings.showEmbedChatIcon
                                            );
                                            if (showIconInput.current) {
                                              showIconInput.current.value = "";
                                            }
                                          }}
                                        />
                                      </>
                                    )}
                                  </span>
                                </FormLabel>
                                <div
                                  style={{
                                    border: "1px solid #EAEAEA",
                                    padding: "3px",
                                    borderRadius: "4px",
                                    width: "130px",
                                  }}
                                >
                                  <label
                                    htmlFor="showIconID"
                                    style={{
                                      background: "lightgrey",
                                      padding: "5px",
                                      width: "100%",
                                      textAlign: "center",
                                      fontSize: "11px",
                                    }}
                                  >
                                    Browse file
                                  </label>
                                  <input
                                    type="file"
                                    id="showIconID"
                                    ref={showIconInput}
                                    accept="image/png, image/svg"
                                    onChange={(e: any) => {
                                      handleShowIconChange(e.target.files[0]);
                                    }}
                                    hidden
                                  />
                                </div>
                              </FieldSection>
                              <FieldSection>
                                <FormLabel
                                  style={{ margin: 0, fontSize: "13px" }}
                                >
                                  Chatbot Hide Icon
                                  <br />
                                  <span style={{ fontSize: "12px" }}>
                                    suggested size of image 30*30 or ratio 1:1
                                    <br />
                                    {hideIconOfEmbedChat && (
                                      <>
                                        {hideIconOfEmbedChat.name}
                                        <img
                                          src={DeleteIcon}
                                          alt="delete"
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "0.5rem",
                                          }}
                                          width={16}
                                          onClick={() => {
                                            setHideIconOfEmbedChat(undefined);
                                            setPreviewOfHideIcon(
                                              leadThemeSettings.hideEmbedChatIcon
                                            );
                                            if (hideIconInput.current) {
                                              hideIconInput.current.value = "";
                                            }
                                          }}
                                        />
                                      </>
                                    )}
                                  </span>
                                </FormLabel>
                                <div
                                  style={{
                                    border: "1px solid #EAEAEA",
                                    padding: "3px",
                                    borderRadius: "4px",
                                    width: "130px",
                                  }}
                                >
                                  <label
                                    htmlFor="hideIconID"
                                    style={{
                                      background: "lightgrey",
                                      padding: "5px",
                                      width: "100%",
                                      textAlign: "center",
                                      fontSize: "11px",
                                    }}
                                  >
                                    Browse file
                                  </label>
                                  <input
                                    type="file"
                                    id="hideIconID"
                                    ref={hideIconInput}
                                    accept="image/png, image/svg"
                                    onChange={(e: any) => {
                                      handleHideIconChange(e.target.files[0]);
                                    }}
                                    hidden
                                  />
                                </div>
                              </FieldSection>
                            </>
                          )}
                          <FieldSection>
                            <DefaultButton
                              type="button"
                              onClick={() => {
                                setLeadThemeSettings({
                                  bookId: selectedBook?._id,
                                  source: selectedSidebarOption.sourceName,
                                  titleBackgroundColor: "#1585b5",
                                  questionBackgroundColor: "#000000",
                                  questionTextColor: "#ffffff",
                                  answerBackgroundColor: "#f8f7fa",
                                  answerTextColor: "#33303c",
                                  questionTextFieldColor: "#000000",
                                  showEmbedChatBgColor: "#1585B5",
                                  hideEmbedChatBgColor: "#1585B5",
                                  showEmbedChatIcon:
                                    "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-show-icon.svg",
                                  hideEmbedChatIcon:
                                    "https://drop-chat-files.s3.amazonaws.com/dropchat-assets/bubble-chat-hide-icon.svg",
                                });
                                setHideIconOfEmbedChat(undefined);
                                setPreviewOfHideIcon(
                                  leadThemeSettings.hideEmbedChatIcon
                                );
                                if (hideIconInput.current) {
                                  hideIconInput.current.value = "";
                                }
                                setShowIconOfEmbedChat(undefined);
                                setPreviewOfShowIcon(
                                  leadThemeSettings.showEmbedChatIcon
                                );
                                if (showIconInput.current) {
                                  showIconInput.current.value = "";
                                }
                              }}
                            >
                              Use Default
                            </DefaultButton>
                            <DefaultButton
                              type="button"
                              onClick={() => setShowPreviewPopup(true)}
                            >
                              Check Preview
                            </DefaultButton>
                          </FieldSection>
                          <FieldSection>
                            <SaveButton
                              type="button"
                              onClick={() => saveEmbeddedTheme()}
                            >
                              SAVE
                            </SaveButton>
                          </FieldSection>
                        </Col>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </LeftSectionContent>
          </ContentParent>
        </ColumnSection>
        <ColumnSection>
          <CheckThemeSettingsPreviewPopup
            showPreviewPopup={showPreviewPopup}
            setShowPreviewPopup={setShowPreviewPopup}
            leadThemeSettings={leadThemeSettings}
            selectedSidebarOption={selectedSidebarOption}
            showEmbedChatBgColor={leadThemeSettings.showEmbedChatBgColor}
            hideEmbedChatBgColor={leadThemeSettings.hideEmbedChatBgColor}
            showEmbedChatIcon={leadThemeSettings.showEmbedChatIcon}
            hideEmbedChatIcon={leadThemeSettings.hideEmbedChatIcon}
            previewOfShowIcon={previewOfShowIcon}
            previewOfHideIcon={previewOfhideIcon}
          />
        </ColumnSection>
      </ParentSection>
    </>
  );
};

export default MobileThemeSettings;
