import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";

const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 14px;
  font-weight: 600;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 0.8rem;
  margin-top: 0.2rem;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 10px;
  }
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;
const ColForHeading = styled(Col)`
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 10px;
  }
`;

const SummarizedAnswer = () => {
  const formik = useFormikContext();
  const { values } = useFormikContext<any>();
  return (
    <>
      <Row>
        <ColForHeading sm={10} xs={10}>
          <MenuHeading>Manage Answer Length</MenuHeading>
        </ColForHeading>
        <Col
          sm={2}
          xs={2}
          style={{
            justifyContent: "flex-end",
            alignSelf: "flex-start",
          }}
        >
          <CheckBoxWrapper>
            <>
              <CheckBox
                id="checkbox"
                type="checkbox"
                checked={values.bookSetting.isSummarized}
                name="bookSetting.isSummarized"
                onChange={(e: any) => {
                  formik.setFieldValue(
                    "bookSetting.isSummarized",
                    e.target.checked
                  );
                }}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </>
          </CheckBoxWrapper>
        </Col>
      </Row>
      <Col>
        <SubHeading>
          By default, the system delivers complete answers, but you can
          summarize the length of response. This functionality works in Bubble
          chat, shared URLs, and iframes.
        </SubHeading>
      </Col>
    </>
  );
};

export default SummarizedAnswer;
