import { useContext, useEffect } from "react";
import { PublicContext } from "../../../../context/PublicContext";
import {
  DEVICE_MOBILE,
  DEVICE_TAB,
  MEDIUM,
  MOBILE,
} from "../../../../constants/breakpoints";
import { Col, Row } from "react-bootstrap";
import CreatedAtColumn from "./data-source-column/created-at-column";
import ReindexAtColumn from "./data-source-column/reindex-at-column";
import TitleColumn from "./data-source-column/title-column";
import DeleteActionColumn from "./data-source-column/delete-action-column";
import ReIndexActionColumn from "./data-source-column/reindex-action-column";
import ShowSourceCloumn from "./data-source-column/show-source-column";
import IncludedInfoColumn from "./data-source-column/include-info-column";
import StatusColumn from "./data-source-column/status-column";
import styled from "styled-components";
import { BookContext } from "../../../../context/BookContext";

const DataSourceDetails = styled(Col)`
  margin-top: 20px;
  display: block;
  background: #fff;
  flex-direction: column;
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  @media (min-width: ${MEDIUM.minWidth}px) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none;
    margin-top: 0px;
  }
`;

const PageCount = styled.span`
  padding-left: 25px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  line-height: 19.5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;
const Time = styled.span`
  padding-left: 5px;
  font-size: 10px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  line-height: 18px;
  color: rgba(51, 48, 60, 0.8);
`;
const TimeSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  line-height: 19.5px;
  color: rgba(51, 48, 60, 1);
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 10px;
    line-height: 18px;
  }
`;
const ROW = styled(Row)`
  padding: 0.6rem 0px;
  align-items: center;
  /* flex-wrap: nowrap; */
  margin: 0;
`;
const Details = styled.div`
  padding: 0.5rem;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
`;
const FileName = styled.span`
  width: 100%;
  font-size: 15px;
  /* padding-left: 10px; */
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  line-height: 19.5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const DataSourceRow = ({
  numberOfSources,
  dataSource,
  setYTTranscriptLink,
  setShowTranscriptBox,
  reindexSource,
  setSelectedSourceToReindex,
  setSelectedSourceToDelete,
  deleteSource,
  setLast,
  toBeSelected,
  toBeEdit,
}: any) => {
  const { deviceWidth } = useContext(PublicContext);
  const { setSelectedSource, selectedSource } = useContext(BookContext);
  // Reset the selected chat source value when the comnponent is destroyed

  useEffect(() => {
    setSelectedSource(selectedSource);
    return () => {
      // cleanup the state when the component is destroyed!
      setSelectedSource("");
    };
  }, []);

  return (
    <>
      {deviceWidth === DEVICE_TAB || deviceWidth === DEVICE_MOBILE ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#f8f7fa",
          }}
        >
          <>
            <DataSourceDetails
              sm={12}
              style={{
                background: toBeSelected ? "#dcedf4" : "",
              }}
            >
              <ROW>
                <Col xs={6} sm={6} style={{ alignItems: "center" }}>
                  <StatusColumn dataSource={dataSource} />

                  <PageCount>
                    <IncludedInfoColumn dataSource={dataSource} />
                  </PageCount>
                </Col>
                <Col xs={6} sm={6} className="justify-content-end">
                  <ShowSourceCloumn
                    dataSource={dataSource}
                    setYTTranscriptLink={setYTTranscriptLink}
                    setShowTranscriptBox={setShowTranscriptBox}
                  />
                  &nbsp; &nbsp; &nbsp;
                  <ReIndexActionColumn
                    dataSource={dataSource}
                    reindexSource={reindexSource}
                    setSelectedSourceToReindex={setSelectedSourceToReindex}
                  />
                  &nbsp; &nbsp; &nbsp;
                  <DeleteActionColumn
                    dataSource={dataSource}
                    setSelectedSourceToDelete={setSelectedSourceToDelete}
                    deleteSource={deleteSource}
                    numberOfSources={numberOfSources}
                    setLast={setLast}
                  />
                </Col>
              </ROW>
              <Details>
                <FileName>
                  <TitleColumn
                    dataSource={dataSource}
                    setYTTranscriptLink={setYTTranscriptLink}
                    setShowTranscriptBox={setShowTranscriptBox}
                  />
                </FileName>
              </Details>
              <ROW>
                <Col xs={6} sm={6}>
                  <TimeSpan>Created:</TimeSpan>
                  <Time>
                    <CreatedAtColumn dataSource={dataSource} />{" "}
                  </Time>
                </Col>
                <Col xs={6} sm={6}>
                  <TimeSpan>Reindex:</TimeSpan>
                  <Time>
                    <ReindexAtColumn dataSource={dataSource} />
                  </Time>
                </Col>
              </ROW>
            </DataSourceDetails>
          </>
        </div>
      ) : (
        <tr
          role="row"
          className=""
          draggable="false"
          key={dataSource.id}
          style={{
            background: toBeSelected ? "#dcedf4" : "#fff",
          }}
        >
          <td className="titleColumn" role="cell">
            {/*  {titleBodyTemplate(ele)} */}
            <TitleColumn
              dataSource={dataSource}
              setYTTranscriptLink={setYTTranscriptLink}
              setShowTranscriptBox={setShowTranscriptBox}
            />
          </td>
          <td className="includedFilesColumn" role="cell">
            <IncludedInfoColumn dataSource={dataSource} />
          </td>
          <td className="statusColumn" role="cell">
            <StatusColumn dataSource={dataSource} />
          </td>
          <td className="statusColumn" role="cell">
            <ReIndexActionColumn
              dataSource={dataSource}
              reindexSource={reindexSource}
              setSelectedSourceToReindex={setSelectedSourceToReindex}
            />
          </td>
          <td className="createdAtColumn" role="cell">
            <CreatedAtColumn dataSource={dataSource} />{" "}
          </td>
          <td className="reindexAtColumn" role="cell">
            <ReindexAtColumn dataSource={dataSource} />
          </td>
          <td className="uploadedOnColumn" role="cell">
            <DeleteActionColumn
              dataSource={dataSource}
              setSelectedSourceToDelete={setSelectedSourceToDelete}
              deleteSource={deleteSource}
              numberOfSources={numberOfSources}
              setLast={setLast}
              reindexSource={reindexSource}
              setSelectedSourceToReindex={setSelectedSourceToReindex}
              toBeEdit={toBeEdit}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default DataSourceRow;
