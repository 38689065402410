import { Field, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  DEVICE_MOBILE,
  MEDIUM,
  MOBILE,
  TAB,
} from "../../constants/breakpoints";
import { PublicContext } from "../../context/PublicContext";
import { UserContext } from "../../context/UserContext";
import { LoaderContext } from "../../context/loader-context";
import { NotesContext } from "../../context/notes-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import ShowAnswerEye from "../../images/answer-eye.svg";
import BackIcon from "../../images/chat-back.svg";
import HideAnswerEye from "../../images/hide-answer-eye.svg";
import NoteCategorySvg from "../../images/note-category-icon.svg";
import { Mixpanel } from "../../mixpanel";
import myAxios from "../../myaxios";
import { CategorySummary, NotesData } from "../../types";
import { FLASHCARD_QUESTION_STATUS } from "../../utils/class-names";
import { isTestUser, parseErrorResponse } from "../../utils/utils";
import MainHeader from "../header";
import SubscriptionExpiredPopup from "../subscription-expired-popup";
import SubscriptionLimitPopup from "../subscription-limit-popup";
import NotesSideBar from "./notes-sidebar";
import * as NotesStyles from "./styled";

const SubscriptionExpireOverlay = styled.div`
  position: absolute;
  height: 70%;
  width: 35%;
  z-index: 9;
  top: 55%;
  left: 55%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const SelectNoteBtn = styled.button`
  background: #1585b5;
  color: #fff;
  border: 1px solid transparent;
  width: auto;
  display: flex;
  margin-top: auto;
  justify-content: space-evenly;
  border-radius: 4px;
  height: 30px;
  align-items: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    /* padding: 0.5rem; */
    font-size: 12px;
    width: 179px;
    height: 33px;
    opacity: 1 !important;
    margin: 10px;
  }

  @media (min-width: ${TAB.minWidth}px) {
    width: 44%;
    margin: 10px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: auto;
  }
`;

const Notes = () => {
  const { user } = useContext(UserContext);
  const { deviceWidth } = useContext(PublicContext);
  const { setNotesCategories, notesCategories } = useContext(NotesContext);
  // eslint-disable-next-line
  const { showLoader, setShowLoader } = useContext(LoaderContext);
  const [showAnswer, setShowAnswer] = useState<boolean>(false);

  const [selectedNoteCategory, setSelectedNoteCategory] = useState<string>("");
  const [questionStatus, setQuestionStatus] = useState<string>("");

  const [categorySummary, setCategorySummary] = useState<CategorySummary>();

  const [reviewStarted, setReviewStarted] = useState<boolean>(false);

  const [notesData, setNotesData] = useState<NotesData>();

  const [currentNotesCount, setCurrentNotesCount] = useState<number>(0);
  const [currentSkipCount, setCurrentSkipCount] = useState<number>(1);
  const [showSideBar, setShowSideBar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    myAxios
      .get(`${process.env.REACT_APP_SERVER_URL}/notes-categories`)
      .then((response) => {
        if (response.data && response.data.data) {
          const sortedCategoryArrayForDefault = [
            ...response.data.data.filter(({ type }: any) => type === "default"),
            ...response.data.data.filter(({ type }: any) => type !== "default"),
          ];
          setNotesCategories(sortedCategoryArrayForDefault);
        }
      })
      .catch((error) => {
        // handle error
        // console.log(
        //   "Gettings error at he time of fetch flashcards categories --- ",
        //   error
        // );
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (notesCategories && notesCategories.length > 0) {
      getSummary();
    }
    // eslint-disable-next-line
  }, [notesCategories]);

  const getSummary = () => {
    setShowAnswer(true);
    setCurrentSkipCount(1);
    myAxios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/notes-categories/get-summary/${
          notesCategories && notesCategories[0]._id
        }`
      )
      .then((response) => {
        if (response.data && response.data.data) {
          setCategorySummary(response.data.data);
          if (notesCategories) setSelectedNoteCategory(notesCategories[0]._id);
        }
        setShowAnswer(false);
      })
      .catch((error) => {
        // handle error
        // console.log(
        //   "Getting error at the time of get flashcards summary ",
        //   error
        // );
        setShowAnswer(false);
      });
  };

  const startReviewAction = (values: any) => {
    trackEvent({
      email: getUsersEmail(user),
      eventCategory: EventCategory.CHATBOT,
      event: ChatBotEvents.USER_REVIEW_FLASHCARD_CATEGORY,
      subCategory: SubCategory.EVENT,
      rawData: values,
    });
    setShowLoader(true);
    // console.log("values.questionStatus  ", values.questionStatus);
    setQuestionStatus(values.questionStatus);

    const dataToSent = {
      pagination: {
        skip: 0,
        limit: 1,
      },
      criteria: {
        notesCategoryId: selectedNoteCategory,
        isForgotten: values.questionStatus === "isForgotten",
        isRemembered: values.questionStatus === "isRemembered",
        isSkipped: values.questionStatus === "isSkipped",
      },
    };
    if (!isTestUser(user?.email))
      Mixpanel.track("Flash card review started", {
        notesCategoryId: selectedNoteCategory,
        //categoryName: values.categoryName,
        email: user?.email,
        action: values.questionStatus,
      });

    myAxios
      .post(`${process.env.REACT_APP_SERVER_URL}/paginated-notes`, dataToSent)
      .then((response) => {
        if (
          response.data &&
          response.data.data &&
          response.data.data.notes &&
          response.data.data.notes.length > 0
        ) {
          setNotesData(response.data.data.notes[0]);
          setCurrentNotesCount(response.data.data.count);
          setReviewStarted(true);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        // handle error
        // console.log(
        //   "Getting error at the time of get flashcards details --- ",
        //   error
        // );
        setShowLoader(false);
      });
  };

  const nextNotes = (increseSkipCount: boolean) => {
    if (currentSkipCount < currentNotesCount) {
      setShowLoader(true);

      const dataToSent = {
        pagination: {
          skip: increseSkipCount ? currentSkipCount : currentSkipCount - 1,
          limit: 1,
        },
        criteria: {
          notesCategoryId: selectedNoteCategory,
          isForgotten: questionStatus === "isForgotten",
          isRemembered: questionStatus === "isRemembered",
          isSkipped: questionStatus === "isSkipped",
        },
      };

      if (increseSkipCount) setCurrentSkipCount(currentSkipCount + 1);

      myAxios
        .post(`${process.env.REACT_APP_SERVER_URL}/paginated-notes`, dataToSent)
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.notes &&
            response.data.data.notes.length > 0
          ) {
            setNotesData(response.data.data.notes[0]);
            setCurrentNotesCount(response.data.data.count);
            setReviewStarted(true);
            setShowLoader(false);
          } else {
            getSummary();
          }
        })
        .catch((error) => {
          // handle error
          setShowLoader(false);
          // console.log(
          //   "Getting error at the time of get flashcards details --- ",
          //   error
          // );
        });
    } else {
      // All question done so need to go summary screen
      getSummary();
      setReviewStarted(false);
    }
  };

  const updateNotesStatus = (
    request: any,
    notesId: any,
    increseSkipCount: boolean
  ) => {
    myAxios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/update-notes-status/${notesId}`,
        request
      )
      .then((response) => {
        if (response.data && response.data.success) {
          setShowAnswer(false);
          NotificationManager.success("Status updated successfully!");
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Failed to update status, please try again"
          );
        }
        nextNotes(increseSkipCount);
      })
      .catch((error) => {
        // console.error("Getting error at the time of update status --- ", error);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const getQuestionStatus = (status: string) => {
    let questionStatus;
    if (status === "isForgotten") {
      questionStatus = "Forgotten";
    } else if (status === "isRemembered") {
      questionStatus = "Remembered";
    } else if (status === "isSkipped") {
      questionStatus = "Skipped";
    } else {
      questionStatus = "All";
    }

    return questionStatus;
  };

  return (
    <>
      <MainHeader isUserPage={true} activeFrom={"fromNotes"} />
      <NotesStyles.MainContainer fluid>
        {showSideBar && deviceWidth === DEVICE_MOBILE && (
          <NotesSideBar
            setCategorySummary={setCategorySummary}
            selectedNoteCategory={selectedNoteCategory}
            setSelectedNoteCategory={setSelectedNoteCategory}
            setReviewStarted={setReviewStarted}
            setShowSideBar={setShowSideBar}
          />
        )}
        {deviceWidth !== DEVICE_MOBILE && (
          <NotesSideBar
            setCategorySummary={setCategorySummary}
            selectedNoteCategory={selectedNoteCategory}
            setSelectedNoteCategory={setSelectedNoteCategory}
            setReviewStarted={setReviewStarted}
            setShowSideBar={setShowSideBar}
          />
        )}

        <NotesStyles.RightColumn sm={12} lg={10} xs={12}>
          <NotesStyles.ChatPageHeader>
            <Col
              sm={1}
              xs={1}
              style={{
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <NotesStyles.BackButton
                src={BackIcon}
                onClick={() => {
                  if (reviewStarted) {
                    setReviewStarted(false);
                  } else {
                    navigate("/my-chatbots/create-pdf-chatbot");
                  }
                }}
              />

              <NotesStyles.BookContent>
                <h5 style={{ margin: 0 }}>Flashcards</h5>
              </NotesStyles.BookContent>
            </Col>
            <Col sm={11} xs={11}></Col>
          </NotesStyles.ChatPageHeader>
          {deviceWidth === DEVICE_MOBILE && (
            <SelectNoteBtn
              onClick={() => {
                setShowSideBar(true);
              }}
            >
              <img src={NoteCategorySvg} alt="category" />
              Select Note Category
            </SelectNoteBtn>
          )}
          {user && user.currentSubscription ? (
            user.currentSubscription.meta &&
            user.currentSubscription?.meta?.flashCard ? (
              <>
                <NotesStyles.ChatInputBox sm={12} xs={12}>
                  <NotesStyles.RightContentRow>
                    <NotesStyles.MainConent sm={12} xs={12} lg={12}>
                      <NotesStyles.CategoryHeading>
                        {categorySummary?.categoryName} Flashcards
                      </NotesStyles.CategoryHeading>
                      {!reviewStarted ? (
                        <>
                          {selectedNoteCategory !== "" ? (
                            <NotesStyles.CategorySummaryParent
                              sm={12}
                              xs={12}
                              lg={7}
                            >
                              <NotesStyles.CategorySummaryHeader>
                                <Col sm={6} xs={6}>
                                  <NotesStyles.SummaryQuestionCount>
                                    Total Questions -{" "}
                                    {categorySummary?.totalQuestionCount}
                                  </NotesStyles.SummaryQuestionCount>
                                </Col>
                                <Col sm={6} xs={6}>
                                  <NotesStyles.SummaryQuestionCount>
                                    Last Checked -{" "}
                                    {categorySummary?.lastChecked}
                                  </NotesStyles.SummaryQuestionCount>
                                </Col>
                              </NotesStyles.CategorySummaryHeader>

                              <NotesStyles.CategorySummaryDetails>
                                <Col className="flex-column" xs={4}>
                                  <Col style={{ alignItems: "center" }}>
                                    <NotesStyles.ForgottenBox></NotesStyles.ForgottenBox>
                                    <NotesStyles.SummaryCountText>
                                      Forgotten
                                    </NotesStyles.SummaryCountText>
                                  </Col>
                                  <Col>
                                    <NotesStyles.SummaryCountValues>
                                      {categorySummary?.forgottenCount} -
                                      Questions
                                    </NotesStyles.SummaryCountValues>
                                  </Col>
                                </Col>
                                <Col className="flex-column" xs={4}>
                                  <Col style={{ alignItems: "center" }}>
                                    <NotesStyles.RememberedBox></NotesStyles.RememberedBox>
                                    <NotesStyles.SummaryCountText>
                                      Remembered
                                    </NotesStyles.SummaryCountText>
                                  </Col>
                                  <Col>
                                    <NotesStyles.SummaryCountValues>
                                      {categorySummary?.rememberedCount} -
                                      Questions
                                    </NotesStyles.SummaryCountValues>
                                  </Col>
                                </Col>
                                <Col className="flex-column" xs={4}>
                                  <Col style={{ alignItems: "center" }}>
                                    <NotesStyles.SkippedBox></NotesStyles.SkippedBox>
                                    <NotesStyles.SummaryCountText>
                                      Skipped
                                    </NotesStyles.SummaryCountText>
                                  </Col>
                                  <Col>
                                    <NotesStyles.SummaryCountValues>
                                      {categorySummary?.skippedCount} -
                                      Questions
                                    </NotesStyles.SummaryCountValues>
                                  </Col>
                                </Col>
                              </NotesStyles.CategorySummaryDetails>
                              <NotesStyles.QuestionCategoryActions>
                                <Formik
                                  initialValues={{
                                    categoryId: "",
                                    questionStatus: "all",
                                  }}
                                  onSubmit={startReviewAction}
                                >
                                  {({ values, errors, touched }: any) => (
                                    <>
                                      <NotesStyles.QuestionActionForm
                                        className={FLASHCARD_QUESTION_STATUS}
                                      >
                                        {categorySummary &&
                                          categorySummary.totalQuestionCount >
                                            0 && (
                                            <>
                                              <Field
                                                as="select"
                                                name="questionStatus"
                                              >
                                                <option value="all">
                                                  All Questions
                                                </option>
                                                {categorySummary.forgottenCount >
                                                  0 && (
                                                  <option value="isForgotten">
                                                    Only Forgotten Questions
                                                  </option>
                                                )}
                                                {categorySummary.rememberedCount >
                                                  0 && (
                                                  <option value="isRemembered">
                                                    Only Remembered Questions
                                                  </option>
                                                )}
                                                {categorySummary.skippedCount >
                                                  0 && (
                                                  <option value="isSkipped">
                                                    Only Skipped Questions
                                                  </option>
                                                )}
                                              </Field>
                                              <button
                                                type="submit"
                                                style={{ fontSize: "13px" }}
                                              >
                                                START REVIEW
                                              </button>
                                            </>
                                          )}
                                      </NotesStyles.QuestionActionForm>
                                    </>
                                  )}
                                </Formik>
                              </NotesStyles.QuestionCategoryActions>
                            </NotesStyles.CategorySummaryParent>
                          ) : (
                            <>
                              <NotesStyles.SummaryCountText>
                                No category available, please create or select
                                any flashcards category from the sidebar!
                              </NotesStyles.SummaryCountText>
                            </>
                          )}
                        </>
                      ) : (
                        <NotesStyles.CategorySummaryParent sm={12} xs={12}>
                          <NotesStyles.CategorySummaryHeader>
                            <Col sm={6} xs={6}>
                              <NotesStyles.SummaryQuestionCount>
                                {getQuestionStatus(questionStatus)} Questions
                              </NotesStyles.SummaryQuestionCount>
                            </Col>
                            <Col sm={6} xs={6} className="justify-content-end">
                              <NotesStyles.SummaryQuestionCount>
                                {currentSkipCount}/{currentNotesCount}
                              </NotesStyles.SummaryQuestionCount>
                            </Col>
                          </NotesStyles.CategorySummaryHeader>

                          {notesData && (
                            <NotesStyles.QuestionCategoryActions>
                              <NotesStyles.NotesQuestionParent>
                                <NotesStyles.QuestionContent>
                                  {notesData.question}
                                </NotesStyles.QuestionContent>
                                {showAnswer && (
                                  <NotesStyles.AnswerContent>
                                    <h3>Answer</h3>
                                    {notesData.answer}
                                  </NotesStyles.AnswerContent>
                                )}
                                <NotesStyles.ShowAnswerButton
                                  onClick={() => {
                                    setShowAnswer((oldState) => !oldState);
                                  }}
                                >
                                  <img
                                    src={
                                      showAnswer ? HideAnswerEye : ShowAnswerEye
                                    }
                                    alt="showanswer"
                                  />{" "}
                                  {showAnswer ? "Hide Answer" : "Show Answer"}
                                </NotesStyles.ShowAnswerButton>
                                <NotesStyles.ButtonSection>
                                  <NotesStyles.ForgottenBtn
                                    onClick={() => {
                                      let increseSkipCount = false;
                                      // console.log(
                                      //   "questionStatus -- ",
                                      //   questionStatus
                                      // );
                                      if (
                                        questionStatus === "all" ||
                                        questionStatus === "isForgotten"
                                      )
                                        increseSkipCount = true;

                                      updateNotesStatus(
                                        { isForgotten: true },
                                        notesData._id,
                                        increseSkipCount
                                      );
                                    }}
                                  >
                                    FORGOTTEN
                                  </NotesStyles.ForgottenBtn>
                                  <NotesStyles.RememberedBtn
                                    onClick={() => {
                                      let increseSkipCount = false;
                                      // console.log(
                                      //   "questionStatus -- ",
                                      //   questionStatus
                                      // );
                                      if (
                                        questionStatus === "all" ||
                                        questionStatus === "isRemembered"
                                      )
                                        increseSkipCount = true;

                                      updateNotesStatus(
                                        { isRemembered: true },
                                        notesData._id,
                                        increseSkipCount
                                      );
                                    }}
                                  >
                                    REMEBERED
                                  </NotesStyles.RememberedBtn>
                                  <NotesStyles.SkipBtn
                                    onClick={() => {
                                      let increseSkipCount = false;
                                      // console.log(
                                      //   "questionStatus -- ",
                                      //   questionStatus
                                      // );
                                      if (
                                        questionStatus === "all" ||
                                        questionStatus === "isSkipped"
                                      )
                                        increseSkipCount = true;

                                      updateNotesStatus(
                                        { isSkipped: true },
                                        notesData._id,
                                        increseSkipCount
                                      );
                                    }}
                                  >
                                    SKIP
                                  </NotesStyles.SkipBtn>
                                </NotesStyles.ButtonSection>
                              </NotesStyles.NotesQuestionParent>
                            </NotesStyles.QuestionCategoryActions>
                          )}
                        </NotesStyles.CategorySummaryParent>
                      )}
                    </NotesStyles.MainConent>
                  </NotesStyles.RightContentRow>
                </NotesStyles.ChatInputBox>
              </>
            ) : (
              <NotesStyles.RightContentRow style={{ width: "81vw" }}>
                <SubscriptionExpireOverlay>
                  <SubscriptionLimitPopup
                    restrictionMessage={`Your plan does not have access to flashcards feature.`}
                    messageregardingrest={`To use flashcards please upgrade your plan`}
                    highliter={`isFlashcardHighliter`}
                  ></SubscriptionLimitPopup>
                </SubscriptionExpireOverlay>
              </NotesStyles.RightContentRow>
            )
          ) : (
            <NotesStyles.RightContentRow style={{ width: "81vw" }}>
              <SubscriptionExpireOverlay>
                <SubscriptionExpiredPopup
                  restrictionMessage={`Your plan has expired!`}
                  messageregardingrest={`To use flashcards please purchase a plan`}
                  highliter={`isFlashcardHighliter`}
                ></SubscriptionExpiredPopup>
              </SubscriptionExpireOverlay>
            </NotesStyles.RightContentRow>
          )}
        </NotesStyles.RightColumn>
      </NotesStyles.MainContainer>
    </>
  );
};

export default Notes;
