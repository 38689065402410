import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";

const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 0.5rem;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;
const InputField = styled.input`
  height: 46px;
  width: 100%;
  padding: 0.5rem;
  background: #eaeaea;
  border: 1px solid #eaeaea;
  margin-bottom: 0.5rem;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const ChatLive = () => {
  const formik = useFormikContext();
  const { values, errors } = useFormikContext<any>();

  return (
    <>
      <div className="d-flex">
        <Col sm={10} xs={10} style={{ flexDirection: "column" }}>
          <MenuHeading>Chat with a human/live chat</MenuHeading>
        </Col>
        <Col
          sm={2}
          xs={2}
          style={{
            justifyContent: "flex-end",
            alignSelf: "flex-start",
          }}
        >
          <CheckBoxWrapper>
            <>
              <CheckBox
                id="checkbox"
                checked={values.chatWithHumanSetting.isChatLive}
                type="checkbox"
                name="chatWithHumanSetting.isChatLive"
                onChange={(e: any) => {
                  formik.setFieldValue(
                    "chatWithHumanSetting.isChatLive",
                    e.target.checked
                  );
                }}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </>
          </CheckBoxWrapper>
        </Col>
      </div>
      <Row>
        <SubHeading>
          When the user clicks on the 'Chat with Human' icon, they will be
          redirected to the following link:
        </SubHeading>
      </Row>
      <Col sm={12} style={{ flexDirection: "column" }}>
        <InputField
          type="text"
          name="chatWithHumanSetting.redirectLink"
          disabled={!values.chatWithHumanSetting.isChatLive}
          defaultValue={values.chatWithHumanSetting.redirectLink}
          onChange={(e: any) => {
            formik.setFieldValue(
              "chatWithHumanSetting.redirectLink",
              e.target.value
            );
          }}
          placeholder="https://dropchat.co"
        />
        {errors?.chatWithHumanSetting &&
        typeof errors.chatWithHumanSetting === "string" ? (
          <div style={{ color: "red" }}>{errors.chatWithHumanSetting}</div>
        ) : (
          <div></div>
        )}
      </Col>
    </>
  );
};

export default ChatLive;
