import { useContext, useState, useRef, useEffect } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styled from "styled-components";
import { LoaderContext } from "../../context/loader-context";
import myAxios from "../../myaxios";
import { Field, Form, Formik } from "formik";
import { isValidEmail, parseErrorResponse } from "../../utils/utils";
import { NotificationManager } from "react-notifications";
import ConfirmationBox from "../utility-component/confirmation-box";
import DeleteIcon from "../../images/trash.svg";

const BookFetchForm = styled(Form)`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
`;

const FormLabelText = styled(FormLabel)`
  color: #33303c;
  font-weight: 600;
  font-size: 15px;
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
  color: #33303cde;
  border: 1px solid #eaeaea;
  background: #f8f7fa;
`;

const ValueSelectField = styled.select`
  width: 100%;
  padding: 10px;
  color: #33303cde;
  border: 1px solid #eaeaea;
  background: #f8f7fa;
`;

const ScriptTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  color: #33303cde;
  border: 1px solid #eaeaea;
  background: #f8f7fa;
  height: 200px;
`;

const FormError = styled.span`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const SaveButton = styled(Button)`
  width: 100%;
  background: #1585b5;
  padding: 0.5rem;
  color: #fff;
  border: none;
`;

const ActionButton = styled(Button)`
  width: max-content;
  background: #1585b5;
  padding: 0.5rem 2rem;
  color: #fff;
  border: none;
`;

const DataTableColumn = styled(Col)`
  flex-direction: column;
  & h5 {
    font-weight: bold;
  }
`;

const HTMLScriptsForm = ({ setShowLoader }: any) => {
  const validateDeleteAccountSubmission = (formData: any) => {
    const errors: any = {};
    if (!formData.name) {
      errors.name = "Enter valid name!";
    }
    if (!formData.content) {
      errors.content = "Enter valid content!";
    }
    return errors;
  };

  const scriptContentTextArea = useRef<HTMLTextAreaElement>(null);

  return (
    <Row style={{ marginTop: "1rem" }}>
      <Col sm={6}>
        <Formik
          initialValues={{
            type: "Footer",
            name: "",
            content: "",
          }}
          validate={validateDeleteAccountSubmission}
          onSubmit={(values) => {
            myAxios
              .post(`/html-script-settings`, {
                type: values.type,
                name: values.name,
                content: values.content,
              })
              .then(async (response) => {
                if (response.data.success) {
                  NotificationManager.success(response.data.message);
                } else {
                  setShowLoader(false);
                  NotificationManager.error(response.data.message, "", 2000);
                }
                // setLoading(false);
              })
              .catch((error) => {
                setShowLoader(false);
                NotificationManager.error(parseErrorResponse(error));
                // setLoading(false);
              });
          }}
        >
          {({ values, errors, touched, resetForm, setFieldValue }: any) => (
            <BookFetchForm>
              <>
                <FormLabelText>Type</FormLabelText>
                <FieldSection>
                  <ValueSelectField
                    name="type"
                    defaultValue={values.type}
                    onChange={(e) => {
                      setFieldValue("type", e.target.value);
                    }}
                  >
                    {/* <option value="Header" selected>
                      Header
                    </option> */}
                    <option value="Footer">Footer</option>
                  </ValueSelectField>
                </FieldSection>
                <FormLabelText>Name</FormLabelText>
                <FieldSection>
                  <FormInput name="name" placeholder="Enter name of script" />
                  {errors.name && touched.name && (
                    <FormError className="formError">{errors.name}</FormError>
                  )}
                </FieldSection>
                <FormLabelText>{values.type} Script Content</FormLabelText>
                <FieldSection>
                  <ScriptTextArea
                    id="script-content-text"
                    ref={scriptContentTextArea}
                    name="content"
                    defaultValue={values.content}
                    onChange={(e: any) => {
                      setFieldValue("content", e.target.value);
                    }}
                  ></ScriptTextArea>
                  {errors.content && touched.content && (
                    <FormError className="formError">
                      {errors.content}
                    </FormError>
                  )}
                </FieldSection>
                <Row>
                  <Col>
                    <SaveButton type="submit">SAVE</SaveButton>
                  </Col>
                </Row>
              </>
            </BookFetchForm>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

const ListOfScripts = ({ setShowLoader }: any) => {
  const [listOfScripts, setListOfScripts] = useState<any>();
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  const [customMessage, setCustomMessage] = useState(
    "Do you really want to delete this script?"
  );
  const [confirmationAction, setConfirmationAction] = useState<string>("");
  const [selectedScript, setSelectedScript] = useState<any>();

  const deleteScript = () => {
    myAxios
      .delete(`/html-script-settings/${selectedScript._id}`)
      .then(async (response) => {
        if (response.data.success) {
          NotificationManager.success(response.data.message);
          setListOfScripts(response.data.data);
          setShowUtilityConfirmationBox(false);
          setShowLoader(false);
        } else {
          setShowLoader(false);
          NotificationManager.error(response.data.message, "", 2000);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const confirmClick = (action: string) => {
    if (action === "delete") {
      deleteScript();
    }
  };

  useEffect(() => {
    myAxios
      .get(`/html-script-settings/script/list-script`)
      .then(async (response) => {
        if (response.data.success) {
          setListOfScripts(response.data.data);
          setShowLoader(false);
        } else {
          setShowLoader(false);
          NotificationManager.error(response.data.message, "", 2000);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  }, []);

  const deleteBody = (data: any) => {
    return (
      <>
        <img
          style={{ cursor: "pointer" }}
          src={DeleteIcon}
          alt={"Delete"}
          className="w-4rem"
          onClick={() => {
            setSelectedScript(data);
            setCustomMessage(`Do you really want to delete this script?`);
            setConfirmationAction("delete");
            setShowUtilityConfirmationBox(true);
          }}
        />
      </>
    );
  };

  return (
    <>
      <Row style={{ marginTop: "1rem" }}>
        {/* <DataTableColumn sm={6}>
          <h5>Header Scripts</h5>
          <div className="">
            <DataTable
              value={
                listOfScripts &&
                listOfScripts.headers.length > 0 &&
                listOfScripts.headers
              }
            >
              <Column field="name" header="Name"></Column>
              <Column field="content" header="Content"></Column>
              <Column header="Action" body={deleteBody}></Column>
            </DataTable>
          </div>
        </DataTableColumn> */}
        <DataTableColumn>
          <h5>Footer Scripts</h5>
          <div className="">
            <DataTable
              value={
                listOfScripts &&
                listOfScripts.footers.length > 0 &&
                listOfScripts.footers
              }
            >
              <Column field="name" header="Name"></Column>
              <Column field="content" header="Content"></Column>
              <Column header="Action" body={deleteBody}></Column>
            </DataTable>
          </div>
        </DataTableColumn>
      </Row>
      {showUtilityConfirmationBox && (
        <ConfirmationBox
          setShow={setShowUtilityConfirmationBox}
          name="Confirmation"
          message={customMessage}
          okButtonText="Yes"
          action={confirmationAction}
          okButtonClick={confirmClick}
        />
      )}
    </>
  );
};

const HTMLScriptsSettings = () => {
  const { setShowLoader } = useContext(LoaderContext);
  const [showList, setShowList] = useState(false);
  return (
    <>
      {showList ? (
        <ActionButton type="button" onClick={() => setShowList(false)}>
          {" "}
          Add scripts{" "}
        </ActionButton>
      ) : (
        <ActionButton type="button" onClick={() => setShowList(true)}>
          {" "}
          Show list of scripts{" "}
        </ActionButton>
      )}
      {showList ? (
        <ListOfScripts setShowLoader={setShowLoader} />
      ) : (
        <HTMLScriptsForm setShowLoader={setShowLoader} />
      )}
    </>
  );
};

export default HTMLScriptsSettings;
