import { useContext } from "react";
import { Accordion, Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { MEDIUM } from "../../../constants/desktop";
import { MOBILE } from "../../../constants/mobile";
import { TAB } from "../../../constants/tab";
import { LoaderContext } from "../../../context/loader-context";
import BluePlusIcon from "../../../images/blue-plus-icon.svg";
import TrashIcon from "../../../images/trash-normal.svg";
import myAxios from "../../../myaxios";
import ConfirmationBox from "../../utility-component/confirmation-box";

const UrlFormLabel = styled(FormLabel)`
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: rgba(51, 48, 60, 0.8);
  font-family: "Poppins", sans-serif;

  margin-bottom: 3px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
    margin: 0;
  }
`;

const NormalInput = styled.input`
  width: 100%;
  padding: 10px;
  position: relative;
  padding-right: 46px;
`;

const UrlSection = styled.div`
  margin: 0.5rem;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    margin: 0.5rem 0rem;
    overflow: auto;
  }

  @media (min-width: ${TAB.minWidth}px) {
    overflow: auto;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin: 1rem;
    flex-direction: column;
  }
`;

const ExtraUrlHeader = styled.div`
  justify-content: space-between;
`;

const DeleteText = styled.span`
  color: #ec2d2d;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
`;

const NormalFieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: row;
  position: relative;
`;

const SetExtraUrlButton = styled.button`
  /* background: #1585b5;
  color: #fff;
  border: none;
  width: max-content; */

  background: none;
  border: none;
  width: max-content;
  right: 0px;
  position: absolute;
  margin-top: 4px;
`;

const CrawlUrlRow = styled(Row)`
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(220, 236, 242, 1);
  margin: 0;
  padding: 6px;
  background: white;
  border-radius: 6px;
  margin-top: 9px;
  cursor: pointer;
`;
const CrawlUrlFirstCol = styled(Col)`
  flex-direction: column;
  flex: 1;
  padding: 0;
`;
const CrawlUrlSecondCol = styled(Col)`
  align-items: center;
  flex: 1;
  justify-content: end;
`;
const LinkCountSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 48, 60, 1);
  font-family: "Poppins", sans-serif;
  line-height: 27px;
`;
const UrlSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: rgba(51, 48, 60, 1);
  font-family: "Poppins", sans-serif;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Headerdiv = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MobileWebsiteSourceAccordian = ({
  allUrlsWithSubUrls,
  selectedKey,
  setSelectedKey,
  handleDeleteMainUrl,
  setOnChangeExtraUrl,
  onChangeExtraUrl,
  isValidHttpUrl,
  validateUrl,
  urls,
  setUrls,
  setallUrlsWithSubUrls,
  removeSubUrls,
  showDeleteConfirmationBox,
  setShowDeleteConfirmationBox,
  deleteUrlIfsubUrlslengthOne,
}: any) => {
  const { setShowLoader } = useContext(LoaderContext);

  return (
    <>
      <Accordion
        className="acc-item mb-0 mt-0"
        style={{
          border: "none",
        }}
      >
        {Object.keys(allUrlsWithSubUrls).map((key, index) => {
          return (
            <Accordion.Item
              key={index}
              eventKey={index.toString()}
              style={{
                display: "block",
                margin: "10px 3px",
                marginBottom: "0",
                borderRadius: "0",
              }}
            >
              <Accordion.Header
                onClick={(e: any) => {
                  setSelectedKey(key);
                }}
              >
                <Headerdiv>
                  <Col xs={10}>
                    <UrlSpan
                      onClick={() => {
                        console.log("key onclick", key);
                        setSelectedKey(key);
                      }}
                    >
                      {key}
                    </UrlSpan>
                  </Col>
                  <Col xs={2}>
                    <img
                      src={TrashIcon}
                      alt="remove"
                      style={{ width: "18px", marginRight: "11px" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        if (key) {
                          setShowDeleteConfirmationBox(true);
                          setSelectedKey(key);
                          // handleDeleteMainUrl(key);
                        }
                      }}
                    />
                  </Col>
                </Headerdiv>
              </Accordion.Header>
              <Accordion.Body>
                <Col sm={12} lg={7} xs={12} style={{ flexDirection: "column" }}>
                  <UrlSection>
                    <ExtraUrlHeader>
                      <UrlFormLabel>Included Links</UrlFormLabel>
                      {/* <DeleteText>Delete All</DeleteText> */}
                    </ExtraUrlHeader>
                    <NormalFieldSection>
                      <NormalInput
                        name="extraUrl"
                        onChange={(e) => {
                          setOnChangeExtraUrl(e.target.value);
                        }}
                        value={onChangeExtraUrl}
                      />
                      <SetExtraUrlButton type="button">
                        <img
                          src={BluePlusIcon}
                          alt="add"
                          onClick={() => {
                            setOnChangeExtraUrl("");
                            if (
                              !isValidHttpUrl(onChangeExtraUrl) ||
                              !validateUrl(onChangeExtraUrl)
                            ) {
                              NotificationManager.error(
                                "valid URL is required!"
                              );
                            } else if (
                              urls.some(
                                (urlData: any) =>
                                  urlData.url === onChangeExtraUrl
                              )
                            ) {
                              NotificationManager.info(
                                onChangeExtraUrl +
                                  " is already available in the list!"
                              );
                            } else {
                              setShowLoader(true);
                              myAxios
                                .post(
                                  process.env.REACT_APP_SERVER_URL +
                                    "/books/url",
                                  {
                                    url: onChangeExtraUrl,
                                  }
                                )
                                .then((response) => {
                                  // here updating the new suburls with the matched selected key-->
                                  if (selectedKey) {
                                    const updatedUrlsWithSubUrls = {
                                      ...allUrlsWithSubUrls,
                                    };

                                    const selectedKeyValues =
                                      updatedUrlsWithSubUrls[selectedKey] || [];

                                    selectedKeyValues.push({
                                      url: response.data.data.url,
                                      characterCount:
                                        response.data.data.characterCount,
                                    });

                                    updatedUrlsWithSubUrls[selectedKey] =
                                      selectedKeyValues;

                                    setallUrlsWithSubUrls(
                                      updatedUrlsWithSubUrls
                                    );
                                  }

                                  setShowLoader(false);
                                  let allUrls = [...urls];
                                  allUrls.unshift(response.data.data);
                                  setUrls(allUrls);
                                });
                            }
                          }}
                        />
                      </SetExtraUrlButton>
                    </NormalFieldSection>
                    <Col
                      style={{
                        flexDirection: "column",
                        overflowY: "scroll",
                        maxHeight: "45vh",
                        // paddingRight: "20px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "2%",
                          flexDirection: "column",
                          paddingBottom: "9rem",
                        }}
                      >
                        {selectedKey && allUrlsWithSubUrls[selectedKey] ? (
                          Object.values(allUrlsWithSubUrls[selectedKey]).map(
                            (value: any, index: number) => (
                              <CrawlUrlRow key={index}>
                                <CrawlUrlFirstCol md={10}>
                                  <UrlSpan>{value.url}</UrlSpan>
                                  <LinkCountSpan>
                                    ({value.characterCount}) Characters
                                  </LinkCountSpan>
                                </CrawlUrlFirstCol>
                                <CrawlUrlSecondCol md={2}>
                                  <img
                                    src={TrashIcon}
                                    alt="remove"
                                    onClick={() => {
                                      removeSubUrls(selectedKey, index);
                                      deleteUrlIfsubUrlslengthOne(selectedKey);
                                    }}
                                  />
                                </CrawlUrlSecondCol>
                              </CrawlUrlRow>
                            )
                          )
                        ) : (
                          <p>No data available.</p>
                        )}
                      </div>
                    </Col>
                  </UrlSection>
                </Col>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      {showDeleteConfirmationBox && (
        <ConfirmationBox
          setShow={setShowDeleteConfirmationBox}
          name="Confirmation"
          message={"Are you sure you want to delete this url ?"}
          okButtonText="Yes"
          action={selectedKey}
          okButtonClick={handleDeleteMainUrl}
        />
      )}
    </>
  );
};

export default MobileWebsiteSourceAccordian;
