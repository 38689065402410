import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";

const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 1rem;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background: #e1e0e2;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 50px;
  height: 22px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #1585b5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 32px;
      transition: 0.2s;
    }
  }
`;

const MarkChatbotAsFeatureChatbot = () => {
  const formik = useFormikContext();
  const { values } = useFormikContext<any>();
  return (
    <>
      <Row style={{ alignItems: "center" }}>
        <Col sm={10} style={{ flexDirection: "column" }}>
          <MenuHeading>Mark chatbot as feature chatbot</MenuHeading>
          <SubHeading>
            You can mark this chatbot as a feature chatbot
          </SubHeading>
        </Col>
        <Col sm={2} style={{ justifyContent: "flex-end" }}>
          <CheckBoxWrapper>
            <>
              <CheckBox
                id="checkbox"
                checked={values.isFeatureChatbot}
                type="checkbox"
                name="isFeatureChatbot"
                onChange={(e: any) => {
                  formik.setFieldValue("isFeatureChatbot", e.target.checked);
                }}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </>
          </CheckBoxWrapper>
        </Col>
      </Row>
    </>
  );
};
export default MarkChatbotAsFeatureChatbot;
