import { useState } from "react";
import MessageVector from "../../images/chat-msg-icon.svg";
import WhiteCross from "../../images/white-cross.svg";
import * as BookChatStyles from "./styled";

const MobileQuestionsSideBar = ({
  questionAnswerHistory,
  setCurrentSessionHistory,
  setShowHistoryPopup,
}: any) => {
  const [selectedQuestion, setSelectedQuestion] = useState<string>("");

  return (
    <>
      <BookChatStyles.Sidebar sm={2}>
        {/* <BookChatStyles.SideBarHeader>
          <img src={WhiteDropChatLogo} alt="DropChatLogo" />
        </BookChatStyles.SideBarHeader> */}
        <BookChatStyles.SideBarContent>
          <BookChatStyles.ChatHistoryHeader>
            <p>Chat History</p>
            <img
              src={WhiteCross}
              alt="cross"
              onClick={() => {
                setShowHistoryPopup(false);
              }}
            />
          </BookChatStyles.ChatHistoryHeader>
          <>
            {questionAnswerHistory &&
              questionAnswerHistory.length > 0 &&
              questionAnswerHistory.map((data: any) => (
                <>
                  <BookChatStyles.HistoryQuestion
                    onClick={() => {
                      setCurrentSessionHistory([data]);
                      setSelectedQuestion(data._id);
                      setShowHistoryPopup(false);
                    }}
                    selected={data._id === selectedQuestion ? true : false}
                  >
                    <img width="18" src={MessageVector} alt="message" />
                    <p>{data.question}</p>
                  </BookChatStyles.HistoryQuestion>
                </>
              ))}
          </>
        </BookChatStyles.SideBarContent>
      </BookChatStyles.Sidebar>
    </>
  );
};

export default MobileQuestionsSideBar;
